<template>

    <div class="flex">
        <modal v-if="!connected" :title="'Stripe integration'" size="small" name="stripe_integration">
            <template #toggle>
                <btn v-if="!connected" type="secondary" size="small" class="-mr-px focus:z-10">
                    Authorize with <img src="/images/logos/stripe-logo.png" class="h-4 ml-1" />
                </btn>
            </template>
            <template #body>
                <validation-errors :errors="errors.validationErrors" />
                <alert v-if="errors.responseError" type="error">
                        <span class="break-all">
                            {{ errors.responseError }}
                        </span>
                </alert>
                <div class="space-y-4">

                    <text-field
                        label="API key"
                        v-model="apiKey"
                        type="password"
                        :show_reveal_password_icon="true"
                        autofocus="true"
                        required="true"
                        :class="{'border-red-400': errors.apiKey}"
                        placeholder="e.g. sk_live_...7quX"
                    >
                        <template #note>
                            Searching for your API key? Simply sign in to your Stripe dashboard, navigate to <strong>Developers</strong> > <strong>API keys</strong>. Copy <strong>Secret key</strong> into the field above for verification.
                        </template>
                    </text-field>

                </div>
            </template>
            <template #footer>
                <div class="flex justify-end">
                    <btn type="primary" :loading="loading.authorize" class="-mr-px focus:z-10" @click="authorize">
                        Authorize
                    </btn>
                </div>
            </template>
        </modal>
        <div v-if="connected" class="border rounded p-6 w-full">
            <div class="text-sm text-gray-500 mb-2">You have successfully connected your Stripe account.</div>
            <modal v-if="connected" title="Stripe integration" size="small" name="stripe_disconnect">
                <template #toggle>
                        <btn size="small" class="-mr-px focus:z-10">Disconnect</btn>
                </template>
                <template #body>
                    <div class="space-y-4">
                        <div class="text-sm text-gray-500">Are you sure you wish to disconnect the Stripe integration?</div>
                    </div>
                </template>
                <template #footer>
                    <btn :loading="loading.disconnect" @click="disconnect">Disconnect</btn>
                </template>
            </modal>
        </div>
    </div>

</template>
<script>

import StripeAfterIntegrationInstructions from "./components/StripeAfterIntegrationInstructions.vue";
import StripeMetadataInstructions from "./components/StripeMetadataInstructions.vue";
import ValidationErrors from "../../../common/ValidationErrors.vue";
import Alert from "../../../../elements/Alert.vue";
import TextField from "../../../../elements/forms/TextField";

export default {
    components: {TextField, Alert, ValidationErrors, StripeAfterIntegrationInstructions, StripeMetadataInstructions},
    props: ['integrationsConfig', 'programId'],
    data() {
        return {
            apiKey: null,
            clientWebhookSecret: null,
            loading: {
                authorize: false,
                disconnect: false,
            },
            errors: {
                apiKey: false,
                responseError: null,
                validationErrors: null,
            },
            connected: this.integrationsConfig.connected ?? false,
        }
    },
    methods: {
        authorize() {

            this.errors.apiKey = false;
            this.errors.responseErrors = null;

            if (this.apiKey === null || this.apiKey.trim() === '') {
                this.errors.apiKey = true;
            }

            if (this.errors.apiKey === false) {
                if (this.loading.authorize) {
                    return;
                }
                this.loading.authorize = true;

                axios.post(route('programs.stripe.quick_connect', {program: this.programId}), {
                    apiKey: this.apiKey,
                }).then((response) => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        window.location.reload();
                    }
                    else if (status === 0) {
                        this.errors.responseError = response.data?.error;
                    }
                })
                .catch((error) => {
                        this.errors.validationErrors = error.response?.data?.errors;
                    })
                .finally(() => this.loading.authorize = false);
            }
        },
        disconnect() {

            if (this.loading.disconnect) {
                return;
            }
            this.loading.disconnect = true;

            axios.post(route('programs.stripe.disconnect', {program: this.programId})).then((response) => {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {
                    window.location.reload();
                }
            })
                .catch((error) => {
                })
                .finally(() => this.loading.disconnect = false);
        }
    }
}
</script>
<style scoped>
</style>
