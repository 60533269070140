<template>
    <div>
        <label :for="input_names.select" class="block mb-1.5 text-sm">{{name}}</label>
        <div class="grid gap-1" :class="types_value_disabled ? 'grid-cols-1' : 'grid-cols-2'">
            <select :id="input_names.select" :name="input_names.select"  v-model="type"
                    class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
                <option value="off">OFF</option>
                <option value="per_payout">Per payout</option>
                <option value="in_total">In total</option>
            </select>
            <text-field :model-value="value" :name="input_names.input" v-if="!types_value_disabled"
                        :disabled="types_value_disabled" :required="!types_value_disabled"></text-field>
        </div>
        <small class="text-gray-500 mt-1 block">{{ description }}</small>
    </div>
</template>
<script>
export default {
    name: 'CommissionClientsThreshold',
    props: ['commission_type', 'commission_value'],
    data() {
        return {
            name: 'Referred clients threshold',
            input_names: {
                select: 'commission_clients_threshold_type',
                input: 'commission_clients_threshold_value',
            },
            type: this.commission_type || 'off',
            value: this.commission_value,
        }
    },
    computed: {
        types_value_disabled() {
            return this.type === 'off';
        },
        description() {
            switch (this.type) {
                case 'per_payout':
                    return 'Set the minimum number of different customers in payout before partner can request it.'
                case 'in_total':
                    return 'Set the minimum number of referred paying clients that a partner must have before they can request a payout.'
                default: return 'Select an option or leave off as unconditional'
            }
        }
    },
    methods: {}
}
</script>
<style scoped>
</style>
