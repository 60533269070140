<template>
    <a
        :href="href"
        class="text-gray-900 text-sm  px-4 inline-flex items-center justify-center whitespace-nowrap"
        :class="{
            'font-semibold bg-white rounded': active,
            'hover:text-teal-600': !active,
        }"
    >
        <span>
            <slot />
        </span>
    </a>
</template>

<script>
export default {
    props: {
        href: {
            type: String,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
