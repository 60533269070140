<template>
    <div v-if="!partneroEmailDeliveryDetails?.is_allowed">
        <alert v-if="partneroEmailDeliveryDetails.is_requested" type="neutral">
            Your request for approval to use the Partnero domain for email delivery has been submitted. Please wait for a decision.
        </alert>
        <alert v-else-if="partneroEmailDeliveryDetails.is_rejected" type="warning">
            Your request for email sending via Partnero has been rejected. <btn @click="openRequestModal" type="link" class="text-yellow-800 underline hover:no-underline">Resubmit request</btn>
            <div class="text-gray-900 text-muted mt-2">
                <div>Reject reason:</div>
                <div>{{ partneroEmailDeliveryDetails.reject_reason }}</div>
            </div>
        </alert>
        <alert v-else type="warning">
            Please note that email sending through a shared Partnero domain is disabled by default. To start sending emails, select one of the available options below. If you’d like to test email delivery using a shared Partnero domain, approval is required.
            <btn @click="openRequestModal" type="link" class="text-yellow-800 underline hover:no-underline">Request for approval</btn>
        </alert>

        <modal title="Request for approval" size="small" name="request_partnero_email_delivery">
            <template #body>
                <alert v-if="requestError" type="error" class="mb-2">{{ requestError }}</alert>
                <div class="space-y-6">
                    <div>
                        <text-field label="Reason"
                            @update:modelValue="updateRequestReason"
                            :modelValue="requestData.request_reason"
                            v-on:keyup.enter.prevent="submitRequest"
                        >
                            <template #note>
                                Please provide a brief explanation of why you would like to use a shared Partnero domain for your emails.
                            </template>
                        </text-field>
                    </div>
                </div>
            </template>
            <template #footer>
                <btn type="primary" @click="submitRequest" :disabled="requestInProgress"
                     :loading="requestInProgress">Submit request
                </btn>
            </template>
        </modal>
    </div>
</template>

<script setup>

import {inject, ref} from "vue";

const props = defineProps({
    program: {
        type: [Number, Array, Object]
    },
    details: {
        type: [Array, Object]
    },
});

const emitter = inject('emitter');
const partneroEmailDeliveryDetails = ref(props.details ?? {})
const requestError = ref(null)
const requestInProgress = ref(false)
const partneroEmailDeliveryRequestUrl = route('app_int.program.emails.partnero_email_delivery_request', props.program)

const requestData = ref({
    request_reason: ''
})

const openRequestModal = () => {
    emitter.emit('modal-on', 'request_partnero_email_delivery')
}

const updateRequestReason = (value) => {
    requestData.value.request_reason = value
}

const submitRequest = () => {
    requestError.value = null;
    requestInProgress.value = true;

    axios
        .post(partneroEmailDeliveryRequestUrl, requestData.value)
        .then(response => {
            const status = parseInt(response.data.status || 0);
            if (status === 1) {
                partneroEmailDeliveryDetails.value = response.data.partnero_email_delivery
                emitter.emit('modal-off', 'request_partnero_email_delivery');
            } else {
                requestError.value = response.data?.error ?? 'Request failed.';
            }
        })
        .catch(error => {
            requestError.value = Object.values(error.response.data.errors).join(', ') || 'Request failed';
        })
        .finally(() => requestInProgress.value = false);
}
</script>
