<template>

    <div class="space-y-4">

        <header-bar size="small" title="Overview">
            <template #actions>
                <div class="flex space-x-2">
                    <select id="commission_period" @change="timePeriodChange($event)" name="time_period"
                            v-model="timePeriod"
                            class="-mt-2 xl:-mt-4 -mr-4 block border-0 focus:ring-0 leading-4 ml-auto px-8 py-1.5 rounded text-right text-xs text-gray-500">
                        <option value="total" selected>Total</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_month">This month</option>
                        <option value="last_month">Last month</option>
                        <option value="last_6_months">Last 6 months</option>
                        <option value="this_year">This year</option>
                        <option value="last_year">Last year</option>
                    </select>
                </div>
            </template>
        </header-bar>

        <setup-guide :programId="program"/>

        <!-- Program settings data -->
        <card
            v-if="data.overview_program_current_settings"
            inset="none"
            #body
        >
            <div class="grid grid-cols-1 lg:grid-cols-3">
                <div class="col-span-1 p-6 border-b lg:border-b-0">
                    <div class="flex items-center gap-4">
                        <div class="flex flex-shrink-0 justify-center items-center w-20 h-20 font-semibold tracking-tight rounded-md text-xl text-teal-600 bg-teal-300 bg-opacity-30">{{ data.overview_program_current_settings?.program_initials }}</div>
                        <div class="flex gap-0.5 flex-col">
                            <span class="block font-medium text-xs uppercase">{{ data.overview_program_current_settings?.program_type_title }}</span>
                            <span class="block font-semibold">{{ data.overview_program_current_settings?.program_name }}</span>
                            <a
                                :href="data.overview_program_current_settings?.partner_portal_url"
                                target="_blank"
                                class="flex gap-0.5 items-center font-medium text-sm text-teal-600 hover:underline"
                                :title="data.overview_program_current_settings?.partner_portal_url"
                            >
                                <icon name="link" size="12"/>
                                <span>
                                    {{ data.overview_program_current_settings?.partner_portal_url.length > 24
                                    ? data.overview_program_current_settings?.partner_portal_url.substring(0, 21) + '...'
                                    : data.overview_program_current_settings?.partner_portal_url.length }}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 p-6 flex items-center">

                    <ul class="flex-1 grid grid-cols-3 gap-y-4 gap-x-4 text-sm">
                        <li class="flex flex-col gap-1.5">
                            <span class="text-sm text-gray-500">Commission</span>
                            <span
                                class="font-semibold"
                            >
                           {{ data.overview_program_current_settings?.commission_description }}
                       </span>
                        </li>
                        <li class="flex flex-col gap-1.5">
                            <span class="text-sm text-gray-500">Cookie lifetime</span>
                            <span class="font-semibold">
                            {{ data.overview_program_current_settings?.cookie_lifetime }}
                            {{ data.overview_program_current_settings?.cookie_lifetime == 1 ? 'day' : 'days'}}
                        </span>
                        </li>
                        <li class="flex flex-col gap-1.5">
                            <span class="text-sm text-gray-500">Payout threshold</span>
                            <span class="font-semibold">{{ data.overview_program_current_settings?.payout_threshold + ' ' + data.overview_program_current_settings?.currency }}</span>
                        </li>
                    </ul>

                </div>
            </div>
        </card>
        <!-- ./ Program settings data -->

            <!-- Program stats -->
        <div class="grid lg:grid-cols-3 gap-4">

            <white-card title="Partners" :value="this.data.total_partners" :growth="this.data.total_partners_growth"></white-card>

            <white-card title="Rewards" :value="this.data.total_reward" :growth="this.data.total_reward_growth" :currency="true"></white-card>

            <white-card title="Program revenue" :value="this.data.total_revenue" :growth="this.data.total_revenue_growth" :currency="true" type="teal"></white-card>

        </div>

    </div>

</template>
<script>
export default {
    name: 'AffiliateProgramStats',
    props: ['dataUrl', 'program'],
    data() {
        return {
            errors: [],
            loading: false,
            timePeriod: 'total',
            isAccountsStats: this.account_stats,
            data: {
                total_partners: 0,
                total_partners_growth: 0,
                total_revenue: 0,
                total_revenue_growth: 0,
                total_reward: 0,
                total_reward_growth: 0,
                overview_program_current_settings: null
            }
        }
    },
    created() {
        this.refreshData();
    },
    methods: {
        refreshData() {

            this.loading = true;

            axios
                .post(this.dataUrl, {filter: {time_period: this.timePeriod, program: this.program}})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.data.total_partners = response.data.data.total_partners ?? 0;
                        this.data.total_partners_growth = response.data.data.total_partners_growth ?? 0;
                        this.data.total_revenue = response.data.data.total_revenue ?? 0;
                        this.data.total_revenue_growth = response.data.data.total_revenue_growth ?? 0;
                        this.data.total_reward = response.data.data.total_reward ?? 0;
                        this.data.total_reward_growth = response.data.data.total_reward_growth ?? 0;
                        this.data.overview_program_current_settings = response.data.data.overview_program_current_settings ?? null;
                    }
                })
                .catch(error => {
                    this.errors = error.response.data.errors || [];
                })
                .finally(() => this.loading = false);
        },
        timePeriodChange() {
            this.emitter.emit('affiliate-overview-time-period-updated', this.timePeriod);
            this.refreshData();
        }
    }
}
</script>

<style scoped>

</style>
