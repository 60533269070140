<template>

    <div v-if="alertGuideCompleted" class="mb-10">
        <alert type="success">
            Great! Setup guide is completed.
        </alert>
    </div>

    <content-accordion
        v-if="show_guide"
        :expanded="true"
        title="Get your Affiliate program ready"
        description="Follow this tailored guide to launch your affiliate program smoothly and effectively."
    >
        <template #accordionHeader>

            <div class="border rounded p-4 text-sm">
                <b>Not sure where to begin?</b> Check our <a href="https://www.partnero.com/tutorials/launching-your-affiliate-program-with-partnero-a-step-by-step-guide" target="_blank" class="underline hover:no-underline font-semibold">step-by-step guide</a> on launching a successful Affiliate program with Partnero!
            </div>

            <div class="flex items-center">
                <div class="w-1/3">
                    <div class="text-sm">
                        <span class="font-semibold">{{ this.stepsCompleted }}</span> out of <span class="font-semibold">{{ this.allSteps }}</span> steps completed
                    </div>
                </div>
                <div class="w-2/3 bg-gray-200 rounded-full h-3.5 dark:bg-gray-700">
                    <div class="bg-teal-500 h-3.5 rounded-full" :style="{width: progressBarWidth + '%'}"></div>
                </div>
            </div>
        </template>

        <template #accordionContent>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
                <div class="bg-white flex justify-between items-center border rounded pl-4 pt-4 pb-4 shadow-sm hover:shadow-md">
                    <div class="flex items-center">
                        <div class="bg-teal-100 text-teal-600 p-3 rounded-full">
                        <icon name="settings" size="18"></icon>
                        </div>
                        <div class="ml-4 space-y-0.5">
                            <div class="font-semibold text-sm">
                                <a href="./edit">Program Settings</a>
                            </div>
                            <div class="text-gray-500 text-sm">
                                Review and customize your program settings to match your needs.
                            </div>
                        </div>
                    </div>
                    <div class="ml-4">
                        <toggle v-model="program_settings_step" @click="editSetupGuideSettings" :disabled="loading"></toggle>
                    </div>
                </div>
                <div class="bg-white flex justify-between items-center border rounded pl-4 pt-4 pb-4 shadow-sm hover:shadow-md">
                    <div class="flex items-center">
                        <div class="bg-teal-100 text-teal-600 p-3 rounded-full">
                        <icon name="key" size="18"></icon>
                        </div>
                        <div class="ml-4">
                            <div class="font-semibold text-sm">
                                <a href="./integrations">Integration</a>
                            </div>
                            <div class="text-gray-500 text-sm">Easily integrate your application with Partnero. <a
                                href="./integrations/guides"
                                class="underline hover:no-underline">Click here</a> to view integration guides.
                            </div>
                        </div>
                    </div>
                    <div class="ml-4">
                        <toggle v-model="integration_step" @click="editSetupGuideSettings" :disabled="loading"></toggle>
                    </div>
                </div>
                <div class="bg-white flex justify-between items-center border rounded pl-4 pt-4 pb-4 shadow-sm hover:shadow-md">
                    <div class="flex items-center">
                        <div class="bg-teal-100 text-teal-600 p-3 rounded-full">
                        <icon name="monitor" size="18"></icon>
                        </div>
                        <div class="ml-4">
                            <div class="font-semibold text-sm">
                                <a href="./portal/layout">Partner Portal</a>
                            </div>
                            <div class="text-gray-500 text-sm">Review and adjust the partner portal settings to align with your design preferences.

                            </div>
                        </div>
                    </div>
                    <div class="ml-4">
                        <toggle v-model="partner_portal_step" @click="editSetupGuideSettings" :disabled="loading"></toggle>
                    </div>
                </div>
                <div class="bg-white flex justify-between items-center border rounded pl-4 pt-4 pb-4 shadow-sm hover:shadow-md">
                    <div class="flex items-center">
                        <div class="bg-teal-100 text-teal-600 p-3 rounded-full">
                        <icon name="mail-settings" size="18"></icon>
                        </div>
                        <div class="ml-4">
                            <div class="font-semibold text-sm">
                                <a href="./emails/settings">Emails</a>
                            </div>
                            <div class="text-gray-500 text-sm">Configure your email settings and review program-related emails.
                            </div>
                        </div>
                    </div>
                    <div class="ml-4">
                        <toggle v-model="emails_step" @click="editSetupGuideSettings" :disabled="loading"></toggle>
                    </div>
                </div>
            </div>

            <div class="flex items-center justify-between text-right mt-6">

                <div class="flex gap-x-1.5">
                    <span class="text-sm text-teal-600">
                        <icon name="external-link"></icon>
                    </span>
                    <a href="#" data-beacon-article="15" class="text-sm text-teal-600 underline hover:no-underline">How to set up an affiliate program</a>
                </div>

                <btn type="secondary" class="rounded-full" size="small" icon-name="close-circle"
                     @click="dismissSetupGuide">
                    Dismiss
                </btn>
            </div>
        </template>
    </content-accordion>
</template>

<script>
export default {
    name: 'SetupGuide',
    props: {
        programId: {
            type: [Number, String],
            required: true,
        }
    },
    data() {
        return {
            loading: false,
            show_guide: false,
            alertGuideCompleted: false,
            program_settings_step: true,
            integration_step: false,
            partner_portal_step: false,
            emails_step: false,

            stepsCompleted: 1,
            allSteps: 4,
            progressBarWidth: 25
        }
    },
    created() {
        this.getSetupGuideSettings();
    },
    methods: {
        getSetupGuideSettings() {
            axios.post(route('app_int.setup_guide.steps.show'), {
                program: this.programId
            }).then(response => {
                const status = parseInt(response.data.status || -1);
                if (status === 1) {
                    this.show_guide = response.data.data.show_guide;
                    let guideSteps = response.data.data.guide_steps;
                    if(guideSteps) {
                        this.program_settings_step = guideSteps.program_settings_step;
                        this.integration_step = guideSteps.integration_step;
                        this.partner_portal_step = guideSteps.partner_portal_step;
                        this.emails_step = guideSteps.emails_step;
                    }
                }
            })
            .catch(error => {
                this.errors = error?.response?.data?.errors || [];
            })
            .finally(() => this.changeProgressBarWidth());
        },
        editSetupGuideSettings () {

            if (this.loading === true) {
                return
            }
            this.loading = true;

            this.checkIfSetupGuideIsCompleted()
            this.changeProgressBarWidth()

            axios.post(route('app_int.setup_guide.steps.edit'), {
                program: this.programId,
                show_guide: this.show_guide,
                program_settings_step: this.program_settings_step,
                integration_step: this.integration_step,
                partner_portal_step: this.partner_portal_step,
                emails_step: this.emails_step
            }).then(response => {
                const status = parseInt(response.data.status || -1);
                if (status === 1 && this.alertGuideCompleted) {
                    let self = this;
                    setTimeout(function () {
                        self.alertGuideCompleted = false;
                    }, 3500);
                }
            }).finally(() => this.loading = false);
        },
        checkIfSetupGuideIsCompleted() {
            if(this.program_settings_step && this.integration_step && this.partner_portal_step && this.emails_step) {
                this.alertGuideCompleted = true;
                this.show_guide = false;
            }
        },
        changeProgressBarWidth() {
            let stepsArray = [
                this.program_settings_step,
                this.integration_step,
                this.partner_portal_step,
                this.emails_step,
            ];
            this.stepsCompleted = stepsArray.filter(Boolean).length;

            if(this.stepsCompleted === 0) {
                this.progressBarWidth = 0;
            } else {
                this.progressBarWidth = 100 * this.stepsCompleted / this.allSteps;
            }
        },
        dismissSetupGuide() {
            this.show_guide = false;
            this.editSetupGuideSettings();
            this.alertGuideCompleted = true;
            let self = this;
            setTimeout(function () {
                self.alertGuideCompleted = false;
            }, 3500);
        }
    }
}
</script>
