<script>
export default {
    data() {
        return {
            window,
            rootData: {},
        }
    }
}
</script>
