<script setup>
    import {ref} from "vue";
    import Spinner from "../../../../../elements/Spinner.vue";
    import ValidationErrors from "../../../../common/ValidationErrors.vue";

    const props = defineProps({
        program: {
            type: Object,
            required: true
        },
        coupon: {
            type: Object,
            required: true
        },
        autogeneratePromotionCodeBlueprint: {
            type: Object,
            required: true
        }
    });

    const deleteAutogeneratedPromoCodeModal = ref(null);
    const deletingAutogeneratedPromoCode = ref(false);
    const errors = ref(null);


    const emit = defineEmits(['deletedSuccessfully']);

    const deleteAutogeneratedPromoCode = () => {
        deletingAutogeneratedPromoCode.value = true;

        axios.delete(
            route('app_int.auto-assignable-promotion-code.delete', {program: props.program, coupon: props.coupon}),
            { data: { promotion_code_to_autogenerate_id: props.autogeneratePromotionCodeBlueprint.id } }
        )
            .then(response => {
                const status = parseInt(response.data.status || -1);
                if (status === 1) {
                    emit('deletedSuccessfully');
                    deleteAutogeneratedPromoCodeModal.value.onCancel();
                }
                if (status === 0) {
                    errors.value = [[response.data.message || 'Error deleting autogenerated promo code']];
                    console.error('Error deleting autogenerated promo code');
                }
            })
            .catch(error => {
                errors.value = [[error.response?.data?.errors || error.response?.data?.message || 'Error deleting autogenerated promo code']];
            })
            .finally(() => {
                deletingAutogeneratedPromoCode.value = false;
            });
    };
</script>

<template>
    <modal title="Are you sure?" size="small" ref="deleteAutogeneratedPromoCodeModal">
        <template #toggle>
            <btn type="secondary" icon-name="trash">
                Delete
            </btn>
        </template>
        <template #body>
            <div class="space-y-6">
                <validation-errors :errors="errors" />
                <div class="text-sm text-gray-500">
                    Do you really want to delete this promotion code?<br/>
                    This process cannot be undone.
                </div>
            </div>
        </template>
        <template #footer>
            <btn type="primary" @click.prevent="deleteAutogeneratedPromoCode">
                <spinner
                    v-if="deletingAutogeneratedPromoCode"
                    :is-white="true"
                    class="mr-1.5"
                />
                {{ deletingAutogeneratedPromoCode ? 'Deleting...' : 'Yes, delete' }}
            </btn>
        </template>
    </modal>
</template>

<style scoped>

</style>
