<template>
    <table class="min-w-full divide-y divide-gray-200">
        <thead>
        <tr class="h-1">
            <th :colspan="TABLE_COLUMNS_COUNT">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <th class="pl-4 w-6">
                <options
                    :isRight="false"
                    name="partner_table_options"
                >
                    <template #toggle>
                        <div class="bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                            <icon name="arrow-down-small"></icon>
                        </div>
                    </template>
                    <template #items>
                        <options-item
                            title="Select all"
                            @click="setExportSelection('selectAll')"
                        />
                        <options-item
                            title="Select visible"
                            @click="setExportSelection('selectVisible')"
                        />
                    </template>
                </options>
            </th>
            <table-header-columns-and-sorting
                :tableHeaders="tableHeaders"
            />
        </tr>
        <tr class="h-1"/>
        </thead>
        <tbody class="divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!partners || partners?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && partners?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr v-for="(partner, index) in partners"
                :key="partner.id + '-' + partner.program_id + '-' + index"
                class="hover:bg-gray-50 cursor-pointer group"
            >
                <td class="pl-4 w-6">
                    <checkbox
                        v-model="exportOptions.selectedIds"
                        :value="partner.id"
                        @click="setExportSelection('selectCustom')"
                    />
                </td>
                <td class="px-4 py-4 whitespace-nowrap font-medium text-sm text-gray-900 group-hover:text-teal-600">
                    <div class="flex items-center">
                        <div
                            class="flex-shrink-0 h-10 w-10"
                            @click="viewPartner(partner.url)"
                        >
                            <avatar :avatar-details="partner.avatar_details"/>
                        </div>
                        <div class="ml-4">
                            <div @click="viewPartner(partner.url)">
                                <span>{{ partner.full_name }}</span>
                            </div>
                            <div class="flex text-sm font-normal text-gray-500 mt-0.5">
                                <div
                                    class="mr-1.5"
                                    @click="viewPartner(partner.url)"
                                >
                                    {{ partner.email }}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td v-if="showPartnerStats(partner)"
                    class="px-2 py-4 whitespace-nowrap text-sm text-gray-900">
                <span v-if="partner.status === 'suspended'"
                      class="text-xs rounded bg-red-100 font-medium text-red-800 capitalize px-1 py-0.5">
                    Suspended
                </span>
                    <span v-else-if="partner.status === 'deleted'"
                          class="text-xs rounded bg-red-100 font-medium text-red-800 capitalize px-1 py-0.5">
                    Deleted
                </span>
                    <span v-else-if="partner.approved && partner.status === 'active'"
                          class="text-xs rounded bg-teal-100 text-teal-800 px-1 py-0.5">
                    Active
                </span>
                </td>
                <td v-if="showPartnerStats(partner)"
                    class="px-2 py-4 whitespace-nowrap text-sm text-gray-900"
                    @click="viewPartner(partner.url)"
                >
                    {{ partner.signups }}
                </td>
                <td v-if="showPartnerStats(partner)"
                    class="px-2 py-4 whitespace-nowrap text-sm text-gray-900"
                    @click="viewPartner(partner.url)"
                >
                    {{ partner.paid_customers }}
                </td>
                <td v-if="showPartnerStats(partner)"
                    class="px-2 py-4 whitespace-nowrap text-sm text-gray-900"
                    @click="viewPartner(partner.url)"
                >
                    {{ partner.sales_count }}
                </td>
                <td v-if="showPartnerStats(partner)"
                    class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    @click="viewPartner(partner.url)"
                >
                    <div v-for="reward in partner.reward">{{ reward }}</div>
                </td>
                <td v-if="showPartnerStats(partner)"
                    class="px-2 py-4 whitespace-nowrap  text-sm font-medium text-gray-900"
                    @click="viewPartner(partner.url)"
                >
                    <div v-for="revenue in partner.revenue">{{ revenue }}</div>
                </td>
                <td v-if="showPartnerStats(partner)"
                    class="pl-2 pr-4 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                    @click="viewPartner(partner.url)"
                >
                    <time :datetime="partner.joining_date_formatted">
                        {{ partner.last_active }}
                    </time>
                </td>

                <td colspan="7" class="px-2 py-4" v-if="!showPartnerStats(partner)">
                    <div class="flex items-center gap-6 justify-between">
                        <div v-if="!partner.approved">
                            <span v-if="partner.status === 'active' && !partnerHasApprovalRequest(partner)"
                                  class="text-xs rounded bg-gray-100 font-medium text-gray-800 capitalize px-1 py-0.5">
                                Pending
                            </span>
                            <span
                                v-else-if="partnerHasApprovalRequest(partner) && partner.approval_request.status === 'submitted'"
                                class="text-xs rounded bg-amber-200 text-amber-500 px-1 py-0.5">
                            Submitted for approval
                            </span>
                            <span
                                v-else-if="partner.status === 'approval_rejected'"
                                class="text-xs rounded bg-red-100 font-medium text-red-800 capitalize px-1 py-0.5">
                            Rejected
                            </span>
                        </div>
                        <partner-request-approval-modal
                            v-if="partner.approval_request?.status === 'submitted' || partner.approval_request?.status === 'rejected'"
                            :approve-request-data="partner.pending_approval_request_data"
                            :request-status="partner.approval_request.status"
                        />
                    </div>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>
import Avatar from "../../../common/Avatar";
import InitialLoadingSkeletonRows from "../../../common/InitialLoadingSkeletonRows";
import NoSearchDataRow from "../../../common/NoSearchDataRow";
import PartnerRequestApprovalModal from "./components/PartnerRequestApprovalModal";
import ProgressBar from "../../../../elements/ProgressBar";
import filtersAndPagination from "../../../../composables/common/filter-and-pagination-data";
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";
import TableHeaderColumnsAndSorting from "../../filters-and-pagination/TableHeaderColumnsAndSorting.vue";

export default {
    name: "PartnerDataTable",
    emits: ['hide-options'],
    components: {
        TableHeaderColumnsAndSorting,
        Avatar,
        InitialLoadingSkeletonRows,
        NoSearchDataRow,
        PartnerRequestApprovalModal,
        ProgressBar
    },
    props: {
        partners: {
            default: []
        },
        tableHeaders: {
            default: []
        }
    },
    setup() {
        const TABLE_COLUMNS_COUNT = 9

        const {loadingFiltersAndPaginationData} = filtersAndPagination()

        const {exportOptions, approvalSetup} = useTableDataExporter()

        return {
            loadingFiltersAndPaginationData,
            TABLE_COLUMNS_COUNT,
            exportOptions,
            approvalSetup
        }
    },
    watch: {
        'exportOptions.selectedIds' (newSelectedIds) {
            this.approvalSetup.selectedPartnersIdsToApprove = []

            if (newSelectedIds.length > 0) {
                newSelectedIds.forEach(partnerId => {
                    const partner = this.partners.find(partner => partner.id === partnerId)
                    if (partner && partner.approval_request?.status === 'submitted') {
                        this.approvalSetup.selectedPartnersIdsToApprove.push(partnerId)
                    }
                })
            }
        }
    },
    methods: {
        showPartnerStats(partner) {

            if (partner.approved) {
                return true;
            }

            if (this.partnerHasApprovalRequest(partner)) {
                return partner.approval_request.status === 'approved';
            }

            return false;
        },
        partnerHasApprovalRequest(partner) {
            return partner.approval_request !== null;
        },
        viewPartner(url) {
            window.location = url;
        },
        setExportSelection(type) {
            this.exportOptions.selectType = type;
            if (type === 'selectVisible' || type === 'selectAll') {
                this.exportOptions.selectedIds = []
                this.partners.forEach(partner => {
                    this.exportOptions.selectedIds.push(partner.id)
                })
            }
            this.emitter.emit('hide-options', 'partner_table_options');
        }
    }
}
</script>
