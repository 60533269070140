<template>
    <div class="px-10 divide-y divide-gray-200">
        <!-- Settings Section -->
        <form-section title="Settings" description="Configure how the partner sign-up popup will appear on your website.">
            <div class="grid grid-cols-1 gap-12">

                <div class="flex justify-start gap-6">
                    <div class="group bg-gray-50 w-28 h-28 border rounded flex flex-shrink-0 relative cursor-pointer">
                        <img
                            v-if="!isPreviewImageBroken"
                            :src="previewImageUrl + '?t=' + Date.now()"
                            alt="Signup popup preview"
                            class="absolute inset-0 w-full h-full object-cover"
                            @error="handlePreviewImageError"
                            @load="handlePreviewImageLoad"
                        >
                        <img v-else src="/images/partner-signup/popup_preview.png" alt="Signup popup preview" class="absolute inset-0 w-full h-full object-cover">

                        <div v-if="isPreviewLoading" class="absolute inset-0 flex items-center justify-center">
                            <icon name="spinner" size="24" class="animate-spin"></icon>
                        </div>
                        <div v-else @click="showPreview" class="invisible group-hover:visible absolute inset-0 flex items-center justify-center">
                            <icon name="eye"></icon>
                        </div>
                    </div>

                    <div>
                        <div class="mb-4">
                            <div class="font-semibold text-sm">Partner Signup Pop-Up</div>
                            <div class="text-sm text-gray-500">
                                Easily collect partner signups directly from your website with a user-friendly pop-up.
                            </div>
                        </div>
                        <toggle
                            :name="SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_ENABLED"
                            v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_ENABLED]"
                            title="Trigger pop-up automatically"
                        >
                            <template #description>
                                Automatically display a pop-up to website visitors based on the trigger settings.
                            </template>
                        </toggle>
                    </div>

                </div>


                <div v-show="isTriggerEnabled">
                    <label class="block text-sm leading-5 mb-1.5">Trigger settings & Frequency</label>
                    <div class="grid gap-2 md:grid-cols-1 border rounded p-4">
                        <div class="flex justify-start items-center space-x-3">
                            <checkbox
                                :name="SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_WAIT_ENABLED"
                                v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_WAIT_ENABLED]"
                                value="on"
                            ></checkbox>
                            <span class="text-sm">Wait</span>
                            <text-field
                                :name="SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_WAIT_SECONDS"
                                class="w-12"
                                v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_WAIT_SECONDS]"
                            ></text-field>
                            <span class="text-sm">seconds before showing the pop-up</span>
                        </div>

                        <div class="flex justify-start items-center space-x-3">
                            <checkbox
                                :name="SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_SCROLL_ENABLED"
                                v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_SCROLL_ENABLED]"
                                value="on"
                            ></checkbox>
                            <span class="text-sm">Show when the visitor scrolls to</span>
                            <select-field
                                :name="SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_SCROLL_PERCENTAGE"
                                v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_SCROLL_PERCENTAGE]"
                            >
                                <option value="50">50%</option>
                                <option value="75">75%</option>
                                <option value="100">100%</option>
                            </select-field>
                        </div>

                        <div class="flex justify-start items-center space-x-3">
                            <checkbox
                                :name="SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_BEFORE_CLOSING_ENABLED"
                                v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_BEFORE_CLOSING_ENABLED]"
                                value="on"
                            ></checkbox>
                            <span class="text-sm">Show before closing the page</span>
                        </div>

                        <div class="flex justify-start items-center space-x-3 border-t pt-4 mt-2">
                            <span class="text-sm">Show the pop-up again after</span>
                            <text-field
                                v-if="settings[SETTING_PORTAL_SIGNUP_POPUP_FREQUENCY_UNIT] !== 'always'"
                                :name="SETTING_PORTAL_SIGNUP_POPUP_FREQUENCY_VALUE"
                                v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_FREQUENCY_VALUE]"
                                class="w-12"
                            ></text-field>
                            <select-field
                                :name="SETTING_PORTAL_SIGNUP_POPUP_FREQUENCY_UNIT"
                                v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_FREQUENCY_UNIT]"
                            >
                                <option value="day">day/(-s)</option>
                                <option value="week">week/(-s)</option>
                                <option value="month">month/(-s)</option>
                                <option value="always">always show</option>
                            </select-field>
                        </div>
                    </div>
                    <small class="text-gray-500 mt-1 block">
                        Define when the pop-up should appear and set conditions for when it will reappear for the same visitor.
                    </small>
                </div>

                <signup-popup-visibility-settings
                    v-show="isTriggerEnabled"
                    v-model:visibility-type="settings[SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE]"
                    v-model:visibility-device-settings="settings[SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS]"
                    v-model:visibility-type-specific-pages="settings[SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_SPECIFIC_PAGES]"
                    :error="errors[SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE]"
                ></signup-popup-visibility-settings>
            </div>
        </form-section>

        <!-- Appearance Section -->
        <form-section title="Appearance" description="The styling for default elements of your partner sign-up popup.">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">

                <radio-group-inline
                    label="Branding"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_BRANDING_TYPE"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_BRANDING_TYPE]"
                    :options="[
                        { value: 'none', label: 'None' },
                        { value: 'logo', label: 'Logo' },
                        { value: 'symbol', label: 'Symbol' },
                    ]"
                ></radio-group-inline>

                <radio-group-inline
                    label="Layout"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_LAYOUT_TYPE"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_LAYOUT_TYPE]"
                    :options="[
                        { value: 'default', label: 'Default' },
                        { value: 'sidebar', label: 'Sidebar', disabled: isRedirectToSignUpPage },
                    ]"
                >
                    <template #note>
                        <template v-if="isRedirectToSignUpPage">
                            The sidebar layout is not available when redirecting to the sign-up page.
                        </template>
                    </template>
                </radio-group-inline>

                <radio-group-inline-color
                    :name="SETTING_PORTAL_SIGNUP_POPUP_BACKGROUND_COLOR"
                    label="Background color"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_BACKGROUND_COLOR]"
                    :options="[
                        { value: 'white', label: 'bg-white', label_is_class: true },
                        { value: 'gray-50', label: 'bg-gray-50', label_is_class: true },
                        { value: 'gray-100', label: 'bg-gray-100', label_is_class: true },
                        { value: 'gray-200', label: 'bg-gray-200', label_is_class: true },
                        { value: 'gray-300', label: 'bg-gray-300', label_is_class: true },
                        { value: 'custom', label: 'Custom', custom: { name: SETTING_PORTAL_SIGNUP_POPUP_BACKGROUND_COLOR, placeholder: '', type: 'input' } },
                    ]"
                ></radio-group-inline-color>

                <radio-group-inline-color
                    label="Background gradient color"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_GRADIENT_COLOR"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_GRADIENT_COLOR]"
                    :options="[
                        { value: 'black', label: 'bg-black', label_is_class: true },
                        { value: 'gray-300', label: 'bg-gray-300', label_is_class: true },
                        { value: 'blue-700', label: 'bg-blue-700', label_is_class: true },
                        { value: 'teal-600', label: 'bg-teal-600', label_is_class: true },
                        { value: 'none', label: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEwIi8+PGxpbmUgeDE9IjQuOTMiIHkxPSI0LjkzIiB4Mj0iMTkuMDciIHkyPSIxOS4wNyIvPjwvc3ZnPg==', label_is_img: true},
                        { value: 'custom', label: 'Custom', custom: { name: SETTING_PORTAL_SIGNUP_POPUP_GRADIENT_COLOR, placeholder: '', type: 'input' } },
                    ]"
                ></radio-group-inline-color>

                <radio-group-inline-color
                    label="Content text color"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_CONTENT_TEXT_COLOR"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_CONTENT_TEXT_COLOR]"
                    :options="[
                        { value: 'gray-900', label: 'bg-gray-900', label_is_class: true },
                        { value: 'gray-800', label: 'bg-gray-800', label_is_class: true },
                        { value: 'gray-700', label: 'bg-gray-700', label_is_class: true },
                        { value: 'gray-600', label: 'bg-gray-600', label_is_class: true },
                        { value: 'gray-500', label: 'bg-gray-500', label_is_class: true },
                        { value: 'custom', label: 'Custom', custom: { name: SETTING_PORTAL_SIGNUP_POPUP_CONTENT_TEXT_COLOR, placeholder: '', type: 'input' } },
                    ]"
                ></radio-group-inline-color>

                <radio-group-inline-color
                    label="Button color"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_BUTTON_COLOR"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_BUTTON_COLOR]"
                    :options="[
                        { value: 'indigo', label: 'bg-indigo-700', label_is_class: true },
                        { value: 'blue', label: 'bg-blue-700', label_is_class: true },
                        { value: 'cyan', label: 'bg-cyan-700', label_is_class: true },
                        { value: 'teal', label: 'bg-teal-600', label_is_class: true },
                        { value: 'black', label: 'bg-black', label_is_class: true },
                        { value: 'custom', label: 'Custom', custom: { name: SETTING_PORTAL_SIGNUP_POPUP_BUTTON_COLOR, placeholder: '', type: 'input' } },
                    ]"
                ></radio-group-inline-color>

                <radio-group-inline
                    label="Input style"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_INPUT_STYLE"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_INPUT_STYLE]"
                    :options="[
                        { value: 'white', label: 'Default' },
                        { value: 'shadow', label: 'Shadow' },
                        { value: 'transparent', label: 'Transparent' }
                    ]"
                ></radio-group-inline>
            </div>
        </form-section>

        <!-- Content Section -->
        <form-section title="Content" description="Partner sign-up popup form and additional content.">

            <div>
                <tabs>
                    <tab @click="setActiveTab(CONTENT_TAB_POPUP)" :class='{"border-b-4": activeTab === CONTENT_TAB_POPUP}'>
                        Pop-up
                    </tab>
                    <tab @click="setActiveTab(CONTENT_TAB_CONFIRMATION)" :class='{"border-b-4": activeTab === CONTENT_TAB_CONFIRMATION}'>
                        Confirmation
                    </tab>
                </tabs>
            </div>

            <div class="space-y-8" v-show="activeTab === CONTENT_TAB_POPUP">

                <div class="space-y-4">
                    <text-field
                        label="Title"
                        :field-id="SETTING_PORTAL_SIGNUP_POPUP_TITLE"
                        :name="SETTING_PORTAL_SIGNUP_POPUP_TITLE"
                        type="text"
                        v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_TITLE]"
                        :error="errors[SETTING_PORTAL_SIGNUP_POPUP_TITLE]"
                    ></text-field>
                    <text-field
                        label="Subtitle"
                        placeholder="Optional. E.g. Join, Promote, Earn!"
                        :field-id="SETTING_PORTAL_SIGNUP_POPUP_SUBTITLE"
                        :name="SETTING_PORTAL_SIGNUP_POPUP_SUBTITLE"
                        type="text"
                        v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_SUBTITLE]"
                        :error="errors[SETTING_PORTAL_SIGNUP_POPUP_SUBTITLE]"
                    ></text-field>
                    <text-editor
                        :name="SETTING_PORTAL_SIGNUP_POPUP_DESCRIPTION"
                        :text="settings[SETTING_PORTAL_SIGNUP_POPUP_DESCRIPTION]"
                        :error="errors[SETTING_PORTAL_SIGNUP_POPUP_DESCRIPTION]"
                    ></text-editor>
                </div>

                <fields-builder
                    v-if="!isRedirectToSignUpPage"
                    name="signup_popup_fields"
                    :value="formFields"
                    zero-fields-message="Add fields to the signup popup."
                    form-type="signup_popup"
                ></fields-builder>

                <text-field
                    label="Button"
                    :field-id="SETTING_PORTAL_SIGNUP_POPUP_BUTTON_TEXT"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_BUTTON_TEXT"
                    type="text"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_BUTTON_TEXT]"
                    :error="errors[SETTING_PORTAL_SIGNUP_POPUP_BUTTON_TEXT]"
                ></text-field>

                <toggle
                    v-if="settings[SETTING_PORTAL_SIGNUP_POPUP_LAYOUT_TYPE] !== 'sidebar'"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_REDIRECT_TO_SIGN_UP_PAGE"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_REDIRECT_TO_SIGN_UP_PAGE]"
                    title="Redirect to sign-up page"
                >
                    <template #description>
                        Redirect visitors to the dedicated sign-up page rather than embedding the sign-up form.
                    </template>
                </toggle>

                <div v-show="!isRedirectToSignUpPage">
                    <settings-accordion
                        label="Additional settings"
                        :placeholder="additionalSettingsSummary"
                        :opened="(errors[SETTING_PORTAL_SIGNUP_POPUP_MARKETING_CONSENT_TEXT] || errors[SETTING_PORTAL_SIGNUP_POPUP_TOS_URL] || errors[SETTING_PORTAL_SIGNUP_POPUP_TOS_SAVED_LOCALLY] || errors[SETTING_PORTAL_SIGNUP_POPUP_TOS_TEXT]) ? true : false"
                    >
                        <div class="space-y-4">
                            <text-field
                                label="Marketing consent"
                                :field-id="SETTING_PORTAL_SIGNUP_POPUP_MARKETING_CONSENT_TEXT"
                                placeholder="E.g. I'd like to receive updates regarding my affiliate account"
                                type="text"
                                :name="SETTING_PORTAL_SIGNUP_POPUP_MARKETING_CONSENT_TEXT"
                                v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_MARKETING_CONSENT_TEXT]"
                                :error="errors[SETTING_PORTAL_SIGNUP_POPUP_MARKETING_CONSENT_TEXT]"
                            >
                                <template #note>
                                    Once text is entered, a checkbox will appear on your signup form.
                                </template>
                            </text-field>
                            <portal-terms-of-services
                                v-model:tos-url="settings[SETTING_PORTAL_SIGNUP_POPUP_TOS_URL]"
                                v-model:tos-save="settings[SETTING_PORTAL_SIGNUP_POPUP_TOS_SAVED_LOCALLY]"
                                v-model:tos-text="settings[SETTING_PORTAL_SIGNUP_POPUP_TOS_TEXT]"
                                tos-url-label="Terms of Use URL"
                                :tos-url-name="SETTING_PORTAL_SIGNUP_POPUP_TOS_URL"
                                :tos-save-toggle-name="SETTING_PORTAL_SIGNUP_POPUP_TOS_SAVED_LOCALLY"
                                tos-save-toggle-label="Save program terms on Partnero"
                                tos-save-toggle-description=""
                                tos-text-label="Terms of Use text"
                                :tos-text-name="SETTING_PORTAL_SIGNUP_POPUP_TOS_TEXT"
                            >
                            </portal-terms-of-services>
                        </div>
                    </settings-accordion>
                </div>

            </div>

            <div class="space-y-6" v-show="activeTab === CONTENT_TAB_CONFIRMATION">
                <text-field
                    label="Title"
                    :field-id="SETTING_PORTAL_SIGNUP_POPUP_THANK_YOU_TITLE"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_THANK_YOU_TITLE"
                    type="text"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_THANK_YOU_TITLE]"
                    :error="errors[SETTING_PORTAL_SIGNUP_POPUP_THANK_YOU_TITLE]"
                ></text-field>

                <text-editor
                    label="Description"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_THANK_YOU_DESCRIPTION"
                    :text="settings[SETTING_PORTAL_SIGNUP_POPUP_THANK_YOU_DESCRIPTION]"
                    :error="errors[SETTING_PORTAL_SIGNUP_POPUP_THANK_YOU_DESCRIPTION]"
                ></text-editor>

                <toggle
                    :name="SETTING_PORTAL_SIGNUP_POPUP_REDIRECT_TO_LOGIN_AFTER_SIGNUP"
                    v-model="settings[SETTING_PORTAL_SIGNUP_POPUP_REDIRECT_TO_LOGIN_AFTER_SIGNUP]"
                    title="Redirect to login page"
                >
                    <template #description>
                        Redirect visitors to the dedicated login page after they sign up.
                    </template>
                </toggle>
            </div>

        </form-section>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import SignupPopupVisibilitySettings from './signup-popup/VisibilitySettings.vue'

// Constants matching PHP SignupPopup class
const SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_ENABLED = 'portal_signup_popup_trigger_enabled'
const SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_WAIT_ENABLED = 'portal_signup_popup_trigger_wait_enabled'
const SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_WAIT_SECONDS = 'portal_signup_popup_trigger_wait_seconds'
const SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_SCROLL_ENABLED = 'portal_signup_popup_trigger_scroll_enabled'
const SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_SCROLL_PERCENTAGE = 'portal_signup_popup_trigger_scroll_percentage'
const SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_BEFORE_CLOSING_ENABLED = 'portal_signup_popup_trigger_before_closing_enabled'
const SETTING_PORTAL_SIGNUP_POPUP_FREQUENCY_VALUE = 'portal_signup_popup_frequency_value'
const SETTING_PORTAL_SIGNUP_POPUP_FREQUENCY_UNIT = 'portal_signup_popup_frequency_unit'
const SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE = 'portal_signup_popup_visibility_type'
const SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS = 'portal_signup_popup_visibility_device_settings'
const SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_SPECIFIC_PAGES = 'portal_signup_popup_visibility_type_specific_pages'
const SETTING_PORTAL_SIGNUP_POPUP_LAYOUT_TYPE = 'portal_signup_popup_layout_type'
const SETTING_PORTAL_SIGNUP_POPUP_BRANDING_TYPE = 'portal_signup_popup_branding_type'
const SETTING_PORTAL_SIGNUP_POPUP_BACKGROUND_COLOR = 'portal_signup_popup_background_color'
const SETTING_PORTAL_SIGNUP_POPUP_CONTENT_TEXT_COLOR = 'portal_signup_popup_content_text_color'
const SETTING_PORTAL_SIGNUP_POPUP_BUTTON_COLOR = 'portal_signup_popup_button_color'
const SETTING_PORTAL_SIGNUP_POPUP_GRADIENT_COLOR = 'portal_signup_popup_gradient_color'
const SETTING_PORTAL_SIGNUP_POPUP_INPUT_STYLE = 'portal_signup_popup_input_style'
const SETTING_PORTAL_SIGNUP_POPUP_TITLE = 'portal_signup_popup_title'
const SETTING_PORTAL_SIGNUP_POPUP_SUBTITLE = 'portal_signup_popup_subtitle'
const SETTING_PORTAL_SIGNUP_POPUP_DESCRIPTION = 'portal_signup_popup_description'
const SETTING_PORTAL_SIGNUP_POPUP_BUTTON_TEXT = 'portal_signup_popup_button_text'
const SETTING_PORTAL_SIGNUP_POPUP_REDIRECT_TO_SIGN_UP_PAGE = 'portal_signup_popup_redirect_to_sign_up_page'
const SETTING_PORTAL_SIGNUP_POPUP_TOS_URL = 'portal_signup_popup_tos_url'
const SETTING_PORTAL_SIGNUP_POPUP_TOS_SAVED_LOCALLY = 'portal_signup_popup_tos_saved_locally'
const SETTING_PORTAL_SIGNUP_POPUP_TOS_TEXT = 'portal_signup_popup_tos_text'
const SETTING_PORTAL_SIGNUP_POPUP_MARKETING_CONSENT_TEXT = 'portal_signup_popup_marketing_consent_text'

const SETTING_PORTAL_SIGNUP_POPUP_THANK_YOU_TITLE = 'portal_signup_popup_thank_you_title'
const SETTING_PORTAL_SIGNUP_POPUP_THANK_YOU_DESCRIPTION = 'portal_signup_popup_thank_you_description'
const SETTING_PORTAL_SIGNUP_POPUP_REDIRECT_TO_LOGIN_AFTER_SIGNUP = 'portal_signup_popup_redirect_to_login_after_signup'

const props = defineProps({
    programSettings: {
        type: Object,
        required: true
    },
    signupPopupFormFields: {
        type: Array,
        required: true
    },
    errors: {
        type: Object,
        default: () => ({
            visibility: false,
            title: false,
            description: false,
            button_text: false
        })
    },
    previewImageUrl: {
        type: String,
        default: ''
    },
    previewUrl: {
        type: String,
        required: true
    }
})

const CONTENT_TAB_POPUP = 'content-tab-popup'
const CONTENT_TAB_CONFIRMATION = 'content-tab-confirmation'

// Reactive refs
const settings = ref({ ...props.programSettings })
const formFields = ref([...props.signupPopupFormFields])
const activeTab = ref(CONTENT_TAB_POPUP)
const isTriggerEnabled = computed(() => ['on', true].includes(settings.value[SETTING_PORTAL_SIGNUP_POPUP_TRIGGER_ENABLED]))
const isRedirectToSignUpPage = computed(() => ['on', true].includes(settings.value[SETTING_PORTAL_SIGNUP_POPUP_REDIRECT_TO_SIGN_UP_PAGE]))

const setActiveTab = (tab) => {
    activeTab.value = tab
}

const isPreviewLoading = ref(false);
const isPreviewImageBroken = ref(false);

const handlePreviewImageError = () => {
    isPreviewImageBroken.value = true;
};

const handlePreviewImageLoad = () => {
    isPreviewImageBroken.value = false;
};

const showPreview = () => {

    if (isPreviewLoading.value) {
        return;
    }

    isPreviewLoading.value = true;

    const iframe = document.createElement('iframe');
    iframe.id = 'po-signup-popup-iframe';
    iframe.className = 'fixed inset-0 w-full h-full border-0 z-[99999999] hidden';
    iframe.src = props.previewUrl;
    iframe.title = 'Preview';

    document.body.appendChild(iframe);

    // Define message handler
    const handleMessage = (event) => {
        if (event.data?.type === 'po:partner-signup-popup:close') {
            iframe.remove();
            // Remove event listener when popup is closed
            window.removeEventListener('message', handleMessage);
        }
        if (event.data?.type === 'po:partner-signup-popup:loaded') {
            isPreviewLoading.value = false;
            iframe.classList.remove('hidden');
        }
    };

    // Add event listener
    window.addEventListener('message', handleMessage);
}

const additionalSettingsSummary = computed(() => {
    const summary = [];

    // Marketing consent status
    const hasMarketingConsent = settings.value[SETTING_PORTAL_SIGNUP_POPUP_MARKETING_CONSENT_TEXT]?.trim();
    summary.push(`Marketing consent: <strong>${hasMarketingConsent ? 'Enabled' : 'Disabled'}</strong>`);

    // Terms of use status
    const hasTermsOfUse = settings.value[SETTING_PORTAL_SIGNUP_POPUP_TOS_URL]?.trim() || settings.value[SETTING_PORTAL_SIGNUP_POPUP_TOS_SAVED_LOCALLY] === 'on';
    summary.push(`Terms of use: <strong>${hasTermsOfUse ? 'Enabled' : 'Disabled'}</strong>`);

    return summary.join('<br>');
});
</script>
