<template>
    <modal :title="'Change subject'" size="small" name="email_subject_update">
        <template #toggle>
            <options-item title="Change subject" />
        </template>
        <template #body>
            <div class="space-y-4">
                <div>
                    <label class="block text-sm opacity-80 mb-1.5">Email subject</label>
                    <text-field
                        :model-value="emailSubject"
                        @update:modelValue="emailSubject = $event"
                    />
                </div>
            </div>
        </template>
        <template #footer>
            <btn
                :loading="emailStore.get_updating_subject"
                :disabled="emailStore.get_updating_subject"
                @click="emailStore.updateSubject(emailSubject)"
            >
                Update
            </btn>
        </template>
    </modal>
</template>

<script setup>
import {useRevolvappEmailStore} from "../../../../../../stores/app/emails/revolvapp/RevolvAppEmailStore";
import {ref, watch} from "vue";

const emailStore = useRevolvappEmailStore()

let emailSubject = ref('')

watch(() => emailStore.get_email_subject, (newValue, oldValue) => {
    emailSubject.value = newValue
})
</script>
