<script setup>
    import LanguagePrefillSelectConfirm from "./LanguagePrefillSelectConfirm.vue";
    import {ref} from "vue";


    const props = defineProps({
        translationSettingsConfig: {
            type: Array,
            required: true,
        },
        availableTranslations: {
            type: Array,
            required: true,
        },
    });

    const selectedTabIndex = ref(0);

    const selectedTranslation = ref('');

</script>

<template>

    <nav class="bg-gray-100 rounded py-3 px-4 mx-4 mt-4 space-x-3 flex overflow-auto" aria-label="Tabs">
        <p v-for="(tab, index) in props.translationSettingsConfig"
           :key="tab"
           @click="selectedTabIndex = index"
           class="text-gray-900 text-sm px-2.5 py-1 inline-flex items-center justify-center whitespace-nowrap cursor-pointer"
           :class="{
           'font-semibold bg-white rounded': index === selectedTabIndex,
           'hover:text-teal-600': index !== selectedTabIndex,
        }"
        >
            {{ tab.label }}
        </p>
    </nav>

    <language-prefill-select-confirm
        :available-translations="props.availableTranslations"
        v-model="selectedTranslation"
    ></language-prefill-select-confirm>

    <div v-for="(tab, index) in props.translationSettingsConfig" class="px-10 divide-y divide-gray-200">
        <template v-for="group in tab['translations']">
            <div v-show="index === selectedTabIndex" class="grid gap-12 py-14 xl:grid-cols-3">
                <div class="space-y-2">
                    <h3 class="text-lg font-medium">
                        {{ group['label'] }}
                    </h3>
                </div>

                <div class="flex grid md:grid-cols-2 col-span-2 gap-8 gap-y-6 items-center">
                    <template v-for="field in group['fields']">

                        <div class="text-sm text-gray-500">
                            {{ field['en'] }}
                        </div>
                        <text-field
                            :name="field['setting']"
                            type="text"
                            :model-value="selectedTranslation ? field[selectedTranslation] : field['model_value']"
                        ></text-field>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped>

</style>
