<template>
    <div class="grid xl:grid-cols-3 gap-12 py-10 lg:py-14">
        <div class="space-y-2">
            <h3 class="text-lg font-medium">{{ title }}</h3>
            <p class="text-gray-500 text-sm">{{ description }}</p>
        </div>
        <div class="col-span-2 space-y-8">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        description: {
            type: String,
        },
    },
}
</script>
