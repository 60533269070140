<template>
    <tr>
        <td
            :colspan="numberOfTableColumns"
            class="px-4 py-4 whitespace-nowrap text-gray-500 text-sm font-medium text-center"
        >
            No results.
        </td>
    </tr>
</template>

<script>
export default {
    name: "NoSearchDataRow",
    props: {
        numberOfTableColumns: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
