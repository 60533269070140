<template>

    <div class="space-y-2">

        <table class="min-w-full border rounded divide-y divide-gray-200"
               v-if="paymentMethods && paymentMethods.length > 0"
        >
            <thead>
                <tr>
                    <th scope="col" class="pl-4 pr-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Card number
                    </th>
                    <th scope="col" class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Expires
                    </th>
                    <th scope="col" class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Default
                    </th>
                    <th scope="col" class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider"></th>
                </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
                <tr v-for="(card, index) in paymentMethods" class="hover:bg-gray-50 cursor-pointer group">
                    <td class="pl-4 pr-4 py-2 whitespace-nowrap font-normal text-sm">
                        {{ card.brand }} {{ card.last4 }}
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ card.exp_year }}.{{ expireMonth(card.exp_month) }}
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                        <badge type="success" title="Default" v-if="card.is_default ?? null"></badge>
                    </td>
                    <td class="pr-4 py-2 flex justify-end items-center">
                        <options>
                            <template #toggle>
                                <btn type="secondary" icon-name="more" size="small" class="h-full"></btn>
                            </template>
                            <template #items>
                                <div v-if="!card.is_default">

                                    <modal title="Set as default" size="small" name="set-payment-method-as-default-modal">
                                        <template #toggle>
                                            <options-item title="Set as default"></options-item>
                                        </template>
                                        <template #body>
                                            <div class="space-y-4">Set as default?</div>
                                        </template>
                                        <template #footer>
                                            <btn @click="setCardAsDefault(card, index)"
                                                 :loading="loading.setting_as_default">
                                                Set as default
                                            </btn>
                                        </template>
                                    </modal>
                                </div>
                                <div>
                                    <options-divider v-if="!card.is_default"></options-divider>
                                    <modal title="Are you sure?" size="small" name="delete-payment-method-modal">
                                        <template #toggle>
                                            <options-item title="Delete"></options-item>
                                        </template>
                                        <template #body>
                                            <div class="space-y-4">
                                                <div class="text-sm text-gray-500">
                                                    Are you sure you want to delete? <br/>
                                                    This process cannot be undone.
                                                </div>
                                            </div>
                                        </template>
                                        <template #footer>
                                            <btn @click="deleteCard(card, index)" :loading="loading.deleting_card"
                                                 type="primary">Yes,
                                                delete
                                            </btn>
                                        </template>
                                    </modal>
                                </div>
                            </template>
                        </options>
                    </td>
                </tr>
            </tbody>
        </table>

        <modal v-if="stripeLoaded" name="stripe-add-payment-card" title="New payment method" size="small">
            <template #toggle>
                <btn type="secondary" icon-name="add-circle" @click="prepNewCard()">
                    New payment method
                </btn>
            </template>
            <template #body>
                <alert type="error" class="mb-5" v-if="errors.add_card">{{ errors.add_card }}</alert>
                <alert type="error" class="mb-5" v-if="errors.prep_add_card">{{ errors.prep_add_card }}</alert>
                <div id="new-card-element"/>
                <div v-if="loading.prep_add_card" class="inline-flex gap-1">
                    <icon name="spinner"/>
                    <span class="text-sm">Loading</span>
                </div>
            </template>
            <template #footer>
                <btn @click="addCard" :loading="loading.adding_new_card"
                     :disabled="loading.adding_new_card || !newCardIntentLoaded">
                    Confirm
                </btn>
            </template>
        </modal>

    </div>
</template>
<script setup>

import {ref, onMounted, inject} from "vue";
import {loadStripe} from '@stripe/stripe-js';

const props = defineProps({
    program: {
        type: [String, Number],
        required: true
    },
    config: {
        type: Object,
        required: true
    },
    notificationModal: {
        type: Object
    }
});

const emitter = inject('emitter');

let stripe = null;
let stripeIntent = null;
let newStripeCard = null;

const config = ref(props.config);
const stripeLoaded = ref(false);
const newCardIntentLoaded = ref(false);
const paymentMethods = ref(config.value.cards);

const loading = ref({
    'prep_add_card': false,
    'deleting_card': false,
    'adding_new_card': false,
    'setting_as_default': false,
});

const errors = ref({
    'add_card': null,
    'prep_add_card': null,
});

onMounted(() => {
    loadStripe(config.value.credentials.key)
        .then(function (_stripe) {
            stripe = _stripe;
            stripeLoaded.value = true;
        });
});

const prepNewCard = () => {

    stripeIntent = null;
    newStripeCard = null;

    errors.value.prep_add_card = false;
    if (loading.value.prep_add_card === true) {
        return;
    }
    loading.value.prep_add_card = true;

    axios.get(route('app_int.intermediary_payouts.charge_option.stripe.get_setup_intent', {program: props.program}))
        .then(response => {
            const status = parseInt(response.data.status ?? -1);
            if (status === 1) {
                const elements = stripe.elements();

                stripeIntent = response.data.setup_intent;
                newStripeCard = elements.create('card', {'hidePostalCode': true});

                newStripeCard.mount('#new-card-element');
                newCardIntentLoaded.value = true;
            }
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => loading.value.prep_add_card = false);
}

async function addCard() {

    errors.value.adding_new_card = false;
    if (loading.value.adding_new_card === true) {
        return;
    }
    loading.value.adding_new_card = true;

    const card = newStripeCard;
    await stripe.confirmCardSetup(stripeIntent.client_secret, {payment_method: {card}})
        .then((result) => {
            if (result.error) {
                loading.value.adding_new_card = false;
            } else {
                axios.post(
                    route('app_int.intermediary_payouts.charge_option.stripe.payment_method_added', {program: props.program}),
                    {'payment_method_id': result.setupIntent.payment_method}
                ).then((response) => {

                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {

                        const addedCard = response.data.payment_method;

                        paymentMethods.value.push({
                            type: 'card',
                            id: response.data.payment_method.id,
                            last4: addedCard.last4,
                            exp_month: addedCard.exp_month >= 10 ? addedCard.exp_month : `${addedCard.exp_month}`,
                            exp_year: addedCard.exp_year,
                            brand: addedCard.brand
                        });

                        newStripeCard = null;

                        if (props.notificationModal) {
                            props.notificationModal.show = true;
                            props.notificationModal.type = 'success';
                            props.notificationModal.title = 'Payment msethod added';
                            props.notificationModal.message = 'Payment method added successfully';
                        }
                    }
                }).finally(() => loading.value.adding_new_card = false);

                emitter.emit('modal-off', 'stripe-add-payment-card');
            }
        })
        .catch((error) => console.error(error))
        .finally(function () {
            loading.value.adding_new_card = false;
        });

}

const deleteCard = (card, index) => {

    if (loading.value.deleting_card === true) {
        return;
    }
    loading.value.deleting_card = true;

    axios.post(
        route('app_int.intermediary_payouts.charge_option.stripe.remove_payment_method', {program: props.program}),
        {'payment_method_id': card.id}
    ).then((response) => {

        const status = parseInt(response.data.status ?? -1);
        if (status === 1) {
            paymentMethods.value.splice(index, 1);
            emitter.emit('modal-off', 'delete-payment-method-modal');

            if (props.notificationModal) {
                props.notificationModal.show = true;
                props.notificationModal.type = 'success';
                props.notificationModal.title = 'Payment method deleted';
                props.notificationModal.message = 'Payment method successfully deleted';
            }
        }
    }).finally(() => loading.value.deleting_card = false);

};

const setCardAsDefault = (card, index) => {

    if (loading.value.setting_as_default === true) {
        return;
    }
    loading.value.setting_as_default = true;

    axios.post(
        route('app_int.intermediary_payouts.charge_option.stripe.set_payment_method_as_default', {program: props.program}),
        {'payment_method_id': card.id},
    ).then((response) => {

        const status = parseInt(response.data.status ?? -1);
        if (status === 1) {

            paymentMethods.value.forEach((card) => {
                card.is_default = false;
            });

            paymentMethods.value[index]['is_default'] = response.data.is_default ?? false;

            if (props.notificationModal && (response.data.is_default ?? false)) {
                props.notificationModal.show = true;
                props.notificationModal.type = 'success';
                props.notificationModal.title = 'Default payment method changed';
                props.notificationModal.message = 'Default payment method successfully changed';
            }

            emitter.emit('modal-off', 'set-payment-method-as-default-modal');
        }
    }).finally(() => loading.value.setting_as_default = false);
};

const expireMonth = (month) => {
    if (month < 10) {
        return "0" + month;
    }

    return month;
}

</script>

<style scoped></style>
