<template>
    <filters-and-pagination
        :pagination-data="filtersAndPaginationData"
        :filterable-fields="filterFields"
        :data-exists="initialDataState"
        :loading-data="loadingFiltersAndPaginationData"
        model-name="partner"
    >
        <partner-data-table :partners="filtersAndPaginationData.data" :table-headers="tableHeaders" />
    </filters-and-pagination>
</template>

<script>
import FiltersAndPagination from "../../filters-and-pagination/FiltersAndPagination";
import ReferralDataTable from "../referrals/components/ReferralDataTable";
import {onMounted, ref, toRef, watch} from "vue";
import useRecentActivities from "../../../../composables/common/filter-and-pagination-data";
import useFiltersAndPagination from "../../../../state/common/filters-and-pagination";
import PartnerDataTable from "./PartnerDataTable";
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";

export default {
    name: "PartnerIndex",

    components: {
        PartnerDataTable,
        FiltersAndPagination,
        ReferralDataTable
    },

    props: {
        partnerUrl: {
            default: ''
        },
        partnerExportUrl: {
            default: ''
        },
        partnerDeleteUrl: {
            default: ''
        },
        partnerApprovalUrl: {
            default: ''
        },
        filterFields: {
            default: {}
        },
        tableHeaders: {
            default: []
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        },
        unapprovedPartnersExistWithinProgram: {
            type: [Boolean, Number],
            default: false
        }
    },

    setup(props) {
        const partnerUrl = toRef(props, 'partnerUrl')
        const partnerExportUrl = toRef(props, 'partnerExportUrl')
        const partnerDeleteUrl = toRef(props, 'partnerDeleteUrl')
        const partnerApprovalUrl = toRef(props, 'partnerApprovalUrl')
        const filterFields = toRef(props, 'filterFields')
        const { loadingFiltersAndPaginationData, filtersAndPaginationData, getFiltersAndPaginationData } = useRecentActivities()
        const { setDataBaseUrl, setFetchDataFunction } = useFiltersAndPagination()
        const { exportSetup, checkForPreviousExport, deleteSetup, approvalSetup } = useTableDataExporter()

        const initialDataState = ref(!!props.dataExists)

        onMounted( () => {
            setDataBaseUrl(partnerUrl.value)
            setFetchDataFunction(getFiltersAndPaginationData)

            exportSetup.value.exportModelNameSingular = 'partner'
            exportSetup.value.exportModelNamePlural = 'partners'
            exportSetup.value.exportUrl = partnerExportUrl.value
            deleteSetup.value.deleteUrl = partnerDeleteUrl.value
            approvalSetup.value.approvalUrl = partnerApprovalUrl.value
            approvalSetup.value.unapprovedPartnersExistWithinProgram = !!props.unapprovedPartnersExistWithinProgram
            checkForPreviousExport()
        })

        watch(filtersAndPaginationData, (newValue) => {
            // the first time the data is loaded, we want to set the initialDataState to true so we can show the table
            // use filters properly if returned data is empty, etc.
            if (newValue?.data?.length) {
                initialDataState.value = true
            }
        })

        return {
            filtersAndPaginationData,
            loadingFiltersAndPaginationData,
            filterFields,
            initialDataState
        }
    }
}
</script>

