<template>
    <span
        class="inline-flex items-center px-1.5 py-0.5 rounded text-xs align-middle font-medium"
        :class="getClassBasedOnStatus()"
    >
        <tooltip :content="getStatusAdditionalInfo()" method="hover">
            {{ getTextBasedOnStatus() }}
        </tooltip>
    </span>
</template>

<script>
export default {
    name: "ReferralStatus",
    props: ['activity', 'status', 'archived'],
    data() {
        return {
            'is_archived': this.archived === true || this.archived === 1 || this.archived === '1'
        }
    },
    methods: {
        getStatusAdditionalInfo() {
            if (this.activity) {
                if (this.activity.status_additional_info?.fraud_detection?.reason) {
                    return this.activity.status_additional_info.fraud_detection.reason;
                } else if(this.activity.status_additional_info?.rejection_data?.reason) {
                    return this.activity.status_additional_info.rejection_data.reason;
                }
            }

            return null;
        },
        getClassBasedOnStatus() {

            if (this.is_archived) {
                return 'border bg-pink-100 text-pink-800 border-pink-100';
            }

            switch (this.status) {
                case null:
                    return ''
                case 'ok':
                    return 'border border-custom-text'
                case 'active':
                    return 'border border-custom-text'
                case 'deleted':
                    return 'border bg-pink-100 text-pink-800 border-pink-100'
                case 'review_period':
                    return 'border bg-amber-100 text-amber-800 border-amber-100'
                case 'insufficient_settings':
                    return 'border bg-pink-100 text-pink-800 border-pink-100'
                case 'post_commission_period':
                    return 'border bg-pink-100 text-pink-800 border-pink-100'
                case 'requested':
                    return 'border bg-amber-100 text-amber-800 border-amber-100'
                case 'fraudulent':
                    return 'border bg-red-100 text-red-800 border-red-100'
                case 'rejected':
                    return 'border bg-pink-100 text-pink-800 border-pink-100'
                default:
                    return 'border bg-gray-100 border-gray-100 text-gray-800'
            }
        },
        getTextBasedOnStatus() {

            if (this.is_archived) {
                return 'Archived';
            }

            switch (this.status) {
                case null:
                    return 'n/a'
                case 'ok':
                    return 'OK'
                case 'deleted':
                    return 'Archived'
                case 'active':
                    return 'Active'
                case 'review_period':
                    return 'Reward in review'
                case 'insufficient_settings':
                    return 'Insufficient settings'
                case 'post_commission_period':
                    return 'Post commission period'
                case 'post_claim_period':
                    return 'Post claim period'
                case 'rejected':
                    return 'Rejected'
                default:
                    return this.status.charAt(0).toUpperCase() + this.status.slice(1);
            }
        }
    }
}
</script>
