<script setup>

    const props = defineProps({
        title: String,
        loading: Boolean,
        deleteExistingSubscribers: {
            type: Boolean,
            default: false,
        },
        provider: {
            type: String,
            default: 'ESP',
        }
    });

    const emit = defineEmits(['disconnectEsp', 'update:deleteExistingSubscribers']);

    const disconnect = () => {
        emit('disconnectEsp');
    };
</script>

<template>
    <modal size="small" :title="title" name="esp_disconnect">
        <template #toggle>
            <btn>Disconnect</btn>
        </template>
        <template #body>
            <div>
                <toggle :model-value="deleteExistingSubscribers"
                        @update:model-value="$emit('update:deleteExistingSubscribers', $event)"
                        title="Delete subscribers from Partnero"
                >
                    <template #description>
                        If enabled, all existing subscribers will be deleted from Partnero. This action is
                        irreversible from Partnero side but the subscribers will remain in your {{ provider }}
                        and you can re-import them at any time.
                    </template>
                </toggle>
            </div>
        </template>
        <template #footer>
            <btn @click="disconnect" :loading="loading.disconnecting">Disconnect</btn>
        </template>
    </modal>
</template>

