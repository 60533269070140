<script setup>
import {capitalize, reactive, ref} from "vue";
import Btn from "../../../../elements/Btn.vue";
import Notification from "../../../../elements/Notification.vue";

    const props = defineProps(
        {
            syncProvider: {
                type: String,
                required: true,
            },
            program: {
                type: Object,
                required: true,
            }
        }
    )

    const syncType = ref('all')

    const saveSyncType = () => {
        savingSyncType.value = true

        axios.post(route('app_int.coupon.provider.sync-type.store', {program: props.program}), {
            provider: props.syncProvider,
            syncType: syncType.value,
        }).then((response) => {
            const status = parseInt(response.data.status || '-1');
            if (status === 1) {

                savingSyncType.value = false

                notification.showNotification = true
                notification.notificationTitle = 'Success'
                notification.notificationType = 'success'
                notification.notificationMessage = response.data.message ?? 'Sync type saved successfully.'
                setTimeout(() => {
                        notification.showNotification = false
                        window.location.reload();
                    },
                    2500
                )
            }
            else if (status === -1) {
                console.log(response.data.error)
                notification.showNotification = true
                notification.notificationTitle = 'Error'
                notification.notificationType = 'warning'
                notification.notificationMessage = response.data.message ?? 'Sync error, see console for error details.'
            }
        }).catch((error) => {
            savingSyncType.value = false
            console.log('error')
            if (error.response && (error.response.status === 409 || error.response.status === 422) && error.response.data.message) {
                notification.notificationMessage = error.response.data.message
            }
            notification.showNotification = true
        })
            .finally(() => {
                savingSyncType.value = false
            })
    }

    const savingSyncType = ref(false)

const notification = reactive({
    showNotification: false,
    notificationTitle: 'Error',
    notificationType: 'warning',
    notificationMessage: 'Sync error, see console for error details.'
})

</script>

<template>
    <card class="mb-14">
        <template #body>
            <form-layout>
                <form-section title="Sync type" description="Select a sync type so we know how to handle the sync of the coupons. You can always change this under Sync coupons button.">
                    <span class="text-sm text-gray-900 pointer-events-none mb-1.5">Select type</span>
                    <select-field
                        v-model="syncType"
                    >
                        <option value="all">All</option>
                        <option value="custom">Custom</option>
                        <template #note>
                                        <span class="text-xs text-gray-500">
                                            {{
                                                syncType === 'all'
                                                    ? 'By selecting “All” you also choose that every coupon created on the third-party platform will automatically be synced with this program.'
                                                    : 'By selecting “Custom” you can choose which coupons you want to sync with this program. Keep in mind that if you choose “Custom” you will have to manually sync each coupon created on the ' + capitalize(syncProvider) + ' side.'
                                            }}
                                        </span>
                        </template>
                    </select-field>
                </form-section>
            </form-layout>
            <notification
                :show="notification.showNotification"
                :title="notification.notificationTitle"
                :type="notification.notificationType"
                class="mb-20"
                @notificationClosed="notification.showNotification = false"
                whitespace="whitespace-normal"
            >
                <template #message>
                    {{ notification.notificationMessage }}
                </template>
            </notification>
        </template>
        <template #footer>
            <btn
                type="primary"
                @click="saveSyncType"
                :loading="savingSyncType"
                :disabled="savingSyncType"
            >
                Save sync type
            </btn>
        </template>
    </card>
</template>

