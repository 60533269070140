<template>

    <div class="space-y-6">

        <alert type="neutral">
            <p class="mb-1.5">The Partnero API offers a straightforward back-end based method for integrating Partnero with any application through a simple REST API.</p>
            <p>Below is a basic example of how to integrate using the REST API. For more detailed examples, <a href="https://developers.partnero.com/guide/affiliate.html" target="_blank" class="underline hover:no-underline">refer to this guide</a>. Full <a href="https://developers.partnero.com/" target="_blank" class="underline hover:no-underline">developer documentation</a> is also available for in-depth information.</p>
        </alert>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>
        </div>

        <tabs>
            <tab @click="setActiveTab(TAB_1)" :class='{"border-b-4": activeTab === TAB_1}'>
                Sign-ups tracking
            </tab>
            <tab @click="setActiveTab(TAB_2)" :class='{"border-b-4": activeTab === TAB_2}'>
                Sales tracking
            </tab>
        </tabs>

        <div class="space-y-4" v-show="activeTab === TAB_1">

            <div class="space-y-4">
                <div class="space-y-2">
                    <div class="text-sm space-y-2">
                        <p>Notify Partnero about each new user registration to monitor referrals from affiliate partners.</p>
                        <p>After each sign-up, make sure to execute the API call. This step is crucial for accurately capturing data about users who joined via affiliate links.</p>
                    </div>
                </div>
            </div>

            <div class="space-y-4">
            <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
POST https://api.partnero.com/v1/customers</code>
                    </pre>
                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
{
    "partner": {
        "key": "UNIQUE_PARTNER_KEY"
    },
    "key": "customer_123456", // or email address
    "name": "John",
    "email": "john.doe@partnero.com"
}</code>
            </pre>
            </div>

            <div class="space-y-4">
                <div class="text-sm space-y-2">
                    <p>The API call's payload should contain information about the customer and the affiliate partner who referred them. The <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">partner</span> object is critical. Fill in the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">partner.key</span> with the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">UNIQUE_PARTNER_KEY</span>, found in the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">partnero_partner</span> cookie set by our Partnero Universal script.</p>
                </div>
            </div>

        </div>

        <div class="space-y-4" v-show="activeTab === TAB_2">

            <div class="space-y-4">
                <div class="space-y-2">
                    <div class="text-sm space-y-2">
                        <p>The following step of the integration is sending data about sales. When any customer makes a purchase, it is recommended to call <a href="https://developers.partnero.com/reference/transactions.html#create-a-transaction" target="_blank" class="underline hover:no-underline">Transaction create</a>.</p>
                        <p>When making this call, we recommend including the most important data about the purchase (see <a href="https://developers.partnero.com/guide/affiliate.html#request-parameters-1" target="_blank" class="underline hover:no-underline">Request parameters</a>). Also, feel free to send data about all purchases; Partnero will ignore requests if there is no existing customer.key.</p>
                    </div>
                </div>
            </div>

            <div class="space-y-4">
                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
POST https://api.partnero.com/v1/transactions</code>
                </pre>
                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-php">
{
    "customer": {
        "key": "customer_123456"
    },
    "key": "transaction_123",
    "amount": 99.99,
    "action": "sale"
}</code>
                </pre>
            </div>

            <div class="space-y-4">
                <div class="space-y-2">
                    <h3 class="font-semibold text-md">Recommendations</h3>
                    <div class="text-sm space-y-2">
                        <p>We also recommend implementing <a href="https://developers.partnero.com/guide/affiliate.html#recommendations" target="_blank" class="underline hover:no-underline">transaction and customer delete</a> actions. These are useful for instances when a refund is processed, or when a customer is deleted or chooses to close their account.</p>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>
<script setup>

import {ref} from 'vue';

const TAB_1 = 'tab_1';
const TAB_2 = 'tab_2';

let activeTab = ref(TAB_1);

const setActiveTab = (tab) => {
    activeTab.value = tab;
};

</script>
