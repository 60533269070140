<script setup>
import {computed, inject, ref} from "vue";
import SelectField from "../../../elements/forms/SelectField";

const props = defineProps({
    availableTranslations: {
        type: Array,
        required: true,
    },
    modelValue: {
        default: '',
    },
});

const unconfirmedValue = ref('');
const confirmedValue = ref('');
const emitter = inject('emitter');

function cancelSelection () {
    emit('update:modelValue', confirmedValue.value)
    emitter.emit('modal-off', 'language_prefill_select')
}

const emit = defineEmits(['update:modelValue'])

const unconfirmedLanguageLabel = computed(() => {
    const translation = props.availableTranslations.find(translation => translation.value === unconfirmedValue.value)
    return translation ? translation.label : ''
})

function confirmSelection () {
    confirmedValue.value = unconfirmedValue.value
    emitter.emit('modal-off', 'language_prefill_select')
}

function showConfirmation (e) {
    unconfirmedValue.value = e
    emit('update:modelValue', unconfirmedValue.value)
    emitter.emit('modal-on', 'language_prefill_select')
}

</script>

<template>
    <div class="bg-white border rounded p-4 mx-4 mt-4 flex gap-12">
       <div class="text-sm text-gray-500">
            To translate standard phrases into another language, modify any of the fields below as needed. Alternatively, you may utilize predefined translations available through Partnero.
        </div>
        <div class="w-1/3">
            <select-field
                class="w-full"
                id="locale"
                name="locale"
                :model-value="props.modelValue"
                @update:model-value="showConfirmation"
            >
                <option value="">Default</option>
                <option v-for="(translation) in props.availableTranslations" :value="translation.value">{{ translation.label }}</option>
            </select-field>
        </div>


    </div>
    <modal :title="'Please confirm'"
           size="small"
           name="language_prefill_select"
           cancel_btn_title="No"
           @modal-close="cancelSelection"
    >
        <template #body>
            <div class="text-left space-y-2">
                <div class="text-sm text-gray-500" v-if="unconfirmedLanguageLabel === ''">
                    Would you like to utilize custom-defined translations for your inputs?
                </div>
                <div class="text-sm text-gray-500" v-else>
                   You are about to translate partner portal to <strong> {{ unconfirmedLanguageLabel }} </strong> language.
                </div>
            </div>
        </template>
        <template #footer>
            <btn type="primary" @click="confirmSelection">Yes, translate</btn>
        </template>
    </modal>

</template>

