<template>
    <table class="min-w-full divide-y divide-gray-200">
        <thead>
        <tr class="h-1">
            <th :colspan="TABLE_COLUMNS_COUNT">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <th class="pl-4 w-6">
                <options
                    :isRight="false"
                    name="coupons_table_options"
                >
                    <template #toggle>
                        <div class="bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                            <icon name="arrow-down-small"></icon>
                        </div>
                    </template>
                    <template #items>
                        <options-item
                            title="Select all"
                            @click="setExportSelection('selectAll')"
                        />
                        <options-item
                            title="Select visible"
                            @click="setExportSelection('selectVisible')"
                        />
                    </template>
                </options>
            </th>
            <th scope="col"
                class="pl-4 pr-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                Coupon
            </th>
            <th scope="col"
                class="px-2 py-3 text-center text-xs font-normal text-gray-500 uppercase tracking-wider">
                Redemptions
            </th>
            <th scope="col"
                class="px-2 py-3 text-center text-xs font-normal text-gray-500 uppercase tracking-wider">
                Promotion codes
            </th>
            <th scope="col"
                class="px-2 py-3 text-center text-xs font-normal text-gray-500 uppercase tracking-wider">
                Expires at
            </th>
            <th scope="col"
                class="px-2 py-3 text-center text-xs font-normal text-gray-500 uppercase tracking-wider">
                Provider
            </th>
            <th scope="col"
                class="px-2 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
            </th>
        </tr>
        <tr class="h-1"/>
        </thead>
        <tbody class="divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!coupons || coupons?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && coupons?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr v-for="(coupon, index) in coupons"
                :key="coupon.id + '-' + coupon.program_id + '-' + index"
                class="hover:bg-gray-50 cursor-pointer group"
            >
                <td class="pl-4 w-6">
                    <checkbox
                        v-model="exportOptions.selectedIds"
                        :value="coupon.id"
                        @click="setExportSelection('selectCustom')"
                    />
                </td>
                <td class="px-4 py-4 whitespace-nowrap font-medium text-sm text-gray-900 group-hover:text-teal-600">
                    <div class="flex items-center">
                        <div>
                            <div @click="viewCoupon(coupon.details_url)">
                                <span>{{ coupon.name }}</span>
                            </div>
                            <div class="flex text-sm font-normal text-gray-500 mt-0.5">
                                <div @click="viewCoupon(coupon.details_url)">
                                    {{ coupon.value_type_duration }}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td
                    class="px-2 py-4 whitespace-nowrap text-center text-sm text-gray-900"
                    @click="viewCoupon(coupon.details_url)"
                >
                    {{ coupon.times_redeemed }}
                </td>
                <td
                    class="px-2 py-4 whitespace-nowrap text-center text-sm text-gray-900"
                    @click="viewCoupon(coupon.details_url)"
                >
                    {{ coupon.promotion_codes_count }}
                </td>
                <td
                    class="px-2 py-4 text-center whitespace-nowrap text-sm text-gray-900"
                    @click="viewCoupon(coupon.details_url)"
                >
                    <time :datetime="coupon.settings_redemption_specific_date_status">
                        {{ coupon.settings_redemption_specific_date_value }}
                    </time>
                </td>
                <td
                    class="px-2 py-4 text-center whitespace-nowrap text-sm text-gray-900"
                    @click="viewCoupon(coupon.details_url)"
                >
                    <badge
                        v-if="coupon.sync_provider"
                        :title="coupon.sync_provider === 'woocommerce' ? 'WooCommerce' : coupon.sync_provider"
                        class="capitalize"
                        :type="coupon.sync_provider === 'stripe' ? 'stripeIndigo' : (coupon.sync_provider === 'shopify' ? 'shopifyGreen' : (coupon.sync_provider === 'woocommerce' ? 'wooPurple' : 'neutral'))"
                        size="small"
                    ></badge>
                    <template v-else>-</template>
                </td>

                <td class="px-2 py-4">
                    <div class="flex justify-end gap-2">
                        <btn type="ghost" icon-name="settings" size="small" :href="coupon.details_url"></btn>
                        <delete-coupon-btn
                            :id="`delete-coupon-${coupon.id}`"
                            :program-id="coupon.program_id"
                            :coupon-id="coupon.id"
                            :sync-provider="coupon.sync_provider"
                            :program-commission-id="coupon.program_commission_id ?? null"
                            class="inline-flex"
                        >
                        </delete-coupon-btn>
                    </div>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>
import Avatar from "../../../common/Avatar";
import InitialLoadingSkeletonRows from "../../../common/InitialLoadingSkeletonRows";
import NoSearchDataRow from "../../../common/NoSearchDataRow";
import ProgressBar from "../../../../elements/ProgressBar";
import filtersAndPagination from "../../../../composables/common/filter-and-pagination-data";
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";

export default {
    name: "CouponDataTable",
    components: {
        Avatar,
        InitialLoadingSkeletonRows,
        NoSearchDataRow,
        ProgressBar
    },
    props: {
        coupons: {
            default: []
        }
    },
    setup() {
        const TABLE_COLUMNS_COUNT = 6

        const {loadingFiltersAndPaginationData} = filtersAndPagination()
        const {exportOptions, deleteSetup} = useTableDataExporter()

        return {
            loadingFiltersAndPaginationData,
            TABLE_COLUMNS_COUNT,
            exportOptions,
            deleteSetup
        }
    },
    methods: {
        viewCoupon(url) {
            window.location = url;
        },
        setExportSelection(type) {
            this.exportOptions.selectType = type
            if (type === 'selectVisible' || type === 'selectAll') {
                this.exportOptions.selectedIds = []
                this.coupons.forEach(coupon => {
                    this.exportOptions.selectedIds.push(coupon.id)
                })
            }
            this.emitter.emit('hide-options', 'coupons_table_options')
        }
    },
    watch: {
        'exportOptions.selectedIds': {
            handler(newVal) {
                if (this.exportOptions.selectType !== 'selectAll') {
                    this.exportOptions.selectTypeCustomModels = this.coupons
                        .filter(coupon => newVal.includes(coupon.id))
                        .map(coupon => {
                            return {
                                id: coupon.id,
                                program_commission_id: coupon.program_commission_id,
                                sync_provider: coupon.sync_provider
                            }
                        });

                    const rewardCoupons = this.exportOptions.selectTypeCustomModels.some(coupon => coupon.program_commission_id);
                    const thirdPartyCoupons = this.exportOptions.selectTypeCustomModels.some(coupon => coupon.sync_provider);

                    this.deleteSetup.additionalData.rewardCouponsExistWithinCustomSelect = rewardCoupons;
                    this.deleteSetup.additionalData.thirdPartyCouponsExistWithinCustomSelect = thirdPartyCoupons;
                } else {
                    this.exportOptions.selectTypeCustomModels = []
                    this.deleteSetup.additionalData.rewardCouponsExistWithinCustomSelect = false;
                    this.deleteSetup.additionalData.thirdPartyCouponsExistWithinCustomSelect = false;
                }
            }
        }
    }
}
</script>
