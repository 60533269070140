import {defineStore} from "pinia";

export let useAnalyticsPartnersStore = defineStore('analyticsPartners', {
    state: () => ({
        analyticsPartnersStatsData: null,
        analyticsPartnersAcquisitionInsightsData: null,
        program: null,
        timePeriod: 'total',
        groupType: 'monthly'
    }),

    actions: {
        setProgram(program) {
            this.program = program
        },
        setTimePeriod(timePeriod) {
            this.timePeriod = timePeriod
        },
        setGroupType(groupType) {
            this.groupType = groupType
        },
        setAnalyticsPartnersStatsData(data) {
            this.analyticsPartnersStatsData = data
        },
        fetchAnalyticsPartnersStatsData() {
            this.setAnalyticsPartnersStatsData(null)
            axios.get(route('app_int.analytics.partners.stats', { program: this.getProgram.id, filter: this.getFilter }))
                .then((response) => {
                    this.setAnalyticsPartnersStatsData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        setAnalyticsPartnersAcquisitionInsightsData(data) {
            this.analyticsPartnersAcquisitionInsightsData = data
        },
        fetchAnalyticsPartnersAcquisitionInsightsData() {
            this.setAnalyticsPartnersAcquisitionInsightsData(null)
            axios.get(route('app_int.analytics.partners.acquisition_insights', { program: this.getProgram.id, filter: this.getFilter }))
                .then((response) => {
                    this.setAnalyticsPartnersAcquisitionInsightsData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },

    getters: {
        getAnalyticsPartnersStatsData: (state) => { return state.analyticsPartnersStatsData },
        getAnalyticsPartnersAcquisitionInsightsData: (state) => { return state.analyticsPartnersAcquisitionInsightsData },
        getProgram: (state) => { return state.program },
        getGroupType: (state) => { return state.groupType },
        getTimePeriod: (state) => { return state.timePeriod },
        getFilter: (state) => {
            return {
                time_period: state.timePeriod,
                group_type: state.groupType,
            }
        },
    }


})
