<script setup>
import Stats from "./components/Stats.vue";
import Chart from "./components/Chart.vue";
import Pages from "./components/Pages.vue";
import Visitors from "./components/Visitors.vue";
import Map from "./components/Map.vue";
import {useAnalyticsRealTimeStore} from "../../../../../stores/app/programs/analytics/AnalyitcsRealTimeStore";
import {onBeforeUnmount, onMounted} from "vue";
import StatsSkeleton from "../../../../common/skeletons/StatsSkeleton.vue";
import ChartSkeleton from "../../../../common/skeletons/ChartSkeleton.vue";
import TableSkeleton from "../../../../common/skeletons/TableSkeleton.vue";

const props = defineProps({
    program: Object
});

const analyticsRealTimeStore = useAnalyticsRealTimeStore();

let intervalId = null;

function init() {
    analyticsRealTimeStore.setAnalyticsRealTimeProgram(props.program);
    analyticsRealTimeStore.fetchRealTimeData();
    analyticsRealTimeStore.fetchRealTimePagesData();
    analyticsRealTimeStore.fetchRealTimeVisitorsBySourceData();
    analyticsRealTimeStore.fetchRealTimeVisitorsByReferralData();
    analyticsRealTimeStore.fetchCurrentVisitorsData();
    intervalId = setInterval(fetchCurrentVisitors, 20000);
}

const fetchCurrentVisitors = () => {
    analyticsRealTimeStore.fetchCurrentVisitorsData();
};

onMounted(() => {
    init();
});

onBeforeUnmount(() => {
    clearInterval(intervalId);
});


</script>

<template>
    <div class="space-y-8">

        <Stats v-if="analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries" />
        <StatsSkeleton v-else :number-of-items="6"/>

        <div class="p-6 bg-white rounded border">
            <div class="flex flex-col">
                <Chart v-if="analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries"/>
                <div v-else class="h-full">
                    <ChartSkeleton :show-border="false"/>
                </div>
            </div>
        </div>

        <div class="space-y-8">
            <div class="flex items-center justify-between">
                <h3 class="text-lg font-semibold text-gray-900">
                    Pages
                </h3>
            </div>
            <Pages v-if="analyticsRealTimeStore.getAnalyticsRealTimePagesData"/>
            <TableSkeleton v-else :number-of-rows="3"/>
        </div>

        <div class="space-y-8">
            <div class="flex items-center justify-between">
                <h3 class="text-lg font-semibold text-gray-900">
                    Visitors
                </h3>
            </div>
            <div>
                <Visitors v-if="analyticsRealTimeStore.getAnalyticsRealTimeVisitorsByReferralData
                 && analyticsRealTimeStore.getAnalyticsRealTimeVisitorsBySourceData"
                />
                <div v-else class="grid grid-cols-2 gap-4">
                    <TableSkeleton :number-of-rows="3"/>
                    <TableSkeleton :number-of-rows="3"/>
                </div>
            </div>
        </div>

        <div id="map-stats-container" class="p-6 bg-white rounded border">
            <Map v-if="analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries"/>
            <ChartSkeleton v-else :show-border="false"/>
        </div>

    </div>
</template>
