<template>
    <div>
        <label :for="input_names.select" class="block mb-1.5 text-sm">{{name}}</label>
        <select :id="input_names.select" :name="input_names.select"  v-model="type"
                class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
            <option value="off">Not required</option>
            <option value="optional">Optional</option>
            <option value="required">Required</option>
        </select>
        <small class="text-gray-500 mt-1 block">{{ description }}</small>
    </div>
</template>
<script>
export default {
    name: 'CommissionClientsPayoutInvoice',
    props: ['invoice_setting'],
    data() {
        return {
            name: 'Requirement for invoice',
            input_names: {
                select: 'clients_payout_invoice',
            },
            type: this.invoice_setting || 'off',
        }
    },
    computed: {
        types_value_disabled() {
            return this.type === 'off';
        },
        description() {
            switch (this.type) {
                case 'optional':
                    return 'The partner can request a payout without an invoice.'
                case 'required':
                    return 'The partner must have an invoice to request a payout.'
                default: return 'Select if an invoice is required for payout requests.'
            }
        }
    },
    methods: {}
}
</script>
