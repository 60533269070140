<template>
    <span
        class="inline-flex items-center px-1.5 py-0.5 rounded text-xs border align-middle font-medium"
        :class="getClassBasedOnStatus()"
    >
        {{ getTextBasedOnStatus() }}
    </span>
</template>

<script>
export default {
    name: "PayoutStatus",
    props: {
        status: {
            type: [null, String],
            default: null
        }
    },
    methods: {
        getClassBasedOnStatus() {
            switch (this.status) {
                case null:
                    return ''
                case 'rejected':
                    return 'bg-pink-100 text-pink-800 border-pink-100'
                case 'approved':
                    return 'bg-teal-100 text-teal-800 border-teal-100'
                case 'approved_auto_scheduled':
                    return 'bg-teal-100 text-teal-800 border-teal-100'
                case 'approved_auto_rescheduled':
                    return 'bg-teal-100 text-teal-800 border-teal-100'
                case 'intermediary_approved_scheduled':
                    return 'bg-teal-100 text-teal-800 border-teal-100'
                case 'failed_auto_scheduled':
                    return 'bg-pink-100 text-pink-800 border-pink-100'
                case 'finished':
                    return 'bg-indigo-100 text-indigo-800 border-indigo-100'
                case 'requested':
                    return 'bg-amber-100 text-amber-800 border-amber-100'
                case 'resubmitted':
                    return 'bg-amber-100 text-amber-800 border-amber-100'
                case 'resubmit_requested':
                    return 'bg-amber-100 text-amber-800 border-amber-100'
                default:
                    return 'border bg-gray-100 border-gray-100 text-gray-800'
            }
        },
        getTextBasedOnStatus() {
            switch (this.status) {
                case null:
                    return 'n/a'
                case 'rejected':
                    return 'Payout rejected'
                case 'approved':
                    return 'Payout approved'
                case 'approved_auto_scheduled':
                    return 'Approved auto scheduled'
                case 'approved_auto_rescheduled':
                    return 'Approved auto rescheduled'
                case 'intermediary_approved_scheduled':
                    return 'Approved auto rescheduled'
                case 'failed_auto_scheduled':
                    return 'Failed auto scheduled'
                case 'finished':
                    return 'Payout sent'
                case 'requested':
                    return 'Payout requested'
                case 'resubmitted':
                    return 'Payout resubmitted'
                case 'resubmit_requested':
                    return 'Resubmit required'
                default:
                    return this.status.charAt(0).toUpperCase() + this.status.slice(1);
            }
        }
    }
}
</script>
