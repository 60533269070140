<template>
    <div>
        <draggable-accordion
            class="mb-2"
            v-for="content in props.data.contents"
            :unique_id="content.unique_id"
            :edit="content.edit"
            @remove="removeContent"
            @toggle="content.edit = !content.edit"
        >
            <template #title>{{ contentName }}</template>
            <template #content>
                <div class="grid lg:grid-cols-2 gap-4 gap-y-4">
                    <div class="col-span-2">
                        <text-editor
                            :text="content.text"
                            @input="(newValue) => { content.text = newValue }"
                        />
                    </div>
                </div>
            </template>
        </draggable-accordion>

        <btn type="secondary" size="small" icon-name="add-circle" @click="addContent">Add {{ contentName }}</btn>

    </div>
</template>

<script setup>
import {defineProps} from 'vue';
import {uniqueId} from '../helpers';

const contentName = 'Text';

const props = defineProps({
    data: Object
});

props.data.contents = props.data?.contents ?? [];

const addContent = () => {
    props.data.contents.push({
        unique_id: uniqueId(),
        edit: true,
        text: '',
    });
}

const removeContent = (uniqueId) => {
    props.data.contents.splice(
        props.data.contents.findIndex(content => content.unique_id === uniqueId)
    , 1);
}
</script>
