<template>
    <div :class="[

                  groupClass
                 ]"
    >
        <field-label v-if="label" :title="label" />
        <checkbox
            v-for="(option, index) in options"
            :name="name"
            v-model="picked"
            :label="option.label"
            :description="option.description"
            :disabled="option.disabled ?? false"
            :value="option.value"
        ></checkbox>
    </div>
</template>

<script setup>
import {ref, watch} from "vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    name: {
        type: String
    },
    label: {
        type: String
    },
    options: {
        type: Array
    },
    modelValue: {},
    groupClass: {
        default: '',
    },
});

/**
 * This should be array of selected checkbox keys
 * In any case it's object we will take values of it
 */
const picked = typeof props.modelValue === 'object' && props.modelValue ? ref(Object.values(props.modelValue)) : ref([]);

watch(picked, async (newValue, oldValue) => {
    emit('update:modelValue', newValue);
});

</script>
