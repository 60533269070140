<template>
    <filters-and-pagination
        :pagination-data="filtersAndPaginationData"
        :filterable-fields="filterFields"
        :data-exists="initialDataState"
        :loading-data="loadingFiltersAndPaginationData"
        model-name="referral"
    >
        <referral-activities-data-table :recent-activities="filtersAndPaginationData.data" />
    </filters-and-pagination>
</template>

<script>
import FiltersAndPagination from "../../filters-and-pagination/FiltersAndPagination";
import {onMounted, ref, toRef, watch} from "vue";
import useRecentActivities from "../../../../composables/common/filter-and-pagination-data";
import useFiltersAndPagination from "../../../../state/common/filters-and-pagination";
import ReferralActivitiesDataTable from "./components/DataTable";
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";

export default {
    name: "ReferAFriendActivityIndex",

    components: {
        FiltersAndPagination,
        ReferralActivitiesDataTable
    },

    props: {
        referralActivitiesUrl: {
            default: ''
        },
        referralActivitiesExportUrl: {
            default: ''
        },
        filterFields: {
            default: {}
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        }
    },

    setup(props) {
        const referralActivitiesUrl = toRef(props, 'referralActivitiesUrl')
        const referralActivitiesExportUrl = toRef(props, 'referralActivitiesExportUrl')
        const filterFields = toRef(props, 'filterFields')
        const { loadingFiltersAndPaginationData, filtersAndPaginationData, getFiltersAndPaginationData } = useRecentActivities()
        const { setDataBaseUrl, setFetchDataFunction } = useFiltersAndPagination()
        const { exportSetup, checkForPreviousExport } = useTableDataExporter()

        const initialDataState = ref(!!props.dataExists)

        onMounted( () => {
            setDataBaseUrl(referralActivitiesUrl.value)
            setFetchDataFunction(getFiltersAndPaginationData)

            exportSetup.value.exportModelNameSingular = 'referral activity'
            exportSetup.value.exportModelNamePlural = 'referral activities'
            exportSetup.value.exportUrl = referralActivitiesExportUrl.value
            checkForPreviousExport()
        })

        watch(filtersAndPaginationData, (newValue) => {
            // the first time the data is loaded, we want to set the initialDataState to true so we can show the table
            // use filters properly if returned data is empty, etc.
            if (newValue?.data?.length) {
                initialDataState.value = true
            }
        })

        return {
            filtersAndPaginationData,
            loadingFiltersAndPaginationData,
            filterFields,
            initialDataState
        }
    }
}
</script>
