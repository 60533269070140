<template>
    <div class="rounded bg-white border mb-10">
        <div class="p-6">
            <div class="sm:flex sm:items-center sm:justify-between">
                <div class="sm:flex sm:space-x-5">
                    <div class="flex justify-center">
                        <div v-html="partner.avatar_html"></div>
                    </div>
                    <div class="mt-4 text-center sm:mt-0 sm:pt-3 sm:text-left">

                        <div class="sm:flex sm:space-x-3 sm:mb-0 mb-1 items-center">
                            <h3 class="font-semibold text-2xl">{{ partner.name + ' ' + partner.surname }}</h3>
                            <span v-if="partner.deleted"
                                  class="inline-block flex-shrink-0 rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800 capitalize">Archived</span>
                            <span v-else-if="['deleted', 'approval_rejected'].includes(partner.status)"
                                  class="inline-block flex-shrink-0 rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800 capitalize">{{
                                    statusName
                                }}</span>
                            <span
                                v-else-if="partner.status === 'active' && (partner.approved || (!partner.approved && approval_request_status === 'submitted'))"
                                class="inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium bg-teal-100 text-teal-800 capitalize">{{
                                    statusName
                                }}</span>
                            <span v-else-if="partner.status === 'active' && !partner.approved"
                                  class="inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium bg-gray-100 text-gray-800 capitalize">{{
                                    statusName
                                }}</span>
                            <tooltip v-if="partner.status === 'suspended'" :content="'Reason: ' + suspendReason"
                                     class="-mt-0.5 inline-block">
                                <span v-if="partner.status === 'suspended'"
                                      class="inline-block flex-shrink-0 rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800 capitalize">{{
                                        statusName
                                    }}</span>
                            </tooltip>
                        </div>
                        <p class="text-gray-900 text-base">{{ partnerEmail }}
                            <tooltip content="Impersonate" class="-mt-0.5 inline-block" v-if="program_is_affiliate">
                                <a target="_blank" :href="impersonate_url">
                                    <svg class="text-gray-900 w-5 h-5" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z"/>
                                        <path
                                            d="M10 11V8l5 4-5 4v-3H1v-2h9zm-7.542 4h2.124A8.003 8.003 0 0 0 20 12 8 8 0 0 0 4.582 9H2.458C3.732 4.943 7.522 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-4.478 0-8.268-2.943-9.542-7z"/>
                                    </svg>
                                </a>
                            </tooltip>
                        </p>
                    </div>
                </div>
                <div class="mt-5 flex justify-center sm:mt-0">
                    <options>
                        <template #toggle>
                            <btn type="secondary" icon-name="more" size="small" class="h-full"></btn>
                        </template>
                        <template #items>
                            <!-- Edit partner modal -->
                            <modal :title="'Partner profile'" size="medium" name="partner_profile_modal"
                                   @modalClose="modalClose">
                                <template #toggle>
                                    <options-item title="Manage"></options-item>
                                </template>

                                <template #body>

                                    <modal-tabs>
                                        <modal-tab @click="setActiveTab('profile')" :active="activeTab('profile')">
                                            Profile
                                        </modal-tab>
                                        <modal-tab v-if="partner_cfields.length"
                                                   @click="setActiveTab('fields')"
                                                   :active="activeTab('fields')"
                                        >
                                            Fields
                                        </modal-tab>
                                        <modal-tab @click="setActiveTab('commissions')"
                                                   :active="activeTab('commissions')">
                                            Commissions
                                        </modal-tab>
                                        <modal-tab v-show="hasApprovalData" @click="setActiveTab('approval-data')"
                                                   :active="activeTab('approval-data')">
                                            Approval data
                                        </modal-tab>
                                    </modal-tabs>

                                    <div v-if="this.editError"
                                         class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm mb-6"
                                         role="alert">
                                        <ul>
                                            <li>{{ this.editError }}</li>
                                        </ul>
                                    </div>

                                    <div v-show="activeTab('profile')"
                                         class="grid gap-y-6 gap-x-8 xl:col-span-2 lg:grid-cols-2">
                                        <div class="flex items-end gap-x-4 col-span-2">
                                            <div class="w-full">
                                                <text-field
                                                    label="ID"
                                                    type="text"
                                                    name="key"
                                                    class="w-full"
                                                    :disabled="disableKeyInput"
                                                    v-model="partner_key"
                                                ></text-field>
                                            </div>
                                            <btn @click="toggleKeyInput" class="w-1/3" type="secondary">
                                                {{ disableKeyInput ? 'Change' : 'Cancel' }}
                                            </btn>
                                        </div>
                                        <text-field
                                            label="Name"
                                            v-model="partner.name"
                                        />
                                        <text-field
                                            label="Last name"
                                            v-model="partner.surname"
                                        />
                                        <text-field
                                            label="Email address"
                                            v-model="partner.email"
                                            class="col-span-2"
                                        />

                                        <password
                                            v-slot="{ childScope }"
                                            :min=password_min_length
                                            :regex=parsed_password_regex
                                        >
                                            <div class="flex items-end gap-x-4 col-span-2">
                                                <div class="w-full">
                                                    <text-field
                                                        label="Password"
                                                        type="password"
                                                        name="password"
                                                        field-id="password"
                                                        v-model="childScope.password"
                                                        :disabled="disablePasswordInput"
                                                        :show_reveal_password_icon=!disablePasswordInput
                                                        @input="setPartnerPassword(childScope.password)"
                                                    ></text-field>
                                                </div>
                                                <btn @click="togglePasswordInput" class="w-1/3" type="secondary">
                                                    {{ disablePasswordInput ? 'Change' : 'Cancel' }}
                                                </btn>
                                            </div>
                                            <div v-show="!disablePasswordInput" class="grid grid-cols-2 gap-2">
                                                <div v-for="regex in parsed_password_regex" class="flex items-center">
                                                    <div
                                                        class="h-2 w-2 rounded-full mr-2"
                                                        :class="childScope.rules[getRegexRuleCamelCase(regex)] ? 'bg-black':'bg-gray-400'">
                                                    </div>
                                                    <span class="text-xs tracking-wide">{{ regex }}</span>
                                                </div>
                                                <div class="flex items-center">
                                                    <div
                                                        class="h-2 w-2 rounded-full mr-2"
                                                        :class="[childScope.rules.passwordLength ? 'bg-black':'bg-gray-400']">
                                                    </div>
                                                    <span class="text-xs tracking-wide">{{ password_min_length }} characters minimum</span>
                                                </div>
                                            </div>
                                        </password>

                                        <!-- Other settings -->
                                        <div class="col-span-2 space-y-2">

                                            <div class="pt-4 border-t space-y-2">

                                                <toggle
                                                    title="Marketing consent opt-in"
                                                    v-model="partner.accepts_marketing"
                                                    name="accepts_marketing_toggle"
                                                    size="default"
                                                >
                                                    <template #description>
                                                        Partner consent for marketing communication.
                                                    </template>
                                                </toggle>

                                                <toggle
                                                    v-if="!program_settings.allow_paypal_transfer"
                                                    title="Payout requests via PayPal"
                                                    v-model="partner.allow_paypal_transfer"
                                                    name="allow_paypal_transfer_toggle"
                                                    size="default"
                                                >
                                                    <template #description>
                                                        Partner can request for a payout via PayPal.
                                                    </template>
                                                </toggle>

                                                <toggle
                                                    v-if="!program_settings.allow_bank_transfer"
                                                    title="Payout requests via bank transfer"
                                                    v-model="partner.allow_bank_transfer"
                                                    name="allow_bank_transfer_toggle"
                                                    size="default"
                                                >
                                                    <template #description>
                                                        Partner can request for a payout via bank transfer.
                                                    </template>
                                                </toggle>

                                                <toggle
                                                    v-if="!program_settings.allow_crypto_transfer"
                                                    title="Payout requests using cryptocurrency"
                                                    v-model="partner.allow_crypto_transfer"
                                                    name="allow_crypto_transfer_toggle"
                                                    size="default"
                                                >
                                                    <template #description>
                                                        Partner can request for a payout via crypto transfer.
                                                    </template>
                                                </toggle>

                                                <toggle
                                                    v-if="!program_settings.allow_partners_to_create_customers"
                                                    title="Allow creating customers from portal"
                                                    v-model="partner.allow_create_customers"
                                                    name="allow_create_customers_toggle"
                                                    size="default"
                                                >
                                                    <template #description>
                                                        Partner will be able to create customers that they brought via partner portal.
                                                    </template>
                                                </toggle>

                                            </div>

                                        </div>
                                        <!-- ./ Other settings -->
                                    </div>

                                    <div v-show="activeTab('fields')">
                                        <!-- Custom Singup Fields -->

                                        <div class="flex items-center justify-between mb-4">
                                            <div class="text-sm font-semibold">Additional partner fields</div>
                                            <btn type="secondary" size="small"
                                                 @click="editPartnerFields = !editPartnerFields">
                                                <span v-if="!editPartnerFields">Edit</span>
                                                <span v-if="editPartnerFields">Cancel</span>
                                            </btn>
                                        </div>

                                        <div v-if="!editPartnerFields"
                                             class="grid gap-y-6 gap-x-8 xl:col-span-2 lg:grid-cols-2 border rounded p-4">
                                            <div v-for="cfield in partner_cfields">

                                                <label class="block text-sm mb-1.5">{{ cfield.title }}</label>
                                                <div class="text-sm font-semibold">{{ cfield.display_value }}</div>

                                            </div>
                                        </div>


                                        <div v-else
                                             class="grid gap-y-6 gap-x-8 xl:col-span-2 lg:grid-cols-2 border rounded p-4">
                                            <div v-for="cfield in partner_cfields">

                                                <div v-if="cfield.type ==='input'">
                                                    <text-field
                                                        :label="cfield.title"
                                                        v-model="cfield.value"
                                                    />
                                                </div>

                                                <div v-if="cfield.type ==='select' && cfield?.options?.length">
                                                    <select-field
                                                        :label="cfield.title"
                                                        v-model="cfield.value"
                                                    >
                                                        <option v-for="(option, index) in cfield.options"
                                                                :value="index">
                                                            {{ option }}
                                                        </option>
                                                    </select-field>
                                                </div>

                                                <div v-if="cfield.type ==='textarea'">
                                                    <field-label :title="cfield.label"/>
                                                    <text-editor
                                                        :text="cfield.value"
                                                        @input="(newValue) => { cfield.value = newValue }"
                                                    />
                                                </div>

                                                <div v-if="cfield.type ==='checkbox' && cfield?.options.length">
                                                    <checkbox-group
                                                        :name="'cfields[' + cfield.id + ']'"
                                                        :label="cfield.title"
                                                        v-model="cfield.value"
                                                        :options="transFormToGroupOptions(cfield.options)"
                                                    />
                                                </div>

                                                <div v-if="cfield.type ==='radio' && cfield?.options.length">
                                                    <radio-group-default
                                                        layout="horizontal"
                                                        :name="'cfields[' + cfield.id + ']'"
                                                        :label="cfield.title"
                                                        v-model="cfield.value"
                                                        :options="transFormToGroupOptions(cfield.options)"
                                                    />
                                                </div>

                                                <div v-if="cfield.type ==='countries' && countries.length">
                                                    <select-field
                                                        :label="cfield.title"
                                                        v-model="cfield.value"
                                                    >
                                                        <option v-for="country in countries" :value="country.value">
                                                            {{ country.name }}
                                                        </option>
                                                    </select-field>
                                                </div>

                                            </div>
                                        </div>
                                        <!-- ./ Custom Singup Fields -->

                                    </div>

                                    <div v-show="activeTab('commissions')">
                                        <div class="mb-6 text-sm">
                                            Choose how you want to reward partner.
                                        </div>

                                        <div class="mb-8">
                                            <fieldset>
                                                <div class="-space-y-px bg-white rounded-md">
                                                    <radio-group
                                                        name="partner_commision"
                                                        @update:modelValue="partnerCommissionTypeChange"
                                                        :model-value="commissionSettings.custom_commission ? 'custom' : 'default'"
                                                        :options="[ { value: 'default', label: 'Default', description: 'Partner rewards counted using default program settings.'}, { value: 'custom', label: 'Custom', description: 'Set a custom reward.'}, ]"
                                                    ></radio-group>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="grid md:grid-cols-3 col-span-2 gap-6"
                                             v-if="!commissionSettings.custom_commission">
                                            <div>
                                                <div class="text-sm text-gray-500 mb-1.5">Commission and currency</div>
                                                <div class="text-sm font-medium text-gray-900">
                                                    {{ commissionsCommissionOriginal }}
                                                </div>
                                            </div>
                                            <div>
                                                <div class="text-sm text-gray-500 mb-1.5">Commission period</div>
                                                <div class="text-sm font-medium text-gray-900">
                                                    {{ commissionsPeriodOriginal }}
                                                </div>
                                            </div>
                                            <div>
                                                <div class="text-sm text-gray-500 mb-1.5">Payout threshold</div>
                                                <div class="text-sm font-medium text-gray-900">{{
                                                        commissionsThreshold
                                                    }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid md:grid-cols-3 col-span-3 gap-6 border-t pt-8"
                                             v-if="commissionSettings.custom_commission">
                                            <div class="col-span-3">
                                                <label for="commission_type" class="block mb-1.5 text-sm">Commission
                                                    type</label>
                                                <fieldset>
                                                    <div class="-space-y-px bg-white rounded-md">
                                                        <radio-group
                                                            name="commission_type"
                                                            id="commission_type"
                                                            :model-value="commissionSettings.settings.commission_type"
                                                            :options="[
                                                    { value: 'percent', label: 'Percentage', description: 'Reward your partners with a percentage of the sale amount.'},
                                                    { value: 'static', label: 'Static', description: 'Reward your partners with a fixed amount per sale.'},
                                                ]"
                                                            @update:modelValue="value => commissionSettings.settings.commission_type = value"
                                                        ></radio-group>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div>
                                                <label for="commission" class="block mb-1.5 text-sm">Commission and
                                                    currency</label>
                                                <div class="flex relative rounded-md mb-1">
                                                    <span
                                                        class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">
                                                        {{ commissionTypeSymbol }}
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name="commission"
                                                        id="commission"
                                                        placeholder="e.g. 10"
                                                        v-model="commissionSettings.settings.commission"
                                                        class="block w-full rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-0 focus:border-gray-400"
                                                    />
                                                </div>
                                                <small class="text-gray-500 mt-1 block">Set the commission amount and
                                                    the
                                                    currency.</small>
                                            </div>
                                            <div>
                                                <commission-period_setting
                                                    :commission_type="commissionSettings.settings.commission_period_type"
                                                    :commission_times_value="commissionSettings.settings.commission_period_times_value"
                                                    @update:type="event => commissionSettings.settings.commission_period_type = event.target.value"
                                                    @update:value="value => commissionSettings.settings.commission_period_times_value = value"
                                                ></commission-period_setting>
                                            </div>
                                            <div>
                                                <text-field
                                                    label="Payout threshold"
                                                    name="payout_threshold"
                                                    type="text"
                                                    class="mb-1"
                                                    placeholder="0.00"
                                                    v-model="commissionSettings.settings.payout_threshold"
                                                    required="true">
                                                </text-field>
                                                <small class="text-gray-500 mt-1 block">Set the minimum commission
                                                    amount
                                                    required
                                                    to request a
                                                    payout.</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-show="activeTab('approval-data')" class="grid gap-x-6 gap-y-4">
                                        <div class="col-span-2">
                                            <div v-for="approval_request_answer in approval_request_answers">
                                                <h5 class="mb-1 text-gray-500 text-sm">
                                                    {{ approval_request_answer['question'] }}</h5>
                                                <div>{{ approval_request_answer['answer'] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #footer>
                                    <btn type="primary" @click="editPartner" :loading="loading" :disabled="loading">Update</btn>
                                </template>
                            </modal>
                            <!-- ./ Edit partner modal -->

                            <options-divider></options-divider>

                            <modal title="Are you sure?" size="small" name="partner_mark_active_modal"
                                   v-if="partner.status === 'active' && !partner.approved && hasApprovalData">
                                <template #toggle>
                                    <options-item title="Approve"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to approve this partner?
                                        </div>
                                        <div v-if="approveError" class="text-sm text-red-600">
                                            {{ approveError }}
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="approvePartner">Yes, approve</btn>
                                </template>
                            </modal>

                            <modal title="Are you sure?" size="small" name="partner_suspend_modal"
                                   v-if="partner.status !== 'suspended' && !partner.deleted">
                                <template #toggle>
                                    <options-item title="Suspend"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to suspend this partner?
                                        </div>
                                        <div class="grid gap-x-6 gap-y-4">
                                            <div class="col-span-2">
                                                <label class="block text-sm mb-1.5">Suspend reason</label>
                                                <textarea v-model="suspendReason"
                                                          class="transition-shadow block w-full rounded focus:ring-0 py-2 px-3 text-sm border-gray-300 focus:border-gray-400"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="suspendPartner">Yes, suspend</btn>
                                </template>
                            </modal>

                            <modal title="Are you sure?" size="small" name="partner_restore_modal"
                                   v-if="partner.status !== 'active' && !partner.deleted">
                                <template #toggle>
                                    <options-item title="Restore"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to restore this partner?
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="restorePartner">Yes, restore</btn>
                                </template>
                            </modal>

                            <modal title="Are you sure?" size="small" name="partner_archive_modal"
                                   v-if="!partner.deleted">
                                <template #toggle>
                                    <options-item title="Archive"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to archive this partner?
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="archivePartner">Yes, archive</btn>
                                </template>
                            </modal>

                            <modal title="Are you sure?" size="small" name="partner_restore_archived_modal"
                                   v-if="partner.deleted">
                                <template #toggle>
                                    <options-item title="Restore archived"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to restore archived partner?
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="restoreArchivePartner">Yes, restore
                                        archived
                                    </btn>
                                </template>
                            </modal>

                            <modal title="Are you sure?" size="small" name="partner_delete_modal">
                                <template #toggle>
                                    <options-item title="Delete"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to delete this partner?<br/>
                                            This process cannot be undone.
                                        </div>
                                        <div class="grid gap-x-6 gap-y-4">
                                            <div class="col-span-2">
                                                <label class="block text-sm mb-1.5">Please type <span
                                                    class="font-semibold">DELETE</span> to confirm.</label>
                                                <text-field v-model="deleteConfirmation"
                                                            :error="deleteConfirmationError"
                                                            v-on:keydown.enter.prevent="deletePartner"/>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="deletePartner">Yes, delete</btn>
                                </template>
                            </modal>
                        </template>
                    </options>
                </div>
            </div>
        </div>

        <div
            class="grid grid-cols-1 sm:grid-cols-3 divide-y divide-gray-200 border-t border-gray-200 sm:divide-y-0 sm:divide-x"
            v-if="program_is_affiliate">
            <div class="px-6 py-5 grid justify-items-center">
                <div class="flex items-center text-sm text-gray-500">
                    <svg class="mr-1.5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18"
                         height="18">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"
                            fill="rgba(17,24,39,1)"/>
                    </svg>
                    <span>Joined on {{ partner.joined }}</span>
                </div>
            </div>

            <div class="px-6 py-5 grid justify-items-center">
                <div class="flex items-center text-sm text-gray-500">
                    <svg class="mr-1.5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18"
                         height="18">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-3.5-8v2H11v2h2v-2h1a2.5 2.5 0 1 0 0-5h-4a.5.5 0 1 1 0-1h5.5V8H13V6h-2v2h-1a2.5 2.5 0 0 0 0 5h4a.5.5 0 1 1 0 1H8.5z"
                            fill="rgba(17,24,39,1)"/>
                    </svg>
                    <span v-if="!commissionSettings.custom_commission">Commission: {{ commissionsCommissionOriginal }}, {{
                            commissionsPeriodOriginal
                        }}</span>
                    <span v-if="commissionSettings.custom_commission">Commission: {{
                            commissionsCommissionCustom
                        }}, {{ commissionsPeriodCustom }}</span>
                </div>
            </div>

            <div class="px-6 py-5 grid justify-items-center" v-if="!referring_partner_id">
                <div class="flex items-center text-sm text-gray-500">
                    <svg class="mr-1.5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18"
                         height="18">
                        <path fill="none" d="M0 0L24 0 24 24 0 24z"/>
                        <path
                            d="M17 2v2h3.007c.548 0 .993.445.993.993v16.014c0 .548-.445.993-.993.993H3.993C3.445 22 3 21.555 3 21.007V4.993C3 4.445 3.445 4 3.993 4H7V2h10zM7 6H5v14h14V6h-2v2H7V6zm2 10v2H7v-2h2zm0-3v2H7v-2h2zm0-3v2H7v-2h2zm6-6H9v2h6V4z"
                            fill="rgba(17,24,39,1)"/>
                    </svg>
                    <span v-if="partner.accepts_marketing">Gave marketing consent</span>
                    <span v-else>No marketing consent</span>
                </div>
            </div>

            <div class="px-6 py-5 grid justify-items-center" v-if="referring_partner_id">
                <div class="flex items-center text-sm text-gray-500">
                    <svg class="mr-1.5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18"
                         height="18">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M1 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H1zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM21.548.784A13.942 13.942 0 0 1 23 7c0 2.233-.523 4.344-1.452 6.216l-1.645-1.196A11.955 11.955 0 0 0 21 7c0-1.792-.393-3.493-1.097-5.02L21.548.784zm-3.302 2.4A9.97 9.97 0 0 1 19 7a9.97 9.97 0 0 1-.754 3.816l-1.677-1.22A7.99 7.99 0 0 0 17 7a7.99 7.99 0 0 0-.43-2.596l1.676-1.22z"
                            fill="rgba(17,24,39,1)"/>
                    </svg>
                    <span>Referred by: <a class="text-teal-600 font-semibold" :href="referring_partner_url"
                                          target="_blank">{{ referring_partner_name }}</a></span>
                </div>
            </div>
        </div>
        <notification
            :show="showNotification"
            :title="notificationTitle"
            :type="notificationType"
            class="mb-20"
            @notificationClosed="this.showNotification = false"
        >
            <template #message>
                {{ notificationMessage }}
            </template>
        </notification>
    </div>
</template>
<script>
import TextField from "../../../../elements/forms/TextField";
import Tooltip from "../../../../elements/Tooltip";
import ModalTabs from "../../../../elements/tabs/ModalTabs.vue";
import ModalTab from "../../../../elements/tabs/ModalTab.vue";

export default {
    name: 'SuspendEditDelete',
    components: {ModalTab, ModalTabs, TextField, Tooltip},
    props: [
        'suspend_reason', 'approval_request_answers', 'suspend_url', 'delete_url', 'restore_url', 'edit_url', 'impersonate_url',
        'disable_2fa_url', 'approve_url', 'partner_approved', 'approval_request_status', 'partner_email', 'partner_name',
        'partner_surname', 'partner_joined', 'partner_avatar_html', 'partner_status', 'allow_paypal_transfer', 'allow_bank_transfer',
        'allow_crypto_transfer', 'program_allow_paypal_transfer', 'program_allow_bank_transfer', 'program_allow_crypto_transfer',
        'partner_deleted', 'accepts_marketing', 'partner_commission', 'partner_identifier', 'program_is_affiliate',
        'password_min_length', 'password_regex', 'custom_signup_fields', 'countries', 'program_id', 'partner_id',
        'referring_partner_id', 'referring_partner_name', 'allow_create_customers', 'program_allow_partner_to_create_customers',
    ],
    data() {
        return {
            loading: false,
            showNotification: false,
            editPartnerFields: false,
            notificationType: '',
            notificationTitle: '',
            notificationMessage: '',

            disableKeyInput: true,
            disablePasswordInput: true,

            deleteConfirmation: '',
            disable2FAConfirmation: '',
            deleteConfirmationError: false,
            suspendReason: this.suspend_reason,

            commissionSettings: this.partner_commission,
            originalCommissions: {...this.partner_commission.program_settings},

            editError: '',
            approveError: '',
            deleteError: '',
            suspendError: '',
            archiveError: '',
            restoreArchivedError: '',
            disable2FAError: '',

            hasApprovalData: (this.approval_request_answers ?? []).length > 0,

            partner: {
                email: this.partner_email ?? null,
                joined: this.partner_joined ?? null,
                status: this.partner_status ?? null,
                deleted: this.partner_deleted ?? false,
                approved: this.partner_approved ?? false,
                name: this.partner_name ?? null,
                surname: this.partner_surname ?? null,
                avatar_html: this.partner_avatar_html ?? null,
                accepts_marketing: this.accepts_marketing ?? false,
                allow_paypal_transfer: this.allow_paypal_transfer ?? false,
                allow_bank_transfer: this.allow_bank_transfer ?? false,
                allow_crypto_transfer: this.allow_crypto_transfer ?? false,
                allow_create_customers: this.allow_create_customers ?? false
            },

            program_settings: {
                allow_paypal_transfer: this.program_allow_paypal_transfer ?? false,
                allow_bank_transfer: this.program_allow_bank_transfer ?? false,
                allow_crypto_transfer: this.program_allow_crypto_transfer ?? false,
                allow_partners_to_create_customers: this.program_allow_partner_to_create_customers ?? false,
            },

            /* Separate email holder to display in view and only update on success update */
            partnerEmail: this.partner_email,
            // Separate key and password as they are optional, for adding them later into the partner object
            partner_key: this.partner_identifier ?? null,
            partner_password: null,
            parsed_password_regex: {},
            activeTabName: 'profile',

            partner_cfields: this.custom_signup_fields ?? [],

            partner_key_backup: this.partner_idendifier ?? null,
            partner_email_backup: this.partner_email ?? null,
            partner_name_backup: this.partner_name ?? null,
            partner_surname_backup: this.partner_surname ?? null,
            partner_accepts_marketing_backup: this.accepts_marketing ?? null,
            partner_allow_paypal_transfer_backup: this.allow_paypal_transfer ?? null,
            partner_allow_bank_transfer_backup: this.allow_bank_transfer ?? null,
            partner_allow_crypto_transfer_backup: this.allow_crypto_transfer ?? null,
            partner_allow_create_customers_backup: this.allow_create_customers ?? null,

            custom_commission_backup: this.partner_commission.custom_commission,
            commission_backup: this.partner_commission.settings.commission,
            commission_type_backup: this.partner_commission.settings.commission_type,
            payout_threshold_backup: this.partner_commission.settings.payout_threshold,
            commission_period_type_backup: this.partner_commission.settings.commission_period_type,
            commission_period_times_value_backup: this.partner_commission.settings.commission_period_times_value,
            referring_partner_url: this.referring_partner_id ? route('programs.partner.show', {
                program: this.program_id,
                partner: this.referring_partner_id
            }) : null
        }
    },
    methods: {
        editPartner() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.editError = false;

            if (!this.disableKeyInput) {
                this.partner.key = this.partner_key
            }
            if (!this.disablePasswordInput) {
                this.partner.password = this.partner_password
            }

            this.addCommissionDataToPartnerObject();

            if (this.partner_cfields.length > 0) {
                this.addCustomFieldDataToPartnerObject();
            }

            axios
                .post(this.edit_url, this.partner)
                .then(response => {
                    if (response.status === 200) {
                        this.updatePartnerObject()
                        this.emitter.emit('modal-off', 'partner_profile_modal');
                        this.showNotification = true
                        this.notificationTitle = 'Done'
                        this.notificationType = 'success'
                        this.notificationMessage = 'Partner profile updated successfully'
                    } else {
                        this.showNotification = true
                        this.notificationTitle = 'Error'
                        this.notificationType = 'warning'
                        this.notificationMessage = 'Partner profile update error, see console for error details.'
                        this.editError = response.data.errors[0] ?? 'Failed to update partner';
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;

                    if (error.response.data.message) {
                        this.editError = error.response.data.message;
                        return;
                    }
                    this.editError = Array.isArray(errors) ? errors[0] : errors
                }).finally(() => this.loading = false);
        },
        deletePartner() {
            if (this.loading) {
                return;
            }

            if (this.deleteConfirmation.toLowerCase() !== 'delete') {
                this.deleteConfirmationError = true;
                return;
            }

            this.loading = true;
            this.deleteError = false;
            this.deleteConfirmationError = false;

            axios
                .post(this.delete_url)
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {
                        window.location.href = route('programs.partners.index', {program: this.program_id});
                    } else {
                        this.deleteError = response.data.errors[0] ?? 'Failed to delete partner';
                    }

                    this.deleteConfirmation = '';
                    const partnerStatus = response.data.partner_status ?? null;
                    if (partnerStatus !== null) {
                        this.partner.status = partnerStatus;
                    }
                    const acceptMarketing = response.data.accepts_marketing ?? null;
                    if (acceptMarketing !== null) {
                        this.partner.accepts_marketing = acceptMarketing;
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.deleteError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);
        },
        archivePartner() {
            if (this.loading || this.partner.deleted) {
                return;
            }

            this.loading = true;
            this.archiveError = false;

            axios
                .post(route('app_int.partner.archive', {program: this.program_id, partner: this.partner_id}), {})
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {
                        window.location.reload();
                    } else {
                        this.archiveError = response.data.errors[0] ?? 'Failed to archive partner';
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.archiveError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);
        },
        restoreArchivePartner() {
            if (this.loading || !this.partner.deleted) {
                return;
            }

            this.loading = true;
            this.archiveError = false;

            axios
                .post(route('app_int.partner.restore_archived', {
                    program: this.program_id,
                    partner: this.partner_id
                }), {})
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {
                        window.location.reload();
                    } else {
                        this.archiveError = response.data.errors[0] ?? 'Failed to archive partner';
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.archiveError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);
        },
        suspendPartner() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.suspendError = false;

            axios
                .post(this.suspend_url, {suspend_reason: this.suspendReason})
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {
                        this.partner.status = 'suspended';
                        this.emitter.emit('modal-off', 'partner_suspend_modal');
                    } else {
                        this.suspendError = response.data.errors[0] ?? 'Failed to suspend partner';
                    }

                    const partnerStatus = response.data.partner_status ?? null;
                    if (partnerStatus !== null) {
                        this.partner.status = partnerStatus;
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.suspendError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);

        },
        approvePartner() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.approveError = false;

            axios
                .post(this.approve_url)
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {
                        this.partner.status = 'active';
                        this.partner.approved = true;
                        this.emitter.emit('modal-off', 'partner_mark_active_modal');
                    } else {
                        this.approveError = response.data.errors[0] ?? 'Failed to activate partner';
                    }

                    const partnerStatus = response.data.partner_status ?? null;
                    const partnerApproved = response.data.partner_approved ?? null;

                    if (partnerStatus !== null) {
                        this.partner.status = partnerStatus;
                    }

                    if (partnerApproved !== null) {
                        this.partner.approved = partnerApproved;
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.approveError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);
        },
        restorePartner() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.suspendError = false;

            axios
                .post(this.restore_url)
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {

                        this.partner.status = 'active';
                        this.partner.deleted = response.data.deleted_at ?? false;

                        this.emitter.emit('modal-off', 'partner_restore_modal');
                    } else {
                        this.suspendError = response.data.errors[0] ?? 'Failed to suspend partner';
                    }

                    const partnerStatus = response.data.partner_status ?? null;
                    if (partnerStatus !== null) {
                        this.partner.status = partnerStatus;
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.suspendError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);

        },
        disablePartner2FA() {
            if (!this.loading) {
                return;
            }

            this.loading = true;
            this.disable2FAError = false;

            axios
                .post(this.disable_2fa_url)
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'partner_2fa_disable_modal');
                    } else {
                        this.disable2FAError = response.data.errors[0] ?? 'Failed to disable 2FA';
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors ?? false;
                    this.disable2FAError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);
        },
        partnerCommissionTypeChange: function (value) {
            this.commissionSettings.custom_commission = value === 'custom';
        },
        addCommissionDataToPartnerObject() {
            this.partner.use_custom = this.commissionSettings.custom_commission;
            this.partner.commission = this.commissionSettings.settings.commission;
            this.partner.commission_type = this.commissionSettings.settings.commission_type;
            this.partner.payout_threshold = this.commissionSettings.settings.payout_threshold;
            this.partner.commission_period_type = this.commissionSettings.settings.commission_period_type;
            this.partner.commission_period_times_value = this.commissionSettings.settings.commission_period_times_value;
        },
        addCustomFieldDataToPartnerObject() {
            this.partner.cfields = {};
            for (let index = 0; index < this.partner_cfields.length; index++) {
                this.partner.cfields[this.partner_cfields[index]['id']] = this.partner_cfields[index]['value'];
            }
        },
        transFormToGroupOptions(options) {
            return options.map((option, index) => {
                return {value: String(index), label: option};
            });
        },
        setActiveTab(name) {
            this.activeTabName = name
        },
        activeTab(name) {
            return name === this.activeTabName;
        },
        toggleKeyInput() {
            this.disableKeyInput = !this.disableKeyInput;
            if (this.disableKeyInput) {
                delete this.partner.key
            }
        },
        togglePasswordInput() {
            this.disablePasswordInput = !this.disablePasswordInput;
            if (this.disablePasswordInput) {
                delete this.partner.password;
            }
        },
        getRegexRuleCamelCase(regex) {
            return _.camelCase(regex);
        },
        setPartnerPassword(newPasswordValue) {
            this.partner_password = newPasswordValue;
        },
        disableOptionalFields() {
            this.disablePasswordInput = true;
            delete this.partner.password;
            this.disableKeyInput = true;
            delete this.partner.key;
        },
        modalClose() {
            // profile data
            this.partner_key = this.partner_key_backup ?? null;
            this.partner.email = this.partner_email_backup;
            this.partner.name = this.partner_name_backup;
            this.partner.surname = this.partner_surname_backup;
            this.partner.accepts_marketing = this.partner_accepts_marketing_backup;
            this.partner.allow_paypal_transfer = this.partner_allow_paypal_transfer_backup;
            this.partner.allow_bank_transfer = this.partner_allow_bank_transfer_backup;
            this.partner.allow_crypto_transfer = this.partner_allow_crypto_transfer_backup;
            this.partner.allow_create_customers = this.partner_allow_create_customers_backup;
            this.disableOptionalFields()

            // commissions settings
            this.commissionSettings.custom_commission = this.custom_commission_backup;
            this.commissionSettings.settings.commission = this.commission_backup;
            this.commissionSettings.settings.commission_type = this.commission_type_backup;
            this.commissionSettings.settings.payout_threshold = this.payout_threshold_backup;
            this.commissionSettings.settings.commission_period_type = this.commission_period_type_backup;
            this.commissionSettings.settings.commission_period_times_value = this.commission_period_times_value_backup;

        },
        updatePartnerObject() {
            // profile data
            this.partnerEmail = this.partner.email;
            this.partner_key_backup = this.partner.key ?? this.partner_key;
            this.partner_email_backup = this.partner.email;
            this.partner_name_backup = this.partner.name;
            this.partner_surname_backup = this.partner.surname;
            this.partner_accepts_marketing_backup = this.partner.accepts_marketing;
            this.partner_allow_paypal_transfer_backup = this.partner.allow_paypal_transfer;
            this.partner_allow_bank_transfer_backup = this.partner.allow_bank_transfer;
            this.partner_allow_crypto_transfer_backup = this.partner.allow_crypto_transfer;
            this.partner_allow_create_customers_backup = this.partner.allow_create_customers;
            this.disableOptionalFields()

            // commission settings
            this.custom_commission_backup = this.partner.use_custom;
            this.commission_backup = this.partner.commission;
            this.commission_type_backup = this.partner.commission_type;
            this.payout_threshold_backup = this.partner.payout_threshold;
            this.commission_period_type_backup = this.partner.commission_period_type;
            this.commission_period_times_value_backup = this.partner.commission_period_times_value;
        }
    },
    computed: {
        statusName() {
            if (this.partner.status === 'active' && this.partner.approved) {
                return 'Active' + (this.approval_request_status === 'approved' ? ' (Approved)' : '');
            } else if ((this.partner.status === 'active' && !this.partner.approved) || this.partner.status === 'approval_rejected') {
                if (this.approval_request_status === 'rejected' || this.partner.status === 'approval_rejected') {
                    return 'Rejected';
                } else if (this.approval_request_status === 'submitted') {
                    return 'Waiting for approval';
                }
                return 'Pending';
            } else if (this.partner.status === 'suspended') {
                return 'Suspended';
            } else if (this.partner.status === 'deleted') {
                return 'Deleted';
            }

            return this.partner.status;
        },
        commissionsCommissionOriginal() {
            const units = this.originalCommissions.commission_type === 'static' ? ' ' + this.originalCommissions.currency.toUpperCase() : '%'
            return this.originalCommissions.commission + units;
        },
        commissionsPeriodOriginal() {
            if (this.originalCommissions.commission_period_type === 'lifetime') {
                return 'Lifetime';
            }

            return this.originalCommissions.commission_period_times_value + ' ' + this.originalCommissions.commission_period_type
        },

        commissionsCommissionCustom() {
            const units = this.commissionSettings.settings.commission_type === 'static' ? ' ' + this.commissionSettings.settings.currency.toUpperCase() : '%'
            return this.commissionSettings.settings.commission + units;
        },
        commissionsPeriodCustom() {
            if (this.commissionSettings.settings.commission_period_type === 'lifetime') {
                return 'Lifetime';
            }

            return this.commissionSettings.settings.commission_period_times_value + ' ' + this.commissionSettings.settings.commission_period_type
        },

        commissionsThreshold() {
            return this.originalCommissions.payout_threshold;
        },
        commissionTypeSymbol() {

            if (this.commissionSettings.settings.commission_type === 'static') {
                return this.originalCommissions.currency.toUpperCase();
            }

            return '%';
        }
    },
    created() {
        this.parsed_password_regex = JSON.parse(this.password_regex)
    }
}
</script>
<style scoped>
</style>
