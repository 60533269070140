<script setup>
   import Spinner from "../../../../elements/Spinner.vue";
   import {onMounted, reactive, ref} from "vue";
   import {useCouponSyncStore} from "../../../../stores/app/programs/common/coupons/CouponSyncStore";
   import SyncCouponsBtnSettingsModal from "./SyncCouponsBtnSettingsModal.vue";

   const props = defineProps({
       programId: {
           type: Number,
           required: true
       },
       syncProviders: {
           type: Array,
           required: true
       }
   })

   const couponSyncStore = useCouponSyncStore()

   onMounted(() => {
       couponSyncStore.setProgramId(props.programId)
       couponSyncStore.setSyncProviders(props.syncProviders)
       props.syncProviders.forEach(syncProvider => {
           couponSyncStore.setSearchQueryProvider(syncProvider, '')
       })
   })

</script>

<template>
    <div>
        <options>
            <template #toggle>
                <btn type="secondary" icon-right="true" icon-name="more" size="large">
                    <div class="flex">
                        <spinner v-if="couponSyncStore.syncInProgress" class="mr-1.5"/>
                        {{ couponSyncStore.syncInProgress ? 'Syncing...' : 'Sync coupons' }}
                    </div>
                </btn>
            </template>
            <template  #items>
                <sync-coupons-btn-settings-modal
                    v-for="syncProvider in couponSyncStore.syncProviders"
                    :key="'coupons-sync-settings-' + syncProvider"
                    :program-id="programId" :sync-provider="syncProvider"
                ></sync-coupons-btn-settings-modal>
            </template>
        </options>
        <notification
            :show="couponSyncStore.notification.showNotification"
            :title="couponSyncStore.notification.notificationTitle"
            :type="couponSyncStore.notification.notificationType"
            class="mb-20"
            @notificationClosed="couponSyncStore.notification.showNotification = false"
            whitespace="whitespace-normal"
        >
            <template #message>
                {{ couponSyncStore.notification.notificationMessage }}
            </template>
        </notification>
    </div>
</template>

