<template>
    <div :class="singleNotification
            ? 'fixed inset-0 flex items-end pointer-events-none lg:p-8 p-6 justify-end z-50'
            : 'flex justify-end w-full'
    ">

        <transition
            enter-active-class="transform ease-out duration-300 transition"
            enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
        <div v-show="showNotification"
             class="lg:max-w-sm w-full bg-black bg-opacity-75 shadow-lg rounded-lg pointer-events-auto"
             style="backdrop-filter: blur(30px); -webkit-backdrop-filter: blur(30px);"
        >
            <div class="rounded-lg shadow-xs overflow-hidden">
                <div class="p-4">
                    <div class="flex items-start">
                        <div class="flex-shrink-0">
                            <!-- Heroicon name: x-circle -->
                            <svg v-if="type === 'warning'"
                                class="h-6 w-6 text-red-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <!-- Heroicon name: bell -->
                            <svg v-else-if="type === 'neutral'"
                                 xmlns="http://www.w3.org/2000/svg"
                                 class="h-6 w-6 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                            </svg>
                            <!-- Heroicon name: check-circle -->
                            <svg v-else
                                class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div class="ml-3 w-0 flex-1 pt-0.5">
                            <p class="text-sm leading-5 font-medium tracking-wide text-white">
                                {{ title || 'Done' }}
                            </p>
                            <p class="text-xs leading-5 tracking-wide text-white text-opacity-75"
                               :class="whitespace"
                            >
                                <slot name="message"></slot>
                            </p>
                        </div>
                        <div class="ml-4 flex-shrink-0 flex">
                            <btn @click.native="hideNotification" type="secondary-inverse" icon-name="close" size="small" rounded-full="true"></btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </transition>

    </div>
</template>


<script>
    export default {
        props: {
            type: '',
            title: '',
            details: '',
            show: {
                default: false
            },
            whitespace: {
                default: 'whitespace-pre'
            },
            singleNotification: {
                default: true
            }
        },

        data: function () {
            return {
                showNotification: this.show
            }
        },

        watch: {
            show(newValue) {
                this.showNotification = newValue
            }
        },

        methods: {
            hideNotification() {
                this.showNotification = false
                this.$emit('notificationClosed')
            }
        }
    }
</script>

