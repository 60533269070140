<template>
    <div>
        <modal
            :title="'Create a transaction'"
            size="small"
            ref="transactionModal"
        >
            <template #toggle>
                <btn
                    type="secondary"
                    @click="validationErrors = null"
                >
                    Create transaction
                </btn>
            </template>
            <template #body>
                <validation-errors :errors="validationErrors" class="mb-2"/>
                <div class="grid md:grid-cols-2 col-span-2 gap-8 gap-y-6">
                    <div class="col-span-2">
                        <text-field
                            v-model="transaction.key"
                            label="Transaction key"
                            field-id="transaction_key"
                            name="key"
                            type="text"
                            autofocus="true"
                            required="true">
                        </text-field>
                        <small class="block mt-2 leading-5 text-gray-500">Enter an unique key associated with your
                            application or payment provider.</small>
                    </div>
                    <div>
                        <text-field
                            v-model.number="transaction.amount"
                            label="Transaction amount"
                            field-id="transaction_amount"
                            name="amount"
                            type="text"
                            placeholder="E.g. 10"
                            required="true">
                        </text-field>
                        <small class="block mt-2 leading-5 text-gray-500">The amount of the purchase.</small>
                    </div>
                    <div>
                        <text-field
                            v-model="transaction.action"
                            label="Action"
                            field-id="transaction_action"
                            name="action"
                            type="text"
                            placeholder="E.g. Sale"
                            required="true">
                        </text-field>
                    </div>
                </div>
            </template>
            <template #footer>
                <btn button-type="button" @click="createTransaction" :disabled="creatingInProgress">
                    <spinner
                        v-if="creatingInProgress"
                        :is-white="true"
                        class="mr-1.5"
                    />
                    {{ creatingInProgress ? 'Creating...' : 'Create' }}
                </btn>
            </template>
        </modal>
    </div>
</template>

<script>
import useFilters from "../../../../../state/common/filters-and-pagination";
import ValidationErrors from "../../../../common/ValidationErrors";
import Spinner from "../../../../../elements/Spinner";
import {ref} from "vue";

export default {
    name: "CreateTransactionModal",
    components: {
        ValidationErrors,
        Spinner
    },
    props: {
        program: {
            type: String,
            required: true
        },
        partner: {
            type: [String, Number],
            required: true
        }
    },
    setup() {
        const creatingInProgress = ref(false)
        const filters = {}
        const {
            fetchDataBaseUrl,
            fetchDataFunction,
        } = useFilters()

        function fetchData() {
            fetchDataFunction.value(fetchDataBaseUrl.value, {...filters})
        }

        return {fetchData, creatingInProgress, filters}
    },
    data() {
        return {
            transaction: {
                key: '',
                amount: '',
                action: '',
                customer: {
                    key: ''
                }
            },
            validationErrors: null
        }
    },
    methods: {
        createTransaction() {

            this.validationErrors = null;
            this.creatingInProgress = true;
            this.transaction.customer.key = this.partner;

            axios.post(route('app_int.customer.transaction.create', {program: this.program}), this.transaction)
                .then(response => {
                    if (response.status === 200) {
                        this.closeTransactionModal();
                        this.resetFields(this.transaction);
                        this.creatingInProgress = false;
                        this.fetchData();
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        this.validationErrors = error.response?.data?.errors;
                    } else if (error.response?.data) {
                        this.validationErrors = {create: error.response?.data};
                    }
                })
                .finally(() => {
                    this.creatingInProgress = false;
                });
        },
        resetFields(item) {
            for (let key in item) {
                if (typeof item[key] === 'object' && item[key] !== null) {
                    this.resetFields(item[key]);
                } else {
                    item[key] = '';
                }
            }
        },
        closeTransactionModal() {
            this.$refs.transactionModal.onCancel();
        }
    }
}
</script>
