<template>
    <modal title="Add a subscriber" size="small" name="create_subscriber" @modalClose="modalClose" id="create-subscriber">
        <template #toggle>
            <btn type="primary">Add subscriber</btn>
        </template>
        <template #body>
            <div class="space-y-4">

                <alert type="error" v-if="errorMessage">{{ errorMessage }}</alert>
                <alert type="success" v-if="successMessage">{{ successMessage }}</alert>

                <div class="text-sm text-gray-500">
                    Manually add a subscriber, and once created, they will be added to your subscriber list.
                </div>

                <text-field label="Email address"
                            fieldId="email" @keydown.enter="createSubscriber"
                            autofocus="true"
                            placeholder="Enter subscriber's email address"
                            :error="(formErrors.email ?? false)"
                            v-model="email"
                ></text-field>

                <text-field label="Name (optional)"
                            fieldId="name" @keydown.enter="createSubscriber"
                            placeholder="Enter subscriber's name or leave it empty"
                            :error="(formErrors.name ?? false)"
                            v-model="name"
                ></text-field>

                <text-field v-if="!disableReferral"
                            label="Referring subscriber (optional)"
                            fieldId="referral" @keydown.enter="createSubscriber"
                            placeholder="Enter email address or leave it empty"
                            :error="(formErrors.referral ?? false)"
                            v-model="referral"
                >
                    <template #note>
                        Email address of the subscriber who referred this subscriber.
                    </template>
                </text-field>

            </div>
        </template>
        <template #footer>
            <btn :loading="loading" @click="createSubscriber" type="primary">Add</btn>
        </template>
    </modal>
</template>
<script>


import Icon from "../../../../../elements/Icon.vue";
import useFilters from "../../../../../state/common/filters-and-pagination";

export default {
    name: 'createSubscriber',
    components: {Icon},
    props: {
        program: {
            type: [String, Number, Object],
            required: true
        },
        referralEmail: {
            type: String,
            default: ''
        },
        disableReferral: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            errorMessage: false,
            successMessage: false,
            loading: false,
            formErrors: {},
            email: '',
            name: '',
            referral: this.referralEmail,
        }
    },
    methods: {
        createSubscriber() {

            this.errorMessage = false;
            this.successMessage = false;

            if (this.loading || !this.valid()) {
                return;
            }

            this.loading = true;

            axios
                .post(route('app_int.subscriber.create', { program: this.program }), {
                    email: this.email,
                    name: this.name,
                    referral: this.referral,
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {

                        const self = this;
                        this.successMessage = 'Subscriber created successfully.';

                        setTimeout(function () {
                            self.emitter.emit('modal-off', 'create_subscriber');
                            self.cleanForm();
                        }, 2000);

                        self.fetchSubscribersData()
                    } else {
                        this.error = 'Failed to create subscriber';
                    }
                })
                .catch(error => {
                    let errorMessage = error.response.data.error || false;
                    if (errorMessage) {
                        this.errorMessage = errorMessage;
                    }
                }).finally(() => this.loading = false);
        },
        valid() {

            let valid = true;
            this.formErrors = {};

            if (this.inputIsEmpty(this.email) || this.isInvalidEmail(this.email)){
                valid = false;
                this.formErrors.email = true;
            }

            if (this.name.length > 100) {
                valid = false;
                this.formErrors.name = true;
            }

            if (!this.inputIsEmpty(this.referral) && this.isInvalidEmail(this.referral)) {
                valid = false;
                this.formErrors.referral = true;
            }

            return valid;
        },
        modalClose(name) {
            this.cleanForm();
        },

        isInvalidEmail(email) {
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(email) !== true
        },
        cleanForm() {
            this.email = '';
            this.name = '';
            this.referral = this.referralEmail;
            this.errorMessage = false;
            this.successMessage = false;
            this.formErrors = {};
        },
        inputIsEmpty(value) {
            return (value ?? '').trim().length < 1;
        },
        fetchSubscribersData() {

            const {filters} = useFilters();
            const {
                fetchDataBaseUrl,
                fetchDataFunction,
            } = useFilters()

            fetchDataFunction.value(fetchDataBaseUrl.value, {...filters.value})
        }
    }
}
</script>
<style scoped>
</style>
