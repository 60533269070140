<template>

    <div class="space-y-6">

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the <badge type="neutral" title="PartneroJS snippet"></badge> on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>

        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Update your Calendly event</h3>
                <div class="text-sm space-y-2">
                    <p>In this step, you need to ensure that visitors are redirected back to your website, to a confirmation page, after a successful booking.</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Log in to your <b>Calendly</b> account, and edit the event you want to track</li>
                        <li>Go to <b>Booking page options</b> section</li>
                        <li>In <b>Confirmation page</b> -> <b>After booking</b> select <b>Redirect to an external site</b> and enter URL to your website's cofirmation page</li>
                        <li>Make sure <b>Pass event details to your redirected page</b> is checked</li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sign-ups tracking</h3>
                <div class="text-sm space-y-2">
                    <p>Paste the following script on the <b>confirmation</b> page:</p>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-2').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-2" rows="2" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs" v-pre><script>
po('integration', 'calendly', null);
</script></textarea>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sales tracking</h3>
                <div class="text-sm space-y-2">
                    <p>To complete the integration, you need to share details of successful sales with Partnero. You can do this in a few ways:</p>
                    <ul class="list-disc ml-4 space-y-0.5">
                        <li>Use <b>Stripe</b> or <b>Paddle Billing</b> integrations</li>
                        <li>Implementing tracking through the <a class="underline hover:no-underline" href="https://developers.partnero.com/guide/affiliate.html#sending-sales-data" target="_blank">Parterno API</a></li>
                        <li>Implementing tracking through the <a class="underline hover:no-underline" href="https://developers.partnero.com/guide/javascript-tracking.html#sales-tracking" target="_blank">Partnero JS</a></li>
                        <li>Use <b>Zapier</b> or <b>Make</b> to connect everything.</li>
                    </ul>
                </div>
            </div>
        </div>

    </div>

</template>
