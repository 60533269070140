<template>

    <h3 class="text-lg font-medium">About</h3>

    <div class="text-gray-500 text-sm">
        Integrating Partnero with Paddle streamlines the integration process, enabling you to easily track and log your customers' purchases while assigning these transactions to your affiliate partners.
    </div>

    <hr />

    <h3 class="text-lg font-medium">Integrating via API</h3>

    <div class="text-gray-500 text-sm">
        When you log transactions for a customer through Paddle, it's crucial to notify Partnero about the exact customer in question. At Partnero, we utilize a unique customer key to distinguish each client. You can assign any identifier to this key that you already use on your own platform to recognize the customer.
    </div>

    <h4 class="text-sm font-medium">Creating a customer</h4>

    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
POST https://api.paddle.com/customers
</code>
</pre>
<pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
{
    "name": "Sam Miller",
    "email": "sam@example.com"
    "custom_data": {
        "customer_key": "CUSTOMER_KEY_VALUE_HERE"
    }
}
</code>
    </pre>

    <h4 class="text-sm font-medium">Creating a transaction</h4>

    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
POST https://api.paddle.com/transactions

{
  "items": [
    {
      "quantity": 20,
      "price_id": "pri_01gsz91wy9k1yn7kx82aafwvea"
    },
    {
      "quantity": 1,
      "price_id": "pri_01gsz96z29d88jrmsf2ztbfgjg"
    }
  ],
  "customer_id": "ctm_01h8441jn5pcwrfhwh78jqt8hk",
  "address_id": "add_01h848pep46enq8y372x7maj0p",
  "currency_code": "USD",
  "collection_mode": "manual",
  "billing_details": {
    "enable_checkout": false,
    "purchase_order_number": "PO-123",
    "payment_terms": {
      "interval": "day",
      "frequency": 14
    }
  },
  "billing_period": {
    "starts_at": "2023-08-01T00:00:00Z",
    "ends_at": "2024-07-31T23:59:00Z"
  },
  "custom_data": {
       "customer_key": "CUSTOMER_KEY_VALUE_HERE"
  }
}
</code>
    </pre>

    <div class="text-gray-500 text-sm">
        For more information on how to add custom data to Paddle, please refer to the<a href="https://developer.paddle.com/build/transactions/custom-data" target="_blank" class="underline hover:no-underline">Paddle custom data documentation</a>.
    </div>

    <hr />

    <h3 class="text-lg font-medium">Front-end integration</h3>

    <div class="text-gray-500 text-sm">
        If you can access a customer key on the front end, you can configure settings for each checkout session initiated through a link or when the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">Paddle.Checkout.open()</span> function is executed.
    </div>

    <h4 class="text-sm font-medium">Passing data via HTML attributes</h4>

    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code v-pre>
&lt;a
    href="#"
    class="paddle_button"
    data-theme="light"
    data-items='[
      {
        "priceId": "pri_01hdzrq6nsbgbgwndhp3tpjjyz",
        "quantity": 1
      },
      {
        "priceId": "pri_01he2bv3m8asfd7caz1mf8vgzb",
        "quantity": 1
      }
    ]'
    data-custom-data='{
        "customer_key": "CUSTOMER_KEY_VALUE_HERE"
    }'
>
    Buy now
&lt;/a&gt;
</code>
    </pre>

    <h4 class="text-sm font-medium">Passing data via JavaScript properties</h4>

    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="javascript">
Paddle.Checkout.open({
  settings: {
    theme: "light",
  },
  items: [
    {
      priceId: 'pri_01gs59hve0hrz6nyybj56z04eq',
      quantity: 1
    },
    {
      priceId: 'pri_01gs59p7rcxmzab2dm3gfqq00a',
      quantity: 1
    }
  ],
  customData: {
    "customer_key": "CUSTOMER_KEY_VALUE_HERE"
  },
  customer: {
    email: "weloveyourproduct@paddle.com",
    address: {
      countryCode: "US",
    }
  }
});
</code>
    </pre>

    <div class="text-gray-500 text-sm">
        For more information on how to add settings to Paddle checkout, please refer to the <a href="https://developer.paddle.com/build/checkout/set-up-checkout-default-settings#checkout-settings" target="_blank" class="underline hover:no-underline">Paddle settings for each checkout</a>.
    </div>

</template>

<script setup>
</script>
