<script setup>

import { formatNumber } from "../../../../helpers/common";
import {computed} from "vue";

    const props = defineProps({
        pagination: {
            type: Object,
            required: true
        }
    });

    const emit = defineEmits(['fetchData']);

    const filteredLinks = computed(() => {
        return props.pagination?.links?.filter( link =>
            link.label !== 'Previous'
            && link.label !== 'Next'
        )
    });

    const fetchData = (url) => {
        emit('fetchData', url);
    }

</script>

<template>
    <div
        v-if="pagination.last_page > 1"
        class="py-6 px-8 border-t"
    >
        <nav
            role="navigation"
            aria-label="Pagination Navigation"
            class="flex justify-between items-center"
        >
            <div class="flex flex-1 justify-between sm:hidden">
            <span
                v-if="pagination.current_page <= 1"
                class="inline-flex relative items-center py-2 px-4 text-sm font-medium leading-5 bg-white bg-gray-200 rounded opacity-50 cursor-default"
            >
                    Previous
            </span>
                <a
                    v-else
                    class="inline-flex relative items-center py-2 px-4 text-sm font-medium leading-5 text-black text-opacity-75 bg-white bg-gray-200 rounded focus:outline-none hover:text-opacity-100 hover:bg-opacity-80"
                    @click.prevent="fetchData(pagination.prev_page_url)"
                >
                    Previous
                </a>

                <a
                    v-if="pagination.current_page < pagination.last_page"
                    class="inline-flex relative items-center py-2 px-4 ml-3 text-sm font-medium leading-5 text-black text-opacity-75 bg-white bg-gray-200 rounded focus:outline-none hover:text-opacity-100 hover:bg-opacity-80"
                    @click.prevent="fetchData(pagination.next_page_url)"
                >
                    Next
                </a>
                <span
                    v-else
                    class="inline-flex relative items-center py-2 px-4 ml-3 text-sm font-medium leading-5 bg-white bg-gray-200 rounded opacity-50 cursor-default"
                >
                    Next
                </span>
            </div>

            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p class="text-sm leading-5 opacity-80">
                        Showing
                        <span class="font-medium">{{ formatNumber(pagination.from) }}</span>
                        to
                        <span class="font-medium">{{ formatNumber(pagination.to) }}</span>
                        of
                        <span class="font-medium">{{ formatNumber(pagination.total) }}</span>
                        results
                    </p>
                </div>

                <div>
                <span class="relative z-0 inline-flex space-x-1.5">

                    <!-- Previous Page Link -->
                        <span
                            v-if="pagination.current_page <= 1"
                            aria-disabled="true" aria-label="Previous"
                        >
                            <span
                                class="inline-flex relative justify-center items-center w-9 h-9 text-sm font-medium leading-5 text-opacity-50 bg-gray-200 rounded opacity-50 cursor-default"
                                aria-hidden="true"
                            >
                                <svg
                                    class="w-5 h-5"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"/>
                                </svg>
                            </span>
                        </span>
                        <a
                            v-else
                            href="#" rel="prev"
                            class="inline-flex relative justify-center items-center w-9 h-9 text-sm font-medium leading-5 text-opacity-75 bg-gray-200 rounded hover:bg-opacity-80 focus:z-10 focus:outline-none text-black"
                            aria-label="Previous"
                            @click.prevent="fetchData(pagination.prev_page_url)"
                        >
                            <svg class="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path
                                d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"/></svg>
                        </a>
                    <!-- ./ Previous Page Link -->

                    <!-- Pagination Elements -->
                    <template
                        v-for="link in filteredLinks"
                    >
                        <!-- "Three Dots" Separator -->
                        <span
                            v-if="link.label === '...'"
                            aria-disabled="true">
                            <span
                                class="inline-flex relative justify-center items-center w-9 h-9 text-base font-medium leading-5 text-opacity-50 bg-gray-200 rounded opacity-50 cursor-default"
                                aria-hidden="true"
                            >
                                {{ link.label }}
                            </span>
                        </span>
                        <!-- ./ "Three Dots" Separator -->
                        <!-- Array Of Links -->
                        <span
                            v-else-if="link.label == pagination.current_page"
                            aria-current="page"
                        >
                            <span
                                class="inline-flex relative justify-center items-center -ml-px w-9 h-9 text-sm font-medium leading-5 bg-gray-200 rounded opacity-50 cursor-default"
                            >
                                {{ formatNumber(link.label) }}
                            </span>
                        </span>
                        <a
                            v-else
                            href="#"
                            class="inline-flex relative justify-center items-center -ml-px w-9 h-9 text-sm font-medium leading-5 text-opacity-75 bg-gray-200 rounded focus:z-10 hover:bg-opacity-80 focus:outline-none text-black"
                            :aria-label="'Go to page ' + link.label"
                            @click.prevent="fetchData(link.url)"
                        >
                            {{ formatNumber(link.label) }}
                        </a>
                        <!-- ./ Array Of Links -->
                    </template>
                    <!-- Next Page Link -->
                        <a
                            v-if="pagination.current_page < pagination.last_page"
                            href="#" rel="next"
                            class="inline-flex relative justify-center items-center -ml-px w-9 h-9 text-sm font-medium leading-5 text-opacity-75 bg-gray-200 rounded hover:bg-opacity-80 focus:z-10 focus:outline-none text-black"
                            aria-label="Next"
                            @click.prevent="fetchData(pagination.next_page_url)"
                        >
                            <svg
                                class="w-5 h-5"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"><path fill="none" d="M0 0h24v24H0z"
                            />
                                <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/>
                            </svg>
                        </a>
                        <span
                            v-else
                            aria-disabled="true" aria-label="Next"
                        >
                            <span
                                class="inline-flex relative justify-center items-center -ml-px w-9 h-9 text-sm font-medium leading-5 text-opacity-75 bg-gray-200 rounded opacity-50 cursor-default"
                                aria-hidden="true"
                            >
                                <svg
                                    class="w-5 h-5"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/>
                                </svg>
                            </span>
                        </span>
                    <!-- ./ Next Page Link -->
                </span>
                </div>
            </div>
        </nav>
    </div>
</template>

