<template>
  <div class="mt-10">
    <header-bar size="xsmall" title="Incoming webhooks">
      <template #actions>
        <Webhook-catcher-modal
            size="medium"
            :events="eventsList"
            :is-edit="false"
            :program="props.program"
            :new-catcher-secret-data="props.config.new_catcher_secret_data ?? {}"
            @submitSuccess="getCatchersList"
        ></Webhook-catcher-modal>
      </template>
    </header-bar>

    <progress-bar v-if="loading"/>

    <div class="bg-white rounded border" v-if="catcherslist.length <= 0">
      <div class="flex justify-center items-center text-center py-20">
        <div>
          <div class="flex justify-center items-center p-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"/>
              <path
                  d="M13.06 8.11l1.415 1.415a7 7 0 0 1 0 9.9l-.354.353a7 7 0 0 1-9.9-9.9l1.415 1.415a5 5 0 1 0 7.071 7.071l.354-.354a5 5 0 0 0 0-7.07l-1.415-1.415 1.415-1.414zm6.718 6.011l-1.414-1.414a5 5 0 1 0-7.071-7.071l-.354.354a5 5 0 0 0 0 7.07l1.415 1.415-1.415 1.414-1.414-1.414a7 7 0 0 1 0-9.9l.354-.353a7 7 0 0 1 9.9 9.9z"/>
            </svg>
          </div>
          <h2 class="font-semibold text-lg mb-2">No incoming webhooks</h2>
          <p class="text-gray-500 text-sm">Set up an incoming webhook to enable data creation on your account from webhooks sent by your application.</p>
        </div>
      </div>
    </div>

    <div v-else class="overflow-hidden bg-white rounded border sm:overflow-visible">
      <div class="overflow-x-auto sm:overflow-x-visible">
        <div class="inline-block min-w-full align-middle">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
              <th scope="col"
                  class="py-3 pr-4 pl-8 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                URL
              </th>
              <th scope="col"
                  class="py-3 px-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                Status
              </th>
              <th scope="col"
                  class="pr-8 pl-4 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                Actions
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="catcher in catcherslist" class="hover:bg-gray-50">
              <td class="py-4 pr-4 pl-8 text-sm font-semibold text-gray-900 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="flex-shrink-0 w-10 h-10">
                    <div
                        class="flex items-center justify-center font-medium text-sm text-gray-500 bg-gray-200 w-10 h-10 rounded">
                      {{ catcher.initial }}
                    </div>
                  </div>
                  <div class="ml-5">
                    <a href="#!" class="hover:text-teal-600">{{ catcher.name }}</a>
                    <div class="text-sm font-normal text-gray-500">
                      {{ catcher.url }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="py-4 px-4 text-base font-semibold text-gray-900 whitespace-nowrap">
                <span v-if="catcher.status === 'active'"
                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-teal-100 text-teal-800">{{
                    statusString(catcher)
                  }}</span>
                <span v-if="catcher.status === 'disabled'"
                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">{{
                    statusString(catcher)
                  }}</span>
              </td>
              <td valign="middle" class="flex justify-end py-5 pr-8 pl-4">

                <Webhook-catcher-modal
                    size="medium"
                    :events="eventsList"
                    :is-edit="true"
                    :catcher="catcher"
                    :program="props.program"
                    @submitSuccess="getCatchersList"
                ></Webhook-catcher-modal>

                <options class="inline-flex">
                  <template #toggle>
                    <btn type="secondary" size="small" icon-name="more"
                         class="rounded-l-none"></btn>
                  </template>
                  <template #items>

                    <options-item
                        :title="actionString(catcher)"
                        @click="updateStatus(catcher)"
                    ></options-item>
                    <options-divider></options-divider>
                    <options-item title="Delete" @click="deleteCatcher(catcher)"></options-item>

                  </template>
                </options>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>

import {ref} from "vue";

import WebhookCatcherModal from './WebhookCatcherModal.vue';
import ProgressBar from "../../../../elements/ProgressBar.vue";
import HeaderBar from "../../../../elements/HeaderBar.vue";

const props = defineProps({
  config: {
    type: Object,
    default: {
      actions: {},
      catchers: []
    }
  },
  program: {
    type: Number,
    required: true
  }
});

const loading = ref(false);
const eventsList = ref(props.config.events);
const catcherslist = ref(props.config.catchers || []);

const getCatchersList = (actionResponse) => {

  loading.value = true;

  axios.get(route('app_int.webhooks_catchers.get_list', {program: props.program}))
      .then(response => {
        const status = parseInt(response.data.status || -1);

        if (status === 1) {
          catcherslist.value = response.data.data.catchers || [];
        }
      })
      .finally(() => loading.value = false);
}
const deleteCatcher = (catcher) => {

  if (loading.value) {
    return;
  }

  loading.value = true;

  axios.delete(route('app_int.webhooks_catchers.delete', {program: props.program, secret: catcher.secret}))
      .then(response => {
        const status = parseInt(response.data.status || -1);

        if (status === 1) {
          getCatchersList();
        }
      })
      .finally(() => loading.value = false);
}
const updateStatus = (catcher) => {

  if (loading.value) {
    return;
  }

  loading.value = true;

  axios.post(route('app_int.webhooks_catchers.update', {program: props.program, secret: catcher.secret}), {
    catcher_data: {status: differentStatus(catcher)}
  })
      .then(response => {
        const status = parseInt(response.data.status || -1);

        if (status === 1) {
          getCatchersList();
        }
      })
      .finally(() => loading.value = false);
}

const statusString = (catcher) => {
  if (catcher.status === 'active') {
    return 'Active';
  }

  if (catcher.status === 'disabled') {
    return 'Disabled';
  }

  return 'n/a';
};

const actionString = (catcher) => {
  if (catcher.status === 'active') {
    return 'Disable';
  }

  if (catcher.status === 'disabled') {
    return 'Activate';
  }

  return 'n/a';
};

const differentStatus = (catcher) => {
  if (catcher.status === 'active') {
    return 'disabled';
  }

  if (catcher.status === 'disabled') {
    return 'active';
  }

  return 'n/a';
};

</script>

<style scoped>

</style>
