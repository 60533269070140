<template>
    <modal :title="'Reset to default template'" size="small" name="reset_to_default_modal">
        <template #toggle>
            <options-item title="Reset to default template" />
        </template>
        <template #body>
            {{ bodyText }}
        </template>
        <template #footer>
            <btn @click="emailStore.resetToDefaultTemplate()">Yes, reset template</btn>
        </template>
    </modal>
</template>

<script setup>
import {useRevolvappEmailStore} from "../../../../../../stores/app/emails/revolvapp/RevolvAppEmailStore";

    defineProps({
        bodyText: {
            type: String,
            default: () => ('')
        }
    })

const emailStore = useRevolvappEmailStore()
</script>

