<template>
    <div>
        <label :for="input_names.select" class="block mb-1.5 text-sm">{{ text.name }}</label>
        <div class="grid gap-1" :class="types_value_disabled ? 'grid-cols-1' : 'grid-cols-2'">
            <select :id="input_names.select" :name="input_names.select" v-model="type" @change="typeChange($event)"
                    class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                    :disabled="disabled"
            >
                <option value="lifetime">Lifetime</option>
                <option v-if="syncProvider !== 'shopify'" value="months">Months</option>
                <option value="once">Once</option>
            </select>
            <text-field
                v-if="!types_value_disabled"
                v-model="times_value"
                :name="input_names.input"
                :autofocus="true"
                :disabled="types_value_disabled || disabled"
                :required="!types_value_disabled"
            >
            </text-field>
        </div>
        <small class="text-gray-500 mt-1 block">{{ text.description }}</small>
    </div>
</template>
<script>
export default {
    emits: ['update:type', 'update:value'],
    name: 'CouponDuration',
    props: {
        coupon_duration_type: {
            type: String,
            default: 'lifetime'
        },
        coupon_duration_value: {
            type: [String, Number],
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        select_name: {
            type: String,
            default: ''
        },
        input_name: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        syncProvider: {
            type: [String, null],
            default: null
        }
    },
    data() {
        return {
            text: {
                name: this.name || 'Coupon period',
                description: this.description || 'Choose how long the rewards will be active for.',
            },
            input_names: {
                select: this.select_name || 'coupon_period_type',
                input: this.input_name || 'coupon_period_times_value',
            },
            type: this.coupon_duration_type,
            times_value: this.coupon_duration_value,
        }
    },
    computed: {
        types_value_disabled() {
            return this.type === 'lifetime' || this.type === 'once';
        }
    },
    methods: {
        typeChange(event) {
            this.$emit('update:type', event);
        },
        valueChange(value) {
            this.$emit('update:value', value);
        },
    }
}
</script>
<style scoped>
</style>
