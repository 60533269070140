<template>
    <modal :title="isEdit ? 'Update webhook' : 'Create webhook'" size="small" name="webhook_modal">

        <template #toggle v-if="isEdit">
            <btn type="secondary" size="small" class="-mr-px rounded-r-none focus:z-10">
                Manage
            </btn>
        </template>
        <template #toggle v-else>
            <btn type="primary">
                Create webhook
            </btn>
        </template>

        <template #body>
            <div class="space-y-4">

                <div v-if="errors.length > 0"
                    class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm mb-6"
                    role="alert"
                >
                    <ul><li v-for="error in errors">{{ error }}</li></ul>
                </div>

                <div>
                    <text-field
                        v-model="modalWebhook.name"
                        label="Webhook name"
                        id="name"
                        name="name"
                        type="text">
                    </text-field>
                </div>

                <div>
                    <text-field
                        v-model="modalWebhook.url"
                        label="Endpoint URL"
                        id="url"
                        name="url"
                        type="text"
                        placeholder="https://">
                    </text-field>
                </div>

                <select-field
                    label="Events to send"
                    id="events"
                    name="events"
                    :model-value="selectedEvent"
                    @update:model-value="addEvent"
                >
                    <option value=""></option>
                    <option v-for="event in events" :value="event">{{ event }}</option>
                </select-field>

                <div v-if="modalWebhook.events.length <= 0"
                    class="flex justify-center rounded-md border border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div class="space-y-1 text-center">
                        <p class="text-sm text-gray-600">No events selected</p>
                        <p class="text-xs text-gray-500">
                            Search for events using the dropdown menu above, or choose to receive
                            <a class="underline" @click="addAllEvents()">all events</a>.
                        </p>
                    </div>
                </div>

                <div v-else
                    class="rounded border border-gray-300 divide-y divide-gray-300 overflow-hidden">
                    <div v-for="event in modalWebhook.events">
                        <div class="flex items-center py-2 px-4 hover:bg-gray-50">
                            <div class="text-xs">{{ event }}</div>
                            <div class="ml-auto">
                                <btn type="light" icon-name="close" size="pill" @click="removeEvent(event)">Delete</btn>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="isEdit" class="pt-8">

                    <div class="flex items-end space-x-5">
                        <div class="w-full">
                            <text-field
                                v-show="signatureType === 'password'"
                                label="Signing secret"
                                type="password"
                                name="signature"
                                disabled="disabled"
                                class="w-full"
                                model-value="********"
                            ></text-field>
                            <text-field
                                v-show="signatureType === 'text'"
                                label="Signing secret"
                                type="text"
                                name="signature"
                                disabled="disabled"
                                class="w-full"
                                :model-value="modalWebhook.signature"
                            ></text-field>
                        </div>
                        <btn class="w-1/4" type="light" @click="toggleReveal">Reveal</btn>
                    </div>

                </div>

            </div>
        </template>

        <template #footer>
            <btn type="primary" :loading="loading" @click="submitHandler">
                <span v-if="isEdit">Update</span>
                <span v-else>Create</span>
            </btn>
        </template>
    </modal>
</template>

<script>
export default {
    name: 'WebhookModal',
    props: {
        submitUrl: {
            type: String,
            required: true
        },
        webhook: {
            type: String
        },
        isEdit: {
            type: Boolean,
            required: true
        },
        events: {
            type: Array,
            required: true
        }
    },
    watch: {
        webhook (val, oldVal) {
            this.modalWebhook = JSON.parse(val);
        }
    },
    data () {
        return {
            errors: [],
            loading: false,
            modalWebhook: this.webhook ? JSON.parse(this.webhook) : this.defaultWebhook(),
            signatureType: 'password',
            selectedEvent: '',
        }
    },
    methods: {
        defaultWebhook () {
            return {
                name: '',
                url: '',
                events: []
            };
        },
        submitHandler () {
            if (this.loading) {
                return;
            }

            this.loading = true;

            let submitRequest = this.isEdit
                                ? axios.put(this.submitUrl, this.modalWebhook)
                                : axios.post(this.submitUrl, this.modalWebhook);
            submitRequest
                .then(response => {
                    const status = parseInt(response.data.status || -1);

                    if (status === 1) {
                        this.emitter.emit('modal-off', 'webhook_modal');
                        this.$emit('submitSuccess');

                        // reset modal UI
                        this.errors = [];
                        if(!this.isEdit) {
                            this.modalWebhook = this.defaultWebhook();
                        }
                    }

                    if(status === 0) {
                        this.errors = response.data.errors || [];
                    }
                })
                .catch(error => {
                    this.errors = this.extractErrors(error.response.data);
                })
                .finally(() => this.loading = false);
        },
        addEvent (value) {
            if(value && !this.modalWebhook.events.includes(value)) {
                this.modalWebhook.events.push(value);
            }
        },
        removeEvent (value) {
            let index = this.modalWebhook.events.indexOf(value);
            if(index > -1) {
                this.modalWebhook.events.splice(index, 1);
            }
        },
        addAllEvents () {
            let select = document.getElementById('events');
            if(select) {
                select.options.forEach((option) => this.addEvent(option.value));
            }
        },
        toggleReveal () {
            this.signatureType = this.signatureType === 'password' ? 'text' : 'password';
        },
        extractErrors (responseData) {
            if(responseData.message) {
                return [responseData.message];
            }

            if(responseData.errors && responseData.errors.length) {
                return responseData.errors;
            }

            return [];
        }
    }
}
</script>
