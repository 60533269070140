import {ref} from "vue";
import {setValidationErrors} from '../../state/common/filters-and-pagination'

const filtersAndPaginationData = ref({})
const loadingFiltersAndPaginationData = ref(false)

export default function useRecentActivities() {

    const getFiltersAndPaginationData = async (url, filters = {}) => {
        setValidationErrors(null);
        loadingFiltersAndPaginationData.value = true
        axios.get(url, {params: filters})
            .then(response => {
                filtersAndPaginationData.value = response.data
            })
            .catch(error => {
                setValidationErrors(error.response?.data?.errors);
            })
            .finally(() => loadingFiltersAndPaginationData.value = false);
    }

    return {
        loadingFiltersAndPaginationData,
        filtersAndPaginationData,
        getFiltersAndPaginationData
    }
}
