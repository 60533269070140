<script setup>
import TextField from "../../../../../elements/forms/TextField.vue";
import {ref} from "vue";
import ValidationErrors from "../../../../common/ValidationErrors.vue";
import Spinner from "../../../../../elements/Spinner.vue";
import {brandNamesMapper} from "../../../../../helpers/common";

const props = defineProps({
    programId: {
        type: Number,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    provider: {
        type: String,
        required: true
    },
    customerIdentifierHolderInMetadata: {
        type: [String, null],
        default: ''
    },
    fieldToLookIn: {
        type: String,
        default: 'metadata'
    }
})

const identifierHolderInMetadata = ref(props.customerIdentifierHolderInMetadata ?? '')

const savingSettings = ref(false)

const settingName = {
    stripe: 'setting_integration_stripe_customer_identifier_mapping',
    paddle: 'setting_integration_paddle_customer_identifier_mapping',
}

const updateCustomerIdentifierHolder = () => {
    savingSettings.value = true
    axios.post(route('app_int.integrations.settings.update', {program: props.programId}), {
            [settingName[props.provider]]: identifierHolderInMetadata.value
        }).then((response) => {
            const status = parseInt(response.data.status ?? -1);
            if (status === 1) {
                window.location.reload();
            }
        })
        .catch((error) => {
            errors.value = error.response?.data?.errors;
        })
        .finally(() => {
            savingSettings.value = false
        })
}

const errors = ref(null)

</script>

<template>
    <modal :title="title" size="small">
        <template #toggle>
            <btn type="light" size="small" class="-mr-px focus:z-10">Mapping</btn>
        </template>
        <template #body>
            <validation-errors :errors="errors" />
            <div class="space-y-4">
                <div class="text-sm space-y-2">
                    <div>Use additional mapping to connect a customer on Partnero with {{ brandNamesMapper[provider] }} data.</div>
                    <div>Look for customer key in <i>{{ fieldToLookIn }}</i> field:</div>
                </div>
                <text-field v-model="identifierHolderInMetadata" placeholder="e.g. customer_key"></text-field>
            </div>
        </template>
        <template #footer>
            <btn type="primary" @click="updateCustomerIdentifierHolder">
                <spinner
                    v-if="savingSettings"
                    :is-white="true"
                    class="mr-1.5"
                />
                {{ savingSettings ? 'Saving...' : 'Save' }}
            </btn>
        </template>
    </modal>
</template>

<style scoped>

</style>
