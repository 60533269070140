/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import { createApp } from 'vue';
import store from '../../packages/filemanager/resources/js/store';
import App from './components/App.vue';

const app = createApp(App);

import mitt from 'mitt';

app.config.globalProperties.emitter = mitt();
app.provide('emitter', app.config.globalProperties.emitter);

// /**
//  * The following block of code may be used to automatically register your
//  * Vue components. It will recursively scan this directory for the Vue
//  * components and automatically register them with their "basename".
//  *
//  * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
//  */

// // const files = require.context('./', true, /\.vue$/i)
// // files.keys().map(key => app.component(key.split('/').pop().split('.')[0], files(key).default);)

app.component('two-factor-auth-enable-modal', require('./components/vendor/accounts/TwoFactorAuthEnableModal.vue').default)
app.component('two-factor-auth-disable-modal', require('./components/vendor/accounts/TwoFactorAuthDisableModal.vue').default)

app.component('alert', require('./elements/Alert.vue').default);
app.component('btn', require('./elements/Btn.vue').default);
app.component('card', require('./elements/cards/Card.vue').default);
app.component('card-navigation', require('./elements/cards/CardNavigation.vue').default);
app.component('notification', require('./elements/Notification.vue').default);
app.component('icon', require('./elements/Icon.vue').default);
app.component('header-bar', require('./elements/HeaderBar.vue').default);
app.component('setup-guide', require('./components/app/views/program/SetupGuide.vue').default);
app.component('newsletter-setup-guide', require('./components/app/views/program/NewsletterSetupGuide.vue').default);
app.component('referral-setup-guide', require('./components/app/views/program/ReferralSetupGuide').default);
app.component('modal', require('./elements/Modal.vue').default);
app.component('modal-upload', require('./elements/ModalUpload.vue').default);
app.component('sidebar-menu', require('./elements/sidebar/SidebarMenu.vue').default);
app.component('text-editor', require('./elements/TextEditor').default);
app.component('tabs', require('./elements/tabs/Tabs').default);
app.component('tab', require('./elements/tabs/Tab').default);
app.component('modal-tabs', require('./elements/tabs/ModalTabs').default);
app.component('modal-tab', require('./elements/tabs/ModalTab').default);
app.component('sub-tabs', require('./elements/tabs/SubTabs').default);
app.component('sub-tab', require('./elements/tabs/SubTab').default);
app.component('sub-tabs-links', require('./elements/tabs/SubTabsLinks').default);
app.component('sub-tab-link', require('./elements/tabs/SubTabLink').default);
app.component('tooltip', require('./elements/Tooltip.vue').default);
app.component('badge', require('./elements/Badge.vue').default);
app.component('content-accordion', require('./elements/Accordion.vue').default);
app.component('white-card', require('./elements/WhiteCard.vue').default);
app.component('account-profile-country-select', require('./components/account-profile/AccountProfileCountrySelect.vue').default);
app.component('program-initials', require('./elements/ProgramInitials').default);
app.component('integration-guide-banner', require('./components/app/views/program/IntegrationGuideBanner').default);

// Accordions (We have different types of Accordions add it in same directory here)
app.component('draggable-accordion', require('./elements/accordions/DraggableAccordion.vue').default);

// Forms

app.component('form-layout', require('./elements/forms/FormLayout.vue').default);
app.component('form-section', require('./elements/forms/FormSection.vue').default);
app.component('field-label', require('./elements/forms/FieldLabel.vue').default);
app.component('text-field', require('./elements/forms/TextField.vue').default);
app.component('select-field', require('./elements/forms/SelectField.vue').default);

app.component('options', require('./elements/options/Options.vue').default);
app.component('options-item', require('./elements/options/OptionsItem.vue').default);
app.component('options-toggle', require('./elements/options/OptionsToggle.vue').default);
app.component('options-divider', require('./elements/options/OptionsDivider.vue').default);

app.component('password', require('./elements/forms/Password.vue').default);
app.component('email', require('./elements/forms/Email.vue').default);
app.component('toggle', require('./elements/forms/Toggle.vue').default);
app.component('radio', require('./elements/forms/Radio.vue').default);
app.component('radio-default', require('./elements/forms/RadioDefault.vue').default);
app.component('checkbox', require('./elements/forms/Checkbox.vue').default);
app.component('checkbox-group', require('./elements/forms/CheckboxGroup.vue').default);
app.component('radio-group', require('./elements/forms/RadioGroup.vue').default);
app.component('radio-group-default', require('./elements/forms/RadioGroupDefault.vue').default);
app.component('radio-group-inline', require('./elements/forms/RadioGroupInline.vue').default);
app.component('radio-group-inline-color', require('./elements/forms/RadioGroupInlineColor.vue').default);
app.component('radio-group-inline-custom-color', require('./elements/forms/RadioGroupInlineCustomColor.vue').default);
app.component('require-password-change-modal', require('./elements/forms/RequirePasswordChangeModal.vue').default);
app.component('file-upload', require('./elements/forms/FileUpload').default);
app.component('account-profile', require('./components/account-profile/AccountProfile.vue').default);
app.component('hamburger', require('./components/Hamburger.vue').default);
app.component('date-range-picker', require('./components/partners/DateRangePicker').default);
app.component('datepicker', require('./elements/forms/Datepicker.vue').default);
app.component('import-data', require('./components/app/programs/imports/Import.vue').default);
app.component('customer-imports-list', require('./components/app/programs/imports/CustomerImportsList.vue').default);

// App
app.component('fields-builder', require('./components/app/fields-builder/FieldsBuilder.vue').default);
app.component('user-email-verification', require('./components/app/views/UserEmailVerificationCheck.vue').default);
app.component('program-custom-domains', require('./components/app/ProgramCustomDomain.vue').default);
app.component('program-custom-links', require('./components/app/ProgramCustomLinks').default);
app.component('program-additional-links', require('./components/app/ProgramAdditionalLinks').default);
app.component('program-trusted-domains', require('./components/app/TrustedDomains').default);
app.component('preboarding-settings', require('./components/app/programs/preboarding/PreboardingSettings.vue').default);

app.component('base-resources-builder', require('./components/app/resources-builder/Builder.vue').default);
app.component('resources-builder', require('./components/app/resources/ResourcesBuilder.vue').default);
app.component('social-share-section', require('./components/app/resources/social-share/SocialShareSection').default);
app.component('dashboard-resources-builder', require('./components/app/preferences/pages/dashboard/DashboardResourcesBuilder.vue').default);
app.component('partnero-branding-setting', require('./components/app/preferences/settings/PartneroBrandingSetting.vue').default);
app.component('portal-widget-setting', require('./components/app/preferences/settings/PortalWidgetSetting.vue').default);
app.component('api-key-modal', require('./components/app/ApiKeyModal').default);
app.component('email-settings', require('./components/app/emails/settings/EmailSettings.vue').default);
app.component('emails-gateway-connect', require('./components/app/emails/GatewayConnect').default);
app.component('emails-sender-email', require('./components/app/emails/SenderEmail').default);
app.component('revolvapp-emails-group', require('./components/app/emails/EmailsList').default);
app.component('send-test-email', require('./components/app/emails/SendTestEmail').default);
app.component('filemanager-wrapper', require('./components/app/filemanager/FilemanagerWrapper').default);
app.component('program-delete', require('./components/app/ProgramDelete').default);
app.component('program-reset', require('./components/app/ProgramReset').default);
app.component('program-duplicate', require('./components/app/ProgramDuplicate').default);
app.component('update-nrp-button', require('./components/app/programs/newsletter/esp/components/UpdateNrpButton.vue').default);
app.component('update-nrp-settings-button', require('./components/app/programs/newsletter/esp/components/UpdateNrpSettingsButton.vue').default);
app.component('email-logo-update-confirmation', require('./components/app/emails/LogoUpdateConfirmation').default);
app.component('partner-ref-links', require('./components/app/programs/partners/RefLinks').default);
app.component('partner-add-reward', require('./components/app/programs/partners/AddReward').default);
app.component('partner-profile', require('./components/app/programs/partners/Profile').default);
app.component('subscriber-profile', require('./components/app/programs/newsletter/subscribers/Profile').default);
app.component('refer-a-friend-customer-profile', require('./components/app/programs/customers/referAFriend/Profile.vue').default);
app.component('commission-period_setting', require('./components/app/programs/settings/CommissionPeriod').default);
app.component('commission-trigger_period_setting', require('./components/app/programs/settings/CommissionTriggerPeriod').default);
app.component('commission-rounding_setting', require('./components/app/programs/settings/CommissionRounding').default);
app.component('commission-review_period_setting', require('./components/app/programs/settings/CommissionReviewPeriod').default);
app.component('commission-maximum_commission_value', require('./components/app/programs/settings/MaximumCommissionValue.vue').default);
app.component('commission-claim_period_setting', require('./components/app/programs/settings/CommissionClaimPeriod').default);
app.component('commission-clients_threshold_setting', require('./components/app/programs/settings/CommissionClientsThreshold').default);
app.component('fraud-detection-settings', require('./components/app/programs/settings/FraudDetectionSettings.vue').default);
app.component('utm-tracking-settings', require('./components/app/programs/settings/UtmTrackingSettings.vue').default);
app.component('commission-clients_payout_invoice', require('./components/app/programs/settings/CommissionClientsPayoutInvoice.vue').default);
app.component('integrations', require('./components/app/integrations/Integrations').default);
app.component('integration-tester', require('./components/app/integrations/Tester').default);
app.component('webhooks-section', require('./components/app/webhooks/Section').default);
app.component('webhooks-catchers', require('./components/app/programs/webhooks-catcher/WebhookCatchers.vue').default);
app.component('integration-mailerlite', require('./components/app/integrations/mailerlite/Connect').default);
app.component('integration-paypal-mass-payout', require('./components/app/integrations/paypal/ConnectMassPayout').default);
app.component('integration-stripe', require('./components/app/integrations/stripe/IntegrationStripe.vue').default);
app.component('integration-stripe-simple', require('./components/app/integrations/stripe/IntegrationStripeSimple.vue').default);
app.component('integration-mailchimp', require('./components/app/integrations/mailchimp/Connect').default);
app.component('integration-amazon-gift-card', require('./components/app/integrations/amazon-gift-card/Integration').default);
app.component('integration-wise-mass-payout', require('./components/app/integrations/wise/ConnectMassPayout').default);
app.component('integration-woocommerce', require('./components/app/integrations/woocommerce/IntegrationWoocommerce').default);
app.component('integration-shopify', require('./components/app/integrations/shopify/IntegrationShopify').default);
app.component('translation-settings', require('./components/app/translations/TranslationSettings.vue').default);

app.component('payouts-stats', require('./components/app/views/payouts/Stats').default);
app.component('payouts-analytics', require('./components/app/views/payouts/analytics/ProgramAnalyticsStats').default);
app.component('intermediary-payouts-settings', require('./components/app/views/payouts/IntermediaryPayouts.vue').default);
app.component('intermediary-payouts-list', require('./components/app/programs/payouts/IntermediaryPayoutsList.vue').default);
app.component('payouts-insights', require('./components/app/views/payouts/analytics/PayoutInsights').default);
app.component('gateway-payout-file-upload', require('./components/app/views/payouts/GatewayPayoutFileUpload').default);
app.component('paypal-payout-through-api', require('./components/app/views/payouts/paypal/PayoutThroughApi.vue').default);
app.component('bank-transfer-payout-through-api', require('./components/app/views/payouts/bank-transfer/PayoutThroughApi.vue').default);
app.component('approvals-stats', require('./components/app/views/approvals/Stats').default);
app.component('affiliate-program-stats', require('./components/app/views/program/AffiliateStats').default);
app.component('affiliate-program-additional-stats', require('./components/app/views/program/AffiliateAdditionalStats').default);
app.component('referral-program-stats', require('./components/app/views/program/ReferralStats').default);
app.component('newsletter-program-stats', require('./components/app/views/program/NewsletterProgramStats.vue').default);
app.component('dashboard-stats', require('./components/app/views/DashboardStats').default);

app.component('affiliate-program-portal-partner-dashboard-appearance-settings', require('./components/app/programs/portal/settings/affiliate/PartnerDashboardAppearance.vue').default);
app.component('affiliate-program-portal-public-pages-appearance-settings', require('./components/app/programs/portal/settings/affiliate/PublicPagesAppearance.vue').default);

app.component('invite-partner-to-program', require('./components/app/programs/partners/Invite').default);
app.component('create-partner-in-program', require('./components/app/programs/partners/Create').default);
app.component('program-commissions-settings-referral', require('./components/app/programs/commissions/Referral').default);

app.component('program-commissions-settings-affiliate', require('./components/app/programs/commissions/Affiliate').default);
app.component('program-commissions-settings-affiliate-multi-tier', require('./components/app/programs/commissions/affiliate/MultiTierAffiliateCommissions.vue').default);
app.component('program-commissions-settings-affiliate-dynamic', require('./components/app/programs/commissions/affiliate/Dynamic').default);
app.component('program-commissions-settings-affiliate-product', require('./components/app/programs/commissions/affiliate/Product').default);
app.component('program-commissions-settings-affiliate-goal', require('./components/app/programs/commissions/affiliate/Goal').default);
app.component('program-commissions-settings-newsletter', require('./components/app/programs/commissions/Newsletter.vue').default);
app.component('program-commissions-delete', require('./components/app/programs/commissions/DeleteCommission.vue').default);

app.component('program-email-notification', require('./components/app/programs/notifications/EmailNotification').default);

// Landing Page
app.component('landing-page', require('./components/app/preferences/pages/landing-page/LandingPage.vue').default);

// Newsletter ESP's
app.component('esp-mailerlite', require('./components/app/programs/newsletter/esp/MailerLite.vue').default);
app.component('esp-mailerlite-classic', require('./components/app/programs/newsletter/esp/MailerLiteClassic.vue').default);
app.component('esp-mailchimp', require('./components/app/programs/newsletter/esp/Mailchimp.vue').default);
app.component('esp-convertkit', require('./components/app/programs/newsletter/esp/ConvertKit.vue').default);
app.component('esp-convertkit-template-snippet', require('./components/app/programs/newsletter/esp/instructions/ConvertKitEmailTemplateCodeSnippet.vue').default);
app.component('esp-klaviyo-template-snippet', require('./components/app/programs/newsletter/esp/instructions/KlaviyoEmailTemplateCodeSnippet.vue').default);
app.component('esp-klaviyo', require('./components/app/programs/newsletter/esp/Klaviyo.vue').default);
app.component('esp-activecampaign', require('./components/app/programs/newsletter/esp/ActiveCampaign.vue').default);
app.component('esp-custom-subscribers-import', require('./components/app/programs/newsletter/esp/CustomSubscribersImport.vue').default);

// Partnero Refer-A-Friend
app.component('partnero-refer-a-friend', require('./components/app/refer-a-friend/Index').default);

// Socials
app.component('social-auth-btn', require('./components/app/socials/AuthBtn.vue').default);
app.component('post-social-register-modal', require('./components/app/socials/PostRegisterModal.vue').default);

// Coupons
app.component('coupon-index', require('./components/app/programs/coupons/CouponIndex.vue').default);
app.component('coupon', require('./components/app/programs/coupons/Coupon.vue').default);
app.component('coupon-edit-and-details', require('./components/app/programs/coupons/CouponEditAndDetails.vue').default);
app.component('sync-coupons-btn', require('./components/app/programs/coupons/SyncCouponsBtn.vue').default);
app.component('new-coupon-btn', require('./components/app/programs/coupons/NewCouponBtn.vue').default);
app.component('delete-coupon-btn', require('./components/app/programs/coupons/DeleteCouponBtn.vue').default);
app.component('program-coupons-notifications', require('./components/app/programs/coupons/ProgramCouponsNotifications.vue').default);

// Promotion Codes
app.component('partner-promotion-code-list', require('./components/app/programs/coupons/promotion-codes/PartnerPromotionCodeList.vue').default);
app.component('classic-promotion-code-list', require('./components/app/programs/coupons/promotion-codes/ClassicPromotionCodeList.vue').default);
app.component('auto-assign-promotion-code-list', require('./components/app/programs/coupons/promotion-codes/AutoAssignPromotionCodeList.vue').default);
app.component('promotion-code-modal', require('./components/app/programs/coupons/promotion-codes/PromotionCodeModal.vue').default);
app.component('auto-assign-promotion-code-modal', require('./components/app/programs/coupons/promotion-codes/AutoAssignPromotionCodeModal.vue').default);

// Filters and pagination
app.component('filters-and-pagination', require('./components/app/filters-and-pagination/FiltersAndPagination.vue').default)

// Customers
app.component('create-partner-customer-in-program', require('./components/app/programs/customers/Create').default);
app.component('integration-shopify-re-sync-customers', require('./components/app/integrations/shopify/components/ReSyncCustomers.vue').default);
app.component('create-refer-a-friend-customer-transaction', require('./components/app/programs/customers/referAFriend/CreateTransactionModal.vue').default);
app.component('delete-customer-modal', require('./components/app/programs/customers/DeleteCustomerModal.vue').default)

// Index pages
app.component('referral-index', require('./components/app/programs/referrals/ReferralIndex.vue').default)
app.component('payouts-index', require('./components/app/programs/payouts/PayoutsIndex.vue').default);
app.component('partner-index', require('./components/app/programs/partners/PartnerIndex.vue').default);
app.component('logs-index', require('./components/app/programs/logs/LogsIndex.vue').default);
app.component('newsletter-subscribers-index', require('./components/app/programs/newsletter/subscribers/Index.vue').default);
app.component('newsletter-approve-subscriber', require('./components/app/programs/newsletter/subscribers/approveSubscriber.vue').default);
app.component('create-subscriber', require('./components/app/programs/newsletter/subscribers/CreateSubscriber.vue').default);
app.component('refer-a-friend-customers-index', require('./components/app/programs/customers/referAFriend/CustomersIndex/CustomersIndex.vue').default);
app.component('referral-activities-index', require('./components/app/programs/referral-activity/ReferralActivitiesIndex.vue').default);
app.component('customer-activities-index', require('./components/app/programs/customers/CustomerActivityIndex.vue').default);
app.component('refer-a-friend-activities-index', require('./components/app/refer-a-friend/activity/Index.vue').default);

/**
 * Leads
 */
app.component('program-leads-index', require('./components/app/programs/leads/LeadsIndex.vue').default);
app.component('program-leads-commission-settings', require('./components/app/programs/portal/leads/CommissionSettings.vue').default);

app.component('revolvapp-program-email-edit', require('./components/app/integrations/revolvapp/EmailEdit.vue').default);

// Billing
app.component('account-billing-information', require('./components/app/billing/AccountBillingInformation').default);
app.component('shopify-checkout', require('./components/app/billing/ShopifyCheckout.vue').default);
app.component('stripe-checkout', require('./components/app/billing/StripeCheckout').default);
app.component('stripe-payment-methods', require('./components/app/billing/StripePaymentMethods').default);
app.component('subscription-choose-plan', require('./components/app/subscription/ChoosePlan').default);
app.component('subscription-cancel-plan', require('./components/app/subscription/CancelPlan').default);
app.component('pay-past-due-invoice', require('./components/app/subscription/PayPastDueInvoice.vue').default);

// Payouts
app.component('payouts-analytics-top-partners', require('./components/app/programs/payouts/components/TopPartners.vue').default);
app.component('paypal-automation-settings', require('./components/app/programs/payouts/automation/paypal/AutomationSettings.vue').default);
app.component('bank-transfer-automation-settings', require('./components/app/programs/payouts/automation/bank-transfer/AutomationSettings.vue').default);
app.component('toggle-paypal-automation', require('./components/app/programs/payouts/automation/paypal/ToggleAutomation.vue').default);
app.component('toggle-bank-transfer-automation', require('./components/app/programs/payouts/automation/bank-transfer/ToggleAutomation.vue').default);
app.component('scheduled-payouts-history', require('./components/app/programs/payouts/automation/ScheduledPayoutsHistory.vue').default);
app.component('reject-payout-request-reward-modal', require('./components/app/programs/payouts/components/RejectRequestRewardModal').default);

// Analytics
app.component('analytics-head-bar', require('./components/app/programs/analytics/components/HeadBar').default);
app.component('real-time-analytics', require('./components/app/programs/analytics/real-time/RealTimeAnalytics.vue').default);
app.component('real-time-period-picker', require('./components/app/programs/analytics/real-time/components/AnalyticsRealTimePeriodPicker.vue').default);
app.component('real-time-analytics-toggle', require('./components/app/programs/analytics/real-time/components/AnalyticsRealTimeToggle.vue').default);
app.component('overview-analytics', require('./components/app/programs/analytics/overview/affiliate/OverviewAnalytics.vue').default);
app.component('affiliate-partners-analytics', require('./components/app/programs/analytics/partners/affiliate/PartnersAnalytics.vue').default);

// Include File manager component and load its store
app.component('filemanager', require('../../packages/filemanager/resources/js/Filemanager').default);
app.config.globalProperties.$store = store;

// Integrations
app.component('shopify-connect-program', require('./components/app/integrations/shopify/components/ConnectProgram').default);
app.component('shopify-after-install-check-list', require('./components/app/integrations/shopify/components/AfterInstallCheckList.vue').default);

app.component('affiliate-onboarding-integration-standard', require('./components/app/programs/onboarding/affiliate/StandardIntegration.vue').default);
app.component('affiliate-onboarding-integration-ecommerce', require('./components/app/programs/onboarding/affiliate/EcommerceIntegration.vue').default);
app.component('create-program-affiliate', require('./components/app/programs/onboarding/affiliate/CreateProgram.vue').default);
app.component('refer-a-friend-onboarding-integration-ecommerce', require('./components/app/programs/onboarding/refer-a-friend/EcommerceIntegration.vue').default);
app.component('create-program-refer-a-friend', require('./components/app/programs/onboarding/refer-a-friend/CreateProgram.vue').default);

app.component('avatar', require('./components/common/Avatar.vue').default);

// Affiliate Guides
app.component('guide-card', require('./components/app/integrations/integration-guides/GuideCard').default);
app.component('guide-stripe', require('./components/app/integrations/integration-guides/instructions/GuideStripe').default);
app.component('guide-stripe-links', require('./components/app/integrations/integration-guides/instructions/GuideStripeLinks').default);
app.component('guide-paddle', require('./components/app/integrations/integration-guides/instructions/GuidePaddle').default);
app.component('guide-paddle-checkout', require('./components/app/integrations/integration-guides/instructions/GuidePaddleCheckout.vue').default);
app.component('guide-paddle-overlay', require('./components/app/integrations/integration-guides/instructions/GuidePaddleOverlay.vue').default);
app.component('guide-paddle-classic-overlay', require('./components/app/integrations/integration-guides/instructions/GuidePaddleClassicOverlay.vue').default);
app.component('guide-shopify', require('./components/app/integrations/integration-guides/instructions/GuideShopify').default);
app.component('guide-woocommerce', require('./components/app/integrations/integration-guides/instructions/GuideWooCommerce').default);
app.component('guide-bigcommerce', require('./components/app/integrations/integration-guides/instructions/GuideBigCommerce').default);
app.component('guide-squarespace', require('./components/app/integrations/integration-guides/instructions/GuideSquarespace').default);
app.component('guide-thinkific', require('./components/app/integrations/integration-guides/instructions/GuideThinkific').default);
app.component('guide-highlevel', require('./components/app/integrations/integration-guides/instructions/GuideHighLevel').default);
app.component('guide-bd', require('./components/app/integrations/integration-guides/instructions/GuideBrilliantDirectories').default);
app.component('guide-simvoly', require('./components/app/integrations/integration-guides/instructions/GuideSimvoly').default);
app.component('guide-wix', require('./components/app/integrations/integration-guides/instructions/GuideWix').default);
app.component('guide-paypal-return', require('./components/app/integrations/integration-guides/instructions/GuidePaypalReturn').default);
app.component('guide-calendly', require('./components/app/integrations/integration-guides/instructions/GuideCalendly').default);
app.component('guide-oncehub', require('./components/app/integrations/integration-guides/instructions/GuideOncehub').default);
app.component('guide-wordpress', require('./components/app/integrations/integration-guides/instructions/GuideWordPress').default);
app.component('guide-typeform', require('./components/app/integrations/integration-guides/instructions/GuideTypeform').default);

app.component('guide-signup-tracking', require('./components/app/integrations/integration-guides/instructions/GuideSignupTracking').default);
app.component('guide-api', require('./components/app/integrations/integration-guides/instructions/GuideApi').default);
app.component('guide-javascript', require('./components/app/integrations/integration-guides/instructions/GuideJavascript').default);
app.component('guide-universal', require('./components/app/integrations/integration-guides/instructions/GuideUniversal').default);

// Referral Guides
app.component('guide-api-referral', require('./components/app/integrations/integration-guides/instructions/referral/GuideApiReferral').default);
app.component('guide-stripe-links-referral', require('./components/app/integrations/integration-guides/instructions/referral/GuideStripeLinksReferral').default);
app.component('guide-stripe-referral', require('./components/app/integrations/integration-guides/instructions/referral/GuideStripeReferral').default);
app.component('guide-signup-tracking-referral', require('./components/app/integrations/integration-guides/instructions/referral/GuideSignupTrackingReferral').default);

//Exports
app.component('standalone-export', require('./components/app/exports/StandaloneExport').default);

// Accounts package
app.component('invitation-role', require('./components/vendor/accounts/invites/Role').default);

// Program portal
app.component('portal-terms-of-services', require('./components/app/programs/portal/signup/TermsOfServices').default)

// Settings components
app.component('settings-accordion', require('./elements/accordions/SettingsAccordion').default);

// Partnero List/Marketplace
app.component('remove-partnero-list-submission', require('./components/app/programs/partnero-list/RemoveSubmission').default)
app.component('partnero-marketplace-form', require('./components/app/programs/partnero-list/MarketplaceForm.vue').default)
app.component('partnero-list', require('./components/app/programs/partnero-list/List.vue').default)

// Signup popup
app.component('program-signup-popup', require('./components/app/programs/portal/SignupPopup.vue').default)
app.component('program-embeddable-signup', require('./components/app/programs/portal/EmbeddableSignup.vue').default)
// /**
//  * Next, we will create a fresh Vue application instance and attach it to
//  * the page. Then, you may begin adding components to this application
//  * or customize the JavaScript scaffolding to fit your unique needs.
//*/

import VueMarkdownIt from 'vue3-markdown-it';

app.use(VueMarkdownIt);

import VueApexCharts from 'vue3-apexcharts';

app.use(VueApexCharts);

import { createPinia } from "pinia";

const pinia = createPinia()

function piniaEmitter() {
    return {emitter: app.config.globalProperties.emitter}
}

pinia.use(piniaEmitter)
app.use(pinia);

app.mount("#app");
