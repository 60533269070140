<template>
    <div
        class="flex items-center p-4 rounded-md text-sm font-medium"
        :class="{
            'bg-green-50 border border-green-100 text-green-800': type === 'success',
            'bg-red-50 border border-red-100 text-red-800': type === 'error',
            'bg-yellow-50 border border-yellow-400 text-yellow-800': type === 'warning',
            'bg-gray-50 border border-gray-200 text-gray-800': type === 'neutral',
        }"
    >
        <icon v-if="type === 'success'" name="check-circle" class="mr-4 flex-shrink-0 mt-0.5"/>
        <icon v-if="type === 'error'" name="close-circle" class="mr-4 flex-shrink-0 mt-0.5"/>
        <icon v-if="type === 'warning'" name="alert-triangle" class="mr-4 flex-shrink-0 mt-0.5"/>
        <icon v-if="type === 'neutral'" name="info" class="mr-4 flex-shrink-0 mt-0.5"/>
        <span><slot/></span>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
            },
        }
    }
</script>

<style scoped>

</style>
