<template>
    <file-upload
        :src="data?.url ?? null"
        :path="data?.path ?? null"
        :no_src="no_src"
        :accept="accept"
        :title="title"
        :name="name"
        :upload_url="options.file_upload_url"
        :delete_url="options.file_delete_url"
        :additional_data="{resource_id: unique_id}"
        @upload:done="uploadDone"
        @delete:done="deleteDone"
        show_validation_errors_within_uploader
    >
        <template v-if="$slots.empty_placeholder" v-slot:empty_placeholder>
            <slot name="empty_placeholder"></slot>
        </template>
        <template v-if="$slots.uploaded_placeholder" v-slot:uploaded_placeholder>
            <slot name="uploaded_placeholder"></slot>
        </template>
    </file-upload>
</template>

<script>
export default {
    props: ['unique_id', 'data', 'options', 'name', 'title', 'no_src', 'accept'],
    methods: {
        uploadDone(data) {
            this.data.url = data[this.name].url;
            this.data.path = data[this.name].path;
        },
        deleteDone(done) {
            if (done) {
                this.data.url = null;
                this.data.path = null;
            }
        }
    }
}
</script>
