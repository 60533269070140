<template>

    <div class="space-y-6">

<!--        <alert type="neutral">-->
<!--            This guide explains how to create a referred customer on Partnero once they sign up on your platform. Once the customer is created, all purchases done via Paddle will be associated to the created customer.-->
<!--        </alert>-->

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>
            <slot name="universal_script"></slot>
        </div>


        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Set up tracking of Paddle Billing Overlay checkout</h3>
                <div class="text-sm space-y-2">
                    <p>Identify purchases made by referred buyers by accurately identifying and linking them via Paddle Billing Overlay checkout. Select an example for the available checkout methods. </p>
                </div>
            </div>

            <tabs>
                <tab @click="setActiveTab(TAB_1)" :class='{"border-b-4": activeTab === TAB_1}'>
                    Paddle.Checkout.open() method
                </tab>
                <tab @click="setActiveTab(TAB_2)" :class='{"border-b-4": activeTab === TAB_2}'>
                    HTML data attributes
                </tab>
            </tabs>

            <div class="space-y-4" v-show="activeTab === TAB_1">
                <div class="text-sm space-y-2">
                    <p>Choose this method if you added Paddle Billing Overlay checkout directly to your website. This method works when checkout is triggered using Paddle's <a class="underline hover:no-underline" href="https://developer.paddle.com/build/checkout/build-overlay-checkout#paddle.checkout.open()-method" target="_blank">Paddle.Checkout.open() method</a> example. </p>
                </div>
                <div class="relative">
                    <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-1').value)">
                        <tooltip content="Copied" method="click">
                            <btn type="secondary" size="pill">Copy</btn>
                        </tooltip>
                    </div>
                    <textarea id="snippet-1" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script type="text/javascript">
    const partneroQueryParam = 'aff';
    const partneroCookieName = 'partnero_partner';

    function getCookie(name) {
        const cookieArr = document.cookie.split(";").map(cookie => cookie.trim());
        for (const cookie of cookieArr) {
            const [cookieName, cookieValue] = cookie.split("=");
            if (name === cookieName) {
                return decodeURIComponent(cookieValue);
            }
        }
        return null;
    }

    function injectCustomData() {
        const customerKey = new URLSearchParams(window.location.search).get(partneroQueryParam) || getCookie(partneroCookieName);

        if (customerKey) {
            const originalOpen = Paddle.Checkout.open;

            Paddle.Checkout.open = function(options) {
                options.customData = options.customData || {};
                options.customData.customer_key = customerKey;
                originalOpen.call(Paddle.Checkout, options);
            };
        }
    }

    document.addEventListener("DOMContentLoaded", injectCustomData);
</script></textarea>
                    <small class="text-gray-500 mt-1 block">
                        Include the following script on every page containing payment links. It's important to place
                        this script at the bottom of the page, directly above the closing <badge type="neutral" title="</body"></badge>
                        tag for optimal functionality.
                    </small>
                </div>
            </div>

            <div class="space-y-4" v-show="activeTab === TAB_2">
                <div class="text-sm space-y-2">
                    <p>Choose this method if you added Paddle Billing Overlay checkout directly to your website. This method works when checkout is triggered using Paddle's <a class="underline hover:no-underline" href="https://developer.paddle.com/build/checkout/build-overlay-checkout#html-data-attributes" target="_blank">HTML data attributes</a> example. </p>
                </div>
                <div class="relative">
                    <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-2').value)">
                        <tooltip content="Copied" method="click">
                            <btn type="secondary" size="pill">Copy</btn>
                        </tooltip>
                    </div>
                    <textarea id="snippet-2" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script type="text/javascript">
    const partneroQueryParam = 'aff';
    const partneroCookieName = 'partnero_partner';

    function getCookie(name) {
        const cookieArr = document.cookie.split(";").map(cookie => cookie.trim());
        for (const cookie of cookieArr) {
            const [cookieName, cookieValue] = cookie.split("=");
            if (name === cookieName) {
                return decodeURIComponent(cookieValue);
            }
        }
        return null;
    }

    function updateCustomData() {
        const partnerCookieValue = getCookie(partneroCookieName);
        const referralKey = new URLSearchParams(window.location.search).get(partneroQueryParam);
        const customerKey = referralKey || partnerCookieValue;

        const signupButtons = document.querySelectorAll(".paddle_button");

        signupButtons.forEach(signupButton => {
            if (customerKey) {
                const customData = JSON.stringify({ customer_key: customerKey });
                signupButton.setAttribute("data-custom-data", customData);
            } else {
                signupButton.removeAttribute("data-custom-data");
            }
        });
    }

    document.addEventListener("DOMContentLoaded", updateCustomData);
</script></textarea>
                    <small class="text-gray-500 mt-1 block">
                        Include the following script on every page containing payment links. It's important to place
                        this script at the bottom of the page, directly above the closing <badge type="neutral" title="</body"></badge>
                        tag for optimal functionality.
                    </small>
                </div>
            </div>
        </div>

    </div>

</template>
<script setup>

import {ref} from 'vue';

const TAB_1 = 'tab_1';
const TAB_2 = 'tab_2';
const TAB_3 = 'tab_3';

let activeTab = ref(TAB_1);

const setActiveTab = (tab) => {
    activeTab.value = tab;
};

</script>

