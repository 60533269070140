<template>

    <filters-and-pagination
        :pagination-data="filtersAndPaginationData"
        :data-exists="dataExists"
        :loading-data="loadingFiltersAndPaginationData"
        :showTableFilter="false"
        model-name="intermediary-payouts"
    >

        <table class="min-w-full divide-y divide-gray-200">
            <thead>
            <tr class="h-1">
                <th :colspan="8">
                    <progress-bar v-if="loadingFiltersAndPaginationData"/>
                </th>
            </tr>
            <tr>
                <th scope="col"
                    class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                    Charge Status
                </th>
                <th scope="col"
                    class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                    Payout Status
                </th>
                <th scope="col"
                    class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                    Payouts total Amount
                </th>
                <th scope="col"
                    class="pl-8 pr-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                    Charged Amount
                </th>
                <th scope="col"
                    class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                    Paid out Amount
                </th>
                <th scope="col"
                    class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                    Payout planned at
                </th>
                <th scope="col"
                    class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                    Charged at
                </th>
                <th scope="col"
                    class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                    Paid out at
                </th>
            </tr>
            <tr class="h-1"/>
            </thead>
            <tbody class="divide-y divide-gray-200">
            <initial-loading-skeleton-rows
                v-if="loadingFiltersAndPaginationData && (!filtersAndPaginationData.data || filtersAndPaginationData.data?.length === 0)"
                :number-of-table-columns="8"
            />
            <no-search-data-row
                v-else-if="!loadingFiltersAndPaginationData && filtersAndPaginationData.data?.length === 0"
                :number-of-table-columns="8"
            />
            <template v-else>
                <tr v-for="(payout, index) in filtersAndPaginationData.data" class="hover:bg-gray-50 group">
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                        {{ payout.charged_status || '---' }}
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                        {{ payout.payout_status || '---' }}
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                        {{ payout.payouts_total_amount }}
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                        {{ payout.charged_amount }}
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                        {{ payout.paid_out_amount }}
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ payout.payout_at }}
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ payout.charged_at }}
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ payout.paid_out_at }}
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
    </filters-and-pagination>

</template>
<script setup>

import {ref, onMounted} from "vue";
import ProgressBar from "../../../../elements/ProgressBar.vue";
import NoSearchDataRow from "../../../common/NoSearchDataRow";
import useFiltersAndPagination from "../../../../state/common/filters-and-pagination";
import FiltersAndPagination from "../../filters-and-pagination/FiltersAndPagination.vue";
import useRecentActivities from "../../../../composables/common/filter-and-pagination-data";
import InitialLoadingSkeletonRows from "../../../common/InitialLoadingSkeletonRows.vue";

const props = defineProps({
    program: {
        type: [String, Number],
        required: true
    }
});

const dataExists = ref(false);

const {loadingFiltersAndPaginationData, filtersAndPaginationData, getFiltersAndPaginationData} = useRecentActivities();
const {setDataBaseUrl, setFetchDataFunction} = useFiltersAndPagination();

onMounted(() => {
    setDataBaseUrl(route('app_int.intermediary_payouts.list', {program: props.program}));
    setFetchDataFunction(getFiltersAndPaginationData);
});


</script>

<style scoped></style>
