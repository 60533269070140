<template>
    <div class="bg-gray-100 rounded py-3 px-4 mt-6">
        <div class="flex justify-between items-center">
            <div class="text-sm">
                <span v-if="exportOptions.selectType === 'selectAll' || exportOptions.selectedIds.length > 0" class="font-semibold">
                    {{
                        exportOptions.selectType === 'selectAll'
                            ?  formatNumber(totalDataCount)
                            : formatNumber(exportOptions.selectedIds.length)
                    }}
                    {{
                        exportOptions.selectedIds.length === 1
                            ? exportSetup.exportModelNameSingular
                            : exportSetup.exportModelNamePlural
                    }} selected.
                </span>
                <span v-if="exportData.status === 'starting'">
                    File export is about to start.
                </span>
                <span class="italic" v-if="exportData.status === 'exporting' || exportData.status === 'started'">
                    Exporting {{ formatNumber(exportData.lines) }} / {{ formatNumber(exportData.total) }}
                </span>
                <span class="font-semibold" v-if="exportData.status === 'done'">
                    The file is ready to download.
                </span>
                <btn
                    v-if="!exportingInProgress && exportData.status !== 'done' && (exportOptions.selectType === 'selectAll' || exportOptions.selectedIds.length > 0)"
                    type="light"
                    iconName="close"
                    size="pill"
                    @click="clearSelectedIds"
                >
                    Clear selection
                </btn>
            </div>
            <div class="flex gap-3">
                <div
                    v-if="exportingInProgress"
                    class="flex gap-3"
                >
                    <btn
                        size="pill"
                        icon-name="spinner"
                        :disabled="true"
                        type="secondary"
                    >
                        Download
                    </btn>
                    <btn
                        type="light"
                        iconName="close"
                        size="pill"
                        @click="cancelExport"
                    >
                        Cancel
                    </btn>
                </div>
                <btn
                    v-else-if="exportSetup.showExportButton && !exportingInProgress && exportData.status !== 'done'"
                    type="light"
                    iconName="download"
                    size="pill"
                    @click="exportCSV"
                >
                    Export
                </btn>
                <div
                    v-else-if="!exportingInProgress && exportData.status === 'done'"
                    class="flex gap-3"
                >
                    <btn
                        type="dark-green"
                        icon-name="download"
                        size="pill"
                        :href="exportData.public_url"
                    >
                        Download
                    </btn>
                    <btn
                        type="light"
                        iconName="close"
                        size="pill"
                        @click="resetExport"
                    >
                        Clear export
                    </btn>
                </div>
                <div>
                    <modal v-if="!exportingInProgress && deleteSetup.deleteUrl && (exportOptions.selectType === 'selectAll' || exportOptions.selectedIds.length > 0)" title="Are you sure?" size="small" ref="modal">
                        <template #toggle>
                            <btn
                                type="light"
                                iconName="trash"
                                size="pill"
                            >
                                Delete
                            </btn>
                        </template>
                        <template #body>
                            <div class="space-y-6 text-sm text-gray-500">
                                <div>
                                    Do you really want to delete <strong>{{
                                        exportOptions.selectType === 'selectAll'
                                            ? formatNumber(totalDataCount)
                                            : formatNumber(exportOptions.selectedIds.length)
                                    }}</strong>
                                    {{ exportOptions.selectedIds.length === 1
                                    ? exportSetup.exportModelNameSingular
                                    : exportSetup.exportModelNamePlural }} ?
                                </div>
                                <toggle v-if="(exportOptions.selectType === 'selectAll' && deleteSetup.additionalData.rewardCouponsExistWithinProgram)
                                    || (exportOptions.selectType !== 'selectAll' && deleteSetup.additionalData.rewardCouponsExistWithinCustomSelect)"
                                        title="Delete rewards linked to a coupon"
                                        v-model="deleteSetup.additionalData.deleteRewardAssociatedWithCoupon"
                                />
                                <toggle v-if="(exportOptions.selectType === 'selectAll' && deleteSetup.additionalData.thirdPartyCouponsExistWithinProgram)
                                    || (exportOptions.selectType !== 'selectAll' && deleteSetup.additionalData.thirdPartyCouponsExistWithinCustomSelect)"
                                        title="Delete coupons on third-party platforms"
                                        v-model="deleteSetup.additionalData.deleteCouponOnSyncProviderSide"
                                />
                                <div>
                                    This process cannot be undone.
                                </div>
                            </div>
                        </template>
                        <template #footer>
                            <btn type="primary"
                                 @click="deleteData"
                                 :loading="deletingInProgress"
                            >
                                {{ deletingInProgress ? 'Deleting...' : 'Yes, delete' }}
                            </btn>
                        </template>
                    </modal>
                </div>
                    <modal v-if="!exportingInProgress
                                 && approvalSetup.approvalUrl
                                 && (approvalSetup.selectedPartnersIdsToApprove.length > 0 || (exportOptions.selectType === 'selectAll' && approvalSetup.unapprovedPartnersExistWithinProgram))"
                           title="Are you sure?"
                           size="small"
                           ref="modal"
                    >
                        <template #toggle>
                            <btn
                                type="light"
                                iconName="check"
                                size="pill"
                            >
                                Approve
                            </btn>
                        </template>
                        <template #body>
                            <div class="space-y-4 text-sm text-gray-500">
                                <div>
                                    Do you really want to approve <strong>{{
                                        exportOptions.selectType === 'selectAll'
                                            ? 'all unapproved '
                                            : formatNumber(approvalSetup.selectedPartnersIdsToApprove.length)
                                    }}</strong>
                                    {{ approvalSetup.selectedPartnersIdsToApprove.length === 1
                                    ? exportSetup.exportModelNameSingular
                                    : exportSetup.exportModelNamePlural }} ?
                                </div>
                            </div>
                        </template>
                        <template #footer>
                            <btn type="primary"
                                 @click="approve"
                                 :loading="approvingInProgress"
                            >
                                {{ approvingInProgress ? 'Approving...' : 'Yes, approve' }}
                            </btn>
                        </template>
                    </modal>
            </div>
        </div>
        <notification
            :show="notification.showNotification"
            :title="notification.notificationTitle"
            :type="notification.notificationType"
            class="mb-20"
            @notificationClosed="notification.showNotification = false"
        >
            <template #message>
                {{ notification.notificationMessage }}
            </template>
        </notification>
    </div>
</template>

<script>
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";
import useRecentActivities from "../../../../composables/common/filter-and-pagination-data";
import Notification from "../../../../elements/Notification";
import {brandNamesMapper, formatNumber} from "../../../../helpers/common";
import Spinner from "../../../../elements/Spinner.vue";
import {ref} from "vue";

export default {
    name: "ExportDeleteAndApproveDetails",
    computed: {
        brandNamesMapper() {
            return brandNamesMapper
        }
    },
    components: {Spinner, Notification},
    setup() {
        const {
            exportOptions,
            exportSetup,
            exportData,
            exportingInProgress,
            cancelExport,
            notification,
            exportCSV,
            resetExport,
            clearSelectedIds,
            deleteSetup,
            deleteData,
            deletingInProgress,
            approve,
            approvalSetup,
            approvingInProgress
        } = useTableDataExporter()

        const { filtersAndPaginationData } = useRecentActivities()

        const  totalDataCount = filtersAndPaginationData.value.total

        return {
            exportOptions,
            exportingInProgress,
            exportSetup,
            exportData,
            cancelExport,
            totalDataCount,
            notification,
            exportCSV,
            resetExport,
            clearSelectedIds,
            deleteData,
            deleteSetup,
            deletingInProgress,
            approve,
            approvalSetup,
            approvingInProgress
        }
    },
    methods: {
        ref,
        formatNumber
    }
}
</script>

