<template>
  <div v-if="!subscriberApproved">
    <modal title="Are you sure?" size="small" name="subscriber_approve_modal">
      <template #toggle>
        <btn type="primary" class="mt-2">Approve</btn>
      </template>
      <template #body>
        <div class="space-y-6">
          <div class="text-sm text-gray-500">Do you really want to approve this subscriber?</div>
        </div>
      </template>
      <template #footer>
        <btn type="primary" :loading="loading" @click="approveSubscriber">Yes, approve</btn>
      </template>
    </modal>
  </div>
</template>
<script setup>
import {ref, inject} from "vue";

const props = defineProps({
  programId: {
    type: [String, Number]
  },
  subscriberId: {
    type: [String, Number]
  },
  subscriberApproved: {
    type: Boolean
  }
});

const emitter = inject('emitter');
const programId = ref(props.programId);
const subscriberId = ref(props.subscriberId);

let loading = ref(false);
let subscriberApproved = ref(props.subscriberApproved);

const approveSubscriber = () => {

  if (loading.value) {
    return;
  }
  loading.value = true;

  axios.post(route('app_int.subscribers.approve_subscriber', {program: programId.value}),
      {subscriber: subscriberId.value}
  )
      .then(response => {
        const status = parseInt(response.data.status || -1);
        if (status === 1) {
          subscriberApproved.value = true;
          emitter.emit('modal-off', 'subscriber_approve_modal');
        }
      })
      .catch(error => {
      })
      .finally(() => {
        loading.value = false;
      });
};

</script>

