<template>
    <div class="flex items-start">
        <div class="flex-shrink-0 w-10 h-10">
            <img class="w-10 h-10 rounded"
                 src="/images/integrations/paypal.png"/>
        </div>
        <div class="ml-5 w-full">
            <div class="flex justify-between">
                <span class="text-sm font-semibold">
                    PayPal
                </span>
                <badge title="Connected" v-if="connected"></badge>
            </div>
            <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                Automate multiple payouts through your PayPal account with ease.
            </div>

            <div>
                <modal :title="'PayPal Mass Pay integration'" size="small" name="paypal_integration" v-if="!connected">
                    <template #toggle>
                        <btn type="secondary" size="small" class="-mr-px focus:z-10">Authorize</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <alert type="error" v-if="connect_error">{{ connect_error }}</alert>
                            <div class="grid gap-y-6 gap-x-8">
                                <text-field
                                    label="Client ID"
                                    field-id="client_id"
                                    name="client_id"
                                    v-model="client_id"
                                    type="text"
                                    autofocus="true"
                                    required="true">
                                </text-field>
                                <text-field
                                    label="Secret"
                                    field-id="client_secret"
                                    name="client_secret"
                                    v-model="client_secret"
                                    type="password"
                                    :show_reveal_password_icon="true"
                                    placeholder="e.g. ENv_iRgBP2rBw...9Mk"
                                    required="true">
                                    <template #note>
                                        To obtain your PayPal Client ID and Secret, please
                                        <a href="#" data-beacon-article="43" class="underline">click
                                            here</a> for instructions on how to retrieve them from your account.
                                    </template>
                                </text-field>
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading.connect" @click="connect">Connect</btn>
                    </template>
                </modal>

                <modal :title="'PayPal Mass Pay integration'" size="small" name="paypal_integration" v-if="connected">
                    <template #toggle>
                        <btn size="small" class="-mr-px focus:z-10">Disconnect</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <alert type="error" v-if="disconnect_error">{{ disconnect_error }}</alert>
                            <div class="text-sm text-gray-500">
                                Are you sure you wish to disconnect the PayPal Mass Pay integration?
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading.disconnect" @click="disconnect">Disconnect</btn>
                    </template>
                </modal>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: 'IntegrationPayPalMassPayout',
    props: ['integrations_config', 'program_id'],
    data() {
        return {

            config: this.integrations_config,

            client_id: '',
            client_secret: '',
            loading: {
                connect: false,
                disconnect: false,
            },
            connect_error: '',
            disconnect_error: '',
            connected: this.integrations_config.connected ?? false,
        }
    },
    methods: {
        connect() {
            if (this.loading.connect) {
                return;
            }

            this.loading.connect = true;

            axios
                .post(route('app_int.integrations.paypal.mass-payout.connect', this.program_id), {
                    client_id: this.client_id,
                    client_secret: this.client_secret,
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {

                        this.connected = true;
                        this.connect_error = false;

                        this.emitter.emit('modal-off', 'paypal_integration');

                        this.config.groups = response.data.groups ?? [];
                    } else {
                        this.connect_error = 'Failed to connect';
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading.connect = false);
        },
        disconnect() {
            if (this.loading.disconnect) {
                return;
            }

            this.loading.disconnect = true;

            axios
                .post(route('app_int.integrations.paypal.mass-payout.disconnect', this.program_id), {
                    api_key: this.api_key
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {

                        this.connected = false;
                        this.disconnect_error = false;

                        this.emitter.emit('modal-off', 'paypal_integration');
                    } else {
                        this.disconnect_error = 'Failed to disconnect';
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading.disconnect = false);
        },
    }
}
</script>
<style scoped>
</style>
