<template>
    <div class="flex items-start">
        <div class="flex-shrink-0 w-10 h-10">
            <img class="w-10 h-10 rounded"
                 src="/images/integrations/wise-256x256.png"/>
        </div>
        <div class="ml-5 w-full">
            <div class="flex justify-between">
                <span class="text-sm font-semibold">
                    Wise
                </span>
                <badge title="Connected" v-if="connected"></badge>
            </div>
            <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                Automate multiple payouts through your Wise account with ease.
            </div>
            <div class="flex" v-if="!connected">
                <modal :title="'Wise Mass Pay integration'" size="small" name="wise_integration">
                    <template #toggle>
                        <btn type="secondary" size="small" class="-mr-px focus:z-10">Authorize</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <alert type="error" v-if="connect_error">{{ connect_error }}</alert>
                            <div class="grid gap-y-6 gap-x-8">
                                <text-field
                                    label="API token"
                                    field-id="api_token"
                                    name="api_token"
                                    v-model="api_token"
                                    type="password"
                                    :show_reveal_password_icon="true"
                                    placeholder="e.g. a1cd3c6b-38...4f0f"
                                    autofocus="true"
                                    required="true">
                                    <template #note>
                                        To obtain your API token, log in to Wise account and navigate to your Settings page and click API tokens. On this page, users can generate and access their personal token.
                                    </template>
                                </text-field>
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading.connect" @click="connect">Connect</btn>
                    </template>
                </modal>
            </div>
            <div class="flex gap-2" v-if="connected">
                <modal :title="'Wise Mass Pay integration'" size="small" name="wise_integration">
                    <template #toggle>
                        <btn size="small" class="-mr-px focus:z-10">Disconnect</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <alert type="error" v-if="disconnect_error">{{ disconnect_error }}</alert>
                            <div class="text-sm text-gray-500">
                                Are you sure you wish to disconnect the Wise Mass Pay integration?
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading.disconnect" @click="disconnect">Disconnect</btn>
                    </template>
                </modal>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: 'IntegrationWiseMassPayout',
    props: ['integrations_config'],
    data() {
        return {
            config: this.integrations_config,
            api_token: '',
            loading: {
                connect: false,
                disconnect: false,
                update: false
            },
            connect_error: '',
            disconnect_error: '',
            connected: this.integrations_config.connected ?? false,
        }
    },
    methods: {
        connect() {
            if (this.loading.connect) {
                return;
            }

            this.loading.connect = true;
            this.connect_error = false;

            axios
                .post(this.config.urls.connect, {
                    api_token: this.api_token,
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        this.api_token = '';
                        this.connected = true;
                        this.connect_error = false;

                        this.emitter.emit('modal-off', 'wise_integration');

                        this.config.groups = response.data.groups ?? [];
                    } else {
                        this.connect_error = 'Failed to connect';
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading.connect = false);
        },
        disconnect() {
            if (this.loading.disconnect) {
                return;
            }

            this.loading.disconnect = true;

            axios
                .post(this.config.urls.disconnect, {
                    api_key: this.api_key
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {

                        this.connected = false;
                        this.disconnect_error = false;

                        this.emitter.emit('modal-off', 'wise_integration');
                    } else {
                        this.disconnect_error = 'Failed to disconnect';
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading.disconnect = false);
        },
    }
}
</script>
<style scoped>
</style>
