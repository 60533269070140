<template>
    <div>
        <div @click="showModal = true">
            <slot name="toggle"></slot>
        </div>

        <transition name="fade">
            <div class="bottom-0 left-0 sm:left-auto fixed flex max-w-sm w-full right-0 sm:right-20 z-50 shadow-lg" v-if="showModal">

                <div class="flex flex-col relative z-50 w-full max-h-screen bg-white lg:rounded-t border shadow-lg">

                    <div class="flex flex-col overflow-y-auto p-8 space-y-8 max-h-screen">

                        <div class="flex justify-between gap-2 w-full overflow-hidden">

                            <div class="flex justify-between overflow-hidden">
                                <div class="overflow-hidden">
                                    <h2 class="text-xl font-medium truncate">{{ title }}</h2>
                                    <span v-if="details" class="text-sm text-opacity-50">{{ details }}</span>
                                </div>
                            </div>

                            <btn @click.native="showModal = false" icon-name="close" type="light" size="small"></btn>

                        </div>

                        <div v-if="$slots.body" class="flex-1 h-0 text-opacity-50 whitespace-normal">
                            <slot name="body"></slot>
                        </div>

                    </div>

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        title: '',
        details: '',
        show: {
            default: false
        },
    },
    data: function () {
        return {
            showModal: this.show
        }
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
