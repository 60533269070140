<template>
    <div>
        <label :for="input_names.select" class="block mb-1.5 text-sm">{{ text.name }}</label>
        <div class="grid gap-1" :class="types_value_disabled ? 'grid-cols-1' : 'grid-cols-2'">
            <select :id="input_names.select" :name="input_names.select" v-model="type" @change="typeChange($event)"
                    class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
                <option value="lifetime">Lifetime</option>
                <option value="times">Times</option>
                <option value="days">Days</option>
                <option value="months">Months</option>
                <option value="years">Years</option>
            </select>
            <text-field :model-value="times_value" :name="input_names.input" v-if="!types_value_disabled"
                        :disabled="types_value_disabled" :required="!types_value_disabled"
                        @update:modelValue="valueChange"></text-field>
        </div>
        <small class="text-gray-500 mt-1 block">{{ text.description }}</small>
    </div>
</template>
<script>
export default {
    emits: ['update:type', 'update:value'],
    name: 'CommissionPeriod',
    props: ['commission_type', 'commission_times_value', 'name', 'description', 'select_name', 'input_name'],
    data() {
        return {
            text: {
                name: this.name || 'Commission period',
                description: this.description || 'Choose how long the rewards will be active for.',
            },
            input_names: {
                select: this.select_name || 'commission_period_type',
                input: this.input_name || 'commission_period_times_value',
            },
            type: this.commission_type,
            times_value: this.commission_times_value,
        }
    },
    computed: {
        types_value_disabled() {
            return this.type === 'lifetime';
        }
    },
    methods: {
        changeType(type) {
            this.type = type;
            this.typeChange(type);
        },
        changeValue(value) {
            this.times_value = value;
            this.valueChange(value);
        },
        typeChange(event) {
            this.$emit('update:type', event);
        },
        valueChange(value) {
            this.$emit('update:value', value);
        },
    }
}
</script>
<style scoped>
</style>
