<template>
    <div>
        <modal title="Appearance of Partner dashboard" size="huge" ref="partner_dashboard_appearance">
            <template #toggle>
                <btn type="secondary" icon-name="settings" size="small">Customize</btn>
            </template>
            <template #body>
                <div class="divide-y divide-gray-200">
                    <form-section title="Default" description="The default styling elements of your Partner dashboard.">
                        <div class="grid lg:grid-cols-2 gap-8">
                            <radio-group-inline-color
                                label="Background color"
                                name="portal_layout_background_color"
                                @picked="settings.portal_layout_background_color = $event.finalValue"
                                :model-value="settings.portal_layout_background_color"
                                :options="[
                                    { value: 'white', label: 'bg-white', label_is_class: true},
                                    { value: 'gray-50', label: 'bg-gray-50', label_is_class: true},
                                    { value: 'gray-100', label: 'bg-gray-100', label_is_class: true},
                                    { value: 'gray-200', label: 'bg-gray-200', label_is_class: true},
                                    { value: 'gray-300', label: 'bg-gray-300', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'portal_layout_background_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>

<!--                            <radio-group-inline-color-->
<!--                                label="Background gradient color"-->
<!--                                name="portal_layout_background_gradient_color"-->
<!--                                @picked="settings.portal_layout_background_gradient_color = $event.finalValue"-->
<!--                                :model-value="settings.portal_layout_background_gradient_color"-->
<!--                                :options="[-->
<!--                                    { value: 'black', label: 'bg-black', label_is_class: true },-->
<!--                                    { value: 'gray-300', label: 'bg-gray-300', label_is_class: true },-->
<!--                                    { value: 'blue-700', label: 'bg-blue-700', label_is_class: true },-->
<!--                                    { value: 'teal-600', label: 'bg-teal-600', label_is_class: true },-->
<!--                                    { value: 'none', label: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEwIi8+PGxpbmUgeDE9IjQuOTMiIHkxPSI0LjkzIiB4Mj0iMTkuMDciIHkyPSIxOS4wNyIvPjwvc3ZnPg==', label_is_img: true},-->
<!--                                    { value: 'custom', label: 'Custom', custom: { name: 'portal_layout_background_gradient_color', placeholder: '', type: 'input' }},-->
<!--                                ]"-->
<!--                            ></radio-group-inline-color>-->

                            <radio-group-inline-color
                                label="Text color"
                                name="portal_layout_text_color"
                                @picked="settings.portal_layout_text_color = $event.finalValue"
                                :model-value="settings.portal_layout_text_color"
                                :options="[
                                    { value: 'gray-900', label: 'bg-gray-900', label_is_class: true},
                                    { value: 'gray-800', label: 'bg-gray-800', label_is_class: true},
                                    { value: 'gray-700', label: 'bg-gray-700', label_is_class: true},
                                    { value: 'gray-600', label: 'bg-gray-600', label_is_class: true},
                                    { value: 'gray-500', label: 'bg-gray-500', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'portal_layout_text_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>

                            <radio-group-inline-color
                                v-if="!appearance.isTrue('portal_layout_brand_primary_color_as_partner_and_pages_primary')"
                                label="Link color"
                                name="portal_layout_link_color"
                                @picked="settings.portal_layout_link_color = $event.finalValue"
                                :model-value="settings.portal_layout_link_color"
                                :options="[
                                    { value: 'indigo-700', label: 'bg-indigo-700', label_is_class: true},
                                    { value: 'blue-700', label: 'bg-blue-700', label_is_class: true},
                                    { value: 'cyan-700', label: 'bg-cyan-700', label_is_class: true},
                                    { value: 'teal-600', label: 'bg-teal-600', label_is_class: true},
                                    { value: 'gray-900', label: 'bg-gray-900', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'portal_layout_link_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>

                            <radio-group-inline-color
                                v-if="!appearance.isTrue('portal_layout_brand_primary_color_as_partner_and_pages_primary')"
                                label="Button color"
                                name="portal_layout_button_color"
                                @picked="settings.portal_layout_button_color = $event.finalValue"
                                :model-value="settings.portal_layout_button_color"
                                :options="[
                                    { value: 'indigo-700', label: 'bg-indigo-700', label_is_class: true},
                                    { value: 'blue-700', label: 'bg-blue-700', label_is_class: true},
                                    { value: 'cyan-700', label: 'bg-cyan-700', label_is_class: true},
                                    { value: 'teal-600', label: 'bg-teal-600', label_is_class: true},
                                    { value: 'black', label: 'bg-black', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'portal_layout_button_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>

                        </div>
                    </form-section>

                    <form-section title="Layout" description="The layout of your Partner dashboard.">
                        <div class="grid lg:grid-cols-2 gap-8">
                            <radio-group-inline
                                label="Layout"
                                name="portal_layout"
                                v-model="settings.portal_layout"
                                :options="[
                                    { value: 'one', label: 'Modern'},
                                    { value: 'two', label: 'Casual'},
                                    { value: 'three', label: 'Sidebar'},
                                ]">
                            </radio-group-inline>

                            <radio-group-inline
                                label="Layout style"
                                name="portal_layout_layout_style"
                                v-model="settings.portal_layout_layout_style"
                                :options="[
                                    { value: 'border', label: 'Border'},
                                    { value: 'shadow', label: 'Shadow'},
                                    { value: 'bald', label: 'Bald'},
                                    { value: 'none', label: 'None'}
                                ]">
                            </radio-group-inline>

                            <radio-group-inline-color
                                label="Layout corners"
                                name="portal_layout_layout_corners"
                                @picked="settings.portal_layout_layout_corners = $event.finalValue"
                                :model-value="settings.portal_layout_layout_corners"
                                :options="[
                                    { value: 'none', label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gPGcgaWQ9Ik1vYmlsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyLjAwMDAwMCwgLTQ0LjAwMDAwMCkiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjQiPiA8cmVjdCBpZD0iUmVjdGFuZ2xlLUNvcHkiIHg9IjE0IiB5PSI0NiIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48L3JlY3Q+IDwvZz4gPC9nPiA8L3N2Zz4=', label_is_img: true},
                                    { value: 'rounded', label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gPGcgaWQ9Ik1vYmlsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU1LjAwMDAwMCwgLTQ0LjAwMDAwMCkiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjQiPiA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSI1NyIgeT0iNDYiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgcng9IjQiPjwvcmVjdD4gPC9nPiA8L2c+IDwvc3ZnPg==', label_is_img: true},
                                    { value: 'rounded-lg', label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gPGcgaWQ9Ik1vYmlsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk4LjAwMDAwMCwgLTQ0LjAwMDAwMCkiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjQiPiA8cmVjdCBpZD0iUmVjdGFuZ2xlLUNvcHktMiIgeD0iMTAwIiB5PSI0NiIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiByeD0iOCI+PC9yZWN0PiA8L2c+IDwvZz4gPC9zdmc+', label_is_img: true},
                                ]">
                            </radio-group-inline-color>

                            <radio-group-inline
                                label="Icons"
                                name="portal_layout_icons"
                                v-model="settings.portal_layout_icons"
                                :options="[
                                    { value: 'outline', label: 'Outline'},
                                    { value: 'bold', label: 'Bold'},
                                    { value: 'off', label: 'None'},
                                ]">
                            </radio-group-inline>

                            <radio-group-inline
                                label="Logo size"
                                name="portal_layout_logo_size"
                                v-model="settings.portal_layout_logo_size"
                                :options="[
                                    { value: 'max-h-4', label: 'Small'},
                                    { value: 'max-h-6', label: 'Default'},
                                    { value: 'max-h-8', label: 'Medium'},
                                    { value: 'max-h-10', label: 'Large'},
                                ]">
                            </radio-group-inline>
                        </div>
                    </form-section>

                    <form-section title="Navigation" description="The style of your main navigation.">
                        <div class="grid lg:grid-cols-2 gap-8">
                            <radio-group-inline
                                label="Navigation background"
                                name="portal_layout_navigation_color"
                                v-model="settings.portal_layout_navigation_color"
                                :options="[
                                    { value: 'brand', label: 'Brand'},
                                    { value: 'white', label: 'White'},
                                    { value: 'gray', label: 'Gray'},
                                    { value: 'black', label: 'Black'},
                                ]">
                            </radio-group-inline>

                            <radio-group-inline
                                label="Navigation style"
                                name="portal_layout_navigation_links"
                                v-model="settings.portal_layout_navigation_links"
                                :options="[
                                    { value: 'links', label: 'Links'},
                                    { value: 'pills', label: 'Pills'},
                                    { value: 'border', label: 'Border'},
                                ]">
                            </radio-group-inline>
                        </div>
                    </form-section>
                </div>
            </template>
            <template #footer>
                <btn @click="updateSettings" :loading="loading.updating">Update</btn>
            </template>
        </modal>
    </div>
</template>

<script setup>

import {ref} from 'vue';
import appearance from "../../../../../../helpers/appearanceConfig";
import Modal from "../../../../../../elements/Modal.vue";

const props = defineProps({
    program: {
        type: [String, Number],
        required: true,
    }
});

const errors = ref({});
const loading = ref({
    updating: false
});
const partner_dashboard_appearance = ref(null);

const settings = ref({
    portal_layout: appearance.get('portal_layout', 'one'),
    portal_layout_background_color: appearance.get('portal_layout_background_color', 'gray-50'),
    portal_layout_background_gradient_color: appearance.get('portal_layout_background_gradient_color', 'none'),
    portal_layout_text_color: appearance.get('portal_layout_text_color', 'gray-800'),
    portal_layout_brand_primary_color_as_partner_and_pages_primary: appearance.get('portal_layout_brand_primary_color_as_partner_and_pages_primary', false),
    portal_layout_link_color: appearance.get('portal_layout_link_color', 'teal-600'),
    portal_layout_button_color: appearance.get('portal_layout_button_color', 'teal'),
    portal_layout_layout_style: appearance.get('portal_layout_layout_style', 'border'),
    portal_layout_layout_corners: appearance.get('portal_layout_layout_corners', 'rounded'),
    portal_layout_icons: appearance.get('portal_layout_icons', 'outline'),
    portal_layout_logo_size: appearance.get('portal_layout_logo_size', 'max-h-6'),
    portal_layout_navigation_color: appearance.get('portal_layout_navigation_color', 'brand'),
    portal_layout_navigation_links: appearance.get('portal_layout_navigation_links', 'border')
});

const updateSettings = () => {

    if (loading.value.updating) {
        return;
    }
    loading.value.updating = true;

    axios
        .post(route('app_int.program.portal.settings.appearance_of_partner_dashboard.update', {program: props.program}), {
            settings: settings.value,
        })
        .then(response => {
            partner_dashboard_appearance.value.onCancel();
        })
        .catch(error => {
            errors.value = error?.response?.data?.errors || {};
        })
        .finally(() => loading.value.updating = false);
};

</script>

<style scoped>

</style>
