<template>

    <table class="min-w-full mt-1 divide-y divide-gray-200">
        <thead>
        <tr class="h-1">
            <th :colspan="TABLE_COLUMNS_COUNT">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <th scope="col"
                class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                Action
            </th>
            <th scope="col"
                class="pl-8 pr-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                Details
            </th>
            <th scope="col"
                class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                Credit
            </th>
            <th scope="col"
                class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                Status
            </th>
            <th scope="col"
                class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                Date
            </th>
        </tr>
        <tr class="h-1" />
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!recentActivities || recentActivities?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && recentActivities?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr v-for="(recentActivity, index) in recentActivities"
                :key="recentActivity.referral_activity_id + '-' + recentActivity.customer_id + '-' + index"
                class="hover:bg-gray-50 cursor-pointer group"
            >
                <td class="px-4 py-4 whitespace-nowrap text-gray-500 text-sm font-medium text-gray-900">
                    <span
                        class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 capitalize">
                        {{ recentActivity.action }}
                    </span>
                </td>
                <td class="pl-8 pr-4 py-4 whitespace-nowrap font-semibold text-sm text-gray-900">
                    {{ recentActivity.customer_full_name }}
                    <div class="text-sm font-normal text-gray-500">{{ recentActivity.email }}</div>
                </td>

                <td class="px-4 py-4 whitespace-nowrap text-gray-500 text-sm font-medium text-gray-900">
                    <!-- Make helper for currency calc on js side or calculate it on backend -->
                    {{ recentActivity.currency_amount }}
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-gray-500 text-sm font-medium text-gray-900">
                    <referral-status
                        :status="(recentActivity.action === 'signup' && recentActivity.deleted_at) ? 'deleted' : recentActivity.status"
                    />
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-gray-500 text-sm text-gray-500">
                    <time datetime="2021-04-12 07:08:55">
                        {{ recentActivity.parsed_date }}
                    </time>
                </td>
            </tr>
        </template>
        </tbody>
    </table>

</template>

<script>
import InitialLoadingSkeletonRows from "../../../../common/InitialLoadingSkeletonRows";
import ProgressBar from "../../../../../elements/ProgressBar";
import ReferralStatus from "../../../../common/ReferralStatus";
import NoSearchDataRow from "../../../../common/NoSearchDataRow";
import filtersAndPagination from "../../../../../composables/common/filter-and-pagination-data";
import useTableDataExporter from "../../../../../composables/common/select-export-and-delete-data";

export default {
    name: "ReferAFriendActivitiesDataTable",
    emits: ['hide-options'],
    components: {
        InitialLoadingSkeletonRows,
        NoSearchDataRow,
        ProgressBar,
        ReferralStatus
    },
    props: {
        recentActivities: {
            default: []
        }
    },
    setup() {
        const TABLE_COLUMNS_COUNT = 5
        const { loadingFiltersAndPaginationData } = filtersAndPagination()

        const { exportOptions } = useTableDataExporter()

        return { loadingFiltersAndPaginationData, TABLE_COLUMNS_COUNT, exportOptions }
    },
    methods: {

    }
}
</script>

