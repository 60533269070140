<template>
    <a
        :href="href"
        class="inline-block py-4 px-6 rounded-t -mb-px text-gray-900 text-sm"
        :class="{
            'bg-white border border-b-0 font-semibold': active,
            'hover:text-teal-600': !active,
        }"
    >
        <slot />
    </a>
</template>

<script>
export default {
    props: {
        href: {
            type: String,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
