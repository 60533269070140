<template>
    <div class="space-y-12">

        <div class="space-y-4">
            <div class="relative">
                <text-field
                    type="text"
                    v-model="nameFilter"
                    @update:modelValue="filter"
                    field-class="rounded-full pl-9"
                    placeholder="Search"
                />
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <icon name="magnifier-glass"></icon>
                </div>
            </div>

            <div class="relative">
                <div class="overflow-x-scroll flex items-center gap-2 pr-8">
                    <template v-for="(category, categoryCode) in categories">
                        <btn
                            class="flex-shrink-0"
                            @click="categoryChange(categoryCode)"
                            :type="categoryFilter.includes(categoryCode) ? 'primary' : 'white'" rounded-full="true">
                            {{ category }}
                        </btn>
                    </template>
                </div>
                <div class="absolute top-0 right-0 h-full from-white bg-gradient-to-l z-10 pointer-events-none w-8"></div>
            </div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">

            <template v-for="(item, index) in items">

                <a v-if="item.show"
                   @click="showEntry(item)"
                   class="group bg-gray-100 rounded p-6 flex items-center hover:scale-102 transform transition ease-in-out"
                >
                    <div class="flex-shrink-0 mr-4">
                        <img
                            class="max-w-100 object-cover rounded max-w-16 max-h-16"
                            :src="item.logo"
                        >
                    </div>
                    <div class="relative overflow-hidden truncate">
                        <div class="text-sm font-semibold text-gray-900">
                            {{ item.name }}
                        </div>
                        <div class="text-sm text-gray-500 truncate mb-1">
                            {{ item.description }}
                        </div>
                        <div class="flex text-xs gap-2 items-center">
                            <div class="flex gap-1.5">
                                    <span class="text-xs">
                                        {{ item.commission_value.substring(0, 20) }}
                                        <span v-if="item.commission_value.length > 20">...</span>
                                    </span>
                                <span class="text-xs">commission</span>
                            </div>
                            <span class="text-gray-300">&bull;</span>
                            <span class="text-xs">{{ item.commission_period.substring(0, 15) }}</span>
                        </div>
                    </div>

                    <div class="ml-auto">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            class="w-6 h-6 text-gray-300 transition-transform transform group-hover:rotate-45 group-hover:text-gray-900"
                        >
                            <path
                                d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </a>

            </template>

        </div>
    </div>
</template>
<script setup>
import {ref, defineProps, getCurrentInstance} from 'vue';
import Btn from "../../../../elements/Btn.vue";

const props = defineProps({
    items: {
        type: Array,
        default: []
    },
    categories: {
        type: Object,
        default: {}
    }
});

const items = ref(props.items);
const categories = ref(props.categories);

const nameFilter = ref('');
const categoryFilter = ref([]);

const categoryChange = (category) => {
    if (categoryFilter.value.includes(category)) {
        categoryFilter.value = categoryFilter.value.filter((item) => item !== category);
    } else {
        categoryFilter.value.push(category);
    }
    filter();
};

const filter = () => {

    items.value.forEach((item) => {
        item.show = true;
    });

    if (categoryFilter.value.length > 0) {
        items.value.forEach((item) => {
            item.show = categoryFilter.value.includes(item.category);
        });
    }

    const searchString = nameFilter.value.toLowerCase();
    if (searchString.length > 2) {
        items.value.forEach((item) => {
            if (item.show) {
                item.show = item.name.toLowerCase().includes(searchString);
            }
        });
    }
}

const showEntry = (item) => {
    window.location.href = route('marketplace.partnero-list.entry', {id: item.id});
}

</script>

