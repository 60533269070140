<template>
    <div
        v-if="payout.info || payout.resubmitRequestMessage || payout.rejectMessage || payout.has_items"
        class="flex flex-column items-center justify-end"
    >
        <modal :title="'Payout request details'" size="huge" name="payout-details-modal" @modalClose="$emit('modal-closed')">
            <template #toggle>
                <btn type="light" size="small" class="whitespace-nowrap">
                    View details
                </btn>
            </template>
            <template #body>
                <div class="grid gap-8 lg:grid-cols-3">
                    <div>
                        <div class="p-6 bg-white rounded-md border space-y-4">
                            <div>
                                <div class="text-gray-500 text-sm">
                                    Requested amount
                                </div>
                                <h3 class="text-lg font-semibold">{{ payout.amount }}</h3>
                            </div>

                            <template v-if="payout.info">

                                <div v-if="payout.info['gateway'] === payout.preferred_gateway_bank"
                                    class="space-y-4"
                                >
                                    <div>
                                        <div class="text-gray-500 text-sm">Method</div>
                                        <h4 class="font-medium">Wire Transfer</h4>
                                    </div>
                                    <div v-if="payout.scheduled_at_formatted" >
                                        <div class="text-gray-500 text-sm">
                                            Scheduled at
                                        </div>
                                        <h4 class="font-medium">{{payout.scheduled_at_formatted}}</h4>
                                    </div>
                                    <div v-for="field in bankTransferFields">
                                        <div class="text-gray-500 text-sm">{{ field.name }}</div>
                                        <h4 class="font-medium">{{ payout.info['bank_transfer_info'][field.key] || field.valueDefault || '--' }}</h4>
                                    </div>
                                </div>

                                <div v-else-if="payout.info['gateway'] === payout.preferred_gateway_paypal"
                                     class="space-y-4"
                                >
                                    <div>
                                        <div class="text-gray-500 text-sm">
                                            Method
                                        </div>
                                        <h4 class="font-medium">PayPal</h4>
                                    </div>
                                    <div v-if="payout.scheduled_at_formatted" >
                                        <div class="text-gray-500 text-sm">
                                            Scheduled at
                                        </div>
                                        <h4 class="font-medium">{{payout.scheduled_at_formatted}}</h4>
                                    </div>
                                    <div v-if="payout.info?.['paypal_info']?.['name']">
                                        <div class="text-gray-500 text-sm">
                                            Full name
                                        </div>
                                        <h4 class="font-medium">{{ payout.info['paypal_info']['name'] }}</h4>
                                    </div>
                                    <div v-if="payout.info?.['paypal_info']?.['username']">
                                        <div class="text-gray-500 text-sm">
                                            PayPal email
                                        </div>
                                        <h4 class="font-medium">{{ payout.info['paypal_info']['username'] }}</h4>
                                    </div>
                                    <div v-if="payout.info?.['paypal_info']?.['address']">
                                        <div class="text-gray-500 text-sm">
                                            Address
                                        </div>
                                        <h4 class="font-medium">{{ payout.info['paypal_info']['address'] }}</h4>
                                    </div>
                                    <div v-if="payout.info?.['paypal_info']?.['tax_id']">
                                        <div class="text-gray-500 text-sm">Tax ID
                                        </div>
                                        <h4 class="font-medium">{{ payout.info['paypal_info']['tax_id'] }}</h4>
                                    </div>
                                    <div v-if="payout.download_invoice_link" >
                                        <div class="text-gray-500 text-sm mb-1.5">
                                            Invoice
                                        </div>
                                        <btn type="light" size="pill" :href="payout.download_invoice_link">
                                            Download
                                        </btn>
                                    </div>

                                </div>
                                <div v-else-if="payout.info['gateway'] === payout.preferred_gateway_crypto"
                                     class="space-y-4"
                                >
                                    <div>
                                        <div class="text-gray-500 text-sm">
                                            Method
                                        </div>
                                        <h4 class="font-medium">Crypto</h4>
                                    </div>
                                    <div v-if="payout.info?.['crypto_info']?.['type']">
                                        <div class="text-gray-500 text-sm">
                                            Crypto type
                                        </div>
                                        <h4 class="font-medium">{{ getCryptoName(payout.info['crypto_info']['type']) }}</h4>
                                    </div>
                                    <div v-if="payout.info?.['crypto_info']?.['name']">
                                        <div class="text-gray-500 text-sm">
                                            Full name
                                        </div>
                                        <h4 class="font-medium">{{ payout.info['crypto_info']['name'] }}</h4>
                                    </div>
                                    <div v-if="payout.info?.['crypto_info']?.['wallet_address']">
                                        <div class="text-gray-500 text-sm">
                                            Wallet address
                                        </div>
                                        <h4 class="font-medium">{{ payout.info['crypto_info']['wallet_address'] }}</h4>
                                    </div>
                                    <div v-if="payout.download_invoice_link" >
                                        <div class="text-gray-500 text-sm mb-1.5">
                                            Invoice
                                        </div>
                                        <btn type="light" size="pill" :href="payout.download_invoice_link">
                                            Download
                                        </btn>
                                    </div>
                                </div>
                            </template>

                        </div>

                        <div
                            v-if="payout.resubmit_request_message"
                            class="p-6 bg-white rounded-md border space-y-4 @if(!empty($info)) mt-2 @endif">
                            <div>
                                <div class="text-gray-500 text-sm">
                                    Resubmit request reason
                                </div>
                                <h3 class="text-sm">{{ payout.resubmit_request_message }}</h3>
                            </div>
                        </div>
                        <div
                            v-if="payout.reject_message"
                            class="p-6 bg-white rounded-md border space-y-4 @if(!empty($info) || !empty($resubmitRequestMessage)) mt-2 @endif">
                            <div>
                                <div class="text-gray-500 text-sm">
                                    Reject reason
                                </div>
                                <h3 class="text-sm">{{ payout.reject_message }}</h3>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="payout && payout.has_items"
                        class="lg:col-span-2"
                    >
                        <div class="bg-white rounded overflow-hidden border">
                            <div class="overflow-x-auto">
                                <div
                                    class="align-middle inline-block min-w-full">
                                    <table
                                        class="min-w-full mt-1 divide-y divide-gray-200">
                                        <thead>
                                        <tr>
                                            <th scope="col"
                                                class="pl-6 pr-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                                Customer
                                            </th>
                                            <th scope="col"
                                                class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                                Action
                                            </th>
                                            <th scope="col"
                                                class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                                Customer reward
                                            </th>
                                            <th scope="col"
                                                class="px-4 py-3 text-center text-xs font-normal text-gray-500 uppercase tracking-wider">
                                                Date
                                            </th>
                                            <th scope="col" class="px-4 py-3 text-right text-xs font-normal text-gray-500 uppercase tracking-wider"></th>
                                        </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                        <tr
                                            v-for="(item, index) in payout.items"
                                            :key="'payout-item-' + index"
                                            class="hover:bg-gray-50 cursor-pointer group">
                                            <td class="pl-6 pr-4 py-3 whitespace-nowrap font-semibold text-sm text-gray-900">
                                                <a href="#!"
                                                   class="hover:text-teal-600">
                                                    {{ item['customer'] || 'n/a'}}
                                                </a>
                                            </td>
                                            <td class="px-4 py-3 whitespace-nowrap font-medium text-sm text-gray-900">
                                                <span
                                                    class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                                                    {{ item['action'] || 'n/a'}}
                                                </span>
                                            </td>
                                            <td class="px-4 py-3 whitespace-nowrap text-gray-500 text-sm font-medium text-gray-900">
                                                {{ item.currency_amount || 'n/a'}}
                                            </td>
                                            <td class="pl-4 pr-6 py-3 whitespace-nowrap text-right text-sm text-gray-500">
                                                {{ item.date_formatted }}
                                            </td>
                                            <td class="pl-4 pr-6 py-3 whitespace-nowrap text-right text-sm text-gray-500">
                                                <reject-payout-request-reward-modal
                                                    v-if="metaData.reject_statuses.includes(payout.status)"
                                                    @payout-updated="closePayoutRewardRejectModal"
                                                    :program-id="payout.program_id"
                                                    :payout-id="payout.id"
                                                    :reward-id="item.reward_id"
                                                    :max-reject-reasons-length="metaData.reward_max_reject_reasons_length"
                                                    default-reject-reason=""
                                                    class="text-left"
                                                ></reject-payout-request-reward-modal>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <div class="p-4 border-t flex gap-4 justify-end bg-gray-50">

                                        <standalone-export
                                            :unique-key="payout.id"
                                            :export-url="exportUrl"
                                        ></standalone-export>

                                        <btn  type="light"
                                              size="pill" :href="payout.url">
                                            All transactions
                                        </btn>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <form class="flex justify-end items-center">
                    <reject-request-modal v-if="metaData.reject_statuses.includes(payout.status)"
                                          @payout-updated="closePayoutDetailsModal"
                                          :program-id="payout.program_id"
                                          :payout-id="payout.id"
                                          :max-reject-reasons-length="metaData.max_reject_reasons_length"
                                          :default-reject-reason="metaData.default_reject_reason"
                    />
                    <resubmit-request-modal v-if="metaData.resubmit_statuses.includes(payout.status)"
                                            @payout-updated="closePayoutDetailsModal"
                                            :program-id="payout.program_id"
                                            :payout-id="payout.id"
                                            :max-resubmit-reasons-length="metaData.max_resubmit_reasons_length"
                                            :default-resubmit-reason="metaData.default_resubmit_reason"
                     />
                    <approve-request-modal v-if="metaData.approve_statuses.includes(payout.status)"
                                           @payout-updated="closePayoutDetailsModal"
                                           :program-id="payout.program_id"
                                           :payout-id="payout.id"
                    />
                    <btn v-if="metaData.finish_statuses.includes(payout.status)"
                        type="primary"
                        class="ml-3"
                        @click.native="markRequestAsPaid(payout.id, payout.program_id)"
                        :loading="updatingPayout"
                        :disabled="updatingPayout"
                    >
                        Mark as paid
                    </btn>
                </form>
            </template>
        </modal>
    </div>
</template>

<script>
import useFiltersAndPagination from "../../../../../state/common/filters-and-pagination";
import RejectRequestModal from "./RejectRequestModal";
import ResubmitRequestModal from "./ResubmitRequestModal";
import ApproveRequestModal from "./ApproveRequestModal";
import usePayouts from "../../../../../composables/app/payouts";

export default {
    name: "PayoutDetailsModal",
    components: {ApproveRequestModal, ResubmitRequestModal, RejectRequestModal },

    props: {
        payout: {
            type: Object,
            required: true
        },
        bankTransferFields: {
            type: Object,
            default: () => {}
        }
    },

    setup(props) {
        const { metaData } = useFiltersAndPagination()
        const { updatePayout, updatingPayout } = usePayouts()

        const exportUrl = route('app_int.payout.export', {program: props.payout.program_id, payout: props.payout.id})

        return { metaData, updatePayout, exportUrl, updatingPayout }
    },

    methods: {
        markRequestAsPaid(payoutId, programId) {
            this.updatePayout(programId, { finish: 1, payout_request: payoutId }).finally(() => {
                this.closePayoutDetailsModal()
            })
        },
        closePayoutDetailsModal() {
            this.emitter.emit('modal-off', 'payout-details-modal');
        },
        getCryptoName(value) {
            return this.metaData.crypto_types?.[value]?.name ?? '--'
        },
        closePayoutRewardRejectModal() {
            this.emitter.emit('modal-off', 'reject-payout-reward-modal');
        },
    }
}
</script>
