<template>
    <modal :title="'Cancel subscription'" size="small">
        <template #toggle>
            <btn type="link" class="text-sm text-white opacity-75">Cancel
                subscription
            </btn>
        </template>
        <template #body>
            <div class="mb-8 text-sm text-gray-900 tracking-wid">What is the biggest
                reason for your wish to cancel your subscription?
            </div>
            <div>
                <radio-group-default
                    name="cancel_reasons"
                    @update:modelValue="reasonUpdate"
                    :options="[
                                { value: 'different_needs', label: 'The product doesn\'t meet my needs'},
                                { value: 'too_expensive', label: 'It\'s too expensive'},
                                { value: 'technical_issues', label: 'I had technical issues'},
                                { value: 'alternative', label: 'I found better alternative'},
                                { value: 'testing', label: 'I was just testing'},
                                { value: 'other', label: 'Other'},
                            ]"
                />
            </div>
            <div class="mt-4">
                <text-field
                    field-id="other_reason"
                    name="other_reason"
                    type="text"
                    :model-value="reason_other">
                </text-field>
            </div>
        </template>
        <template #footer>
            <btn :loading="loading.canceling" @click="cancelPlan">Proceed</btn>
        </template>
    </modal>
</template>
<script>
export default {
    name: "StripeCheckout",
    props: {
        subscription_id: {type: String, required: true},
        cancel_plan_url: {type: String, required: true},
    },
    data() {
        return {
            reason: null,
            reason_other: null,
            loading: {
                canceling: false
            }
        }
    },
    methods: {
        cancelPlan() {
            if (this.loading.canceling) {
                return;
            }

            this.loading.canceling = true;

            axios.post(this.cancel_plan_url, {
                'reason': this.reason,
                'reason_other': this.reason_other,
                'subscription': this.subscription_id
            })
                .then((response) => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        window.location.reload();
                    }
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    this.loading.canceling = false
                });
        },
        reasonUpdate(value) {
            this.reason = value;
        }
    }
}
</script>
<style scoped></style>
