<template>
    <div v-if="errors.length > 0">
        <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm mb-6" role="alert">
            <ul>
                <li v-for="error in errors">{{ error }}</li>
            </ul>
        </div>
    </div>

    <div class="bg-white border rounded relative">
        <div v-if="step === 1">
            <div class="px-10 py-14">
                <form-layout>
                    <form-section title="Program details" description="Set up the main details of your referral program.">

                        <text-field
                            label="Program name"
                            field-id="name"
                            name="name"
                            type="text"
                            autofocus="true"
                            v-model="data.programName"
                            required="true"
                        >
                            <template #note>
                                This name will be visible to your customers when they sign up.
                            </template>
                        </text-field>

                        <text-field
                            label="Website URL"
                            field-id="website"
                            name="landingPageUrl"
                            type="text"
                            placeholder="https://"
                            :prefixes="['https://', 'http://']"
                            v-model="data.landingPageUrl"
                            required="true"
                        >
                            <template #note>
                                The page that your customers’ referral links will lead to.
                            </template>
                        </text-field>
                    </form-section>
                </form-layout>
            </div>
            <div class="bg-gray-50 px-10 py-4 text-right rounded-b sticky bottom-0 z-40">
                <btn button-type="button" type="primary" @click="validateStepOne" :loading="loading.validatingStepOne">
                    Next
                </btn>
            </div>
        </div>

        <div v-if="step === 2">
            <div class="px-10 py-14 space-y-8">

                <form-layout>
                    <form-section title="Reward structure"
                                  description="Specify how you plan to reward your customers."
                    >
                        <radio-group
                            name="commission_options"
                            id="predefined_commission_options"
                            :model-value="data.predefinedCommissionOption"
                            :options="commissionOptions"
                            @update:model-value="predefinedCommissionOptionChanged"
                        >
                            <template #note>
                                The reward structure can be modified at any time in the future.
                            </template>
                        </radio-group>

                        <div v-if="data.partner.commission.type !== 'none'">
                            <text-field
                                v-if="data.partner.commission.type === 'generic_coupon'"
                                name="partner_commission_value"
                                label="Coupon for referring customer"
                                placeholder="E.g. DISCOUNT20"
                                v-model="data.partner.commission.value"
                            >
                                <template #note>
                                    Enter an existing coupon or promo code to reward the referring customer.
                                </template>
                            </text-field>

                            <div class="-space-y-px" v-if="show.commission_type">
                                <radio-group
                                    name="commission_type"
                                    id="commission_type"
                                    layout="horizontal"
                                    model-value="percent"
                                    :options="[
                                        { value: 'percent', label: 'Percentage', description: 'Reward your customers with a percentage amount of reward units.'},
                                        { value: 'static', label: 'Fixed amount', description: 'Reward your customers with a fixed amount of reward units.'}
                                    ]"
                                    @update:model-value="data.partner.commission.type = $event">
                                </radio-group>
                            </div>
                            <div class="grid gap-8 mt-6 md:grid-cols-2" v-show="show.commission_value_and_period">
                                <div>
                                    <label for="commission" class="block mb-1.5 text-sm">Commission and currency</label>
                                    <div class="flex relative rounded">
                                    <span v-if="data.partner.commission.type === 'percent'"
                                          class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">%</span>
                                        <input type="text" name="commission" id="commission"
                                               :required="true"
                                               v-model="data.partner.commission.value"
                                               class="block w-full rounded-r border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                                               :class="{ 'rounded-l': data.partner.commission.type !== 'percent' }"
                                        />
                                        <div class="flex absolute inset-y-0 right-0 items-center">
                                            <label for="currency" class="sr-only">Currency</label>
                                            <select
                                                v-model="commission.units_currency"
                                                class="py-0 pr-7 pl-2 h-full text-gray-500 bg-transparent rounded border-transparent focus:ring-0 focus:border-gray-400 sm:text-sm">
                                                <option v-for="currency in props.currencies" :value="currency.code_upper">
                                                    {{ currency.code_upper }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <small v-if="commission.type === 'percent'" class="text-gray-500 mt-1 block">
                                        The percentage of the sales amount allocated to each conversion, for example, 30
                                        percent.
                                    </small>
                                    <small v-if="commission.type === 'static'" class="text-gray-500 mt-1 block">
                                        The fixed portion of the sales amount designated for each conversion, such as $50.
                                    </small>
                                </div>
                                <div>
                                    <CommissionCustomPeriod
                                        v-if="commission.units === 'shopify_store_credit'"
                                        name="Shopify store credit expiry"
                                        description="Choose store credit validity period."
                                        select_name="commission_units_shopify_store_credit_period_type"
                                        input_name="commission_units_shopify_store_credit_period_times_value"
                                        :period_type="commission.units_shopify_store_credit_period_type"
                                        :period_times_value="commission.units_shopify_store_credit_period_times_value"
                                        @update:period_type="commission.units_shopify_store_credit_period_type = $event"
                                        @update:period_times_value="commission.units_shopify_store_credit_period_times_value = $event"
                                    />
                                </div>
                            </div>


                            <div v-if="data.program && data.partner.commission.type === 'dynamic_coupon'" class="space-y-4">
                                <div v-if="data.partner.commission.type === 'dynamic_coupon' && !isShopifyConnectingProgram" class="border rounded p-4">
                                    <integration-stripe
                                        :integrations-config="newProgramIntegrationStripeConfig"
                                        :program-id="data.program?.id || null"
                                        :sync-customers-available="data.program?.program_type?.type === 'refer_a_friend'"
                                        :o-auth-session-data="data"
                                        @stripe-connected="handleStripeConnect"
                                        :checking-connection="checkingOAuthConnection"
                                    ></integration-stripe>
                                </div>

                                <div class="space-y-4" v-if="data.partner.commission.type === 'dynamic_coupon'">
                                    <div>
                                        <div>
                                            <label class="block text-sm leading-5 mb-1.5">Coupon for referring customer</label>
                                            <div class="grid grid-cols-3 gap-4 items-center">
                                                <text-field
                                                    class="col-span-2"
                                                    type="text"
                                                    name="partner_commission_value"
                                                    v-model="partnerSelectedCouponName"
                                                    :disabled="true"
                                                    placeholder="A new coupon will appear here after creation"
                                                >
                                                    <template #append>
                                                        <div v-if="data.partnerSelectedCoupon" class="inline-flex items-center pl-2 cursor-pointer">
                                                            <icon
                                                                :disabled="!data.partnerSelectedCoupon"
                                                                name="trash"
                                                                size="18"
                                                                @click="data.partnerSelectedCoupon = null"
                                                            />
                                                        </div>
                                                    </template>
                                                </text-field>
                                                <CouponModal v-if="!data.partnerSelectedCoupon"
                                                             :program="data.program"
                                                             :coupon-data="data.couponData"
                                                             :sync-provider="getSyncProvider"
                                                             :coupon-sync-providers="[getSyncProvider]"
                                                             disable-in-app-coupon
                                                             coupon-action="create"
                                                             @coupon-created="handlePartnerCouponCreated"
                                                             id="partner-coupon-modal"
                                                             :disabled="!newProgramIntegrationStripeConfig.connected && !isShopifyConnectingProgram"
                                                >
                                                </CouponModal>
                                            </div>
                                            <small v-if="newProgramIntegrationStripeConfig.connected" class="text-gray-500 mt-1 block">Create a new coupon.</small>
                                            <small v-else class="text-gray-500 mt-1 block">
                                                Once you connect your
                                                {{ getSyncProviderFirstLetterCapitalized }}
                                                account, you’ll be able to create a new
                                                {{ getSyncProviderFirstLetterCapitalized }}
                                                coupon.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="space-y-8" v-if="data.partnerSelectedCoupon && data.partnerSelectedCoupon.sync_provider === 'stripe'">
                                        <toggle title="Apply a coupon to existing customers"
                                                name="stripe_products_for_partner_to_apply_coupon_to_enabled"
                                                v-model="data.stripeProductsForPartnerToApplyCouponToEnabled"
                                        >
                                            <template #description>
                                                When enabled, you can apply a coupon to an existing customer for a specific product.
                                            </template>
                                        </toggle>
                                    </div>

                                    <div v-if="data.stripeProductsForPartnerToApplyCouponToEnabled && data.partnerSelectedCoupon && data.partnerSelectedCoupon.sync_provider === 'stripe'">
                                        <div>
                                            <label class="block text-sm leading-5 mb-1.5">Product</label>
                                            <VueMultiselect
                                                id="partner-stripe-product-select"
                                                name="stripe_products_for_partner_to_apply_coupon_to"
                                                placeholder="Select a product"
                                                :options="stripeProductsListForPartner"
                                                track-by="id"
                                                label="name"
                                                v-model="data.selectedStripeProductsToApplyCouponToForPartner"
                                                :multiple="true"
                                                :searchable="true"
                                                :loading="isLoadingStripeProductsForPartner"
                                                :internal-search="false"
                                                :clear-on-select="false"
                                                :close-on-select="false"
                                                :options-limit="300"
                                                :limit="20"
                                                :limit-text="limitText"
                                                :max-height="600"
                                                :show-no-results="true"
                                                @search-change="searchStripeProductsForPartners"
                                            ></VueMultiselect>
                                            <small class="text-gray-500 mt-1 block">Select a product to which a coupon needs to be applied.</small>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="data.referral.commission.type === 'dynamic_coupon'" class="space-y-4">
                                    <div>
                                        <div>
                                            <label class="block text-sm leading-5 mb-1.5">Coupon for referred customer</label>
                                            <div class="grid grid-cols-3 gap-4 items-center">
                                                <text-field
                                                    class="col-span-2"
                                                    type="text"
                                                    name="referral_commission_value"
                                                    v-model="referralSelectedCouponName"
                                                    :disabled="true"
                                                    placeholder="A new coupon will appear here after creation"
                                                >
                                                    <template #append>
                                                        <div v-if="data.referralSelectedCoupon" class="inline-flex items-center pl-2 cursor-pointer">
                                                            <icon
                                                                :disabled="!data.referralSelectedCoupon"
                                                                name="trash"
                                                                size="18"
                                                                @click="data.referralSelectedCoupon = null"
                                                            />
                                                        </div>
                                                    </template>
                                                </text-field>
                                                <CouponModal v-if="!data.referralSelectedCoupon"
                                                             :program="data.program"
                                                             :coupon-data="data.couponData"
                                                             :sync-provider="getSyncProvider"
                                                             :coupon-sync-providers="[getSyncProvider]"
                                                             disable-in-app-coupon
                                                             coupon-action="create"
                                                             @coupon-created="handleReferralCouponCreated"
                                                             id="referral-coupon-modal"
                                                             :disabled="!newProgramIntegrationStripeConfig.connected && !isShopifyConnectingProgram"
                                                >
                                                </CouponModal>
                                            </div>
                                            <small v-if="newProgramIntegrationStripeConfig.connected" class="text-gray-500 mt-1 block">Create a new coupon.</small>
                                            <small v-else class="text-gray-500 mt-1 block">
                                                Once you connect your
                                                {{ getSyncProviderFirstLetterCapitalized }}
                                                account, you’ll be able to create a new
                                                {{ getSyncProviderFirstLetterCapitalized }}
                                                coupon.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="space-y-8" v-if="data.referralSelectedCoupon && data.referralSelectedCoupon.sync_provider === 'stripe'">
                                        <toggle title="Apply a coupon to existing customers"
                                                name="stripe_products_for_referral_to_apply_coupon_to_enabled"
                                                v-model="data.stripeProductsForReferralToApplyCouponToEnabled"
                                        >
                                            <template #description>
                                                When enabled, you can apply a coupon to an existing customer for a specific product.
                                            </template>
                                        </toggle>
                                    </div>

                                    <div v-if="data.stripeProductsForReferralToApplyCouponToEnabled && data.referralSelectedCoupon && data.referralSelectedCoupon.sync_provider === 'stripe'">
                                        <div>
                                            <label class="block text-sm leading-5 mb-1.5">Product</label>
                                            <VueMultiselect
                                                id="referral-stripe-product-select"
                                                class="col-span-2"
                                                name="stripe_products_for_referral_to_apply_coupon_to"
                                                placeholder="Select a product"
                                                :options="stripeProductsListForReferral"
                                                track-by="id"
                                                label="name"
                                                v-model="data.selectedStripeProductsToApplyCouponToForReferral"
                                                :multiple="true"
                                                :searchable="true"
                                                :loading="isLoadingStripeProductsForReferral"
                                                :internal-search="false"
                                                :clear-on-select="false"
                                                :close-on-select="false"
                                                :options-limit="300"
                                                :limit="20"
                                                :limit-text="limitText"
                                                :max-height="600"
                                                :show-no-results="true"
                                                @search-change="searchStripeProductsForReferrals"
                                            ></VueMultiselect>
                                            <small class="text-gray-500 mt-1 block">Select a product to which a coupon needs to be applied.</small>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-if="data.referral.commission.type !== 'none'">
                            <text-field
                                v-if="data.referral.commission.type === 'generic_coupon'"
                                name="referral_commission_value"
                                label="Coupon for referred customer"
                                placeholder="E.g. DISCOUNT20"
                                v-model="data.referral.commission.value"
                            >
                                <template #note>
                                    Enter an existing coupon or promo code to reward the referred customer.
                                </template>
                            </text-field>
                        </div>
                    </form-section>
                </form-layout>

            </div>
            <div class="bg-gray-50 px-10 py-4 text-right rounded-b sticky bottom-0 z-40">
                <btn button-type="button" type="link" class="mr-6" @click="showStep(1)">Back</btn>
                <btn button-type="submit" type="primary" @click="create" :loading="loading.creating">
                    Proceed
                </btn>
            </div>
        </div>

    </div>
</template>

<script setup>
import {computed, onBeforeMount, onMounted, reactive, ref, watch} from "vue";
import FormSection from "../../../../../elements/forms/FormSection.vue";
import TextField from "../../../../../elements/forms/TextField.vue";
import Toggle from "../../../../../elements/forms/Toggle.vue";
import CouponModal from "../../coupons/CouponModal.vue";
import VueMultiselect from "vue-multiselect";
import {useCouponStore} from "../../../../../stores/app/programs/common/coupons/CouponStore";
import Icon from "../../../../../elements/Icon.vue";
import CommissionCustomPeriod from "../../settings/CommissionCustomPeriod.vue";

const props = defineProps({
    coupon_data: {
        type: Object,
        default: () => {
            return {}
        }
    },
    stripe_o_auth_session_data: {
        type: [Object, null],
        default: () => {
            return null
        }
    },
    prefill_suggestions: {
        type: Object,
        default: () => {
            return {}
        }
    },
    currencies: {
        type: Array,
        default: () => {
            return []
        }
    }
});

const show = reactive({
    commission_type: false,
    commission_value_and_period: false,
});

const localStorageKey = 'prtnr_rafp_spod';

onBeforeMount(() => {

  const _data = props.stripe_o_auth_session_data || getStorageItem('data');
  const _step = props.stripe_o_auth_session_data ? 2 : getStorageItem('step');

  if (props.prefill_suggestions) {
    data.programName = props.prefill_suggestions.name;
    data.landingPageUrl = props.prefill_suggestions.landing_page_url;
  }

  if (_data !== null) {
    Object.assign(data, _data);
  }

  if (_step !== null) {
    step.value = _step;
  }
})

const getStorageItem = (item) => {
  const storageData = JSON.parse(localStorage.getItem(localStorageKey));

  if (storageData === null || item === null) {
    return storageData
  }

  return storageData[item] ?? null;
}

const { setShowRewardCouponAsOption } = useCouponStore()

onMounted(() => {
    if(data.program) {
      checkIfOAuthWasSuccessful(data.program)
    }

    data.couponData = props.coupon_data
    data.couponData.is_reward_coupon = true
    if (data.couponData.auto_assign_promotion_code) {
        data.couponData.auto_assign_promotion_code.is_reward_promotion_code = true
    }
    setShowRewardCouponAsOption(false)
})

const checkingOAuthConnection = ref(false)

const checkIfOAuthWasSuccessful = (program) => {
  checkingOAuthConnection.value = true
  axios.get(route('app_int.programs.type.check-stripe-oauth-status.refer-a-friend', {program: program}))
      .then(response => {
        if (response.data.status === 1) {
          newProgramIntegrationStripeConfig.connected = true
          data.program.stripe_connected = true
        }
      })
      .finally(() => {
          checkingOAuthConnection.value = false
      })
}

const data = reactive({
    programName: '',
    landingPageUrl: '',
    program: null,
    partner: {
        commission: {
            type: 'generic_coupon',
            value: '',
        }
    },
    referral: {
        commission: {
            type: 'none',
            value: '',
        }
    },
    commissionName: 'Generic coupon code for referring customer.',
    couponsList: [],
    partnerSelectedCoupon: null,
    referralSelectedCoupon: null,
    stripeProductsForPartnerToApplyCouponToEnabled: false,
    selectedStripeProductsToApplyCouponToForPartner: [],
    stripeProductsForReferralToApplyCouponToEnabled: false,
    selectedStripeProductsToApplyCouponToForReferral: [],
    couponData: {},
    predefinedCommissionOption: '1'
});

const partnerSelectedCouponName = computed(() => {
    return data.partnerSelectedCoupon ? data.partnerSelectedCoupon.name : '';
});

const referralSelectedCouponName = computed(() => {
    return data.referralSelectedCoupon ? data.referralSelectedCoupon.name : '';
});

// Shopify store data are returned from the prefill_suggestions prop containing the shopify store name, landing page url and subdomain
const isShopifyConnectingProgram = !!props.prefill_suggestions?.name;

const getSyncProvider = computed(() => {
    return isShopifyConnectingProgram ? 'shopify' : 'stripe';
});

const getSyncProviderFirstLetterCapitalized = computed(() => {
    return getSyncProvider.value.charAt(0).toUpperCase() + getSyncProvider.value.slice(1);
});

window.addEventListener('beforeunload', (event) => {

    const navigationEntries = performance.getEntriesByType('navigation');
    const navigationType = navigationEntries.length > 0 ? navigationEntries[0].type : '';

    if (navigationType !== 'reload' || step.value === 1) {
        if (step.value === 1 && data.program) {
            axios.delete(route('app_int.programs.type.delete-uncompleted-form-program.refer-a-friend', {program: data.program}));
        }
        localStorage.removeItem(localStorageKey);
    }
});

const newProgramIntegrationStripeConfig = reactive({
    connected: data.program?.stripe_connected || false,
    doNotReloadPageAfterConnect: true,
    url: {
        webhook: route('integrations.stripe.webhook', data.program?.pub_id ?? ''),
    },
    afterConnectReturnToUrl: route('programs.create.type.show', {program_type: 'refer_a_friend'}),
    supported_events: ['checkout.session.completed', 'invoice.payment_succeeded', 'charge.refunded', 'charge.succeeded', "coupon.created", "coupon.deleted", "coupon.updated", 'promotion_code.created', 'promotion_code.updated']
})

const handleStripeConnect = ($event) => {
    newProgramIntegrationStripeConfig.connected = $event
}

const stripeProductsListForPartner = ref([]);
const stripeProductsListForReferral = ref([]);

const isLoadingStripeProductsForPartner = ref(false);
const isLoadingStripeProductsForReferral = ref(false);

const delayTimerForPartners = ref(null);
const delayTimerForReferrals = ref(null);

const searchStripeProductsForPartners = (query) => {
    clearTimeout(delayTimerForPartners.value);
    delayTimerForPartners.value = setTimeout(() => {
        isLoadingStripeProductsForPartner.value = true;
        if (query.length > 0 && query.length < 3) {
            isLoadingStripeProductsForPartner.value = false;
            return;
        }
        axios.get(route('app_int.provider.products.search.stripe', {program: data.program}) + '?search=' + query)
            .then(response => {
                isLoadingStripeProductsForPartner.value = false;
                stripeProductsListForPartner.value = response.data?.data || [];
            })
            .catch(error => {
                isLoadingStripeProductsForPartner.value = false;
            })
            .finally(() => {
                isLoadingStripeProductsForPartner.value = false;
            });
    }, 700);
}


const searchStripeProductsForReferrals = (query) => {
    clearTimeout(delayTimerForReferrals.value);
    delayTimerForReferrals.value = setTimeout(() => {
        isLoadingStripeProductsForReferral.value = true;
        if (query.length > 0 && query.length < 3) {
            isLoadingStripeProductsForReferral.value = false;
            return;
        }
        axios.get(route('app_int.provider.products.search.stripe', {program: data.program}) + '?search=' + query)
            .then(response => {
                isLoadingStripeProductsForReferral.value = false;
                stripeProductsListForReferral.value = response.data?.data || [];
            })
            .catch(error => {
                isLoadingStripeProductsForReferral.value = false;
            })
            .finally(() => {
                isLoadingStripeProductsForReferral.value = false;
            });
    }, 700);
}

const limitText = (count) => {
    return `and ${count} other customers`
}

const handlePartnerCouponCreated = (coupon) => {
    setTimeout(() => {
        data.couponsList.push(coupon);
        data.partnerSelectedCoupon = {
            id: coupon.id,
            name: coupon.name,
            uuid_code: coupon.uuid_code,
            sync_provider: coupon.sync_provider
        };
    }, 500);
}
const handleReferralCouponCreated = (coupon) => {
    setTimeout(() => {
        data.couponsList.push(coupon);
        data.referralSelectedCoupon = {
            id: coupon.id,
            name: coupon.name,
            uuid_code: coupon.uuid_code,
            sync_provider: coupon.sync_provider

        };
    }, 500);
}

const validateStepOne = () => {
    errors.value = [];

    if (loading.value.validatingStepOne === true) {
        return;
    }
    loading.value.validatingStepOne = true;

    axios.post(route('app_int.programs.create.type.validate-and-create.refer-a-friend'), {
        _program_type: 'refer_a_friend',
        name: data.programName,
        landing_page_url: data.landingPageUrl,
        program: data.program
    })
        .then((response) => {
            const status = parseInt(response.data.status ?? -1);
            if (status === 1) {
                data.program = response.data.program;
                showStep(2);
            }

            if (status === 0 && response.data.errors) {
                displayErrors(response.data.errors);
            }
        })
        .catch((error) => {
            if (error.response?.data?.errors) {
                displayErrors(error.response.data.errors);
            } else {
              console.log(error)
            }
        })
        .finally(() => loading.value.validatingStepOne = false);
}

const commission = reactive({
    type: null,
    amount: null,
    units: 'custom',
    units_currency: 'USD',
    units_shopify_store_credit_period_type: 'lifetime',
    units_shopify_store_credit_period_times_value: '1',
});

const create = () => {

    errors.value = [];

    if (loading.value.creating === true) {
        return;
    }
    loading.value.creating = true;

    if (optionsConfig[data.predefinedCommissionOption]?.label === proceedWithoutDefiningRewardText && data.program) {
        localStorage.removeItem(localStorageKey);
        window.location = route('programs.onboarding.index', data.program)
    } else {
        const commissionData = {
            program_id: data.program?.id || null,
            commission_name: data.commissionName,
            reward_trigger: 'sale',
            commission_units: commission.units,
            commission_units_currency: commission.units_currency,
            commission_units_shopify_store_credit_period_type: commission.units_shopify_store_credit_period_type,
            commission_units_shopify_store_credit_period_times_value: commission.units_shopify_store_credit_period_times_value,
            commission_period_type: 'lifetime',
            commission_review_period_type: 'off',
            commission_start_trigger_type: 'immediately',
            commission_end_trigger_type: 'never',
            partner_commission_type: data.partner.commission.type,
            partner_commission_value: data.partner.commission.type === 'dynamic_coupon' ? partnerJsonCouponReward.value : data.partner.commission.value,
            referral_commission_type: data.referral.commission.type,
            referral_commission_value: data.referral.commission.type === 'dynamic_coupon' ? referralJsonCouponReward.value : data.referral.commission.value
        }

        axios.post(route('app_int.programs.create.type.store.refer-a-friend.predefine-commission'), commissionData)
            .then((response) => {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {
                    localStorage.removeItem(localStorageKey);
                    window.location = response.data.redirect_url;
                }

                if (status === 0 && response.data.errors) {
                    displayErrors(response.data.errors);
                }
            })
            .catch((error) => {
                if (error.response?.data?.errors) {
                    displayErrors(error.response.data.errors);
                }
            })
            .finally(() => loading.value.creating = false);
    }

}

const showStep = (stepValue) => {
    step.value = stepValue;
}

watch(() => step, () => {
    setStorageData();
});

watch(() => data, () => {
    setStorageData();
});

const setStorageData = () => {
    localStorage.setItem(localStorageKey, JSON.stringify({
        data: data,
        step: step.value
    }));
}

const displayErrors = (errs) => {

    let dErrors = [];
    if (typeof errs === 'object') {
        for (const property in errs) {
            dErrors = dErrors.concat(errs[property]);
        }
    }

    errors.value = dErrors;
};

const step = ref(1);
const errors = ref([]);
const loading = ref({
    creating: false,
    validatingStepOne: false
});

const proceedWithoutDefiningRewardText = 'Manually create custom rewards';

const optionsConfig = reactive({
    '1': {
        label: 'Reward a referring customer with a coupon code',
        description: 'The referring customer receives a coupon code when their referral makes a successful purchase.'
    },
    '2': {
        label: 'Reward both referring and referred customers with a coupon code',
        description: 'Both customers receive a coupon code when the referral leads to a purchase.'
    },
    '3': {
        label: 'Reward a referring customer with a dynamic Stripe coupon',
        description: 'The referring customer receives a dynamically generated Stripe coupon when their referral makes a successful purchase.'
    },
    '4': {
        label: 'Reward both referring and referred customers with dynamic Stripe coupons',
        description: 'Both customers receive dynamically generated Stripe coupons when the referral results in a successful purchase.   '
    },
    '5': {
        label: 'Reward a referring customer with a Shopify coupon code',
        description: 'Customers receive a Shopify coupon code for every successful referral.'
    },
    '6': {
        label: 'Reward referring and referred customers with a Shopify coupon codes',
        description: 'Both the referring and referred customers receive a Shopify coupon code for every successful referral.'
    },
    '7': {
        label: 'Reward a referring customer with a Shopify store credit',
        description: 'Customers receive a Shopify store credit for every successful referral.'
    },
    '8': {
        label: proceedWithoutDefiningRewardText,
        description: 'Choose to create and distribute rewards yourself, allowing full control over the type and value of each reward.'
    },
});

const commissionOptions = computed(() => {
    const options = [
        {value: '1', label: optionsConfig['1'].label, description: optionsConfig['1'].description},
        {value: '2', label: optionsConfig['2'].label, description: optionsConfig['2'].description},
    ]

    if (isShopifyConnectingProgram) {
        options.push({value: '5', label: optionsConfig['5'].label, description: optionsConfig['5'].description});
        options.push({value: '6', label: optionsConfig['6'].label, description: optionsConfig['6'].description});
        options.push({value: '7', label: optionsConfig['7'].label, description: optionsConfig['7'].description});
    } else {
        options.push({value: '3', label: optionsConfig['3'].label, description: optionsConfig['3'].description});
        options.push({value: '4', label: optionsConfig['4'].label, description: optionsConfig['4'].description});
    }

    options.push({value: '8', label: optionsConfig['8'].label, description: optionsConfig['8'].description});

    return options;
});

const predefinedCommissionOptionChanged = (option) => {
    data.predefinedCommissionOption = option;

    if (option === '1') {
        data.partner.commission.type = 'generic_coupon';
        // generate random generic code from capitalised program name without spaces, random number and 3 random letters
        data.partner.commission.value = data.programName.replace(/\s/g, '').toUpperCase() + Math.floor(Math.random() * 1000) + Math.random().toString(36).substring(2, 5).toUpperCase();

        data.referral.commission.type = 'none';
        data.referral.commission.value = '';

        data.commissionName = 'Generic coupon code for referring customer';

        show.commission_type = false;
        show.commission_value_and_period = false

        commission.units = 'custom'
    }

    if (option === '2') {
        data.partner.commission.type = 'generic_coupon';
        data.partner.commission.value = data.programName.replace(/\s/g, '').toUpperCase() + Math.floor(Math.random() * 1000) + Math.random().toString(36).substring(2, 5).toUpperCase();
        data.referral.commission.type = 'generic_coupon';
        data.referral.commission.value = data.programName.replace(/\s/g, '').toUpperCase() + 'REF' + Math.floor(Math.random() * 1000) + Math.random().toString(36).substring(2, 5).toUpperCase();

        data.commissionName = 'Generic coupon codes for referring and referred customers';

        show.commission_type = false;
        show.commission_value_and_period = false;

        commission.units = 'custom'
    }

    if (option === '3') {
        data.partner.commission.type = 'dynamic_coupon';
        data.partner.commission.value = '';

        data.referral.commission.type = 'none';
        data.referral.commission.value = '';

        data.commissionName = 'Stripe coupon code for referring customer';

        show.commission_type = false;
        show.commission_value_and_period = false;

        commission.units = 'custom'
    }

    if (option === '4') {
        data.partner.commission.type = 'dynamic_coupon';
        data.partner.commission.value = '';

        data.referral.commission.type = 'dynamic_coupon';
        data.referral.commission.value = '';

        data.commissionName = 'Stripe coupons for referring and referred customers';

        show.commission_type = false;
        show.commission_value_and_period = false;

        commission.units = 'custom'
    }

    if (option === '5') {
        data.partner.commission.type = 'dynamic_coupon';
        data.partner.commission.value = '';

        data.referral.commission.type = 'none';
        data.referral.commission.value = '';

        data.commissionName = 'Shopify coupon code for referring customer';

        show.commission_type = false;
        show.commission_value_and_period = false;

        commission.units = 'custom'
    }

    if (option === '6') {
        data.partner.commission.type = 'dynamic_coupon';
        data.partner.commission.value = '';

        data.referral.commission.type = 'dynamic_coupon';
        data.referral.commission.value = '';

        data.commissionName = 'Shopify coupons for referring and referred customers.';

        show.commission_type = false;
        show.commission_value_and_period = false;

        commission.units = 'custom'
    }

    if (option === '7') {
        data.partner.commission.type = 'percent';
        data.partner.commission.value = '10';

        data.referral.commission.type = 'none';
        data.referral.commission.value = '';

        data.commissionName = 'Shopify store credit for referring customer';

        show.commission_type = true;
        show.commission_value_and_period = true;

        commission.units = 'shopify_store_credit'
    }

    if (option === '8') {
        data.partner.commission.type = 'none';
        data.partner.commission.value = '';

        data.referral.commission.type = 'none';
        data.referral.commission.value = '';

        data.commissionName = '';

        show.commission_type = false;
        show.commission_value_and_period = false;

        commission.units = 'custom'
    }
}

const partnerJsonCouponReward = computed(() => {
    if (data.partnerSelectedCoupon == null) {
        return null;
    }

    if (data.partnerSelectedCoupon?.sync_provider === 'stripe') {
        return JSON.stringify({
            ...data.partnerSelectedCoupon,
            stripe_products_for_partner_to_apply_coupon_to_enabled: data.stripeProductsForPartnerToApplyCouponToEnabled,
            stripe_products: data.selectedStripeProductsToApplyCouponToForPartner.map(product => {
                return {
                    id: product.id,
                    name: product.name
                }
            })
        });
    }

    return JSON.stringify(data.partnerSelectedCoupon);
});

const referralJsonCouponReward = computed(() => {
    if (data.referralSelectedCoupon == null) {
        return null;
    }

    if (data.referralSelectedCoupon?.sync_provider === 'stripe') {
        return JSON.stringify({
            ...data.referralSelectedCoupon,
            stripe_products_for_referral_to_apply_coupon_to_enabled: data.stripeProductsForReferralToApplyCouponToEnabled,
            stripe_products: data.selectedStripeProductsToApplyCouponToForReferral.map(product => {
                return {
                    id: product.id,
                    name: product.name
                }
            })
        });
    }

    return JSON.stringify(data.referralSelectedCoupon);
});

</script>
