window.partnero = window.partnero || {};
window.partnero.appearanceConfig = window.partnero.appearanceConfig || {};
export default {
    config: window.partnero.appearanceConfig || {},
    get(name, defaultValue = null) {
        if (this.config.hasOwnProperty(name)) {
            return this.config[name] ?? defaultValue;
        }
        return defaultValue;
    },
    is(name, value) {
        return this.get(name) === value;
    },
    isTrue(name) {
        const value = this.get(name);
        return value === true || value === 1 || value === '1';
    },
    isCustomColor(name) {
        const value = this.get(name);
        return (typeof value === 'string' && value.startsWith('#'));
    }
};
