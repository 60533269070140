export const isCustomColor = (setting = null) => {
    return String(setting).trim().startsWith('#')
}

export const isNumber = (value) => {
    return typeof value === 'number'
}

export const createRouteForTemplate = (path, params = {}) => {
    return route(path, params)
}

export const generateRandomShortString = () => {
    return (Math.random() + 1).toString(36).substring(7);
}

export const setFilterFields = (filters, filterList, setToDefaults = false) => {
    Object.keys(filterList).forEach(filterField => setInitialFilterFieldValue(filters, filterList, filterField, setToDefaults))
}

function setInitialFilterFieldValue(filters, filterList, filterField, setToDefaults) {
    switch (filterList[filterField].type) {
        case 'select':
            /*
            *  request_value is value when redirecting from some other page - check Program/Partners/FilterableFields.php
            *  setToDefaults means don't use requested values (needed when clearing all filters from Clear all button)
            */
            if (setToDefaults) {
                filters[filterField] = filterList[filterField].default_value || ''
            } else {
                filters[filterField] = filterList[filterField].request_value || filterList[filterField].default_value || ''
            }
            break
        case 'text_with_condition_select':
            filters[filterField] = filterList[filterField].default_text || ''
            filters[filterField + 'Condition'] = filterList[filterField].default_select_value || ''
            break
        case 'range_datepicker':
            filters.date_from = filterList[filterField].date_from
            filters.date_to = filterList[filterField].date_to
            break
        case 'data_transfer':
            filters[filterField] = filterList[filterField].value;
            break
    }
    return filterList[filterField];
}

export const formatNumber = (number) => {
    if(!isNaN(Number(number))) {
        return Number(number).toLocaleString('us');
    }
    return number;
}

export const copyToClipboard = (text) => {
    if (!navigator.clipboard) {
        return fallbackCopyToClipboard(text);
    }
    else {
        navigator.clipboard
        .writeText(text)
        .then(
            () => { return true }, // success callback
            (err) => { return fallbackCopyToClipboard(text) } // failed callback
        );
    }
};

function fallbackCopyToClipboard (text) {
    let isCopied = false;
    let textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
        isCopied = true;
    }
    catch (err) {
    }

    document.body.removeChild(textArea);
    return isCopied;
}


export const rightTrimSlashes = (str) => {
    return str.replace(/\/+$/g, '');
}

export const leftTrimSlashes = (str) => {
    return str.replace(/^\/+/g, '');
}

export const trimSlashes = (str) => {
    return rightTrimSlashes(leftTrimSlashes(str))
}

export const ellipsisString = (string, maxLength) => {
    if(string.length > maxLength) {
        return string.substring(0, maxLength) + '...';
    }
    return string;
}

export const brandNamesMapper = {
    stripe: 'Stripe',
    paddle: 'Paddle',
    paypal: 'PayPal',
    shopify: 'Shopify',
    woocommerce: 'WooCommerce',
}
