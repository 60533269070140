<template>
    <div>

        <div class="flex justify-between items-center">

            <button type="button"
                :disabled="disabled"
                @click="handleToggle"
                class="inline-flex relative flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                :class="
                [
                    [sizing[size].size],
                    localValue ? onClass : 'bg-gray-200',
                    disabled ? 'cursor-not-allowed opacity-50' : ''
                ]
                "
                role="switch" aria-checked="false" aria-labelledby="availability-label">
                <span class="sr-only">Use setting</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span aria-hidden="true"
                      class="inline-block bg-white rounded-full ring-0 transition duration-200 ease-in-out transform pointer-events-none"
                      :class="[
                        [sizing[size].span],
                        {
                            'translate-x-5': localValue,
                            'translate-x-0': !localValue,
                        }
                        ]"
                ></span>
            </button>

            <span
                v-if="title"
                class="flex flex-col flex-grow"
                :class="[
                    disabled ? 'cursor-not-allowed opacity-50' : ''
                ]"
            >
                <span
                    class="text-gray-900"
                    :class="
                        [sizing[size].text]
                    "
                >
                    {{ title }}
                </span>
                <span class="text-sm text-gray-500"

                >
                    <slot name="description" />
                </span>
            </span>

        </div>

        <input type="checkbox"
               :name="name"
               :checked="localValue"
               @input="$emit('update:modelValue', $event.target.value)"
               class="absolute invisible"
               :value="trueValue"
        />

        <span v-if="error && errorMessage" class="block text-red-400 tracking-wide mt-1 text-sm">{{ errorMessage }}</span>

        <slot name="when-on" v-if="localValue"/>
        <slot name="when-off" v-if="!localValue"/>

    </div>
</template>

<script>
export default {
    emits: ['update:modelValue'],
    props: {
        disabled: {
            default: false,
        },
        title: {
            type: String,
        },
        size: {
            default: 'default',
        },
        name: '',
        on_class: null,
        modelValue: {
            default: false
        },
        label: {
            default: false
        },
        error: {
            default: false,
        },
        errorMessage: '',
        trueValue: {
            default: 'on'
        },
    },
    data() {
        return {
            localValue: this.modelValue,
            onClass: this.on_class ?? 'bg-teal-500',
            sizing: {
                default: { input: 'py-2 text-base', error: 'text-xs', size: 'mr-5 w-11 h-6', span: 'w-5 h-5', text: 'text-sm font-medium' },
                medium: { input: 'py-2.5 text-base', error: 'text-xs', size: 'mr-3 w-9 h-4', span: 'w-3 h-3', text: 'text-sm font-normal' },
            },
        }
    },
    watch: {
        modelValue: {
            handler(newVal) {
                this.localValue = newVal;
            }
        }
    },
    methods: {
        handleToggle() {
            this.localValue = !this.localValue;
            this.$emit('update:modelValue', this.localValue);
        }
    }
}
</script>

<style scoped>

</style>
