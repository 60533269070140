<template>
    <div class="flex items-center justify-start gap-4">

        <modal title="Disable two-factor authentication" size="small" name="enable_2fa_modal">
            <template #toggle>
                <btn type="primary">Disable</btn>
            </template>
            <template #body>
                <div class="space-y-6">
                    <div class="row align-items-start">

                        <div class="space-y-4">

                            <div class="text-sm">To disable Two-Factor Authentication, please enter your password.</div>

                            <div class="form-group" :class="errorsForInput('current_password') ? 'has-error' : ''" v-if="!is_social_login">
                                <text-field
                                    type="password"
                                    show_reveal_password_icon="true"
                                    label="Current password"
                                    required="true"
                                    name="current_password"
                                    field-id="current_password"
                                    v-model="current_password"
                                    v-on:keydown.enter.prevent="disable2FA"
                                    :error="errorsForInput('current_password')"
                                    :error-message="errorsForInput('current_password')"
                                ></text-field>
                            </div>

                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <btn type="primary" :loading="loading.disabling" @click="disable2FA">Disable</btn>
            </template>
        </modal>

        <a class="text-sm underline hover:no-underline cursor-pointer" href="#" @click.prevent="downloadRecoveryCodes" v-if="allow_download_recovery_codes">
            Download recovery codes
        </a>

    </div>
</template>
<script>
import TextField from "../../../elements/forms/TextField.vue";

export default {
    components: {TextField},
    props: [
        'isSocialLogin',
        'allowDownloadRecoveryCodes',
        'twoFactorAuthDisableRoute',
        'twoFactorAuthRecoveryCodesRoute',
        'appName',
    ],
    data() {
        return {
            errors: '',
            current_password: '',
            is_social_login: !!this.isSocialLogin,
            allow_download_recovery_codes: !!this.allowDownloadRecoveryCodes,
            loading: {
                disabling: false,
            }
        }
    },
    methods: {
        disable2FA() {
            axios.post(this.twoFactorAuthDisableRoute, {
                current_password: this.current_password
            })
                .then(response => {
                    location.reload();
                })
                .catch(error => {
                    this.errors = error.response.data;
                })
        },
        fileDownload(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.appName + '_recovery_codes.txt');
            document.body.appendChild(link);
            link.click();
        },
        downloadRecoveryCodes() {
            axios.get(this.twoFactorAuthRecoveryCodesRoute)
                .then(response => {
                    this.fileDownload(response);
                })
                .catch(error => {
                    this.errors = error.response.data;
                })
        },
        cancel() {
            this.errors = '';
            this.current_password = '';
        },
        errorsForInput(input) {
            if (this.errors.errors && input in this.errors.errors) {
                return this.errors.errors[input][0];
            }
        }
    }
}
</script>
