<template>
    <div class="flex justify-between items-center">
        <toggle name="logo_update_confirmation" :model-value="is_set" @update:modelValue="updateSetting"></toggle>
        <span class="flex flex-col flex-grow" id="availability-label">
                                    <span
                                        class="text-sm font-medium text-gray-900">{{title}}</span>
                                    <span class="text-sm text-gray-500">{{description}}</span>
                                </span>
    </div>
</template>
<script>
export default {
    props: ['is_set', 'update_url', 'title', 'description'],
    methods: {
        updateSetting(value) {
            axios
                .post(this.update_url, {enabled: value})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {

                    }
                })
                .catch(error => {
                });
        }
    }
}
</script>

<style scoped>

</style>
