<template>
    <file-upload
        :src="data?.url ?? data?.external_url ?? null"
        :path="data?.path ?? null"
        :no_src="no_src"
        :accept="accept"
        :title="title"
        :name="name"
        :upload_url="options.file_upload_url"
        :delete_url="options.file_delete_url"
        :additional_data="{resource_id: unique_id}"
        @upload:done="uploadDone"
        @delete:done="deleteDone"
        ref="fileUploader"
    >
        <template v-if="$slots.empty_placeholder" v-slot:empty_placeholder>
            <slot name="empty_placeholder"></slot>
        </template>
        <template v-if="$slots.uploaded_placeholder" v-slot:uploaded_placeholder>
            <slot name="uploaded_placeholder"></slot>
        </template>
        <template v-if="allow_external_url" v-slot:custom_actions>
            <modal title="External Link" size="small" name="partnero_resource_ext_url_modal" @modalClose="onCancel">
                <template #toggle>
                    <icon name="link" class="text-gray-500 hover:text-gray-900 cursor-pointer"></icon>
                </template>
                <template #body>
                    <div>
                        <text-field
                            v-model="external_url"
                            label="URL"
                            type="text"
                        >
                        </text-field>

                    </div>
                </template>
                <template #footer>
                    <btn @click="onExternalUrlSave">Save</btn>
                </template>

            </modal>
        </template>
    </file-upload>
</template>

<script>
export default {
    props: ['unique_id', 'data', 'options', 'name', 'title', 'no_src', 'accept', 'allow_external_url'],
    data() {
        return {
            external_url: this.data.external_url
        }
    },
    methods: {
        uploadDone(data) {
            this.data.url = data[this.name].url;
            this.data.path = data[this.name].path;

            this.removeExternalUrl()
        },
        deleteDone(done) {
            if (done) {
                this.data.url = null;
                this.data.path = null;
            }
            this.removeExternalUrl()
        },
        onExternalUrlSave() {
            this.data.external_url = this.external_url
            this.emitter.emit('modal-off', 'partnero_resource_ext_url_modal')
            setTimeout(() => {
                this.$refs.fileUploader.refreshTheSrc();
            }, 100)
        },
        removeExternalUrl() {
            this.external_url = null;
            this.data.external_url = this.external_url
        },
        onCancel(name) {
            if (name !== null && name === 'partnero_resource_ext_url_modal') {
                this.external_url = this.data.external_url;
            }
        }
    }
}
</script>

<style lang="scss"></style>
