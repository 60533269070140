<template>

    <div class="space-y-6">
        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Install WooCommerce plugin to your store</h3>
                <div class="text-sm space-y-2">
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Download the <a href="https://wordpress.org/plugins/partnero/" target="_blank" class="underline hover:no-underline">Partnero Affiliate Management</a> plugin</li>
                        <li>Upload plugin files to the <span class="font-italic">/wp-content/plugins/partnero</span> directory</li>
                        <li>Alternatively, you can find Partnero plugin via <b>Plugins -> Add new</b>. Search for <b>Partnero</b></li>
                        <li>Activate the plugin through the <b>Plugins</b> menu in WordPress</li>
                        <li>Click on Partnero on the right-hand side menu and enter your program API key. Click <b>Connect</b></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>

</template>
<script setup>
</script>

