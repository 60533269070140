<template>
    <table class="min-w-full divide-y divide-gray-200">
        <thead>
        <tr class="h-1">
            <th :colspan="TABLE_COLUMNS_COUNT">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <th class="pl-4 w-6">
                <options
                    :isRight="false"
                    name="recent_activities_table_options"
                >
                    <template #toggle>
                        <div class="bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                            <icon name="arrow-down-small"></icon>
                        </div>
                    </template>
                    <template #items>
                        <options-item
                            title="Select all"
                            @click="setExportSelection('selectAll')"
                        />
                        <options-item
                            title="Select visible"
                            @click="setExportSelection('selectVisible')"
                        />
                    </template>
                </options>
            </th>
            <table-header-columns-and-sorting
                :tableHeaders="tableHeaders"
            />
        </tr>
        <tr class="h-1"/>
        </thead>
        <tbody class="divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!recentActivities || recentActivities?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && recentActivities?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr v-for="(recentActivity, index) in recentActivities"
                :key="recentActivity.partner_id + '-' + recentActivity.customer_id + '-' + index"
                class="hover:bg-gray-50 cursor-pointer group"
            >
                <td class="pl-4 w-6">
                    <checkbox
                        v-model="exportOptions.selectedIds"
                        :value="recentActivity.id"
                        @click="setExportSelection('selectCustom')"
                    />
                </td>
                <td
                    class="px-4 py-4 whitespace-nowrap font-semibold text-sm text-gray-900"
                    @click="viewPartnerOrReferral(recentActivity.referral_url)"
                >
                    <template v-if="recentActivity.email || recentActivity.customer_full_name">
                        <div v-if="recentActivity.customer_full_name">
                            {{ recentActivity.customer_full_name }}
                        </div>
                        <div class="text-sm font-normal text-gray-500" v-if="recentActivity.email">
                            {{ recentActivity.email }}
                        </div>
                    </template>
                    <template v-else>
                        -
                    </template>
                </td>

                <td
                    class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    @click="viewPartnerOrReferral(recentActivity.referral_url)"
                >
                    <referral-status
                        :activity="recentActivity"
                        :status="(recentActivity.action === 'signup' && recentActivity.deleted_at) ? 'deleted' : recentActivity.status" :archived="recentActivity.is_archived ?? false"
                    />
                </td>
                <td
                    class="px-4 py-4 whitespace-nowrap text-sm text-gray-500"
                    @click="viewPartnerOrReferral(recentActivity.referral_url)"
                >
                    <time datetime="2021-04-12 07:08:55">
                        {{ recentActivity.parsed_date }}
                    </time>
                </td>
                <td class="pl-4 pr-4 py-4 whitespace-nowrap text-right">
                    <div
                        class="rounded-full flex items-center justify-center font-medium text-gray-500 bg-gray-200 w-10 h-10 text-sm inset-x-0 mx-auto mr-0"
                        @click="viewPartnerOrReferral(recentActivity.partner_url)"
                    >
                        {{ recentActivity['partner_initials'] }}
                    </div>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>
import ReferralStatus from "../../../../common/ReferralStatus";
import InitialLoadingSkeletonRows from "../../../../common/InitialLoadingSkeletonRows";
import NoSearchDataRow from "../../../../common/NoSearchDataRow";
import ProgressBar from "../../../../../elements/ProgressBar";
import filtersAndPagination from "../../../../../composables/common/filter-and-pagination-data";
import useTableDataExporter from "../../../../../composables/common/select-export-and-delete-data";
import TableHeaderColumnsAndSorting from "../../../filters-and-pagination/TableHeaderColumnsAndSorting.vue";

export default {
    name: "CustomerDataTable",
    emits: ['hide-options'],
    components: {
        TableHeaderColumnsAndSorting,
        InitialLoadingSkeletonRows,
        NoSearchDataRow,
        ReferralStatus,
        ProgressBar
    },
    props: {
        recentActivities: {
            default: []
        },
        tableHeaders: {
            default: []
        }
    },
    setup() {
        const TABLE_COLUMNS_COUNT = 5;

        const {loadingFiltersAndPaginationData} = filtersAndPagination()
        const {exportOptions} = useTableDataExporter()

        return {loadingFiltersAndPaginationData, TABLE_COLUMNS_COUNT, exportOptions}
    },
    methods: {
        viewPartnerOrReferral(url) {
            if (url) {
                window.location = url
            }
        },
        setExportSelection(type) {
            this.exportOptions.selectType = type
            if (type === 'selectVisible' || type === 'selectAll') {
                this.exportOptions.selectedIds = []
                this.recentActivities.forEach(activity => {
                    this.exportOptions.selectedIds.push(activity.id)
                })
            }
            this.emitter.emit('hide-options', 'recent_activities_table_options');
        }
    },
    watch: {
        'exportOptions.selectedIds': {
            deep: true,
            handler(newValue) {
                if (this.exportOptions.selectType !== 'selectAll') {
                    this.exportOptions.selectTypeCustomModels = this.recentActivities
                        .filter(activity => newValue.includes(activity.id))
                        .map(activity => ({
                                id: activity.id,
                                identifier: activity.identifier,
                                action: activity.action
                            })
                        );
                }
            }
        }
    }
}
</script>

