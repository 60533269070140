<template>
    <span class="relative align-middle" @click="open('click')" @mouseover="open('hover')" @mouseleave="mouseleave">
        <slot v-if="this.$slots.default"></slot>
        <svg v-else class="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm2-1.645A3.502 3.502 0 0 0 12 6.5a3.501 3.501 0 0 0-3.433 2.813l1.962.393A1.5 1.5 0 1 1 12 11.5a1 1 0 0 0-1 1V14h2v-.645z"/></svg>
        <div v-if="content" :class="this.$slots.default ? '' : '-ml-4', show === true ? 'flex' : 'hidden'" class="-left-14 -right-14 absolute bottom-0 flex-col  items-center mb-6">
            <span v-if="showContentWithNewLines"
                  class="-mr-2 bg-black leading-none p-2 relative rounded shadow-lg text-white text-xs whitespace-no-wrap z-10"
                  v-html="content"
            />
            <span v-else class="-mr-2 bg-black leading-none p-2 relative rounded shadow-lg text-white text-xs whitespace-no-wrap z-10">{{ content }}</span>
            <div class="-mt-2 bg-black h-3 rotate-45 transform w-3"></div>
        </div>
    </span>
</template>
<script>
    export default {
        props: {
            content: String,
            method: {
                default: 'hover'
            },
            showContentWithNewLines: {
                default: false
            }
        },
        data() {
            return {
                show: false
            }
        },
        methods: {
            open(method) {
                if (this.method === method) {
                    this.show = true;
                }
            },
            mouseleave() {
                setTimeout(() => { this.show = false }, 300)
            }
        }
    }
</script>
