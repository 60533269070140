<template>
    <div class="bg-white rounded rounded-t-none border relative">
        <initial-loading-skeleton-email-settings v-if="emailSettingsStore.getLoadingSettingsStatus"/>
        <form v-else>
            <div class="px-10 divide-y divide-gray-200">
                <div class="grid py-14 xl:grid-cols-3 gap-12">
                    <div class="space-y-2">
                        <h3 class="text-lg font-medium">
                            Default sender
                        </h3>
                        <p class="text-gray-500 text-sm">This will be displayed in the 'From' field of your recipient's
                            email client.</p>
                    </div>
                    <div class="grid col-span-2 gap-y-8 gap-8">

                        <partnero-email-delivery :program="props.program" :details="partneroEmailDeliveryDetails"></partnero-email-delivery>

                        <div>
                            <div class="grid gap-6">
                                <div>
                                    <text-field label="Sender name"
                                                :placeholder="program.name"
                                                name="emails_sender_name"
                                                v-model="emailSettingsStore.settings.emails_sender_name"
                                    />
                                </div>
                                <div>
                                    <emails-sender-email :get-domains-url="getDomainsUrl"/>
                                </div>
                                <div>
                                    <text-field
                                        label="Reply-to email (optional)"
                                        type="email"
                                        placeholder=""
                                        name="emails_reply_to_email"
                                        v-model="emailSettingsStore.settings.emails_reply_to_email"
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <emails-gateway-connect
                                :smtp_settings='emailSettingsStore.smtpSettings'
                                :mailersend_api_key="emailSettingsStore.getSettings.emails_mailersend_api_key"
                                :gateway_update_url="gatewayUpdateUrl"
                                :gateway_disconnect_url="gatewayDisconnectUrl"
                                :is_gmail_connected="props.is_gmail_connected"
                                :gmail_redirect="gmailRedirect"
                            ></emails-gateway-connect>
                        </div>

                        <div>
                            <send-test-email
                                title="Send test email"
                                description=""
                                email_address=""
                                :send_test_email_url="sendTestEmailUrl"
                            ></send-test-email>
                        </div>
                    </div>
                </div>

                <logo-and-disclaimer-settings :program="program"/>

                <email-default-colors/>

            </div>
            <div class="bg-gray-50 px-10 py-4 text-right rounded-b sticky bottom-0 z-40">
                <btn
                    type="primary"
                    :disabled="emailSettingsStore.getUpdatingSettingsStatus"
                    :loading="emailSettingsStore.getUpdatingSettingsStatus"
                    @click="emailSettingsStore.updateSettings()"
                >
                    Update
                </btn>
            </div>
        </form>
        <notification
            :show="emailSettingsStore.getNotification.showNotification"
            :title="emailSettingsStore.getNotification.notificationTitle"
            :type="emailSettingsStore.getNotification.notificationType"
            class="mb-20"
            @notificationClosed="emailSettingsStore.getNotification.showNotification = false"
        >
            <template #message>
                {{ emailSettingsStore.getNotification.notificationMessage }}
            </template>
        </notification>
    </div>
</template>

<script setup>

import {useEmailSettingsStore} from "../../../../stores/app/emails/settings/EmailSettingsStore";
import {onMounted, onBeforeMount} from "vue";
import LogoAndDisclaimerSettings from "./settings-components/LogoAndDisclaimerSettings.vue";
import InitialLoadingSkeletonEmailSettings from "./settings-components/InitialLoadingSkeletonEmailSettings.vue";
import EmailDefaultColors from "./settings-components/EmailDefaultColors.vue";
import PartneroEmailDelivery from "../PartneroEmailDelivery";

let emailSettingsStore = useEmailSettingsStore()

const props = defineProps({
    program: {
        type: [Number, Array, Object]
    },
    partneroEmailDelivery: {
        type: [Array, Object]
    },
    is_gmail_connected: {
        type: Boolean
    }
});

const partneroEmailDeliveryDetails = props.partneroEmailDelivery;
const getDomainsUrl = route('app_int.program.emails.settings.get_sender_domain', props.program)
const gatewayUpdateUrl = route('app_int.program.emails.settings.update_gateway', props.program)
const gatewayDisconnectUrl = route('app_int.program.emails.settings.disconnect_gateway', props.program)
const sendTestEmailUrl = route('app_int.program.emails.send_simple_test', props.program)
const gmailRedirect = route('programs.gmail.connect', props.program)

onBeforeMount(() => {
    emailSettingsStore.loadingSettings = true;
})

onMounted(() => {
    emailSettingsStore.program = props.program;
    emailSettingsStore.init();
})

</script>

