<script setup>
    import Btn from "../../../../../elements/Btn.vue";

    const props = defineProps({
        program: {
            type: Object,
            required: true,
        },
        couponId: {
            type: Number,
            required: true,
        },
        promoCodesCount: {
            type: Number,
            default: 0,
        },
    });

    const promoCodeLandingPageRoute = () => {
        return route('programs.coupons.promotion-codes.list', {
            program: props.program.id,
            coupon: props.couponId,
        });
    };
</script>

<template>
    <div>
        <div class="border rounded p-4 space-y-1">
            <div class="text-sm font-semibold">Total promotion code count: {{ promoCodesCount }}</div>
            <small class="block text-gray-500">
                To view the list of the promotion codes, please click on the button below.
                You will be redirected to the promotion code landing page where you can manage the coupon promotion codes.
            </small>
        </div>
        <div class="text-right mt-6">
            <btn :href="promoCodeLandingPageRoute()" type="secondary" size="small">
                View promotion codes
            </btn>
        </div>
    </div>
</template>
