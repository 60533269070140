<template>
    <div>
        <!-- Accordion Header -->
        <div class="flex justify-between items-center">
            <label class="block text-sm leading-5 mb-1.5">{{ label }}</label>
            <div @click="toggleAccordion" class="cursor-pointer flex justify-end items-center group">
                <span class="text-xs mr-1.5 text-gray-500 group-hover:text-gray-900" v-if="!isAccordionOpen">Edit</span>
                <icon class="text-gray-500 group-hover:text-gray-900" :name="isAccordionOpen ? 'arrow-up-s' : 'settings'"></icon>
            </div>
        </div>

        <!-- Accordion Content -->
        <div v-show="!isAccordionOpen"
             :class="{
                'border rounded p-4 text-sm text-gray-500 leading-5': placeholder,
                'border-b': !placeholder
            }"
             v-html="placeholder"
        >
        </div>
        <div v-show="isAccordionOpen" class="border rounded p-4" :class="error ? 'border-red-400' : ''">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';

// Props for label
const props = defineProps({
    label: {
        type: String,
        required: true
    },
    placeholder: {
        type: String,
        required: false
    },
    opened: {
        type: Boolean,
        default: false
    },
    error: {
        default: false
    }
});

// Reactive state for accordion
const isAccordionOpen = ref(props.opened);

// Function to toggle the accordion
const toggleAccordion = () => {
    isAccordionOpen.value = !isAccordionOpen.value;
};
</script>
