<template>
    <filters-and-pagination
        :pagination-data="filtersAndPaginationData"
        :filterable-fields="filterFields"
        :data-exists="initialDataState"
        :loading-data="loadingFiltersAndPaginationData"
        model-name="referral"
    >
        <refer-a-friend-customers-data-table :partners="filtersAndPaginationData.data" :table-headers="tableHeaders"/>
    </filters-and-pagination>
</template>

<script>
import FiltersAndPagination from "../../../../filters-and-pagination/FiltersAndPagination";
import {onMounted, ref, toRef, watch} from "vue";
import useRecentActivities from "../../../../../../composables/common/filter-and-pagination-data";
import useFiltersAndPagination from "../../../../../../state/common/filters-and-pagination";
import ReferAFriendCustomersDataTable from "./DataTable.vue";
import useTableDataExporter from "../../../../../../composables/common/select-export-and-delete-data";

export default {
    name: "CustomersIndex",

    components: {
        ReferAFriendCustomersDataTable,
        FiltersAndPagination,
    },

    props: {
        customersUrl: {
            default: ''
        },
        customersExportUrl: {
            default: ''
        },
        customersDeleteUrl: {
            default: ''
        },
        filterFields: {
            default: {}
        },
        tableHeaders: {
            default: []
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        }
    },

    setup(props) {
        const customersUrl = toRef(props, 'customersUrl')
        const customersExportUrl = toRef(props, 'customersExportUrl')
        const customersDeleteUrl = toRef(props, 'customersDeleteUrl')
        const filterFields = toRef(props, 'filterFields')
        const {
            loadingFiltersAndPaginationData,
            filtersAndPaginationData,
            getFiltersAndPaginationData
        } = useRecentActivities()
        const {setDataBaseUrl, setFetchDataFunction} = useFiltersAndPagination()
        const {exportSetup, deleteSetup, checkForPreviousExport} = useTableDataExporter()

        const initialDataState = ref(!!props.dataExists)

        onMounted(() => {
            setDataBaseUrl(customersUrl.value)
            setFetchDataFunction(getFiltersAndPaginationData)

            exportSetup.value.exportModelNameSingular = 'customer'
            exportSetup.value.exportModelNamePlural = 'customers'
            exportSetup.value.exportUrl = customersExportUrl.value
            deleteSetup.value.deleteUrl = customersDeleteUrl.value
            checkForPreviousExport()
        })

        watch(filtersAndPaginationData, (newValue) => {
            // the first time the data is loaded, we want to set the initialDataState to true so we can show the table
            // use filters properly if returned data is empty, etc.
            if (newValue?.data?.length) {
                initialDataState.value = true
            }
        })

        return {
            filtersAndPaginationData,
            loadingFiltersAndPaginationData,
            filterFields,
            initialDataState
        }
    }
}
</script>

