<script setup>
    import {onMounted, ref} from "vue";
    import ListSkeleton from "../../../../common/skeletons/ListSkeleton.vue";
    import SimplePagination from "../SimplePagination.vue";
    import ProgressBar from "../../../../../elements/ProgressBar.vue";
    import NoDataTable from "../../../filters-and-pagination/NoDataTable.vue";

    const props = defineProps({
        program: {
            type: Object,
            required: true
        },
        partner: {
            type: Object,
            required: true
        },
    });

    const partnerPromotionCodes = ref({
        data: []
    });

    const baseUrl = route('app_int.program.partner.promotion-code.list', { program: props.program, partner: props.partner });

    const loadingPartnerPromotionCodes = ref(false);

    const getPartnerPromotionCodes = (url) => {
        loadingPartnerPromotionCodes.value = true;
        axios.get(url)
            .then(response => {
                partnerPromotionCodes.value = response.data;
            })
            .finally(() => {
                loadingPartnerPromotionCodes.value = false;
            });
    };

    onMounted(() => {
        getPartnerPromotionCodes(baseUrl);
    });

</script>

<template>
    <div v-if="partnerPromotionCodes.data.length > 0">
        <header-bar title="Promotion codes" size="xsmall"></header-bar>

        <div class="space-y-4 mb-10">
            <div class="border rounded divide-y">
                <table class="min-w-full">
                    <thead class="border-b">
                    <tr class="h-1">
                        <th :colspan="6">
                            <progress-bar v-if="loadingPartnerPromotionCodes"/>
                        </th>
                    </tr>
                    <tr>
                        <th class="py-3 px-4 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">Promotion code</th>
                        <th class="py-3 px-4 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">Redemptions</th>
                        <th class="py-3 px-4 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">Expires</th>
                        <th class="py-3 px-4 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">Status</th>
                    </tr>
                    </thead>
                    <tbody class="divide-y">
                    <tr v-for="promotionCode in partnerPromotionCodes.data" :key="'classic-promo-' + promotionCode['code'] ?? ''">
                        <td class="p-6 text-sm">

                            <div class="flex gap-4 items-center">
                                <icon name="coupon" size="24"></icon>

                                <div>
                                    <div class="font-semibold">{{ promotionCode['code'] ?? '' }}</div>
                                    <div class="text-gray-500">
                                        {{ promotionCode['details'] ?? '' }}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="p-6 text-sm">{{ promotionCode['times_redeemed']  }} / {{ promotionCode['redemption_times_status'] === '0' ? '&#8734' : promotionCode['redemption_times_value'] }}</td>
                        <td class="p-6 text-sm">{{ promotionCode['expiration_date_status'] === '1' ? promotionCode['expiration_date_value'] : '—' }}</td>
                        <td class="p-6 text-sm">
                            <badge v-if="promotionCode['active']"
                                   type="success"
                                   size="small"
                                   title="Active"
                            ></badge>
                            <badge v-else
                                   type="warning"
                                   size="small"
                                   title="Paused"
                            ></badge>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <SimplePagination :pagination="partnerPromotionCodes" @fetchData="getPartnerPromotionCodes"></SimplePagination>
            </div>
        </div>
    </div>
</template>

