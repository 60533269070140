<template>
    <modal :title="'Reject reason'" size="small">
        <template v-if="$slots.toggle" v-slot:toggle>
            <slot name="toggle"></slot>
        </template>
        <template v-else v-slot:toggle>
            <btn type="primary">Reject</btn>
        </template>

        <template #body>
            <div class="space-y-4">
                <p class="text-sm">Please enter the rejection reason below:</p>
                <div>
                    <textarea
                        v-model="rejectionReason"
                        class="transition-shadow block w-full rounded focus:ring-0 py-2 px-3 text-sm border-gray-300 focus:border-gray-400"
                    ></textarea>
                    <small class="text-gray-500 mt-1 block">Note: this message will be shown to your partner.</small>
                </div>
            </div>
        </template>
        <template #footer>

            <btn
                button-type="submit"
                type="primary"
                @click.prevent="rejectApprovalRequest()"
            >
                Reject
            </btn>
        </template>
    </modal>
</template>

<script>
import {ref} from "vue";
import useApprovalRequests from "../../../../../composables/app/approval-requests";

export default {
    name: "RequestRejectionModal",
    emits: ['request-rejected'],
    props: {
        rejectUrl: {
            type: String,
            required: true
        }
    },
    setup() {
        const rejectionReason = ref('')
        const { approveRejectRequest } = useApprovalRequests()

        return { rejectionReason, approveRejectRequest }
    },
    methods: {
        rejectApprovalRequest() {
            this.approveRejectRequest(this.rejectUrl, { message: this.rejectionReason })
            this.$emit('request-rejected')
        }
    }

}
</script>
