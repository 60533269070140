<template>

    <div class="space-y-6">

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>

            <div class="space-y-2">
                <div class="text-sm space-y-2">
                    <p>Follow these steps to insert Partnero Universal into your site:</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Log in to your account</li>
                        <li>Go to the <b>Builder</b> -> <b>Website Settings</b> -> <b>Custome Code</b></li>
                        <li>Paste the script inside the <b>Custom Head Markup</b> section</li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sign-ups tracking</h3>
                <div class="text-sm space-y-2">
                    <p>On the same page, paste the following script into the same section just below Partnero Universal:</p>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-2').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-2" rows="2" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs" v-pre><script>
po('integration', 'simvoly', null);
</script></textarea>
            </div>
        </div>

    </div>

</template>
