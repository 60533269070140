<template>
    <modal title="Reject request" size="small">
        <template #toggle>
            <btn type="light">Reject</btn>
        </template>
        <template #body>
            <div class="space-y-4">
                <div class="text-sm text-gray-500 mb-6">
                    Do you really want to reject this request?
                </div>
                <div>
                    <label class="block text-sm text-gray-900 mb-1.5">Reject reason</label>
                    <textarea
                        v-model="rejectionReason"
                        name="reject_reason"
                        :maxlength="maxRejectReasonsLength"
                        class="transition-shadow block w-full rounded focus:ring-0 py-2 px-3 text-sm border-gray-300 focus:border-gray-400"
                    >
                        {{ defaultRejectReason }}
                    </textarea>
                </div>
            </div>
        </template>
        <template #footer>
            <btn type="primary"
                 @click.native="rejectPayoutRequest()"
                 :loading="updatingPayout"
                 :disabled="updatingPayout"
            >
                Reject
            </btn>
        </template>
    </modal>
</template>

<script>
import {ref} from "vue";
import usePayouts from "../../../../../composables/app/payouts";

export default {
    name: "RejectRequestModal",
    emits: ['payout-updated'],
    props: {
        maxRejectReasonsLength: {
            default: 160
        },
        defaultRejectReason: {
            default: ''
        },
        programId: {
            required: true
        },
        payoutId: {
            required: true
        }
    },
    setup() {
        const rejectionReason = ref('')
        const { updatePayout, updatingPayout } = usePayouts()

        return {
            rejectionReason,
            updatePayout,
            updatingPayout
        }
    },
    methods: {
        rejectPayoutRequest() {
            this.updatePayout(this.programId, { reject: 1, payout_request: this.payoutId, reject_reason: this.rejectionReason }).finally(() => {
                this.$emit('payout-updated')
            })
        }
    }
}
</script>
