<template>
    <div class="flex flex-column items-center">
        <modal
            :title="'Approval submission'"
            size="large"
            ref="mainApprovalRequestModal"
        >
            <template #toggle>
                <btn
                    type="secondary"
                    size="small"
                    class="-mr-px rounded-r-none leading-normal"
                >
                    Check submission
                </btn>
            </template>
            <template #body>
                <div class="space-y-6">
                    <template v-if="approveRequestData.display_fields?.length > 0">
                        <div v-for="displayField in approveRequestData.display_fields">
                            <h5 class="mb-1 text-gray-500 text-sm">{{ displayField['question'] }}</h5>
                            <div>{{ displayField['answer'] }}</div>
                        </div>
                    </template>
                    <p v-else>No custom fields provided</p>
                </div>
            </template>
            <template #footer>
                <request-rejection-modal
                    v-if="requestStatus==='submitted'"
                    :reject-url="approveRequestData.reject_url"
                    @request-rejected="closeParentModal()"
                />
                <request-approval-modal
                    :approve-url="approveRequestData.approve_url"
                />
            </template>
        </modal>

        <options class="inline-flex">
            <template #toggle>
                <btn
                    type="secondary"
                    size="small"
                    class="rounded-l-none leading-normal"
                >
                    <icon size="18" name="more"></icon>
                </btn>
            </template>
            <template #items>
                <request-approval-modal
                    :approve-url="approveRequestData.approve_url"
                >
                    <template v-slot:toggle>
                        <options-item title="Approve"></options-item>
                    </template>
                </request-approval-modal>
                <options-divider  v-if="requestStatus==='submitted'"></options-divider>
                <request-rejection-modal
                    v-if="requestStatus==='submitted'"
                    :reject-url="approveRequestData.reject_url"
                    @request-rejected="closeParentModal()"
                >
                    <template v-slot:toggle>
                        <options-item title="Reject"></options-item>
                    </template>
                </request-rejection-modal>
            </template>
        </options>

    </div>

</template>

<script>
import RequestRejectionModal from "./RequestRejectionModal";
import RequestApprovalModal from "./RequestApprovalModal";

export default {
    name: "PartnerRequestApprovalModal",
    components: {RequestRejectionModal, RequestApprovalModal},

    props: {
        approveRequestData: {
            type: Object,
            required: true
        },
        requestStatus: {
            type: String,
            required: true
        }
    },

    methods: {
        closeParentModal() {
            this.$refs.mainApprovalRequestModal.onCancel()
        }
    }
}
</script>
