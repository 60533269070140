import {defineStore} from "pinia";

export let useCouponSyncStore = defineStore('couponSync', {
    state: () => ({
        programId: null,
        syncProviders: [],
        syncInProgress: false,
        syncSettings: {
            syncTypeDisabled: false,
            syncType: 'all',
            selectedIds: [],
            limit: 10,
        },
        syncProviderCoupons: [],
        providerHasMoreCoupons: false,
        loadingProviderCoupons: false,
        nextPageParam: null,
        prevPageParam: null,
        notification: {
            showNotification: false,
            notificationTitle: 'Error',
            notificationType: 'warning',
            notificationMessage: 'Sync error, see console for error details.'
        },
        searchQueries: {}, // search queries for each provider
        paginationMessage: ''
    }),

    actions: {
        setProgramId(programId) {
            this.programId = programId
        },
        setSyncProviders(providers) {
            this.syncProviders = providers
        },
        setSearchQueryProvider(provider, query) {
            this.searchQueries[provider] = query
        },
        getSyncProviderCouponList(provider, pagination = '') {

            const paginationParams = {}

            if (pagination === 'next' && this.nextPageParam) {
                switch (provider) {
                    case 'stripe':
                        paginationParams.lastCouponId = this.nextPageParam
                        break
                    default:
                        break
                }
            }

            if (pagination === 'prev') {
                switch (provider) {
                    case 'stripe':
                        paginationParams.firstCouponId = this.prevPageParam
                        break
                    default:
                        break
                }
            }

            this.loadingProviderCoupons = true
            axios.get(route('app_int.coupon.provider.list' , { program: this.programId }),
                {
                    params: {
                        provider: provider,
                        syncType: (this.syncSettings.syncType === 'all')  ? 'all' : 'custom',
                        selectedCouponIds: this.syncSettings.selectedIds,
                        limit: this.syncSettings.limit,
                        pagination: paginationParams
                    }
                })
                .then((response) => {
                    this.syncProviderCoupons = response.data.data
                    if (provider === 'stripe') {
                        if (response.data.data.length > 0 && response.data.hasMore) {
                          this.nextPageParam = response.data.data[response.data.data.length - 1].id
                        }
                        if (pagination === 'next') {
                            if (response.data.data.length > 0) {
                                this.prevPageParam = this.nextPageParam
                            } else {
                                this.paginationMessage = 'No more coupons to load.'
                            }
                            this.nextPageParam = response.data.data?.[response.data.data.length - 1]?.id || null
                        }
                        if (pagination === 'prev') {
                            if (response.data.data.length > 0) {
                                this.nextPageParam = this.prevPageParam
                            } else {
                                this.paginationMessage = 'No more coupons to load.'
                            }
                            this.prevPageParam = response.data.data?.[0]?.id || null
                        }
                    }
                })
                .catch((error) => {
                    if (error.response && (error.response.status === 409 || error.response.status === 422) && error.response.data.message) {
                        this.notification.notificationMessage = error.response.data.message
                    }
                    this.notification.showNotification = true
                    console.log(error, error.response)
                })
                .finally(() => {
                    this.loadingProviderCoupons = false
                })
        },
        searchSyncProviderCoupon(provider) {
            this.loadingProviderCoupons = true
            this.syncProviderCoupons = []
            axios.get(route('app_int.coupon.provider.search' , { program: this.programId }),
                {
                    params: {
                        provider: provider,
                        search: this.searchQueries[provider],
                    }
                })
                .then((response) => {
                    this.syncProviderCoupons.push(response.data.data)
                    this.providerHasMoreCoupons = response.data.hasMore ?? false
                })
                .catch((error) => {
                    if (error.response && (error.response.status === 409 || error.response.status === 422) && error.response.data.message) {
                        this.notification.notificationMessage = error.response.data.message
                    }
                    this.notification.showNotification = true
                    console.log(error, error.response)
                })
                .finally(() => {
                    this.loadingProviderCoupons = false
                })
        },
        getProgramProviderCurrentSyncType(provider) {
            axios.get(route('app_int.coupon.provider.sync-type.get' , { program: this.programId }),
                {
                    params: {
                        provider: provider
                    }
                })
                .then((response) => {
                    this.syncSettings.syncType = response.data.syncType || 'all'
                })
                .catch((error) => {
                    this.notification.showNotification = true
                    console.log(error, error.response)
                })
        },
        showNotification(show, title, type, message) {
            this.notification.showNotification = show;
            this.notification.notificationTitle = title;
            this.notification.notificationType = type;
            this.notification.notificationMessage = message;
        },
        syncCoupons(syncProvider) {
            this.syncInProgress = true
            axios.get(route('app_int.coupon.sync' , { program: this.programId }),
                {
                    params: {
                        syncProvider: syncProvider,
                        syncType: this.syncSettings.syncType,
                        selectedCouponIds: this.syncSettings.selectedIds
                    }
                })
                .then((response) => {
                    const status = parseInt(response.data.status || '-1');
                    if (status === 1) {
                        this.syncInProgress = false

                        this.notification.showNotification = true
                        this.notification.notificationTitle = 'Success'
                        this.notification.notificationType = 'success'
                        this.notification.notificationMessage = response.data.message ?? 'Sync completed successfully.'
                        setTimeout(() => {
                                this.notification.showNotification = false
                                window.location.reload();
                            },
                            2500
                        )
                    }
                    else if (status === -1) {
                        this.notification.showNotification = true
                        this.notification.notificationTitle = 'Error'
                        this.notification.notificationType = 'warning'
                        this.notification.notificationMessage = response.data.error ?? 'Sync error, contact support.'
                    }
                })
                .catch((error) => {
                    if (error.response && (error.response.status === 409 || error.response.status === 422) && error.response.data.message) {
                        this.notification.notificationMessage = error.response.data.message
                    }
                    this.notification.showNotification = true
                    console.log(error, error.response)
                })
                .finally(() => {
                    this.syncInProgress = false
                })
        },
        capitalizeProviderName(provider) {
            if (provider === 'woocommerce') {
                return 'WooCommerce'
            }
            return provider.charAt(0).toUpperCase() + provider.slice(1)
        }
    },

    getters: {
        getProgramId: (state) => state.programId,
        getSyncProviders: (state) => state.syncProviders,
        getSyncInProgress: (state) => state.syncInProgress,
        getSyncSettings: (state) => state.syncSettings,
        getNextPageParam: (state) => state.nextPageParam,
        getPrevPageParam: (state) => state.prevPageParam,
    }
})
