<script setup>
import TableSkeleton from '../../../common/skeletons/TableSkeleton'
import StatsSkeletonItem from '../../../common/skeletons/StatsSkeletonItem'
import { computed } from 'vue';

const props = defineProps({
    title: {
        type: String,
        default: 'Affiliate overview'
    },
    dataLoading: {
        type: Boolean,
        default: false
    },
    programs: {
        type: Array,
        required: true,
        default: () => []
    },
    approvals: {
        type: Array,
        required: true,
        default: () => []
    },
    payouts: {
        type: [Array, Object],
        required: true,
        default: () => []
    },
});

const programsCount = computed(() => {
    return props.programs.length;
});

const singleProgram = computed(() => {
    if (programsCount.value === 1) {
        return props.programs[0];
    }
    return null;
});
</script>

<template>
    <div class="space-y-4">
        <header-bar :title="title" size="xsmall">
            <template v-if="singleProgram" #actions>
                <btn :href="singleProgram.link" type="secondary" size="small" icon-name="arrow-right-s" icon-right="true">
                    Full overview
                </btn>
            </template>
        </header-bar>

        <table-skeleton v-if="dataLoading" :number-of-rows="1"></table-skeleton>
        <div v-else-if="programs.length > 0" class="bg-white border rounded" :class="{'divide-y': !singleProgram}">
            <div v-for="program in programs" :key="program.id" class="grid grid-cols-1 lg:grid-cols-3">
                <div class="col-span-1 p-6 border-b lg:border-b-0" :class="{'flex items-center': !singleProgram}">
                    <div class="flex items-center gap-4">
                        <div
                            class="flex flex-shrink-0 justify-center items-center font-semibold tracking-tight rounded-md text-teal-600 bg-teal-300 bg-opacity-30"
                            :class="{'w-20 h-20 text-xl': singleProgram, 'w-10 h-10 text-sm': !singleProgram}"
                        >
                            {{ program.initial }}
                        </div>
                        <div class="flex gap-0.5 flex-col">
                            <template v-if="singleProgram">
                                <div class="text-xs font-medium uppercase">Affiliate</div>
                                <div class="text-md font-semibold">
                                    <a :href="program.link" class="hover:underline">
                                        {{ program.name }}
                                    </a>
                                </div>
                            </template>
                            <div v-else class="text-md font-semibold">
                                <a :href="program.link" class="hover:underline">
                                    {{ program.name }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 flex items-center p-6">
                    <ul
                        :class="[
                                'flex-1 grid gap-y-4 gap-x-4 text-sm',
                                singleProgram ? 'grid-cols-3' : 'grid-cols-4'
                            ]"
                    >
                        <li class="flex flex-col gap-1.5">
                            <div class="text-sm text-gray-500">Partners</div>
                            <div :class="['font-semibold', singleProgram ? 'text-lg' : 'text-sm']">{{ program.total_partners }}</div>
                        </li>
                        <li class="flex flex-col gap-1.5">
                            <div class="text-sm text-gray-500">Signups</div>
                            <div :class="['font-semibold', singleProgram ? 'text-lg' : 'text-sm']">{{ program.total_signups }}</div>
                        </li>
                        <li class="flex flex-col gap-1.5">
                            <div class="text-sm text-gray-500">Paid customers</div>
                            <div :class="['font-semibold', singleProgram ? 'text-lg' : 'text-sm']">{{ program.total_paid_accounts }}</div>
                        </li>
                        <li v-if="!singleProgram" class="flex flex-col items-end justify-center gap-1.5">
                            <a :href="program.link">
                                <icon size="18" name="arrow-right-s"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <card v-else class="overflow-hidden" #body>
            <div class="text-sm text-center">
                There are no Affiliate Programs.
            </div>
        </card>

        <div class="space-y-4">
            <div class="grid lg:grid-cols-2 gap-8">
                <div>
                    <header-bar title="Partner approval requests" size="xsmall"></header-bar>

                    <stats-skeleton-item v-if="dataLoading"></stats-skeleton-item>
                    <card v-else class="overflow-hidden" #body>
                        <div v-if="approvals.length <= 0" class="flex items-center justify-center -mx-10 -my-10 h-20 max-h-20">
                            <div class="text-sm text-center">There are no pending approval requests.</div>
                        </div>
                        <ul v-else class="-mx-10 -my-10 divide-y divide-gray-200">
                            <li v-for="approval_program in approvals">
                                <a :href="approval_program.link"
                                   class="flex items-center justify-between p-6 hover:bg-gray-50">
                                    <div class="flex items-center space-x-2.5">
                                        <div
                                            class="flex justify-center items-center w-8 h-8 font-semibold text-xs tracking-tight rounded-md text-teal-600 bg-teal-300 bg-opacity-30">
                                            {{ approval_program.initial }}
                                        </div>
                                        <div class="font-medium text-gray-900">{{ approval_program.name }}</div>
                                    </div>
                                    <div class="text-gray-900 flex items-center space-x-1.5">
                                        <span>{{ approval_program.pending_approval }}</span>
                                        <icon name="chevron-right" size="12"></icon>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </card>
                </div>
                <div>
                    <header-bar title="Payout requests" size="xsmall"></header-bar>

                    <stats-skeleton-item v-if="dataLoading"></stats-skeleton-item>
                    <card v-else class="overflow-hidden" #body>
                        <div v-if="!payouts.manual.length && !payouts.automated.length" class="flex items-center justify-center -mx-10 -my-10 h-20 max-h-20">
                            <div class="text-sm text-center">There are no pending payout requests.</div>
                        </div>
                        <ul v-else class="-mx-10 -my-10 divide-y divide-gray-200">
                            <li v-for="manunal_payout_program in payouts.manual">
                                <a :href="manunal_payout_program.link"
                                   class="flex items-center justify-between p-6 hover:bg-gray-50 max-h-20">
                                    <div class="flex items-center space-x-2.5">
                                        <div
                                            class="flex justify-center items-center w-8 h-8 font-semibold text-xs tracking-tight rounded-md text-teal-600 bg-teal-300 bg-opacity-30">
                                            {{ manunal_payout_program.initial }}
                                        </div>
                                        <div class="font-medium text-gray-900">{{ manunal_payout_program.name }}</div>
                                    </div>

                                    <div class="text-gray-900 flex items-center space-x-1.5">
                                        <div class="flex flex-col items-end">
                                            <span>{{ manunal_payout_program.pending_payouts }}</span>
                                        </div>
                                        <icon name="chevron-right" size="12"></icon>
                                    </div>
                                </a>
                            </li>
                            <li v-for="automated_payout_program in payouts.automated">
                                <a :href="automated_payout_program.link"
                                   class="flex items-center justify-between p-6 hover:bg-gray-50 max-h-20">
                                    <div class="flex items-center space-x-2.5">
                                        <div
                                            class="flex justify-center items-center w-8 h-8 font-semibold text-xs tracking-tight rounded-md border border-gray-900 border-opacity-5 bg-tahiti-100">
                                            {{ automated_payout_program.initial }}
                                        </div>
                                        <div class="font-medium text-gray-900">{{ automated_payout_program.name }}</div>
                                    </div>
                                    <div class="text-gray-900 flex items-center space-x-1.5">
                                        <div class="flex flex-col items-end">
                                            <span>{{ automated_payout_program.pending_amount }}</span>
                                            <span class="text-xs opacity-50">{{ automated_payout_program.date }}</span>
                                        </div>
                                        <icon name="chevron-right" size="12"></icon>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
