<template>
    <div class="space-y-8">
        <template v-for="creditCard in creditCards">

            <div v-if="!creditCard">
                no card
            </div>
            <div v-if="creditCard.type === 'card'"
                 class="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                <h4 class="sr-only">{{ creditCard.card.brand }}</h4>
                <div class="sm:flex sm:items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zm-6 6h4v2h-4v-2z"/>
                    </svg>
                    <div class="mt-3 sm:mt-0 sm:ml-4">
                        <div class="text-sm font-medium text-gray-900">Ending with {{ creditCard.card.last4 }} <span v-if="creditCard.customers_default" class="text-xs rounded bg-teal-100 text-teal-800 px-1 py-0.5">Primary</span>
                        </div>
                        <div class="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                            <div>Expires {{ creditCard.card.exp_month }}/{{ creditCard.card.exp_year }}</div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                    <options>
                        <template #toggle>
                            <btn type="secondary" icon-name="more" size="small" class="h-full"></btn>
                        </template>
                        <template v-slot:items="{ setShow }">
                            <options-item :disabled="creditCard.customers_default"
                                          title="Set as default"
                                          @click="setCardAsDefault(creditCard.id);setShow(false)"></options-item>
                            <modal title="Remove card" size="small" name="remove_card_modal">
                                <template #toggle>
                                    <options-item title="Remove card"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to remove this card?
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading.removingCard"
                                         @click="removeCard(creditCard.id)">
                                        Yes, remove card
                                    </btn>
                                </template>
                            </modal>
                        </template>
                    </options>
                </div>
            </div>

        </template>
        <div v-if="loading.addedCard" class="inline-flex gap-1">
            <icon name="spinner"/>
            <span class="text-sm">Loading</span>
        </div>
        <modal
            v-if="stripeLoaded"
            name="stripe-add-payment-card"
            title="New payment method"
            size="small"
        >
            <template #toggle>
                <btn
                    type="secondary"
                    icon-name="add-circle"
                    @click="prepCard()"
                >
                    New payment method
                </btn>
            </template>
            <template #body>
                <alert type="error" class="mb-5" v-if="cardAddError">{{ cardAddError }}</alert>
                <div id="card-element"/>
                <div v-if="loading.prepCard" class="inline-flex gap-1">
                    <icon name="spinner"/>
                    <span class="text-sm">Loading</span>
                </div>
            </template>
            <template #footer>
                <btn @click="addCard" :loading="loading.addingCard" :disabled="!cardLoaded || loading.addingCard">
                    Confirm
                </btn>
            </template>
        </modal>
    </div>
</template>
<script>
import {loadStripe} from '@stripe/stripe-js';

export default {
    name: "StripePaymentMethods",
    props: {
        stripe_key: {type: String, required: true},
        payment_methods: {type: Array, required: true},
        setup_intent_url: {type: String, required: true},
        payment_method_added_url: {type: String, required: true},
        remove_payment_method_url: {type: String, required: true},
        set_payment_method_as_default_url: {type: String, required: true},
    },
    data() {
        return {
            stripe: null,
            stripeCard: null,
            stripeIntent: null,
            cardLoaded: false,
            stripeLoaded: false,
            cardAddError: false,
            paymentMethods: this.payment_methods,
            showUpdateDefaultPaymentMethod: true,
            loading: {
                prepCard: false,
                addedCard: false,
                addingCard: false,
                removingCard: false,
                settingCardAsDefault: false,
                updateDefaultPaymentMethod: false,
            },
        }
    },
    mounted() {
        const self = this;
        loadStripe(this.stripe_key).then(function (stripe) {
            self.stripe = stripe;
            self.stripeLoaded = true;
        });
    },
    created() {
    },
    methods: {
        async addCard() {

            if (this.loading.addingCard) {
                return;
            }
            this.loading.addingCard = true;
            this.cardAddError = false;

            const self = this;
            const card = this.stripeCard;
            await this.stripe.confirmCardSetup(this.stripeIntent.client_secret, {payment_method: {card}})
                .then((result) => {

                    self.loading.addedCard = true;

                    if (result.error) {
                        self.cardAddError = result.error?.message ? result.error.message : 'Something went wrong';
                    } else {
                        axios.post(self.payment_method_added_url, {
                            'payment_method_id': result.setupIntent.payment_method
                        }).then((response) => {

                            const status = parseInt(response.data.status ?? -1);
                            if (status === 1) {

                                const addedCard = response.data.payment_method.card;

                                this.paymentMethods.push({
                                    type: 'card',
                                    id: response.data.payment_method.id,
                                    customers_default: response.data.customers_default,
                                    card: {
                                        last4: addedCard.last4,
                                        exp_month: addedCard.exp_month >= 10 ? addedCard.exp_month : `0${addedCard.exp_month}`,
                                        exp_year: String(addedCard.exp_year).slice(-2),
                                        brand: addedCard.brand,
                                    },
                                });
                            }
                        }).finally(() => self.loading.addedCard = false);
                        self.emitter.emit('modal-off', 'stripe-add-payment-card');
                    }
                })
                .catch((error) => console.error(error))
                .finally(function () {
                    self.loading.addingCard = false;
                });
        },
        prepCard() {

            this.cardAddError = false;
            if (this.loading.prepCard === true) {
                return;
            }
            this.loading.prepCard = true;

            axios.get(this.setup_intent_url)
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        const elements = this.stripe.elements();

                        this.stripeIntent = response.data.setup_intent;
                        this.stripeCard = elements.create('card', {'hidePostalCode': true});

                        this.card = this.stripeCard.mount('#card-element');
                        this.cardLoaded = true;
                    }
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => this.loading.prepCard = false);
        },
        removeCard(payment_method) {

            if (this.loading.removingCard) {
                return;
            }
            this.loading.removingCard = true;

            axios.post(this.remove_payment_method_url, {
                'payment_method': payment_method
            })
                .then((response) => {

                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        this.paymentMethods.forEach((card, i) => {
                            if (card.id === payment_method) {
                                this.paymentMethods.splice(i, 1);
                                this.emitter.emit('modal-off', 'remove_card_modal');
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading.removingCard = false;
                });
        },
        setCardAsDefault(payment_method) {
            if (this.loading.settingCardAsDefault) {
                return;
            }
            this.loading.settingCardAsDefault = true;

            axios.post(this.set_payment_method_as_default_url, {
                payment_method: payment_method
            })
                .then((response) => {

                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        this.paymentMethods.forEach((card, i) => {
                            this.paymentMethods[i]['customers_default'] = card.id === payment_method;
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading.settingCardAsDefault = false;
                });
        }
    },
    computed: {
        creditCards() {
            this.$emit('paymentMethodsChangeEvent', this.paymentMethods);
            return this.paymentMethods;
        },
    },
}
</script>
<style scoped></style>
