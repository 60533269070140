<template>
    <modal :title="'Are you sure?'" size="small" name="remove-partnero-list-submission">
        <template #toggle>
            <btn type="light">Remove submission</btn>
        </template>
        <template #body>
            <div class="space-y-6">
                <div class="text-sm text-gray-500">
                    Do you really want to remove PartneroList submission of this program?
                </div>
                <alert v-if="error" type="error">{{ error }}</alert>
            </div>
        </template>
        <template #footer>
            <btn @click="removeSubmission" :disabled="loading" :loading="loading" type="primary">Yes, remove</btn>
        </template>
    </modal>
</template>
<script setup>
import {ref, defineProps, getCurrentInstance} from 'vue';
import axios from 'axios';

const {proxy} = getCurrentInstance()
const props = defineProps({
    remove_url: String
});

const error = ref('');
const loading = ref(false);

const removeSubmission = () => {
    if (loading.value) {
        return;
    }

    error.value = '';
    loading.value = true;

    axios
        .delete(props.remove_url)
        .then(response => {
            const status = parseInt(response.data.status || -1);
            if (status === 1) {
                proxy.emitter.emit('modal-off', 'remove-partnero-list-submission')
                const redirect_url = response.data.redirect_url || null;
                if (redirect_url) {
                    window.location.href = redirect_url;
                } else {
                    window.location.reload();
                }
            }
        })
        .catch(err => {
            error.value = err.response?.data?.message ?? 'Failed to remove PartneroList submission';
        })
        .finally(() => {
            loading.value = false;
        });
}
</script>

