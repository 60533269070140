import {defineStore} from "pinia";

export let usePartneroReferAFriendStore = defineStore('partneroReferAFriend', {
    state: () => ({
        customer: null,
        referrals: null,
        refLinks: null,
        balance: null,
        loadingStats: false,
        loadingBalance: false
    }),

    actions: {
        fetchCustomerStats() {
            if (this.loadingStats) {
                return
            }
            this.loadingStats = true;

            axios.get(route('app_int.refer_a_friend.stats'))
                .then((response) => {
                    this.customer = response.data.customer
                    this.referrals = response.data.referrals
                    this.refLinks = response.data.ref_links
                })
                .catch((error) => {
                    console.log(error)
                }).finally(() => {
                    this.loadingStats = false
                })
        },
        fetchCustomerBalance() {
            if (this.loadingBalance) {
                return
            }
            this.loadingBalance = true;

            axios.get(route('app_int.refer_a_friend.balance'))
                .then((response) => {
                    this.balance = response.data.balance
                })
                .catch((error) => {
                    console.log(error)
                }).finally(() => {
                    this.loadingBalance = false
                })
        },
    },

    getters: {
        getCustomer: (state) => state.customer,
        getReferrals: (state) => state.referrals,
        getRefLinks: (state) => state.refLinks,
        getBalance: (state) => state.balance
    }
})
