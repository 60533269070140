<template>
    <textarea
        id="custom-html-code"
        v-model="textAreaContent"
        rows="5"
        class="block w-full text-sm rounded border-gray-300 focus:ring-0 focus:border-gray-400 mb-1 scroll-h"
    ></textarea>
</template>

<script>
export default {
    data() {
        return {
            textAreaContent: `<table width="100%" border="0" cellspacing="0" cellpadding="0">
    <tr>
        <td class="row" style="padding: 40px 50px;">
            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border-collapse: separate!important; border-radius: 8px;">
                <tr>
                    <td class="row" bgcolor="#ffffff" style="padding: 40px; border: 1px solid #E6E6E6; border-radius: 7px;">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tr>
                                <td style="padding-bottom: 30px;">
                                    <h2 style="font-family: 'Inter', sans-serif; color: #000000; font-size: 24px; line-height: 125%; font-weight: bold; font-style: normal; text-decoration: none; ;margin-bottom: 10px; text-align: center;">
                                        Share and earn rewards
                                    </h2>
                                    <p style="font-family: 'Inter', sans-serif; color: #515856; font-size: 16px; line-height: 165%; margin-top: 0; margin-bottom: 0; text-align: center;">
                                        Share this newsletter with your friends.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td align="center">
                                    <table class="ml-btn ml-btn-primary ml-btn-filled" border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse: separate; width: auto;">
                                        <tr>
                                            <th align="center" valign="middle" style="background-color: #2CB191; border-radius: 6px; font-weight: normal; font-style: normal; text-decoration: none; ;mso-padding-top-alt: 14px; mso-padding-bottom-alt: 14px; mso-padding-left-alt: 25px; mso-padding-right-alt: 25px; -webkit-font-smoothing: auto; word-break: break-all;">
                                                <a href="{{subscriber.partnero_newsletter_portal_link}}" target="_blank" style="display: inline-block; padding: 14px 25px; font-family: 'Inter', sans-serif; color: #ffffff; font-size: 14px; letter-spacing: 0.025em; text-decoration: none; border-radius: 6px; line-height: 16px; mso-line-height-rule: at-least; word-break: break-all; min-width: 70px; width: auto; max-width: 90%;">
                                                <div>Click to share</div>
                                                </a>
                                            </th>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding-top: 30px;">
                                    <p style="font-family: 'Inter', sans-serif; color: #515856; font-size: 16px; line-height: 165%; margin-top: 0; margin-bottom: 0; text-align: center;">
                                        Or copy & paste the link directly to your friends: {{subscriber.partnero_newsletter_share_link}}
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>`
        };
    }
}
</script>
