import {reactive} from "vue";
import {withState} from "../../helpers/store-helper";

const state = reactive({
    fetchDataBaseUrl: null,
    fetchDataFunction: null,
    filters: {},
    sorting: {
        key: 'created_at',
        direction: 'desc'
    },
    searchFieldPlaceholder: 'Search by name or email...',
    search: '',
    layoutOptions: {},
    metaData: {},
    validationErrors: null
})

const setLayoutOptions = (options) => {
    state.layoutOptions = options
}

const setDataBaseUrl = (url) => {
    state.fetchDataBaseUrl = url
}

const setFetchDataFunction = (fetchFunction) => {
    state.fetchDataFunction = fetchFunction
}

const setFilters = (modelFilters) => {
    state.filters = modelFilters
}

const setSearchFieldPlaceholder = (placeholder) => {
    state.searchFieldPlaceholder = placeholder
}

const setSearchParam = (searchQuery) => {
    state.search = searchQuery
}

const setMetaData = (data) => {
    state.metaData = data
}

export const setValidationErrors = (errors) => {
    state.validationErrors = errors
}

export const setSorting = (sorting) => {
    state.sorting = sorting
}

export default () => withState({
    setDataBaseUrl,
    setLayoutOptions,
    setFetchDataFunction,
    setFilters,
    setSearchFieldPlaceholder,
    setSearchParam,
    setMetaData,
    setSorting
}, state)
