<template>
    <slot :childScope="childScope"/>
</template>

<script>
    export default {
        props: {
            class: {
                default: ''
            }
        },
        data() {
            return {
                childScope: {
                    hamburgerActive: false,
                }
            }
        }
    }
</script>
