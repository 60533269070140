<template>

    <h3 class="text-lg font-medium">About</h3>

    <div class="text-gray-500 text-sm">
        Integrating Stripe with Partnero allows you to monitor and record your customers' purchases.
    </div>

    <div class="hidden">
        <h3 class="text-lg font-medium">Add customer key to Stripe metadata</h3>
        <div class="text-gray-500 text-sm">
            <p class="mb-2">Whenever you record transactions for a customer using Stripe, it's important to inform Partnero about which specific customer you're referring to. We use a customer key at Partnero to uniquely identify each customer, and this key can be any value that you use to identify them on your platform.</p>

            <p class="mb-2">If you created the customer record using our Partnero API, then the key you passed as the customer object's key would be used.</p>

            <p class="mb-2">To accomplish this, we look for the customer_key in the Stripe metadata. Here's an example of how this could be implemented on your back-end. Please keep in mind that your implementation may vary depending on how your product interacts with Stripe.</p>
            <pre class="text-xs bg-gray-100 rounded p-2 my-2">
    <code class="language-php">
    $stripe = new \Stripe\StripeClient('sk_test_4eC39HqLyjWDarjtT1zdp7dc');

    $stripe->charges->create([
    'amount' => 2000,
    'currency' => 'usd',
    'source' => 'tok_visa',
    'metadata' => [
    'customer_key' => 'customer_key',
    ],
    ]);</code>
            </pre>
            <p>For more information on how to add metadata to Stripe, please refer to the <a href="https://stripe.com/docs/api/metadata" target="_blank" class="underline hover:no-underline">Stripe documentation</a>.</p>
        </div>
    </div>

    <div class="space-y-6">
        <tabs>
            <tab @click="setActiveStripe(STRIPE_1)" :class='{"border-b-4": activeStripe === STRIPE_1}'>
                Checkout tracking
            </tab>
            <tab @click="setActiveStripe(STRIPE_2)" :class='{"border-b-4": activeStripe === STRIPE_2}'>
                Tracking via Payment Links
            </tab>
        </tabs>

        <div class="space-y-6" v-show="activeStripe === STRIPE_1">

            <div class="text-sm space-y-2">
                <p>The most efficient and straightforward method to monitor referred transactions is immediately after the completion of a purchase.</p>
                <p>Run this script right after a sale is successfully concluded.</p>
            </div>

            <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-js">
&#60;script&#62;
  po('transactions', 'create', {
    data: {
      key: 'stripe_charge_id',
      amount: 99.99,
      amount_units: 'usd',
      customer: {
        key: 'stripe_customer_id'
      }
    },
    options: {
      create_customer: true
    }
  });
&#60;/script&#62;</code>
                    </pre>

            <div class="text-sm space-y-2">
                <p>Please ensure that the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">stripe_charge_id</span> and <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">stripe_customer_id</span> fields are accurately updated with the corresponding data from Stripe.</p>
            </div>

        </div>

        <div class="space-y-6" v-show="activeStripe === STRIPE_2">

            <div class="text-sm space-y-2">
                <p>
                    Include the following script on every page containing payment links. It's important to place
                    this script at the bottom of the page, directly above the closing <span
                    class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">&lt;/body&gt;</span>
                    tag for optimal functionality.
                </p>
            </div>
            <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-js">
&#60;script&#62;
(function() {
    const updateLinkHref = link => {
        const cookieValue = (document.cookie.match(/(^| )partnero_partner=([^;]+)/) || [])[2];
        if (cookieValue) {
            try {
                const url = new URL(link.href);
                url.searchParams.set('client_reference_id', decodeURIComponent(cookieValue));
                link.href = url.href;
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    document.querySelectorAll('a[href*="buy.stripe.com"]').forEach(link => {
        updateLinkHref(link);
        link.addEventListener('click', () => updateLinkHref(link));
    });
})();
&#60;/script&#62;</code>
                    </pre>




        </div>
    </div>

        </template>

<script setup>

import {ref} from 'vue';

const props = defineProps({
    program: {
        type: [String, Number]
    },
    integrations_config: {
        type: Object
    },
});

const TAB_PADDLE = 'paddle';
const TAB_STRIPE = 'stripe';
const TAB_PARTNERO_API = 'partnero_api';
const TAB_PARTNERO_JS = 'partnero_js';

const PADDLE_JS = 'paddle_js';
const PADDLE_BACKEND = 'paddle_backend';

const STRIPE_1 = 'stripe_1';
const STRIPE_2 = 'stripe_2';

let activeTab = ref(TAB_PARTNERO_API);
let activePaddle = ref(PADDLE_JS);
let activeStripe = ref(STRIPE_1);

const program = props.program;
const config = props.integrations_config;

const setActiveTab = (tab) => {
    activeTab.value = tab;
};

const setActivePaddle = (tab) => {
    activePaddle.value = tab;
};

const setActiveStripe = (tab) => {
    activeStripe.value = tab;
};

</script>


