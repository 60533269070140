<template>

    <div class="space-y-6">

        <div class="bg-gradient-to-tr from-white via-gray-50 to-teal-50 border rounded p-4 text-sm flex items-center gap-2">
            <icon name="lightbulb"></icon>
            <div>
                This guide explains how to to integrate partnero into your <b>WordPress</b> website.
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Install a plugin to add custom code snippets</h3>
                <div class="text-sm space-y-2">
                    <p>To integrate Partnero, you’ll need to insert a code snippet into your WordPress website. The easiest way to do this is by installing a dedicated plugin that simplifies the process.</p>
                    <p>You can choose any plugin that allows you to add scripts to the header, body, and footer of your site. However, we recommend using the <a class="underline hover:no-underline" href="https://wordpress.org/plugins/insert-headers-and-footers/" target="_blank">WPCode Lite</a> plugin for its ease of use and reliability.</p>
                </div>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>To track referred visitors on your website, you need to install the <badge type="neutral" title="PartneroJS snippet"></badge> on your website as custom code.</p>
                </div>
            </div>
            <slot name="universal_script"></slot>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sign-ups tracking</h3>
                <div class="text-sm space-y-2">
                    <p>Each referred visitor who signs up or performs a similar action that needs to be tracked as a referral on your site must be created as a customer on Partnero.</p>
                    <p>By default, WordPress provides a basic user registration system, allowing visitors to sign up and create accounts. However, depending on your needs, the default functionality might be limited, which is why many site owners use plugins to extend it.</p>
                    <p>To track default sign-ups, you can insert the <badge type="neutral" title="sign-up tracking snippet"></badge> at the bottom of your website before closing <badge type="neutral" title="</body>"></badge> tag. This script captures sign-ups that come through the standard WordPress registration form as well as most plugins.</p>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-2').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-2" rows="2" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs" v-pre><script>
po('integration', 'universal', null);
</script></textarea>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sales tracking</h3>
                <div class="text-sm space-y-2">
                    <p>To complete the integration, you need to share details of successful sales with Partnero. Since WordPress doesn’t offer a built-in solution for handling purchases, this can be achieved by using one of the following methods:</p>
                    <ul class="list-disc ml-4 space-y-0.5">
                        <li>Use <b>Stripe</b> or <b>Paddle Billing</b> integrations</li>
                        <li>Implementing tracking through the <a class="underline hover:no-underline" href="https://developers.partnero.com/guide/affiliate.html#sending-sales-data" target="_blank">Parterno API</a></li>
                        <li>Implementing tracking through the <a class="underline hover:no-underline" href="https://developers.partnero.com/guide/javascript-tracking.html#sales-tracking" target="_blank">Partnero JS</a></li>
                        <li>Use <b>Zapier</b> or <b>Make</b> to connect everything.</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Alternatives</h3>
                <div class="text-sm space-y-2">
                    <p>Alternative integration options may be available. If you’re using third-party plugins on your WordPress site, please refer to other guides, such as the one for integrating with <b>Stripe via Payment Links</b>, or explore other relevant options.</p>
                </div>
            </div>
        </div>

    </div>

</template>
