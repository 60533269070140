<template>

    <div class="space-y-4">

        <header-bar size="small" title="Overview">
            <template #actions>
                <div class="flex space-x-2">
                    <select
                        id="commission_period"
                        name="time_period"
                        class="-mt-2 xl:-mt-4 -mr-4 block border-0 focus:ring-0 leading-4 ml-auto px-8 py-1.5 rounded text-right text-xs text-gray-500"
                        @change="timePeriodChange($event)"
                        v-model="timePeriod"
                    >
                        <option value="total" selected>Total</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_month">This month</option>
                        <option value="last_month">Last month</option>
                        <option value="last_6_months">Last 6 months</option>
                        <option value="this_year">This year</option>
                        <option value="last_year">Last year</option>
                    </select>
                </div>
            </template>
        </header-bar>

        <referral-setup-guide :programId="programId"/>

        <card inset="none" class="mb-4" #body>
            <div class="grid grid-cols-1 sm:grid-cols-3">
                <div class="col-span-1 p-6 border-b sm:border-b-0">
                    <div class="flex items-center gap-4">
                        <div
                            class="flex flex-shrink-0 justify-center items-center w-20 h-20 rounded-md text-xl font-semibold tracking-tight bg-orange-100 text-orange-900">
                            {{ program.initial }}
                        </div>
                        <div class="flex gap-0.5 flex-col">
                            <span class="block font-medium text-xs uppercase">{{ program.type }}</span>
                            <span class="block font-semibold">{{ program.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 p-6 flex items-center">

                    <ul class="flex-1 grid grid-cols-3 gap-y-4 gap-x-4 text-sm">
                        <li class="flex flex-col gap-1.5">
                            <span class="text-sm text-gray-900 tracking-wide">Active rewards</span>
                            <span
                                class="font-semibold"
                            >
                           {{ active_rewards }}
                       </span>
                        </li>
                        <li class="flex flex-col gap-1.5">
                            <span class="text-sm text-gray-900 tracking-wide">Program created</span>
                            <span class="font-semibold">
                            {{ program.created_at }}
                        </span>
                        </li>
                        <li class="flex flex-col gap-1.5">
                            <span class="text-sm text-gray-900 tracking-wide">Last edited</span>
                            <span class="font-semibold">{{ program.last_edited }}</span>
                        </li>
                    </ul>

                </div>
            </div>
        </card>

        <div class="grid xl:col-span-3 lg:grid-cols-3 gap-4 ">
            <white-card title="Customers" :value="total_customers">
                <icon :name="getIconName(total_customers)" size="10"></icon>
                <span>{{ total_customers_growth }}%</span>
            </white-card>
            <white-card title="Referring customers" :value="total_referring_customers">
                <icon :name="getIconName(total_referring_customers)" size="10"></icon>
                <span>{{ total_referring_customers_growth }}%</span>
            </white-card>
            <white-card title="Referred customers" :value="total_referred_customers">
                <icon :name="getIconName(total_referred_customers)" size="10"></icon>
                <span>{{ total_referred_customers_growth }}%</span>
            </white-card>
            <white-card title="Sales" :value="total_sales">
                <icon :name="getIconName(total_sales)" size="10"></icon>
                <span>{{ total_sales_growth }}%</span>
            </white-card>
            <white-card title="Participation" :value="participation + '%'">
                <icon :name="getIconName(participation)" size="10"></icon>
                <span>{{ participation_growth }}%</span>
            </white-card>
            <white-card title="Program revenue" :value="total_revenue_from_sales" type="teal"></white-card>

        </div>

    </div>

</template>

<script>
export default {
    name: 'ReferralProgramOverviewStats',
    props: {
        programId: {
            type: Number,
            required: true,
        },
        dataUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            errors: [],
            loading: false,
            timePeriod: 'total',
            program: {
                initial: '',
                type: '',
                name: '',
                created_at: '',
                last_edited: '',
            },
            active_rewards: 0,

            total_customers: 0,
            total_customers_growth: 0,

            total_referring_customers: 0,
            total_referring_customers_growth: 0,

            total_referred_customers: 0,
            total_referred_customers_growth: 0,

            total_sales: 0,
            total_sales_growth: 0,

            participation: 0,
            participation_growth: 0,

            total_revenue_from_sales: ''
        }
    },
    created() {
        this.refreshData();
    },
    methods: {
        refreshData() {

            this.loading = true;

            axios
                .post(this.dataUrl, {
                    filter: {
                        time_period: this.timePeriod,
                        program: this.programId
                    }
                })
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.program = response.data.data.program;
                        this.active_rewards = response.data.data.active_rewards;

                        this.total_customers = response.data.data.total_customers;
                        this.total_customers_growth = response.data.data.total_customers_growth;

                        this.total_referring_customers = response.data.data.total_referring_customers;
                        this.total_referring_customers_growth = response.data.data.total_referring_customers_growth;

                        this.total_referred_customers = response.data.data.total_referred_customers;
                        this.total_referred_customers_growth = response.data.data.total_referred_customers_growth;

                        this.total_sales = response.data.data.total_sales;
                        this.total_sales_growth = response.data.data.total_sales_growth;

                        this.participation = response.data.data.participation;
                        this.participation_growth = response.data.data.participation_growth;

                        this.total_revenue_from_sales = this.buildTotalRevenueString(response.data.data.total_revenue_from_sales);

                    }
                })
                .catch(error => {
                    this.errors = error?.response?.data?.errors || [];
                })
                .finally(() => this.loading = false);
        },
        buildTotalRevenueString(values) {
            return Object.values(values).join(' ');
        },
        timePeriodChange() {
            this.refreshData();
        },
        getIconName(value) {
            return (value < 0) ? 'arrow-down' : 'arrow-up';
        }
    }
}
</script>
