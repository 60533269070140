<template>
    <div>
    <label for="commission_rounding" class="block mb-1.5 text-sm">Commission rounding</label>
    <div class="grid gap-1" :class="rounding_value_disabled ? 'grid-cols-1' : 'grid-cols-2'">
        <select id="commission_rounding" name="commission_rounding" v-model="rounding_enabled"
                class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
            <option value="0">OFF</option>
            <option value="1">ON</option>
        </select>
        <text-field :model-value="rounding_value" name="commission_rounding_precision" v-if="!rounding_value_disabled"
                    :disabled="rounding_value_disabled" :required="!rounding_value_disabled"></text-field>
    </div>
    <small class="text-gray-500 mt-1 block">Round rewards to decimal places (between 0 and 4).</small>
    </div>
</template>
<script>
export default {
    name: 'CommissionRounding',
    props: ['commission_rounding_enabled', 'commission_rounding_value'],
    data() {
        return {
            rounding_enabled: this.commission_rounding_enabled,
            rounding_value: this.commission_rounding_value,
        }
    },
    computed: {
        rounding_value_disabled() {
            return this.rounding_enabled === '0';
        }
    },
    methods: {}
}
</script>
<style scoped>
</style>
