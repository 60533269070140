<template>
    <modal :title="'Approve partner'" size="small">
        <template v-if="$slots.toggle" v-slot:toggle>
            <slot name="toggle"></slot>
        </template>
        <template v-else v-slot:toggle>
            <btn type="light">Approve</btn>
        </template>
        <template #body>
            <div class="space-y-6">
                <div class="text-sm text-gray-500">
                    Do you really want to approve this partner?
                </div>
            </div>
        </template>
        <template #footer>
            <btn
                button-type="submit"
                type="primary"
                @click.prevent="approveRejectRequest(approveUrl)"
            >
                Approve
            </btn>
        </template>
    </modal>
</template>

<script>
import useApprovalRequests from "../../../../../composables/app/approval-requests";

export default {
    name: "RequestApprovalModal",
    props: {
        approveUrl: {
            type: String,
            required: true
        }
    },
    setup () {
        const { approveRejectRequest } = useApprovalRequests()

        return { approveRejectRequest }
    }
}
</script>
