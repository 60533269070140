<script setup>
import {ref, onMounted} from 'vue';
import AffiliateOverview from './dashboard/AffiliateOverview';
import ReferralOverview from './dashboard/ReferralOverview';
import NewsletterOverview from './dashboard/NewsletterOverview';
import AccountSubscriptionOverview from "./dashboard/AccountSubscriptionOverview";
import DashboardSkeleton from "../../common/skeletons/DashboardSkeleton";

const props = defineProps({
    dataUrl: {
        type: String,
        required: true,
    },
    programsIndexUrl: {
        type: String,
    },
    currentPlan: {
        type: Object,
        default: () => null
    }
});

const errors = ref([]);
const loading = ref(false);
const timePeriod = 'total';
const data = ref({
    program_count: 0,
    account_total_rewards: 0,
    account_total_rewards_growth: 0,
    account_total_revenue: 0,
    account_total_revenue_growth: 0,
    affiliate_programs: [],
    referral_programs: [],
    newsletter_programs: [],
    coupon_codes: {},
    approvals: [],
    payouts: {
        manual: [],
        automated: [],
    },
});

const loadData = async () => {
    loading.value = true;
    try {
        const {data: {data: responseData}} = await axios.post(props.dataUrl, {
            filter: {
                time_period: timePeriod.value,
            },
        });

        data.value = {
            program_count: responseData.program_count,
            account_total_rewards: responseData.account_total_rewards,
            account_total_rewards_growth: responseData.account_total_rewards_growth,
            account_total_revenue: responseData.account_total_revenue,
            account_total_revenue_growth: responseData.account_total_revenue_growth,
            affiliate_programs: responseData.affiliate_programs ?? [],
            referral_programs: responseData.referral_programs ?? [],
            newsletter_programs: responseData.newsletter_programs ?? [],
            coupon_codes: responseData.coupon_codes,
            approvals: responseData.approvals,
            payouts: responseData.payouts,
        };
    } catch (error) {
        errors.value = error?.response?.data?.errors || [];
    } finally {
        loading.value = false;
    }
};

onMounted(() => {
    loadData();
});
</script>

<template>
    <dashboard-skeleton v-if="loading"></dashboard-skeleton>
    <template v-else>
        <affiliate-overview
            v-if="data.affiliate_programs.length > 0"
            title="Affiliate overview"
            :programs="data.affiliate_programs"
            :approvals="data.approvals"
            :payouts="data.payouts"
        ></affiliate-overview>

        <referral-overview
            v-if="data.referral_programs.length > 0"
            title="Referral overview"
            :programs="data.referral_programs"
        ></referral-overview>

        <newsletter-overview
            v-if="data.newsletter_programs.length > 0"
            title="Newsletter overview"
            :programs="data.newsletter_programs"
        ></newsletter-overview>

        <account-subscription-overview
            v-if="currentPlan"
            title="Account details"
            :current-plan="currentPlan"
        ></account-subscription-overview>
    </template>
</template>
