<template>

    <div class="space-y-6">

        <div class="bg-gradient-to-tr from-white via-gray-50 to-teal-50 border rounded p-4 text-sm flex items-center gap-2">

            <icon name="lightbulb"></icon>
            <div>
                This guide explains how to create a customer on Partnero when your website uses <b>Typeform</b> for user sign-ups.
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the <badge type="neutral" title="PartneroJS snippet"></badge> on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>

        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Create a hidden field on your form</h3>
                <div class="text-sm space-y-2">
                    <p>In this step, you’ll need to create a hidden field where the referral key will be stored. This key is essential for assigning the customer to the correct partner.</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Open your form from your workspace. On the right panel, click <b>Logic</b>. You can now see the <b>Personalize with data option</b>. Click this to go to <b>Hidden Fields</b>.</li>
                        <li>Click <b>Hidden Fields</b>, and a new dialog will open where you can add your <b>Hidden Fields</b>.</li>
                        <li>Click + Add new field button and created hidden field named <b>aff</b>, then hit <b>Save</b>.</li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sign-ups tracking</h3>
                <div class="text-sm space-y-2">
                    <p>Paste the following script on the page that contains the link to your Typeform form. Alternatively, you can insert this script on all relevant pages.</p>
                    <p><b>Please note:</b> Replace the URL in the script with the link to your Typeform form. You can also use a URL pattern if applicable.</p>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-2').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-2" rows="4" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs" v-pre><script>
(function() {

    const baseUrlPattern = 'https://link-to-form.com'; // Replace this with the URL or URL pattern that matches your form.
    const affParameter = 'aff'; // This is the name of your hidden field. You can modify it if you’re using a different hidden field name.

    const updateLinkHref = link => {

        const cookieValue = (document.cookie.match(/(^| )partnero_partner=([^;]+)/) || document.cookie.match(/(^| )partnero_referral=([^;]+)/) || [])[2];

        if (cookieValue) {
            try {
                const url = new URL(link.href);

                url.hash = `${affParameter}=${decodeURIComponent(cookieValue)}`;
                link.href = url.href;
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // Select all links that match the baseUrlPattern and update them
    document.querySelectorAll(`a[href^="${baseUrlPattern}"]`).forEach(link => {
        updateLinkHref(link);
        link.addEventListener('click', () => updateLinkHref(link));
    });
})();
</script></textarea>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sending data to Partnero</h3>
                <div class="text-sm space-y-2">
                    <p>If user submitted the form as a referral, hidden field <b>aff</b> should have partner's key. You need to send this data to Partnero.</p>
                    <p>The easiest way to do that is through Webhooks.</p>
                    <ul class="list-disc ml-4 space-y-0.5">
                        <li>Open your form from your workspace. Go to <b>Connect</b> -> <b>Webhooks</b>.</li>
                        <li>Go to your Partnero account. Go to <b>Integration</b> -> <b>Webhooks</b>.</li>
                        <li>Look for <b>Incoming webhooks</b> section, click on <b>Add incoming webhook</b> button.</li>
                        <li>Choose <b>Create a partner</b> option and follow instructions.</li>
                        <li>Go back to Typeform and Create a webhook. Use URL given at Partner's incoming webhooks.</li>
                        <li>Match required fields. Don't forget to use <b>hidden field's</b> value as <b>partner key</b>.</li>
                    </ul>
                    <p>Alternatively, you can use Use <b>Zapier</b> or <b>Make</b> integrations.</p>
                </div>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sales tracking</h3>
                <div class="text-sm space-y-2">
                    <p>To complete the integration, you need to share details of successful sales with Partnero. You can do this in a few ways:</p>
                    <ul class="list-disc ml-4 space-y-0.5">
                        <li>Use <b>Stripe</b> or <b>Paddle Billing</b> integrations</li>
                        <li>Implementing tracking through the <a class="underline hover:no-underline" href="https://developers.partnero.com/guide/affiliate.html#sending-sales-data" target="_blank">Parterno API</a></li>
                        <li>Implementing tracking through the <a class="underline hover:no-underline" href="https://developers.partnero.com/guide/javascript-tracking.html#sales-tracking" target="_blank">Partnero JS</a></li>
                        <li>Use <b>Zapier</b> or <b>Make</b> to connect everything.</li>
                    </ul>
                </div>
            </div>
        </div>

    </div>

</template>
