<template>
    <slot :childScope="childScope" :childMethods="{ postpone, submit }"/>
</template>

<script>
export default {
    props: {
        min: Number,
        regex: Object,
        change_url: String,
        postpone_url: String,
    },

    data() {
        return {
            childScope: {
                newPassword: '',
                currentPassword: '',
                newPasswordConfirmation: '',
                submitting: false,
                rules: {
                    passwordLength: false,
                },
                errors: {
                    current_password: false,
                    new_password: false,
                }
            }
        };
    },

    computed: {
        rulesNotSatisfied() {
            return Object.keys(this.childScope.rules).filter((key) => !this.childScope.rules[key]).length > 0;
        },

        cannotChange() {
            return this.childScope.rulesNotSatisfied || this.childScope.submitting;
        }
    },

    watch: {
        'childScope.newPassword': function (password) {
            this.childScope.errors.new_password = false;
            _.forEach(this.regex, (value, key) => {
                this.childScope.rules[_.camelCase(value)] = new RegExp(key).test(password)
            })

            this.childScope.rules.passwordLength = password.length >= this.min
        },

        'childScope.currentPassword': function () {
            this.childScope.errors.current_password = false;
        }
    },

    created() {
        _.forEach(this.regex, (value, key) => {
            this.childScope.rules[_.camelCase(value)] = false
        })
    },

    methods: {
        postpone() {
            if (this.childScope.submitting) return;
            this.childScope.submitting = true;
            axios.post(this.postpone_url).then(() => {
                this.childScope.submitting = false;
            }).finally(() => {
                this.childScope.submitting = false;
            });
        },
        submit() {
            if (this.cannotChange || this.childScope.submitting) {
                return;
            }

            this.childScope.submitting = true;
            axios.post(this.change_url, {
                current_password: this.childScope.currentPassword,
                new_password: this.childScope.newPassword,
                new_password_confirmation: this.childScope.newPasswordConfirmation,
            }).then(() => {
                this.childScope.submitting = false;
                this.emitter.emit('modal-off', 'password_change_reminder')
            }).catch((error) => {
                const errors = _.get(error, 'response.data.errors', {});

                Object.keys(errors).forEach((key) => {
                    this.childScope.errors[key] = true;
                })

                this.childScope.submitting = false;
            }).finally(() => {
                this.childScope.submitting = false;
            });
        }
    }
}
</script>
