<template>
    <div class="bg-white border rounded space-y-4 p-4">

        <div class="flex items-center justify-between">

            <div class="flex items-center gap-4">
                <img v-if="icon"
                    class="w-10 h-10 rounded" :src="`/images/integrations/${this.icon}.png`"/>
                <div class="text-sm font-semibold">{{ name }}</div>
            </div>

            <div v-if="$slots.instructions">
                <modal :title="name" size="large">
                    <template #toggle>
                        <btn type="secondary" size="small">Instructions</btn>
                    </template>
                    <template #body>
                        <slot name="instructions" />
                    </template>
                </modal>
            </div>

        </div>

        <div class="grid grid-cols-3 bg-gray-50 p-3 rounded">
            <div v-if="difficulty">
                <div class="text-xs font-semibold mb-1.5">Difficulty</div>
                <div>
                   <badge v-if="difficulty == 'easy'" title="Easy" type="green"></badge>
                   <badge v-if="difficulty == 'medium'" title="Medium" type="warning"></badge>
                </div>
            </div>
            <div>
                <div class="text-xs font-semibold mb-1.5">Developer</div>
                <div>
                    <badge v-if="developer" title="Not required" type="neutral"></badge>
                    <badge title="Required" type="warning" v-else></badge>
                </div>
            </div>
            <div v-if="custom || stripe || paddle">
                <div class="text-xs font-semibold mb-1.5">Payment gateways</div>
                <div>
                    <tooltip v-if="custom" content="Integration with payment gateway is not required.">
                        <badge v-if="custom" title="Custom" type="neutral" class="mr-1 cursor-pointer"></badge>
                    </tooltip>
                    <tooltip v-if="stripe" content="Stripe integration is need.">
                        <badge v-if="stripe" title="Stripe" type="stripeIndigo" class="mr-1 cursor-pointer"></badge>
                    </tooltip>
                    <tooltip v-if="paddle" content="Paddle Billing integration is need for this method.">
                        <badge class="cursor-pointer" v-if="paddle" title="Paddle" type="neutral"></badge>
                    </tooltip>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
export default {
    props: {
        name: '',
        icon: '',
        difficulty: '',
        type: {
            default: 'default',
        },
        developer: {
            default: false
        },
        stripe: {
            default: false
        },
        paddle: {
            default: false
        },
        custom: {
            default: false
        },
    },
    data() {
        return {
            types: {
                default: {style: 'bg-white border text-gray-900'},
                teal: {style: 'bg-teal-500 text-white'},
            },
        }
    }
}
</script>
<style scoped>
</style>
