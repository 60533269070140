<template>
    <btn size="huge" type="secondary" :icon-name="iconName" class="w-full" @click="submit">
        {{ text }}
    </btn>
</template>

<script>
export default {
    name: 'AuthBtn',
    props: {
        submitUrl: {
            type: String,
            required: true
        },
        provider: {
            type: String,
            required: true
        },
        text: {
            type: String
        },
        registerUser: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {}
    },
    computed: {
        iconName () {
            return `${this.provider}-logo`;
        }
    },
    methods: {
        submit () {
            let url = new URL(this.submitUrl);
            let registerUser = this.submitUrl.includes('/register/');
            let isVerificationRequired = document.querySelector('[name="account_name"]') === null;

            if(!registerUser || isVerificationRequired) {
                window.location.href = url.href;
                return;
            }

            let companyName = document.querySelector('[name="account_name"]').value;
            let alertElement = document.getElementById('company-name-alert');

            !companyName.length ? alertElement.style.display = 'block' : alertElement.style.display = 'none';

            if(companyName.length) {
                url.searchParams.append('account_name', companyName);
                window.location.href = url.href;
            }
        }
    }
}
</script>
