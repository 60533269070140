<script setup>
import TableSkeleton from "../../../common/skeletons/TableSkeleton";
import {usePartneroReferAFriendStore} from "../../../../stores/app/refer-a-friend/PartneroReferAFriendStore";
import {copyToClipboard} from "../../../../helpers/common";

const partneroReferAFriendStore = usePartneroReferAFriendStore()
</script>

<template>
    <div class="space-y-1">
        <h4 class="text-md font-semibold">Share your unique link</h4>
        <div class="text-sm">Share your unique referral link by copying and sending it or sharing it on your social media.</div>
    </div>

    <div v-if="partneroReferAFriendStore.getRefLinks?.length" class="rounded-md bg-gray-50 sm:flex sm:items-start sm:justify-between gap-4">
        <table class="table">
            <tbody>
            <tr v-for="refLink in partneroReferAFriendStore.getRefLinks" class="group">
                <td width="60">
                    <svg class="w-5 h-5 opacity-60" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"></path>
                    </svg>
                </td>
                <td class="whitespace-nowrap">
                    <a :href="refLink.url" target="_blank"
                       class="font-normal text-opacity-100 text-custom-link hover:underline"
                    >
                        {{ refLink.url }}
                    </a>
                </td>
                <td class="font-medium whitespace-nowrap">
                    <div class="flex gap-2">
                        <tooltip content="Copy URL">
                            <a @click="copyToClipboard(refLink.url)">
                                <icon name="link"></icon>
                            </a>
                        </tooltip>
                        <template v-if="refLink.social_shares?.length">
                            <tooltip v-for="socialShare in refLink.social_shares" :content="`Click to share on ${socialShare.social}`">
                                <a target="_blank"
                                   data-size="large"
                                   :href="socialShare.link"
                                >
                                    <icon :name="socialShare.social"></icon>
                                </a>
                            </tooltip>
                        </template>
                    </div>
                </td>
                <td class="whitespace-nowrap"> <span class="font-medium">{{ refLink.clicks }}</span> clicks</td>
                <td v-if="partneroReferAFriendStore.getRefLinks.length > 1" class="whitespace-nowrap">
                    <span v-if="refLink.default" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-opacity-75 text-teal-600 bg-teal-100">
                        Default
                    </span>
                    <span v-else class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-opacity-75 text-teal-600 bg-teal-100">
                        Custom
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <TableSkeleton v-else :number-of-rows="1"></TableSkeleton>
</template>
