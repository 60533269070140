<script setup>
   import {useAnalyticsRealTimeStore} from "../../../../../../stores/app/programs/analytics/AnalyitcsRealTimeStore";
   import {computed} from "vue";
   import {formatNumber, isNumber} from "../../../../../../helpers/common";

   const analyticsRealTimeStore = useAnalyticsRealTimeStore();

   const bounceRateGrowth = analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries?.stats?.bounce_rate ?? 0;

   function calculatePercentage(firstArg, secondArg) {
       if (!isNumber(firstArg) || !isNumber(secondArg)) {
           return 0;
       }
       return ((firstArg / secondArg) * 100).toFixed(2)
   }

</script>

<template>
    <div class="space-y-8">
        <div class="grid lg:grid-cols-3 gap-4">
            <div
                class="p-6 bg-teal-500 rounded">
                <div class="mb-4 text-sm text-white">Current visitors</div>
                <h3 class="text-2xl xl:text-3xl font-semibold leading-tight flex gap-2 items-center">
                    <span>
                        <div class="flex items-baseline">
                            <span class="text-white">{{ formatNumber(analyticsRealTimeStore.getAnalyticsRealTimeCurrentVisitors?.current_total_visitors) || 0 }}</span>
                            <span class="ml-2 text-sm font-medium text-white">/ {{ formatNumber(analyticsRealTimeStore.getAnalyticsRealTimeCurrentVisitors?.current_total_referred_visitors) || 0 }} referred</span>
                        </div>
                    </span>
                </h3>
            </div>

            <div class="p-6 bg-white rounded border">
                <div class="mb-4 text-sm text-gray-900">Visitors</div>
                <h3 class="text-2xl xl:text-3xl font-semibold leading-tight dark:text-gray-300 flex gap-2 items-center">
                    <span>
                        <div class="flex items-baseline">
                            {{ formatNumber(analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries?.stats?.total_visitors) }}
                        <span class="ml-2 text-sm font-medium text-gray-500">
                            / {{ formatNumber(analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries?.stats?.total_views) }}
                            views
                        </span>
                        </div>
                    </span>
                </h3>
            </div>

            <div class="p-6 bg-white rounded border">
                <div class="mb-4 text-sm text-gray-900">Referred visitors</div>
                <h3 class="text-2xl xl:text-3xl font-semibold leading-tight dark:text-gray-300 flex gap-2 items-center">
                    <span>
                        <div class="flex items-baseline">
                            {{ formatNumber(analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries?.stats?.total_referred_visitors) }}
                            <span class="ml-2 text-sm font-medium text-gray-500">
                                / {{ formatNumber(analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries?.stats?.total_referred_views) }} views
                            </span>
                        </div>
                    </span>
                </h3>
            </div>
        </div>

        <div class="grid lg:grid-cols-3 gap-4">
            <div
                class="p-6 bg-white rounded border">
                <div class="mb-4 text-sm text-gray-900">Visitors to referrals</div>
                <h3 class="text-2xl xl:text-3xl font-semibold leading-tight dark:text-gray-300 flex gap-2 items-center">
                    <span>
                        <div>{{ analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries?.stats?.visitors_to_referrals ?? "0.00" }}%</div>
                    </span>
                </h3>
            </div>

            <div class="p-6 bg-white rounded-md border">
                <div class="mb-4 text-sm text-gray-900">Bounce rate</div>
                <h3 class="text-2xl xl:text-3xl font-semibold leading-tight dark:text-gray-300 flex gap-2 items-center">
                    <span>
                        <div>{{ formatNumber(analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries?.stats?.bounces) ?? 0 }}</div>
                    </span>
                    <span v-if="bounceRateGrowth < 0"
                          class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-red-100 bg-opacity-50 font-normal align-top tracking-tight text-red-500"
                    >
                            <svg class="inline w-2 h-2 text-red-600" fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z"/><path d="M13 12h7l-8 8-8-8h7V4h2z"/>
                            </svg>
                        {{ bounceRateGrowth }}%
                    </span>
                    <span v-else-if="bounceRateGrowth > 0"
                          class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-teal-100 bg-opacity-50 font-normal align-top tracking-tight text-teal-600">
                                <svg class="inline w-2 h-2 text-teal-600" fill="currentColor"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z"/><path d="M13 12v8h-2v-8H4l8-8 8 8z"/>
                                </svg>
                                {{ bounceRateGrowth }}%
                    </span>
                </h3>
            </div>

            <!-- Hidden for now! -->
            <div class="p-6 bg-white rounded-md border hidden">
                <div class="mb-4 text-sm text-gray-900">Referral conversion</div>
                <h3 class="text-2xl xl:text-3xl font-semibold leading-tight dark:text-gray-300 flex gap-2 items-center">
                    <span>
                        <div>{{ analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries?.stats?.referral_conversion ?? "0.00" }}%</div>
                    </span>
                </h3>
            </div>
        </div>
    </div>
</template>
