<script setup>

import {computed, onMounted, ref} from "vue";
import ListSkeleton from "../../../../common/skeletons/ListSkeleton.vue";
import SimplePagination from "../SimplePagination.vue";
import ProgressBar from "../../../../../elements/ProgressBar.vue";
import DeleteAutotogeneratePromotionCodeBlueprint from "./DeleteAutotogeneratePromotionCodeBlueprint.vue";

    const props = defineProps({
        program: {
            type: Object,
            required: true
        },
        coupon: {
            type: Object,
            required: true
        },
        autogeneratePromotionCodeBlueprint: {
            type: Object,
            required: true
        }
    });

    const autoAssignPromotionCodes = ref({
        data: []
    });

    const autogeneratePromotionCodeBlueprintIsNotEmpty = computed(() => {
            return Object.keys(props.autogeneratePromotionCodeBlueprint).length > 0;
        });

    const getSyncProviderStatus = (syncProviderId) => {
        if (syncProviderId) {
            return 'Yes';
        } else {
            return '<span class="text-red-600">No</span>';
        }
    }

    const baseUrl = route('app_int.program.coupon.promotion-code.auto-assign-list', { program: props.program, coupon: props.coupon });

    const loadingAutoAssignPromotionCodes = ref(false);

    const getAutoAssignPromotionCodes = (url) => {
        loadingAutoAssignPromotionCodes.value = true;
        axios.get(url)
            .then(response => {
                autoAssignPromotionCodes.value = response.data;
            })
            .finally(() => {
                loadingAutoAssignPromotionCodes.value = false;
            });
    };

    const getPartnerLink = (partnerId) => {
        if (!partnerId) return '#';
        return route('programs.partner.show', { program: props.program, partner: partnerId });
    };

    const getReferralLink = (customerId) => {
        if (!customerId) return '#';
        return route('programs.customer.show', { program: props.program, customer: customerId });
    };

    onMounted(() => {
        getAutoAssignPromotionCodes(baseUrl);
    });

    const reloadPage = () => {
        window.location.reload();
    };

</script>

<template>
    <div v-if="loadingAutoAssignPromotionCodes && autoAssignPromotionCodes.data.length === 0">
        <ListSkeleton :number-of-items="2"></ListSkeleton>
    </div>
    <div v-else-if="autoAssignPromotionCodes.data.length > 0" class="space-y-4">
        <header-bar title="Auto-generated promotion codes" size="xsmall">
            <template v-if="autogeneratePromotionCodeBlueprintIsNotEmpty" #actions>
                <delete-autotogenerate-promotion-code-blueprint
                    :autogenerate-promotion-code-blueprint="autogeneratePromotionCodeBlueprint"
                    :coupon="coupon"
                    :program="program"
                    @deletedSuccessfully="reloadPage"
                />
            </template>
        </header-bar>
        <div class="space-y-4">
            <div v-if="autogeneratePromotionCodeBlueprintIsNotEmpty" class="bg-gray-50 rounded rounded-b-none p-6">
                <ul class="flex-1 grid grid-cols-4 gap-y-4 gap-x-4 text-sm">
                    <li class="flex flex-col gap-1.5">
                        <span class="text-sm text-gray-500">First-time order only</span>
                        <span class="font-semibold">{{autogeneratePromotionCodeBlueprint['first_time_order'] ? 'Yes' : 'No' }}</span>
                    </li>
                    <li class="flex flex-col gap-1.5">
                        <span class="text-sm text-gray-500">Expires</span>
                        <span class="font-semibold">{{autogeneratePromotionCodeBlueprint['expiration_date_value'] ?? '-' }}</span>
                    </li>
                    <li class="flex flex-col gap-1.5">
                        <span class="text-sm text-gray-500">Min. order amount</span>
                        <span class="font-semibold">{{autogeneratePromotionCodeBlueprint['minimum_order_value'] ?? '-' }}</span>
                    </li>
                    <li class="flex flex-col gap-1.5">
                        <span class="text-sm text-gray-500">Redemption limit</span>
                        <span class="font-semibold">{{autogeneratePromotionCodeBlueprint['redemption_times_value'] ?? '-' }}</span>
                    </li>
                    <li class="flex flex-col gap-1.5">
                        <span class="text-sm text-gray-500">Autogeneration</span>
                        <span class="font-semibold">{{autogeneratePromotionCodeBlueprint['auto_generate_promotion_code'] ? 'On' : 'Off' }}</span>
                    </li>
                </ul>
            </div>
            <div class="border rounded divide-y">
                <table class="min-w-full">
                    <thead class="border-b">
                    <tr class="h-1">
                        <th :colspan="5">
                            <progress-bar v-if="loadingAutoAssignPromotionCodes"/>
                        </th>
                    </tr>
                    <tr>
                        <th class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Promotion code</th>
                        <th class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Redemptions</th>
                        <th class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Assigned</th>
                        <th class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Status</th>
                        <th v-if="coupon.sync_provider" class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Synced</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="divide-y">
                        <tr v-for="promotionCode in autoAssignPromotionCodes.data">
                        <td class="py-2 px-6 text-sm">
                            <div class="font-semibold">{{ promotionCode['code'] }}</div>
                        </td>
                        <td class="py-2 px-6 text-sm">{{ promotionCode['times_redeemed']  }} / {{ promotionCode['redemption_times_status'] === '0' ? '&#8734' : promotionCode['redemption_times_value'] }}</td>
                        <td class="py-2 px-6 text-sm space-x-2">
                            <a v-if="promotionCode['coupon_specific_partners']"
                               :href="getPartnerLink(promotionCode['coupon_specific_partners']?.['id'])"
                               class="font-medium text-sm text-gray-900 hover:text-teal-600">
                                {{ promotionCode['coupon_specific_partners']?.['full_name'] }}
                            </a>
                            <a v-if="promotionCode['coupon_specific_customers']"
                               :href="getReferralLink(promotionCode['coupon_specific_customers']?.['id'])"
                               class="font-medium text-sm text-gray-900 hover:text-teal-600">
                                {{ promotionCode['coupon_specific_customers']?.['customer_full_name'] }}
                            </a>
                            <template v-else>
                                —
                            </template>
                        </td>
                        <td class="py-2 p-6 text-sm whitespace-nowrap">
                            <badge v-if="promotionCode['active']"
                                type="success"
                                size="small"
                                title="Active"
                            ></badge>
                            <badge v-else
                                type="warning"
                                size="small"
                                title="Not active"
                            ></badge>
                        </td>
                        <td v-if="coupon.sync_provider" class="p-6 text-sm">
                            <div v-html="getSyncProviderStatus(promotionCode['sync_provider_id'])"></div>
                        </td>
                        <td class="py-2 px-6">
                            <div class=" flex items-center justify-end">
                                <promotion-code-modal
                                    :promotion-code="promotionCode"
                                    :program="program"
                                    :coupon-id="coupon['id']"
                                    promotion-code-action="edit"
                                ></promotion-code-modal>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <SimplePagination :pagination="autoAssignPromotionCodes" @fetchData="getAutoAssignPromotionCodes"></SimplePagination>
            </div>
        </div>
    </div>
</template>

