import {ref} from "vue";
import useFiltersAndPagination from '../../../js/state/common/filters-and-pagination'

export default function usePayouts() {

    const updatingPayout = ref(false)
    const payoutError = ref(null)

    const { fetchDataFunction, fetchDataBaseUrl,filters, search: searchQuery } = useFiltersAndPagination()

    const updatePayout = async (programId, data) => {
        if (updatingPayout.value) {
            return
        }

        updatingPayout.value = true
        return axios.patch('/app_int/programs/' + programId + '/payouts/payout', data)
            .then(response => {
                if (response.status === 200) {
                    updatingPayout.value = false
                    fetchDataFunction.value(fetchDataBaseUrl.value, {...filters.value, search: searchQuery.value})
                }
            })
            .catch(error => {
            })
            .finally(() => updatingPayout.value = false);
    }

    const rejectPayoutReward = async (programId, payoutId, data) => {
        if (updatingPayout.value) {
            return
        }

        updatingPayout.value = true
        payoutError.value = null

        return axios.patch('/app_int/programs/' + programId + '/payouts/' + payoutId + '/rewards/reject', data)
            .then(response => {
                if (response.status === 200) {
                    updatingPayout.value = false
                    fetchDataFunction.value?.(fetchDataBaseUrl.value, {...filters.value, search: searchQuery.value})
                }
            })
            .catch(error => {
                payoutError.value = error.response?.data?.message || 'Failed to reject reward.'
            })
            .finally(() => updatingPayout.value = false);
    }

    return {updatingPayout, updatePayout, rejectPayoutReward, payoutError}
}
