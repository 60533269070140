<script setup>
import {reactive, ref} from "vue";

    const props = defineProps({
        programId: {
            type: [Number, String],
            required: true,
        },
        realtimeAnalyticsEnabled: {
            type: Boolean,
            required: true,
        },
    });

    const showNotification = ref(false);
    const notificationTitle = ref('');
    const notificationType = ref('');
    const notificationMessage = ref('');

    const isRealtimeAnalyticsEnabled = ref(props.realtimeAnalyticsEnabled);

    const updateToggleState = () => {
        axios.post(
            route('app_int.analytics.real-time.real-time-toggle', {program: props.programId}),
            {realtimeAnalyticsEnabled: isRealtimeAnalyticsEnabled.value}
        ).then( response => {
            if (response.data.status === 1) {
                showNotification.value = true;
                notificationTitle.value = 'Done';
                notificationType.value = 'success';
                notificationMessage.value = 'Real-time analytics tracking has been ' + (isRealtimeAnalyticsEnabled.value ? 'enabled' : 'disabled') + ' for your program.';
            }
            else {
                showNotification.value = true;
                notificationTitle.value = 'Error';
                notificationType.value = 'error';
                notificationMessage.value = 'There was an error toggling real-time analytics tracking for your program.';
            }
        })
        .catch( error => {
            console.log(error);
        });
    };
</script>

<template>
    <div class="flex justify-between items-center">
        <toggle v-model="isRealtimeAnalyticsEnabled" @update:model-value="updateToggleState"></toggle>
        <span class="flex flex-col flex-grow" id="availability-label">
            <span class="text-sm font-medium text-gray-900">
                Enable comprehensive visitor analytics tracking for your website
            </span>
            <span class="text-sm text-gray-500">
                Gain valuable insights into your website's performance, including detailed data on both referral and non-referral visits.
            </span>
        </span>
    </div>
    <notification
        :show="showNotification"
        :title="notificationTitle"
        :type="notificationType"
        class="mb-20"
        @notificationClosed="showNotification = false"
        whitespace="whitespace-normal"
    >
        <template #message>
            {{ notificationMessage }}
        </template>
    </notification>
</template>

