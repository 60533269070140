<template>

    <div class="bg-white rounded overflow-hidden border" v-if="isConfigNew">
        <div class="flex justify-center items-center text-center p-20">
            <div>
                <div class="flex justify-center items-center p-4">
                    <icon name="currency" size="24"/>
                </div>
                <h2 class="font-semibold text-lg mb-2">Intermediary Payouts by Partnero</h2>
                <p class="text-gray-500 text-sm mb-4">
                    Let Partnero manage all your partner payouts seamlessly. Set up intermediary payments with ease and
                    enjoy a single, consolidated charge.
                </p>
                <btn @click="setupPayouts">Set up payouts</btn>
            </div>
        </div>
    </div>

    <div class="space-y-6" v-else>
        <div class="bg-white border rounded p-6 flex justify-between items-center gap-4">

            <div class="space-y-4">
                <header-bar title="Intermediary Payouts" size="xsmall"></header-bar>
                <toggle v-model="featureEnabled"
                        @click="toggleFeature"
                        :disabled="featureToggleDisabled"
                ></toggle>
                <span class="flex flex-col flex-grow" id="availability-label">
                    <span class="text-sm font-medium text-gray-900">Intermediary Payouts</span>
                    <span class="text-sm text-gray-500">Intermediary Payouts</span>
                </span>
            </div>

            <div class="text-sm space-y-2">
                <div>
                    <span class="font-semibold">{{ 'Status: ' }}</span>
                    <badge :title="featureEnabled ? 'Live' : 'Inactive'"
                           :type="featureEnabled ? 'success' : 'neutral'"
                    ></badge>
                </div>
                <div>
                    <span class="font-semibold">{{ 'Schedule: ' }}</span>
                    <span
                        v-if="scheduleConfig.frequencyType === 'days' && scheduleConfig.frequencyValueDays?.length > 0"
                        class="text-gray-500">
{{
                            'Every ' + scheduleConfig.frequencyValueDays.map(day => day.charAt(0).toUpperCase() + day.slice(1)).join(', ')
                        }}
</span>
                    <span v-else-if="scheduleConfig.frequencyType === 'every_week' && scheduleConfig.frequencyValue"
                          class="text-gray-500">{{
                            'Every week on ' + scheduleConfig.frequencyValue.charAt(0).toUpperCase() + scheduleConfig.frequencyValue.slice(1)
                        }}</span>
                    <span
                        v-else-if="scheduleConfig.frequencyType === 'every_month' && scheduleConfig.frequencyValue"
                        class="text-gray-500">{{
                            'Every month on ' + scheduleConfig.frequencyValue.charAt(0).toUpperCase() + scheduleConfig.frequencyValue.slice(1)
                        }}</span>
                    <span v-else class="text-gray-500">--</span>
                </div>
                <div>
                    <span class="font-semibold">Min amount:</span> <span
                    class="text-gray-500">{{ scheduleConfig.minimumPayoutAmount ?? 'n/a' }}</span>
                    <span>∙ <span class="font-semibold">Max amount:</span> <span
                        class="text-gray-500">{{ scheduleConfig.maximumPayoutAmount ?? 'n/a' }}</span></span>
                </div>
            </div>

            <btn @click="setupPayouts">Manage</btn>
        </div>
    </div>

    <modal title="Intermediary Payouts by Partnero" size="large" name="paypal_automation_settings_modal" :show="false">
        <template #body>

            <div class="space-y-8">

                <div class="text-sm text-gray-500">
                    Intermediary Payouts simplify payouts by automating partner payments. Once set up, Partnero handles all payouts and charges you a single consolidated invoice, saving time and reducing accounting hassles.
                </div>

                <div class="space-y-4">

                    <alert type="error"
                           v-if="frontendValidationMessages.length > 0">
                        <ul>
                            <li v-for="(error) in frontendValidationMessages">
                                {{ error }}
                            </li>
                        </ul>
                    </alert>

                    <div class="grid md:grid-cols-2 col-span-2"
                         :class="{'gap-8 gap-y-6': scheduleConfig.frequencyType !== 'days'}"
                    >
                        <div>
                            <field-label title="Payout frequency"></field-label>
                            <select v-model="scheduleConfig.frequencyType"
                                    class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                                    @change="resetFrequencyValues"
                            >
                                <option :value="null">Select frequency</option>
                                <option value="days">Every day</option>
                                <option value="every_week">Every week</option>
                                <option value="every_month">Every month</option>
                            </select>
                        </div>
                        <div
                            v-if="scheduleConfig.frequencyType === 'every_week' || scheduleConfig.frequencyType === 'every_month'">
                            <div v-if="scheduleConfig.frequencyType === 'every_week'">
                                <field-label title="Day of the week"></field-label>
                                <select
                                    class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                                    v-model="scheduleConfig.frequencyValue"
                                >
                                    <option :value="null">Select a day</option>
                                    <option v-for="day in dayList" :key="day.value" :value="day.value">{{
                                            day.label
                                        }}
                                    </option>
                                </select>
                            </div>
                            <div v-else>
                                <field-label title="Day of the month"></field-label>
                                <select
                                    class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                                    v-model="scheduleConfig.frequencyValue"
                                >
                                    <option :value="null">Select a day</option>
                                    <option value="1">1st</option>
                                    <option value="2">2nd</option>
                                    <option value="3">3rd</option>
                                    <option value="4">4th</option>
                                    <option value="5">5th</option>
                                    <option value="6">6th</option>
                                    <option value="7">7th</option>
                                    <option value="8">8th</option>
                                    <option value="9">9th</option>
                                    <option value="10">10th</option>
                                    <option value="11">11th</option>
                                    <option value="12">12th</option>
                                    <option value="13">13th</option>
                                    <option value="14">14th</option>
                                    <option value="15">15th</option>
                                    <option value="16">16th</option>
                                    <option value="17">17th</option>
                                    <option value="18">18th</option>
                                    <option value="19">19th</option>
                                    <option value="20">20th</option>
                                    <option value="21">21st</option>
                                    <option value="22">22nd</option>
                                    <option value="23">23rd</option>
                                    <option value="24">24th</option>
                                    <option value="25">25th</option>
                                    <option value="26">26th</option>
                                    <option value="27">27th</option>
                                    <option value="28">28th</option>
                                    <option value="last_day">last day of the month</option>
                                    <option value="29">29th (not available in all months)</option>
                                    <option value="30">30th (not available in all months)</option>
                                    <option value="31">31st (not available in all months)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="scheduleConfig.frequencyType === 'days'"
                         class="grid md:grid-cols-5 col-span-2 gap-2">
                        <div v-for="day in dayList" :key="day.value"
                             class="flex justify-start items-center"
                        >
                            <toggle
                                :model-value="scheduleConfig.frequencyValueDays.includes(day.value)"
                                @update:modelValue="updateFrequencyDays(day.value)"
                            ></toggle>
                            <div class="text-sm font-medium text-gray-900">
                                {{ day.label }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid md:grid-cols-2 col-span-2 gap-8 gap-y-6">
                    <text-field v-model="scheduleConfig.minimumPayoutAmount" label="Minimum amount (optional)"
                                type="number">
                        <template #note>
                            The scheduled payout will not initiate if the minimum total commission amount is not
                            reached.
                        </template>
                    </text-field>
                    <text-field v-model="scheduleConfig.maximumPayoutAmount" label="Maximum amount (optional)"
                                type="number">
                        <template #note>
                            Scheduled payouts will be stopped if the total commission amount from all affiliates
                            surpasses the maximum limit.
                        </template>
                    </text-field>
                </div>

                <div class="space-y-4">

                    <div>
                        <label class="block text-sm leading-5">Fund source</label>
                        <small class="text-gray-500">
                            Provide the fund source that will be pre-charged before processing payouts to partners.
                        </small>
                    </div>

                    <charge-option-stripe
                        :program="props.program"
                        :config="config.charge_option_stripe"
                        :notification-modal="notificationModal"></charge-option-stripe>

                </div>

                <div class="space-y-2">
                    <div class="flex justify-start items-start space-x-2">
                        <input type="checkbox"
                               class="h-4 w-4 cursor-pointer text-teal-600 border-gray-300 rounded-sm focus:ring-teal-100"
                               id="terms-auto-payout"
                               v-model="scheduleConfig.partneroTosAccepted"
                        >
                        <label class="text-sm text-gray-500 cursor-pointer" for="terms-auto-payout">
                            I acknowledge that Partnero is not responsible for unverified payouts that are approved and paid automatically. I also understand that an additional Partnero fee is applied to the payout amount.
                        </label>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <btn type="primary" :loading="loading.savingConfig"
                 :disabled="loading.savingConfig || !scheduleConfig.partneroTosAccepted"
                 @click="saveSettings">Save
            </btn>
        </template>
    </modal>

    <notification
        :show="notificationModal.show"
        :title="notificationModal.title"
        :type="notificationModal.type"
        whitespace="whitespace-normal"
        class="mb-20"
        @notificationClosed=""
    >
        <template #message>{{ notificationModal.message }}</template>
    </notification>
</template>
<script setup>

import {ref, onMounted, inject} from "vue";
import chargeOptionStripe from './intermediaryPayouts/ChargeOptionStripe.vue';
import FieldLabel from "../../../../elements/forms/FieldLabel";

const props = defineProps({
    program: {
        type: [String, Number],
        required: true
    },
    config: {
        type: Object,
        required: true
    }
})

const config = ref(props.config);

const isConfigNew = ref(config.value.is_config_new ?? false);
const featureEnabled = ref(config.value.feature_enabled ?? false);
const featureToggleDisabled = ref(config.value.feature_can_be_enabled ?? false);
const notificationModal = ref({
    show: false,
    title: null,
    type: 'success',
    message: null,
});

const emitter = inject('emitter');
const loading = ref({
    savingConfig: false
});
const frontendValidationMessages = ref([]);
const scheduleConfig = ref({
    featureEnabled: props.config.feature_enabled ?? false,
    maximumPayoutAmount: props.config.schedule_config.maximum_payout_amount ?? null,
    minimumPayoutAmount: props.config.schedule_config.minimum_payout_amount ?? null,
    frequencyType: props.config.schedule_config.frequency_type ?? null,
    frequencyValue: props.config.schedule_config.frequency_value ?? null,
    frequencyValueDays: props.config.schedule_config.frequency_value_days ?? [],
    partneroTosAccepted: props.config.schedule_config.partnero_tos_accepted === '1',
});
const dayList = [
    {value: 'sunday', label: 'Sunday'},
    {value: 'monday', label: 'Monday'},
    {value: 'tuesday', label: 'Tuesday'},
    {value: 'wednesday', label: 'Wednesday'},
    {value: 'thursday', label: 'Thursday'},
    {value: 'friday', label: 'Friday'},
    {value: 'saturday', label: 'Saturday'},
];

const saveSettings = () => {

    if (loading.value.savingConfig === true || !scheduleConfig.value.partneroTosAccepted) {
        return;
    }
    loading.value.savingConfig = true;
    frontendValidationMessages.value = [];

    const data = {
        new_config: isConfigNew.value,
        frequency_type: scheduleConfig.value.frequencyType,
        frequency_value: scheduleConfig.value.frequencyValue,
        maximum_amount: scheduleConfig.value.maximumPayoutAmount,
        minimum_amount: scheduleConfig.value.minimumPayoutAmount,
        partnero_tos_accepted: scheduleConfig.value.partneroTosAccepted
    }

    if (scheduleConfig.value.frequencyType === 'days') {
        data.frequency_value = scheduleConfig.value.frequencyValueDays.join(',');
    }

    axios.post(route('app_int.intermediary_payouts.schedule.settings', {program: props.program}), data)
        .then((response) => {
            const status = parseInt(response.data.status || -1);
            if (status === 1) {
                isConfigNew.value = false;

                if (response.data?.data?.feature_enabled ?? false) {
                    featureEnabled.value = true;
                }

                emitter.emit('modal-off', 'paypal_automation_settings_modal')
            }
        })
        .catch((error) => {
            const errors = error.response?.data?.errors || {};
            Object.entries(errors).forEach(([key, value]) => {
                frontendValidationMessages.value.push(value[0]);
            });
        })
        .finally(() => {
            loading.value.savingConfig = false;
        });
}

const resetFrequencyValues = () => {
    scheduleConfig.value.frequencyValue = null;
    scheduleConfig.value.frequencyValueDays = [];
}

const updateFrequencyDays = (day) => {
    if (scheduleConfig.value.frequencyValueDays.includes(day)) {
        scheduleConfig.value.frequencyValueDays.splice(scheduleConfig.value.frequencyValueDays.indexOf(day), 1);
    } else {
        scheduleConfig.value.frequencyValueDays.push(day);
    }
}

const setupPayouts = () => {
    emitter.emit('modal-on', 'paypal_automation_settings_modal')
}

const toggleFeature = () => {
    axios.post(route('app_int.intermediary_payouts.schedule.toggle_feature', {program: props.program}), {
        feature_enabled: featureEnabled.value
    }).then((response) => {
        const status = parseInt(response.data.status || -1);
        if (status === 1) {
            featureEnabled.value = response.data.enabled;

            notificationModal.value.show = true;
            notificationModal.value.type = 'success';
            notificationModal.value.title = 'Payouts status changed';
            notificationModal.value.message = featureEnabled.value ? 'Payouts successfully enabled' : 'Payouts successfully disabled';
        }
    }).catch((error) => {
        const errorMessage = error.response.data?.errors?.feature_enabled[0] || false;
        if (errorMessage) {
            notificationModal.value.show = true;
            notificationModal.value.type = 'warning';
            notificationModal.value.title = 'Payouts status cannot be changed';
            notificationModal.value.message = error.response.data.errors.feature_enabled[0];
        }
    });
};

onMounted(() => {

});


</script>

<style scoped></style>
