<script setup>
    import {useAnalyticsRealTimeStore} from "../../../../../../stores/app/programs/analytics/AnalyitcsRealTimeStore";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5map from "@amcharts/amcharts5/map";
    import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

    import {computed, onBeforeUnmount, onMounted, ref, shallowRef, watch} from "vue";

    const analyticsRealTimeStore = useAnalyticsRealTimeStore();

    const showFullCountriesList = ref(false);

    const mostVisitsCountries = computed(() => {
        return analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries?.countries?.filter((country) => {
            return country.country_code !== null
        }).slice(0, 8);
    });

    const preparedData = computed(() => {
        return analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries?.countries?.filter((country) => {
            return country.country_code !== null
        }).reduce((acc, country) => {
            acc.push({
                id: country.country_code,
                value: (country.visitors || 0)
            });
            return acc;
        }, []);
    });

    let mapData = preparedData.value;

    const chartdiv = shallowRef(null);

    let root;

    const chart = shallowRef(null);


    onMounted(() => {
        instantiateMap();
    })

    watch(preparedData, (newVal, oldVal) => {
        if (newVal !== oldVal) {
            if (chart.value) {
                mapData = newVal;
                chart.value.series.getIndex(0).data.setAll(mapData);
            }
        }
    })

    const instantiateMap = () => {

        // Create root
        root = am5.Root.new(chartdiv.value);

        // Set themes
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create chart
        const chartObject = root.container.children.push(
            am5map.MapChart.new(root, {
                panX: "rotateX",
                projection: am5map.geoNaturalEarth1()
            })
        );

        // Create polygon series
        var polygonSeries = chartObject.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow,
                valueField: "value",
                calculateAggregates: true,
                fill: am5.color(0xe9e9e9), // no data color
            })
        );

        polygonSeries.mapPolygons.template.setAll({
            tooltipText: "{name}: {value}"
        });

        polygonSeries.set("heatRules", [{
            target: polygonSeries.mapPolygons.template,
            dataField: "value",
            min: am5.color(0x2DD4BF),  // heat rule min color
            max: am5.color(0x15837B),  // heat rule max color
            key: "fill"
        }]);

        polygonSeries.mapPolygons.template.states.create("hover", {
            fill: am5.color(0xaaaaaa) // hover country color
        });

        if (mapData && mapData.length > 0) {
            polygonSeries.data.setAll(mapData);
        }

        chart.value = chartObject;
    }

    onBeforeUnmount(() => {
        if (root) {
            root.dispose();
        }
    });

</script>

<template>
    <div class="h-full grid md:grid-cols-3 gap-8">
            <div ref="chartdiv"
                 class="h-80 col-span-2"
            >
            </div>
            <div class="grid content-between h-80">
                <div class="flex flex-row custom-scrollbar flex flex-row max-h-full mb-1 overflow-x-hidden overflow-y-auto pr-1">
                    <div class="flex-1">
                        <ul v-if="showFullCountriesList" class="space-y-0.5">
                            <li v-for="(countryData, index) in analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries.countries"
                                :key="'countries-' + index"
                                class="flex overflow-hidden -mx-4 md:-mx-5 transition-all relative"><a
                                class="grid-cols-4 lg:grid-cols-5 relative rounded-sm z-20 grid text-sm gap-1 px-4 md:px-5 py-1.5 border border-transparent hover:border-black/10 dark:hover:border-white/10 hover:bg-white hover:dark:bg-gray-900 cursor-pointer overflow-hidden whitespace-nowrap w-full transition-all"><span
                                class="col-span-3 lg:col-span-4 truncate overflow-hidden">
                                <div class="inline">
                                    <span>{{ countryData.country || 'Unknown' }}</span>
                                </div>
                            </span>
                                <!---->
                                <span class="col-span-1 text-right">
                                    {{ countryData.visitors + ' / ' + countryData.referred_visitors }}
                                </span>
                            </a>
                                <div class="left-0 absolute z-10 bg-teal-500/20 bottom-0 top-0 rounded-sm"
                                     style="width: 12.1699%;">
                                </div>
                            </li>
                        </ul>
                        <ul v-else class="space-y-0.5">
                            <li v-for="(countryData, index) in mostVisitsCountries"
                                :key="'countries-' + index"
                                class="flex overflow-hidden -mx-4 md:-mx-5 transition-all relative"><a
                                class="grid-cols-4 lg:grid-cols-5 relative rounded-sm z-20 grid text-sm gap-1 px-4 md:px-5 py-1.5 border border-transparent hover:border-black/10 dark:hover:border-white/10 hover:bg-white hover:dark:bg-gray-900 cursor-pointer overflow-hidden whitespace-nowrap w-full transition-all"><span
                                class="col-span-3 lg:col-span-4 truncate overflow-hidden"><div
                                class="inline"><span>{{ countryData.country || 'Unknown' }}</span></div></span><!----><span
                                class="col-span-1 text-right">{{ countryData.visitors + ' / ' + countryData.referred_visitors }}</span></a>
                                <div class="left-0 absolute z-10 bg-teal-500/20 bottom-0 top-0 rounded-sm"
                                     style="width: 12.1699%;"></div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="analyticsRealTimeStore.getAnalyticsRealTimeStatsChartAndCountries.countries.length > 8">
                    <button @click="showFullCountriesList = !showFullCountriesList"
                            class="border dark:bg-gray-800 dark:hover:bg-gray-700 flex hover:bg-gray-200 justify-center px-2 py-1 rounded text-sm w-full">
                        {{ showFullCountriesList ? 'Show most visits' : 'Show full list' }}
                        <icon class="text-gray-500 dark:text-gray-400 ml-2"
                           :name="showFullCountriesList ? 'arrow-up' : 'arrow-down'">
                        </icon>
                    </button>
                </div>
            </div>
        </div>
</template>

<style scoped lang="scss">
#map-stats-container {
    height: 23.5rem;
}
</style>
