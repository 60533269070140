<template>
    <modal title="Reject reward" size="small" name="reject-payout-reward-modal" @modalClose="handleModalClose">
        <template #toggle>
            <btn type="light" size="pill" class="whitespace-nowrap">
                Reject
            </btn>
        </template>
        <template #body>
            <div class="space-y-4">
                <div class="text-sm text-gray-500 mb-6">
                    Do you really want to reject this reward?
                </div>
                <div>
                    <label class="block text-sm text-gray-900 mb-1.5">Reject reason</label>
                    <textarea
                        v-model="rejectionReason"
                        name="reject_reason"
                        :maxlength="maxRejectReasonsLength"
                        class="transition-shadow block w-full rounded focus:ring-0 py-2 px-3 text-sm border-gray-300 focus:border-gray-400"
                    >
                        {{ defaultRejectReason }}
                    </textarea>
                </div>
                <alert v-if="payoutError" type="error">{{ payoutError }}</alert>
            </div>
        </template>
        <template #footer>
            <btn type="primary"
                 @click.native="rejectReward"
                 :loading="updatingPayout"
                 :disabled="updatingPayout"
            >
                Reject
            </btn>
        </template>
    </modal>
</template>

<script setup>
import { ref } from 'vue';
import usePayouts from "../../../../../composables/app/payouts";

// Define props
const props = defineProps({
    maxRejectReasonsLength: {
        default: 160
    },
    defaultRejectReason: {
        default: ''
    },
    programId: {
        required: true
    },
    payoutId: {
        required: true
    },
    rewardId: {
        required: true
    }
});

// Define emits
const emit = defineEmits(['payout-updated']);

// Setup state and composables
const rejectionReason = ref('');
const { updatingPayout, rejectPayoutReward, payoutError } = usePayouts();

// Method equivalent in setup
const rejectReward = () => {
    rejectPayoutReward(props.programId, props.payoutId, {
        reward_id: props.rewardId,
        reject_reason: rejectionReason.value
    }).finally(() => {
        emit('payout-updated');
    });
};

const handleModalClose = () => {
    rejectionReason.value = ''
}

</script>
