<template>
    <div class="grid grid-cols-4 flex gap-4 border rounded px-4 py-4">
        <div>
            <div class="flex relative rounded-md">
                <span
                    v-if="typeIsPercent"
                    class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">
                    %
                </span>
                <input
                    type="text"
                    v-model="commissionValue"
                    class="block w-full rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-0 focus:border-gray-400"
                    :class="{ 'rounded-l': !typeIsPercent }"
                    @input="updateCommissionValue($event.target.value)"
                    placeholder="e.g. 10"
                />
                <div class="flex items-center text-sm text-gray-500 ml-4">if</div>
            </div>
        </div>

        <select-field
            v-model="conditionElement"
            @input="updateConditionElement($event.target.value)"
        >
            <option value="">select</option>
            <option v-for="conditionElement in props.conditionElements" :value="conditionElement.key">
                {{ conditionElement.label }}
            </option>
        </select-field>

        <div v-if="conditionElement === 'first_sale'" class="flex items-center text-sm text-gray-500">
            from the customer.
        </div>

        <select-field
            v-if="conditionElement !== 'first_sale'"
            v-model="conditionType"
            @input="updateConditionType($event.target.value)"
        >
            <option value="">select</option>
            <option v-for="conditionType in props.conditionTypes" :value="conditionType.key">
                {{ conditionType.label }}
            </option>
        </select-field>

        <div class="flex items-center justify-end gap-2">
            <text-field
                type="text"
                v-model="conditionValue"
                v-if="conditionElement !== 'first_sale'"
                @input="updateConditionValue($event.target.value)"
                placeholder="e.g. 50"
            >
            </text-field>
            <btn
                v-if="['single_sale', 'first_sale'].includes(conditionElement) === false"
                :icon-name="accordionOpen === true ? 'arrow-up-s' : 'arrow-down-s'"
                @click="() => accordionOpen = !accordionOpen"
                type="secondary"
            >
            </btn>
            <btn
                icon-name="trash"
                type="secondary"
                @click="remove"
            >
            </btn>
        </div>

        <div v-if="['single_sale', 'first_sale'].includes(conditionElement) === false" v-show="accordionOpen === true" class="col-span-4 grid grid-cols-4 gap-4 flex items-center border-t pt-4">
            <div class="flex relative rounded">
                <div class="flex items-center text-sm text-gray-500 mr-4">Condition value period</div>
            </div>
            <select-field
                v-model="conditionValuePeriod"
                @input="updateConditionValuePeriod($event.target.value)"
            >
                <option value="all_time">all time</option>
                <option value="previous">previous</option>
            </select-field>
            <div v-if="conditionValuePeriod === 'previous'" class="flex relative rounded gap-1 col-span-2">
                <text-field
                    type="text"
                    v-model="conditionValuePeriodTimes"
                    @input="updateConditionValuePeriodTimes($event.target.value)"
                    class="w-1/3"
                    placeholder="e.g. 1"
                >
                </text-field>
                <select-field v-model="conditionValuePeriodType" @input="updateConditionValuePeriodType($event.target.value)">
                    <option value="">select</option>
                    <option value="months">Months</option>
                    <option value="years">Years</option>
                </select-field>
            </div>
        </div>
    </div>
</template>

<script setup>
import {defineProps, defineEmits, ref} from 'vue';

const props = defineProps({
    commissionValue: String,
    conditionElement: String,
    conditionType: String,
    conditionValue: String,
    conditionElements: Object,
    conditionTypes: Object,
    typeIsPercent: Boolean,
    conditionValuePeriod: String,
    conditionValuePeriodTimes: String,
    conditionValuePeriodType: String,
});

const commissionValue = ref(props.commissionValue);
const conditionElement = ref(props.conditionElement);
const conditionType = ref(props.conditionType);
const conditionValue = ref(props.conditionValue);

const conditionValuePeriod = ref(props.conditionValuePeriod || 'all_time');
const conditionValuePeriodTimes = ref(props.conditionValuePeriodTimes);
const conditionValuePeriodType = ref(props.conditionValuePeriodType);
const accordionOpen = ref(false)

const emit = defineEmits([
    'update:commissionValue', 
    'update:conditionElement', 
    'update:conditionType', 
    'update:conditionValue', 
    'update:conditionValuePeriod', 
    'update:conditionValuePeriodTimes', 
    'update:conditionValuePeriodType', 
    'remove'
]);

const updateCommissionValue = (value) => {
    emit('update:commissionValue', value);
};

const updateConditionElement = (value) => {
    emit('update:conditionElement', value);
};

const updateConditionType = (value) => {
    emit('update:conditionType', value);
};

const updateConditionValue = (value) => {
    emit('update:conditionValue', value);
};

const updateConditionValuePeriod = (value) => {
    emit('update:conditionValuePeriod', value);
};  

const updateConditionValuePeriodTimes = (value) => {
    emit('update:conditionValuePeriodTimes', value);
};

const updateConditionValuePeriodType = (value) => {
    emit('update:conditionValuePeriodType', value);
};

const remove = () => {
    emit('remove');
};
</script>

