<template>
    <div v-for="email_template in emailsListStore.getTemplates[templatesGroup]"
         class="rounded border bg-white"
    >
        <div class="flex justify-between py-4 px-5">
            <div class="flex items-center space-x-4">
                <svg
                    class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path
                        d="M20 7.238l-7.928 7.1L4 7.216V19h10v2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v9h-2V7.238zM19.501 5H4.511l7.55 6.662L19.502 5zM17.05 19.548a3.017 3.017 0 0 1 0-1.096l-1.014-.586 1-1.732 1.014.586c.278-.238.599-.425.95-.55V15h2v1.17c.351.125.672.312.95.55l1.014-.586 1 1.732-1.014.586a3.017 3.017 0 0 1 0 1.096l1.014.586-1 1.732-1.014-.586a2.997 2.997 0 0 1-.95.55V23h-2v-1.17a2.997 2.997 0 0 1-.95-.55l-1.014.586-1-1.732 1.014-.586zM20 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                </svg>
                <div class="flex-1 text-sm">
                    <div class="font-medium">{{ email_template.name }}</div>
                    <span class="text-gray-500 text-xs">{{ email_template.description }}</span>
                </div>
            </div>
            <div class="flex pl-6 space-x-4" v-if="email_template.mail">
                <button type="button" class="focus:outline-none" @click="emailsListStore.toggleEditMode(email_template)">
                    <svg
                        v-if="email_template.is_open_for_edit"
                        class="w-5 h-5 text-gray-500 hover:text-gray-900 text-teal-600 hover:text-teal-700"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                        height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                            d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                    </svg>
                    <svg v-else class="w-5 h-5 text-gray-500 hover:text-gray-900" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                            d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                    </svg>
                </button>
            </div>
        </div>
        <div class="py-8 px-8 bg-gray-50 bg-opacity-75 border-t space-y-6"
             v-if="email_template.is_open_for_edit && email_template.mail">
            <div class="flex lg:flex-row flex-col gap-8 lg:items-center">
                <div>
                    <div class="w-28 h-28 border rounded bg-cover bg-top group relative overflow-hidden">
                        <div
                            class="flex items-center justify-center absolute inset-0 opacity-0 p-4 group-hover:opacity-100 transition-opacity ease-in-out bg-gray-900 bg-opacity-25">
                            <div class="flex flex-col gap-2 w-full">

                                <modal :title="'Preview'" size="full" v-if="email_template.can_preview">
                                    <template #toggle>
                                        <div class="absolute inset-0 flex justify-center items-center cursor-pointer">
                                            <svg class="w-6 h-6 text-white" fill="currentColor"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                 height="24">
                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                <path
                                                    d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"/>
                                            </svg>
                                        </div>
                                    </template>
                                    <template #body>
                                        <div class="flex h-full">
                                            <iframe :src="email_template.urls.preview"
                                                    class="w-full bg-gray-50 "></iframe>
                                        </div>
                                    </template>
                                </modal>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-1 space-y-6">
                    <div class="space-y-4">
                        <div>
                            <label class="block mb-1.5 text-sm">
                                Email subject
                            </label>

                            <div class="flex gap-2">
                                <input
                                    v-on:keyup.enter.prevent="emailsListStore.saveChanges(email_template)"
                                    type="text" name="subject" placeholder="Enter email subject"
                                    v-model="email_template.mail.subject"
                                    class="block w-full rounded border-gray-300 transition-shadow sm:text-sm focus:border-gray-400 focus:ring-0">
                                <btn type="primary" @click="emailsListStore.saveChanges(email_template)"
                                     :disabled="emailsListStore.saveEmailUpdatesInProgress[email_template.slug]"
                                     :loading="emailsListStore.saveEmailUpdatesInProgress[email_template.slug]"
                                >
                                    Update
                                </btn>
                            </div>
                        </div>

                        <div class="flex flex-col sm:flex-row gap-2 sm:justify-start">
                            <div>
                                <btn type="primary"
                                     size="small"
                                     @click="emailsListStore.openEmailEdit(email_template)"
                                     v-if="email_template.can_edit"
                                     title="Edit template"
                                >
                                    Edit content
                                </btn>
                            </div>
                            <div>
                                <modal :title="'Send a test email'" size="small">
                                    <template #toggle>
                                        <btn type="secondary" size="small">Send a test email</btn>
                                    </template>
                                    <template #body>
                                        <div class="space-y-6">
                                            <div class="text-sm opacity-60">
                                                Send test email for <strong>{{ email_template.name }}</strong>
                                            </div>
                                            <div>
                                                <text-field label="Recipient email address" type="email" placeholder=""
                                                            v-model="emailsListStore.testEmailAddress"/>
                                            </div>
                                        </div>
                                    </template>
                                    <template #footer>
                                        <btn type="primary" @click="emailsListStore.sendTestEmail(email_template)"
                                             :disabled="emailsListStore.sendTestEmailInProgress" :loading="emailsListStore.sendTestEmailInProgress">Send
                                        </btn>
                                    </template>
                                </modal>
                            </div>
                        </div>

                        <div class="border rounded p-2 flex flex-col sm:flex-row sm:justify-between items-center bg-gray-100"
                             v-if="email_template.mail.has_draft">
                            <div class="text-xs">New version is available.</div>
                            <btn
                                size="pill"
                                @click="emailsListStore.publishDraft(templatesGroup, email_template)"
                                :disabled="emailsListStore.publishingEmailDraftInProgress[email_template.slug]"
                                :loading="emailsListStore.publishingEmailDraftInProgress[email_template.slug]"
                                title="Publish new version"
                            >
                                Publish
                            </btn>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useRevolvappEmailsListStore} from "../../../../stores/app/emails/revolvapp/RevolvAppEmaisListStore";

    let emailsListStore = useRevolvappEmailsListStore()

    defineProps({
        templatesGroup: {
            type: String,
            required: true
        }
    })

</script>
