<template>
    <slot :childScope="childScope" :childComputed="{ timezonesOptions, timeFormatsOptions }"
          :childMethods="{ selectCountry, updateCurrentTime, setTimezone, getTimezone, setTimeFormat, getTimeFormatString, getTimeFormat }"/>
</template>

<script>
import moment from "moment";

const defaultTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export default {
    props: {
        countriesWithState: [Array],
        initialCountry: [String],
        initialTimezone: {
            type: String,
            default: "UTF"
        },
        initialTimeFormat: {
            type: String,
            default: "0"
        },
        timezonesProp: {
            type: Array,
            default: () => []
        },
        timeFormatsProp: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            childScope: {
                showState: false,
                currentTime: null,
                offset: 0,
                timeFormat: null
            }
        }
    },
    computed: {
        timezonesOptions() {
            return this.timezonesProp.map(t => {
                return {
                    name: t.name_for_humans,
                    timezone: t.name,
                    offset_name: t.offset_name,
                    offset: t.offset,
                    value: t.id,
                }
            })
        },
        timeFormatsOptions() {
            return Object.keys(this.timeFormatsProp).map((key) => {
                const tf = this.timeFormatsProp[key]
                return {
                    name: moment().format(tf),
                    value: key,
                }
            })
        },
    },
    methods: {
        selectCountry(option) {
            this.childScope.showState = this.countriesWithState.includes(option.value)
        },
        updateCurrentTime() {
            const m = moment();
            m.utcOffset(this.childScope.offset);
            this.childScope.currentTime = m.format(this.getTimeFormatString());
        },
        setTimezone(option) {
            const timezone = this.timezonesOptions.filter((i) => {
                return i.value == option.value;
            }).pop();

            this.childScope.offset = timezone ? timezone.offset : 0;
            this.updateCurrentTime();
        },
        getTimezone() {
            // supports 95+% browsers and gives exact timezone
            // as opposed to trying to guess based on offset
            let tz;
            if (!this.initialTimezone) {
                try {
                    tz = Intl.DateTimeFormat().resolvedOptions().timeZone
                } catch (e) { // fallback
                    tz = 'UTC'
                }
            } else {
                tz = this.initialTimezone;
            }
            let r = this.timezonesOptions.filter((i) => {
                return i.timezone == tz
            }).pop();

            return r || {value: 0};
        },
        setTimeFormat(option) {
            this.childScope.timeFormat = option.value;
            this.updateCurrentTime();
        },
        getTimeFormatString() {
            return this.timeFormatsProp[this.childScope.timeFormat ?? this.initialTimeFormat] ?? defaultTimeFormat
        },
        getTimeFormat() {
            const tf = this.childScope.timeFormat ?? this.initialTimeFormat
            let r = this.timeFormatsOptions.filter((timeFormat) => {
                return timeFormat.value == tf
            }).pop();

            return r || {value: 0};
        }
    },
    mounted() {
        this.setTimezone(this.getTimezone());
        this.childScope.showState = this.countriesWithState.includes(this.initialCountry)
        window.setInterval(this.updateCurrentTime.bind(this), 1000)
    }
}
</script>
