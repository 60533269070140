<template>
    <div class="flex items-start">
        <div class="flex-shrink-0 w-10 h-10">
            <img class="w-10 h-10 rounded" src="/images/integrations/mailchimp-256x256.png"/>
        </div>
        <div class="ml-5 w-full">
            <div class="flex justify-between">
                <span class="text-sm font-semibold">
                    Mailchimp
                </span>
                <badge title="Connected" v-if="connected"></badge>
            </div>
            <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                Syncronize your partners with your Mailchimp account.
            </div>
            <div class="flex" v-if="!connected">
                <btn :href="config.urls.authorize" type="secondary" size="small" class="-mr-px focus:z-10">Authorize</btn>
            </div>
            <div class="flex gap-2" v-if="connected">

                <modal :title="'Mailchimp integration'" name="mailchimp_integration">
                    <template #toggle>
                        <btn type="secondary" size="small" class="-mr-px focus:z-10">Manage</btn>
                    </template>
                    <template #body>
                        <div class="space-y-8">
                            <div>
                                <label for="mailchimp_lists" class="flex text-sm mb-1.5 justify-between">
                                    Mailchimp list
                                    <div class="flex gap-2">
                                        <span v-if="loading_lists">Refreshing</span>
                                        <a @click="refreshLists">
                                            <svg class="w-3 h-3 inline-block" xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                 stroke-linejoin="round">
                                                <polyline points="1 4 1 10 7 10"></polyline>
                                                <polyline points="23 20 23 14 17 14"></polyline>
                                                <path
                                                    d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </label>
                                <div class="grid">
                                    <select id="mailchimp_lists" name="main_list" v-model="main_list" class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
                                        <option value="">Select a list</option>
                                        <option :value="list.id" v-for="list in config.lists">{{ list.name }}</option>
                                    </select>

                                    <small class="flex mt-2 leading-5 text-gray-500">
                                        Choose a list from your Mailchimp account to send your partners into.

                                        <modal :title="'Mailchimp list/audience create'" size="small" name="mailchimp_integration_new_list" class="text-gray-900">
                                            <template #toggle>
                                                <btn type="link" size="small" class="underline hover:no-underline">Create new list</btn>
                                            </template>
                                            <template #body>
                                                <div class="space-y-4">
                                                    <alert type="error" v-if="new_list_error">{{ new_list_error }}</alert>
                                                    <div class="space-y-8">
                                                        <div>
                                                            <label class="flex text-sm mb-1.5">Name</label>
                                                            <div class="flex gap-2">
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="Enter a name"
                                                                            @update:modelValue="newListName"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                            </div>
                                                        </div>
                                                        <label class="flex text-sm mb-0.5">Contact information</label>
                                                        <div class="space-y-5">
                                                            <div>
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="Enter company name"
                                                                            @update:modelValue="(value) => newListContact('company', value)"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                            </div>
                                                            <div class="flex gap-2">
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="Address"
                                                                            @update:modelValue="(value) => newListContact('address1', value)"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="City"
                                                                            @update:modelValue="(value) => newListContact('city', value)"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                            </div>
                                                            <div class="flex gap-2">
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="State"
                                                                            @update:modelValue="(value) => newListContact('state', value)"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="ZIP code"
                                                                            @update:modelValue="(value) => newListContact('zip', value)"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="Country"
                                                                            @update:modelValue="(value) => newListContact('country', value)"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label class="flex text-sm mb-1.5">Permission reminder</label>
                                                            <div class="flex gap-2">
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="Permission reminder"
                                                                            @update:modelValue="newListPermissionReminder"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                            </div>
                                                        </div>
                                                        <label class="flex text-md mb-0.5">Campaign defaults</label>
                                                        <div class="space-y-5">
                                                            <div>
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="Enter subject"
                                                                            @update:modelValue="(value) => newListCampaignDefaults('subject', value)"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                            </div>
                                                            <div class="flex gap-2">
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="From name"
                                                                            @update:modelValue="(value) => newListCampaignDefaults('from_name', value)"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                                <text-field type="text" class="flex-grow"
                                                                            placeholder="From email"
                                                                            @update:modelValue="(value) => newListCampaignDefaults('from_email', value)"
                                                                            v-on:keyup.enter.prevent="createNewList"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <template #footer>
                                                <btn :loading="loading_new_list" @click="createNewList">Create</btn>
                                            </template>
                                        </modal>
                                    </small>
                                </div>
                            </div>

                            <div class="grid gap-6">
                                <div>
                                    <div class="block text-tiny font-medium">Integrations settings</div>
                                    <small class="block leading-5 text-gray-500">Choose how your integration
                                        behaves.</small>
                                </div>
                                <div>
                                    <div class="flex justify-between items-center">
                                        <toggle name="integration_mailchimp_sync_approved_partners_only"
                                                :model-value="sync_approved_partners_only"
                                                @update:modelValue="updateApprovedOnly"></toggle>
                                        <span class="flex flex-col flex-grow">
                                                                <span class="text-sm font-medium text-gray-900">Send approved partners only</span>
                                                                <span class="text-sm text-gray-500">Only approved partners will be sent to your Mailchimp account.</span>
                                                            </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="flex justify-between items-center">
                                        <toggle name="integration_mailchimp_sync_marketing_partners_only"
                                                :model-value="sync_marketing_partners_only"
                                                @update:modelValue="updateMarketingOnly"></toggle>
                                        <span class="flex flex-col flex-grow">
                                                                <span class="text-sm font-medium text-gray-900">Send only partners with marketing permissions</span>
                                                                <span class="text-sm text-gray-500">Only partners who accept marketing permissions will be sent to your Mailchimp account.</span>
                                                            </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="flex justify-between items-center">
                                        <toggle name="integration_mailchimp_auto_partners_sync"
                                                :model-value="auto_partners_sync"
                                                @update:modelValue="updateAutoSync"></toggle>
                                        <span class="flex flex-col flex-grow">
                                                                <span class="text-sm font-medium text-gray-900">Partner synchronization</span>
                                                                <span class="text-sm text-gray-500">The data about partners will be updated everytime it changes.</span>
                                                            </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading" @click="update">Save</btn>
                    </template>
                </modal>

                <modal :title="'Mailchimp integration'" size="small" name="mailchimp_integration">
                    <template #toggle>
                        <btn type="secondary" size="small" class="-mr-px focus:z-10">Export</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <alert type="error" v-if="export_error">{{ export_error }}</alert>
                            <div class="text-sm text-gray-500">
                                Export current partners to your Mailchimp account.
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading" @click="exportPartners">Yes, export</btn>
                    </template>
                </modal>

                <modal :title="'Mailchimp integration'" size="small" name="mailchimp_integration">
                    <template #toggle>
                        <btn size="small" class="-mr-px focus:z-10 ml-auto">Disconnect</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <alert type="error" v-if="disconnect_error">{{ disconnect_error }}</alert>
                            <div class="text-sm text-gray-500">
                                Are you sure you wish to disconnect the Mailchimp integration?
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading" @click="disconnect">Disconnect</btn>
                    </template>
                </modal>
            </div>
        </div>

    </div>

</template>
<script>
export default {
    name: 'IntegrationMailchimp',
    props: ['integrations_config'],
    data() {
        return {
            loading: false,
            export_error: '',
            new_list: {
                name: '',
                contact: {
                    company: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                },
                permission_reminder: '',
                campaign_defaults: {
                    subject: '',
                    from_name: '',
                    from_email: ''
                },
            },
            new_list_error: '',
            disconnect_error: '',
            loading_lists: false,
            loading_new_list: false,
            show_create_new_list: false,
            config: this.integrations_config,
            connected: this.integrations_config.connected,
            main_list: this.integrations_config.main_list,
            auto_partners_sync: this.integrations_config.settings.auto_partners_sync ?? false,
            sync_approved_partners_only: this.integrations_config.settings.sync_approved_partners_only ?? false,
            sync_marketing_partners_only: this.integrations_config.settings.sync_marketing_partners_only ?? false,
        }
    },
    methods: {
        disconnect() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            axios
                .post(this.config.urls.disconnect, {
                    api_key: this.api_key,
                    server: this.server
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {

                        this.connected = false;
                        this.disconnect_error = false;

                        this.emitter.emit('modal-off', 'mailchimp_integration');
                    } else {
                        this.disconnect_error = 'Failed to disconnect';
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading = false);
        },
        update() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            axios
                .post(this.config.urls.update, {
                    main_list: this.main_list,
                    integration_mailchimp_auto_partners_sync: this.auto_partners_sync,
                    integration_mailchimp_sync_approved_partners_only: this.sync_approved_partners_only,
                    integration_mailchimp_sync_marketing_partners_only: this.sync_marketing_partners_only,
                })
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'mailchimp_integration');
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading = false);
        },
        refreshLists() {
            if (this.loading_lists) {
                return;
            }

            this.loading_lists = true;

            axios
                .get(this.config.urls.get_lists)
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.config.lists = response.data.lists;
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading_lists = false);
        },
        newListName(value) {
            this.new_list.name = value;
        },
        newListContact(key, value) {
            this.new_list.contact[key] = value;
        },
        newListCampaignDefaults(key, value) {
            this.new_list.campaign_defaults[key] = value;
        },
        newListPermissionReminder(value) {
            this.new_list.permission_reminder = value;
        },
        exportPartners() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            axios
                .post(this.config.urls.export)
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        this.export_error = false;
                        this.emitter.emit('modal-off', 'mailchimp_integration');
                    } else {
                        this.export_error = 'Export failed';
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading = false);
        },
        createNewList() {
            if (this.loading_new_list) {
                return;
            }

            this.loading_new_list = true;

            axios
                .post(this.config.urls.new_list, {
                    name: this.new_list.name,
                    contact: {
                        company: this.new_list.contact.company,
                        address1: this.new_list.contact.address1,
                        city: this.new_list.contact.city,
                        state: this.new_list.contact.state,
                        zip: this.new_list.contact.zip,
                        country: this.new_list.contact.country
                    },
                    permission_reminder: this.new_list.permission_reminder,
                    campaign_defaults: {
                        subject: this.new_list.campaign_defaults.subject,
                        from_name: this.new_list.campaign_defaults.from_name,
                        from_email: this.new_list.campaign_defaults.from_email,
                    }
                })
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.refreshLists();
                        this.emitter.emit('modal-off', 'mailchimp_integration_new_list');
                    }
                })
                .catch(error => {
                    this.new_list_error = error.response.data?.message || null
                })
                .finally(() => this.loading_new_list = false);
        },
        updateAutoSync(value) {
            this.auto_partners_sync = value;
        },
        updateApprovedOnly(value) {
            this.sync_approved_partners_only = value;
        },
        updateMarketingOnly(value) {
            this.sync_marketing_partners_only = value;
        }
    }
}
</script>
<style scoped>
</style>
