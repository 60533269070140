<template>
    <customer-activity-stats :customer-stats="filtersAndPaginationData.customer_stats" />

    <header-bar title="Transaction history" size="xsmall">
        <template #actions>
            <create-transaction-modal
                v-if="!customerDeletedAt && programType === 'affiliate'"
                :create-transaction-url="createTransactionUrl"
                :customer-key="customerKey"
            />
        </template>

    </header-bar>

    <filters-and-pagination
        :pagination-data="filtersAndPaginationData.customer_activities"
        :show-table-filter="false"
        :filterable-fields="filterFields"
        :data-exists="initialDataState"
        :loading-data="loadingFiltersAndPaginationData"
        model-name="transaction"
    >
        <customer-activity-table
            :customer-activities="filtersAndPaginationData?.customer_activities?.data"
            :program-type="programType"
            :table-headers="tableHeaders"
        />
    </filters-and-pagination>
</template>

<script>
import CustomerActivityStats from "./components/CustomerActivityStats";
import CustomerActivityTable from "./components/CustomerActivityTable";
import FiltersAndPagination from "../../filters-and-pagination/FiltersAndPagination";
import ReferralActivitiesDataTable from "../referral-activity/components/ReferralActivitiesDataTable";
import {onMounted, ref, toRef, watch} from "vue";
import useRecentActivities from "../../../../composables/common/filter-and-pagination-data";
import useFiltersAndPagination from "../../../../state/common/filters-and-pagination";
import CreateTransactionModal from "./CreateTransactionModal";
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";

export default {
    name: "CustomerActivityIndex",

    components: {
        CreateTransactionModal,
        CustomerActivityTable,
        CustomerActivityStats,
        FiltersAndPagination,
        ReferralActivitiesDataTable
    },

    props: {
        customerActivitiesUrl: {
            default: ''
        },
        customerActivitiesExportUrl: {
            default: ''
        },
        filterFields: {
            default: {}
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        },
        createTransactionUrl: {
            type: String,
            required: true
        },
        customerKey: {
            type: String,
            required: true
        },
        customerDeletedAt: {
            default: null
        },
        programType: {
            required: true
        },
        tableHeaders: {
            default: []
        }
    },

    setup(props) {
        const customerActivitiesUrl = toRef(props, 'customerActivitiesUrl')
        const customerActivitiesExportUrl = toRef(props, 'customerActivitiesExportUrl')
        const filterFields = toRef(props, 'filterFields')
        const { loadingFiltersAndPaginationData, filtersAndPaginationData, getFiltersAndPaginationData } = useRecentActivities()
        const { setDataBaseUrl, setFetchDataFunction } = useFiltersAndPagination()
        const { exportSetup, checkForPreviousExport } = useTableDataExporter()

        const initialDataState = ref(!!props.dataExists)

        onMounted( () => {
            setDataBaseUrl(customerActivitiesUrl.value)
            setFetchDataFunction(getFiltersAndPaginationData)

            exportSetup.value.exportModelNameSingular = 'customer activity'
            exportSetup.value.exportModelNamePlural = 'customer activities'
            exportSetup.value.exportUrl = customerActivitiesExportUrl.value
            checkForPreviousExport()
        })

        watch(filtersAndPaginationData, (newValue) => {
            // the first time the data is loaded, we want to set the initialDataState to true so we can show the table
            // use filters properly if returned data is empty, etc.
            if (newValue?.customer_activities?.data?.length) {
                initialDataState.value = true
            }
        })

        return {
            filtersAndPaginationData,
            loadingFiltersAndPaginationData,
            filterFields,
            initialDataState
        }
    }
}
</script>
