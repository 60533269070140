<template>
    <div class="flex items-start">
        <div class="flex-shrink-0 w-10 h-10">
            <img class="w-10 h-10 rounded" src="/images/integrations/mailerlite-256x256.png"/>
        </div>
        <div class="ml-5 w-full">
            <div class="flex justify-between">
                <span class="text-sm font-semibold">
                    MailerLite
                </span>
                <badge title="Connected" v-if="connected"></badge>
            </div>
            <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                Syncronize your partners with your MailerLite account.
            </div>
            <div class="flex" v-if="!connected">
                <modal :title="'MailerLite integration'" size="small" name="mailerlite_integration">
                    <template #toggle>
                        <btn type="secondary" size="small" class="-mr-px focus:z-10">Authorize</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <div class="text-red-500 text-sm" v-if="connect_error">{{ connect_error }}</div>
                            <div>
                                <text-field
                                    label="API key"
                                    field-id="api_key"
                                    name="api_key"
                                    :modelValue="api_key"
                                    type="password"
                                    :show_reveal_password_icon="true"
                                    placeholder="e.g. eyJ0eXAiOiJ...bGci"
                                    @update:modelValue="updateApiKey"
                                    autofocus="true"
                                    required="true">
                                    <template #note>
                                        To find your API key, log into your MailerLite account and navigate to the Integrations area. From there, access the MailerLite API section and follow the steps to create a new API key.
                                    </template>
                                </text-field>
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading" @click="connect">Connect</btn>
                    </template>
                </modal>
            </div>
            <div class="flex gap-2" v-if="connected">

                <modal :title="'MailerLite integration'" name="mailerlite_integration">
                    <template #toggle>
                        <btn type="secondary" size="small" class="-mr-px focus:z-10">Manage</btn>
                    </template>
                    <template #body>
                        <div class="space-y-8">
                            <div>
                                <label for="mailerlite_groups" class="flex text-sm mb-1.5 justify-between">
                                    MailerLite group
                                    <div class="flex gap-2">
                                        <span v-if="loading_groups">Refreshing</span>
                                        <a @click="refreshGroups">
                                            <svg class="w-3 h-3 inline-block" xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                 stroke-linejoin="round">
                                                <polyline points="1 4 1 10 7 10"></polyline>
                                                <polyline points="23 20 23 14 17 14"></polyline>
                                                <path
                                                    d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </label>
                                <div class="grid">
                                    <select id="mailerlite_groups" name="main_group" v-model="main_group" class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
                                        <option value="">Select a group</option>
                                        <option :value="group.id" v-for="group in config.groups">{{ group.name }}</option>
                                    </select>

                                    <small class="block mt-2 leading-5 text-gray-500">
                                        Choose a group from your MailerLite account to send your partners into.
                                        <a @click="show_create_new_group = true" class="text-gray-900 underline hover:no-underline">Create new
                                            group</a>
                                    </small>

                                    <div v-if="show_create_new_group" class="mt-4 bg-gray-50  border rounded p-4">
                                        <label class="flex text-sm mb-1.5">Create a new group at MailerLite</label>
                                        <div class="flex gap-2">
                                            <text-field type="text" class="flex-grow"
                                                        placeholder="Enter a name"
                                                        @update:modelValue="newGroupName"
                                                        v-on:keyup.enter.prevent="createNewGroup"/>
                                            <btn :loading="loading_new_group" @click="createNewGroup">Create</btn>
                                            <btn @click="show_create_new_group = false" type="link">Cancel</btn>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="grid gap-6">
                                <div>
                                    <div class="block text-tiny font-medium">Integrations settings</div>
                                    <small class="block leading-5 text-gray-500">Choose how your integration
                                        behaves.</small>
                                </div>
                                <div>
                                    <div class="flex justify-between items-center">
                                        <toggle name="integration_mailerlite_sync_approved_partners_only"
                                                :model-value="sync_approved_partners_only"
                                                @update:modelValue="updateApprovedOnly"></toggle>
                                        <span class="flex flex-col flex-grow">
                                                                <span class="text-sm font-medium text-gray-900">Send approved partners only</span>
                                                                <span class="text-sm text-gray-500">Only approved partners will be sent to your MailerLite account.</span>
                                                            </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="flex justify-between items-center">
                                        <toggle name="integration_mailerlite_sync_marketing_partners_only"
                                                :model-value="sync_marketing_partners_only"
                                                @update:modelValue="updateMarketingOnly"></toggle>
                                        <span class="flex flex-col flex-grow">
                                                                <span class="text-sm font-medium text-gray-900">Send only partners with marketing permissions</span>
                                                                <span class="text-sm text-gray-500">Only partners who accept marketing permissions will be sent to your MailerLite account.</span>
                                                            </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="flex justify-between items-center">
                                        <toggle name="integration_mailerlite_auto_partners_sync"
                                                :model-value="auto_partners_sync"
                                                @update:modelValue="updateAutoSync"></toggle>
                                        <span class="flex flex-col flex-grow">
                                                                <span class="text-sm font-medium text-gray-900">Partner synchronization</span>
                                                                <span class="text-sm text-gray-500">The data about partners will be updated everytime it changes.</span>
                                                            </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading" @click="update">Save</btn>
                    </template>
                </modal>

                <modal :title="'MailerLite integration'" size="small" name="mailerlite_integration">
                    <template #toggle>
                        <btn type="secondary" size="small" class="-mr-px focus:z-10">Export</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <alert type="error" v-if="export_error">{{ export_error }}</alert>
                            <div class="text-sm text-gray-500">
                                Export current partners to your MailerLite account.
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading" @click="exportPartners">Yes, export</btn>
                    </template>
                </modal>

                <modal :title="'MailerLite integration'" size="small" name="mailerlite_integration">
                    <template #toggle>
                        <btn size="small" class="-mr-px focus:z-10 ml-auto">Disconnect</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <alert type="error" v-if="disconnect_error">{{ disconnect_error }}</alert>
                            <div class="text-sm text-gray-500">
                                Are you sure you wish to disconnect the MailerLite integration?
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading" @click="disconnect">Yes, disconnect</btn>
                    </template>
                </modal>

            </div>
        </div>

    </div>

</template>
<script>
export default {
    name: 'IntegrationMailerLite',
    props: ['integrations_config'],
    data() {
        return {
            api_key: '',
            loading: false,
            export_error: '',
            connect_error: '',
            new_group_name: '',
            disconnect_error: '',
            loading_groups: false,
            loading_new_group: false,
            show_create_new_group: false,
            config: this.integrations_config,
            connected: this.integrations_config.api_key,
            main_group: this.integrations_config.main_group,
            auto_partners_sync: this.integrations_config.settings.auto_partners_sync ?? false,
            sync_approved_partners_only: this.integrations_config.settings.sync_approved_partners_only ?? false,
            sync_marketing_partners_only: this.integrations_config.settings.sync_marketing_partners_only ?? false,
        }
    },
    methods: {
        connect() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            axios
                .post(this.config.urls.connect, {
                    api_key: this.api_key
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {

                        this.connected = true;
                        this.connect_error = false;

                        this.emitter.emit('modal-off', 'mailerlite_integration');

                        this.config.groups = response.data.groups ?? [];
                    } else {
                        this.connect_error = 'Failed to connect';
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading = false);
        },
        disconnect() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            axios
                .post(this.config.urls.disconnect, {
                    api_key: this.api_key
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {

                        this.connected = false;
                        this.disconnect_error = false;

                        this.emitter.emit('modal-off', 'mailerlite_integration');
                    } else {
                        this.disconnect_error = 'Failed to disconnect';
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading = false);
        },
        update() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            axios
                .post(this.config.urls.update, {
                    main_group: this.main_group,
                    integration_mailerlite_auto_partners_sync: this.auto_partners_sync,
                    integration_mailerlite_sync_approved_partners_only: this.sync_approved_partners_only,
                    integration_mailerlite_sync_marketing_partners_only: this.sync_marketing_partners_only,
                })
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'mailerlite_integration');
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading = false);
        },
        refreshGroups() {
            if (this.loading_groups) {
                return;
            }

            this.loading_groups = true;

            axios
                .get(this.config.urls.get_groups)
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.config.groups = response.data.groups;
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading_groups = false);
        },
        newGroupName(value) {
            this.new_group_name = value;
        },
        exportPartners() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            axios
                .post(this.config.urls.export)
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        this.export_error = false;
                        this.emitter.emit('modal-off', 'mailerlite_integration');
                    } else {
                        this.export_error = 'Export failed';
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading = false);
        },
        createNewGroup() {
            if (this.loading_new_group || !this.new_group_name) {
                return;
            }

            this.loading_new_group = true;

            axios
                .post(this.config.urls.new_group, {group_name: this.new_group_name})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.refreshGroups();
                        this.show_create_new_group = false;
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading_new_group = false);
        },
        updateApiKey(value) {
            this.api_key = value;
        },
        updateAutoSync(value) {
            this.auto_partners_sync = value;
        },
        updateApprovedOnly(value) {
            this.sync_approved_partners_only = value;
        },
        updateMarketingOnly(value) {
            this.sync_marketing_partners_only = value;
        }
    }
}
</script>
<style scoped>
</style>
