<template>
    <filters-and-pagination
        :pagination-data="filtersAndPaginationData"
        :filterable-fields="filterFields"
        :data-exists="initialDataState"
        :loading-data="loadingFiltersAndPaginationData"
        model-name="coupon"
    >
        <coupon-data-table :coupons="filtersAndPaginationData.data" />
    </filters-and-pagination>
</template>

<script>
import FiltersAndPagination from "../../filters-and-pagination/FiltersAndPagination";
import {onMounted, ref, toRef, watch} from "vue";
import useRecentActivities from "../../../../composables/common/filter-and-pagination-data";
import useFiltersAndPagination from "../../../../state/common/filters-and-pagination";
import CouponDataTable from "./CouponDataTable";
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";

export default {
    name: "CouponIndex",
    components: {
        CouponDataTable,
        FiltersAndPagination,
    },
    props: {
        couponIndexUrl: {
            default: ''
        },
        couponExportUrl: {
            default: ''
        },
        rewardCouponsExistWithinProgram: {
            type: [Boolean, Number],
            default: false
        },
        thirdPartyCouponsExistWithinProgram: {
            type: [Boolean, Number],
            default: false
        },
        couponDeleteUrl: {
            default: ''
        },
        filterFields: {
            default: {}
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        }
    },

    setup(props) {
        const couponIndexUrl = toRef(props, 'couponIndexUrl')
        const couponDeleteUrl = toRef(props, 'couponDeleteUrl')
        const filterFields = toRef(props, 'filterFields')
        const { loadingFiltersAndPaginationData, filtersAndPaginationData, getFiltersAndPaginationData } = useRecentActivities()
        const { setDataBaseUrl, setFetchDataFunction } = useFiltersAndPagination()
        const { deleteSetup, exportSetup } = useTableDataExporter()

        const initialDataState = ref(!!props.dataExists)

        onMounted( () => {
            setDataBaseUrl(couponIndexUrl.value)
            setFetchDataFunction(getFiltersAndPaginationData)
            deleteSetup.value.deleteUrl = couponDeleteUrl.value
            deleteSetup.value.additionalData.rewardCouponsExistWithinProgram = props.rewardCouponsExistWithinProgram
            deleteSetup.value.additionalData.thirdPartyCouponsExistWithinProgram = props.thirdPartyCouponsExistWithinProgram
            exportSetup.value.exportModelNameSingular = 'coupon'
            exportSetup.value.exportModelNamePlural = 'coupons'
            exportSetup.value.showExportButton = false
        })

        watch(filtersAndPaginationData, (newValue) => {
            // the first time the data is loaded, we want to set the initialDataState to true so we can show the table
            // use filters properly if returned data is empty, etc.
            if (newValue?.data?.length) {
                initialDataState.value = true
            }
        })

        return {
            filtersAndPaginationData,
            loadingFiltersAndPaginationData,
            filterFields,
            initialDataState
        }
    }
}
</script>

