<template>
    <draggable-accordion
        :unique_id="unique_id"
        :edit="data.edit"
    >
        <template #title>{{ getResourceName(data.type) }}</template>
        <template #content>

            <div class="space-y-4">

                <div>
                    <label class="flex justify-between items-center mb-1.5 text-sm"> Title </label>
                    <div class="flex relative rounded">
                        <input
                            type="text"
                            v-model="data.title"
                            placeholder="Enter a title or leave it empty"
                            class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"/>
                    </div>
                </div>
                <div>

                    <text-editor
                        :text="data.description"
                        @input="(newValue) => { data.description = newValue }"
                    />

                </div>

                <ResourceFileUpload
                    :unique_id="unique_id"
                    :data="data.main_image ?? {}"
                    accept="image/*"
                    title="Icon"
                    name="main_image"
                    @uploaded="uploadedMainImage"
                    @deleted="deletedMainImage"
                    no_src="/images/upload-image.png"
                ></ResourceFileUpload>

                <toggle
                    title="Resize icon for retina display compatibility"
                    name="main_image_retina_resize"
                    size="medium"
                    v-model="data.main_image_retina_resize"
                    v-if="showMainImageResizeToggle"
                >
                </toggle>

                <div class="bg-white border rounded p-4" v-if="stringToComponent(data.type)">
                    <div class="border border-dashed rounded p-4 text-sm text-center mb-1.5"
                         v-if="!data?.contents?.length && showSubContentBlock(data.type)"
                    >
                        Click button below to start adding content or content blocks.
                    </div>
                    <component
                        :is="stringToComponent(data.type)"
                        :data="data"
                        :unique_id="data.unique_id"
                    />
                </div>
            </div>

        </template>
    </draggable-accordion>
</template>

<script setup>
import {ref} from 'vue';
import {getResourceName} from '../helpers';
import Text from './Text.vue';
import Link from './Link.vue';
import Faq from './Faq.vue';
import CTA from './CTA.vue';
import AvailableRewards from './AvailableRewards.vue';
import ContentBlock from './ContentBlock.vue';
import Image from './Image.vue';
import ResourceFileUpload from "./ResourceFileUpload.vue";

const props = defineProps({
    data: Object,
    unique_id: String,
});

props.data.main_image = props.data?.main_image ?? {};

const showMainImageResizeToggle = ref(false);

const uploadedMainImage = (data) => {
    toggleMainImageResizeToggle((data?.url?.length ?? 0) > 0);
};

const deletedMainImage = (data) => {
    toggleMainImageResizeToggle((data?.url?.length ?? 0) > 0);
};

const toggleMainImageResizeToggle = (on) => {
    showMainImageResizeToggle.value = on;
};

toggleMainImageResizeToggle((props.data.main_image?.url?.length ?? 0) > 0);

/**
 * Sole purpose of this function is to load component in <component :is="">
 * To give component a local name string you need 'components' option
 * This will serve as workaround for <script setup>
 * @link https://github.com/vuejs/rfcs/discussions/273
 *
 * @param  {string} type
 * @return {Object}
 */
const stringToComponent = (type) => {
    switch (type) {
        case 'text':
            return Text;
        case 'link':
            return Link;
        case 'faq':
            return Faq;
        case 'cta':
            return CTA;
        case 'content_block':
            return ContentBlock;
        case 'image':
            return Image;
        case 'available_rewards':
            return AvailableRewards;
    }
}

const showSubContentBlock = (type) => {
    return type !== 'available_rewards';
}

</script>
