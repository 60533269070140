<template>
    <div class="flex justify-between items-center px-10 py-10 space-x-8">
        <div class="space-y-2">
            <h3 class="text-lg font-medium">Delete program</h3>
            <p class="text-sm text-gray-500">Permanently delete your program. All settings, information, and partner activity tracking will be completely removed. This action cannot be undone.</p>
        </div>
        <div>
            <modal :title="'Are you sure?'" size="small">
                <template #toggle>
                    <btn type="light">Delete</btn>
                </template>
                <template #body>
                    <div class="space-y-6">
                        <div class="text-sm text-gray-500">
                            Do you really want to delete this program?<br/>
                            This process cannot be undone.
                        </div>
                        <div class="grid gap-x-6 gap-y-4">
                            <div class="col-span-2">
                                <label class="block text-sm mb-1.5">Please type <span
                                    class="font-semibold">DELETE</span> to confirm.</label>
                                <text-field v-model="confirmationText" v-on:keydown.enter="deleteProgram"
                                            v-on:keydown.enter.prevent="deleteProgram"
                                            :error="error"/>
                            </div>
                        </div>
                    </div>
                </template>
                <template #footer>
                    <btn type="primary" :loading="loading" @click="deleteProgram">Yes, delete</btn>
                </template>
            </modal>
        </div>
    </div>
</template>
<script>
export default {
    name: "ProgramDelete",
    props: ['program', 'delete_url'],
    data() {
        return {
            error: false,
            loading: false,
            confirmationText: ''
        }
    },
    methods: {
        deleteProgram: function () {

            if (this.loading) {
                return;
            }

            this.loading = true;

            if (this.confirmationText.toLowerCase() !== 'delete') {
                this.error = true;
                this.loading = false;
                return;
            }
            axios
                .post(this.delete_url, {"program": this.program, "confirmationText": this.confirmationText})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        const redirect_url = response.data.redirect_url || null;
                        if (redirect_url) {
                            window.location.href = redirect_url;
                        } else {
                            window.location.reload();
                        }
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>
