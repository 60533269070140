<template>
    <table class="min-w-full divide-y divide-gray-200">
        <thead>
        <tr class="h-1">
            <th :colspan="TABLE_COLUMNS_COUNT">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <th class="pl-4 w-6">
                <options
                    :isRight="false"
                    name="subscriber_table_options"
                >
                    <template #toggle>
                        <div class="bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                            <icon name="arrow-down-small"></icon>
                        </div>
                    </template>
                    <template #items>
                        <options-item
                            title="Select all"
                            @click="setExportSelection('selectAll')"
                        />
                        <options-item
                            title="Select visible"
                            @click="setExportSelection('selectVisible')"
                        />
                    </template>
                </options>
            </th>
            <table-header-columns-and-sorting :table-headers="tableHeaders"/>
        </tr>
        <tr class="h-1"/>
        </thead>
        <tbody class="divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!subscribers || subscribers?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && subscribers?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr v-for="(subscriber, index) in subscribers"
                :key="subscriber.id + '-' + subscriber.program_id + '-' + index"
                class="hover:bg-gray-50 cursor-pointer group">
                <td class="pl-4 w-6">
                    <checkbox
                        v-model="exportOptions.selectedIds"
                        :value="subscriber.id"
                        @click="setExportSelection('selectCustom')"
                    />
                </td>
                <td class="px-4 py-4 whitespace-nowrap font-medium text-sm text-gray-900 group-hover:text-teal-600">
                    <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10" @click="viewSubscriber(subscriber.url)">
                            <avatar :avatar-details="subscriber.avatar_details"/>
                        </div>
                        <div class="ml-4">
                            <div @click="viewSubscriber(subscriber.url)">
                                <span>{{ subscriber.full_name }}</span>
                            </div>
                            <div @click="viewSubscriber(subscriber.url)"
                                 class="flex text-sm font-normal text-gray-500 mt-0.5">
                                <div class="mr-1.5" @click="viewSubscriber(subscriber.url)">
                                    {{ subscriber.email }}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                    <span
                        :class="{
                        'bg-teal-100 text-teal-800': subscriber.status_name === 'Active',
                        'bg-red-100 text-red-800': subscriber.status_name === 'Rejected' || subscriber.status_name === 'Suspended',
                        'bg-yellow-100 text-yellow-800': subscriber.status_name === 'Not Approved',
                    }"
                        class="text-xs rounded px-1 py-0.5">
                        {{ subscriber.status_name }}
                    </span>
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900" @click="viewSubscriber(subscriber.url)">
                    {{ subscriber.referrals_count }}
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-gray-500 text-sm text-gray-900"
                    @click="viewSubscriber(subscriber.url)">
                    {{ subscriber.is_referred ? 'Yes' : 'No' }}
                </td>
                <td class="pl-4 pr-8 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                    @click="viewSubscriber(subscriber.url)">
                    <time :datetime="subscriber.joining_date_formatted">
                        {{ subscriber.joining_date_formatted }}
                    </time>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>
import Avatar from "../../../../common/Avatar";
import InitialLoadingSkeletonRows from "../../../../common/InitialLoadingSkeletonRows";
import NoSearchDataRow from "../../../../common/NoSearchDataRow";
import ProgressBar from "../../../../../elements/ProgressBar";
import filtersAndPagination from "../../../../../composables/common/filter-and-pagination-data";
import useTableDataExporter from "../../../../../composables/common/select-export-and-delete-data";
import TableHeaderColumnsAndSorting from "../../../filters-and-pagination/TableHeaderColumnsAndSorting.vue";

export default {
    name: "SubscriberDataTable",
    emits: ['hide-options'],
    components: {
        TableHeaderColumnsAndSorting,
        Avatar,
        InitialLoadingSkeletonRows,
        NoSearchDataRow,
        ProgressBar
    },
    props: {
        subscribers: {
            default: []
        },
        config: {
            default: {
                program_requires_approval: false
            }
        },
        tableHeaders: {
            default: []
        }
    },
    setup() {
        const TABLE_COLUMNS_COUNT = 6;
        const {loadingFiltersAndPaginationData} = filtersAndPagination();
        const {exportOptions} = useTableDataExporter();

        return {
            loadingFiltersAndPaginationData,
            TABLE_COLUMNS_COUNT,
            exportOptions
        }
    },
    methods: {
        viewSubscriber(url) {
            window.location = url;
        },
        setExportSelection(type) {
            this.exportOptions.selectType = type;
            if (type === 'selectVisible' || type === 'selectAll') {
                this.exportOptions.selectedIds = [];
                this.subscribers.forEach(subscriber => {
                    this.exportOptions.selectedIds.push(subscriber.id);
                })
            }
            this.emitter.emit('hide-options', 'subscriber_table_options');
        }
    }
}
</script>
