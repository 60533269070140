<template>
    <div>
        <draggable-accordion
            class="mb-2"
            v-for="content in props.data.contents"
            :unique_id="content.unique_id"
            :edit="content.edit"
            @remove="removeContent"
            @toggle="content.edit = !content.edit"
        >
            <template #title>{{ contentName }}</template>

        </draggable-accordion>
    </div>

    <div class="flex justify-between items-center">
        <div class="flex justify-between items-center">
            <toggle
                name="columns_toggle"
                size="default"
                v-model="data.display_blocks_in_columns"
            />
            <span class="flex flex-col flex-grow" id="columns_toggle-label">
                    <span class="text-sm text-gray-900">Display blocks in columns</span>
                </span>
        </div>
    </div>

</template>

<script setup>
import {defineProps} from 'vue';
import {uniqueId} from '../helpers';

const contentName = 'AvailableRewards';

const props = defineProps({
    data: Object
});

props.data.contents = props.data?.contents ?? [];

const addContent = () => {
    props.data.contents.push({
        unique_id: uniqueId(),
        edit: true,
        text: '',
    });
}

const removeContent = (uniqueId) => {
    props.data.contents.splice(
        props.data.contents.findIndex(content => content.unique_id === uniqueId)
    , 1);
}
</script>
