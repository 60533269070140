<template>
    <div class="bg-white rounded border p-4">
        <!-- Integration not connected -->
        <div class="flex justify-between items-center" v-if="!isConnected">
            <div class="text-sm text-gray-900">
                ActiveCampaign is not connected.
            </div>
            <div>
                <modal size="small" title="ActiveCampaign integration" name="esp_connect">
                    <template #toggle>
                        <btn type="secondary" size="small">Authorize</btn>
                    </template>
                    <template #body>

                        <div class="grid space-y-6">
                            <text-field
                                label="API url"
                                name="api_url"
                                type="text"
                                class="col-span-2"
                                v-model="apiUrl"
                                autofocus="true"
                            >
                                <template #note>
                                    To access an API url, sign into your ActiveCampaign account. Once logged in, head over to the Settings menu and then proceed to the Developer section to find the necessary details.
                                </template>
                            </text-field>

                            <text-field
                                label="API key"
                                name="api_key"
                                type="text"
                                class="col-span-2"
                                v-model="apiKey"
                            >
                                <template #note>
                                    Your API key is right below your API url.
                                </template>
                            </text-field>
                        </div>

                    </template>
                    <template #footer>
                        <btn type="primary" @click="connect" :loading="loading.connecting">
                            Connect
                        </btn>
                    </template>
                </modal>

            </div>
        </div>
        <!-- Integration is connected -->
        <div class="flex justify-between items-center gap-4" v-if="isConnected">
            <div class="flex items-center">
                <div class="flex-shrink-0 w-10 h-10">
                    <img class="w-10 h-10 rounded" src="/images/integrations/activecampaign-256x256.png">
                </div>
                <div class="text-sm text-gray-500 ml-5">
                    Successfully connected.
                </div>
            </div>
            <div>
                <DisconnectNrpButton title="ActiveCampaign disconnect"
                                     :loading="loading.disconnecting"
                                     @disconnectEsp="disconnect"
                                     v-model:delete-existing-subscribers="deleteExistingSubscribersFromPartnero"
                                     provider="ActiveCampaign"
                ></DisconnectNrpButton>
            </div>
        </div>
        <div class="justify-between items-center gap-4 mt-5 w-full" v-if="esp_error_message">
            <alert type="warning" v-if="esp_error_message">{{ esp_error_message }}</alert>
        </div>
    </div>

    <div v-if="isConnected && enabled_settings.setting_list_import">
        <label for="format" class="block text-sm mb-1.5">Subscriber list</label>
        <select name="esp_activecampaign_list_import" class="input" v-model="espSettingsStore.list_import">
            <option value="0">Select a list</option>
            <option v-for="list in espSettingsStore.lists" :value="list.id">{{ list.name }}</option>
        </select>
        <small class="text-gray-500 mt-1 block">
            Please select the subscribers who will participate in your newsletter referral program.
        </small>
    </div>

    <div v-if="isConnected && enabled_settings.setting_list_export">
        <label for="format" class="block text-sm mb-1.5">Destination list</label>
        <select name="esp_activecampaign_list_export" class="input" v-model="espSettingsStore.list_export">
            <option value="0">Select a list</option>
            <option v-for="list in espSettingsStore.lists" :value="list.id">{{ list.name }}</option>
        </select>
        <small class="text-gray-500 mt-1 block">Please choose the destination where referred subscribers should be saved upon subscribing.</small>
    </div>

    <div class="flex justify-between items-center" v-if="isConnected && enabled_settings.setting_subscribers_sync">
        <toggle name="esp_activecampaign_subscribers_sync" v-model="subscribers_sync"></toggle>
        <span class="flex flex-col flex-grow">
            <span class="text-sm font-medium text-gray-900">Enable subscribers synchronization</span>
            <span class="text-sm text-gray-500">When enabled every new subscriber that has the selected list will be synced with your newsletter referral program.</span>
        </span>
    </div>
    <input type="hidden" name="esp_activecampaign_enabled_settings" v-model="enabled_settings_json">
</template>

<script>

import DisconnectNrpButton from "./components/DisconnectNrpButton.vue";
import {useEspSettingsStore} from "../../../../../stores/app/programs/newsletter/EspSettingsStore";

export default {
    name: "EspActiveCampaign",
    components: {DisconnectNrpButton},
    props: [
        'program', 'esp_config', 'setting_list_import', 'setting_list_export', 'setting_subscribers_sync'
    ],
    setup (props) {
        const espSettingsStore = useEspSettingsStore();
        espSettingsStore.setLists(props.esp_config?.lists ?? []);
        espSettingsStore.setListImport(props.esp_config?.list_import ?? 0);
        espSettingsStore.setListExport(props.esp_config?.list_export ?? 0);
        return {
            espSettingsStore
        }
    },
    data() {
        const espConfig = this.esp_config ?? {};

        return {
            apiKey: '',
            apiUrl: '',
            deleteExistingSubscribersFromPartnero: false,
            convert_kit_subscribers_form_id: espConfig.convert_kit_subscribers_form_id ?? 0,
            subscribers_sync: espConfig.subscribers_sync ?? true,
            isConnected: espConfig.connected ?? false,
            isSetupCompleted: espConfig.setupCompleted ?? false,

            loading: {
                connecting: false,
                disconnecting: false,
            },
            forms: espConfig.forms ?? [],
            esp_error_message: espConfig.esp_error_message ?? null,
            enabled_settings: {
                setting_list_import: this.setting_list_import ?? false,
                setting_list_export: this.setting_list_export ?? false,
                setting_subscribers_sync: this.setting_subscribers_sync ?? false,
            }
        }
    },
    computed: {
        enabled_settings_json() {
            return JSON.stringify(this.enabled_settings);
        }
    },
    methods: {
        connect() {
            if (this.loading.connecting) {
                return;
            }
            this.loading.connecting = true;

            axios
                .post(route('app_int.newsletter.esp.connect', {program: this.program}), {
                    type: 'activecampaign',
                    connect_config: {
                        api_key: this.apiKey,
                        api_url: this.apiUrl,
                    }
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);

                    if (status === 1) {

                        let tmpLists = [];
                        const responseLists = response.data.result.lists ?? [];

                        this.forms = response.data.result.forms ?? [];

                        for (let i = 0; i < responseLists.length; i++) {
                            tmpLists.push(
                                {
                                    id: responseLists[i]['id'],
                                    name: responseLists[i]['name']
                                }
                            );
                        }

                        this.espSettingsStore.setLists(tmpLists);
                        this.isConnected = true;
                        this.emitter.emit('modal-off', 'esp_connect');
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading.connecting = false);
        },
        disconnect() {
            if (this.loading.disconnecting) {
                return;
            }
            this.loading.disconnecting = true;

            axios
                .post(route('app_int.newsletter.esp.disconnect', {program: this.program}), {
                    type: 'activecampaign',
                    deleteExistingSubscribersFromPartnero: this.deleteExistingSubscribersFromPartnero
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);

                    if (status === 1) {
                        this.apiKey = '';
                        this.apiUrl = '';
                        this.espSettingsStore.setLists([]);
                        this.isConnected = false;
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading.disconnecting = false);
        },
    }
}
</script>

