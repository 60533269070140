<template>
    <div class="rounded border bg-white divide-y divide-gray-200 divide-opacity-75">
        <div class="flex justify-between py-4 px-5">
            <div class="flex items-center space-x-4 truncate">
                <div class="flex justify-center items-center w-5 h-5 bg-teal-400 rounded-full"
                     v-if="mailerSendConnected">
                    <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M5 13l4 4L19 7"></path>
                    </svg>
                </div>
                <div class="flex justify-center items-center w-5 h-5 bg-gray-300 rounded-full"
                     v-if="!mailerSendConnected">
                    <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
                <div class="flex-1 text-sm space-y-0.5">
                    <div class="font-medium">Use MailerSend</div>
                    <div class="text-gray-500 text-xs">Connect to MailerSend and use your own domain for sending emails.</div>
                </div>
            </div>
            <div class="flex items-center pl-6 space-x-4">
                <div>
                    <modal title="Connect to MailerSend" size="small" name="email_gateway_mailersend_modal">
                        <template #toggle>

                            <btn size="small" type="secondary">
                                <span class="px-1.5">Setup</span>
                            </btn>

                        </template>
                        <template #body>
                            <div class="space-y-6">
                                <div class="text-sm opacity-60">
                                    Copy and paste your MailerSend API token to connect your own domain. If you haven’t created it yet, <a href="https://www.mailersend.com/help/managing-api-tokens" target="_blank" class="underline">here’s how to do it</a>.
                                </div>
                                <div v-if="!mailerSendConnected">
                                    <label class="block text-sm mb-1.5">MailerSend API token</label>
                                    <textarea
                                        v-if="!mailerSendConnected"
                                        rows="7"
                                        :value="mailersend_api_key"
                                        @input="mailersend_api_key = $event.target.value"
                                        placeholder="eyJ0eXAiOi..."
                                        class="placeholder-gray-400 block -ml-px w-full border-gray-300 transition-shadow rounded text-sm focus:ring-indigo-100 focus:ring-0 focus:border-gray-400"
                                    ></textarea>
                                </div>
                            </div>
                            <div v-if="mailersendConnectError" class="text-red-500 text-sm">
                                {{ mailersendConnectError }}
                            </div>
                        </template>
                        <template #footer>
                            <btn v-if="!mailerSendConnected" type="primary" @click="connectMailersend"
                                 :disabled="processingInProgress" :loading="processingInProgress">Connect
                            </btn>
                            <btn v-if="mailerSendConnected" type="primary" @click="disconnectMailersend"
                                 :disabled="processingInProgress" :loading="processingInProgress">Disconect
                            </btn>
                        </template>
                    </modal>
                </div>
            </div>
        </div>
        <div class="flex justify-between py-4 px-5">
            <div class="flex items-center space-x-4 truncate">
                <div class="flex justify-center items-center w-5 h-5 bg-teal-400 rounded-full"
                     v-if="smtpConnected">
                    <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M5 13l4 4L19 7"></path>
                    </svg>
                </div>
                <div class="flex justify-center items-center w-5 h-5 bg-gray-300 rounded-full"
                     v-if="!smtpConnected">
                    <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
                <div class="flex-1 text-sm space-y-0.5">
                    <div class="font-medium">Use custom SMTP</div>
                    <div class="text-gray-500 text-xs">Configure outgoing mail to follow your
                        own custom settings.
                    </div>
                </div>
            </div>
            <div class="flex items-center pl-6 space-x-4">
                <div>
                    <modal :title="'SMTP'" size="small" name="email_gateway_smtp_modal">
                        <template #toggle>
                            <btn size="small" type="secondary">
                                <span class="px-1.5">Setup</span>
                            </btn>
                        </template>
                        <template #body>
                            <div class="space-y-6">
                                <div class="text-sm opacity-60">
                                    Connect to your SMTP server by adding your credentials from your email service provider. Once added, verify the connection by sending yourself a test email.
                                </div>

                                <alert v-if="smtpConnectError" type="error">{{ smtpConnectError }}</alert>

                                <div class="grid gap-x-6 gap-y-4" v-if="!smtpConnected">
                                    <div>
                                        <label class="block text-sm mb-1.5">Server</label>
                                        <div class="md:flex space-y-4 md:space-y-0">
                                            <input type="text" placeholder="0.0.0.0" v-model="smtpSettings.host"
                                                   class="md:w-6/12 flex-1 focus:z-10 block -ml-px w-full border-gray-300 transition-shadow rounded md:rounded-r-none text-sm focus:ring-indigo-100 focus:ring-0 focus:border-gray-400"/>
                                            <input type="text" placeholder="587" v-model="smtpSettings.port"
                                                   class="md:w-3/12 focus:z-10 block -ml-px w-full border-gray-300 transition-shadow rounded md:rounded-none text-sm focus:ring-indigo-100 focus:ring-0 focus:border-gray-400"/>
                                            <select
                                                v-model="smtpSettings.security"
                                                class="md:w-3/12 focus:z-10 block -ml-px w-full border-gray-300 transition-shadow rounded md:rounded-l-none text-sm focus:ring-indigo-100 focus:ring-0 focus:border-gray-400">
                                                <option disabled>Security</option>
                                                <option value="auto" selected>Auto</option>
                                                <option value="none">None</option>
                                                <option value="tls">TLS</option>
                                                <option value="ssl">SSL</option>
                                                Auto
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <text-field label="Username" v-model="smtpSettings.username"/>
                                    </div>
                                    <div>
                                        <text-field label="Password" type="password" :show_reveal_password_icon="true"
                                                    v-model="smtpSettings.password"/>
                                    </div>
                                    <div>
                                        <text-field label="From email address" v-model="smtpSettings.from_address"/>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #footer>
                            <btn v-if="!smtpConnected" type="primary" @click="connectSmtp"
                                 :disabled="processingInProgress"
                                 :loading="processingInProgress">Connect
                            </btn>
                            <btn v-if="smtpConnected" type="primary" @click="disconnectSmtp"
                                 :disabled="processingInProgress"
                                 :loading="processingInProgress">Disconnect
                            </btn>
                        </template>
                    </modal>

                </div>
            </div>
        </div>
        <div class="flex justify-between py-4 px-5">
            <div class="flex items-center space-x-4 truncate">
                <div class="flex justify-center items-center w-5 h-5 bg-teal-400 rounded-full"
                     v-if="gmailConnected">
                    <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M5 13l4 4L19 7"></path>
                    </svg>
                </div>
                <div class="flex justify-center items-center w-5 h-5 bg-gray-300 rounded-full"
                     v-if="!gmailConnected">
                    <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
                <div class="flex-1 text-sm space-y-0.5">
                    <div class="font-medium">Send with Gmail</div>
                    <div class="text-gray-500 text-xs">Send emails using your own Gmail account.</div>
                </div>
            </div>
            <div class="flex items-center pl-6 space-x-4">
                <div>

                    <modal v-if="!gmailConnected" title="Send with Gmail" size="small" name="send_with_gmail_modal">
                        <template #toggle>
                            <btn size="small" type="secondary"> Sign in </btn>
                        </template>
                        <template #body>
                            <div class="text-sm text-gray-500">
                                By using Patnero to send notifications via your Gmail account, you acknowledge and agree that any information received from Google APIs will be used and transferred in compliance with the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" class="underline hover:no-underline">Google API Services User Data Policy</a>, including its Limited Use requirements.
                            </div>
                        </template>
                        <template #footer>
                            <btn v-if="!gmailConnected" :href="gmail_redirect"
                                 :disabled="processingInProgress"
                                 :loading="processingInProgress">Continue to Sign in
                            </btn>
                        </template>
                    </modal>





                    <modal v-if="gmailConnected" title="Are you sure you want to disconnect?" size="small" name="email_gateway_gmail_disconnect_modal">
                        <template #toggle>
                            <btn size="small" type="secondary"> Disconnect </btn>
                        </template>
                        <template #footer>
                            <btn type="primary" @click="disconnectGmail"
                                 :disabled="processingInProgress"
                                 :loading="processingInProgress">Disconnect
                            </btn>
                        </template>
                    </modal>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "EmailsGatewayConnect",
    props: {
        mailersend_api_key: {
            type: String,
            default: 'eyJ0eXAiO...'
        },
        is_gmail_connected: {
            type: Boolean,
            default: false,
        },
        gmail_redirect: {
            type: String,
            default: '',
        },
        gateway_update_url: {
            type: String,
            default: ''
        },
        gateway_disconnect_url: {
            type: String,
            default: ''
        },
        smtp_settings:
            {
                type: Object,
                default:
                    {
                        host: '',
                        port: '',
                        username: '',
                        password: '',
                        security: 'none',
                        from_address: '',
                    }
            }
    },
    data() {
        return {
            smtpConnected: false,
            smtpConnectError: false,
            mailersendConnectError: false,
            mailerSendConnected: false,
            gmailConnected: false,
            processingInProgress: false,
            smtpSettings: this.smtp_settings,
        }
    },
    created() {
        this.smtpConnected = this.smtpSettings.host ? this.smtpSettings.host.length > 2 : false;
        this.mailerSendConnected = this.mailersend_api_key ? this.mailersend_api_key.length > 2 : false;
        this.gmailConnected = this.is_gmail_connected;
    },
    methods: {
        connectSmtp() {
            this.smtpConnectError = false;
            this.saveSettings('smtp', this.smtpSettings)
                .then(response => {
                    const status = parseInt(response?.status ?? 0);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'email_gateway_smtp_modal');
                        this.smtpConnected = true;

                        const updatedSettings = response.updated_settings || false;
                        if (updatedSettings) {
                            this.smtpSettings = Object.assign(this.smtpSettings, updatedSettings);
                        }

                        this.emitter.emit('email-gateway-update');
                    } else {
                        this.smtpConnectError = response.message ?? 'Failed to connect';
                    }
                });
        },
        connectMailersend() {
            this.mailersendConnectError = false;
            this.saveSettings('mailersend', {
                'api_key': this.mailersend_api_key
            }).then(response => {
                if (response.status === 1) {
                    this.emitter.emit('modal-off', 'email_gateway_mailersend_modal');
                    this.mailerSendConnected = true;
                    this.emitter.emit('email-gateway-update');
                } else {
                    this.mailersendConnectError = 'Your API token is not valid. Please make sure you have the correct permissions level. .'
                }
            });
        },
        disconnectSmtp() {
            this.disconnectGateway('smtp').then(response => {
                if (response === 1) {
                    this.smtpConnected = false;
                    this.emitter.emit('email-gateway-update');
                }
            });
        },
        disconnectMailersend() {
            this.disconnectGateway('mailersend').then(response => {
                if (response === 1) {
                    this.mailerSendConnected = false;
                    this.emitter.emit('email-gateway-update');
                }
            });
        },
        disconnectGmail() {
            this.disconnectGateway('gmail').then(response => {
                if( response === 1) {
                    this.gmailConnected = false;
                    this.emitter.emit('email-gateway-update');
                }
            })
        },
        disconnectGateway(type) {
            this.processingInProgress = true;
            return axios
                .post(this.gateway_disconnect_url, {'type': type})
                .then(response => {
                    return parseInt(response.data.status || -1);
                })
                .catch(error => {
                })
                .finally(() => this.processingInProgress = false);
        },
        saveSettings(type, settings) {
            this.processingInProgress = true;
            return axios
                .post(this.gateway_update_url, {
                    'type': type,
                    'settings': settings
                })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    return error.response.data;
                })
                .finally(() => this.processingInProgress = false);
        }
    }
}
</script>

<style scoped>

</style>
