<script setup>
import {ref, getCurrentInstance} from "vue";

const props = defineProps({
    title: {
        type: String,
        default: 'Analytics'
    },
    timePeriodPicker: {
        type: Boolean,
        default: false
    }
});
const {proxy} = getCurrentInstance()

const timePeriod = ref('total')

const handleTimePeriodChange = (event) => {
    proxy.emitter.emit('program-analytics-time-period-updated', event.target.value)
}
</script>

<template>
    <header-bar size="small" :title="title">
        <template v-if="timePeriodPicker" #actions>
            <div class="flex space-x-2">
                <select id="time_period" @change="handleTimePeriodChange" name="time_period"
                        v-model="timePeriod"
                        class="-mt-2 xl:-mt-4 -mr-4 block border-0 focus:ring-0 leading-4 ml-auto px-8 py-1.5 rounded text-right text-xs text-gray-500">
                    <option value="total" selected>Total</option>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="this_month">This month</option>
                    <option value="last_month">Last month</option>
                    <option value="last_6_months">Last 6 months</option>
                    <option value="this_year">This year</option>
                    <option value="last_year">Last year</option>
                </select>
            </div>
        </template>
    </header-bar>
</template>
