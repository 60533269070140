<template>
    <div class="px-10 divide-y divide-gray-200">
        <!-- Settings Section -->
        <form-section title="Settings" description="Configure how the embeddable partner sign-up form will appear on your website.">
            <div class="grid grid-cols-1 gap-12">
                <div class="flex justify-start gap-6">
                    <div class="group bg-gray-50 w-28 h-28 border rounded flex flex-shrink-0 relative cursor-pointer">
                        <img
                            v-if="!isPreviewImageBroken"
                            :src="previewImageUrl + '?t=' + Date.now()"
                            alt="Embeddable signup preview"
                            class="absolute inset-0 w-full h-full object-cover"
                            @error="handlePreviewImageError"
                            @load="handlePreviewImageLoad"
                        >
                        <img v-else src="/images/partner-signup/embeddable_preview.png" alt="Signup popup preview" class="absolute inset-0 w-full h-full object-cover">

                        <div v-if="isPreviewLoading" class="absolute inset-0 flex items-center justify-center">
                            <icon name="spinner" size="24" class="animate-spin"></icon>
                        </div>
                        <div v-else @click="showPreview" class="invisible group-hover:visible absolute inset-0 flex items-center justify-center">
                            <icon name="eye"></icon>
                        </div>
                    </div>

                    <div>
                        <div class="mb-2">
                            <div class="font-semibold text-sm">Embeddable Partner Sign-Up form</div>
                            <div class="text-sm text-gray-500">
                                Easily collect partner signups directly from your website with a user-friendly form.
                            </div>
                        </div>
                        <toggle
                            :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_EMBED_ENABLED"
                            v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_EMBED_ENABLED]"
                            title="Embed sign-up form automatically"
                        >
                            <template #description>
                                Automatically embed and display a form on your website for seamless visitor interaction.                            </template>
                        </toggle>
                    </div>
                </div>
            </div>
            <div class="relative">
                <div class="absolute right-5 top-1.5"
                    @click="copySnippet"
                >
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea
                    ref="partnerEmbeddableSignupSnippet"
                    rows="1"
                    class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h"
                    :value='`<div id="po-partner-embeddable-signup-${programPubId}"></div>`'
                    readonly
                ></textarea>

                <small class="text-gray-500 mt-1 block">
                    You can place this snippet anywhere within your website's HTML where you'd like the form to appear.
                </small>
            </div>
        </form-section>

        <!-- Appearance Section -->
        <form-section title="Appearance" description="The styling for default elements of your embeddable partner sign-up.">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <radio-group-inline
                    label="Branding"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_BRANDING_TYPE"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_BRANDING_TYPE]"
                    :options="[
                        { value: 'none', label: 'None' },
                        { value: 'logo', label: 'Logo' },
                        { value: 'symbol', label: 'Symbol' },
                    ]"
                ></radio-group-inline>

                <div class="invisible lg:visible"></div>

                <radio-group-inline-color
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_BACKGROUND_COLOR"
                    label="Background color"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_BACKGROUND_COLOR]"
                    :options="[
                        { value: 'white', label: 'bg-white', label_is_class: true },
                        { value: 'gray-50', label: 'bg-gray-50', label_is_class: true },
                        { value: 'gray-100', label: 'bg-gray-100', label_is_class: true },
                        { value: 'gray-200', label: 'bg-gray-200', label_is_class: true },
                        { value: 'gray-300', label: 'bg-gray-300', label_is_class: true },
                        { value: 'custom', label: 'Custom', custom: { name: SETTING_PORTAL_EMBEDDABLE_SIGNUP_BACKGROUND_COLOR, placeholder: '', type: 'input' } },
                    ]"
                ></radio-group-inline-color>

                <radio-group-inline-color
                    label="Background gradient color"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_GRADIENT_COLOR"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_GRADIENT_COLOR]"
                    :options="[
                        { value: 'black', label: 'bg-black', label_is_class: true },
                        { value: 'gray-300', label: 'bg-gray-300', label_is_class: true },
                        { value: 'blue-700', label: 'bg-blue-700', label_is_class: true },
                        { value: 'teal-600', label: 'bg-teal-600', label_is_class: true },
                        { value: 'none', label: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEwIi8+PGxpbmUgeDE9IjQuOTMiIHkxPSI0LjkzIiB4Mj0iMTkuMDciIHkyPSIxOS4wNyIvPjwvc3ZnPg==', label_is_img: true},
                        { value: 'custom', label: 'Custom', custom: { name: SETTING_PORTAL_EMBEDDABLE_SIGNUP_GRADIENT_COLOR, placeholder: '', type: 'input' } },
                    ]"
                ></radio-group-inline-color>

                <radio-group-inline-color
                    label="Layout corners"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_LAYOUT_CORNERS"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_LAYOUT_CORNERS]"
                    :options="[
                        {
                            value: 'none',
                            label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8cmVjdCB4PSIyIiB5PSIyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjQiIGZpbGw9Im5vbmUiPjwvcmVjdD48L3N2Zz4=',
                            label_is_img: true
                        },
                        {
                            value: 'rounded-lg',
                            label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8cmVjdCB4PSIyIiB5PSIyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHJ4PSI0IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSI0IiBmaWxsPSJub25lIj48L3JlY3Q+PC9zdmc+',
                            label_is_img: true
                        },
                        {
                            value: 'rounded-xl',
                            label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8cmVjdCB4PSIyIiB5PSIyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHJ4PSI4IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSI0IiBmaWxsPSJub25lIj48L3JlY3Q+PC9zdmc+',
                            label_is_img: true
                        },
                        {
                            value: 'rounded-3xl',
                            label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8cmVjdCB4PSIyIiB5PSIyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHJ4PSIxMCIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iNCIgZmlsbD0ibm9uZSI+PC9yZWN0Pjwvc3ZnPg==',
                            label_is_img: true
                        }
                    ]">
                </radio-group-inline-color>

                <radio-group-inline
                    label="Layout style"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_LAYOUT_STYLE"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_LAYOUT_STYLE]"
                    :options="[
                        { value: 'border', label: 'Border'},
                        { value: 'shadow', label: 'Shadow'},
                        { value: 'none', label: 'None'}
                    ]">
                </radio-group-inline>

                <radio-group-inline-color
                    label="Content text color"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_CONTENT_TEXT_COLOR"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_CONTENT_TEXT_COLOR]"
                    :options="[
                        { value: 'gray-900', label: 'bg-gray-900', label_is_class: true },
                        { value: 'gray-800', label: 'bg-gray-800', label_is_class: true },
                        { value: 'gray-700', label: 'bg-gray-700', label_is_class: true },
                        { value: 'gray-600', label: 'bg-gray-600', label_is_class: true },
                        { value: 'gray-500', label: 'bg-gray-500', label_is_class: true },
                        { value: 'custom', label: 'Custom', custom: { name: SETTING_PORTAL_EMBEDDABLE_SIGNUP_CONTENT_TEXT_COLOR, placeholder: '', type: 'input' } },
                    ]"
                ></radio-group-inline-color>

                <radio-group-inline-color
                    label="Button color"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_BUTTON_COLOR"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_BUTTON_COLOR]"
                    :options="[
                        { value: 'indigo', label: 'bg-indigo-700', label_is_class: true },
                        { value: 'blue', label: 'bg-blue-700', label_is_class: true },
                        { value: 'cyan', label: 'bg-cyan-700', label_is_class: true },
                        { value: 'teal', label: 'bg-teal-600', label_is_class: true },
                        { value: 'black', label: 'bg-black', label_is_class: true },
                        { value: 'custom', label: 'Custom', custom: { name: SETTING_PORTAL_EMBEDDABLE_SIGNUP_BUTTON_COLOR, placeholder: '', type: 'input' } },
                    ]"
                ></radio-group-inline-color>

                <radio-group-inline
                    label="Input style"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_INPUT_STYLE"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_INPUT_STYLE]"
                    :options="[
                        { value: 'white', label: 'Default' },
                        { value: 'shadow', label: 'Shadow' },
                        { value: 'transparent', label: 'Transparent' }
                    ]"
                ></radio-group-inline>
            </div>
        </form-section>

        <!-- Content Section -->
        <form-section title="Content" description="Embedded partner sign-up form and additional content.">

            <div>
                <tabs>
                    <tab @click="setActiveTab(CONTENT_TAB_FORM)" :class='{"border-b-4": activeTab === CONTENT_TAB_FORM}'>
                        Form
                    </tab>
                    <tab @click="setActiveTab(CONTENT_TAB_CONFIRMATION)" :class='{"border-b-4": activeTab === CONTENT_TAB_CONFIRMATION}'>
                        Confirmation
                    </tab>
                </tabs>
            </div>

            <div class="space-y-6" v-show="activeTab === CONTENT_TAB_FORM">
                <text-field
                    label="Title"
                    :field-id="SETTING_PORTAL_EMBEDDABLE_SIGNUP_TITLE"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_TITLE"
                    type="text"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_TITLE]"
                    :error="errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_TITLE]"
                ></text-field>
                <text-field
                    label="Subtitle"
                    placeholder="Optional. E.g. Join, Promote, Earn!"
                    :field-id="SETTING_PORTAL_EMBEDDABLE_SIGNUP_SUBTITLE"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_SUBTITLE"
                    type="text"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_SUBTITLE]"
                    :error="errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_SUBTITLE]"
                ></text-field>
                <text-editor
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_DESCRIPTION"
                    :text="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_DESCRIPTION]"
                    :error="errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_DESCRIPTION]"
                ></text-editor>

                <fields-builder
                    v-if="!isRedirectToSignUpPage"
                    name="embeddable_signup_fields"
                    :value="formFields"
                    zero-fields-message="Add fields to the embeddable signup form."
                    form-type="embeddable_signup"
                ></fields-builder>

                <text-field
                    label="Button"
                    :field-id="SETTING_PORTAL_EMBEDDABLE_SIGNUP_BUTTON_TEXT"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_BUTTON_TEXT"
                    type="text"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_BUTTON_TEXT]"
                    :error="errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_BUTTON_TEXT]"
                ></text-field>

                <toggle
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_REDIRECT_TO_SIGN_UP_PAGE"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_REDIRECT_TO_SIGN_UP_PAGE]"
                    title="Redirect to sign-up page"
                >
                    <template #description>
                        Redirect visitors to the dedicated sign-up page rather than embedding the sign-up form.
                    </template>
                </toggle>

                <div v-show="!isRedirectToSignUpPage">
                    <settings-accordion
                        label="Additional settings"
                        :placeholder="additionalSettingsSummary"
                        :opened="(errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_MARKETING_CONSENT_TEXT] || errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_URL] || errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_SAVED_LOCALLY] || errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_TEXT]) ? true : false"
                    >
                        <div class="space-y-4">
                            <text-field
                                v-show="!isRedirectToSignUpPage"
                                label="Marketing consent"
                                :field-id="SETTING_PORTAL_EMBEDDABLE_SIGNUP_MARKETING_CONSENT_TEXT"
                                placeholder="E.g. I'd like to receive updates regarding my affiliate account"
                                type="text"
                                :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_MARKETING_CONSENT_TEXT"
                                v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_MARKETING_CONSENT_TEXT]"
                                :error="errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_MARKETING_CONSENT_TEXT]"
                            >
                                <template #note>
                                    Once text is entered, a checkbox will appear on your signup form.
                                </template>
                            </text-field>
                            <portal-terms-of-services
                                v-show="!isRedirectToSignUpPage"
                                v-model:tos-url="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_URL]"
                                v-model:tos-save="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_SAVED_LOCALLY]"
                                v-model:tos-text="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_TEXT]"
                                tos-url-label="Terms of Use URL"
                                :tos-url-name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_URL"
                                :tos-save-toggle-name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_SAVED_LOCALLY"
                                tos-save-toggle-label="Save program terms on Partnero"
                                tos-save-toggle-description="Save your program terms on Partnero and edit them whenever necessary."
                                tos-text-label="Terms of Use text"
                                :tos-text-name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_TEXT"
                            >
                            </portal-terms-of-services>
                        </div>
                    </settings-accordion>
                </div>

            </div>

            <div class="space-y-6" v-show="activeTab === CONTENT_TAB_CONFIRMATION">
                <text-field
                    label="Title"
                    :field-id="SETTING_PORTAL_EMBEDDABLE_SIGNUP_THANK_YOU_TITLE"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_THANK_YOU_TITLE"
                    type="text"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_THANK_YOU_TITLE]"
                    :error="errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_THANK_YOU_TITLE]"
                ></text-field>

                <text-editor
                    label="Description"
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_THANK_YOU_DESCRIPTION"
                    :text="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_THANK_YOU_DESCRIPTION]"
                    :error="errors[SETTING_PORTAL_EMBEDDABLE_SIGNUP_THANK_YOU_DESCRIPTION]"
                ></text-editor>

                <toggle
                    :name="SETTING_PORTAL_EMBEDDABLE_SIGNUP_REDIRECT_TO_LOGIN_AFTER_SIGNUP"
                    v-model="settings[SETTING_PORTAL_EMBEDDABLE_SIGNUP_REDIRECT_TO_LOGIN_AFTER_SIGNUP]"
                    title="Redirect to login page"
                >
                    <template #description>
                        Redirect visitors to the dedicated login page after they sign up.
                    </template>
                </toggle>
            </div>

        </form-section>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { copyToClipboard } from '../../../../helpers/common.js'

// Constants matching PHP EmbeddableSignup class
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_EMBED_ENABLED = 'portal_embeddable_signup_embed_enabled'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_BRANDING_TYPE = 'portal_embeddable_signup_branding_type'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_BACKGROUND_COLOR = 'portal_embeddable_signup_background_color'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_CONTENT_TEXT_COLOR = 'portal_embeddable_signup_content_text_color'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_BUTTON_COLOR = 'portal_embeddable_signup_button_color'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_GRADIENT_COLOR = 'portal_embeddable_signup_gradient_color'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_INPUT_STYLE = 'portal_embeddable_signup_input_style'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_TITLE = 'portal_embeddable_signup_title'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_SUBTITLE = 'portal_embeddable_signup_subtitle'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_DESCRIPTION = 'portal_embeddable_signup_description'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_BUTTON_TEXT = 'portal_embeddable_signup_button_text'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_REDIRECT_TO_SIGN_UP_PAGE = 'portal_embeddable_signup_redirect_to_sign_up_page'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_URL = 'portal_embeddable_signup_tos_url'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_SAVED_LOCALLY = 'portal_embeddable_signup_tos_saved_locally'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_TEXT = 'portal_embeddable_signup_tos_text'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_MARKETING_CONSENT_TEXT = 'portal_embeddable_signup_marketing_consent_text'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_LAYOUT_CORNERS = 'portal_embeddable_signup_layout_corners'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_LAYOUT_STYLE = 'portal_embeddable_signup_layout_style'

const SETTING_PORTAL_EMBEDDABLE_SIGNUP_THANK_YOU_TITLE = 'portal_embeddable_signup_thank_you_title'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_THANK_YOU_DESCRIPTION = 'portal_embeddable_signup_thank_you_description'
const SETTING_PORTAL_EMBEDDABLE_SIGNUP_REDIRECT_TO_LOGIN_AFTER_SIGNUP = 'portal_embeddable_signup_redirect_to_login_after_signup'

const props = defineProps({
    programSettings: {
        type: Object,
        required: true
    },
    embeddableSignupFormFields: {
        type: Array,
        required: true
    },
    errors: {
        type: Object,
        default: () => ({
            title: false,
            description: false,
            button_text: false
        })
    },
    previewImageUrl: {
        type: String,
        default: ''
    },
    previewUrl: {
        type: String,
        required: true
    },
    programPubId: {
        type: String,
        required: true
    }
})

const CONTENT_TAB_FORM = 'content-tab-form'
const CONTENT_TAB_CONFIRMATION = 'content-tab-confirmation'

// Reactive refs
const settings = ref({ ...props.programSettings })
const formFields = ref([...props.embeddableSignupFormFields])
const activeTab = ref(CONTENT_TAB_FORM)
const isRedirectToSignUpPage = computed(() => ['on', true].includes(settings.value[SETTING_PORTAL_EMBEDDABLE_SIGNUP_REDIRECT_TO_SIGN_UP_PAGE]))

const setActiveTab = (tab) => {
    activeTab.value = tab
}

const isPreviewLoading = ref(false);
const isPreviewImageBroken = ref(false);

const handlePreviewImageError = () => {
    isPreviewImageBroken.value = true;
};

const handlePreviewImageLoad = () => {
    isPreviewImageBroken.value = false;
};

const showPreview = () => {

    if (isPreviewLoading.value) {
        return;
    }

    isPreviewLoading.value = true;

    const iframe = document.createElement('iframe');
    iframe.id = 'po-embeddable-signup-iframe';
    iframe.className = 'fixed inset-0 w-full h-full border-0 z-[99999999] hidden';
    iframe.src = props.previewUrl;
    iframe.title = 'Preview';

    document.body.appendChild(iframe);

    // Define message handler
    const handleMessage = (event) => {
        if (event.data?.type === 'po:partner-embeddable-signup:close') {
            iframe.remove();
            // Remove event listener when preview is closed
            window.removeEventListener('message', handleMessage);
        }
        if (event.data?.type === 'po:partner-embeddable-signup:loaded') {
            isPreviewLoading.value = false;
            iframe.classList.remove('hidden');
        }
    };

    // Add event listener
    window.addEventListener('message', handleMessage);
}

const additionalSettingsSummary = computed(() => {
    const summary = [];

    // Marketing consent status
    const hasMarketingConsent = settings.value[SETTING_PORTAL_EMBEDDABLE_SIGNUP_MARKETING_CONSENT_TEXT]?.trim();
    summary.push(`Marketing consent: <strong>${hasMarketingConsent ? 'Enabled' : 'Disabled'}</strong>`);

    // Terms of use status
    const hasTermsOfUse = settings.value[SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_URL]?.trim() || settings.value[SETTING_PORTAL_EMBEDDABLE_SIGNUP_TOS_SAVED_LOCALLY] === 'on';
    summary.push(`Terms of use: <strong>${hasTermsOfUse ? 'Enabled' : 'Disabled'}</strong>`);

    return summary.join('<br>');
});

const partnerEmbeddableSignupSnippet = ref(null)

const copySnippet = () => {
    if (partnerEmbeddableSignupSnippet.value) {
        copyToClipboard(partnerEmbeddableSignupSnippet.value.value)
    }
}
</script>
