<template>
    <div class="bg-tahiti-100" v-if="show_notification">
        <div class="px-4 py-3 mx-auto max-w-5xl sm:px-8">
            <div class="flex flex-wrap items-center justify-between">
                <div class="flex w-0 flex-1 items-center">
                    <p class="ml-3 truncate font-medium">
                        Your email address isn't verified. Please check your mailbox or <span
                        v-if="loading.resending_verification">sending</span><a v-if="!loading.resending_verification"
                                                                               href="#" @click="resendVerification"
                                                                               class="underline hover:no-underline">click
                        here</a> to resend verification email.
                    </p>
                </div>
                <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                    <button type="button" @click="removeNotification"
                            class="-mr-1 flex rounded-md p-2 hover:bg-gray-800 hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2">
                        <span class="sr-only">Dismiss</span>
                        <!-- Heroicon name: outline/x-mark -->
                        <svg class="h-6 w-6 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'UserEmailVerificationCheck',
    props: ['is_verified', 'dont_display'],
    data() {
        return {
            show_notification: !this.dont_display && !this.is_verified,
            loading: {
                resending_verification: false
            }
        }
    },
    methods: {
        resendVerification() {

            if (this.loading.resending_verification) {
                return;
            }
            this.loading.resending_verification = true;

            axios.post(route('app_int.user.send_email_verification'), {})
                .finally(() => this.loading.resending_verification = false);
        },
        removeNotification() {
            this.show_notification = false;
            axios.post(route('app_int.user.remove_verification_notification'), {});
        }
    }
}
</script>

<style scoped>

</style>
