<script setup>
   import Spinner from "../../../../elements/Spinner.vue";
   import {reactive, ref} from "vue";
   import {brandNamesMapper} from "../../../../helpers/common";

   const props = defineProps({
       programId: {
           type: Number,
           required: true
       },
       couponId: {
           type: Number,
           required: true
       },
       syncProvider: {
           type: String,
           default: null
       },
       programCommissionId: {
           type: [Number,null],
           default: null
       }
   })

   const deleteModal = ref(null)

   const deletingInProgress = ref(false)

   const deleteCouponOnSyncProviderSide = ref(false)

   const deleteRewardAssociatedWithCoupon = ref(false)

   const notificationObj = reactive({
       showNotification: false,
       notificationTitle: 'Error',
       notificationType: 'warning',
       notificationMessage: 'Sync error, see console for error details.'
   });


   const deleteCoupon = () => {
       deletingInProgress.value = true
       axios.delete(route('app_int.coupon.destroy', { program: props.programId, coupon: props.couponId }),
           {
               data: {
                   deleteCouponOnSyncProviderSide: deleteCouponOnSyncProviderSide.value,
                   deleteRewardAssociatedWithCoupon: deleteRewardAssociatedWithCoupon.value
               }
           })
           .then((response) => {
               const status = parseInt(response.data.status || '-1');
               if (status === 1) {
                   deleteModal.value.onCancel()
                   deletingInProgress.value = false

                   notificationObj.showNotification = true
                   notificationObj.notificationTitle = 'Success'
                   notificationObj.notificationType = 'success'
                   notificationObj.notificationMessage = response.data.message ?? 'Coupon deleted successfully.'
                   setTimeout(() => {
                           notificationObj.showNotification = false
                           window.location.reload();
                       },
                       2000
                   )
               }
               else if (status === -1) {
                   notificationObj.showNotification = true
                   notificationObj.notificationTitle = 'Error'
                   notificationObj.notificationType = 'warning'
                   notificationObj.notificationMessage = response.data.error ?? 'Coupon delete error, contact support.'
               }
           })
           .catch(() => {
               notificationObj.showNotification = true
               console.log(error, error.response)
           })
           .finally(() => {
               deletingInProgress.value = false
           })
   }
</script>

<template>
    <div>
        <modal :title="'Please confirm'" size="small" ref="deleteModal">
            <template #toggle>
                <btn type="ghost" icon-name="delete" size="small"></btn>
            </template>
            <template #body>
                <div class="space-y-6 text-left">
                    <div class="text-sm text-gray-500">
                        Do you really want to delete this coupon?<br/>
                        This process cannot be undone.
                    </div>
                    <toggle v-if="syncProvider"
                           :title="'Delete the coupon from ' + brandNamesMapper[syncProvider] + ' too'"
                           v-model="deleteCouponOnSyncProviderSide"
                    />
                    <toggle v-if="programCommissionId"
                           title="Delete the reward linked to the coupon"
                           v-model="deleteRewardAssociatedWithCoupon"
                    />
                </div>
            </template>
            <template #footer>
                <btn type="primary" @click.prevent="deleteCoupon" :loading="deletingInProgress">
                    Delete
                </btn>
            </template>
        </modal>
        <notification
            :show="notificationObj.showNotification"
            :title="notificationObj.notificationTitle"
            :type="notificationObj.notificationType"
            class="mb-20 text-left"
            @notificationClosed="notificationObj.showNotification = false"
            whitespace="whitespace-normal"
        >
            <template #message>
                {{ notificationObj.notificationMessage }}
            </template>
        </notification>
    </div>
</template>

