<script setup>
  import ScheduledPayoutDetailsModal from "./ScheduledPayoutDetailsModal.vue";
  import {onMounted} from "vue";
  import {usePayoutAllAutomationStore} from "../../../../../stores/app/programs/payouts/automation/PayoutAllAutomationStore";

  const props = defineProps({
    programId: {
      type: Number,
      required: true,
    },
    scheduledPayouts: {
      type: Array,
      required: true,
    },
  })

  const payoutAutomationStore = usePayoutAllAutomationStore()

  onMounted(() => {
    payoutAutomationStore.setScheduledPayoutsHistory(props.scheduledPayouts)
  })

    const getTypeBasedOnStatus = (status) => {
        switch (status) {
            case 'scheduled_with_error':
                return 'error'
            case 'scheduled':
                return 'warning'
            case 'success':
                return 'success'
            case 'completed':
                return 'success'
            case 'failed':
                return 'error'
            case 'error':
                return 'error'
            default:
                return 'neutral'
        }
    }

    const getContentBasedOnStatus = (scheduledPayout) => {
        switch (scheduledPayout.status) {
            case 'scheduled':
                return 'The payout is scheduled to be completed on ' + scheduledPayout.scheduled_at_formatted
            case 'scheduled_with_error':
                return scheduledPayout.error
            case 'success':
                return 'The payouts has been completed successfully.'
            case 'completed':
                return 'The payouts has been completed successfully.'
            case 'failed':
                return 'The payouts has failed with the following error: ' + scheduledPayout.error + '.'
            case 'error':
                return 'The payouts has failed with the following error: ' + scheduledPayout.error + '.'
            default:
                return 'Unknown'
        }
    }

  const payoutMethodName = (payout) => {
      if (payout.payout_gateway === 'paypal') {
          return 'PayPal';
      }

      if (payout.payout_gateway === 'bank_transfer') {
          return 'Wire Transfer';
      }

      return '---';
  }

  const viewPayout = (url) => {
      window.location = url
  }
</script>

<template>
    <div class="bg-white rounded border overflow-hidden ">
        <table class="min-w-full divide-y divide-gray-200">
            <thead>
                <tr>
                    <th scope="col"
                        class="pl-6 pr-4 py-3.5 text-left text-xs font-normal uppercase text-gray-500 tracking-wider">
                      Action
                    </th>
                    <th scope="col"
                        class="px-4 py-3.5 text-left text-xs font-normal uppercase text-gray-500 tracking-wider">
                      Scheduled Amount
                    </th>
                    <th scope="col"
                        class="px-4 py-3.5 text-left text-xs font-normal uppercase text-gray-500 tracking-wider">
                      Payed amount
                    </th>
                    <th scope="col"
                        class="px-4 py-3.5 text-left text-xs font-normal uppercase text-gray-500 tracking-wider">
                      Status
                    </th>
                    <th scope="col"
                        class="px-4 py-3.5 text-left text-xs font-normal uppercase text-gray-500 tracking-wider">
                      Scheduled at
                    </th>
                    <th scope="col"
                        width="140"
                        class="pl-4 pr-6 py-3.5 text-right text-xs font-normal uppercase text-gray-500 tracking-wider">
                      Details
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="scheduledPayout in payoutAutomationStore.getScheduledPayoutsHistory" class="hover:bg-gray-50 group">
                    <td class="py-4 pr-4 pl-6 text-sm text-gray-900 whitespace-nowrap">
                      {{ scheduledPayout.uuid }}
                        <span class="text-gray-500 text-sm block">Method: <strong>{{ payoutMethodName(scheduledPayout) }}</strong></span>
                    </td>
                    <td class="py-4 px-4 text-sm text-gray-900 whitespace-nowrap">
                      {{ scheduledPayout.scheduled_amount }}
                    </td>
                    <td class="py-4 px-4 text-sm text-gray-900 whitespace-nowrap">
                      {{ scheduledPayout.paid_amount }}
                    </td>
                    <td class="py-4 px-4 text-sm text-gray-900 whitespace-nowrap">
                      <tooltip :content="getContentBasedOnStatus(scheduledPayout)">
                        <badge :title="scheduledPayout.status" :type="getTypeBasedOnStatus(scheduledPayout.status)"></badge>
                      </tooltip>
                    </td>
                    <td class="py-4 px-4 text-sm text-gray-900 whitespace-nowrap">
                      {{ scheduledPayout.scheduled_at_formatted }}

                    </td>
                    <td class="py-4 pr-6 pl-4 text-sm text-gray-500 whitespace-nowrap">
                      <scheduled-payout-details-modal
                          :scheduled-payout="scheduledPayout"
                          :program-id="props.programId"
                          :store="payoutAutomationStore"
                          :gateway="scheduledPayout.payout_gateway"
                      ></scheduled-payout-details-modal>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

