<template>

    <div class="space-y-6">

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Install Partnero app to your Shopify store</h3>
                <div class="text-sm space-y-2">
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Visit our <a class="font-semibold underline hover:no-underline" href="https://apps.shopify.com/partnero-affiliate-management" target="_blank">Shopify app listing here</a> and click <b>Add app</b>.</li>
                        <li>This will redirect you back to your Shopify admin panel where you’ll need to click <b>Install app</b>.</li>
                        <li>Then you will be asked to connect and authorize your Partnero account. Click <b>Connect</b> to be taken to the login screen for your account.</li>
                        <li>Choose the program you'd like to connect or create a new one.</li>
                    </ol>
                </div>
            </div>
        </div>

    </div>

</template>
