<template>
    <modal :title="'Cancel subscription'" size="small">
        <template #toggle>
            <btn type="link" class="text-sm text-white opacity-75">Pay past due invoice</btn>
        </template>
        <template #body>
            <div class="mb-8 text-sm text-gray-900 tracking-wid">
                We encountered an issue with recent subscription renewal. Please, check your payment method and billing details
                and try to pay the past due invoice to continue using the service.
            </div>
        </template>
        <template #footer>
            <btn :loading="loading.canceling" @click="payPastDueInvoice">Pay</btn>
        </template>
    </modal>
</template>
<script>
export default {
    name: "PayPastDueInvoice",
    props: {
        subscription_id: {type: String, required: true},
        pay_past_due_invoice_url: {type: String, required: true},
    },
    data() {
        return {
            loading: {
                canceling: false
            }
        }
    },
    methods: {
        payPastDueInvoice() {
            if (this.loading.canceling) {
                return;
            }

            this.loading.canceling = true;

            axios.post(this.pay_past_due_invoice_url, {
                'subscription': this.subscription_id
            })
                .then((response) => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        window.location.reload();
                    }
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    this.loading.canceling = false
                });
        }
    }
}
</script>
