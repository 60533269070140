<script setup>
import {generateRandomShortString} from "../../../helpers/common";
import StatsSkeletonItem from "./StatsSkeletonItem";
import {computed} from "vue";

const props = defineProps({
    numberOfItems: {
        type: Number,
        default: 3
    },
    gridCols: {
        type: [String, Number],
        default: 3
    },
    gap: {
        type: [String, Number],
        default: 4
    }
});

const gridColsClass = computed(() => {
    return 'grid lg:grid-cols-' + props.gridCols
})

const gapClass = computed(() => {
    return 'gap-' + props.gap
})

const fieldKey = generateRandomShortString();
</script>

<template>
    <div :class="[gridColsClass, gapClass]">
        <StatsSkeletonItem v-for="num in numberOfItems" :key="'stat-' + fieldKey + '-' + num"></StatsSkeletonItem>
    </div>
</template>
