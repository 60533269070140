<template>
    <modal
        :title="modalTitle"
        size="default"
        name="import_modal"
        @modalClose="modalClose"
    >
        <template #toggle>
            <btn v-if="modalButtonStyle === 'btn'" type="secondary">{{ modalButtonTitle }}</btn>
            <options-item v-else-if="modalButtonStyle === 'options-item'" :title="modalButtonTitle"></options-item>
        </template>
        <template #body>
            <div class="space-y-4">

                <alert type="error" v-if="errorMessage">{{ errorMessage }}</alert>
                <alert type="success" v-if="successMessage">{{ successMessage }}</alert>

                <div class="text-sm text-gray-500">
                    Effortlessly import {{ importTypeName }} list from CSV file, saving time and eliminating manual data
                    entry.
                </div>

                <template v-if="batchDetails && importInProgress">
                    <div class="h-1 relative rounded-full overflow-hidden">
                        <div class="w-full h-full bg-gray-200 absolute"></div>
                        <div class="h-full bg-teal-600 relative" :style="'width:' + batchDetails?.progress + '%'"></div>
                    </div>
                    <div class="flex justify-between">
                        <small>Import in progress ({{ batchDetails?.progress + '%' }})</small>
                        <btn
                            type="light"
                            iconName="close"
                            size="pill"
                            :disabled="cancelingImport"
                            @click="cancelImport"
                        >
                            Cancel
                        </btn>
                    </div>
                </template>

                <div class="rounded border bg-white"
                     :class="{'border-red-300':errorMessage}"
                >
                    <div class="flex justify-between p-2 pr-4 handle">
                        <div class="flex items-center space-x-4 truncate">
                            <div
                                class="flex justify-center items-center p-2 w-12 h-12 bg-gray-50 rounded border overflow-hidden">
                                <svg v-if="!loading && !importInProgress" xmlns="http://www.w3.org/2000/svg"
                                     width="24" height="24"
                                     fill="currentColor"
                                     class="bi bi-filetype-csv"
                                     viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                          d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"/>
                                </svg>
                                <svg v-if="loading || importInProgress" class="animate-spin h-8 w-8 text-gray-500"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path
                                        d="M3.055 13H5.07a7.002 7.002 0 0 0 13.858 0h2.016a9.001 9.001 0 0 1-17.89 0zm0-2a9.001 9.001 0 0 1 17.89 0H18.93a7.002 7.002 0 0 0-13.858 0H3.055z"/>
                                </svg>
                            </div>
                            <span class="text-sm truncate">{{ selectedFile ? selectedFile.name : 'Choose file' }}</span>
                        </div>
                        <div class="flex pl-4 space-x-4 items-center">
                            <button v-if="selectedFile" type="button" class="focus:outline-none" @click="clearFile">
                                <svg class="w-5 h-5 text-gray-500 hover:text-red-600"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path
                                        d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"></path>
                                </svg>
                            </button>
                            <label
                                class="inline-flex group overflow-hidden relative justify-center items-center">
                                <input
                                    class="block absolute opacity-0 cursor-pointer"
                                    type="file"
                                    ref="fileInput"
                                    name="csv_file"
                                    accept=".csv"
                                    @change="handleFileChange"
                                >
                                <svg class="w-5 h-5 text-gray-500 group-hover:text-gray-900"
                                     fill="currentColor"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 24 24"
                                     width="24"
                                     height="24"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path
                                        d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zm9-10v7h-2V9H6l6-6 6 6h-5z"/>
                                </svg>
                            </label>
                        </div>
                    </div>
                </div>

                <div @click.prevent="toggleShowInstructions()" class="flex justify-end">
                    <a href="" class="flex items-center hover:underline">
                        <span class="text-xs font-medium ml-1">
                            {{ showInstructions ? 'Hide instructions' : 'Show instructions' }}
                        </span>
                    </a>
                </div>

                <div v-show="showInstructions">
                    <slot name="import-instructions">No instructions provided</slot>
                </div>

                <alert type="error" v-if="formErrors.duplicateMappings">{{ formErrors.duplicateMappings }}</alert>
                <alert type="error" v-if="formErrors.unmappedHeaders">{{ formErrors.unmappedHeaders }}</alert>

                <div v-if="csvPreviewData.data.length > 0 && !importInProgress && !successMessage">
                    <p class="mb-5 font-bold text-gray-500">Match fields</p>
                    <div class="grid grid-cols-3 gap-x-8 gap-y-5">
                        <template v-for="csvHeader in csvMappedHeader" :key="csvHeader.csv_header_key">
                            <div class="col-span-2">
                                <div class="mb-1 text-sm text-gray-500">
                                    <strong>{{ csvHeader.csv_header_key }}</strong>
                                </div>
                                <div class="border border-gray-200 border-opacity-75 rounded">
                                    <div class="divide-y divide-gray-200 divide-opacity-75">
                                        <div v-for="(item, index) in csvPreviewData.data"
                                             :key="csvHeader.csv_header_key + '-' + index"
                                             class="text-xs text-black dark:text-white text-opacity-75 px-3 py-2">
                                            {{ item[csvHeader.csv_header_key] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <div class="mb-1 text-sm font-bold text-gray-500">
                                    Belongs to
                                </div>
                                <select
                                    :id="'columnsMap-' + csvHeader.csv_header_key"
                                    :name="'columnsMap-' + csvHeader.csv_header_key"
                                    class="block border border-gray-300 focus:ring-0 leading-4 py-1.5 rounded text-gray-500 text-xs w-full"
                                    v-model="csvHeader.corresponding_default_key"
                                >
                                    <option :value="null">Skip this column</option>
                                    <template v-if="modelHeaderConfig">
                                        <option
                                            v-for="defaultHeaderColumn in modelHeaderConfig"
                                            :value="defaultHeaderColumn.key"
                                            :disabled="isOptionDisabled(csvHeader.corresponding_default_key, defaultHeaderColumn.key)"
                                        >
                                            {{ defaultHeaderColumn.label }}
                                            {{ defaultHeaderColumn.required ? '' : '(optional)' }}
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <btn :loading="loading" @click="importData" type="primary">Import</btn>
        </template>
    </modal>
</template>

<script setup>
import useFilters from "../../../../state/common/filters-and-pagination";
import {getCurrentInstance, reactive, ref, toRaw} from "vue";

let errorMessage = ref(null)
const successMessage = ref(null)
const loading = ref(false)
const cancelingImport = ref(false)
let formErrors = reactive({
    unmappedHeaders: '',
    duplicateMappings: '',
})
const selectedFile = ref(null)
const fileInput = ref(null)
const modelHeaderConfig = ref(null)

let csvPreviewData = reactive({
    data: [],
    errors: [],
})

let csvMappedHeader = ref(null)
let csvFileDefaultHeader = ref(null)

const {proxy} = getCurrentInstance()
const emitter = proxy.emitter

const props = defineProps({
    program: {
        type: [Number, Array, Object],
        required: true
    },
    modalTitle: {
        type: String,
        default: 'Import'
    },
    importType: {
        type: String,
        required: true
    },
    importUrl: {
        type: String,
        required: true
    },
    modalButtonTitle: {
        type: String,
        default: 'Import',
    },
    modalButtonStyle: {
        default: 'btn'
    }
})

const isOptionDisabled = (currentValue, optionValue) => {
    if (currentValue === optionValue) {
        return false;
    }

    return csvMappedHeader.value.some(item => item.corresponding_default_key === optionValue);
}

let importTypeName = props.importType;
if (props.importType === 'refer-a-friend-customers') {
    importTypeName = 'Customers';
}

const handleFileChange = () => {
    const files = fileInput.value.files;
    if (files.length > 0) {
        selectedFile.value = files[0];

        if (loading.value) {
            return;
        }

        loading.value = true;

        const formData = new FormData();

        formData.append('csv_file', selectedFile.value);
        formData.append('import_type', props.importType);

        axios
            .post(route('app_int.import.import-configuration', {program: props.program}), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                errorMessage.value = null;
                const status = parseInt(response.data.status || 0);
                if (status === 1) {
                    csvPreviewData.data = response.data.data;
                    modelHeaderConfig.value = response.data.model_header_config;
                    csvMappedHeader.value = response.data.csv_mapped_header;
                    csvFileDefaultHeader.value = response.data.csv_file_default_header;
                } else {
                    errorMessage.value = 'Something went wrong';
                    clearFile();
                }
            })
            .catch(error => {
                let errors = error.response.data.error || error.response.data.errors || false;
                if (errors) {
                    errorMessage.value = errors;
                    if (typeof errors === 'object') {
                        errorMessage.value = Object.values(errors).flat().join(' ');
                    }
                }
            }).finally(() => loading.value = false);

    } else {
        selectedFile.value = null;
    }
};

const clearFile = () => {
    selectedFile.value = null;
    // Reset the input field to allow selecting the same file again
    fileInput.value.value = '';
    successMessage.value = null;
    errorMessage.value = null;
    csvPreviewData.data = [];
    csvPreviewData.errors = [];
    formErrors.unmappedHeaders = '';
    formErrors.duplicateMappings = '';
    if (batchDetails.value && batchDetails.value.finishedAt) {
        importInProgress.value = null;
        clearInterval(importStatusInterval.value);
    }
};

const scrollModalToTop = () => {
    const modal = document.getElementById('modal-body-import_modal'); // why this id -> check Modal component for body slot id
    if (modal) {
        modal.scrollTop = 0;
    }
}

const batchDetails = ref(null);
const importInProgress = ref(false);
const importStatusInterval = ref(null)

const getErrorMessage = (batchId) => {
    axios.get(route('app_int.import.batch.error-message', {program: props.program, batch_id: batchId}))
        .then(response => {
            errorMessage.value = errorMessage.value + ' ' + response.data;
        })
}

const importStatus = (batchId) => {
    axios.get(route('app_int.import.batch', {program: props.program, batch_id: batchId}))
        .then(response => {
            batchDetails.value = response.data;
            if (response.data.cancelledAt && response.data.failedJobs) {
                importInProgress.value = false;
                errorMessage.value = 'File import failed and canceled. Check the data and try again.';
                clearInterval(importStatusInterval.value);
                getErrorMessage(batchId);
            } else if (response.data.cancelledAt) {
                importInProgress.value = false;
                fileImportCanceled();
                clearInterval(importStatusInterval.value);
            }
            if (response.data.progress == 100 && response.data.finishedAt && !response.data.cancelledAt) {
                importInProgress.value = false;
                fileImportedSuccessfully();
                clearInterval(importStatusInterval.value);
            }
        })
        .catch(error => {
            let errors = error.response.data.error || error.response.data.errors || false;
            if (errors) {
                if (typeof errors === 'object') {
                    errorMessage.value = Object.values(errors).flat().join(' ');
                }
                errorMessage.value = errors;
            }
        }).finally(() => loading.value = false);
}

function fileImportedSuccessfully() {
    successMessage.value = 'File imported successfully.';
    clearBatchAndFileData();
}

function fileImportCanceled() {
    errorMessage.value = 'File import canceled.';
    clearBatchAndFileData();
}

function clearBatchAndFileData() {
    fetchParentComponentListData();
    batchDetails.value = null;
    setTimeout(function () {
        emitter.emit('modal-off', 'import_modal');
        clearFile();
    }, 2000);
}

const importData = () => {

    errorMessage.value = null;
    successMessage.value = null;

    if (loading.value || !valid()) {
        return;
    }

    loading.value = true;

    const formData = new FormData();

    formData.append('csv_file', selectedFile.value);
    formData.append('csv_file_default_header', JSON.stringify(csvFileDefaultHeader.value));
    formData.append('csv_mapped_header', JSON.stringify(csvMappedHeader.value));

    axios
        .post(props.importUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            const status = parseInt(response.data.status || 0);
            if (status === 1) {

                scrollModalToTop();

                batchDetails.value = response.data.batch;

                if (response.data.batch.progress === 100) {
                    fileImportedSuccessfully();
                } else {
                    importInProgress.value = true;
                    importStatusInterval.value = setInterval(() => {
                        importStatus(response.data.batch.id);
                    }, 3000);
                }
            } else {
                errorMessage.value = 'Failed to import' + props.importType + '. Please check the file and try again.';
            }
        })
        .catch(error => {
            let errors = error.response.data.error || error.response.data.errors || false;
            if (errors) {
                if (typeof errors === 'object') {
                    errors = Object.values(errors).flat().join(' ');
                }
                errorMessage.value = errors;
                scrollModalToTop();
            }
        }).finally(() => loading.value = false);
}

const valid = () => {
    let valid = true;
    formErrors.unmappedHeaders = '';
    formErrors.duplicateMappings = '';

    if (selectedFile.value === null) {
        errorMessage.value = 'Please select a file to import.';
        return false;
    }

    if (csvMappedHeader.value === null
        || requiredHeadersAreNotMappedCorrectly()
        || importInProgress.value
    ) {
        valid = false;
    }

    return valid;
}

const getLabel = (header) => {
    if (header.optional_if) {
        if (Array.isArray(header.optional_if)) {
            return header.label + ' (optional if present: ' + toRaw(header.optional_if).join(' or ') + ')';
        }
        return header.label + ' (optional if present ' + header.optional_if + ')';
    }

    return header.label;
}

const requiredHeadersAreNotMappedCorrectly = () => {
    const mappedDefaultKeys = csvMappedHeader.value.map(header => header.corresponding_default_key).filter(Boolean);
    const unmappedHeaders = [];
    const duplicateMappings = [];

    modelHeaderConfig.value.forEach(header => {
        if (header.required === false || isOptionalHeader(mappedDefaultKeys, header)) {
            return;
        }
        if (!mappedDefaultKeys.includes(header.key)) {
            unmappedHeaders.push(getLabel(header));
        } else {
            const mappedHeaderCount = csvMappedHeader.value.filter(
                mappedHeader => mappedHeader.corresponding_default_key === header.key
            ).length;
            if (mappedHeaderCount > 1) {
                duplicateMappings.push(header.label);
            }
        }
    });

    if (unmappedHeaders.length > 0) {
        formErrors.unmappedHeaders = `The following headers are not mapped: ${unmappedHeaders.join(', ')}`;
    }
    if (duplicateMappings.length > 0) {
        formErrors.duplicateMappings = `The following headers are mapped more than once: ${duplicateMappings.join(', ')}`;
    }

    return unmappedHeaders.length > 0 || duplicateMappings.length > 0;
}

const isOptionalHeader = (mappedDefaultKeys, header) => {
    if (!header.optional_if) {
        return false;
    }

    if (Array.isArray(header.optional_if)) {
        return toRaw(header.optional_if).some(optionalIf => mappedDefaultKeys.includes(optionalIf));
    }

    return mappedDefaultKeys.includes(header.optional_if)
}

const cancelImport = () => {
    cancelingImport.value = true;
    if (batchDetails.value && batchDetails.value.id) {
        axios.post(route('app_int.import.cancel', {program: props.program, batch_id: batchDetails.value.id}))
            .then(response => {
                if (response.data.status === 1) {
                    batchDetails.value = null;
                    clearFile();
                } else {
                    errorMessage.value = response.data.message;
                }
            })
            .catch(error => {
                let errors = error.response.data.error || error.response.data.errors || false;
                if (errors) {
                    if (typeof errors === 'object') {
                        errors = Object.values(errors).flat().join(' ');
                    }
                    errorMessage.value = errors;
                }
            }).finally(() => {
            cancelingImport.value = false;
            loading.value = false
        });
    }
}

const modalClose = () => {
    clearFile();
}

const fetchParentComponentListData = () => {
    const {
        fetchDataBaseUrl,
        fetchDataFunction,
    } = useFilters()
    fetchDataFunction.value(fetchDataBaseUrl.value)
}

let showInstructions = ref(false)
const toggleShowInstructions = () => {
    showInstructions.value = !showInstructions.value
}

</script>
