<template>

    <div class="space-y-4">

        <header-bar title="Partner performance" size="xsmall"></header-bar>

        <div class="grid lg:grid-cols-3 gap-4">
            <white-card title="Visits" :value="this.data.affiliate_referred_visitors_clicks" :growth="this.data.affiliate_referred_visitors_clicks_growth"></white-card>

            <white-card title="Signups" :value="this.data.total_signups" :growth="this.data.total_signups_growth"></white-card>

            <white-card title="Click-to-Signup conversion" :value="this.data.click_to_signup_conversion" :growth="this.data.click_to_signup_conversion_growth" :show-percentage="true"></white-card>

            <white-card title="Paid customers" :value="this.data.total_paid_accounts" :growth="this.data.total_paid_accounts_growth"></white-card>

            <white-card title="Signup-to-Sale conversion" :value="this.data.signup_to_sale_conversion" :growth="this.data.signup_to_sale_conversion_growth" :show-percentage="true"></white-card>

            <white-card title="Total sales" :value="this.data.total_purchases" :growth="this.data.total_purchases_growth"></white-card>
        </div>

    </div>

</template>
<script>
export default {
    name: 'AffiliateProgramAdditionalStats',
    props: ['dataUrl', 'program'],
    data() {
        return {
            errors: [],
            loading: false,
            timePeriod: 'total',
            data: {
                total_signups: 0,
                total_signups_growth: 0,
                total_paid_accounts: 0,
                total_paid_accounts_growth: 0,
                total_purchases: 0,
                total_purchases_growth: 0,
                affiliate_referred_visitors_clicks: 0,
                affiliate_referred_visitors_clicks_growth: 0,
                click_to_signup_conversion: 0,
                click_to_signup_conversion_growth: 0,
                signup_to_sale_conversion: 0,
                signup_to_sale_conversion_growth: 0,
            }
        }
    },
    created() {
        this.refreshData();
    },
    mounted() {
        this.emitter.on('affiliate-overview-time-period-updated', (timePeriod) => {
            this.timePeriod = timePeriod;
            this.refreshData();
        });
    },
    methods: {
        refreshData() {

            this.loading = true;

            axios
                .post(this.dataUrl, {filter: {time_period: this.timePeriod, program: this.program}})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.data.total_signups = response.data.data.total_signups ?? 0;
                        this.data.total_signups_growth = response.data.data.total_signups_growth ?? 0;
                        this.data.total_paid_accounts = response.data.data.total_paid_accounts ?? 0;
                        this.data.total_paid_accounts_growth = response.data.data.total_paid_accounts_growth ?? 0;
                        this.data.total_purchases = response.data.data.total_purchases ?? 0;
                        this.data.total_purchases_growth = response.data.data.total_purchases_growth ?? 0;
                        this.data.affiliate_referred_visitors_clicks = response.data.data.affiliate_referred_visitors_clicks ?? 0;
                        this.data.affiliate_referred_visitors_clicks_growth = response.data.data.affiliate_referred_visitors_clicks_growth ?? 0;
                        this.data.click_to_signup_conversion = response.data.data.click_to_signup_conversion ?? 0;
                        this.data.click_to_signup_conversion_growth = response.data.data.click_to_signup_conversion_growth ?? 0;
                        this.data.signup_to_sale_conversion = response.data.data.signup_to_sale_conversion ?? 0;
                        this.data.signup_to_sale_conversion_growth = response.data.data.signup_to_sale_conversion_growth ?? 0;
                    }
                })
                .catch(error => {
                    this.errors = error.response.data.errors || [];
                })
                .finally(() => this.loading = false);
        }
    }
}
</script>

<style scoped>

</style>
