<template>
    <nav class="border border-gray-100 border-t-0 rounded-b mx-4 py-4 flex overflow-auto divide-x" aria-label="Tabs">
        <slot />
    </nav>
</template>

<script>
export default {
    props: {
        name: {},
    },
}
</script>
