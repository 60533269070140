<template>
    <fieldset class="space-y-8">

        <radio-group
            name="program_leads_commission_type"
            id="program_leads_commission_type"
            v-model="settings.commission_type"
            :options="[
                {
                    value: 'follow_program_settings',
                    label: 'Follow program settings',
                    description: 'Reward partners for submitting leads using the default commission structure.'
                },
                {
                    value: 'custom',
                    label: 'Custom',
                    description: 'Set a custom reward for partners when their submitted leads convert into customers.'
                },
            ]"
        ></radio-group>


        <div v-if="settings.commission_type === 'custom'" class="space-y-4">
            <radio-group
                name="program_leads_custom_commission_type"
                id="program_leads_custom_commission_type"
                v-model="settings.custom_commission_type"
                :options="[
                    { value: 'percentage', label: 'Percentage', description: 'Reward your partners with percentage of a deal amount.'},
                    { value: 'static', label: 'Static', description: 'Reward your partners with a fixed amount.'},
                ]"
            ></radio-group>
            <div v-if="settings.commission_type === 'custom'">
                <div>
                    <div class="flex relative rounded-md mb-1">
                        <span
                            v-if="settings.custom_commission_type === 'percentage'"
                            class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">%</span>
                        <input
                            type="text"
                            placeholder="e.g. 10"
                            name="program_leads_commission_amount"
                            v-model="settings.commission_amount"
                            :required="true"
                            class="block w-full rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-0 focus:border-gray-400"
                            :class="{'rounded-l': !(settings.custom_commission_type === 'percentage')}"
                        />

                    </div>
                </div>
            </div>
        </div>

    </fieldset>
</template>

<script setup>
import {ref} from 'vue';

const props = defineProps({
    program_leads_commission_type: {
        type: String,
        required: true,
    },
    program_leads_custom_commission_type: {
        type: String,
        required: true,
    },
    program_leads_commission_amount: {
        type: [String, Number],
        required: false,
    }
});

const settings = ref({
    commission_amount: props.program_leads_commission_amount ?? null,
    commission_type: props.program_leads_commission_type ?? 'follow_program_settings',
    custom_commission_type: props.program_leads_custom_commission_type ?? 'static'
});
</script>

<style scoped>

</style>
