<script setup>
import {useAnalyticsOverviewStore} from "../../../../../../../stores/app/programs/analytics/AnalyticsOverview";
import {createRouteForTemplate} from "../../../../../../../helpers/common";

const analyticsOverviewStore = useAnalyticsOverviewStore();

</script>

<template>
    <div class="grid xl:grid-cols-3 gap-4">
        <div class="p-6 bg-white rounded border">
            <div class="mb-4 text-sm text-gray-900">Payouts requests</div>
            <h3 class="text-2xl xl:text-3xl font-semibold leading-tight dark:text-gray-300 flex gap-2 items-center">
                <div class="flex items-baseline">
                    <a :href="createRouteForTemplate('programs.payouts.list', analyticsOverviewStore.getProgram )"
                       class="hover:underline">
                        {{ analyticsOverviewStore.getAnalyticsOverviewPayoutsData['payoutsStats']?.['payoutRequests'] }}
                    </a>
                    <span class="ml-2 text-sm font-medium text-gray-500">
                        /
                        <a :href="createRouteForTemplate('programs.payouts.list', { program:analyticsOverviewStore.getProgram, status: analyticsOverviewStore.getAnalyticsOverviewPayoutsData['payoutStatuses']?.['requested']})" class="hover:underline">
                            {{ analyticsOverviewStore.getAnalyticsOverviewPayoutsData['payoutsStats']['waiting'] }}
                        </a>
                        waiting
                    </span>
                </div>
            </h3>
        </div>
        <div class="p-6 bg-white rounded border">
            <div class="mb-4 text-sm text-gray-900">Avg. reward per request</div>
            <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                <template v-for="avgReward in analyticsOverviewStore.getAnalyticsOverviewPayoutsData['payoutsStats']['avgRewardsPerRequest']">
                    <div :class="{ 'text-xl':analyticsOverviewStore.getAnalyticsOverviewPayoutsData['payoutsStats']['avgRewardsPerRequest'].length > 1 }">
                        {{ avgReward }}
                    </div>
                </template>
            </h3>
        </div>
        <div class="p-6 bg-white rounded border">
            <div class="mb-4 text-sm text-gray-900">Partners eligible for a payout</div>
            <h3 class="text-2xl xl:text-3xl font-semibold leading-tight dark:text-gray-300 flex gap-2 items-center">
                <div class="flex items-baseline">
                    <a :href="createRouteForTemplate('programs.partners.index', analyticsOverviewStore.getProgram)"
                       class="hover:underline"
                    >
                        {{ analyticsOverviewStore.getAnalyticsOverviewPayoutsData['payoutsEligiblePartnersStats']['total'] }}
                    </a>
                    <span class="ml-2 text-sm font-medium text-gray-500">/
                            <a :href="createRouteForTemplate('programs.partners.index', analyticsOverviewStore.getProgram)"
                               class="hover:underline"
                            >
                                {{ analyticsOverviewStore.getAnalyticsOverviewPayoutsData['payoutsEligiblePartnersStats']['neverAsked'] }}
                            </a>
                            never asked
                        </span>
                </div>
            </h3>
        </div>
    </div>
</template>
