import axios from 'axios';

export const main = {
    namespaced: true,
    state: () => ({
        fileSizeLimit: 0,
        acceptedMimeTypes: '',
    }),
    mutations: {
        setFileSizeLimit(state, limit) {
            state.fileSizeLimit = limit;
        },
        setAcceptedMimeTypes(state, mimeTypes) {
            state.acceptedMimeTypes = mimeTypes;
        }
    },
    actions: {
        getConfig({commit}) {
            axios.get('/filemanager/config')
                .then((response) => {
                    commit('setFileSizeLimit', response.data.data.fileSizeLimit);
                    commit('setAcceptedMimeTypes', response.data.data.acceptedMimeTypes);
                });
        }
    },
    getters: {

    }
}
