<script setup>

import {useAnalyticsOverviewStore} from "../../../../../../../stores/app/programs/analytics/AnalyticsOverview";

const analyticsOverviewStore = useAnalyticsOverviewStore();

const viewPartnerProfile = (url) => {
    window.location = url
}
</script>

<template>
    <div class="bg-white rounded border overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider"> Partner
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Signups
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Sales
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Rewards
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Generated revenue
                    </th>
                    <th scope="col"
                        class="pl-4 pr-8 py-3 text-right text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Joining date
                    </th>
                </tr>
                <tr class="h-1"></tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                <tr v-for="partner in analyticsOverviewStore.getAnalyticsOverviewTopPartnersData"
                    :key="'top-partner-' + partner.id"
                    class="hover:bg-gray-50 cursor-pointer group"
                    @click="viewPartnerProfile(partner.url)"
                >
                    <td class="px-4 py-4 whitespace-nowrap font-medium text-sm text-gray-900 group-hover:text-teal-600">
                        <div class="flex items-center">
                            <div class="flex-shrink-0 h-10 w-10">
                                <div
                                    class="rounded-full flex items-center justify-center font-medium text-gray-500 bg-gray-200 w-10 h-10">
                                    {{ partner['initials'] }}
                                </div>
                            </div>
                            <div class="ml-4">
                                <div><span>{{ partner['fullName'] }}</span></div>
                                <div class="flex text-sm font-normal text-gray-500 mt-0.5">
                                    <div class="mr-1.5">{{ partner['email'] }}</div>
                                </div>
                            </div>
                        </div>
                    </td>

                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">{{ partner['signups'] }}</td>
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">{{ partner['sales'] }}</td>
                    <td class="px-4 py-4 whitespace-nowrap text-gray-500 text-sm text-gray-900">
                        <div v-for="reward in partner['rewardsCollected']" >{{ reward }}</div>
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-gray-500 text-sm text-gray-900">
                        <div v-for="revenue in partner['generatedRevenue']" >{{ revenue }}</div>
                    </td>
                    <td class="pl-4 pr-8 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <time :datetime="partner['joiningDate']">{{ partner['joiningDate'] }}</time>
                    </td>
                </tr>
                </tbody>
            </table>
    </div>
</template>
