<template>
    <filters-and-pagination
        :pagination-data="filtersAndPaginationData"
        :filterable-fields="filterFields"
        :data-exists="initialDataState"
        :loading-data="loadingFiltersAndPaginationData"
        model-name="program_lead"
    >
        <data-table
            :program="program"
            :leads="filtersAndPaginationData.data"
            :table-headers="tableHeaders"

            @lead-changed="leadChanged"
            @lead-deleted="leadDeleted"

            :program-leads-commission-type="programLeadsCommissionType"
            :program-leads-custom-commission-type="programLeadsCustomCommissionType"
            :program-leads-commission-amount="programLeadsCommissionAmount"
        />
    </filters-and-pagination>
</template>

<script>
import {onMounted, ref, toRef, watch} from "vue";
import FiltersAndPagination from "../../filters-and-pagination/FiltersAndPagination";
import useRecentActivities from "../../../../composables/common/filter-and-pagination-data";
import useFiltersAndPagination from "../../../../state/common/filters-and-pagination";
import dataTable from "./DataTable.vue";
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";

export default {
    name: "LeadsIndex",

    components: {
        dataTable,
        FiltersAndPagination,
    },

    props: {
        program: {
            type: [Number, String],
            required: true,
        },
        leadsUrl: {
            default: ''
        },
        leadsDeleteUrl: {
            default: ''
        },
        leadsExportUrl: {
            default: ''
        },
        filterFields: {
            default: {}
        },
        tableHeaders: {
            default: []
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        },

        programLeadsCommissionType: {
            type: [String, null],
            default: null
        },
        programLeadsCustomCommissionType: {
            type: [String, null],
            default: null
        },
        programLeadsCommissionAmount: {
            type: [Number, String, null],
            default: null
        },
    },

    setup: function (props) {

        const program = toRef(props.program);
        const leadsUrl = toRef(props, 'leadsUrl');
        const leadsDeleteUrl = toRef(props, 'leadsDeleteUrl');
        const leadsExportUrl = toRef(props, 'leadsExportUrl');
        const filterFields = toRef(props, 'filterFields');

        const {
            loadingFiltersAndPaginationData,
            filtersAndPaginationData,
            getFiltersAndPaginationData
        } = useRecentActivities();

        const {setDataBaseUrl, setFetchDataFunction} = useFiltersAndPagination();
        const {deleteSetup, exportSetup, checkForPreviousExport} = useTableDataExporter();

        const initialDataState = ref(!!props.dataExists)

        onMounted(() => {
            setDataBaseUrl(leadsUrl.value);
            setFetchDataFunction(getFiltersAndPaginationData);
            deleteSetup.value.deleteUrl = leadsDeleteUrl.value;

            exportSetup.value.exportModelNameSingular = 'lead';
            exportSetup.value.exportModelNamePlural = 'leads';
            exportSetup.value.exportUrl = leadsExportUrl.value;
            checkForPreviousExport();

        })

        watch(filtersAndPaginationData, (newValue) => {
            if (newValue?.data?.length) {
                initialDataState.value = true;
            }
        })

        return {
            program,
            filtersAndPaginationData,
            loadingFiltersAndPaginationData,
            filterFields,
            initialDataState
        }
    },
    methods: {
        leadChanged(lead, index, newValue) {
            this.filtersAndPaginationData.data[index] = newValue;
        },
        leadDeleted(lead, index) {
            this.filtersAndPaginationData.data.splice(index, 1);
        }
    }
}
</script>

