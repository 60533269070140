<template>
    <div class="grid gap-4 mb-6 xl:grid-cols-2 md:mb-8 lg:mb-10">
        <div class="grid xl:col-span-2 gap-4 mb-4 lg:grid-cols-3 xl:mb-0">
            <div
                class="p-6 bg-white rounded-md border transition-shadow duration-200 dark:bg-transparent dark:border-gray-800">
                <div class="mb-4 text-sm text-gray-900">Transactions</div>
                <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                    {{ customerStats.transactions_count }}
                </h3>
            </div>
            <div
                class="p-6 bg-white rounded-md border transition-shadow duration-200 dark:bg-transparent dark:border-gray-800">
                <div class="mb-4 text-sm text-gray-900">Spent</div>
                <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                    <div v-for="spent in customerStats.total_spent">{{ spent }}</div>
                </h3>
                <div class="text-sm font-normal text-gray-500">
                    {{ totalSpentPendingString }} in review
                </div>
            </div>
            <white-card
                type="teal"
                :title="'Generated rewards'"
                :value="customerStats.generated_rewards"
                :currency="true"
                :show-all-values="true"
                extra-title="in review"
                :extra-value="pendingRewardsString"
                extra-text-color="white"
            />
        </div>

    </div>
</template>

<script>
import WhiteCard from "../../../../../elements/WhiteCard.vue";

export default {
    name: "CustomerActivityStats",
    components: {WhiteCard},
    props: {
        customerStats: {
            type: Object,
            default: {
                transactions_count: 0,
                total_spent: 0,
                total_spent_pending: 0,
                generated_rewards: 0,
                pending_rewards: 0
            }
        }
    },
    computed: {
        pendingRewardsString() {
            if (this.customerStats.pending_rewards === 0) {
                return this.customerStats.pending_rewards;
            }
            let string = '';
            Object.values(this.customerStats.pending_rewards).forEach((reward, index) => {
                string += reward + (index < Object.values(this.customerStats.pending_rewards).length - 1 ? ',' : '');
            });
            return string;
        },
        totalSpentPendingString() {
            if (this.customerStats.total_spent_pending === 0) {
                return this.customerStats.total_spent_pending;
            }
            let string = '';
            Object.values(this.customerStats.total_spent_pending).forEach((spent, index) => {
                string += spent + (index < Object.values(this.customerStats.total_spent_pending).length - 1 ? ',' : '');
            });
            return string;
        }
    }
}
</script>
