<template>
  <div class="flex justify-between items-center px-10 py-10 space-x-8">
    <div class="space-y-2">
      <h3 class="text-lg font-medium">Reset program</h3>
      <p class="text-sm text-gray-500">Reset your program to its initial state. This action will revert all settings and
        information to the beginning.</p>
    </div>
    <div>
      <modal :title="'Reset a program'" size="small" name="program_reset_modal_main">
        <template #toggle>
          <btn type="light">Reset</btn>
        </template>
        <template #body>
          <div class="space-y-6">
            <div class="text-sm text-gray-500">
              Please select what would you like to reset.
            </div>

            <div class="space-y-4">
              <toggle title="Program stats" v-model="types.stats">
                <template #description>
                  If selected, all clicks and transactions will be deleted.
                </template>
              </toggle>
              <toggle :title="texts.partners_list" v-model="types.partners">
                <template #description>
                  {{ texts.partners_list_description }}
                </template>
              </toggle>
            </div>

          </div>
        </template>
        <template #footer>
          <btn @click="resetProgram" :loading="loading">Reset</btn>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgramReset",
  props: ['program', "programType"],
  data() {
    return {
      error: false,
      loading: false,
      types: {
        stats: false,
        partners: false,
      },
      confirmationText: '',
      texts: {
        partners_list: this.programType === 'refer_a_friend' ? 'Customer list' : 'Partner list',
        partners_list_description: this.programType === 'refer_a_friend' ? 'If selected, all customers will be deleted.' : 'If selected, all partners and their customers will be deleted.',
      }
    }
  },
  methods: {
    resetProgram: function () {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios
          .post(route('app_int.program.reset'), {
            program: this.program, types: {
              stats: this.types.stats,
              partners: this.types.partners,
            }
          })
          .then(response => {
            const status = parseInt(response.data.status || -1);
            if (status === 1) {
              this.emitter.emit('modal-off', 'program_reset_modal_main');
            }
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }
}
</script>

<style scoped>

</style>
