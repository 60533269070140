<template>
    <div class="flex items-start">
        <div class="flex-shrink-0 w-10 h-10">
            <img class="w-10 h-10 rounded" src="/images/integrations/amazon.png"/>
        </div>
        <div class="ml-5 w-full">
            <div class="flex justify-between">
                <span class="text-sm font-semibold">
                    Amazon Incentives
                </span>
                <badge title="Connected" v-if="connected"></badge>
            </div>
            <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                Give Amazon Gift Cards as reward to your referrers.
            </div>
            <div class="flex" v-if="!connected">
                <modal title="Amazon Incentives integration" size="large" name="amazon_gift_card_integration">
                    <template #toggle>
                        <btn type="secondary" size="small" class="-mr-px focus:z-10">Connect</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">

                            <alert type="error" v-if="connect_error">{{ connect_error }}</alert>

                            <h3 class="text-lg font-medium">About</h3>
                            <div class="text-sm text-gray-500">
                                Amazon Incentives allows to distribute Amazon Gift Cards on demand.
                            </div>

                            <hr/>

                            <h3 class="text-lg font-medium">Getting started</h3>
                            <div class="text-sm text-gray-500">
                                Create the Amazon API account on <a class="underline hover:no-underline" href="https://www.amazon.com/corporate-gift-cards/b?ie=UTF8&node=15243182011" target="_blank">Amazon Incentives</a> portal.
                            </div>

                            <h3 class="text-lg font-medium">Region</h3>
                            <div class="text-sm text-gray-500">
                                Select a region that your account belongs to.
                            </div>
                            <select
                                v-model="formData.endpoint"
                                :class="{'border-red-400': errors.endpoint}"
                                class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                            >
                                <option value="">Select a region</option>
                                <option v-for="endpoint in config.endpoints" :value="endpoint.value">{{ endpoint.title }} ({{ endpoint.description }})</option>
                            </select>
                            <small v-if="errors.endpoint" class="text-xs text-red-500">{{ errors.endpoint }}</small>

                            <h3 class="text-lg font-medium">Secret Key</h3>
                            <ol class="list-decimal ml-4 mb-4 text-gray-500 text-sm space-y-0.5">
                                <li>
                                    While logged in to your Amazon Incentives Portal account, look for the section related to API credentials or security.
                                </li>
                                <li>
                                    Generate or find your <span class="font-semibold">AWS Secret Key</span>. This secret key is used for securely signing your API requests.
                                </li>
                                <li>
                                    Copy the provided <span class="font-semibold">AWS Secret Key</span> value and paste it below:
                                </li>
                            </ol>
                            <input
                                v-model="formData.secret"
                                type="text"
                                required
                                :class="{'border-red-400': errors.secret}"
                                class="relative mt-3 block w-full -mr-px transition-shadow text-sm rounded focus:ring-0 focus:border-gray-400 border-gray-300"
                                placeholder="Enter the Secret Key here"
                            >
                            <small v-if="errors.secret" class="text-xs text-red-500">{{ errors.secret }}</small>

                            <h3 class="text-lg font-medium">Access Key</h3>
                            <ol class="list-decimal ml-4 mb-4 text-gray-500 text-sm space-y-0.5">
                                <li>
                                    While logged in to your Amazon Incentives Portal account, look for the section related to API credentials or security.
                                </li>
                                <li>
                                    Generate or locate your <span class="font-semibold">AWS Access Key</span>. This key is essential for API authentication.
                                </li>
                                <li>
                                    Copy the provided <span class="font-semibold">AWS Access Key</span> value and paste it below:
                                </li>
                            </ol>
                            <input
                                v-model="formData.key"
                                type="text"
                                required
                                :class="{'border-red-400': errors.key}"
                                class="relative mt-3 block w-full -mr-px transition-shadow text-sm rounded focus:ring-0 focus:border-gray-400 border-gray-300"
                                placeholder="Enter the Access Key here"
                            >
                            <small v-if="errors.key" class="text-xs text-red-500">{{ errors.key }}</small>

                            <h3 class="text-lg font-medium">Partner ID</h3>
                            <ol class="list-decimal ml-4 mb-4 text-gray-500 text-sm space-y-0.5">
                                <li>
                                    In the Amazon Incentives Portal account,  look for the right top of the page header, where your name and company information is displayed.
                                </li>
                                <li>
                                    Look for information labeled <span class="font-semibold">Partner ID</span>.
                                </li>
                                <li>
                                    Copy the provided account <span class="font-semibold">Partner ID</span> and paste it below:
                                </li>
                            </ol>
                            <input
                                v-model="formData.partner_id"
                                type="text"
                                required
                                :class="{'border-red-400': errors.partner_id}"
                                class="relative mt-3 block w-full -mr-px transition-shadow text-sm rounded focus:ring-0 focus:border-gray-400 border-gray-300"
                                placeholder="Enter the Partner ID here"
                            >
                            <small v-if="errors.partner_id" class="text-xs text-red-500">{{ errors.partner_id }}</small>

                        </div>
                    </template>
                    <template #footer>
                        <div class="flex justify-end">
                            <btn type="primary" :loading="loading" class="-mr-px focus:z-10" @click="connect">
                                Connect
                            </btn>
                        </div>
                    </template>
                </modal>
            </div>
            <div class="flex" v-if="connected">
                <modal :title="'Amazon Gift Card integration'" size="small" name="amazon_gift_card_integration">
                    <template #toggle>
                        <btn size="small" class="-mr-px focus:z-10 ml-auto">Disconnect</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <alert type="error" v-if="disconnect_error">{{ disconnect_error }}</alert>
                            <div class="text-sm text-gray-500">
                                Do you really want to disconect Amazon Gift Card?
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading" @click="disconnect">Yes, disconnect</btn>
                    </template>
                </modal>
            </div>


        </div>

    </div>

</template>
<script>
export default {
    name: 'IntegrationAmazonGiftCard',
    props: ['integrations_config'],
    data() {
        return {
            loading: false,
            disconnect_error: '',
            connect_error: '',
            config: this.integrations_config,
            connected: this.integrations_config.connected,
            formData: {
                endpoint: this.integrations_config.endpoint || '',
                key: this.integrations_config.key || '',
                secret: this.integrations_config.secret || '',
                partner_id: this.integrations_config.partner_id || '',
            },
            errors: {}
        }
    },
    methods: {
        connect() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            axios
                .post(this.config.urls.connect, this.formData)
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {

                        this.connected = true;
                        this.connect_error = false;

                        this.emitter.emit('modal-off', 'amazon_gift_card_integration');

                        this.config.groups = response.data.groups ?? [];
                    } else {
                        this.connect_error = 'Failed to connect';
                    }
                })
                .catch(error => {
                    this.errors = Object.fromEntries(Object.keys(error.response.data.errors || {}).map(key => [key, error.response.data.errors[key][0]]));
                })
                .finally(() => this.loading = false);
        },
        disconnect() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            axios
                .post(this.config.urls.disconnect, {
                    api_key: this.api_key,
                    server: this.server
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {

                        this.connected = false;
                        this.disconnect_error = false;

                        this.emitter.emit('modal-off', 'amazon_gift_card_integration');
                    } else {
                        this.disconnect_error = 'Failed to disconnect';
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.errors = error.response.data
                })
                .finally(() => this.loading = false);
        },
    }
}
</script>
<style scoped>
</style>
