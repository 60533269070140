<template>
    <div>
        <btn @click="showEnableModal" :loading="loading.two_fa_data" type="primary">Enable</btn>

        <modal title="Enable two-factor authentication" size="small" name="enable_2fa_modal">
            <template #body>
                <div class="space-y-6">
                    <div class="row align-items-start">

                        <div class="space-y-8">

                            <div class="text-sm">Scan the QR code below with any two-factor authentication app of your choice and enter the generated code. Alternatively, you can manually input the code into your authentication app.</div>

                            <div>
                                <img v-if="qrCode.length > 0" class="img-fluid border rounded w-1/3 mb-2" :src="qrCode" alt=""/>
                                <span class="text-sm text-gray-500">{{ google2aSecret }}</span>
                            </div>

                            <div class="space-y-4">

                                <text-field
                                    label="Generated 6-digit code"
                                    required="true"
                                    name="totp"
                                    field-id="totp"
                                    v-model="totp"
                                    v-on:keydown.enter.prevent="enable2FA"
                                    :error="errorsForInput('totp')"
                                    :error-message="errorsForInput('totp')"
                                ></text-field>

                                <text-field
                                    v-if="!is_social_login"
                                    type="password"
                                    show_reveal_password_icon="true"
                                    label="Current password"
                                    required="true"
                                    name="current_password"
                                    field-id="current_password"
                                    v-model="current_password"
                                    v-on:keydown.enter.prevent="enable2FA"
                                    :error="errorsForInput('current_password')"
                                    :error-message="errorsForInput('current_password')"
                                ></text-field>

                            </div>

                        </div>

                    </div>
                </div>
            </template>
            <template #footer>
                <btn type="primary" :loading="loading.setting_up" @click="enable2FA">Enable 2FA</btn>
            </template>
        </modal>
    </div>
</template>
<script>
import TextField from "../../../elements/forms/TextField.vue";

export default {
    components: {TextField},
    props: [
        'isSocialLogin',
        'twoFactorAuthEnableRoute',
        'twoFactorAuthGenerateSecretAndQrCodeRoute',
    ],
    data() {
        return {
            qrCode: '',
            google2aSecret: '',
            current_password: '',
            is_social_login: !!this.isSocialLogin,
            totp: '',
            errors: '',
            loading: {
                two_fa_data: false,
                setting_up: false,
            },
        }
    },
    methods: {
        showEnableModal() {

            if (this.loading.two_fa_data) {
                return;
            }

            this.loading.two_fa_data = true;

            axios.get(this.twoFactorAuthGenerateSecretAndQrCodeRoute)
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.qrCode = response.data.qrCode;
                        this.google2aSecret = response.data.google2aSecret;

                        this.emitter.emit('modal-on', 'enable_2fa_modal');
                    }
                })
                .finally(
                    () => {
                        this.loading.two_fa_data = false;
                    }
                );
        },
        enable2FA() {

            if (this.loading.setting_up) {
                return;
            }

            this.loading.setting_up = true;

            axios.post(this.twoFactorAuthEnableRoute, {
                current_password: this.current_password,
                google2fa_secret: this.google2aSecret,
                totp: this.totp
            })
                .then(response => {
                    location.reload();
                })
                .catch(error => {
                    this.errors = error.response.data;
                })
                .finally(
                    () => {
                        this.loading.setting_up = false;
                    }
                );
        },
        cancel() {
            this.totp = '';
            this.errors = '';
        },
        errorsForInput(input) {
            if (this.errors.errors && input in this.errors.errors) {
                return this.errors.errors[input][0];
            }
        },
    }
}
</script>
