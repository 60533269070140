<template>
    <label class="block text-sm mb-1.5">Sender email</label>
    <div class="flex">
        <div class="relative flex-1">
            <input
                type="text"
                name="emails_sender_email_name"
                :placeholder="senderNamePlaceholder"
                :readonly="selectedIsFixedGroup"
                :disabled="selectedIsFixedGroup"
                :class="{'bg-gray-50': selectedIsFixedGroup}"
                v-model="emailSettingsStore.settings.emails_sender_email_name"
                class="focus:z-10 w-full border border-r-0 border-gray-300 rounded-l text-sm focus:border-gray-400 focus:ring-0"/>
            <label
                class="absolute flex h-full items-center px-2 right-0 text-gray-500 top-0 pb-1">@</label>
        </div>
        <select
            v-model="senderGroupAndDomain"
            class="focus:z-10 border border-gray-300 rounded-r text-sm focus:border-gray-400 focus:ring-0">
            <optgroup v-for="domainGroup in domainGroups" :label="domainGroup.name">
                <option v-for="domain in domainGroup.domains"
                        :value="this.mergedGroupAndDomain(domainGroup.group, domain.name)">{{ domain.name }}
                </option>
            </optgroup>
        </select>
    </div>
</template>
<script>
import {useEmailSettingsStore} from "../../../stores/app/emails/settings/EmailSettingsStore";

export default {
    name: "EmailsSenderEmail",
    props: {
        getDomainsUrl: {
            type: String
        }
    },
    setup() {
        let emailSettingsStore = useEmailSettingsStore();
        return {emailSettingsStore};
    },
    data() {
        return {
            domainGroups: [],
            userNameGroups: [],
            loadingDomainsInProgress: false
        }
    },
    watch: {
        senderGroupAndDomain: function () {
            this.setFixedUsername();
        }
    },
    computed: {
        senderGroupAndDomain: {
            get() {
                return this.mergedGroupAndDomain(this.emailSettingsStore.settings?.emails_sender_email_group, this.emailSettingsStore.settings?.emails_sender_email_domain);
            },
            set(newValue) {
                return [this.emailSettingsStore.settings.emails_sender_email_group, this.emailSettingsStore.settings.emails_sender_email_domain] = newValue.split(';');
            }
        },
        senderNamePlaceholder() {
            return this.emailSettingsStore.program?.name.toLowerCase().replace(/\s/g, '');
        },
        selectedIsFixedGroup() {
            return this.emailSettingsStore.settings?.emails_sender_email_group === 'smtp'
                || this.emailSettingsStore.settings?.emails_sender_email_group === 'gmail';
        }
    },
    created() {
        const self = this;
        this.loadSenderDomains();
        this.emitter.on('email-gateway-update', function () {
            self.loadSenderDomains();
        });
    },
    methods: {
        loadSenderDomains() {
            this.loadingDomainsInProgress = true;
            axios
                .get(this.getDomainsUrl)
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1 && response.data.domain_groups) {
                        this.domainGroups = response.data.domain_groups;
                        this.userNameGroups = response.data.user_name_groups;

                        // default first option values
                        let group = this.domainGroups[0]?.group;
                        let domain = this.domainGroups[0]?.domains[0]?.name;

                        // if we have pre-defined option values
                        if (this.emailSettingsStore.settings?.emails_sender_email_group && this.emailSettingsStore.settings?.emails_sender_email_domain) {
                            group = this.emailSettingsStore.settings?.emails_sender_email_group;
                            domain = this.emailSettingsStore.settings?.emails_sender_email_domain;
                        }

                        this.senderGroupAndDomain = this.mergedGroupAndDomain(group, domain);
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loadingDomainsInProgress = false);
        },
        mergedGroupAndDomain(group, domain) {
            return (group && domain) ? group + ';' + domain : '';
        },
        setFixedUsername() {
            if(this.selectedIsFixedGroup) {

                let username = '';

                if (this.emailSettingsStore.settings?.emails_sender_email_group === 'smtp') {
                    username = this.userNameGroups.find(item => item.group === 'smtp')?.username;
                }

                if (this.emailSettingsStore.settings?.emails_sender_email_group === 'gmail') {
                    username = this.userNameGroups.find(item => item.group === 'gmail')?.username;
                }

                if(username) {
                    this.emailSettingsStore.settings.emails_sender_email_name = username;
                }
            }
        }
    }
}
</script>

