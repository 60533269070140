<script setup>
import {ref, defineProps} from "vue";

const {roles, programs, currentRole, currentPrograms, error} = defineProps({
    roles: {
        type: Object,
        default: () => {}
    },
    programs: {
        type: Object,
        default: () => {}
    },
    currentRole: {
        type: String,
        default: 'User'
    },
    currentPrograms: {
        type: Array,
        default: () => []
    },
    error: {
        type: String,
        default: ''
    }
})

const role = ref(currentRole)
const programIds = ref(currentPrograms)
</script>

<template>
    <div class="">
        <label class="block mb-1 text-sm">User type</label>
        <small :class="{'text-danger': error, 'text-gray-500': !error}" class="mb-3 block">Select a user type below or create a custom.</small>

        <div class="-space-y-px bg-white rounded-md">
            <radio-group
                name="role"
                :options="roles"
                v-model="role"
            ></radio-group>
        </div>

        <div v-if="role === 'User'" class="mt-10">
            <label class="block mb-1 text-sm">Select programs</label>
            <small :class="{'text-danger': error, 'text-gray-500': !error}" class="mb-3 block">Select programs to which user will have access.</small>

            <div class="grid grid-cols-2">

                <div v-for="(program, index) in programs" class="space-x-2 flex text-sm mb-3" :key="index">

                    <input type="checkbox"
                       :id="`program-${index}`"
                       name="program_ids[]"
                       :value="program.id"
                       v-model="programIds"
                       class="h-5 w-5 rounded cursor-pointer text-gray-600 border-gray-300 focus:ring-gray-100"
                    >

                    <label :for="`program-${index}`">{{ program.name }}</label>

                </div>

                <template v-if="!programs">
                    No programs to display
                </template>

            </div>

        </div>

<!--        <div class="mt-10 hidden">-->

<!--            <div class="">-->

<!--                <input type="radio"-->
<!--                       name="role"-->
<!--                       id="custom-role"-->
<!--                       data-toggle="collapse"-->
<!--                       data-target=".collapsePermissions:not(.show)"-->
<!--                       class="custom-control-input"-->
<!--                       value="{{ \MailerLite\Accounts\Role::CUSTOM }}" {{ old('role') ===  \MailerLite\Accounts\Role::CUSTOM ? 'checked' : '' }}-->
<!--                >-->

<!--                <label for="custom-role" class="custom-control-label pl-custom-control mb-1">Custom-->
<!--                    user</label>-->

<!--                <span-->
<!--                    class="text-sm d-flex pl-custom-control {{ $errors->has('permissions') ? 'text-danger' : 'text-muted' }}">Select the sections of your account that the user should have access to.</span>-->

<!--            </div>-->

<!--            <div class="grid grid-cols-2 {{ $errors->has('permissions') ? 'show' : '' }}">-->

<!--                @forelse($permissionGroups as $groupName => $permissions)-->

<!--                <div class="">-->

<!--                    <Label class="block mb-3 text-sm">{{ $groupName }}</Label>-->

<!--                    @foreach($permissions as $permission)-->

<!--                    <div class="space-x-2 flex text-sm mb-3">-->

<!--                        <input type="checkbox"-->
<!--                               id="{{ $permission->name }}"-->
<!--                               name="permissions[]"-->
<!--                               value="{{ $permission->name }}"-->
<!--                               class="h-5 w-5 rounded cursor-pointer text-gray-600 border-gray-300 focus:ring-gray-100"-->
<!--                        >-->

<!--                        <label-->
<!--                            for="{{ $permission->name }}">{{ $permission->label }}</label>-->

<!--                    </div>-->

<!--                    @endforeach-->

<!--                </div>-->

<!--                @empty-->

<!--                No permissions to display-->

<!--                @endforelse-->


<!--            </div>-->

<!--        </div>-->

    </div>
</template>

<script>

</script>

<style scoped>

</style>
