<template>
    <label
        class="flex relative p-4 border cursor-pointer"
        :class="{
            'bg-teal-50 border-gray-300 z-10': modelValue === value,
            'border-gray-300': modelValue !== value,
        }"
    >
        <input :disabled="disabled"
               type="radio"
               :name="name"
               :value="value"
               :checked="isChecked"
               @change="handleChange"
            class="h-4 w-4 mt-0.5 cursor-pointer text-teal-600 border-gray-300 focus:ring-teal-100 mr-3"
           :class="{
                'hidden': invisibleInput
           }"
            aria-labelledby="percent-label"
            aria-describedby="percent-description">
        <div class="flex flex-col">
            <span id="percent-label"
                class="block text-sm font-medium"
                :class="{
                    'text-gray-900': modelValue === value,
                    'text-gray-900': modelValue !== value,
                }"
            >
                {{ label }}
            </span>
            <span id="percent-description"
                class="block text-sm"
                :class="{
                    'text-gray-600': modelValue === value,
                    'text-gray-500': modelValue !== value,
                }"
            >
                <vue3-markdown-it :source='description' />
            </span>
        </div>
    </label>
</template>

<script>

export default {
    emits: ['update:modelValue'],

    props: {
        disabled: {
            default: false,
        },
        invisibleInput: {
            default: false,
        },
        size: {
            default: 'default',
        },
        name: '',
        description: '',
        value: {},
        modelValue: {},
        label: {
            default: false
        }
    },
    data() {
        return {
            sizing: {
                medium: { input: 'py-2.5 text-base', error: 'text-xs' },
                default: { input: 'py-2 text-base', error: 'text-xs' },
            },
        }
    },
    computed: {
        isChecked() {
            return this.modelValue === this.value;
        },
    },
    methods: {
        handleChange(event) {
            this.$emit('update:modelValue', event.target.value);
        },
    },
}
</script>

<style scoped>

</style>
