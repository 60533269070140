import {computed} from "vue";

// this will generate getters for all state properties
// i.e.
//
// const state = reactive({
//     search: ''
// })
//
// result will be: computed(() => state.search)
// and will act like a getter from the "store"
export const withState = (target, state) => {
    Object.keys(state).forEach(prop => {
        target[prop] = computed(() => state[prop])
    })
    return target
}
