export const fileUpload = {
    namespaced: true,
    state: () => ({
        uploadFiles: [],
    }),
    mutations: {
        addUploadFile(state, file) {
            state.uploadFiles.push(file);
        },
        updateUploadFile(state, file) {
            state.uploadFiles = state.uploadFiles.map((f) => {
                return (f.id === file.id) ? file : f;
            })
        },
    },
    actions: {

    },
    getters: {

    }
}
