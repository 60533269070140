<template>
    <div class="flex items-center justify-between pb-5">
        <div>
            <h3 class="text-lg font-semibold text-gray-900">{{ emailStore.email_subject }}</h3>
        </div>
        <div class="flex items-center justify-between gap-4">
            <div class="flex justify-between items-center">
                <toggle
                    name="program_approval_form"
                    :model-value="emailStore.get_autosave"
                    @update:modelValue="emailStore.updateAutosave($event)"
                />
                <span class="flex flex-col flex-grow" id="availability-label">
                    <span class="text-sm font-medium text-gray-900">Save content automatically</span>
                </span>
            </div>

            <!-- This view when content is saved automatically -->
            <auto-save-menu v-if="emailStore.get_autosave"/>
            <!-- Manual saving -->
            <manual-save-menu v-else />
        </div>
    </div>
</template>

<script setup>
import { useRevolvappEmailStore } from "../../../../../stores/app/emails/revolvapp/RevolvAppEmailStore";
import AutoSaveMenu from "./header-components/AutoSaveMenu";
import ManualSaveMenu from "./header-components/ManualSaveMenu";

let emailStore = useRevolvappEmailStore()
</script>

