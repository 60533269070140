<template>
    <slot name="navigation" />
    <div class="border rounded rounded-t-none relative">
        <slot v-if="$slots.tabs" name="tabs" />
        <div
            :class="[
                inset === 'none' ? '' : 'px-10 py-14'
            ]"
        >
            <slot name="body" />
        </div>

        <div v-if="$slots.footer" class="bg-gray-50 px-10 py-4 text-right rounded-b sticky bottom-0 z-40">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        inset: {
            type: String,
        },
    },
}
</script>
