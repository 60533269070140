<template>

    <div class="space-y-4">
<!--    <header-bar title="Long term insights" size="xsmall"></header-bar>-->
        <div class="p-6 bg-white border rounded h-full flex flex-col">
            <div class="flex items-center mb-5" v-if="hasData">
                <div class="grid gap-4 md:grid-cols-3 flex-1 xl:grid-cols-12">
                    <div class="xl:col-span-6">
                        <div>
                            <btn class="flex-grow rounded-r-none" :type="showRevenueChart ? 'secondary-green' : 'secondary'" @click="visibleChart = 'revenue'">
                                <span class="text-xs">Revenue</span>
                            </btn>
                            <btn class="flex-grow  border-l-0 rounded-l-none text-xs" :type="showSignupChart ? 'secondary-green' : 'secondary'" @click="visibleChart = 'signup'">
                                <span class="text-xs">Signups</span>
                            </btn>
                        </div>
                    </div>
                    <div class="xl:col-span-6">
                        <div class="flex h-full items-center">
                            <select
                                id="chart_group_type"
                                name="chart_group_type"
                                class="block border-0 focus:ring-0 leading-4 ml-auto px-8 py-1.5 rounded text-right text-xs text-gray-500"
                                v-model="filter.group_type"
                                @change="refreshData"
                            >
                                <option value="yearly">Yearly</option>
                                <option value="monthly">Monthly</option>
                                <option value="daily">Daily</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <chart-skeleton v-if="loading"></chart-skeleton>
            <template v-else>
                <div v-if="showSignupChart">
                    <apexchart
                        v-if="singupChart"
                        :width="singupChart.width"
                        :height="singupChart.height"
                        :type="singupChart.type"
                        :options="singupChart.options"
                        :series="singupChart.series">
                    </apexchart>
                </div>
                <div v-if="showRevenueChart">
                    <apexchart
                        v-if="revenueChart"
                        :width="revenueChart.width"
                        :height="revenueChart.height"
                        :type="revenueChart.type"
                        :options="revenueChart.options"
                        :series="revenueChart.series">
                    </apexchart>
                </div>
            </template>
        </div>

    </div>

</template>

<script>
import moment from 'moment-timezone';
import { formatNumber } from '../../../helpers/common.js';
import HeaderBar from "../../../elements/HeaderBar";
import ChartSkeleton from "../../common/skeletons/ChartSkeleton";

export default {
    name: 'AccountInsights',
    components: {HeaderBar, ChartSkeleton},
    props: {
        program: {
            type: [Number, String],
            default: ''
        }
    },
    data () {
        return {
            errors: [],
            loading: false,
            hasData: true,
            visibleChart: 'revenue',
            singupChart: false,
            revenueChart: false,
            chartStroke: {
                'curve' : 'smooth',
                width: 2
            },
            yAxisLabelFormat: {
                labels: {
                    formatter: (value) => formatNumber(value)
                }
            },
            filter: {
                group_type: 'monthly',
                date_range: {
                    from: null,
                    to: moment()
                },
                program: this.program
            },
            dataUrl: route('app_int.views.account_or_program_insights'),
            datepickerRanges: {
                'total': [null, moment()],
                'today': [moment().startOf('day'), moment().endOf('day')],
                'yesterday': [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
                'this_month': [moment().startOf('month'), moment()],
                'last_month': [moment().startOf('month').subtract(1, 'month'), moment().endOf('month').subtract(1, 'month')],
                'last_6_months': [moment().endOf('month').subtract(6, 'month').startOf('month'), moment().endOf('month')],
                'this_year': [moment.utc().startOf('year'), moment()],
                'last_year': [moment.utc().startOf('year').subtract(1, 'year'), moment().utc().endOf('year').subtract(1, 'year')],
                'last_7_days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()]
            },
            currencyArray: [
                {
                    name: 'USD',
                    symbol: '$'
                },
                {
                    name: 'EUR',
                    symbol: '€'
                }
            ]
        }
    },
    computed: {
        showRevenueChart() {
            return this.visibleChart === 'revenue';
        },
        showSignupChart() {
            return this.visibleChart === 'signup';
        }
    },
    created() {
        if (this.hasData) {
            this.refreshData();
        }
    },
    mounted() {
        this.emitter.on('affiliate-overview-time-period-updated', (timePeriod) => {
            if (this.datepickerRanges[timePeriod]) {
                this.filter.date_range.from = this.datepickerRanges[timePeriod][0]?.format('YYYY-MM-DD');
                this.filter.date_range.to = this.datepickerRanges[timePeriod][1].format('YYYY-MM-DD');

                this.refreshData();
            }
        });
    },
    methods: {
        formatNumber,
        refreshData() {

            let _this = this;
            this.loading = true;

            axios
                .post(this.dataUrl, {filter: this.filter})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.singupChart = response.data.signupChart;
                        this.singupChart.options.stroke = this.chartStroke;
                        this.singupChart.options.yaxis = this.yAxisLabelFormat;

                        if (response.data.currencyList && response.data.currencyList.length > 0) {
                            this.currencyArray = response.data.currencyList;
                        }

                        this.revenueChart = response.data.revenueChart;
                        this.revenueChart.options.stroke = this.chartStroke;
                        this.revenueChart.options.yaxis = this.yAxisLabelFormat;
                        this.revenueChart.options.tooltip = {
                            y : {
                                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                                    return _this.getCurrencyFromSeriesTitle(seriesIndex)
                                            + formatNumber(value);
                                }
                            }
                        };
                    }
                })
                .catch(error => {
                    this.errors = error?.response?.data?.errors || [];
                })
                .finally(() => this.loading = false);
        },
        getCurrencyFromSeriesTitle(seriesIndex) {
            let symbol = '';
            this.currencyArray.forEach((currency) => {
                if(this.revenueChart.series[seriesIndex].name.includes(currency.name)) {
                    symbol = currency.symbol;
                }
            });
            return symbol;
        }
    }
}
</script>
