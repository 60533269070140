<template>

    <div class="space-y-6">

        <alert type="neutral">
            <div class="space-y-2">
                <p>Choose this method if you are using PayPal Pay Links and Buttons to accept payments on your website. This integration method requires to enable <b>transaction creation via JS calls</b> setting.</p>
                <p><b>Please note</b> that statistics will be displayed in Partnero after the first purchase is completed by the referred customer.</p>
            </div>
        </alert>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>
        </div>

        <div class="space-y-4 border-t pt-6">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Update PayPal settings</h3>
                <div class="text-sm space-y-2">
                    <p>Make necessary changes in your PayPal account settings.</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Log in to your <b>PayPal</b> account</li>
                        <li>Go to <b>Account Settings</b> from the upper right corner</li>
                        <li>Go to <b>Website payments</b> section</li>
                        <li>Locate <b>Website preferences</b> and click <b>Update</b></li>
                        <li>Switch <b>Auto return</b> to <b>"On"</b> and enter the URL of the return/thank you page</li>
                        <li>Switch <b>Payment data transfer</b> to <b>"On"</b></li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="space-y-4 border-t pt-6">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Set up tracking of PayPal purchases in return page</h3>
                <div class="text-sm space-y-2">
                    <p>Identify purchases made by referred buyers by fetching purchase from return/thank you page. The page should be under the same domain where your referred visitors go.</p>
                </div>
            </div>

            <div class="space-y-4">
                <div class="relative">
                    <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-1').value)">
                        <tooltip content="Copied" method="click">
                            <btn type="secondary" size="pill">Copy</btn>
                        </tooltip>
                    </div>
                    <textarea id="snippet-1" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script>
(function() {
        const cookieValue = (document.cookie.match(/(^| )partnero_partner=([^;]+)/) || [])[2];

        function getURLParameter(name) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(name);
        }

        if (cookieValue) {
            const transactionID = getURLParameter('tx') || getURLParameter('txn_id');
            const amount = getURLParameter('amt') || getURLParameter('payment_gross');
            const payerID = getURLParameter('PayerID') || getURLParameter('payer_id');
            const payerEmail = getURLParameter('payer_email');
            const firstName = getURLParameter('first_name');
            const lastName = getURLParameter('last_name');

            if (transactionID && amount && payerEmail) {
                po('transactions', 'create', {
                    data: {
                        key: 'paypal_' + transactionID,
                        amount: amount,
                        action: 'sale',
                        customer: {
                            key:  payerID || payerEmail,
                            email: payerEmail,
                            name: `${firstName || ''} ${lastName || ''}`.trim()
                        }
                    },
                    options: {
                        create_customer: true
                    }
                });
            }
        }
    })();
</script></textarea>

                    <small class="text-gray-500 mt-1 block">
                        Include the following script on returning/thank you page. It's important to place
                        this script at the bottom of the page, directly above the closing <span
                        class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">&lt;/body&gt;</span>
                        tag for optimal functionality.            </small>
                </div>
            </div>
        </div>

    </div>
</template>


