<template>
    <div>
        <div class="flex flex-col lg:flex-row gap-10">
            <div class="hidden lg:block lg:w-3/12 xl:w-2/12 pt-2">
                <FilemanagerTypeFilter/>
                <hr class="my-6">
                <FilemanagerFolders/>
            </div>
            <div class="lg:w-9/12 xl:w-10/12 space-y-6">
                <FilemanagerActions/>
                <FilemanagerOptions/>
                <FilemanagerList/>
            </div>
        </div>
        <FilemanagerModals/>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import {defineAsyncComponent} from 'vue'

export default {
    name: 'Filemanager',
    components: {
        FilemanagerModals: defineAsyncComponent(() => import('./Modals/Modals')),
        FilemanagerActions: defineAsyncComponent(() => import('./FilemanagerActions')),
        FilemanagerList: defineAsyncComponent(() => import('./FilemanagerList')),
        FilemanagerOptions: defineAsyncComponent(() => import('./FilemanagerOptions')),
        FilemanagerTypeFilter: defineAsyncComponent(() => import('./FilemanagerTypeFilter')),
        FilemanagerFolders: defineAsyncComponent(() => import('./FilemanagerFolders')),
    },
    mounted() {
        this.getFolders()
        this.getConfig()
    },
    methods: {
        ...mapActions('folder', [
            'getFolders',
        ]),
        ...mapActions('main', ['getConfig']),
    },
}
</script>
