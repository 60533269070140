<template>
    <div class="relative">

        <div @click="show = true" class="h-full">
            <slot name="toggle">

            </slot>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div v-show="show"
                 class="bg-white border border-gray-200 rounded-r shadow-lg absolute py-1 whitespace-nowrap z-50 max-w-64 bottom-0 top-auto dark:bg-gray-900 dark:border-gray-800"
                 :class="isRight ? 'left-20' : 'left-0', position"
            >
                <ul v-if="$slots.items">
                    <slot name="items"></slot>
                </ul>
            </div>
        </transition>
        <div v-if="show" @click="show = false" class="fixed left-0 top-0 w-full h-screen z-40"></div>
    </div>
</template>

<script>
import Btn from "../Btn";

export default {

    components: { Btn },

    props: {
        isRight: {
            default: true,
        },
        position: {
            default: '',
        }
    },

    data: function () {
        return {
            show: false
        }
    }
}
</script>

<style scoped>

</style>
