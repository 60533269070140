<template>

    <div class="flex space-x-2 mb-6">
        <select id="commission_period" @change="timePeriodChange($event)" name="time_period"
                v-model="timePeriod"
                class="-mt-2 xl:-mt-4 -mr-4 block border-0 focus:ring-0 leading-4 ml-auto px-8 py-1.5 rounded text-right text-xs text-gray-500">
            <option value="total" selected>Total</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="this_month">This month</option>
            <option value="last_month">Last month</option>
            <option value="last_6_months">Last 6 months</option>
            <option value="this_year">This year</option>
            <option value="last_year">Last year</option>
        </select>
    </div>

    <div class="space-y-6">
        <div>
            <div class="grid xl:grid-cols-3 gap-4">

                <div class="grid lg:grid-cols-2 xl:col-span-2 gap-4">

                    <div class="p-6 bg-white rounded border">
                        <div class="mb-4 text-sm text-gray-900">Payout requests</div>
                        <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                            <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                                {{ data.payout_requests }}
                                <span v-if="data.payout_requests_growth < 0"
                                      class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-red-100 bg-opacity-50 font-normal align-top tracking-tight text-red-500">
                                    <svg class="inline w-2 h-2 text-red-600" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12h7l-8 8-8-8h7V4h2z"/></svg>
                                {{ data.payout_requests_growth }}%
                                </span>
                                <span v-else
                                      class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-teal-100 bg-opacity-50 font-normal align-top tracking-tight text-teal-600">
                                    <svg class="inline w-2 h-2 text-teal-600" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12v8h-2v-8H4l8-8 8 8z"/></svg>
                                    {{ data.payout_requests_growth }}%
                                </span>
                            </h3>
                        </h3>
                    </div>
                    <div
                        class="p-6 bg-white rounded border">
                        <div class="mb-4 text-sm text-gray-900">Approved requests</div>

                        <h3 class="text-2xl xl:text-3xl font-semibold leading-tight dark:text-gray-300 flex gap-2 items-center">
                            <div class="flex items-baseline">
                                {{ data.approved_requests }}
                                <span class="ml-2 text-sm font-medium text-gray-500">
                                    / {{ data.waiting_requests }} waiting
                                </span>
                            </div>
                            <span v-if="data.approved_requests_growth < 0"
                                  class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-red-100 bg-opacity-50 font-normal align-top tracking-tight text-red-500">
                                    <svg class="inline w-2 h-2 text-red-600" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12h7l-8 8-8-8h7V4h2z"/></svg>
                                {{ data.approved_requests_growth }}%
                            </span>
                            <span v-else
                                  class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-teal-100 bg-opacity-50 font-normal align-top tracking-tight text-teal-600">
                                    <svg class="inline w-2 h-2 text-teal-600" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12v8h-2v-8H4l8-8 8 8z"/></svg>
                                    {{ data.approved_requests_growth }}%
                            </span>
                        </h3>

                    </div>
                    <div class="p-6 bg-white rounded border">
                        <div class="mb-4 text-sm text-gray-900">Avg. customers per request</div>
                        <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                            {{ data.average_customers_per_request }}
                            <span v-if="data.average_customers_per_request_growth < 0"
                                  class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-red-100 bg-opacity-50 font-normal align-top tracking-tight text-red-500">
                                    <svg class="inline w-2 h-2 text-red-600" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12h7l-8 8-8-8h7V4h2z"/></svg>
                                {{ data.average_customers_per_request_growth }}%
                                </span>
                            <span v-else
                                  class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-teal-100 bg-opacity-50 font-normal align-top tracking-tight text-teal-600">
                                    <svg class="inline w-2 h-2 text-teal-600" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12v8h-2v-8H4l8-8 8 8z"/></svg>
                                    {{ data.average_customers_per_request_growth }}%
                            </span>
                        </h3>
                    </div>
                    <div class="p-6 bg-white rounded border">
                        <div class="mb-4 text-sm text-gray-900">Avg. reward per request</div>
                        <h3 class="text-2xl font-semibold leading-tight xl:text-3xl flex items-center gap-2">
                            <span>
                                <div v-for="averageReward in data.average_reward_per_request">
                                    {{ averageReward }}
                                </div>
                            </span>
                            <span v-if="data.average_reward_per_request_growth < 0"
                                  class="flex text-2xs font-medium px-1 py-0.5 rounded bg-red-100 bg-opacity-50 font-normal align-top tracking-tight text-red-500">
                                    <svg class="inline w-2 h-2 text-red-600" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12h7l-8 8-8-8h7V4h2z"/></svg>
                                {{ data.average_reward_per_request_growth }}%
                            </span>
                            <span v-else
                                  class="flex text-2xs font-medium px-1 py-0.5 rounded bg-teal-100 bg-opacity-50 font-normal align-top tracking-tight text-teal-600">
                                    <svg class="inline w-2 h-2 text-teal-600" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12v8h-2v-8H4l8-8 8 8z"/></svg>
                                    {{ data.average_reward_per_request_growth }}%
                            </span>
                        </h3>
                    </div>
                </div>
                <div>
                    <div class="p-6 h-full bg-teal-500 rounded-md transition-shadow duration-200 dark:bg-teal-600">
                        <div class="mb-4 text-sm text-white">Total amount sent</div>
                        <h3 class="text-2xl xl:text-3xl font-semibold leading-tight flex items-center gap-2">
                            <span>
                                <div class="text-white" v-for="totalSent in data.total_amount_sent">
                                    {{ totalSent }}
                                </div>
                            </span>
                            <span v-if="data.total_amount_sent_growth < 0"
                                  class="flex text-2xs font-medium px-1 py-0.5 rounded bg-red-100 bg-opacity-50 font-normal align-top tracking-tight text-red-500">
                                    <svg class="inline w-2 h-2 text-red-600" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12h7l-8 8-8-8h7V4h2z"/></svg>
                                {{ data.total_amount_sent_growth }}%
                            </span>
                            <span v-else
                                  class="flex text-2xs font-medium px-1 py-0.5 rounded bg-teal-100 bg-opacity-50 font-normal align-top tracking-tight text-teal-600">
                                    <svg class="inline w-2 h-2 text-teal-600" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12v8h-2v-8H4l8-8 8 8z"/></svg>
                                    {{ data.total_amount_sent_growth }}%
                            </span>
                        </h3>

                        <div class="border-b border-white border-opacity-10 my-8"></div>

                        <div class="mb-3 text-sm text-white">Pending amount</div>
                        <h3 class="text-2xl  xl:text-3xl font-semibold leading-tight text-white mb-1 flex gap-2 items-center">
                            <span>
                                <div class="text-white" v-for="totalPending in data.pending_amount">
                                    {{ totalPending }}
                                </div>
                            </span>
                            <span v-if="data.pending_amount_growth < 0"
                                  class="flex text-2xs font-medium px-1 py-0.5 rounded bg-red-100 bg-opacity-50 font-normal align-top tracking-tight text-red-500">
                                        <svg class="inline w-2 h-2 text-red-600" fill="currentColor"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                            fill="none" d="M0 0h24v24H0z"/><path d="M13 12h7l-8 8-8-8h7V4h2z"/></svg>
                                    {{ data.pending_amount_growth }}%
                                    </span>
                            <span v-else
                                  class="flex text-2xs font-medium px-1 py-0.5 rounded bg-teal-100 bg-opacity-50 font-normal align-top tracking-tight text-teal-600">
                                        <svg class="inline w-2 h-2 text-teal-600" fill="currentColor"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                            fill="none" d="M0 0h24v24H0z"/><path d="M13 12v8h-2v-8H4l8-8 8 8z"/></svg>
                                        {{ data.pending_amount_growth }}%
                            </span>
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <payout-insights :program="program"/>

        <div class="flex items-center justify-between">
            <h3 class="text-lg font-semibold text-gray-900">Partner activity</h3>
        </div>

        <div>
            <div class="grid lg:grid-cols-2 gap-4">
                <div
                    class="p-6 bg-white rounded border">
                    <div class="mb-4 text-sm text-gray-900">Partners requesting payouts</div>
                    <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                        {{ data.partners_requesting_payouts }}
                    </h3>
                </div>
                <div
                    class="p-6 bg-white rounded border">
                    <div class="mb-4 text-sm text-gray-900">Partners eligible for a payout</div>
                    <h3 class="text-2xl xl:text-3xl font-semibold leading-tight dark:text-gray-300 flex gap-2 items-center">
                    <span>
                        <div class="flex items-baseline">
                            {{ data.eligible_partners_for_payout }}
                        <span class="ml-2 text-sm font-medium text-gray-500">/ {{ data.not_asked_for_payout }} not asked</span>
                        </div>
                    </span>
                    </h3>
                </div>
            </div>
        </div>


        <div class="flex items-center justify-between">
            <h3 class="text-lg font-semibold text-gray-900">Top partners</h3>
        </div>

        <payouts-analytics-top-partners :program="program"/>

    </div>
</template>
<script>
import PayoutsAnalyticsTopPartners from "../../../programs/payouts/components/TopPartners.vue";
import PayoutInsights from "./PayoutInsights.vue";

export default {
    name: 'payouts-analytics',
    components: {PayoutInsights, PayoutsAnalyticsTopPartners},
    props: {
        program: {
            type: [String, Number],
            required: true
        },
    },
    data() {
        return {
            errors: [],
            loading: false,
            timePeriod: 'total',
            isAccountsStats: this.account_stats,
            data: {
                payout_requests: 0,
                payout_requests_growth: 0,
                approved_requests: 0,
                approved_requests_growth: 0,
                waiting_requests: 0,
                average_customers_per_request: 0,
                average_customers_per_request_growth: 0,
                average_reward_per_request: 0,
                average_reward_per_request_growth: 0,
                total_amount_sent: 0,
                total_amount_sent_growth: 0,
                pending_amount: 0,
                pending_amount_growth: 0,
                partners_requesting_payouts: 0,
                eligible_partners_for_payout: 0,
                not_asked_for_payout: 0,
            }
        }
    },
    created() {
        this.refreshData();
    },
    methods: {
        refreshData() {

            this.loading = true;

            axios
                .post(route('app_int.views.payouts.analytics'),{filter: {time_period: this.timePeriod, program: this.program}})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.data.payout_requests = response.data.data.payout_requests ?? 0;
                        this.data.payout_requests_growth = response.data.data.payout_requests_growth ?? 0;
                        this.data.approved_requests = response.data.data.approved_requests ?? 0;
                        this.data.approved_requests_growth = response.data.data.approved_requests_growth ?? 0;
                        this.data.waiting_requests = response.data.data.waiting_requests ?? 0;
                        this.data.average_customers_per_request = response.data.data.average_customers_per_request ?? 0;
                        this.data.average_customers_per_request_growth = response.data.data.average_customers_per_request_growth ?? 0;
                        this.data.average_reward_per_request = response.data.data.average_reward_per_request ?? 0;
                        this.data.average_reward_per_request_growth = response.data.data.average_reward_per_request_growth ?? 0;
                        this.data.total_amount_sent = response.data.data.total_amount_sent ?? 0;
                        this.data.total_amount_sent_growth = response.data.data.total_amount_sent_growth ?? 0;
                        this.data.pending_amount = response.data.data.pending_amount ?? 0;
                        this.data.pending_amount_growth = response.data.data.pending_amount_growth ?? 0;
                        this.data.partners_requesting_payouts = response.data.data.partners_requesting_payouts ?? 0;
                        this.data.eligible_partners_for_payout = response.data.data.eligible_partners_for_payout ?? 0;
                        this.data.not_asked_for_payout = response.data.data.not_asked_for_payout ?? 0;
                    }
                })
                .catch(error => {
                    this.errors = error.response.data.errors || [];
                })
                .finally(() => this.loading = false);
        },
        timePeriodChange() {
            this.refreshData();
        }
    }
}
</script>

<style scoped>

</style>
