<template>

    <div class="bg-gradient-to-tr from-white via-gray-50 to-teal-50 border rounded p-4 lg:p-6 xl:p-10"
    >
        <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-semibold mb-2 uppercase">
            Partnero Integration assistant <badge type="neutral" title="Beta"></badge>
        </p>
        <h3 class="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug text-blue-gray-900">
            Run a check. Choose an integration.
        </h3>
        <p
            class="block antialiased font-sans text-base font-light leading-relaxed text-inherit mt-2 mb-6 !text-base font-normal text-gray-500"
        >
            Prepare your website for the Partnero integration.
        </p>

        <div class="space-y-3 mb-6">
            <div v-for="(siteUrl, index) in siteUrls" class="relative w-full lg:w-2/3">
                <text-field
                    v-model="siteUrls[index]"
                    placeholder="E.g. https://www.partnero.com"
                    :disabled="testInProgress"
                >
                    <template v-if="index === 0" #note>
                        Enter the URL of your website, ideally including your homepage and checkout pages, where you would like to integrate Partnero.
                    </template>
                </text-field>
                <btn v-if="siteUrls.length > 1" @click="removeSiteUrl(index)" :disabled="testInProgress" type="pill" class="absolute top-0.5 right-0" icon-name="trash"></btn>
            </div>
        </div>

        <div class="mb-4">
            <btn @click="addSiteUrl" :disabled="testInProgress" type="secondary" size="pill" icon-name="add-box" class="shrink-0 rounded-full">Add more links</btn>
        </div>
        <div>
            <btn :loading="testInProgress" :disabled="testInProgress" @click="checkIntegration">Run a check</btn>
        </div>

        <div class="space-y-6 border-t mt-6 pt-6" v-if="testResults.length > 0">
            <template v-for="testResult in testResults">
                <h4 class="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-blue-gray-900">
                    Test results for <strong>{{ testResult.siteUrl }}</strong>
                </h4>
                <ValidationErrors v-if="testResult.errors" :errors="testResult.errors"></ValidationErrors>
                <template v-if="testResult.checks">
                    <div v-if="testResult.checks.woocommerce_site === true" class="flex justify-start gap-4">
                        <div class="rounded w-12 h-12">
                            <img class="w-full h-full rounded" src="/images/integrations/woocommerce.png"/>
                        </div>
                        <div class="w-full">
                            <div class="text-sm font-semibold mb-0.5">WooCommerce site detected</div>
                            <div v-if="testResult.checks.partnero_universal_script" class="text-sm">
                                We have identified the Partnero integration on your website. Everything seems to be fine.
                            </div>
                            <div v-else>
                                <div class="text-sm mb-4">
                                    Looks like your website is running on WooCommerce. We suggest choosing either the official <a href="https://wordpress.org/plugins/partnero/" target="_blank" class="underline hover:no-underline">Partnero Affiliate Management</a> WooCommerce plugin or the Partnero Universal integration.
                                </div>
                                <div class="flex gap-2">
                                    <modal title="WooCommerce guide" size="large">
                                        <template #toggle>
                                            <btn type="secondary" size="pill">WooCommerce guide</btn>
                                        </template>
                                        <template #body>
                                            <guide-woocommerce></guide-woocommerce>
                                        </template>
                                    </modal>
                                    <modal title="Partnero Universal" size="large">
                                        <template #toggle>
                                            <btn type="secondary" size="pill">Partnero Universal guide</btn>
                                        </template>
                                        <template #body>
                                            <guide-universal>
                                                <template v-slot:universal_script>
                                                    <slot name="universal_script"></slot>
                                                </template>
                                            </guide-universal>
                                        </template>
                                    </modal>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="testResult.checks.shopify_site === true" class="flex justify-start gap-4">
                        <div class="rounded w-12 h-12">
                            <img class="w-full h-full rounded" src="/images/integrations/shopify.png"/>
                        </div>
                        <div class="w-full">
                            <div class="text-sm font-semibold mb-0.5">Shopify site detected</div>
                            <div v-if="testResult.checks.shopify_site_partnero_tracking">
                                We have identified the Partnero integration on your website. Everything seems to be fine.
                            </div>
                            <div v-else>
                                <div class="text-sm mb-4">
                                    Looks like your website is running on Shopify. We suggest installing the official <a href="https://apps.shopify.com/partnero-affiliate-management" target="_blank" class="underline hover:no-underline">Partnero Affiliate Management</a> app.
                                </div>
                                <modal title="Shopify guide" size="large">
                                    <template #toggle>
                                        <btn type="secondary" size="pill">Shopify guide</btn>
                                    </template>
                                    <template #body>
                                        <guide-shopify>
                                        </guide-shopify>
                                    </template>
                                </modal>
                            </div>
                        </div>
                    </div>
                    <template v-else>
                        <div class="flex justify-start gap-4">
                            <div class="rounded w-12 h-12">
                                <img class="w-full h-full rounded" src="/images/integrations/partnero.png"/>
                            </div>
                            <div v-if="testResult.checks.partnero_universal_script === true" class="w-full">
                                <div class="text-sm font-semibold mb-0.5">
                                    PartneroJS snippet is present
                                </div>
                                <div class="text-sm mb-4">
                                    We've identified the correct PartneroJS snippet on your website. This snippet allows us to  recognize referred visitors coming to your site.
                                </div>
                            </div>
                            <div v-else class="text-sm">
                                <div class="text-sm font-semibold mb-0.5">Partnero integration is not present yet</div>
                                <div>
                                    <div class="text-sm mb-4 space-y-1">
                                        <p>It seems you haven't started integrating with Partnero yet. We recommend beginning with the Partnero Universal integration.</p>
                                        <p>For more robust alternatives, you might consider trying the <a href="https://developers.partnero.com/guide/affiliate.html" target="_blank" class="underline hover:no-underline">Patnero API</a> or <a href="https://developers.partnero.com/guide/javascript-tracking.html" target="_blank" class="underline hover:no-underline">Partnero JS</a>.</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <modal title="Partnero Universal" size="large">
                                            <template #toggle>
                                                <btn type="secondary" size="pill">Partnero Universal guide</btn>
                                            </template>
                                            <template #body>
                                                <guide-universal>
                                                    <template v-slot:universal_script>
                                                        <slot name="universal_script"></slot>
                                                    </template>
                                                </guide-universal>
                                            </template>
                                        </modal>
                                        <modal title="Partnero API" size="large">
                                            <template #toggle>
                                                <btn type="secondary" size="pill">Partnero API guide</btn>
                                            </template>
                                            <template #body>
                                                <guide-api>
                                                    <template v-slot:universal_script>
                                                        <slot name="universal_script"></slot>
                                                    </template>
                                                </guide-api>
                                            </template>
                                        </modal>
                                        <modal title="Partnero JS" size="large">
                                            <template #toggle>
                                                <btn type="secondary" size="pill">Partnero JS guide</btn>
                                            </template>
                                            <template #body>
                                                <guide-javascript>
                                                    <template v-slot:universal_script>
                                                        <slot name="universal_script"></slot>
                                                    </template>
                                                </guide-javascript>
                                            </template>
                                        </modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="testResult.checks.stripe_payment_links === true" class="flex justify-start gap-4">
                            <div class="rounded w-12 h-12">
                                <img class="w-full h-full rounded" src="/images/integrations/stripe.png"/>
                            </div>
                            <div class="text-sm">
                                <div class="text-sm font-semibold mb-0.5">Stripe Payment Links are detected</div>
                                <div v-if="testResult.checks.stripe_payment_links_partnero_tracking">
                                    We have identified the correct Partnero integration with Stripe on your website. Everything seems to be fine.
                                </div>
                                <div v-else>
                                    <div class="text-sm mb-4 space-y-1">
                                        <p>Stripe Payment Links are detected on your site but we cannot find any other required tracking scripts. Please follow the Stripe guide.</p>
                                    </div>
                                    <modal title="Stripe via Payment links" size="large" class="inline-block">
                                        <template #toggle>
                                            <btn type="secondary" size="pill">Stripe via Payment links</btn>
                                        </template>
                                        <template #body>
                                            <guide-stripe-links>
                                                <template v-slot:universal_script>
                                                    <slot name="universal_script"></slot>
                                                </template>
                                            </guide-stripe-links>
                                        </template>
                                    </modal>
                                </div>
                            </div>
                        </div>
                        <div v-if="testResult.checks.partnero_js_signup_tracking === true" class="flex justify-start gap-4">
                            <div class="rounded w-12 h-12">
                                <img class="w-full h-full rounded" src="/images/integrations/partnero.png"/>
                            </div>
                            <div class="text-sm">
                                <div class="text-sm font-semibold mb-0.5">Partnero Universal sign-up tracking is present</div>
                                <div class="text-sm">
                                    It seems that your website has a script in place that identifies customers who sign up and sends their details to Partnero.
                                </div>
                            </div>
                        </div>
                        <div v-if="testResult.checks.partnero_js_transaction_tracking === true" class="flex justify-start gap-4">
                            <div class="rounded w-12 h-12">
                                <img class="w-full h-full rounded" src="/images/integrations/partnero.png"/>
                            </div>
                            <div class="text-sm">
                                <div>Partnero JS transaction tracking found!</div>
                            </div>
                        </div>

                        <div v-if="
                            testResult.checks.partnero_universal_script === true
                            && (
                                (testResult.checks.stripe_payment_links && testResult.checks.stripe_payment_links_partnero_tracking)
                            )" class="text-sm">
                            <alert type="success">
                                Your integration is complete. Good job!
                            </alert>
                        </div>

                        <div v-if="
                            testResult.checks.partnero_universal_script === true
                            && (
                                testResult.checks.partnero_js_signup_tracking
                            )">
                            <alert type="success">
                                Your integration for <b>sign-up tracking</b> is complete. Good job!
                            </alert>
                        </div>
                        <div v-if="
                            testResult.checks.partnero_universal_script === true
                            && (
                                testResult.checks.partnero_js_signup_tracking
                            )" class="text-sm space-y-2">
                            <p>Please note that to fully complete the integration, you need to share details of successful sales with Partnero. You can do this in a few ways:</p>
                            <ul class="list-disc ml-4 space-y-0.5">
                                <li>Use <b>Stripe</b> or <b>Paddle Billing</b> integrations</li>
                                <li>Implementing tracking through the <a class="underline hover:no-underline" href="https://developers.partnero.com/guide/affiliate.html#sending-sales-data" target="_blank">Parterno API</a></li>
                                <li>Implementing tracking through the <a class="underline hover:no-underline" href="https://developers.partnero.com/guide/javascript-tracking.html#sales-tracking" target="_blank">Partnero JS</a></li>
                                <li>Use <b>Zapier</b> or <b>Make</b> to connect everything.</li>
                            </ul>
                        </div>


                        <div v-if="
                                   testResult.checks.partnero_universal_script === true
                                   && !testResult.checks.stripe_payment_links
                                   && !testResult.checks.partnero_js_signup_tracking
                                   && !testResult.checks.partnero_js_transaction_tracking
                                  "
                             class="text-sm"
                        >

                            <alert type="warning">
                                While we are able to identify referred visitors to your website, we are unable to detect the essential scripts required to send your customer details to Partnero. Please consider using the

                                <modal title="Partnero Universal guide" size="large" class="inline-block text-gray-900">
                                <template #toggle>
                                    <span class="font-semibold underline hover:no-underline text-yellow-800 cursor-pointer">Partnero Universal</span>
                                </template>
                                <template #body>
                                    <guide-universal>
                                        <template v-slot:universal_script>
                                            <slot name="universal_script"></slot>
                                        </template>
                                    </guide-universal>
                                </template>
                                </modal>

                                 integration or selecting another suitable option.
                            </alert>
                        </div>

                    </template>
                </template>
            </template>
        </div>
    </div>
</template>

<script setup>
import ValidationErrors from "../../common/ValidationErrors";
import { ref, computed } from 'vue';
import GuideShopify from "./integration-guides/instructions/GuideShopify";
import GuideUniversal from "./integration-guides/instructions/GuideUniversal";
import GuideJavascript from "./integration-guides/instructions/GuideJavascript";
import GuideStripeLinks from "./integration-guides/instructions/GuideStripeLinks";

const props = defineProps({
    defaultSiteUrl: {
        type: String,
        default: ''
    },
    integrationTestUrl: {
        type: String,
        default: ''
    }
});

const siteUrls = ref([props.defaultSiteUrl]);
const testResults = ref([]);
const testErrors = ref(null);
const testInProgress = ref(false);

const checkIntegration = () => {
    if (testInProgress.value) {
        return;
    }

    testInProgress.value = true
    testResults.value = [];

    const asyncRequests = []
    for (let i = 0; i < siteUrls.value.length; i++) {
        if (siteUrls.value[i]) {
            asyncRequests.push(getTestResult(i))
        }
    }

    Promise.all(asyncRequests).then(() => {
        testInProgress.value = false
    })
};

const getTestResult = (index) => {
    return axios
        .post(props.integrationTestUrl, {
            site_url: siteUrls.value[index]
        })
        .then(response => {
            if (response.data.status) {
                testResults.value.push({
                    siteUrl: siteUrls.value[index],
                    checks: response.data.checks
                });
            }
        })
        .catch(error => {
            if (error.response?.data?.errors) {
                testResults.value.push({
                    siteUrl: siteUrls.value[index],
                    errors: error.response.data.errors
                });
            } else if (error.response?.data?.message) {
                testResults.value.push({
                    siteUrl: siteUrls.value[index],
                    errors: {test: [error.response.data.message]}
                });
            }
        });
}

const addSiteUrl = () => {
    if (testInProgress.value) {
        return;
    }
    siteUrls.value.push('')
}

const removeSiteUrl = (index) => {
    if (testInProgress.value) {
        return;
    }
    siteUrls.value.splice(index, 1)
}
</script>

