<template>
    <div>
        <toggle
            title="Set up UTM tracking for referral links"
            name="utm_tracking[enabled]"
            v-model="utm_tracking_enabled"
        >
            <template #description>
                Set up an additional UTM tracking for your links. Each link will generate additional UTM tags.
            </template>
        </toggle>

        <div v-if="utm_tracking_enabled" class="border rounded grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-4 p-4 mt-4">
            <text-field
                label="Campaign source"
                name="utm_tracking[tags][campaign_source]"
                v-model="utm_tracking_tags.campaign_source"
            >
                <template #note>Added to the links as <i>utm_source</i>.</template>
            </text-field>

            <text-field
                label="Campaign medium"
                name="utm_tracking[tags][campaign_medium]"
                v-model="utm_tracking_tags.campaign_medium"
            >
                <template #note>Added to the links as <i>utm_medium</i>.</template>
            </text-field>

            <text-field
                label="Campaign name"
                name="utm_tracking[tags][campaign_name]"
                v-model="utm_tracking_tags.campaign_name"
            >
                <template #note>Added to the links as <i>utm_campaign</i>.</template>
            </text-field>

            <text-field
                label="Campaign term"
                name="utm_tracking[tags][campaign_term]"
                v-model="utm_tracking_tags.campaign_term"
            >
                <template #note>Added to the links as <i>utm_term</i>.</template>
            </text-field>

            <text-field
                label="Campaign content"
                name="utm_tracking[tags][campaign_content]"
                v-model="utm_tracking_tags.campaign_content"
            >
                <template #note>Added to the links as <i>utm_content</i>.</template>
            </text-field>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
    settings: {
        type: [Array, Object],
        required: true,
        default: {
            enabled: false,
            tags: {}
        }
    },
});

const utm_tracking_enabled = ref(props.settings.enabled ?? false);
const utm_tracking_tags = ref(props.settings.tags ?? {});
</script>
