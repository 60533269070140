<template>


    <div class="space-y-4">
        <div class="space-y-2">
            <h3 class="font-semibold text-md">Set up tracking of Paddle checkout</h3>
        </div>
    </div>

    <tabs>
        <tab @click="setActiveTab(TAB_1)" :class='{"border-b-4": activeTab === TAB_1}'>
            HTML data attribute
        </tab>
        <tab @click="setActiveTab(TAB_2)" :class='{"border-b-4": activeTab === TAB_2}'>
            Javascript properties
        </tab>
    </tabs>

    <div class="space-y-4" v-show="activeTab === TAB_1">
        <div class="text-sm space-y-2">
            <p>
                If you are using HTML for processing paddle payments you can identify purchases made by referred buyers
                by accurately identifying and linking them via Paddle checkout HTML attributes.
            </p>
        </div>

        <div class="relative">
            <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-1').value)">
                <tooltip content="Copied" method="click">
                    <btn type="secondary" size="pill">Copy</btn>
                </tooltip>
            </div>
            <textarea id="snippet-1" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script>
document.querySelectorAll('a.paddle_button').forEach(link => {
    const cookieValue = (document.cookie.match(/(^| )partnero_partner=([^;]+)/) || [])[2];
    if (cookieValue) {
        link.setAttribute('data-custom-data', JSON.stringify({ client_reference_id: cookieValue }));
    }
});
</script></textarea>
            <small class="text-gray-500 mt-1 block">
                Include the following script on every page containing payment links. It's important to place
                this script at the bottom of the page, directly above the closing
                <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">&lt;/body&gt;</span>
                tag for optimal functionality.
            </small>
        </div>
    </div>

    <div class="space-y-4" v-show="activeTab === TAB_2">
        <div class="text-sm space-y-2">
            <p>
                If you are using javascript for processing paddle payments you can identify purchases made by referred
                buyers by accurately identifying and linking them via Paddle checkout Javascript properties.
            </p>
        </div>

        <div class="relative">
            <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-3').value)">
            <tooltip content="Copied" method="click">
                <btn type="secondary" size="pill">Copy</btn>
            </tooltip>
        </div>
            <textarea id="snippet-3" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs" v-pre>
<script>
                const cookieValue = (document.cookie.match(/(^| )partnero_partner=([^;]+)/) || [])[2];

Paddle.Checkout.open({
  settings: {
    theme: "light",
  },
  items: [
    {
      priceId: 'pri_01gs59hve0hrz6nyybj56z04eq',
      quantity: 1
    },
    {
      priceId: 'pri_01gs59p7rcxmzab2dm3gfqq00a',
      quantity: 1
    }
  ],
  customData: {
    "client_reference_id": cookieValue,
  },
  customer: {
    email: "weloveyourproduct@paddle.com",
    address: {
      countryCode: "US",
    }
  }
});
</script>

</textarea>
        </div>

        <div class="text-sm space-y-2">
            <p class="mt-4 mb-4">
                Update the checkout like in the example so you pass customData to the Paddle.Checkout.open()
                method to add client_reference_id to a custom data within the created transaction.
            </p>
        </div>


    </div>


</template>
<script setup>

import {ref} from 'vue';

const TAB_1 = 'tab_1';
const TAB_2 = 'tab_2';

let activeTab = ref(TAB_1);

const setActiveTab = (tab) => {
    activeTab.value = tab;
};

</script>

