<template>

    <div class="space-y-6">

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>

            <div class="space-y-2">
                <div class="text-sm space-y-2">
                    <p>Follow these steps to insert Partnero Universal into your BigCommerce site:</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Log in to your <strong>BigCommerce</strong> account</li>
                        <li>Proceed to <strong>Storefront</strong> and choose <strong>Script Manager</strong></li>
                        <li>Create a new script by clicking <strong>Create a script</strong> button</li>
                    </ol>
                    <p>Chose the following:</p>
                    <ul class="list-disc ml-4 space-y-1">
                        <li>Script name: <strong>Partnero Universal</strong> (or you can name it yourself)</li>
                        <li>Placement: <strong>Header</strong></li>
                        <li>Location: <strong>All pages</strong></li>
                        <li>Script category: <strong>Analytics</strong></li>
                        <li>Script type: <strong>Script</strong></li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="space-y-4 border-t pt-6">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sales tracking on checkout</h3>
                <div class="text-sm space-y-2">
                    <p>Set up order tracking during the checkout.</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Go to <strong>Settings</strong></li>
                        <li>Locate <strong>Advanced</strong> section and choose <strong>Data Solutions</strong></li>
                        <li>Choose <strong>Affiliate Conversion Tracking</strong></li>
                    </ol>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-1').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-1" rows="4" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script>
po('transactions', 'create', {
    data: {
      key: '%%ORDER_ID%%',
      amount: %%ORDER_AMOUNT%% ,
      customer: {
        key: '%%ORDER_EMAIL%%',
        email: '%%ORDER_EMAIL%%'
      }
    },
    options: {
      create_customer: true
    }
  });
</script></textarea>
                <small class="text-gray-500 mt-1 block">Paste this script into <strong>Connection</strong> area and click Connect.</small>
            </div>
        </div>

        <alert type="warning"><b>Please note:</b> When using this integration method, transaction creation via JavaScript (JS) calls must be enabled to ensure successful tracking.</alert>

    </div>

</template>
