<template>
    <div class="flex items-start">
        <div class="flex-shrink-0 w-10 h-10">
            <img class="w-10 h-10 rounded" src="/images/integrations/paddle-classic.png"/>
        </div>
        <div class="ml-5">
            <div class="flex justify-between">
                <div class="flex items-center">
                    <span class="text-sm font-semibold">Paddle Classic</span>
                    <PaddleClassicAfterIntegrationInstructions
                        v-if="connected"
                        class="ml-1.5"
                        :webhook-url="integrationsConfig.url.webhook"
                        :events-list="integrationsConfig.supported_events"
                    />
                </div>
                <badge title="Connected" v-if="connected"></badge>
            </div>
            <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                If you are using older version, connect your Paddle Classic account to Partnero.
            </div>
            <div class="flex gap-2 items-center">
            <modal v-if="!connected" :title="'Paddle Classic integration'" size="small" name="paddle_classic_integration">
                <template #toggle>
                    <btn v-if="!connected" type="secondary" size="small" class="-mr-px focus:z-10">
                        Authorize
                    </btn>
                </template>
                <template #body>
                    <validation-errors :errors="errors.validationErrors" />
                    <alert v-if="errors.responseError" type="error">
                        <span class="break-all">
                            {{ errors.responseError }}
                        </span>
                    </alert>
                    <div class="space-y-4">

                        <text-field
                            label="Paddle Vendor ID"
                            v-model="vendorId"
                            type="text"
                            autofocus="true"
                            required="true"
                            :error="errors.vendorId"
                            placeholder="e.g. 25998"
                        >
                            <template #note>
                                Looking for Paddle Vendor ID? Navigate to <strong>Developer Tools</strong> -> <strong>Authentication</strong>.
                            </template>
                        </text-field>

                        <text-field
                            label="Auth Code"
                            v-model="authCode"
                            type="password"
                            :show_reveal_password_icon="true"
                            required="true"
                            :error="errors.authCode"
                            placeholder="e.g. ed21b04...15bca"
                        >
                            <template #note>
                                Looking for Auth Code? Navigate to <strong>Developer Tools</strong> -> <strong>Authentication</strong>. Create a new or use an existing Auth Code.
                            </template>

                        </text-field>

                        <div>
                            <label class="block text-sm mb-1">Public key</label>
                            <textarea v-model="publicKey"
                                      id="trusted-domains"
                                      rows="5"
                                      class="block w-full text-sm rounded border-gray-300 focus:ring-0 focus:border-gray-400 scroll-h"
                                      :class="{'border-red-400': errors.publicKey}"
                                      required
                                      placeholder="-----BEGIN PUBLIC KEY-----&#10;MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAx14OI0ZjFAfq9AZVanLc..."
                            ></textarea>
                            <small class="text-gray-500 block mt-1">
                                Looking for your Public key? Navigate to <strong>Developer Tools</strong> -> <strong>Public key</strong>.
                            </small>
                        </div>

                        <toggle
                            size="default"
                            v-model="useSandboxAccount"
                            title="Enable test mode"
                        >
                            <template #description>
                                If enabled, you can connect Paddle's sandbox.
                            </template>
                        </toggle>

                        <div>
                            <hr />
                        </div>

                        <PaddleClassicWebhookInstructions
                            :webhook-url="integrationsConfig.url.webhook"
                            :events-list="integrationsConfig.supported_events"
                        />

<!--                        <div v-show="showMore">-->
<!--                            <PaddleClassicWebhookInstructions-->
<!--                                :webhook-url="integrationsConfig.url.webhook"-->
<!--                                :events-list="integrationsConfig.supported_events"-->
<!--                            />-->
<!--                        </div>-->
<!--                        <div @click.prevent="showMoreFields()" class="flex">-->
<!--                            <a href="" class="flex items-center hover:underline">-->
<!--                        <span class="text-sm">-->
<!--                            {{showMore ? 'Hide webhook setup' : 'Webhook setup'}}-->
<!--                        </span>-->
<!--                            </a>-->
<!--                        </div>-->

                    </div>
                </template>
                <template #footer>
                    <div class="flex justify-end">
                        <btn type="primary" :loading="loading.authorize" class="-mr-px focus:z-10" @click="authorize">
                            Authorize
                        </btn>
                    </div>
                </template>
            </modal>
            <modal v-if="connected" title="Paddle classic integration" size="small" name="paddle_classic_disconnect">
                <template #toggle>
                    <btn v-if="connected" size="small" class="-mr-px focus:z-10">Disconnect</btn>
                </template>
                <template #body>
                    <div class="space-y-4">
                        <div class="text-sm text-gray-500">Are you sure you wish to disconnect the Paddle Classic integration?</div>
                    </div>
                </template>
                <template #footer>
                    <btn :loading="loading.disconnect" @click="disconnect">Disconnect</btn>
                </template>
            </modal>
                <customer-identifier-mapping
                    v-if="connected"
                    :program-id="programId"
                    title="Paddle Classic integration mapping (optional)"
                    provider="paddle"
                    :customer-identifier-holder-in-metadata="integrationsConfig.setting_integration_paddle_classic_customer_identifier_mapping"
                    field-to-look-in="custom data"
                />
            </div>
        </div>
    </div>
</template>
<script>

import ValidationErrors from "../../../common/ValidationErrors.vue";
import Alert from "../../../../elements/Alert.vue";
import CustomerIdentifierMapping from "../common/identifier-mapping/CustomerIdentifierMapping.vue";
import PaddleClassicAfterIntegrationInstructions from "./components/PaddleClassicAfterIntegrationInstructions.vue";
import PaddleClassicWebhookInstructions from "./components/PaddleClassicWebhookInstructions.vue";

export default {
    components: {
        PaddleClassicWebhookInstructions,
        PaddleClassicAfterIntegrationInstructions,
        CustomerIdentifierMapping, Alert, ValidationErrors},
    props: ['integrationsConfig', 'programId'],
    data() {
        return {
            vendorId: null,
            authCode: null,
            publicKey: null,
            useSandboxAccount: false,
            clientWebhookSecret: null,
            loading: {
                authorize: false,
                disconnect: false,
            },
            errors: {
                authCode: false,
                vendorId: false,
                publicKey: false,
                responseError: null,
                validationErrors: null,
            },
            connected: this.integrationsConfig.connected ?? false,
            showMore: false,
        }
    },
    methods: {
        authorize() {

            this.errors.authCode = false;
            this.errors.vendorId = false;
            this.errors.publicKey = false;
            this.errors.responseErrors = null;

            if (this.authCode === null || this.authCode.trim() === '') {
                this.errors.authCode = true;
            }

            if (this.vendorId === null || this.vendorId.trim() === '') {
                this.errors.vendorId = true;
            }

            if (this.publicKey === null || this.publicKey.trim() === '') {
                this.errors.publicKey = true;
            }

            if (this.errors.authCode === false && this.errors.vendorId === false && this.errors.publicKey === false) {
                if (this.loading.authorize) {
                    return;
                }
                this.loading.authorize = true;

                axios.post(route('programs.paddle-classic.quick_connect', {program: this.programId}), {
                    authCode: this.authCode,
                    vendorId: this.vendorId,
                    publicKey: this.publicKey,
                    useSandboxAccount: this.useSandboxAccount,
                }).then((response) => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        window.location.reload();
                    }
                    else if (status === 0) {
                        this.errors.responseError = response.data?.error;
                    }
                })
                .catch((error) => {
                        this.errors.validationErrors = error.response?.data?.errors;
                    })
                .finally(() => this.loading.authorize = false);
            }
        },
        disconnect() {

            if (this.loading.disconnect) {
                return;
            }
            this.loading.disconnect = true;

            axios.post(route('programs.paddle-classic.disconnect', {program: this.programId})).then((response) => {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {
                    window.location.reload();
                }
            })
                .catch((error) => {
                })
                .finally(() => this.loading.disconnect = false);
        },
        showMoreFields() {
            this.showMore = !this.showMore;
        }
    }
}
</script>
