<script setup>

import {useAnalyticsPartnersStore} from "../../../../../../stores/app/programs/analytics/AnalyticsPartners";
import {useAnalyticsOverviewStore} from "../../../../../../stores/app/programs/analytics/AnalyticsOverview";
import {onMounted, getCurrentInstance} from "vue";
import Stats from "./components/Stats.vue";
import StatsSkeleton from "../../../../../common/skeletons/StatsSkeleton.vue";
import {createRouteForTemplate} from "../../../../../../helpers/common";
import TableSkeleton from "../../../../../common/skeletons/TableSkeleton.vue";
import TopPartners from "../../overview/affiliate/components/TopPartners";
import AcquisitionInsights from "./components/AcquisitionInsights";

const {proxy} = getCurrentInstance()
const props = defineProps({
    program: Object
});

const analyticsPartnersStore = useAnalyticsPartnersStore();
const analyticsOverviewStore = useAnalyticsOverviewStore();

const loadData = () => {
    analyticsPartnersStore.fetchAnalyticsPartnersStatsData();
    analyticsPartnersStore.fetchAnalyticsPartnersAcquisitionInsightsData();

    analyticsOverviewStore.fetchAnalyticsOverviewTopPartnersData();
};

onMounted(() => {
    analyticsPartnersStore.setProgram(props.program);
    analyticsOverviewStore.setAnalyticsOverviewProgram(props.program);

    loadData();

    proxy.emitter.on('program-analytics-time-period-updated', (timePeriod) => {
        analyticsPartnersStore.setTimePeriod(timePeriod)

        loadData()
    });
});

</script>

<template>
    <div class="space-y-4">
        <Stats v-if="analyticsPartnersStore.getAnalyticsPartnersStatsData"/>
        <StatsSkeleton v-else />
    </div>

    <acquisition-insights v-if="analyticsOverviewStore.getProgram" />

    <div class="space-y-4">
        <div class="flex items-center justify-between">
            <h3 class="text-lg font-semibold text-gray-900">Top partners</h3>
            <btn v-if="analyticsOverviewStore.getProgram"
                 :href="createRouteForTemplate('programs.partners.index', { program: analyticsOverviewStore.getProgram })"
                 type="secondary" size="small" icon-name="arrow-right-s" icon-right="true"
            >
                All partners
            </btn>
        </div>

        <TopPartners v-if="analyticsOverviewStore.getAnalyticsOverviewTopPartnersData"/>

        <TableSkeleton v-else />
    </div>
</template>
