<template>
    <div class="flex items-center" v-if="showDownload">
        <div class="flex-shrink-0 w-10 h-10">
            <icon name="file-list" size="40"/>
        </div>
        <div class="ml-5">
            <div class="text-sm font-semibold">List download</div>
            <div class="text-sm font-normal text-gray-500 mt-0.5" v-if="loading.downloading">
                Downloading...
            </div>
            <div class="text-sm font-normal text-gray-500 mt-0.5" v-if="!loading.downloading">
                Download list of approved rewards ready for payout.
            </div>
        </div>
        <div class="ml-5">
            <btn type="secondary" icon-name="download" @click="downloadFile">Download</btn>
        </div>
    </div>
    <div class="flex items-center" v-if="!showDownload">
        <div class="flex-shrink-0 w-10 h-10">
            <icon name="file-chart" size="40"/>
        </div>
        <div class="ml-5">
            Complete payout
            <div class="text-sm font-normal text-gray-500 mt-0.5" v-if="!result_message">
                Upload completed payments to complete payout process.
            </div>
            <div class="text-sm font-normal text-gray-500 mt-0.5" v-if="result_message">
                {{ result_message }}
            </div>
        </div>
        <div class="ml-5">
            <btn type="secondary" icon-name="upload" @click="$refs.file.click()">Upload</btn>
            <input
                class="block absolute opacity-0 cursor-pointer"
                ref="file"
                type="file"
                :name="name"
                accept=".csv"
                style="display: none"
                v-on:change="uploadFile()"
            >
        </div>
    </div>
    <modal title="Mark payouts as paid" @modalClose="modalClose" name="gateway-mass-payout-file-modal">
        <template #body>
            <div class="space-y-6">
                <span v-if="message" class="ml-5">{{ message }}</span>
            </div>
        </template>
        <template #footer>
            <btn v-on:click="markAsPaid" :loading="loading.finishing" type="primary"
                 :disabled="!can_mark_as_paid">
                Mark as paid
            </btn>
        </template>
    </modal>
</template>
<script>
import {isProxy, toRaw} from "vue";
import useFilters from "../../../../state/common/filters-and-pagination";

export default {
    name: "GatewayMassPayoutsFinish",
    props: ['program', 'gateway'],
    data() {
        return {
            ids: [],
            message: null,
            result_message: null,
            selectedIds: [],
            selectedType: 'selectAll',
            showDownload: true,
            loading: {
                uploading: false,
                downloading: false,
                finishing: false
            },
            can_mark_as_paid: false
        }
    },
    computed: {
        name() {
            return this.gateway + "_mass_payout";
        }
    },
    mounted() {
        let self = this;
        this.emitter.on('export-selection-set', function (exportData) {
            if (exportData.name === self.name) {
                let selectedIds = exportData.options.selectedIds;
                if (isProxy(exportData.options.selectedIds)) {
                    selectedIds = toRaw(exportData.options.selectedIds)
                }
                self.payoutsChanges(exportData.options.selectType, selectedIds);
            }
        });
    },
    methods: {
        downloadFile() {
            if (this.loading.downloading) {
                return;
            }

            const self = this;
            this.loading.downloading = true;

            axios.post(route('app_int.program.payouts.mass-payout.file-download', {program: this.program, gateway: this.gateway}), {
                type: this.selectedType,
                ids: this.selectedIds
            }).then(function (response) {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {

                    const fileURL = window.URL.createObjectURL(new Blob([response.data.result.file_content]));
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', self.name + '.csv');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    self.showDownload = false;
                }
            }).finally(() => this.loading.downloading = false);
        },
        async uploadFile() {

            if (this.loading.uploading) {
                return;
            }

            this.loading.uploading = true;

            const self = this;
            const formData = new FormData();
            formData.append('name', this.name);
            formData.append(this.name, this.$refs.file.files[0]);

            axios
                .post(route('app_int.program.payouts.mass-payout.file-upload', {program: this.program, gateway: this.gateway}), formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(function (response) {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        const count = response.data.result.count;
                        if (count < 1) {
                            self.can_mark_as_paid = false;
                            self.message = 'No payouts that can be marked as paid where found.';
                        } else {
                            self.can_mark_as_paid = true;
                            self.ids = response.data.result.ids;
                            const payoutTxt = count === 1 ? 'payout' : 'payouts';
                            self.message = count + ' ' + payoutTxt + ' will be marked as paid. Proceed?';
                            self.emitter.emit('modal-on', 'gateway-mass-payout-file-modal')
                        }
                    }
                })
                .catch(function (e) {
                })
                .finally(() => self.loading.uploading = false);
        },
        markAsPaid() {
            if (this.loading.finishing) {
                return;
            }

            this.loading.finishing = true;
            const self = this;

            axios
                .post(
                    route('app_int.program.payouts.mass-payout.file-mark-as-paid', {program: this.program, gateway: this.gateway}),
                    {
                        ids: self.ids
                    })
                .then(function (response) {
                    const status = parseInt(response.data.status ?? -1);

                    if (status === 1) {

                        self.reloadPayouts();

                        const count = response.data.finished;
                        const payoutTxt = count === 1 ? 'payout' : 'payouts';
                        self.result_message = count + ' ' + payoutTxt + ' successfully marked as paid.';
                        self.emitter.emit('modal-off', 'gateway-mass-payout-file-modal')
                    }
                })
                .catch(function (e) {
                })
                .finally(() => self.loading.finishing = false);
        },
        payoutsChanges(selectType, selectedIds) {
            this.selectedType = selectType;
            this.selectedIds = selectedIds;
            this.showDownload = (this.selectedType === 'selectAll' || this.selectedIds.length > 0);
        },
        finish() {
            this.emitter.emit('modal-off', 'gateway-mass-payout-file-modal')
            this.reloadPayouts();
        },
        reloadPayouts() {
            const {
                fetchDataBaseUrl,
                fetchDataFunction,
            } = useFilters()
            fetchDataFunction.value(fetchDataBaseUrl.value);
        },
        modalClose() {
            this.ids = [];
            this.message = null;
            this.result_message = null;
            this.$refs.file.value = '';
        }
    }
}
</script>

<style scoped>

</style>
