<script setup>
import {useAnalyticsRealTimeStore} from "../../../../../../stores/app/programs/analytics/AnalyitcsRealTimeStore";
import Icon from "../../../../../../elements/Icon.vue";
import AnalyticsTablesPagination from "./AnalyticsTablesPagination.vue";
import {formatNumber} from "../../../../../../helpers/common";


const analyticsRealTimeStore = useAnalyticsRealTimeStore();

const prepVisitorSource = (source) => {
    // remove protocol and www
    return source.replace(/(^\w+:|^)\/\//, '').replace('www.', '');
}

</script>

<template>
    <div class="grid lg:grid-cols-2 gap-4">
        <div class="bg-white rounded border grid grid-cols-1 content-between">
            <table class="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Source
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Visitors
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Referred visitors
                    </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                <tr v-if="!analyticsRealTimeStore?.getAnalyticsRealTimeVisitorsBySourceData?.data?.length">
                    <td colspan="3" class="px-4 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
                        No data for selected time period
                    </td>
                </tr>
                <template v-else>
                    <tr v-for="(visitorData, index) in analyticsRealTimeStore.getAnalyticsRealTimeVisitorsBySourceData.data"
                        :key="'visitors-by-source-' + index"
                        class="hover:bg-gray-50 cursor-pointer group">
                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                                <span class="truncate overflow-hidden" :title="visitorData.source || ''">
                                    <div
                                        class="inline">
                                        <span class="flex items-center gap-2">
                                            <img v-if="visitorData.source"
                                                 :src="'https://icons.duckduckgo.com/ip3/' + prepVisitorSource(visitorData.source) + '.ico'"
                                                 class="w-4 h-4"
                                            >
                                            <Icon v-else
                                                  name="global"
                                                  class="w-4 h-4"
                                            />
                                            {{ visitorData.source
                                            ? (visitorData.source.length > 30 ? visitorData.source.substring(0, 30) + '...' : visitorData.source)
                                            : 'unknown' }}
                                        </span>
                                    </div>
                                </span>
                        </td>
                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{ formatNumber(visitorData.visitors) }}
                        </td>
                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{ formatNumber(visitorData.referred_visitors) }}
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            <div v-if="analyticsRealTimeStore?.getAnalyticsRealTimeVisitorsBySourceData?.last_page > 1"
                 class="text-sm text-gray-900 border-t">
                <AnalyticsTablesPagination
                    :paginationData="analyticsRealTimeStore.getAnalyticsRealTimeVisitorsBySourceData"
                    data-to-fetch="visitorsBySource"
                />
            </div>
        </div>
        <div class="bg-white rounded border grid grid-cols-1 content-between">
            <table class="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Referral
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Referred visitors
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Conversion
                    </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                <tr v-if="!analyticsRealTimeStore?.getAnalyticsRealTimeVisitorsByReferralData?.data?.length">
                    <td colspan="3" class="px-4 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
                        No data for selected time period
                    </td>
                </tr>
                <template v-else>
                    <tr v-for="(visitorData, index) in analyticsRealTimeStore?.getAnalyticsRealTimeVisitorsByReferralData?.data"
                        :key="'visitors-by-referral-' + index"
                        class="hover:bg-gray-50 cursor-pointer group">
                        <td class="px-4 py-4 whitespace-nowrap font-medium text-sm text-gray-900 group-hover:text-teal-600">
                            <span :title="visitorData.referral_partner || ''">
                                {{ visitorData.referral_partner
                                ? (visitorData.referral_partner.length > 30 ? visitorData.referral_partner.substring(0, 30) + '...' : visitorData.referral_partner)
                                : 'unknown' }}
                            </span>
                        </td>
                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{ formatNumber(visitorData.referred_visitors) }}
                        </td>
                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{ formatNumber(visitorData.conversion) }} %
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            <div v-if="analyticsRealTimeStore?.getAnalyticsRealTimeVisitorsByReferralData?.last_page > 1"
                 class="text-sm text-gray-900 border-t">
                <AnalyticsTablesPagination
                    :paginationData="analyticsRealTimeStore.getAnalyticsRealTimeVisitorsByReferralData"
                    data-to-fetch="visitorsByReferral"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
.custom-scrollbar::-webkit-scrollbar-track {
    margin-top: 2.56rem;
}
</style>
