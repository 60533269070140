<template>
    <div>
        <label for="maximum_commission" class="block mb-1.5 text-sm">Maximum commission</label>
        <div class="grid gap-1" :class="setting_disabled ? 'grid-cols-1' : 'grid-cols-2'">
            <select id="maximum_commission" name="maximum_commission" v-model="enabled"
                    class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
                <option value="0">OFF</option>
                <option value="1">ON</option>
            </select>
            <text-field :model-value="value" name="maximum_commission_value" v-if="!setting_disabled"
                        :disabled="setting_disabled" :required="!setting_disabled"></text-field>
        </div>
        <small class="text-gray-500 mt-1 block">Set the maximum commission.</small>
    </div>
</template>
<script>
export default {
    name: 'MaximumCommissionValue',
    props: ['maximum_commission_enabled', 'maximum_commission_value'],
    data() {
        return {
            enabled: this.maximum_commission_enabled ?? 0,
            value: this.maximum_commission_value ?? null,
        }
    },
    computed: {
        setting_disabled() {
            return this.enabled === '0';
        }
    },
    methods: {}
}
</script>
<style scoped>
</style>
