<template>
    <slot :childScope="childScope" :childMethods="{showPasswordForm, togglePasswordReveal}"/>
</template>
<script>
export default {
    props: ['min', 'regex', 'errors'],
    data() {
        return {
            childScope: {
                password: '',
                revealPassword: false,
                rules: {
                    passwordLength: false,
                    // regex rules added dynamically on created()
                },
                passwordFormVisible: false,
            }
        }
    },

    created() {
        _.forEach(this.regex, (value, key) => {
            this.childScope.rules[_.camelCase(value)] = false;
        });
        this.childScope.passwordFormVisible = this.errors; // if we have errors, make it visible
    },

    watch: {
        'childScope.password': function (current, old) {
            _.forEach(this.regex, (value, key) => {
                this.childScope.rules[_.camelCase(value)] = new RegExp(key).test(current)
            });
            this.childScope.rules.passwordLength = current.length >= this.min
        }
    },

    methods: {
        showPasswordForm() {
            this.childScope.passwordFormVisible = true;
        },
        togglePasswordReveal() {
            this.childScope.revealPassword = !this.childScope.revealPassword
        }
    }
}
</script>
