<script setup>
    import Modal from "../../../../../elements/Modal.vue";
    import Btn from "../../../../../elements/Btn.vue";
    import Icon from "../../../../../elements/Icon.vue";
    import RedemptionSpecificDatePeriod from "../settings/RedemptionSpecificDatePeriod.vue";
    import VueMultiselect from "vue-multiselect";
    import Spinner from "../../../../../elements/Spinner.vue";
    import TextField from "../../../../../elements/forms/TextField.vue";
    import RedemptionTimesPeriod from "../settings/RedemptionTimesPeriod.vue";
    import Tooltip from "../../../../../elements/Tooltip.vue";
    import {reactive, ref} from "vue";
    import Notification from "../../../../../elements/Notification.vue";
    import ValidationErrors from "../../../../common/ValidationErrors.vue";
    import Alert from "../../../../../elements/Alert.vue";

    const props = defineProps({
        program: {
            type: Object,
            required: true,
        },
        couponId: {
            type: Number,
            required: true,
        },
        promotionCode: {
            type: Object,
            required: true,
        },
        promotionCodeAction: {
            type: String,
            required: true,
        },
        canArchive: {
            type: Boolean,
            required: false,
            default: true,
        }
    });

    const isReferAFriendProgram = props.program?.program_type?.type === 'refer_a_friend'

    const promoCode = reactive(props.promotionCode);

    const promoCodeAlreadySaved = () => {return props.promotionCodeAction === 'edit' && !!props.promotionCode.id }

    const editingAction = props.promotionCodeAction === 'edit'

    const updatingPromotionCode = ref(false);

    const togglingActivePromoCode = ref(false);

    const savingPromotionCode = ref(false);

    const errors = reactive({
        responseError: null,
        validationErrors: null,
    })

    const successResponse = ref(null)

    const limitText = (count) => {
        return `and ${count} other customers`
    }

    const isLoadingPartners = ref(false)
    const isLoadingCustomers = ref(false)
    const specific_partners_list = ref([])
    const specific_customers_list = ref([])

    let delayTimer = null;

    function doSearch(query, searchingFor) {
        clearTimeout(delayTimer);

        delayTimer = setTimeout(function () {
            if (searchingFor === 'partners') {
                asyncFindPartners(query);
            }
            else if (searchingFor === 'customers' && isReferAFriendProgram) {
                asyncFindReferrals(query);
            } else if (searchingFor === 'customers') {
                asyncFindCustomers(query);
            }
        }, 700);
    }
    const asyncFindCustomers = (query) => {
        isLoadingCustomers.value = true
        axios.get(route('app_int.promotion-codes.customers.search', props.program) + '?search=' + query)
            .then(response => {
                specific_customers_list.value = response.data
                isLoadingCustomers.value = false
            })
            .finally(() => {
                isLoadingCustomers.value = false
            })
    }

    const asyncFindReferrals = (query) => {
        isLoadingCustomers.value = true
        axios.get(route('app_int.promotion-codes.partners.search', props.program) + '?search=' + query)
            .then(response => {
                specific_customers_list.value = response.data
                isLoadingCustomers.value = false
            })
            .finally(() => {
                isLoadingCustomers.value = false
            })
    }

    const asyncFindPartners = (query) => {
        isLoadingPartners.value = true
        axios.get(route('app_int.promotion-codes.partners.search', props.program) + '?search=' + query)
            .then(response => {
                specific_partners_list.value = response.data
                isLoadingPartners.value = false
            })
            .finally(() => {
                isLoadingPartners.value = false
            })
    }

    const removeMetadata = (metadataIndex) => {
        promoCode.metadata.splice(metadataIndex, 1);
    }

    const toggleActivePromoCode = () => {
        if (promoCode.id) {
            togglingActivePromoCode.value = true;
            axios.patch(route('app_int.promotion-code.toggle-active', {program: props.program, coupon: props.couponId, promoCode: promoCode.id}))
                .then(response => {
                    const status = parseInt(response.data.status || '-1');
                    if (status === 1) {
                        successResponse.value = response.data?.message;
                        if (response.data?.sync?.enabled) {
                            if (response.data?.sync?.status) {
                                successResponse.value += response.data?.sync?.message;
                            } else {
                                errors.responseError = response.data?.sync?.error || response.data?.sync?.message || 'An error occurred during sync';
                            }
                        }
                        reloadPage();
                    }
                    else if (status === 0) {
                        errors.responseError = response.data?.error || response.data?.message || 'An error occurred';
                    }
                })
                .catch((error) => {
                    errors.validationErrors = error.response?.data?.errors;
                })
                .finally(() => {
                togglingActivePromoCode.value = false;
                })
        }
        else {
            // reload promo code page
        }
    }

    const addMetadata = () => {
        promoCode.metadata.push({ key: '', value: '' });
    }

    const updatePromotionCodeMetadata = () => {
        updatingPromotionCode.value = true;
        axios.patch(route('app_int.promotion-code.update', {program: props.program, coupon: props.couponId, promoCode: promoCode.id}), {
            metadata: promoCode.metadata,
            'limit_to_specific_partner': promoCode.limit_to_specific_partner,
            'coupon_specific_partners': promoCode.coupon_specific_partners,
            'limit_to_specific_customer': promoCode.limit_to_specific_customer,
            'coupon_specific_customers': promoCode.coupon_specific_customers,
        }).then(response => {
            const status = parseInt(response.data.status || '-1');
            if (status === 1) {
                successResponse.value = response.data?.message;
                if (response.data?.sync?.enabled) {
                    if (response.data?.sync?.status) {
                        successResponse.value += response.data?.sync?.message;
                    } else {
                        errors.responseError = response.data?.sync?.error || response.data?.sync?.message || 'An error occurred during sync';
                    }
                }
                reloadPage();
            } else if (status === 0) {
                errors.responseError = response.data?.error || response.data?.message || 'An error occurred';
            }
        })
            .catch((error) => {
                errors.validationErrors = error.response?.data?.errors;
            })
            .finally(() => {
            updatingPromotionCode.value = false;
        })
    }

    const savePromotionCode = () => {
        savingPromotionCode.value = true;
        axios.post(route('app_int.promotion-code.create', {program: props.program, coupon: props.couponId}), {
            coupon_id: props.couponId,
            code: promoCode.code,
            first_time_order: promoCode.first_time_order,
            limit_to_specific_partner: promoCode.limit_to_specific_partner,
            coupon_specific_partners: promoCode.coupon_specific_partners,
            limit_to_specific_customer: promoCode.limit_to_specific_customer,
            coupon_specific_customers: promoCode.coupon_specific_customers,
            minimum_order_status: promoCode.minimum_order_status,
            minimum_order_value: promoCode.minimum_order_value,
            expiration_date_status: promoCode.expiration_date_status,
            expiration_date_value: promoCode.expiration_date_value,
            redemption_times_status: promoCode.redemption_times_status,
            redemption_times_value: promoCode.redemption_times_value,
            metadata: promoCode.metadata,
        }).then(response => {
            const status = parseInt(response.data.status || '-1');
            if (status === 1) {
                successResponse.value = response.data?.message;
                if (response.data?.sync?.enabled) {
                    if (response.data?.sync?.status) {
                        successResponse.value += response.data?.sync?.message;
                    } else {
                        errors.responseError = response.data?.sync?.error || response.data?.sync?.message || 'An error occurred during sync';
                    }
                }
                reloadPage();
            } else if (status === 0) {
                errors.responseError = response.data?.error || response.data?.message || 'An error occurred';
            }
        }).catch((error) => {
                errors.validationErrors = error.response?.data?.errors;
        }).finally(() => {
            savingPromotionCode.value = false;
        })
    }

    const reloadPage = () => {
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

</script>

<template>
    <div>
        <modal v-if="promoCode" :title="promoCodeAlreadySaved() ? 'Promotion code details' : 'New promotion code'" ref="promoCodeModal">
            <template #toggle>
                <btn v-if="promotionCodeAction === 'create'" type="primary">New promotion code</btn>
                <btn v-else type="ghost" icon-name="settings" size="small"></btn>
            </template>
            <template #body>
                <validation-errors :errors="errors.validationErrors" class="mb-5"/>
                <alert v-if="errors.responseError" type="error" class="mb-5">
                        <span class="break-all">
                            {{ errors.responseError }}
                        </span>
                </alert>
                <alert v-if="successResponse" type="success" class="mb-5">
                        <span class="break-all">
                            {{ successResponse }}
                        </span>
                </alert>
                <div class="space-y-4">
                    <div class="space-y-8 border rounded p-6">
                        <div class="grid grid-cols-4 gap-4">
                            <text-field
                                class="col-span-3"
                                label="Code"
                                type="text"
                                autofocus="true"
                                v-model="promoCode.code"
                                placeholder="E.g. DISCOUNT30"
                                :disabled="promoCodeAlreadySaved()"
                            >
                                <template #note v-if="!promoCodeAlreadySaved()">
                                    This code must be unique across all active promotion codes.
                                    If left blank we will generate code automatically.
                                </template>
                                <template v-if="!promoCodeAlreadySaved()" #append>
                                    <div class="inline-flex items-center pl-1 text-gray-500 cursor-pointer">
                                        <tooltip content="Only alpha numeric, no special characters allowed. The field is case-insensitive.">
                                            <icon
                                                name="info"
                                                size="18"
                                            />
                                        </tooltip>
                                    </div>
                                </template>
                            </text-field>
                        </div>

                        <div class="space-y-4">
                            <div class="flex justify-between items-center" v-if="!promoCodeAlreadySaved()">
                                <toggle
                                    v-model="promoCode.first_time_order"
                                    :disabled="promoCodeAlreadySaved()"
                                >
                                </toggle>
                                <span class="flex flex-col flex-grow" id="first_time_order-label">
                                        <span class="text-sm font-medium text-gray-900">
                                            Eligible for first-time order only
                                        </span>
                                        <span class="text-sm text-gray-500">
                                            If enabled, coupon will be valid only for first time order i.e. for customers who have never made a purchase.
                                        </span>
                                    </span>
                            </div>
                            <div v-if="!isReferAFriendProgram" class="flex justify-between items-center">
                                <toggle
                                    v-model="promoCode.limit_to_specific_partner"
                                >
                                </toggle>
                                <span class="flex flex-col flex-grow" id="limit_to_specific_partner-label">
                                    <span class="text-sm font-medium text-gray-900">
                                        Limit to specific partner
                                    </span>
                                    <span class="text-sm text-gray-500">
                                        If enabled, to set the specific partner that is eligible for discounts, add the relevant partner in the Apply to specific partners field.
                                    </span>
                                </span>
                            </div>
                            <div v-if="promoCode.limit_to_specific_partner && !isReferAFriendProgram">
                                <VueMultiselect
                                    v-model="promoCode.coupon_specific_partners"
                                    label="full_name"
                                    track-by="id"
                                    placeholder="Choose a partner"
                                    :options="specific_partners_list"
                                    :multiple="false"
                                    :searchable="true"
                                    :loading="isLoadingPartners"
                                    :internal-search="false"
                                    :clear-on-select="false"
                                    :close-on-select="true"
                                    :options-limit="300"
                                    :limit="1"
                                    :limit-text="limitText"
                                    :max-height="600"
                                    :show-no-results="true"
                                    @search-change="doSearch($event, 'partners')"
                                >
                                    <span slot="noResult">Oops! No partners found. Consider changing the search query.</span>
                                </VueMultiselect>
                            </div>
                            <div class="flex justify-between items-center" v-if="!promoCodeAlreadySaved() || isReferAFriendProgram">
                                <toggle
                                    v-model="promoCode.limit_to_specific_customer"
                                    :disabled="promoCodeAlreadySaved() && !isReferAFriendProgram"
                                >
                                </toggle>
                                <span class="flex flex-col flex-grow" id="limit_to_specific_customer-label">
                                    <span class="text-sm font-medium text-gray-900">
                                        Limit to specific customer
                                    </span>
                                    <span class="text-sm text-gray-500">
                                        If enabled, to set the specific customers that is eligible for discounts, add the relevant customers in the Apply to specific customers field.
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div>
                            <div v-if="promoCode.limit_to_specific_customer">
                                <VueMultiselect
                                    v-model="promoCode.coupon_specific_customers"
                                    label="customer_full_name"
                                    track-by="id"
                                    placeholder="Choose a customer"
                                    :options="specific_customers_list"
                                    :multiple="false"
                                    :searchable="true"
                                    :loading="isLoadingCustomers"
                                    :internal-search="false"
                                    :clear-on-select="false"
                                    :close-on-select="false"
                                    :options-limit="300"
                                    :limit="2"
                                    :limit-text="limitText"
                                    :max-height="600"
                                    :show-no-results="true"
                                    @search-change="doSearch($event, 'customers')"
                                >
                                    <span slot="noResult">Oops! No customers found. Consider changing the search query.</span>
                                </VueMultiselect>
                            </div>
                        </div>

                        <div class="grid grid-cols-2 gap-8" v-if="!promoCodeAlreadySaved()">
                            <div>
                                <label for="promo_code_min_status" class="block mb-1.5 text-sm">
                                    Minimum order value
                                </label>
                                <div
                                    class="grid gap-1"
                                    :class="promoCode.minimum_order_status === '0' ? 'grid-cols-1' : 'grid-cols-2'"
                                >
                                    <select
                                        id="promo_code_min_status"
                                        v-model="promoCode.minimum_order_status"
                                        class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                                        :disabled="promoCodeAlreadySaved()"
                                    >
                                        <option value="0">OFF</option>
                                        <option value="1">ON</option>
                                    </select>
                                    <text-field
                                        v-if="promoCode.minimum_order_status !== '0'"
                                        v-model="promoCode.minimum_order_value"
                                        :disabled="promoCode.minimum_order_status === '0' || promoCodeAlreadySaved()"
                                        :required="promoCode.minimum_order_status !== '0'"
                                    >
                                    </text-field>
                                </div>
                                <small class="text-gray-500 mt-1 block">
                                    Set an minimum amount that is eligible to use a promotion code.
                                </small>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-8" v-if="!promoCodeAlreadySaved()">
                            <redemption-specific-date-period
                                id="spec-date-period-promo-code"
                                name="Expiration date"
                                description="Limit the date when customers can redeem this promotion code."
                                v-model:type="promoCode.expiration_date_status"
                                v-model:redemption_value="promoCode.expiration_date_value"
                                :disabled="promoCodeAlreadySaved()"
                            />
                            <redemption-times-period
                                name="Number of times"
                                description="Limit the total number of times this code can be redeemed."
                                v-model:type="promoCode.redemption_times_status"
                                v-model:redemption_value="promoCode.redemption_times_value"
                                :disabled="promoCodeAlreadySaved()"
                            />
                        </div>

                        <hr />

                        <div>
                            <label class="block mb-1.5 text-sm">
                                Metadata
                            </label>
                            <div
                                v-for="(metadata, metadataIndex) in promoCode.metadata"
                                :key="'promoCode-' + '-metadata-' + metadataIndex"
                                class="grid grid-cols-5 gap-4 mb-2"
                            >
                                <text-field
                                    class="md:col-span-2"
                                    type="text"
                                    v-model="metadata.key"
                                    placeholder="Key"
                                >
                                </text-field>
                                <text-field
                                    class="col-span-3"
                                    type="text"
                                    v-model="metadata.value"
                                    placeholder="Value"
                                >
                                    <template #append>
                                        <div class="inline-flex items-center pl-2 text-gray-500 cursor-pointer">
                                            <icon
                                                name="trash"
                                                size="18"
                                                @click="removeMetadata(metadataIndex)"
                                            />
                                        </div>
                                    </template>
                                </text-field>
                            </div>
                            <btn type="secondary"
                                 icon-name="add-circle"
                                 @click="addMetadata()"
                                 size="small"
                            >
                                Add metadata
                            </btn>
                        </div>

                    </div>

                    <div class="flex items-center justify-end" v-if="promoCodeAlreadySaved() && canArchive">
                        <btn @click.prevent="toggleActivePromoCode()" v-if="promoCode.active"
                             icon-name="archive" type="secondary" size="small">
                            <spinner
                                v-if="togglingActivePromoCode"
                                class="mr-2"
                            />
                            Archive
                        </btn>
                        <btn @click.prevent="toggleActivePromoCode()" v-if="!promoCode.active"
                             icon-name="unarchive" type="secondary" size="small">
                            <spinner
                                v-if="togglingActivePromoCode"
                                class="mr-2"
                            />
                            Unarchive
                        </btn>
                    </div>

                    <div>

                    </div>

                </div>
            </template>
            <template #footer>
                <btn v-if="promotionCodeAction === 'create'"
                     type="primary"
                     @click="savePromotionCode()"
                     :disabled="savingPromotionCode"
                >
                    <spinner
                        v-if="savingPromotionCode"
                        :is-white="true"
                        class="mr-1.5"
                    />
                    {{ savingPromotionCode ? 'Creating...' : 'Create' }}
                </btn>
                <btn v-if="editingAction && promoCodeAlreadySaved()"
                     type="primary"
                     @click="updatePromotionCodeMetadata()"
                     :disabled="updatingPromotionCode"
                >
                    <spinner
                        v-if="updatingPromotionCode"
                        :is-white="true"
                        class="mr-1.5"
                    />
                    {{ updatingPromotionCode ? 'Updating...' : 'Update' }}
                </btn>
            </template>
        </modal>
    </div>
</template>

