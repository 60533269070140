<template>
    <modal title="Are you sure?" size="small">
        <template #toggle>
            <options-item title="Delete"></options-item>
        </template>
        <template #body>
            <div class="space-y-6">
                <validation-errors :errors="validationErrors"/>
                <div class="text-sm text-gray-500">
                    Do you really want to delete this customer?<br/>
                    This process cannot be undone.
                </div>
                <div class="grid gap-x-6 gap-y-4">
                    <div class="col-span-2">
                        <label class="block text-sm mb-1.5">Please type <span class="font-semibold">DELETE</span>
                            to confirm.</label>
                        <text-field v-model="confirmationString"/>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <btn type="primary" @click="deleteCustomer" :loading="loading.deleting">
                Yes, delete
            </btn>
        </template>
    </modal>
    <modal title="Are you sure?" size="small" v-if="!archived">
        <template #toggle>
            <options-item title="Archive"></options-item>
        </template>
        <template #body>
            <div class="space-y-6">
                <validation-errors :errors="validationErrors"/>
                <div class="text-sm text-gray-500">
                    Do you really want to archive this customer?
                </div>
            </div>
        </template>
        <template #footer>
            <btn type="primary" @click="archiveCustomer" :loading="loading.archiving">
                Yes, archive
            </btn>
        </template>
    </modal>
    <modal title="Are you sure?" size="small" v-if="archived">
        <template #toggle>
            <options-item title="Restore archived"></options-item>
        </template>
        <template #body>
            <div class="space-y-6">
                <validation-errors :errors="validationErrors"/>
                <div class="text-sm text-gray-500">
                    Do you really want to restore archived customer?
                </div>
            </div>
        </template>
        <template #footer>
            <btn type="primary" @click="restoreArchiveCustomer" :loading="loading.restoring_archived">
                Yes, restore archived
            </btn>
        </template>
    </modal>
</template>

<script>
import ValidationErrors from "../../../common/ValidationErrors";

export default {
    name: "DeleteCustomerModal",
    components: {ValidationErrors},
    props: {
        customerKey: {
            type: [String, Number],
            required: true
        },
        programId: {
            type: [Number, String],
            required: true
        },
        isArchived: {
            default: false
        }
    },
    data() {
        return {
            confirmationString: '',
            validationErrors: null,
            archived: this.isArchived === true || this.isArchived === '1' || this.isArchived === 1,
            loading: {
                archiving: false,
                deleting: false,
                restoring_archived: false
            }
        }
    },
    methods: {
        deleteCustomer() {

            if (this.loading.deleting) {
                return;
            }
            this.loading.deleting = true;

            axios.delete(route('programs.customer.destroy', {'program': this.programId}), {
                data: {
                    key: this.customerKey,
                    'confirmation_string': this.confirmationString
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        window.location.href = '/programs/' + this.programId + '/referrals'
                    }
                })
                .catch(error => {
                    this.validationErrors = error.response?.data?.errors;
                })
                .finally(() => this.loading.deleting = false);
        },
        archiveCustomer() {

            if (this.loading.archiving) {
                return;
            }
            this.loading.archiving = true;

            axios.post(route('app_int.customer.archive', {'program': this.programId}), {
                key: this.customerKey
            })
                .then(response => {
                    if (response.status === 200) {
                        window.location.reload();
                    }
                })
                .catch(error => {
                    this.validationErrors = error.response?.data?.errors;
                })
                .finally(() => this.loading.archiving = false);
        },
        restoreArchiveCustomer() {

            if (this.loading.restoring_archived) {
                return;
            }
            this.loading.restoring_archived = true;

            axios.post(route('app_int.customer.restore_archived', {'program': this.programId}), {
                key: this.customerKey
            })
                .then(response => {
                    if (response.status === 200) {
                        window.location.reload();
                    }
                })
                .catch(error => {
                    this.validationErrors = error.response?.data?.errors;
                })
                .finally(() => this.loading.restoring_archived = false);
        }
    }
}
</script>
