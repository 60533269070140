<template>
    <div class="rounded border bg-white">
        <div class="flex justify-between p-4 handle">
            <div class="flex items-center space-x-3 truncate">
                <button type="button" class="cursor-move focus:outline-none">
                    <svg class="w-5 h-5 text-gray-500 hover:text-gray-900" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                    </svg>
                </button>

                <div class="flex items-center space-x-3 -my-4 h-12 cursor-pointer" @click.native="data.edit=!data.edit">

                    <svg v-if="data.type === 'links'" class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"/></svg>
                    <svg v-else-if="data.type === 'assets'" class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 8v12.993A1 1 0 0 1 20.007 22H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8zm-2 1h-5V4H5v16h14V9zM8 7h3v2H8V7zm0 4h8v2H8v-2zm0 4h8v2H8v-2z"/></svg>
                    <svg v-else-if="data.type === 'banners'" class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M5 11.1l2-2 5.5 5.5 3.5-3.5 3 3V5H5v6.1zm0 2.829V19h3.1l2.986-2.985L7 11.929l-2 2zM10.929 19H19v-2.071l-3-3L10.929 19zM4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm11.5 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg>
                    <svg v-else-if="data.type === 'accordion'" class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"/></svg>
                    <svg v-else-if="data.type === 'colors'" class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 2c5.522 0 10 3.978 10 8.889a5.558 5.558 0 0 1-5.556 5.555h-1.966c-.922 0-1.667.745-1.667 1.667 0 .422.167.811.422 1.1.267.3.434.689.434 1.122C13.667 21.256 12.9 22 12 22 6.478 22 2 17.522 2 12S6.478 2 12 2zm-1.189 16.111a3.664 3.664 0 0 1 3.667-3.667h1.966A3.558 3.558 0 0 0 20 10.89C20 7.139 16.468 4 12 4a8 8 0 0 0-.676 15.972 3.648 3.648 0 0 1-.513-1.86zM7.5 12a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm9 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM12 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg>
                    <svg v-else-if="data.type === 'videos'" class="w-5 h-5 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M3 3.9934C3 3.44476 3.44495 3 3.9934 3H20.0066C20.5552 3 21 3.44495 21 3.9934V20.0066C21 20.5552 20.5551 21 20.0066 21H3.9934C3.44476 21 3 20.5551 3 20.0066V3.9934ZM5 5V19H19V5H5ZM10.6219 8.41459L15.5008 11.6672C15.6846 11.7897 15.7343 12.0381 15.6117 12.2219C15.5824 12.2658 15.5447 12.3035 15.5008 12.3328L10.6219 15.5854C10.4381 15.708 10.1897 15.6583 10.0672 15.4745C10.0234 15.4088 10 15.3316 10 15.2526V8.74741C10 8.52649 10.1791 8.34741 10.4 8.34741C10.479 8.34741 10.5562 8.37078 10.6219 8.41459Z"></path></svg>
                    <svg v-else class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path d="M13 6v15h-2V6H5V4h14v2z"></path></svg>

                    <span class="text-sm text-gray-900 truncate max-w-96">
                        {{ data.title }}
                        <span v-if="!data.title" class="text-gray-500">
                            <template v-if="data.type === 'accordion'">Accordion</template>
                            <template v-if="data.type === 'assets'">Assets</template>
                            <template v-if="data.type === 'banners'">Banners</template>
                            <template v-if="data.type === 'links'">Links</template>
                            <template v-if="data.type === 'colors'">Colors</template>
                            <template v-if="data.type === 'videos'">Video</template>
                        </span>
                    </span>

                </div>
            </div>
            <div class="flex pl-6 space-x-4">
                <button type="button" class="focus:outline-none" @click.native="$emit('remove', data)"
                        v-if="!data.protected">
                    <svg class="w-5 h-5 text-gray-500 hover:text-red-600" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"/>
                    </svg>
                </button>
                <button type="button" class="focus:outline-none" @click.native="data.edit=!data.edit">
                    <svg class="w-5 h-5 text-gray-500 hover:text-gray-900"
                         v-bind:class="{ 'text-teal-600 hover:text-teal-700': data.edit }" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                    </svg>
                </button>
            </div>
        </div>

        <div class="py-6 px-6 bg-gray-50 bg-opacity-75 border-t space-y-4" v-if="data.edit">

            <div class="space-y-4">

                <text-field
                    label="Title"
                    v-model="data.title"
                />

                <text-editor
                    :text="data.description"
                    @input="(newValue) => { data.description = newValue }"
                />

                <div>
                    <label class="flex justify-between items-center mb-1.5 text-sm"> Content </label>

                    <div v-if="data.type === 'assets'">
                        <Assets :data="data" :options="options" :unique_id="unique_id"></Assets>
                    </div>

                    <div v-if="data.type === 'accordion'">
                        <Accordion :data="data" :options="options" :unique_id="unique_id"></Accordion>
                    </div>

                    <div v-if="data.type === 'links'">
                        <Links :data="data" :options="options" :unique_id="unique_id"></Links>
                    </div>

                    <div v-if="data.type === 'banners'">
                        <Banners :data="data" :options="options" :unique_id="unique_id"></Banners>
                    </div>

                    <div v-if="data.type === 'colors'">
                        <Colors :data="data" :options="options" :unique_id="unique_id"></Colors>
                    </div>

                    <div v-if="data.type === 'videos'">
                        <Videos :data="data" :options="options" :unique_id="unique_id"></Videos>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Banners from './Res/Banners.vue'
import Assets from './Res/Assets.vue'
import Links from './Res/Links.vue'
import Accordion from './Res/Accordion.vue'
import Colors from './Res/Colors.vue'
import {BubbleMenu, FloatingMenu,} from '@tiptap/vue-3'
import Videos from './Res/Videos.vue'

export default {
    props: ['data', 'options', 'unique_id'],
    components: {
        Assets,
        Banners,
        Links,
        Accordion,
        Colors,
        BubbleMenu,
        FloatingMenu,
        Videos,
    },
    created() {
        this.data.unique_id = this.unique_id;
    }
}
</script>

<style lang="scss">
.ProseMirror {
    min-height: 70px;

    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    &:focus, &:active {
        outline: none;
    }

    h1 {
        font-weight: bold;
        font-size: 32px;
        line-height: 1.1;
    }

    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.1;
    }
}

.tiptap-menu {
    display: flex;

    button {
        border: none;
        background: none;
        color: #FFF;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0 0.4rem;
        opacity: 0.6;

        &:focus, &:active {
            outline: none;
        }

        &:hover,
        &.is-active {
            opacity: 1;
        }
    }
}

.floating-menu {
    display: flex;
    background-color: #111827;
    padding: 0.2rem;
    border-radius: 0.5rem;

    button {
        border: none;
        background: none;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0 0.2rem;
        opacity: 0.6;

        &:hover,
        &.is-active {
            opacity: 1;
        }
    }
}
</style>
