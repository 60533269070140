<script setup>

import {useAnalyticsRealTimeStore} from "../../../../../../stores/app/programs/analytics/AnalyitcsRealTimeStore";

    const analyticsRealTimeStore = useAnalyticsRealTimeStore();

    const handleTimePeriodChange = (e) => {
        analyticsRealTimeStore.fetchRealTimeData();
        analyticsRealTimeStore.fetchRealTimePagesData();
        analyticsRealTimeStore.fetchRealTimeVisitorsBySourceData();
        analyticsRealTimeStore.fetchRealTimeVisitorsByReferralData();
    }

</script>

<template>
    <header-bar v-if="analyticsRealTimeStore?.getAnalyticsRealTimeProgram" size="small" title="Analytics">
        <template #actions>
            <div class="flex space-x-2">
                <select
                    id="realtime_period"
                    name="timePeriod"
                    v-model="analyticsRealTimeStore.timePeriod"
                    @change="handleTimePeriodChange"
                    class="-mt-2 xl:-mt-4 -mr-4 block border-0 focus:ring-0 leading-4 ml-auto px-8 py-1.5 rounded text-right text-xs text-gray-500">
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="last_week">Last week</option>
                    <option value="this_month">This month</option>
                    <option value="last_month">Last month</option>
                    <option value="last_6_months">Last 6 months</option>
                    <option value="this_year">This year</option>
                    <option value="last_year">Last year</option>
                </select>
            </div>
        </template>
    </header-bar>
</template>
