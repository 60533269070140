<template>

    <div class="space-y-6">

        <alert type="neutral">
            <p>The Partnero JS is a straightforward front-end based method for integrating Partnero with any application making simple JavaScript calls.</p>
        </alert>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>
        </div>

        <tabs>
            <tab @click="setActiveTab(TAB_1)" :class='{"border-b-4": activeTab === TAB_1}'>
                Sign-ups tracking
            </tab>
            <tab @click="setActiveTab(TAB_2)" :class='{"border-b-4": activeTab === TAB_2}'>
                Sales tracking
            </tab>
        </tabs>

        <div class="space-y-4" v-show="activeTab === TAB_1">

            <div class="space-y-4">
                <div class="space-y-2">
                    <div class="text-sm space-y-2">
                        <p>Notify Partnero about new user sign-ups to keep track of referrals from affiliate partners.</p>
                        <p>Immediately after a user successfully signs up, make sure to run this script to ensure proper tracking.</p>
                    </div>
                </div>
            </div>

            <div class="space-y-4">
                    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-js">
&#60;script&#62;
    po('customers',
      'signup', {
        data: {
            key: 'customer_123456', // or email address
            name: 'John',
            email: 'john.doe@partnero.com'
        }
      }
    );
&#60;/script&#62;</code>
                    </pre>
            </div>

            <div class="space-y-4">
                <div class="text-sm space-y-2">
                    Customers referred by partners are automatically identified when their data matches information stored in the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">partnero_partner</span> cookie, which is set by our Partnero Universal script. This process is handled by the Partnero Universal script, ensuring accurate recognition of referred customers.
                </div>
            </div>


        </div>

        <div class="space-y-4" v-show="activeTab === TAB_2">

            <div class="space-y-4">
                <div class="space-y-2">
                    <div class="text-sm space-y-2">
                        <p>The following step of the integration is sending data about sales. When any user makes a purchase, it is recommended to inform Partnero about it.</p>
                        <p>Please ensure to execute this script immediately following a successful sale.</p>
                    </div>
                </div>
            </div>

            <div>
                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-js">
&#60;script&#62;
    po('transactions', 'create', {
        data: {
            key: 'transaction_123',
            amount: 99.99,
            amount_units: 'usd',
            customer: {
                key: 'customer_123456'
            }
        }
    });
&#60;/script&#62;</code>
                </pre>
            </div>

        </div>

    </div>

</template>
<script setup>

import {ref} from 'vue';

const TAB_1 = 'tab_1';
const TAB_2 = 'tab_2';

let activeTab = ref(TAB_1);

const setActiveTab = (tab) => {
    activeTab.value = tab;
};

</script>
