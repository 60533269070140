<template>
    <div>
        <label :for="input_names.select" class="block mb-1.5 text-sm">{{text.name}}</label>
        <div class="grid gap-1" :class="types_value_disabled ? 'grid-cols-1' : 'grid-cols-2'">
            <select :id="input_names.select" :name="input_names.select"  v-model="type"
                    class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
                <option value="off">OFF</option>
                <option value="hours">Hours</option>
                <option value="days">Days</option>
                <option value="months">Months</option>
            </select>
            <text-field :model-value="times_value" :name="input_names.input" v-if="!types_value_disabled"
                        :disabled="types_value_disabled" :required="!types_value_disabled"></text-field>
        </div>
        <small class="text-gray-500 mt-1 block">{{text.description}}</small>
    </div>
</template>
<script>
export default {
    name: 'CommissionReviewPeriod',
    props: ['commission_type', 'commission_times_value', 'name', 'description', 'select_name', 'input_name'],
    data() {
        return {
            text:{
                name: this.name || 'Commission review period',
                description: this.description || 'After this period, the referred purchase will count toward a reward.',
            },
            input_names: {
                select: this.select_name || 'commission_review_period_type',
                input: this.input_name || 'commission_review_period_times_value',
            },
            type: this.commission_type || 'off',
            times_value: this.commission_times_value,
        }
    },
    computed: {
        types_value_disabled() {
            return this.type === 'off';
        }
    },
    methods: {}
}
</script>
<style scoped>
</style>
