import {ref} from "vue";
import useFiltersAndPagination from '../../state/common/filters-and-pagination'

export default function useApprovalRequests() {

    const updatingApprovalRequest = ref(false)

    const { fetchDataFunction, fetchDataBaseUrl,filters, search: searchQuery } = useFiltersAndPagination()

    const approveRejectRequest = async (approveOrRejectUrl, data = {}) => {
        updatingApprovalRequest.value = true
        axios.patch(approveOrRejectUrl, data)
            .then(response => {
                if (response.status === 202) {
                    updatingApprovalRequest.value = false
                    fetchDataFunction.value(fetchDataBaseUrl.value, {...filters.value, search: searchQuery.value})
                }
            })
            .catch(error => {
            })
            .finally(() => updatingApprovalRequest.value = false);
    }

    return {approveRejectRequest}
}
