<template>
    <div class="col-span-2 space-y-4">

        <text-field
            :label="tosUrlLabel"
            :name="tosUrlName"
            placeholder="https://"
            type="text"
            :model-value="props.tosUrl"
            @update:model-value="$emit('update:tos-url', $event)"
        >
            <template #note>
                Once URL is entered, a checkbox will appear on your signup form.
            </template>
        </text-field>

        <div class="space-y-2">
            <div>
                <div class="flex justify-between items-center">
                    <toggle
                        :name="tosSaveToggleName"
                        size="default"
                        v-model="tosSaveModel"
                        @update:model-value="$emit('update:tos-save', $event)"
                    ></toggle>
                    <span class="flex flex-col flex-grow">
                        <span class="text-sm font-medium text-gray-900">{{ tosSaveToggleLabel }}</span>
                        <span class="text-sm text-gray-500">{{ tosSaveToggleDescription }}</span>
                    </span>
                </div>
            </div>

            <div v-if="tosSaveModel">
                <label class="block mb-1.5 text-sm">{{ tosTextLabel }}</label>
                <text-editor 
                    :name="tosTextName" 
                    :text="props.tosText"
                    @input="$emit('update:tos-text', $event)"
                ></text-editor>
            </div>
        </div>

    </div>
</template>

<script setup>
import {ref} from "vue";

const props = defineProps({
    tosUrl: String,
    tosSave: String,
    tosText: String,
    tosUrlLabel: {
        type: String,
        default: 'URL'
    },
    tosUrlName: {
        type: String,
        default: 'portal_signup_tos_url'
    },
    tosSaveToggleName: {
        type: String,
        default: 'portal_signup_tos_saved_locally'
    },
    tosSaveToggleLabel: {
        type: String,
        default: 'Save program terms on Partnero'
    },
    tosSaveToggleDescription: {
        type: String,
        default: 'Save your program terms on Partnero and edit them whenever necessary.'
    },
    tosTextLabel: {
        type: String,
        default: 'Program terms'
    },
    tosTextName: {
        type: String,
        default: 'portal_signup_tos_text'
    }
})

const tosSaveModel = ref(!!props.tosSave)
</script>
