<template>
    <div class="bg-white rounded border p-4">
        <!-- Integration not connected -->
        <div class="flex justify-between items-center" v-if="!isConnected">
            <div class="text-sm text-gray-900">
                Kit is not connected.
            </div>
            <div>
                <modal size="small" title="Kit integration" name="esp_connect">
                    <template #toggle>
                        <btn type="secondary" size="small">Authorize</btn>
                    </template>
                    <template #body>

                        <div class="grid space-y-6">
                            <div>
                                <text-field
                                    label="API key"
                                    name="api_key"
                                    type="text"
                                    class="col-span-2"
                                    v-model="apiKey"
                                    autofocus="true">
                                    <template #note>
                                        To access your API key, please log in to your Kit account, navigate to Settings and go to Advanced section.
                                    </template>
                                </text-field>
                            </div>

                            <div>
                                <text-field
                                    label="API secret"
                                    name="api_secret"
                                    type="text"
                                    class="col-span-2"
                                    v-model="apiSecret">
                                    <template #note>
                                        Your API secret is right below your API key.
                                    </template>
                                </text-field>
                            </div>
                        </div>

                    </template>
                    <template #footer>
                        <btn type="primary" @click="connect" :loading="loading.connecting">
                            Connect
                        </btn>
                    </template>
                </modal>

            </div>
        </div>
        <!-- Integration is connected -->
        <div class="flex justify-between items-center gap-4" v-if="isConnected">
            <div class="flex items-center">
                <div class="flex-shrink-0 w-10 h-10">
                    <img class="w-10 h-10 rounded" src="/images/integrations/convertkit-integration.jpg">
                </div>
                <div class="text-sm text-gray-500 ml-5">
                    Successfully connected.
                </div>
            </div>
            <DisconnectNrpButton title="Kit disconnect"
                                 :loading="loading.disconnecting"
                                 @disconnectEsp="disconnect"
                                 v-model:delete-existing-subscribers="deleteExistingSubscribersFromPartnero"
                                 provider="ConvertKit"
            ></DisconnectNrpButton>
        </div>
    </div>

    <div v-if="isConnected && enabled_settings.setting_tag_import">
        <label for="format" class="block text-sm mb-1.5">Subscriber list</label>
        <select name="esp_convertkit_tag_import" class="input" v-model="espSettingsStore.tag_import">
            <option value="0">Select a tag</option>
            <option v-for="tag in espSettingsStore.tags" :value="tag.id">{{ tag.name }}</option>
        </select>
        <small class="text-gray-500 mt-1 block">
            Please select the subscribers (by tag) who will participate in your newsletter referral program.</small>
    </div>

    <div v-if="isConnected && enabled_settings.setting_tag_export">
        <label for="format" class="block text-sm mb-1.5">Destination list</label>
        <select name="esp_convertkit_tag_export" class="input" v-model="espSettingsStore.tag_export">
            <option value="0">Select a tag</option>
            <option v-for="tag in espSettingsStore.tags" :value="tag.id">{{ tag.name }}</option>
        </select>
        <small class="text-gray-500 mt-1 block">Please select the tag that you'd like to use for tagging referred subscribers in your ConvertKit account.</small>
    </div>

    <div class="flex justify-between items-center" v-if="isConnected && enabled_settings.setting_subscribers_sync">
        <toggle name="esp_convertkit_subscribers_sync" v-model="subscribers_sync"></toggle>
        <span class="flex flex-col flex-grow">
            <span class="text-sm font-medium text-gray-900">Enable subscribers synchronization</span>
            <span class="text-sm text-gray-500">When enabled every new subscriber that has the selected tag will be synced with your newsletter referral program.</span>
        </span>
    </div>
    <input type="hidden" name="esp_convertkit_enabled_settings" v-model="enabled_settings_json">

    <div v-if="isConnected">
        <label class="block text-sm mb-1.5">Subscriber form (optional)</label>
        <select
            class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
            name="convert_kit_subscribers_form_id"
            v-model="convert_kit_subscribers_form_id"
        >
            <option value="0">Select form</option>
            <option v-for="form in forms" :value="form.id">
                {{ form.name }}
            </option>
        </select>
        <small class="text-sm text-gray-500">
            To ensure your subscribers are automatically enrolled in your newsletter referral program through Kit,
            <convert-kit-instructions class="inline-block"></convert-kit-instructions>.
        </small>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import ConvertKitInstructions from "./instructions/ConvertKitInstructions.vue";
import FieldLabel from "../../../../../elements/forms/FieldLabel.vue";
import DisconnectNrpButton from "./components/DisconnectNrpButton.vue";
import {useEspSettingsStore} from "../../../../../stores/app/programs/newsletter/EspSettingsStore";

export default {
    name: "EspConvertKit",
    components: {DisconnectNrpButton, FieldLabel, ConvertKitInstructions, VueMultiselect},
    props: [
        'program', 'esp_config', 'setting_tag_import', 'setting_tag_export', 'setting_subscribers_sync'
    ],
    setup (props) {
        const espSettingsStore = useEspSettingsStore();
        espSettingsStore.setTags(props.esp_config?.tags ?? [])
        espSettingsStore.setTagImport(props.esp_config?.tag_import ?? 0);
        espSettingsStore.setTagExport(props.esp_config?.tag_export ?? 0);
        return {
            espSettingsStore
        }
    },
    data() {
        const espConfig = this.esp_config ?? {};

        return {
            apiKey: '',
            apiSecret: '',
            deleteExistingSubscribersFromPartnero: false,
            convert_kit_subscribers_form_id: espConfig.convert_kit_subscribers_form_id ?? 0,
            subscribers_sync: espConfig.subscribers_sync ?? true,
            isConnected: espConfig.connected ?? false,
            isSetupCompleted: espConfig.setupCompleted ?? false,

            loading: {
                connecting: false,
                disconnecting: false,
            },
            forms: espConfig.forms ?? [],
            enabled_settings: {
                setting_tag_import: this.setting_tag_import ?? false,
                setting_tag_export: this.setting_tag_export ?? false,
                setting_subscribers_sync: this.setting_subscribers_sync ?? false,
            }
        }
    },
    computed: {
        enabled_settings_json() {
            return JSON.stringify(this.enabled_settings);
        }
    },
    methods: {
        connect() {
            if (this.loading.connecting) {
                return;
            }
            this.loading.connecting = true;

            axios
                .post(route('app_int.newsletter.esp.connect', {program: this.program}), {
                    type: 'convertkit',
                    connect_config: {
                        api_key: this.apiKey,
                        api_secret: this.apiSecret,
                    }
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);

                    if (status === 1) {

                        let tmpTags = [];
                        const responseTags = response.data.result.tags ?? [];

                        this.forms = response.data.result.forms ?? [];

                        for (let i = 0; i < responseTags.length; i++) {
                            tmpTags.push(
                                {
                                    id: responseTags[i]['id'],
                                    name: responseTags[i]['name']
                                }
                            );
                        }

                        this.espSettingsStore.setTags(tmpTags);
                        this.isConnected = true;
                        this.emitter.emit('modal-off', 'esp_connect');
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading.connecting = false);
        },
        disconnect() {
            if (this.loading.disconnecting) {
                return;
            }
            this.loading.disconnecting = true;

            axios
                .post(route('app_int.newsletter.esp.disconnect', {program: this.program}), {
                    type: 'convertkit',
                    deleteExistingSubscribersFromPartnero: this.deleteExistingSubscribersFromPartnero
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);

                    if (status === 1) {
                        this.apiKey = '';
                        this.apiSecret = '';
                        this.espSettingsStore.setTags([]);
                        this.isConnected = false;
                    }
                })
                .catch(error => {
                })
                .finally(() => this.loading.disconnecting = false);
        },
    }
}
</script>

