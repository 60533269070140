<script setup>

    import useFiltersAndPagination from "../../../state/common/filters-and-pagination";
    import {onMounted, reactive, watch} from "vue";

    const props = defineProps({
        tableHeaders: {
            type: Array,
            required: true
        },
        lastColumnAlignment: {
            type: String,
            default: 'justify-end'
        },
        headerTextComponent: {
            type: String,
            default: 'span'
        }
    })

    const {filters, search, fetchDataFunction, fetchDataBaseUrl} = useFiltersAndPagination()
    const {setSorting: setSortingToStore} = useFiltersAndPagination()

    const sorting = reactive({
        key: '',
        direction: ''
    })

    const defaultSorting = props.tableHeaders.find(header => header.default === true)
    if (defaultSorting) {
        sorting.key = defaultSorting.key
        sorting.direction = defaultSorting.direction || 'desc'
    } else {
        const lastHeader = props.tableHeaders[props.tableHeaders.length - 1]
        sorting.key = lastHeader?.key || ''
        sorting.direction = lastHeader?.direction || 'desc'
    }

    watch(sorting, (updatedSorting) => {
        setSortingToStore(updatedSorting)
        fetchData()
    })

    const fetchData = () => {
        fetchDataFunction.value(fetchDataBaseUrl.value, {
            ...filters.value,
            search: search.value,
            sortingColumn: sorting.key,
            sortingDirection: sorting.direction
        })
    }

    const updateSorting = (header) => {
        if (!header.sortable) {
            return
        }
        if (sorting.key === header.key) {
            // do not confuse, this is to toggle sorting direction
            sorting.direction = sorting.direction === 'asc' ? 'desc' : 'asc'
        } else {
            sorting.key = header.key
            sorting.direction = 'desc'
        }
    }

    const getColumnClasses = (header) => {

        if (header.style) {
            return header.style
        }

        const isFirst = header.key === props.tableHeaders[0].key;
        const isLast = header.key === props.tableHeaders[props.tableHeaders.length - 1].key;

        return [
            isLast ? 'pl-2 pr-4 text-right' : 'text-left',
            isFirst ? 'px-4' : '',
            !isFirst && !isLast ? 'px-2' : '',
            header.sortable ? 'cursor-pointer' : ''
        ].join(' ');
    }

    const getIconName = () => {
        return sorting.direction === 'asc' ? 'sort-asc' : 'sort-desc'
    }
</script>

<template>
    <th v-for="(header) in tableHeaders"
        :key="'partner_' + header.key"
        scope="col"
        class="py-3 text-xs font-normal text-gray-500 uppercase tracking-wider"
        :class="getColumnClasses(header)"
    >
        <component :is="headerTextComponent"
             class="flex"
             @click="updateSorting(header)"
             :class="[
                 props.tableHeaders[props.tableHeaders.length - 1].key === header.key ? lastColumnAlignment : '',
                 header.sortable ? 'decoration-gray-300 hover:underline' : '',
                 header.headerTextComponentStyle || ''
             ]"
        >
            {{ header.label }}
            <icon v-if="header.sortable && sorting.key === header.key"
                  :name="getIconName(header)"
                  size="12"
                  class="ml-1 self-center"
            />
        </component>
    </th>
</template>
