<template>
    <filters-and-pagination
        :pagination-data="filtersAndPaginationData"
        :filterable-fields="filterFields"
        :data-exists="dataExists"
        :loading-data="loadingFiltersAndPaginationData"
        :showTableFilter="showTableFilter"
        model-name="logs"
    >
        <logs-data-table :logs="filtersAndPaginationData.data"
                         :table-headers="tableHeaders"
                         :program="program"
        />
    </filters-and-pagination>
</template>

<script>
import FiltersAndPagination from "../../filters-and-pagination/FiltersAndPagination";
import LogsDataTable from "./components/LogsDataTable";
import useRecentActivities from "../../../../composables/common/filter-and-pagination-data";
import {onMounted, toRef} from "vue";
import useFiltersAndPagination from "../../../../state/common/filters-and-pagination";

export default {
    name: "LogsIndex",
    components: {
        FiltersAndPagination,
        LogsDataTable
    },
    props: {
        showTableFilter: {
            type: Boolean,
            default: true
        },
        logsUrl: {
            default: ''
        },
        filterFields: {
            default: {}
        },
        tableHeaders: {
            default: []
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        },
        program: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const logsUrl = toRef(props, 'logsUrl')
        const filterFields = toRef(props, 'filterFields')
        const {setSearchFieldPlaceholder} = useFiltersAndPagination()

        const {
            loadingFiltersAndPaginationData,
            filtersAndPaginationData,
            getFiltersAndPaginationData
        } = useRecentActivities()

        const {setDataBaseUrl, setFetchDataFunction} = useFiltersAndPagination()

        onMounted(() => {
            setDataBaseUrl(logsUrl.value)
            setFetchDataFunction(getFiltersAndPaginationData)
            setSearchFieldPlaceholder('Search logs by message or details...')
        })

        return {
            filtersAndPaginationData,
            loadingFiltersAndPaginationData,
            filterFields,
        }
    }
}
</script>

