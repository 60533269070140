export const updateTemplateHeader = (template, settings) => {
    return template.replace(
        /<re-header.*re-header>/s,
        settings?.emails_logo_file
            ? '<re-header padding="60px 0 20px 0">\n' +
            '                             <re-block align="center">\n' +
            '                                <re-image src="' + settings?.emails_logo_file + '" width="50px"></re-image>\n' +
            '                              </re-block>\n' +
            '                          </re-header>'
            : '<re-header padding="60px 0 20px 0">\n' +
            '                            <re-block align="center" padding="0 0 20px 0">\n' +
            '                               <re-text font-size="20px">\n' +
            '                                   <b>{$program_name}</b>\n' +
            '                               </re-text>\n' +
            '                           </re-block>\n' +
            '          </re-header>'
    )
}

export const updateTemplateFooter = (template, settings) => {
    const brandColor = settings?.email_brand_color || DEFAULT_MAIL_COLOR
    const footerTextColor = settings?.email_footer_text_color || DEFAULT_FOOTER_TEXT_COLOR

    return template.replace(
        /<re-footer.*re-footer>/s,
        settings?.emails_disclaimer && settings?.emails_disclaimer !== '<p></p>' // text editor leaves p tags if everything's deleted
            ? '<re-footer padding="40px 20px 40px 20px" background-color="' + brandColor + '">\n' +
            '                        <re-block align="center">\n' +
            '                            <re-text color="' + footerTextColor + '" font-size="13px">\n' +
                                            settings?.emails_disclaimer.replace(/<p>/g,'').replace(/<\/p>/g,'<br>') +
            '                            </re-text>\n' +
            '                        </re-block>\n' +
            '                    </re-footer>'
            : '<re-footer padding="40px 20px 40px 20px" background-color="' + brandColor + '">\n' +
            '                        <re-block align="center">\n' +
            '                            <re-text color="' + footerTextColor  + '" font-size="13px">\n' +
                                            DEFAULT_FOOTER_TEXT +
            '                            </re-text>\n' +
            '                        </re-block>\n' +
            '                    </re-footer>'
    )
}

export const updateTemplateBrandColors = (template, settings) => {

    const brandColor = settings?.email_brand_color || DEFAULT_MAIL_COLOR

    // button, button border, email border and footer should use Brand color.
    // first we replace default database template color (this is needed for first change ever)
    return template.replace(
        /border="2px solid.*?"/s,
        'border="2px solid ' + brandColor + '"',
    )
        .replace(
            /border="1px solid.*?"/s,
            'border="1px solid ' + brandColor + '"',
        )
        .replace(
            /<re-footer.*?>/s,
            '<re-footer padding="40px 20px 40px 20px" background-color="' + brandColor + '">',
        )
}

export const updateTemplateBackgroundColors = (template, initialSettings, settings) => {
    const backgroundColor = settings?.email_background_color || DEFAULT_EMAIL_BACKGROUND_COLOR

    return template.replace(
        /re-main background-color="[^"]+"/g,
        're-main background-color="' + backgroundColor + '"'
    );
}

export const DEFAULT_FOOTER_TEXT = 'Copyright © {$program_name}<br>All rights reserved.<br>\n'
export const DEFAULT_MAIL_COLOR = '#14b8a6'
export const DEFAULT_TEXT_HEADING_AND_LINK_COLOR = '#000000'
export const DEFAULT_EMAIL_BACKGROUND_COLOR = '#FFFFFF'
export const DEFAULT_FOOTER_TEXT_COLOR = '#FFFFFF'
export const DEFAULT_DIVIDER_COLOR = '#d1d5db'

