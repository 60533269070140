<template>
    <div class="grid py-14 xl:grid-cols-3 gap-12">
        <div class="space-y-2">
            <h3 class="text-lg font-medium">
                Logo and disclaimer
            </h3>
            <p class="text-gray-500 text-sm">The following information will help us automatically build your
                email footers to comply with the CAN-SPAM act and international anti-spam laws.</p>
        </div>
        <div class="grid col-span-2 gap-y-8 gap-8">
            <div class="grid lg:grid-cols-2 gap-y-8">

                <file-upload
                    :src="emailSettingsStore.getSettings.emails_logo_file"
                    no_src=""
                    accept="image/*"
                    title="Default logo"
                    name="emails_logo_file"
                    @upload:done="updateStoreLogoSettings"
                    @delete:done="setStoreLogoSettingsToNull"
                    :upload_url="uploadUrl"
                    :delete_url="deleteUrl"
                    show_validation_errors_within_uploader
                ></file-upload>

                <div class="col-span-2">
                    <text-editor
                        :text="getFooterText()"
                        @input="(newValue) => { emailSettingsStore.getSettings.emails_disclaimer = newValue }"
                    ></text-editor>
                </div>

                <email-logo-update-confirmation
                    :is_set="emailSettingsStore.getSettings.emails_logo_updated"
                    :update_url="updateUrl"
                    title="Force logo update"
                    description="Lorem ipsum"
                    class="hidden"
                ></email-logo-update-confirmation>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useEmailSettingsStore} from "../../../../../stores/app/emails/settings/EmailSettingsStore";
import {DEFAULT_FOOTER_TEXT} from "../../../../../helpers/revolvapp-helper";

    const emailSettingsStore = useEmailSettingsStore()

    const props = defineProps({
        program: {
            type: [Number, Array, Object]
        }
    })

    const uploadUrl = route('app_int.program_settings_file.upload', props.program)
    const deleteUrl = route('app_int.program_settings_file.delete', props.program)
    const updateUrl = route('app_int.program.emails.settings.logo_update', props.program)

    const updateStoreLogoSettings = (data) => {
        emailSettingsStore.settings.emails_logo_file = data['emails_logo_file']?.url
    }
    const setStoreLogoSettingsToNull = () => {
        emailSettingsStore.settings.emails_logo_file = null
    }

    const getFooterText = () => {
        return (emailSettingsStore.getSettings.emails_disclaimer && emailSettingsStore.getSettings.emails_disclaimer !== '<p></p>')
               ? emailSettingsStore.getSettings.emails_disclaimer
               : DEFAULT_FOOTER_TEXT
    }

</script>
