<template>
    <table class="min-w-full divide-y divide-gray-200">
        <thead>
        <tr class="h-1">
            <th :colspan="TABLE_COLUMNS_COUNT">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <th class="pl-4 w-6">
                <options
                    :isRight="false"
                    name="partner_table_options"
                >
                    <template #toggle>
                        <div class="bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                            <icon name="arrow-down-small"></icon>
                        </div>
                    </template>
                    <template #items>
                        <options-item
                            title="Select all"
                            @click="setExportSelection('selectAll')"
                        />
                        <options-item
                            title="Select visible"
                            @click="setExportSelection('selectVisible')"
                        />
                    </template>
                </options>
            </th>
            <table-header-columns-and-sorting
                :tableHeaders="tableHeaders"
            />
        </tr>
        <tr class="h-1"/>
        </thead>
        <tbody class="divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!partners || partners?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && partners?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr v-for="(partner, index) in partners"
                :key="partner.id + '-' + partner.program_id + '-' + index"
                class="hover:bg-gray-50 cursor-pointer group"
            >
                <td class="pl-4 w-6">
                    <checkbox
                        v-model="exportOptions.selectedIds"
                        :value="partner.id"
                        @click="setExportSelection('selectCustom')"
                    />
                </td>
                <td
                    class="px-4 py-4 whitespace-nowrap font-medium text-sm text-gray-900 group-hover:text-teal-600"
                >
                    <div class="flex items-center">
                        <div
                            class="flex-shrink-0 h-10 w-10"
                            @click="viewPartner(partner.url)"
                        >
                            <avatar :avatar-details="partner.avatar_details"/>
                        </div>
                        <div class="ml-4">
                            <div @click="viewPartner(partner.url)">
                                <span>{{ partner.full_name }}</span>
                            </div>
                            <div class="flex text-sm font-normal text-gray-500 mt-0.5">
                                <div
                                    class="mr-1.5"
                                    @click="viewPartner(partner.url)"
                                >
                                    {{ partner.email }}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td
                    class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{ partner.signups }}
                </td>
<!--                <td-->
<!--                    class="px-4 py-4 text-sm font-medium text-gray-900 whitespace-nowraptext-gray-500"-->
<!--                    @click="viewPartner(partner.url)"-->
<!--                >-->
<!--                    <div v-if="partner.sales.current_balance.length === 0">0</div>-->
<!--                    <div v-else v-for="balance in partner.sales.current_balance">{{balance.formatted}}</div>-->
<!--                </td>-->
<!--                <td-->
<!--                    class="px-4 py-4 text-sm font-medium text-gray-900 whitespace-nowraptext-gray-500"-->
<!--                    @click="viewPartner(partner.url)"-->
<!--                >-->
<!--                    <div v-if="partner.sales.used_credit.length === 0">0</div>-->
<!--                    <div v-for="credit in partner.sales.used_credit">{{credit.formatted}}</div>-->
<!--                </td>-->
                <td
                    class="pl-4 pr-8 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                    @click="viewPartner(partner.url)"
                >
                    <time :datetime="partner.joining_date_formatted">
                        {{ partner.last_active }}
                    </time>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>
import Avatar from "../../../../../common/Avatar";
import InitialLoadingSkeletonRows from "../../../../../common/InitialLoadingSkeletonRows";
import NoSearchDataRow from "../../../../../common/NoSearchDataRow";
import ProgressBar from "../../../../../../elements/ProgressBar";
import filtersAndPagination from "../../../../../../composables/common/filter-and-pagination-data";
import useTableDataExporter from "../../../../../../composables/common/select-export-and-delete-data";
import TableHeaderColumnsAndSorting from "../../../../filters-and-pagination/TableHeaderColumnsAndSorting.vue";

export default {
    name: "ReferAFriendCustomersDataTable",
    emits: ['hide-options'],
    components: {
        TableHeaderColumnsAndSorting,
        Avatar,
        InitialLoadingSkeletonRows,
        NoSearchDataRow,
        ProgressBar
    },
    props: {
        partners: {
            default: []
        },
        tableHeaders: {
            default: []
        },
    },
    setup() {

        const TABLE_COLUMNS_COUNT = 4
        const {loadingFiltersAndPaginationData} = filtersAndPagination()
        const {exportOptions} = useTableDataExporter()

        return {
            loadingFiltersAndPaginationData,
            TABLE_COLUMNS_COUNT,
            exportOptions
        }
    },
    methods: {
        viewPartner(url) {
            window.location = url;
        },
        setExportSelection(type) {
            this.exportOptions.selectType = type;
            if (type === 'selectVisible' || type === 'selectAll') {
                this.exportOptions.selectedIds = [];
                this.partners.forEach(partner => {
                    this.exportOptions.selectedIds.push(partner.id);
                })
            }
            this.emitter.emit('hide-options', 'partner_table_options');
        }
    }
}
</script>
