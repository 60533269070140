<template>
    <options>
        <template #toggle>
            <btn type="secondary" icon-right="true" icon-name="more">Import</btn>
        </template>
        <template #items>

            <import-data
                title="Import customers"
                import-type="customers"
                modal-button-title="Import customers"
                modal-button-style="options-item"
                :import-url="customersImportUrl"
                :program="program"
            >
                <template #import-instructions>
                    <div class="text-gray-500 text-sm space-y-2">
                        <div>
                            Ensure that the headers in the first row of the worksheet are appropriately named. Provide a header for each respective column. Once you upload the file, you will be prompted to confirm the matching fields.
                        </div>
                        <ul class="list-disc space-y-1 ml-4">
                            <li><b>Email address</b>: email address of the customer</li>
                            <li><b>Name</b>: name of the customer</li>
                            <li><b>Last name (optional)</b>: last name of the customer</li>
                            <li><b>Customer key</b>: unique key of the customer, can be email address as well </li>
                            <li><b>Partner ID*</b>: unique ID of the partner, used to link customer with correct partner</li>
                            <li><b>Partner key / token*</b>: unique key of the partner (or referral link token), used to link customer with correct partner</li>
                            <li><b>Partner email*</b>: email address of the partner, used to link customer with correct partner</li>
                            <li><b>Joining date</b>: date when customer joined</li>
                        </ul>
                        <div class="py-2">
                            * one of the identificators (Partner ID / Partner key / Partner email) is required.
                        </div>
                        <div class="py-2">
                            <hr />
                        </div>
                        <div class="font-semibold mt-4">Recommendations:</div>
                        <div>
                            Before proceeding with the import, please ensure that you have filled in data in the
                            corresponding fields. Additionally, it's important to adhere to the limit of 100
                            characters for the name and last name, 64 chars for the key and 255 for other fields to avoid any issues.
                        </div>
                        <div>
                            Please make sure that the <i>Customer key</i> and <i>Email</i> columns contain unique values. This is
                            crucial for accurate data processing and to prevent any potential conflicts.
                        </div>
                        <div>
                            Also make sure you provided <i>Partner key</i> and/or <i>Partner Email</i> of existing partners
                            in the Partnero.
                        </div>
                        <div>For the <i>Joining date</i> column, please format the dates using the <i>Y-m-d</i> format.
                            This standard format ensures consistency and compatibility with the import process.
                        </div>
                        <div>By following these guidelines, you can ensure a smooth and accurate import of your data.</div>
                    </div>
                </template>
            </import-data>

            <import-data
                title="Import transactions"
                import-type="transactions"
                modal-button-title="Import transactions"
                modal-button-style="options-item"
                :import-url="transactionsImportUrl"
                :program="program"
            >
                <template #import-instructions>
                    <div class="text-gray-500 text-sm space-y-2">
                        <div>Ensure that the headers in the first row of the worksheet
                            are appropriately named. Provide a header for each respective column. Once you upload the
                            file, you will be prompted to confirm the matching fields.
                        </div>
                        <div>The following list contain required and optional columns for import:
                        </div>

                        <ul class="list-disc space-y-1 ml-4 mb-4">
                            <li><b>Transaction name (optional)</b>: name of the transaction</li>
                            <li><b>Customer key</b>: key of the a customer who made the transaction</li>
                            <li><b>Action</b>: action (signup / sale) for which the transaction was made</li>
                            <li><b>Reward date</b>: date on which the reward was generated from the transaction</li>
                            <li><b>Reward amount</b>: amount of the generated reward from the transaction</li>
                            <li><b>Amount (optional)</b>: full amount of the transaction</li>
                            <li><b>Paid out (optional)</b>: identification (true / false) whether the reward has been paid</li>
                            <li><b>Product ID (optional)</b>: related product id for which the transaction has been made</li>
                            <li><b>Product type (optional)</b>: related product type for which the transaction has been made</li>
                        </ul>
                        <div class="py-2">
                            <hr />
                        </div>
                        <div class="font-semibold mt-4">Recommendations:</div>
                        <div>Make sure you provided <b>Customer key</b> of existing customers.</div>
                        <div><b>Reward date</b> column's format should be <i>Y-m-d</i>.</div>
                        <div><b>Paid out</b> column determines if the reward has been paid. If the reward was already paid out, use value <i>true or TRUE or 1</i>.
                            If the reward is not paid yet, you can use value <i>false or FALSE or 0</i> or even leave the cell blank.
                            The default value is <i>false</i>.
                        </div>
                        <div>There is a limit of 100 characters for the <b>Transaction name</b>, and 64 characters for the <b>Customer key</b>.</div>

                    </div>
                </template>
            </import-data>

        </template>
    </options>
</template>

<script>
export default {
    name: "CustomerImportsList",
    props: {
        program: {
            type: [String, Number, Object],
            required: true
        },
    },
    data() {
        return {
            customersImportUrl: route('app_int.import.customers', this.program),
            transactionsImportUrl: route('app_int.import.transactions', this.program),
        }
    },
}


</script>

<style scoped>

</style>
