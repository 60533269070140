import {defineStore} from "pinia";
import Revolvapp from "../../../../revolvapp/revolvapp.usm.min";
import {
    updateTemplateBackgroundColors,
    updateTemplateBrandColors,
    updateTemplateFooter,
    updateTemplateHeader
} from "../../../../helpers/revolvapp-helper";

export let useEmailSettingsStore = defineStore('emailSettings', {
    state: () => ({
        program: null,
        settings: null,
        initialStyleSettingsLogoAndFooter: null, // needed to check if templates update is needed
        smtpSettings: {},
        updatingSettings: false,
        loadingSettings: false,
        revolvappTemplates: [],
        notification: {
            showNotification: false,
            notificationType: '',
            notificationTitle: '',
            notificationMessage: '',
        }
    }),

    getters: {
        getSenderGroupAndDomain: (state) => state.settings?.emails_sender_email_group + ';' + state.settings?.emails_sender_email_domain,
        getNotification: (state) => state.notification,
        getSettings: (state) => state.settings,
        getInitialStyleSettingsLogoAndFooter: (state) => state.initialStyleSettingsLogoAndFooter,
        getUpdatingSettingsStatus: (state) => state.updatingSettings,
        getLoadingSettingsStatus: (state) => state.loadingSettings,
        getRevolvappTemplates: (state) => state.revolvappTemplates
    },

    actions: {
        updateNotification(show, type, title, msg) {
            this.$patch({
                notification: {
                    showNotification: show,
                    notificationType: type,
                    notificationTitle: title,
                    notificationMessage: msg
                }
            })
        },
        init() {
            this.loadingSettings = true
            axios.get(route('app_int.program.emails.settings', this.program))
                .then(response => {
                    this.$patch({
                        settings: response.data.settings,
                        initialStyleSettingsLogoAndFooter: this.getResponseStyleSettingsLogoAndFooter(response.data.settings),
                        smtpSettings: response.data.smtp_settings,
                        revolvappTemplates: response.data.revolvapp_templates
                    })
                    this.loadingSettings = false
                })
                .finally(() => {
                    this.loadingSettings = false
                })
        },
        updateSettings() {
            this.updatingSettings = true
            axios.post(route('app_int.program.emails.settings.update', this.program),
                {
                    ...this.settings,
                    sender_domain_and_name: this.getSenderGroupAndDomain
                }
            )
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.$patch({
                            settings: response.data.email_settings.settings,
                            smtpSettings: response.data.email_settings.smtp_settings,
                            revolvappTemplates: response.data.email_settings.revolvapp_templates
                        })
                        if (this.styleChanged()) {
                            this.updateTemplates()
                            this.$patch({
                                initialStyleSettingsLogoAndFooter: this.getResponseStyleSettingsLogoAndFooter(response.data.email_settings.settings)
                            })
                        }
                        this.updateNotification(
                            true,
                            'success',
                            'Updated',
                            'Email setting successfully updated'
                        );
                        this.updatingSettings = false
                    }
                })
                .catch(error => {
                    if (error.response?.status === 422) {
                        const errors = error.response.data.errors
                        let messages = ''
                        Object.values(errors).map(error => {
                            error.map((message) => {
                                messages += message + '\n'
                            })
                        })
                        this.updateNotification(
                            true,
                            'warning',
                            'Update failed',
                            messages
                        )
                    } else {
                        this.updateNotification(
                            true,
                            'warning',
                            'Update failed',
                            'Updating settings failed. Check console for details.'
                        )
                    }
                    console.log(error, error.response)
                })
                .finally(() => {
                    this.updatingSettings = false
                })
        },
        updateTemplates() {
            const revolvApp = Revolvapp('#revolvApp', {
                styles: {
                    text: {
                        'font-size': '16px',
                        'color': this.getSettings.email_text_color
                    },
                    heading: {
                        'color': this.getSettings.email_text_color
                    },
                    link: {
                        'color': this.getSettings.email_link_color
                    },
                    button: {
                        'font-size': '16px',
                        'font-weight': 'bold',
                        'color': '#fff',
                        'background-color': this.getSettings.email_brand_color,
                        'padding': '20px 40px',
                        'border-radius': '4px'
                    },
                    divider: {
                        'height': '2px',
                        'background-color': this.getSettings.email_divider_color
                    },
                },
            })

            this.getRevolvappTemplates.forEach((template) => {
                const updatedTemplate = this.updateTemplateColors(template.mail?.revolvapp_template || template.revolvapp_template)
                revolvApp.app.editor.setTemplate(updatedTemplate)
                axios.post(route('programs.portal.emails.revolvapp_emails.autosave', this.program), {
                    'html': revolvApp.app.editor.getHtml(),
                    'template': revolvApp.app.editor.getTemplate(),
                    'slug': template.slug
                })
            })
        },
        styleChanged() {
            if (this.getInitialStyleSettingsLogoAndFooter) {
                return Object.keys(this.getInitialStyleSettingsLogoAndFooter).some(key => this.getInitialStyleSettingsLogoAndFooter[key] !== this.getSettings[key])
            } else return false
        },
        getResponseStyleSettingsLogoAndFooter(settings) {
            return {
                email_background_color: settings.email_background_color,
                email_footer_text_color: settings.email_footer_text_color,
                email_text_color: settings.email_text_color,
                email_link_color: settings.email_link_color,
                email_brand_color: settings.email_brand_color,
                email_divider_color: settings.email_divider_color,
                emails_disclaimer: settings.emails_disclaimer,
                emails_logo_file: settings.emails_logo_file
            }
        },
        // logo, footer, background and border-color are not able to define through app instantiation, therefore
        // we have to update it manually. Because of speed we only update it if it's changed
        updateTemplateColors(template) {
            if (this.getInitialStyleSettingsLogoAndFooter?.emails_logo_file !== this.getSettings?.emails_logo_file) {
                template = updateTemplateHeader(template, this.getSettings);
            }
            if (
                this.getInitialStyleSettingsLogoAndFooter?.emails_disclaimer !== this.getSettings?.emails_disclaimer
                || this.getInitialStyleSettingsLogoAndFooter?.email_footer_text_color !== this.getSettings?.email_footer_text_color
            ) {
                template = updateTemplateFooter(template, this.getSettings);
            }
            if (this.getInitialStyleSettingsLogoAndFooter?.email_background_color !== this.getSettings?.email_background_color) {
                template = updateTemplateBackgroundColors(template, this.getInitialStyleSettingsLogoAndFooter, this.getSettings);
            }
            if (this.getInitialStyleSettingsLogoAndFooter?.email_brand_color !== this.getSettings?.email_brand_color) {
                template = updateTemplateBrandColors(template, this.getSettings);
            }

            return template
        }

    }
})
