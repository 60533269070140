<template>
    <div class="inline-flex">
        <btn
            type="secondary"
            class="-mr-px rounded-r-none focus:z-10"
            @click="emailStore.manuallySaveTemplate()"
        >
            Save
        </btn>
        <div class="relative -ml-px block">
            <options>
                <template #toggle>
                    <button type="button"
                            class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                            id="option-menu-button" aria-expanded="true" aria-haspopup="true"
                    >
                        <span class="sr-only">Open options</span>
                        <!-- Heroicon name: mini/chevron-down -->
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                             fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clip-rule="evenodd"
                            />
                        </svg>
                    </button>
                </template>
                <template #items>
                    <options-item
                        title="Save as draft"
                        @click="emailStore.saveAsDraft()"
                    />
                    <update-subject-modal />
                    <reset-to-default-template-modal body-text="You will have to manually save template after confirmation." />
                </template>
            </options>
        </div>
    </div>
</template>

<script setup>

import {useRevolvappEmailStore} from "../../../../../../stores/app/emails/revolvapp/RevolvAppEmailStore";
import UpdateSubjectModal from "./UpdateSubjectModal";
import ResetToDefaultTemplateModal from "./ResetToDefaultTemplateModal";

const emailStore = useRevolvappEmailStore()
</script>

