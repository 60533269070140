<template>
    <div class="flex items-start">
        <div class="flex-shrink-0 w-10 h-10">
            <img class="w-10 h-10 rounded" src="/images/integrations/woocommerce.png"/>
        </div>
        <div class="ml-5 w-full">
            <div class="flex justify-between">
                <div class="flex items-center">
                    <span class="text-sm font-semibold">WooCommerce Coupons</span>
                    <!-- <WooCommerceAfterIntegrationInstructions
                        v-if="connected"
                        class="ml-1.5"
                        :webhook-url="integrationsConfig.url.webhook"
                        :events-list="integrationsConfig.supported_events"
                    /> -->
                </div>
                <badge title="Connected" v-if="connected"></badge>
            </div>
            <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
               Manage coupons on your WooCommerce store with Partnero.
            </div>
            <div class="flex gap-2 items-center">
            <modal v-if="!connected" :title="'WooCommerce integration'" size="small" name="woocommerce_integration">
                <template #toggle>
                    <btn v-if="!connected" type="secondary" size="small" class="-mr-px focus:z-10">
                        Authorize
                    </btn>
                </template>
                <template #body>
                    <validation-errors :errors="errors.validationErrors" />
                    <alert v-if="errors.responseError" type="error">
                        <span class="break-all">
                            {{ errors.responseError }}
                        </span>
                    </alert>
                    <div class="space-y-4">
                        <text-field
                            label="Site URL"
                            field-id="site_url"
                            name="site_url"
                            v-model="site_url"
                            type="text"
                            autofocus="true"
                            required="true">
                        </text-field>
                        <text-field
                            label="Consumer Key"
                            field-id="consumer_key"
                            name="consumer_key"
                            v-model="consumer_key"
                            type="password"
                            :show_reveal_password_icon="true"
                            required="true"
                            placeholder="e.g. ck_qwx..xwe"
                        >
                        </text-field>
                        <text-field
                            label="Consumer Secret"
                            field-id="consumer_secret"
                            name="consumer_secret"
                            v-model="consumer_secret"
                            type="password"
                            :show_reveal_password_icon="true"
                            required="true"
                            placeholder="e.g. cs_qwx..xwe"
                        >
                            <template #note>
                                Navigate to <strong>WooCommerce → Settings</strong>. On the <strong>Advanced</strong> select the <strong>REST API</strong> menu to generate your key. Make sure to select <strong>Read/Write</strong> permission.
                            </template>

                        </text-field>

                    </div>
                </template>
                <template #footer>
                    <div class="flex justify-end">
                        <btn type="primary" :loading="loading.authorize" class="-mr-px focus:z-10" @click="authorize">
                            Authorize
                        </btn>
                    </div>
                </template>
            </modal>
            <modal v-if="connected" title="WooCommerce integration" size="small" name="woocommerce_disconnect">
                <template #toggle>
                    <btn v-if="connected" size="small" class="-mr-px focus:z-10">Disconnect</btn>
                </template>
                <template #body>
                    <div class="space-y-4">
                        <div class="text-sm text-gray-500">Are you sure you wish to disconnect the WooCommerce integration?</div>
                    </div>
                </template>
                <template #footer>
                    <btn :loading="loading.disconnect" @click="disconnect">Disconnect</btn>
                </template>
            </modal>
            <!-- <modal v-if="connected" title="WooCommerce integration" size="large" name="woocommerce_instructions">
                <template #toggle>
                    <btn v-if="connected" type="secondary" size="small" class="-mr-px focus:z-10">Integration guide</btn>
                </template>
                <template #body>
                    <div class="space-y-4">
                        <WooCommerceCustomDataInstructions />
                    </div>
                </template>
            </modal> -->
            </div>
        </div>
    </div>
</template>
<script>

/**
 * @todo convert to composition api
 */
import ValidationErrors from "../../../common/ValidationErrors.vue";
import Alert from "../../../../elements/Alert.vue";

export default {
    components: {Alert, ValidationErrors},
    props: ['integrationsConfig', 'programId'],
    data() {
        return {
            site_url: '',
            consumer_key: '',
            consumer_secret: '',
            loading: {
                authorize: false,
                disconnect: false,
            },
            errors: {
                responseError: null,
                validationErrors: null,
            },
            connected: this.integrationsConfig.connected ?? false,
        }
    },
    methods: {
        isValidInputs() {
            return this.site_url.trim() !== '' || this.consumer_key.trim() !== '' || this.consumer_secret.trim() !== '';
        },
        authorize() {

            this.errors.responseErrors = null;

            if (!this.isValidInputs() || this.loading.authorize) {
                return;
            }
            this.loading.authorize = true;

            axios.post(
                route('app_int.integrations.woocommerce.connect', { program: this.programId }),
                {
                    site_url: this.site_url,
                    consumer_key: this.consumer_key,
                    consumer_secret: this.consumer_secret,
                }
            )
            .then((response) => {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {
                    window.location.reload();
                }
                else if (status === 0) {
                    this.errors.responseError = response.data?.error;
                }
            })
            .catch((error) => {
                this.errors.validationErrors = error.response?.data?.errors;
            })
            .finally(() => this.loading.authorize = false);
        },
        disconnect() {

            if (this.loading.disconnect) {
                return;
            }
            this.loading.disconnect = true;

            axios.post(
                route('app_int.integrations.woocommerce.disconnect', { program: this.programId })
            )
            .then((response) => {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {
                    window.location.reload();
                }
            })
            .catch((error) => {})
            .finally(() => this.loading.disconnect = false);
        }
    }
}
</script>
