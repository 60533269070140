<template>
    <div>
        <draggable-accordion
            class="mb-2"
            v-for="content in props.data.contents"
            :unique_id="content.unique_id"
            :edit="content.edit"
            @remove="removeContent"
            @toggle="content.edit = !content.edit"
        >
            <template #title>{{ contentName }}</template>
            <template #content>
                <div class="grid lg:grid-cols-2 gap-6 gap-y-4">
                    <div class="col-span-2">
                        <label
                            class="flex justify-between items-center mb-1.5 text-sm">
                            Title
                        </label>

                        <input
                            type="text"
                            v-model="content.title"
                            class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"/>
                    </div>
                    <div class="col-span-2">
                        <label
                            class="flex justify-between items-center mb-1.5 text-sm">
                            Image
                        </label>

                        <ResourceFileUpload
                            :unique_id="content.unique_id"
                            :data="content.image_resource"
                            accept="image/*"
                            title="Image"
                            name="image_resource"
                            no_src="/images/upload-image.png"
                        ></ResourceFileUpload>

                    </div>
                </div>
            </template>
        </draggable-accordion>

        <div class="flex justify-between items-center">
            <btn type="secondary" size="small" icon-name="add-circle" @click="addContent">Add {{ contentName }}</btn>
            <div class="flex justify-between items-center">
                <toggle
                    name="columns_toggle"
                    size="default"
                    v-model="data.display_content"
                />
                <span class="flex flex-col flex-grow" id="columns_toggle-label">
                    <span class="text-sm text-gray-900">Display content in columns</span>
                </span>
            </div>
        </div>

    </div>
</template>

<script setup>
import {defineProps} from 'vue';
import {uniqueId} from '../helpers';
import ResourceFileUpload from './ResourceFileUpload';

const contentName = 'Image';

const props = defineProps({
    data: Object
});

props.data.display_content = props.data?.display_content ?? false;
props.data.contents = props.data?.contents ?? [];

const addContent = () => {
    props.data.contents.push({
        unique_id: uniqueId(),
        edit: true,
        title: '',
        image_resource: {},
    });
}

const removeContent = (uniqueId) => {
    props.data.contents.splice(
        props.data.contents.findIndex(content => content.unique_id === uniqueId)
    , 1);
}
</script>
