<template>
    <div>
        <div class="space-y-12">
            <div class="flex flex-col justify-start gap-4  lg:flex-row lg:justify-between lg:items-center">

                <div>
                    <div class="text-2xl font-semibold">
                        No limits. No hidden fees.
                    </div>
                    <div class="text-md">
                        Grow and manage partnerships with peace of mind.
                    </div>
                </div>

                <div class="flex justify-between items-center" v-if="allowYearlySwitch">
                    <toggle :model-value="isPeriodYearly" :disabled="!allowYearlySwitch"
                            @update:modelValue="changePlansPeriod($event)"></toggle>
                    <div class="flex flex-col flex-grow">
                        <span class="text-sm font-medium text-gray-900">Billed yearly</span>
                        <span class="text-sm text-gray-500">Choose <span class="font-semibold">yearly billing</span> and get <span
                            class="font-semibold">2 months free</span>.</span>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 gap-y-6 lg:grid-cols-3 lg:gap-x-6">

                <template v-for="plan in plans">
                    <div
                        v-if="!plan.is_plan_newsletter"
                        v-show="plan?.period_interval === currentPeriodType"
                        class="bg-white border rounded p-6 xl:p-8">
                        <div class="relative flex">
                            <div class="flex flex-1">
                                <div class="flex flex-col">
                                    <span class="block text-xl font-medium text-gray-900">{{
                                            plan.details.title
                                        }}</span>
                                    <span
                                        class="mt-1 flex items-center text-sm text-gray-500 tracking-wide flex items-baseline gap-1">
                                    <span class="font-semibold text-lg text-gray-900">
                                      {{ plan.amount_formatted }}
                                    </span>
                                    <span>USD/{{ perPeriodText(plan) }}</span>
                                    </span>
                                    <span class="mt-5 flex items-center text-sm text-gray-900 tracking-wide">{{
                                            plan.details.description
                                        }}</span>
                                </div>
                            </div>
                            <div class="absolute right-0 top-0" v-if="plan.current">
                                <span
                                    class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">Current</span>
                            </div>
                        </div>
                        <div class="w-full mt-5">
                            <btn v-if="!plan.available_to_purchase" :disabled="true" size="huge" class="w-full">
                                Purchase
                            </btn>
                            <btn v-else @click.prevent="purchasePLanClicked(plan)"
                                 :disabled="!plan.available_to_purchase" size="huge" class="w-full">
                                Purchase
                            </btn>
                        </div>

                        <div>
                            <div class="mt-5 py-5 border-t border-gray-200 dark:border-gray-600 border-opacity-75">
                                <ul class="space-y-3 text-sm leading-tight">
                                    <li v-if="plan.details.program_count">
                                        <span class="tracking-wide text-black flex items-center space-x-2">
                                            <div class="opacity-75">
                                                <icon name="programs" size="14"/>
                                            </div>
                                            <div>
                                                <span class="font-semibold">{{ plan.details.program_count }}</span>
                                                <span v-if="plan.details.program_count == '1'"> program</span>
                                                <span v-else> programs</span>
                                            </div>
                                        </span>
                                    </li>
                                    <li v-if="plan.details.user_seats">
                                        <span class="tracking-wide text-black flex items-center space-x-2">
                                            <div class="opacity-75">
                                                <icon name="user" size="14"/>
                                            </div>
                                            <div>
                                                <span class="font-semibold">{{ plan.details.user_seats }}</span>
                                                <span v-if="plan.details.user_seats == '1'"> user seat</span>
                                                <span v-else> user seats</span>
                                            </div>
                                        </span>
                                    </li>
                                    <li v-for="feature in plan.details.features">
                                        <span class="tracking-wide text-black flex items-center space-x-2">
                                            <div class="opacity-75">
                                                <icon name="check-line" size="14"/>
                                            </div>
                                            <div v-html="feature"></div>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="border-t border-gray-200 border-opacity-75 pt-4"
                                 v-if="plan.details.grouped_features">
                                <div v-for="grouped_features in plan.details.grouped_features">
                                    <span class="font-semibold text-sm dark:text-white">{{
                                            grouped_features.title
                                        }}</span>
                                    <ul class="space-y-2 text-sm leading-tight mt-2 mb-4">
                                        <li v-for="feature in grouped_features.features" v-html="feature"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="plan.is_plan_newsletter"
                        v-show="plan?.period_interval === currentPeriodType"
                        class="bg-white border rounded p-6 xl:p-8 col-span-1 lg:col-span-3">
                        <div class="flex items-center justify-between gap-6 xl:gap-8">
                            <div class="flex flex-col">
                                <span class="block text-xl font-medium text-gray-900">Newsletter</span>
                                <span
                                    class="mt-1 flex items-center text-sm text-gray-500 tracking-wide flex items-baseline gap-1">
                                <span class="font-semibold text-lg text-gray-900">
                                  {{ plan.amount_formatted }}
                                </span>
                                <span>
                                    USD/{{ perPeriodText(plan) }}
                                </span>
                            </span>
                                <span class="mt-5 flex items-center text-sm text-gray-900 tracking-wide">Looking to launch only a Newsletter Referral Program? Choose our discounted plan designed specifically for that.</span>
                            </div>
                            <div>
                                <btn v-if="!plan.available_to_purchase" :disabled="true" size="huge" class="w-full">
                                    Purchase
                                </btn>
                                <btn v-else @click.prevent="purchasePLanClicked(plan)"
                                     :disabled="!plan.available_to_purchase" size="huge" class="w-full">
                                    Purchase
                                </btn>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <modal size="small" title="Program quota reached" name="excess_programs_modal">
            <template #body>
                <div class="space-y-6">
                    <div class="space-y-4" v-if="clickedExcessivePrograms">
                        <div class="text-sm space-y-2">
                            <p>You currently have <b>{{ clickedExcessivePrograms.has }}</b> programs active. The
                                <b>{{ clickedPlan.details.title }}</b> plan permits only
                                <b>{{ clickedExcessivePrograms.can_have }}</b>.</p>
                            <p>Please select and delete <b>{{ clickedExcessivePrograms.excess }}</b> program(s) of your
                                choice to comply with your plan's limits or choose higher plan.</p>
                        </div>
                        <div class="space-y-2">
                            <toggle
                                v-for="excessiveProgram in excessiveProgramsList"
                                :title="excessiveProgram.name"
                                @update:modelValue="excessiveProgramsDeleteListUpdate(excessiveProgram)">
                            </toggle>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <modal size="small" title="Are you sure?" name="excess_programs_you_sure_modal">
                    <template #toggle>
                        <btn>Delete selected</btn>
                    </template>
                    <template #body>
                        <div class="text-sm">
                            Are you sure? This cannot be undone!
                        </div>
                    </template>
                    <template #footer>
                        <btn @click="deletePrograms" :loading="loadingDeletePrograms">Yes, delete programs</btn>
                    </template>
                </modal>
            </template>
        </modal>
        <modal size="small" title="Program quota reached" name="excess_programs_modal_for_newsletter">
            <template #body>
                <div class="space-y-6">
                    <div class="space-y-4" v-if="clickedExcessivePrograms">
                        <div class="text-sm space-y-2">
                            <p>You currently have <b>{{ clickedExcessivePrograms.has }}</b> programs active. The
                                <b>{{ clickedPlan.details.title }}</b> plan permits only
                                <b>{{ clickedExcessivePrograms.can_have }}</b>.</p>
                            <p>Please select and delete <b>{{ clickedExcessivePrograms.excess }}</b> program(s) of your
                                choice to comply with your plan's limits or choose higher plan.</p>
                        </div>

                        <div class="space-y-2" v-if="clickedExcessivePrograms.has_non_newsletter_programs > 0">
                            <div class="text-sm">All non newsletter type programs will be deleted.</div>
                            <template v-for="excessiveProgram in excessiveProgramsList">
                                <template v-if="excessiveProgram.type !== 'newsletter'">
                                    <toggle
                                        :disabled="true"
                                        :title="excessiveProgram.name"
                                        :model-value="true">
                                    </toggle>
                                </template>
                            </template>
                        </div>

                        <div class="space-y-2">
                            <template v-for="excessiveProgram in excessiveProgramsList">
                                <template v-if="excessiveProgram.type === 'newsletter'">
                                    <toggle
                                        :title="excessiveProgram.name"
                                        @update:modelValue="excessiveProgramsDeleteListUpdate(excessiveProgram)">
                                    </toggle>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <modal size="small" title="Are you sure?" name="excess_programs_you_sure_modal">
                    <template #toggle>
                        <btn>Delete selected</btn>
                    </template>
                    <template #body>
                        <div class="text-sm">
                            Are you sure? This cannot be undone!
                        </div>
                    </template>
                    <template #footer>
                        <btn @click="deletePrograms" :loading="loadingDeletePrograms">Yes, delete programs</btn>
                    </template>
                </modal>
            </template>
        </modal>
    </div>
</template>
<script setup>

import {inject, ref} from "vue";
import Modal from "../../../elements/Modal.vue";
import Toggle from "../../../elements/forms/Toggle";

const props = defineProps({
    allPlans: {type: Object},
    currentPlan: {type: Object},
    excessiveProgramsList: {type: Object},
});
const emitter = inject('emitter')

const PLAN_KEY_TRIAL = 'trial';

const PERIOD_YEARLY = 'yearly';
const PERIOD_MONTHLY = 'monthly';

let clickedPlan = null;
let clickedExcessivePrograms = null;

const plans = ref(props.allPlans);
const excessiveProgramsDeleteList = [];
const currentPlan = ref(props.currentPlan);
const loadingDeletePrograms = ref(false);
const excessiveProgramsList = ref(props.excessiveProgramsList);
const excessiveProgramsModalName = ref('excess_programs_modal');

const allowYearlySwitch = ref(!!currentPlan.value === false);
if (currentPlan.value?.plan_key === PLAN_KEY_TRIAL) {
    allowYearlySwitch.value = true;
}

let currentPeriodType = ref(currentPlan.value?.period_interval ?? PERIOD_YEARLY);
if (currentPeriodType.value !== PERIOD_MONTHLY && currentPeriodType.value !== PERIOD_YEARLY) {
    currentPeriodType.value = PERIOD_YEARLY;
}

let isPeriodYearly = ref(currentPeriodType.value === PERIOD_YEARLY);

const perPeriodText = (plan) => {
    if (plan.period_interval === 'monthly') {
        return 'month';
    }
    if (plan.period_interval === PERIOD_YEARLY) {
        return 'year';
    }
};

const changePlansPeriod = (yearly) => {
    if (allowYearlySwitch.value) {
        isPeriodYearly.value = yearly;
        currentPeriodType.value = yearly ? PERIOD_YEARLY : PERIOD_MONTHLY;
    }
};

const purchasePLanClicked = (plan) => {

    clickedPlan = plan;

    const excessPrograms = plan.excess_plan_features?.programs_count ?? null;

    if (excessPrograms === null) {
        window.location.href = plan.checkout_url;
        return;
    }

    clickedExcessivePrograms = excessPrograms;
    excessiveProgramsModalName.value = (plan.key === 'newsletter_yearly' || plan.key === 'newsletter_monthly') ? 'excess_programs_modal_for_newsletter' : 'excess_programs_modal';
    emitter.emit('modal-on', excessiveProgramsModalName.value)
};

const deletePrograms = () => {
    if (loadingDeletePrograms.value) {
        return;
    }

    loadingDeletePrograms.value = true;

    axios
        .post(route('app_int.delete_excessive_programs', {}), {
            plan_key: clickedPlan.key,
            delete_programs_list: excessiveProgramsDeleteList
        })
        .then(response => {
            const status = parseInt(response.data.status || -1);

            emitter.emit('modal-off', excessiveProgramsModalName.value)
            emitter.emit('modal-off', 'excess_programs_you_sure_modal')

            if (status === 1 && clickedPlan) {
                window.location.href = clickedPlan.checkout_url;
                return;
            }
        })
        .finally(() => {
            loadingDeletePrograms.value = false;
        });
};

const excessiveProgramsDeleteListUpdate = (excessiveProgram) => {
    const index = excessiveProgramsDeleteList.indexOf(excessiveProgram.id);
    if (index !== -1) {
        excessiveProgramsDeleteList.splice(index, 1);
    } else {
        excessiveProgramsDeleteList.push(excessiveProgram.id);
    }
}

</script>
<style scoped></style>
