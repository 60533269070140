import {defineStore} from "pinia";

export let useAnalyticsRealTimeStore = defineStore('analyticsRealTime', {
    state: () => ({
        analyticsRealTimeStatsChartAndCountries: null,
        analyticsRealTimeCurrentVisitors: null,
        analyticsRealTimePagesData: null,
        analyticsRealTimeVisitorsBySourceData: null,
        analyticsRealTimeVisitorsByReferralData: null,
        program: null,
        timePeriod: 'last_week'
    }),

    actions: {
        setAnalyticsRealTimeData(data) {
            this.analyticsRealTimeStatsChartAndCountries = data
        },
        setAnalyticsRealTimeProgram(program) {
            this.program = program
        },
        fetchCurrentVisitorsData() {
            axios.get(route('app_int.analytics.real-time.current-visitors', { program: this.getAnalyticsRealTimeProgram.id }), {
                params: {
                    timePeriod: this.timePeriod
                }
            })
                .then((response) => {
                    this.analyticsRealTimeCurrentVisitors = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        fetchRealTimeData() {
            axios.get(route('app_int.analytics.real-time.stats-chart-countries', { program: this.getAnalyticsRealTimeProgram.id }), {
                params: {
                    timePeriod: this.timePeriod
                }
            })
                .then((response) => {
                    this.setAnalyticsRealTimeData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        fetchRealTimePagesData(url = route('app_int.analytics.real-time.pages', { program: this.getAnalyticsRealTimeProgram.id })) {
            axios.get(url, {
                params: {
                    timePeriod: this.timePeriod
                }
            })
                .then((response) => {
                    this.analyticsRealTimePagesData = (response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        fetchRealTimeVisitorsBySourceData(url = route('app_int.analytics.real-time.visitors-by-source', { program: this.getAnalyticsRealTimeProgram.id })) {
            axios.get(url, {
                params: {
                    timePeriod: this.timePeriod
                }
            })
                .then((response) => {
                    this.analyticsRealTimeVisitorsBySourceData = (response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        fetchRealTimeVisitorsByReferralData(url = route('app_int.analytics.real-time.visitors-by-referral', { program: this.getAnalyticsRealTimeProgram.id })) {
            axios.get(url, {
                params: {
                    timePeriod: this.timePeriod
                }
            })
                .then((response) => {
                    this.analyticsRealTimeVisitorsByReferralData = (response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    },

    getters: {
        getAnalyticsRealTimeStatsChartAndCountries: (state) => state.analyticsRealTimeStatsChartAndCountries,
        getAnalyticsRealTimeCurrentVisitors: (state) => state.analyticsRealTimeCurrentVisitors,
        getAnalyticsRealTimePagesData: (state) => state.analyticsRealTimePagesData,
        getAnalyticsRealTimeVisitorsBySourceData: (state) => state.analyticsRealTimeVisitorsBySourceData,
        getAnalyticsRealTimeVisitorsByReferralData: (state) => state.analyticsRealTimeVisitorsByReferralData,
        getAnalyticsRealTimeProgram: (state) => state.program,
    }
})
