<template>
    <div class="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
        <div class="sm:flex sm:items-start">
            <div class="text-sm" v-if="billingInformation.company_name">
                <span class="font-semibold">{{ billingInformation.company_name }}</span><br>
                {{ billingInformation.address }}<br>
                {{ billingInformation.city }}, {{ countryName }}
            </div>
            <div class="text-sm italic mt-2" v-else>No billing details. Click Edit to enter.</div>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
            <modal title="Billing details" size="large" name="account-billing-info">
                <template #toggle>
                    <btn type="light">Edit</btn>
                </template>
                <template #body>
                    <div class="grid md:grid-cols-2 col-span-2 gap-8 gap-y-6">
                        <text-field
                            label="Name"
                            field-id="name"
                            name="first_name"
                            type="text"
                            :error="errors.first_name ?? false"
                            v-model="billingInformation.first_name">
                        </text-field>
                        <text-field
                            label="Last name"
                            field-id="last_name"
                            name="last_name"
                            type="text"
                            :error="errors.last_name ?? false"
                            v-model="billingInformation.last_name">
                        </text-field>
                        <text-field
                            label="Company name"
                            field-id="company_name"
                            name="company_name"
                            type="text"
                            class="col-span-2"
                            :error="errors.company_name ?? false"
                            v-model="billingInformation.company_name">
                        </text-field>
                        <text-field
                            label="Street address"
                            field-id="address"
                            name="address"
                            type="text"
                            :error="errors.address ?? false"
                            v-model="billingInformation.address">
                        </text-field>
                        <text-field
                            label="City"
                            field-id="city"
                            name="city"
                            type="text"
                            :error="errors.city ?? false"
                            v-model="billingInformation.city">
                        </text-field>

                        <div>
                            <field-label title="Country"/>
                            <select name="country" v-model="billingInformation.country"
                                    class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
                                <option v-for="country in countriesOptions" :value="country.value">
                                    {{ country.name }}
                                </option>
                            </select>
                        </div>

                        <text-field
                            label="ZIP / Postal"
                            field-id="zip"
                            name="zip"
                            type="text"
                            :error="errors.zip ?? false"
                            v-model="billingInformation.zip">
                        </text-field>
                        <text-field
                            v-if="requireState"
                            label="State / Province"
                            field-id="state"
                            name="state"
                            type="text"
                            :error="errors.state ?? false"
                            v-model="billingInformation.state">
                        </text-field>
                    </div>

                </template>
                <template #footer>
                    <btn :loading="loading.update" @click="update">Save</btn>
                </template>
            </modal>
        </div>
    </div>
</template>
<script>

export default {
    name: "AccountBillingInformation",

    props: {
        formErrors: {},
        billing_information: {type: Object, required: false},
        update_billing_information_url: {type: String, required: true},

        countries: {
            type: Array,
            required: true,
        },
        countriesRequiringTaxId: {
            type: Array,
            required: true,
        },
        countriesRequiringRegId: {
            type: Array,
            required: true,
        },
        countriesRequiringState: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            errors: {},
            billingInformation: {
                country: this.billing_information.country_code ?? null,
                first_name: this.billing_information.first_name ?? null,
                last_name: this.billing_information.last_name ?? null,
                company_name: this.billing_information.company ?? null,
                address: this.billing_information.address ?? null,
                city: this.billing_information.city ?? null,
                zip: this.billing_information.zip_code ?? null,
                state: this.billing_information.state ?? null,
            },
            loading: {
                update: false,
            }
        }
    },
    methods: {
        update() {

            if (this.loading.update === true) {
                return;
            }

            this.errors = {};
            this.loading.update = true;

            axios.post(this.update_billing_information_url, this.billingInformation)
                .then((response) => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'account-billing-info')
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                })
                .finally(() => this.loading.update = false);
        }
    },
    computed: {
        countryName() {
            const code = this.billingInformation.country;
            const country = this.countries.find((i) => {
                return i.value === code;
            });

            if (country) {
                return country.name;
            }

            return '';
        },
        countriesOptions() {
            return this.countries.map((i) => ({
                name: i.name,
                value: i.value,
            }));
        },
        requireState() {
            return this.countriesRequiringState.includes(this.billingInformation.country)
        },
        requireTaxId() {
            return this.countriesRequiringTaxId.includes(this.billingInformation.country)
        },
        requireRegId() {
            return this.countriesRequiringRegId.includes(this.billingInformation.country)
        }
    },
}
</script>
<style scoped></style>
