<template>
    <div class="space-y-4">
        <div v-if="productCommissions.length === 0" class="text-sm text-gray-500 bg-gray-100 rounded py-4 px-4">
            Add a product-based commission model to reward partners for sales within specific products or categories.
        </div>
        <div class="flex items-center justify-between gap-4" v-for="(productCommission, index) in productCommissions">
            <div class="grid grid-cols-6 gap-4 flex items-center">

                <div class="flex relative rounded col-span-2">
                    <span v-if="props.typeIsPercent" class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">%</span>
                    <input
                        type="text"
                        placeholder="e.g. 10"
                        v-model="productCommission.commission_value"
                        class="block w-full rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-0 focus:border-gray-400"
                        :class="{'rounded-l': !typeIsPercent}"
                        @input="commissionUpdated"
                    />
                    <div class="flex items-center text-sm text-gray-500 ml-4">when</div>
                </div>

                <select-field v-model="productCommission.condition_element" @input="commissionUpdated">
                    <option value="">select</option>
                    <option v-for="conditionElement in props.productCommissionConditionElements" :value="conditionElement.key">
                        {{ conditionElement.label }}
                    </option>
                </select-field>

                <div class="col-span-3">
                    <vue3-tags-input
                        :tags="productCommission.condition_value"
                        placeholder="Enter product ID or category"
                        @on-tags-changed="(tags) => handleProductIdChange(index, tags)"
                        :add-tag-on-blur="true"
                    >
                    </vue3-tags-input>
                </div>
            </div>

            <div class="flex items-center gap-2">
                <btn
                    v-if="props.showProductImport && productCommission.condition_element === 'product_id'"
                    @click="handleProductImportOpen(index)"
                    icon-name="add-box"
                    type="secondary"
                >
                </btn>
                <btn
                    icon-name="trash"
                    type="secondary"
                    @click="removeProductCommission(index)"
                >
                </btn>
            </div>

        </div>

        <div v-if="productCommissions.length > 0" class="py-3 self-stretch">
            <hr class="border border-b-0">
            <span class="text-xs block bg-white text-black px-2 py-1 -mt-3 float-left ml-3 uppercase">and</span>
        </div>

        <div v-if="canAddProductCommission">
            <btn type="secondary" size="small" icon-name="add-circle" @click="addProductCommission">
                Add
            </btn>
        </div>

        <modal v-if="showProductImport" title="Product import" size="medium" name="products_import_modal" @modalClose="handleProductImportClose">
            <template #body>

                <alert type="warning" class="mb-8">
                    The product recognition for <b>Stripe</b> and <b>Shopify</b> integrations are currently in the beta testing phase.
                </alert>

                <div class="border rounded">
                    <div class="p-4">
                        <text-field
                            v-model="searchKey"
                            placeholder="Search"
                            size="large"
                            class="bg-gray-50">
                        </text-field>
                    </div>

                    <ul class="divide-y border-t overflow-y-scroll max-h-96">
                        <li v-if="props.loading" class="flex items-center justify-center gap-4 py-2 px-4 hover:bg-gray-50">
                            <svg
                                class="animate-spin -ml-1 w-4 h-4 mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </li>
                        <li v-if="!props.loading && getStripeProducts.length === 0 && getShopifyProducts.length === 0" class="flex items-center justify-center gap-4 py-2 px-4 hover:bg-gray-50">
                            <p class="text-sm text-gray-500 my-3">
                                No products available
                            </p>
                        </li>
                        <li v-for="stripeProduct in getStripeProducts" class="flex items-center justify-between gap-4 py-2 px-4 hover:bg-gray-50">
                            <div class="flex gap-4">
                                <div class="border rounded w-10 h-10">
                                    <img :src="stripeProduct.image"/>
                                </div>
                                <div>
                                    <div class="text-sm font-semibold">{{ stripeProduct.name }}</div>
                                    <badge type="neutral" :title="stripeProduct.id"></badge>
                                </div>
                            </div>
                            <div class="flex gap-4">
                                <badge type="stripeIndigo" title="Stripe"></badge>
                                <checkbox v-model="modalSelectedProducts" :value="stripeProduct.id"></checkbox>
                            </div>
                        </li>
                        <li v-for="shopifyProduct in getShopifyProducts" class="flex items-center justify-between gap-4 py-2 px-4 hover:bg-gray-50">
                            <div class="flex gap-4">
                                <div class="border rounded w-10 h-10">
                                    <img :src="shopifyProduct.image"/>
                                </div>
                                <div>
                                    <div class="text-sm font-semibold">{{ shopifyProduct.name }}</div>
                                    <badge type="neutral" :title="shopifyProduct.id"></badge>
                                </div>
                            </div>
                            <div class="flex gap-4">
                                <badge type="shopifyGreen" title="Shopify"></badge>
                                <checkbox v-model="modalSelectedProducts" :value="shopifyProduct.id"></checkbox>
                            </div>
                        </li>
                    </ul>
                </div>
            </template>
            <template #footer>
                <btn @click="handleProductImport" type="primary">Choose</btn>
            </template>
        </modal>

    </div>
</template>

<script setup>
import Vue3TagsInput from 'vue3-tags-input';
import {computed, inject, onMounted, ref} from "vue";
import Options from "../../../../../elements/options/Options";

const props = defineProps({
    typeIsPercent: {
        type: Boolean,
        required: true
    },
    productCommissions: {
        type: Array,
        default(rawProps) {
            return [];
        }
    },
    productCommissionConditionElements: {
        type: Object,
        default: () => {}
    },
    showProductImport: {
        type: Boolean,
        default: false
    },
    stripeProducts: {
        type: Object,
        default: () => []
    },
    shopifyProducts: {
        type: Object,
        default: () => []
    },
    loading: {
        type: Boolean,
        default: false
    }
});
const emitter = inject('emitter')
const emit = defineEmits(['update:product-commissions'])

// Products commissions
const productCommissions = ref(props.productCommissions);
const canAddProductCommission = computed(() => productCommissions.value.length < 5);

const handleProductIdChange = (index, tags) => {
    productCommissions.value[index].condition_value = tags;
    commissionUpdated()
}
const addProductCommission = () => {
    if (canAddProductCommission) {
        productCommissions.value.push({
            commission_value: '',
            condition_element: '',
            condition_value: []
        });
        commissionUpdated()
    }
};
const removeProductCommission = (index) => {
    productCommissions.value.splice(index, 1);
    commissionUpdated()
};
const commissionUpdated = () => {
    emit('update:product-commissions', productCommissions.value)
}

// Product import modal
const modalRowIndex = ref(null)
const modalSelectedProducts = ref([])

const handleProductImportOpen = (rowIndex) => {
    modalRowIndex.value = rowIndex
    modalSelectedProducts.value = productCommissions.value[rowIndex].condition_value
    emitter.emit('modal-on', 'products_import_modal')
}

const handleProductImport = () => {
    productCommissions.value[modalRowIndex.value].condition_value = modalSelectedProducts.value
    emitter.emit('modal-off', 'products_import_modal')
}

const handleProductImportClose = () => {
    modalRowIndex.value = null
    modalSelectedProducts.value = []
}

// Searching products
const searchKey = ref('');

// Computed properties to filter products based on search input
const getShopifyProducts = computed(() => {
    if (!searchKey.value.trim()) return props.shopifyProducts;

    const searchTerm = searchKey.value.trim().toLowerCase();
    return props.shopifyProducts.filter(product => product.name.toLowerCase().includes(searchTerm));
});

const getStripeProducts = computed(() => {
    if (!searchKey.value.trim()) return props.stripeProducts;

    const searchTerm = searchKey.value.trim().toLowerCase();
    return props.stripeProducts.filter(product => product.name.toLowerCase().includes(searchTerm));
});
</script>

<style>

.v3ti {
    border: 1px solid #cccccc;
    font-size: .875rem;
}
.v3ti .v3ti-tag {
    background: #ccc;
    font-size: 0.875rem;
    border: none;
    border-radius: 0.25rem;
}
.v3ti .v3ti-tag .v3ti-remove-tag {
    color: #000000;
    transition: color .3s;
}

.v3ti .v3ti-tag .v3ti-remove-tag:hover {
    color: #ffffff;
}
</style>
