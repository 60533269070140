<template>
    <modal :title="'Add reward'" size="small" name="add_reward">
        <template #toggle>
            <btn type="secondary">Add reward</btn>
        </template>
        <template #body>
            <div class="space-y-4">
                <div>
                    <label for="add_reward" class="block text-sm opacity-80 mb-1.5">
                        Reward amount
                    </label>
                    <text-field fieldId="add_reward" :model-value="reward_amount"
                                v-on:keydown.enter="addReward"></text-field>
                </div>
            </div>
        </template>
        <template #footer>
            <btn :loading="loading" @click="addReward">Add</btn>
        </template>
    </modal>
</template>
<script>
export default {
    name: 'AddReward',
    props: ['add_reward_url'],
    data() {
        return {
            error: false,
            loading: false,
            reward_amount: 0
        }
    },
    methods: {
        addReward() {

            this.error = false;
            this.loading = true;

            axios
                .post(this.add_reward_url, {reward_amount: this.reward_amount})
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'add_reward');
                    } else {
                        this.error = 'Failed to add reward';
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors.key || false;
                    this.error = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);

        }
    }
}
</script>
<style scoped>
</style>
