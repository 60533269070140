<template>
    <div>
        <header-bar :title="section_title" size="xsmall">
            <template #actions>
                <options>
                    <template #toggle>
                        <btn icon-right="true" icon-name="more">Create</btn>
                    </template>
                    <template #items>
                        <modal
                            v-if="program_links.length > 0"
                            title="Referral URL"
                            cancel_btn_title="Cancel"
                            size="small"
                            name="partner_add_custom_link"
                            @modalClose="modalOff"
                        >
                            <template #toggle>
                                <options-item title="Create a referral link"/>
                            </template>
                            <template #body>
                                <div class="space-y-4">
                                    <div>
                                        <select id="program_custom_link" name="program_custom_link"
                                                v-model="create_referral_link_program_link_index"
                                                @change="create_referral_link_programLinkChange"
                                                class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
                                            <option v-for="(link, index) in program_links" v-bind:value="index">
                                                {{ link.domain }}
                                            </option>
                                        </select>
                                    </div>
                                    <text-field
                                        label="Partner identification code"
                                        :model-value="create_referral_link_key"
                                        field-id="identification"
                                        @update:modelValue="create_referral_link_updateRefLink"
                                        v-on:keyup.enter="create_referral_link_addCustomLink"
                                        :error="errors.key || false"
                                        :error-message="errors.key || null"
                                    ></text-field>
                                    <div
                                        class="py-3 px-4 text-sm bg-gray-100 text-gray-900 text-opacity-60 rounded flex items-center space-x-1.5">
                                        <icon name="link"></icon>
                                        <span>{{ ref_link }}</span>
                                    </div>
                                </div>
                            </template>
                            <template #footer>
                                <btn v-on:click="create_referral_link_addCustomLink" :loading="loading">Create</btn>
                            </template>
                        </modal>


                        <modal
                            title="Direct URL tracking"
                            size="small"
                            name="direct_url_tracking"
                            @modalClose="modalOff"
                        >
                            <template #toggle>
                                <options-item title="Create a direct URL"/>
                            </template>
                            <template #body>
                                <div class="text-sm space-y-4">
                                    <p class="text-gray-500">Direct URL tracking lets you track referrals with personalized
                                        links, without needing a referral token in the URL.</p>

                                    <text-field label="Page URL" v-model="dt_url"
                                                placeholder="https://www.example.com/joe"
                                                :error="errors.dt_url || false"
                                                :error-message="errors.dt_url || null"
                                    ></text-field>
                                    <toggle v-model="dt_redirect" title="Redirect to URL">
                                        <template #description>
                                            If the page doesn’t exist, set up a redirect to a relevant page or the homepage.
                                        </template>
                                    </toggle>
                                    <text-field label="Redirect URL" v-model="dt_redirect_url"
                                                v-if="dt_redirect"
                                                placeholder="https://www.example.com/features"
                                                :error="errors.dt_redirect_url || false"
                                                :error-message="errors.dt_redirect_url || null"
                                    ></text-field>

                                    <div class="border-t pt-4">
                                        <div class="space-y-2">
                                            <div class="font-semibold pb-2">
                                                Instructions
                                            </div>
                                            <div class="flex gap-2">
                                                <span class="text-gray-500">1. In the <b>Page URL</b> field above, enter the URL you want to track.</span>
                                            </div>
                                            <div class="flex items-center gap-2">
                                                <span class="text-gray-500">2. Copy and paste the following script into the <badge type="neutral" title="<head>"></badge> section of your website or into the specific page where tracking is required.</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="relative">
                                        <div class="absolute right-5 top-3"
                                            onclick="copyToClipboard(document.getElementById('snippet-1').value)">
                                            <tooltip content="Copied" method="click">
                                                <btn type="secondary" size="pill">Copy</btn>
                                            </tooltip>
                                        </div>
                                        <textarea id="snippet-1"
                                                rows="2"
                                                v-model="direct_tracking_js_snippet"
                                                class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"
                                        >{{direct_tracking_js_snippet}}</textarea>
                                    </div>

                                </div>
                            </template>
                            <template #footer>
                                <btn @click="createDtLink" :loading="loading">Create</btn>
                            </template>
                        </modal>
                    </template>
                </options>
            </template>
        </header-bar>

        <div class="bg-white rounded border mb-10">
            <table class="min-w-full mt-1 divide-y divide-gray-200">
                <thead>
                <tr>
                    <th scope="col"
                        colspan="2"
                        class="pl-4 pr-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Referral link
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Clicks
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Signups
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Conversion
                    </th>
                    <th scope="col" class="px-4 py-4"></th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(custom_link, index) in this.custom_links">
                    <td class="p-4 w-5">
                        <icon v-if="custom_link.additional" class="w-5 h-5 opacity-60" name="corner-down-right-line"></icon>
                        <icon v-else class="w-5 h-5 opacity-60" name="link-globe"></icon>
                    </td>
                    <td class="py-4 text-sm text-gray-900 overflow-x-hidden w-auto">
                        <a :href="custom_link.url" target="_blank"
                        :title="custom_link.url"
                        class="font-normal text-opacity-100 text-teal-600 hover:underline whitespace-nowrap">
                            {{ custom_link.url.length > 55 ? custom_link.url.substring(0, 52) + '...' : custom_link.url }}
                        </a>
                    </td>
                    <td class="py-4 px-4 text-sm font-medium">
                        <span v-if="custom_link.additional && custom_link.clicks == 0">-</span>
                        <span v-else>{{ custom_link.clicks }}</span>
                    </td>
                    <td class="py-4 px-4 text-sm font-medium">
                        <span v-if="custom_link.additional && custom_link.signups == 0">-</span>
                        <span v-else>{{ custom_link.signups }}</span>
                    </td>
                    <td class="py-4 px-4 text-sm font-medium">
                        <span v-if="custom_link.additional && custom_link.click_to_signup_conversion == 0">-</span>
                        <span v-else>{{ custom_link.click_to_signup_conversion }}</span>
                    </td>
                    <td class="px-4 py-4">
                        <badge title="Direct" v-if="custom_link.direct_tracking"></badge>
                        <badge title="Default" v-else-if="custom_link.default"></badge>
                        <badge title="Additional" v-else-if="custom_link.additional"></badge>
                        <badge title="Custom" v-else></badge>
                    </td>
                    <td class="pr-4 py-4">
                        <options>
                            <template #toggle>
                                <btn type="secondary" icon-name="more" size="small" class="h-full"></btn>
                            </template>
                            <template #items>

                                <modal
                                    title="Link update"
                                    size="small"
                                    name="custom_link_update_modal"
                                    @modalClose="customLinkModalClose"
                                >
                                    <template #toggle>
                                        <options-item title="Manage"></options-item>
                                    </template>
                                    <template #body>
                                        <div class="space-y-4">

                                            <text-field
                                                label="Link key"
                                                v-model="custom_link.key"
                                                :error="errors.key || false"
                                                :error-message="errors.key || null"
                                            ></text-field>

                                            <div class="py-3 px-4 text-sm bg-gray-100 rounded truncate">
                                                <span class="opacity-60">{{ exampleUrl(index) }}</span>
                                            </div>

                                            <div class="space-y-4" v-if="custom_link.direct_tracking">
                                                <div>
                                                    <toggle v-model="custom_link.direct_tracking_redirect"
                                                            v-if="custom_link.direct_tracking"
                                                            title="Redirect to URL">
                                                        <template #description>
                                                            If the page doesn’t exist, set up a redirect to a relevant page or the homepage.
                                                        </template>
                                                    </toggle>
                                                </div>
                                            </div>

                                            <div class="space-y-4" v-if="custom_link.direct_tracking">
                                                <text-field
                                                    label="Redirect URL"
                                                    v-model="custom_link.direct_tracking_redirect_url"
                                                    v-if="custom_link.direct_tracking && custom_link.direct_tracking_redirect"
                                                    :error="errors.dt_redirect_url || false"
                                                    :error-message="errors.dt_redirect_url || null"
                                                >
                                                    placeholder="https://www.example.com/features">
                                                </text-field>
                                            </div>

                                        </div>
                                    </template>
                                    <template #footer>
                                        <btn @click="updateCustomRefLink(custom_link)" :loading="loading">
                                            Update
                                        </btn>
                                    </template>
                                </modal>

                                <div v-if="!custom_link.default">
                                    <options-divider></options-divider>

                                    <modal
                                        title="Are you sure?"
                                        size="small"
                                        name="link_delete_modal"
                                        @modalClose="modalOff"
                                    >
                                        <template #toggle>
                                            <options-item title="Delete" :disabled="custom_link.default"></options-item>
                                        </template>
                                        <template #body>
                                            <div class="space-y-4">
                                                <alert type="error" v-if="error">{{ error }}</alert>
                                                <div class="text-sm text-gray-500">
                                                    Are you sure you want to delete this link: <b>{{ exampleUrl(index) }}</b>? <br />
                                                    This process cannot be undone.
                                                </div>
                                            </div>
                                        </template>
                                        <template #footer>
                                            <btn @click="deleteCustomLink(custom_link.id)" :loading="loading"
                                                type="primary">Yes,
                                                delete
                                            </btn>
                                        </template>
                                    </modal>
                                </div>
                            </template>
                        </options>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>

import Alert from "../../../../elements/Alert";
export default {
    name: 'RefLinks',
    components: {Alert},
    props: [
        'title',
        'program',
        'partner',
        'ref_link_url',
        'ref_link_key',
        'program_links',
        'program_pub_id',
        'partner_custom_links',
        'create_custom_link_url',
        'update_custom_link_url',
        'delete_custom_link_url',
        'create_direct_link_url',
        'update_direct_link_url',
        'update_key_url',
        'direct_tracking_url',
        'direct_tracking_redirect',
        'direct_tracking_redirect_url',
    ],
    data() {
        return {
            errors: {},
            loading: false,

            create_referral_link_key: '',
            create_referral_link_program_link_index: 0,
            create_referral_link_create_allowed: false,
            create_referral_link_program_link: this.program_links[0],

            section_title: this.title ?? 'Partner links',
            custom_links: JSON.parse(this.partner_custom_links),

            key_backup: this.ref_link_key,
            custom_links_backup: JSON.parse(this.partner_custom_links),

            dt_url: this.direct_tracking_url || '',
            dt_redirect: !!this.direct_tracking_redirect,
            dt_redirect_url: this.direct_tracking_redirect_url || ''
        }
    },
    computed: {
        direct_tracking_js_snippet() {
            return ('\<script\>po(\'program\', \'' + this.program_pub_id + '\', \'settings\', \'direct_tracking\', true);\</script\>');
        },
        ref_link() {
            return this.create_referral_link_program_link.url + this.create_referral_link_key;
        }
    },
    methods: {
        createDtLink() {

            if (this.loading) {
                return;
            }

            axios
                .post(this.create_direct_link_url, {
                    url: this.dt_url,
                    redirect: this.dt_redirect,
                    redirect_url: this.dt_redirect_url,
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'direct_url_tracking');
                        window.location.reload();
                    }
                })
                .catch(error => {
                    this.setErrors(error);
                })
                .finally(() => this.loading = false);

            this.error = false;
            this.loading = true;
        },
        create_referral_link_programLinkChange(event) {
            this.create_referral_link_program_link = this.program_links[event.target.value];
        },

        create_referral_link_updateRefLink(value) {
            let identifier = value.trim().replaceAll(' ', '');

            this.create_referral_link_key = identifier;
            this.create_referral_link_create_allowed = identifier.length > 4;
        },
        create_referral_link_addCustomLink() {

            if (this.loading) {
                return;
            }

            axios
                .post(this.create_custom_link_url, {
                    key: this.create_referral_link_key,
                    domain_id: this.create_referral_link_program_link.id,
                    is_additional: this.create_referral_link_program_link.is_additional
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'partner_add_custom_link');
                        window.location.reload();
                    }
                })
                .catch(error => {
                    this.setErrors(error);
                })
                .finally(() => this.loading = false);

            this.error = false;
            this.loading = true;

        },
        updateCustomRefLink(customLink) {

            if (this.loading) {
                return;
            }

            this.error = false;
            this.loading = true;

            let data = {
                link_id: customLink.id,
                key: customLink.key,
            };

            if (customLink.direct_tracking) {
                data['direct_tracking'] = customLink.direct_tracking;
                data['direct_tracking_domain'] = customLink.direct_tracking_domain;
                data['direct_tracking_redirect'] = customLink.direct_tracking_redirect;
                data['direct_tracking_redirect_url'] = customLink.direct_tracking_redirect_url;
            }

            axios
                .post(this.update_custom_link_url, data)
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'custom_link_update_modal');
                        window.location.reload();
                    }

                    this.setErrors(response.data);
                })
                .catch(error => {
                    this.setErrors(error);
                })
                .finally(() => this.loading = false);
        },
        deleteCustomLink(customLinkId) {

            if (this.loading) {
                return;
            }

            this.error = false;
            this.loading = true;

            axios
                .post(this.delete_custom_link_url, {link_id: customLinkId})
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'partner_delete_custom_link');
                        window.location.reload();
                    }

                    this.setErrors(response.data);
                })
                .catch(error => {
                    this.setErrors(error);
                })
                .finally(() => this.loading = false);
        },
        customLinkModalClose() {

            this.modalOff();

            this.key = this.key_backup;
            this.custom_links = JSON.parse(JSON.stringify(this.custom_links_backup));
        },
        exampleUrl(index) {
            return this.custom_links_backup[index].url.replace(this.custom_links_backup[index].key, this.custom_links[index].key);
        },
        setErrors(response) {
            if (response.response.data.errors[0] && (response.response.data.status ?? null) === 0) {
                this.errors['key'] = response.response.data.errors[0];
                this.errors['dt_url'] = response.response.data.errors[0];
                return;
            }

            for (const property in (response.response.data.errors || {})) {

                let errorMessage = response.response.data.errors[property].constructor === Array ? response.response.data.errors[property][0] : response.response.data.errors[property];
                this.errors[property] = errorMessage;

                if (property === 'direct_tracking_redirect_url') {
                    this.errors['dt_redirect_url'] = errorMessage;
                }

                if (property === 'url') {
                    this.errors['dt_url'] = errorMessage;
                }

                if (property === 'redirect_url') {
                    this.errors['dt_redirect_url'] = errorMessage;
                }

                if (property === 'direct_tracking_redirect_url') {
                    this.errors['dt_redirect_url'] = errorMessage;
                }
            }
        },
        modalOff() {
            this.errors = {};
        }
    }
}
</script>
<style scoped>
</style>

