import { defineStore } from "pinia";
import Revolvapp from "../../../../revolvapp/revolvapp.usm.min";
import {
    updateTemplateBackgroundColors,
    updateTemplateBrandColors,
    updateTemplateFooter,
    updateTemplateHeader
} from "../../../../helpers/revolvapp-helper";

export let useRevolvappEmailStore = defineStore('revolvappEmail', {
    state: () => ({
        autosave_url: null,
        draft_save_url: null,
        autosave_status_update_url: null,
        update_email_subject_url: null,
        autosave: false,
        email_subject: '',
        revolvapp_default_template: null,
        default_revolvapp_style_settings: null,
        updating_subject: false,
        slug: null,
        notification: {
            showNotification: false,
            notificationType: '',
            notificationTitle: '',
            notificationMessage: '',
        }
    }),

    actions: {
        updateNotification(show, type, title, msg) {
            this.$patch({
                notification: {
                    showNotification: show,
                    notificationType: type,
                    notificationTitle: title,
                    notificationMessage: msg
                }
            })
        },
        updateAutosave(value) {
            this.autosave = value

            // Save template when turning on autosave
            if (value === true){
                this.manuallySaveTemplate()
            }

            axios
                .post(this.autosave_status_update_url, {
                    'slug': this.slug,
                    'autosave_status': value
                })
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.updateNotification(
                            true,
                            'success',
                            'Autosave updated',
                            'Autosave changed for current template'
                        );
                    }
                })
                .catch(error => {
                    this.updateNotification(
                        true,
                        'warning',
                        'Action failed',
                        'Autosave is not persisted for future updates of this template'
                    )
                });
        },
        manuallySaveTemplate() {
            const app = Revolvapp('#editor');
            this.saveTemplate(app, true)
        },
        saveTemplate (revolvapp, manualSave = false, draftSave = false) {
            axios
                .post( draftSave ? this.draft_save_url : this.autosave_url, {
                    'html': revolvapp.app.editor.getHtml(),
                    'template': revolvapp.app.editor.getTemplate(),
                    'slug': this.slug
                })
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        // We are showing success messages only for manual save
                        if (manualSave || draftSave) {
                            this.updateNotification(
                                true,
                                'success',
                                'Done',
                                manualSave && draftSave
                                    ? 'Template successfully saved as draft.'
                                    : 'Template successfully updated.'
                            )
                        }
                    }
                })
                .catch(error => {
                    this.updateNotification(
                        true,
                        'warning',
                        'Action failed',
                        'Saving template failed. Check console for details.'
                    )
                    console.log(error, error.response)
                });
        },
        updateSubject(updatedSubject) {
            this.updating_subject = true;
            axios
                .post(this.update_email_subject_url, {
                    slug: this.slug,
                    subject: updatedSubject,
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {
                        this.updateNotification(
                            true,
                            'success',
                            'Done',
                            'Subject successfully updated.'
                        )
                        this.email_subject = updatedSubject
                        this.emitter.emit('modal-off', 'email_subject_update')
                    }
                })
                .catch(error => {
                    this.updateNotification(
                        true,
                        'warning',
                        'Action failed',
                        'Saving subject failed. Check console for details.'
                    )
                    console.log(error, error.response)
                })
                .finally(() => this.updating_subject = false);
        },
        resetToDefaultTemplate() {
            const revolvapp = Revolvapp('#editor');

            const template = this.setColorsFromUserSettingsToDefaultTemplate(this.revolvapp_default_template)

            revolvapp.app.editor.setTemplate(template);

            this.emitter.emit('modal-off', 'reset_to_default_modal');
        },
        saveAsDraft() {
            const app = Revolvapp('#editor');
            this.saveTemplate(app, true, true)
        },
        setColorsFromUserSettingsToDefaultTemplate(template) {
            template = updateTemplateHeader(template, this.get_default_revolvapp_style_settings);
            template = updateTemplateBackgroundColors(template, this.get_default_revolvapp_style_settings, this.get_default_revolvapp_style_settings);
            template = updateTemplateBrandColors(template, this.get_default_revolvapp_style_settings);
            template = updateTemplateFooter(template, this.get_default_revolvapp_style_settings);

            return template
        }
    },

    getters: {
        get_autosave: (state) => state.autosave,
        get_email_subject: (state) => state.email_subject,
        get_updating_subject: (state) => state.updating_subject,
        get_notification: (state) => state.notification,
        get_default_revolvapp_style_settings: (state) => state.default_revolvapp_style_settings
    }
})
