<script setup>
import {inject, ref} from "vue";
  import Spinner from "../../../../../elements/Spinner.vue";
  import {usePayoutAllAutomationStore} from "../../../../../stores/app/programs/payouts/automation/PayoutAllAutomationStore";
  import WhiteCard from "../../../../../elements/WhiteCard";

  const props = defineProps({
    scheduledPayout: {
      type: Object,
      required: true,
    },
    programId: {
      type: Number,
      required: true,
    },
    gateway: {
      type: String,
      required: true,
    },
  })

  const payoutAutomationStore = usePayoutAllAutomationStore();

  const emitter = inject('emitter');

  const payoutRequests = ref([]);

  const loadingPayoutRequests = ref(false);

  const getCustomerLink = (customerId) => {
      return route('programs.customer.show', {program: props.programId, customer: customerId});
  }

  const getPayoutRequests = async () => {
    loadingPayoutRequests.value = true;
    const {data} = await axios.get(route('app_int.views.payouts.mass-payout-process-data-payout-list', {program: props.programId, gateway: props.gateway}), {
      params: {
        process_data_uuid: props.scheduledPayout.uuid,
      }
    });
    payoutRequests.value = data;
    loadingPayoutRequests.value = false;
  }

  const processingManually = ref(false);
  const processingManuallyMessage = ref(null);
  const processingManuallyErrorMessage = ref(null);
  const isProcessingManuallyDone = ref(false);

  const initProcessManually = () => {
      if (processingManually.value) {
          return;
      }

      processingManually.value = true;
      processingManuallyMessage.value = null;

      axios.post(route('app_int.payouts.mass-payout.gateway.api_process', {program: props.programId, gateway: props.gateway}), {
          uuid: props.scheduledPayout.uuid,
          manual_retry_of_failed_process: true
      }).then(function (response) {
          const status = parseInt(response.data.status ?? -1);
          if (status === 1) {
              if (response.data.result.status_text ?? false) {
                  processingManuallyMessage.value = 'Status: ' + response.data.result.status_text;
              }
              manuallyInitiatedProcessStatus();
          }
      }).finally();

      const manuallyInitiatedProcessStatus = () => {
          axios.post(route('app_int.payouts.mass-payout.gateway.api_process_status', {program: props.programId, gateway: props.gateway}), {
              uuid: props.scheduledPayout.uuid,
          }).then(function (response) {
              const status = parseInt(response.data.status ?? -1);
              if (status === 1) {
                  if (response.data.result.status_text ?? false) {
                      processingManuallyMessage.value = 'Status: ' + response.data.result.status_text;
                      if (response.data.result.paid_amount ?? false) {
                          processingManuallyMessage.value += '. Total paid: ' + response.data.result.paid_amount;
                      }
                  }
                  if (response.data.result.error ?? false) {
                      isProcessingManuallyDone.value = true;
                      processingManuallyMessage.value = null;
                      processingManuallyErrorMessage.value = response.data.result.error;
                  }

                  if (response.data.result.is_done ?? false) {
                      isProcessingManuallyDone.value = true;
                      processingManually.value = false;
                      processingManuallyErrorMessage.value = response.data.result?.error ?? null;

                      payoutAutomationStore.reloadScheduledPayoutsHistory();

                      setTimeout(() => {
                          emitter.emit('modal-off', 'scheduled_payout_modal');
                      }, 2000);

                  } else {
                      manuallyInitiatedProcessStatus();
                  }
              }
          });
      }
  }

</script>

<template>
  <div class="flex flex-column items-center justify-end">
    <modal :title="'Payout Automation details'" size="huge" name="scheduled_payout_modal">
      <template #toggle>
        <btn size="small" @click="getPayoutRequests">View details</btn>
      </template>
      <template #body>
          <div class="grid lg:grid-cols-3 gap-6">

              <white-card title="Total amount" :value="scheduledPayout.scheduled_amount"></white-card>
              <white-card title="Status" :value="scheduledPayout.status" :bigger-text-on-large-screen="scheduledPayout.status.length <= 7 "></white-card>
              <white-card title="Paid amount" :value="scheduledPayout.paid_amount"></white-card>

          </div>
        <div class="mt-6 space-y-6">
          <div class="space-y-4">

              <div
                v-if="scheduledPayout.error"
                class="px-6 py-4 bg-red-100 rounded-md border border-red-400 space-y-4">
              <div>
                <h3 class="text-red-400 text-lg font-semibold">
                  Error
                </h3>
                <h3 class="font-semibold">{{ scheduledPayout.error }}</h3>
              </div>
            </div>
          </div>

            <div v-if="loadingPayoutRequests"
              class="flex grid content-center justify-center lg:col-span-2 h-1/2 border rounded">
              <spinner/>
          </div>
          <div
              v-if="!loadingPayoutRequests && scheduledPayout && payoutRequests.length > 0"
              class="lg:col-span-2"
          >
          <div class="bg-white rounded overflow-hidden border divide-y">


                  <div v-for="payoutRequest in payoutRequests" :key="'nav-' + payoutRequest.id" class="relative py-4 px-6">
                      <div class="">
                          <div class="flex justify-between">
                              <h3 class="font-semibold">{{ payoutRequest.partner_full_name }}</h3>
                              <div>Total: {{ payoutRequest.amount }}</div>
                          </div>
                      </div>
                      <ul role="list" class="relative z-0 divide-y divide-gray-200 hidden">
                          <li class="bg-white">
                              <table class="min-w-full mt-1 divide-y divide-gray-200">
                                  <thead>
                                  <tr>
                                      <th scope="col"
                                          class="pl-8 pr-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                          Customer
                                      </th>
                                      <th scope="col"
                                          class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                          Action
                                      </th>
                                      <th scope="col"
                                          class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                          Revenue
                                      </th>
                                      <th scope="col"
                                          class="px-4 pl-3 pr-6 text-right text-xs font-normal text-gray-500 uppercase tracking-wider">
                                          Date
                                      </th>
                                  </tr>
                                  </thead>
                                  <tbody class="bg-white divide-y divide-gray-200">
                                  <tr
                                      v-for="(item, index) in payoutRequest.items"
                                      :key="'payout-item-' + index"
                                      class="hover:bg-gray-50 cursor-pointer group">
                                      <td class="pl-8 pr-4 py-3 whitespace-nowrap font-semibold text-sm text-gray-900">
                                          <a :href="getCustomerLink(item['customer_id'])"
                                             class="hover:text-teal-600">
                                              {{ item['customer'] || 'n/a'}}
                                          </a>
                                      </td>
                                      <td class="px-4 py-3 whitespace-nowrap font-medium text-sm text-gray-900">
                                                <span
                                                    class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                                                    {{ item['action'] || 'n/a'}}
                                                </span>
                                      </td>
                                      <td class="px-4 py-3 whitespace-nowrap text-gray-500 text-sm font-medium text-gray-900">
                                          {{ item.currency_amount || 'n/a'}}
                                      </td>
                                      <td class="pl-4 pr-6 py-3 whitespace-nowrap text-right text-sm text-gray-500">
                                          {{ item.date_formatted }}
                                      </td>
                                  </tr>
                                  <tr>
                                      <td
                                          colspan="4"
                                          class="p-0 text-sm font-medium text-center text-opacity-100 text-indigo-600 hover:bg-gray-50 hover:underline"
                                      >
                                          <a :href="payoutRequest.url"
                                             class="block px-4 py-3.5"
                                          >
                                              View full activity
                                          </a>
                                      </td>
                                  </tr>
                                  </tbody>
                              </table>
                          </li>
                      </ul>
                  </div>


          </div>

          <div class="grid space-y-2 justify-end py-6 text-right">
              <btn v-if="scheduledPayout.error"
                   v-on:click="initProcessManually"
                   :loading="processingManually"
                   type="primary"
                   :disabled="processingManually"
              >
                Init process again manually
              </btn>
              <span v-if="processingManuallyMessage" class="text-sm">{{ processingManuallyMessage }}</span>
              <span v-if="processingManuallyErrorMessage" class="text-sm max-w-xs text-red-500">{{ processingManuallyErrorMessage }}</span>
          </div>

          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

