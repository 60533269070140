<template>
    <div>
        <label :for="input_names.select" class="block mb-1.5 text-sm">{{ text.name }}</label>
        <div class="grid gap-1" :class="types_value_disabled ? 'grid-cols-1' : 'grid-cols-2'">
            <select
                :id="input_names.select"
                :name="input_names.select"
                v-model="type"
                @change="typeChange"
                class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
            >
                <option value="lifetime">Lifetime</option>
                <option value="days">Days</option>
                <option value="months">Months</option>
                <option value="years">Years</option>
            </select>
            <text-field
                v-if="!types_value_disabled"
                :disabled="types_value_disabled"
                :required="!types_value_disabled"
                :model-value="times_value"
                :name="input_names.input"
                @update:modelValue="valueChange"
            >
            </text-field>
        </div>
        <small class="text-gray-500 mt-1 block">{{ text.description }}</small>
    </div>
</template>

<script>
export default {
    name: 'DayBasedPeriod',
    emits: ['update:period_type', 'update:period_times_value'],
    props: {
        name: { type: String, default: 'Period' },
        description: { type: String, default: 'Choose the time period.' },
        select_name: { type: String, required: true },
        input_name: { type: String, required: true },
        period_type: { type: String, default: 'days' },
        period_times_value: { type: [String, Number], default: '' }
    },
    computed: {
        types_value_disabled() {
            return this.type === 'lifetime';
        }
    },
    data() {
        return {
            text: {
                name: this.name,
                description: this.description,
            },
            input_names: {
                select: this.select_name,
                input: this.input_name,
            },
            type: this.period_type,
            times_value: this.period_times_value,
        }
    },
    methods: {
        typeChange(event) {
            this.$emit('update:period_type', event.target.value);
        },
        valueChange(value) {
            this.$emit('update:period_times_value', value);
        },
    }
}
</script>
