<template>
    <div>
        <div class="px-10 divide-y divide-gray-200">

            <form-section title="Program details" description="Main details about your program.">

                <text-field
                    label="Title"
                    v-model="data.general.introduction"
                ></text-field>

                <text-editor
                    :text="data.general.description"
                    @input="(newValue) => { data.general.description = newValue }"
                />

                <radio-group-inline
                    label="Branding"
                    v-model="data.general.program_branding_type"
                    :options="[
                                { value: 'symbol', label: 'Symbol'},
                                { value: 'logo', label: 'Logo'}
                            ]">
                    <template #note>
                        Select which image to display alongside the program summary.
                    </template>
                </radio-group-inline>

                <div class="space-y-4">

                    <toggle
                        size="default"
                        v-model="data.general.display_signup_and_login_links"
                        title="Include links for sign-up and log-in"
                    >
                        <template #description>
                            Display links for sign-up and log-in under the program description.
                        </template>
                    </toggle>

                    <toggle
                        v-if="leadsEnabled"
                        size="default"
                        v-model="data.general.display_submit_lead_link"
                        title="Include a lead submission link"
                    >
                        <template #description>
                            Display a link for lead submission.
                        </template>
                    </toggle>

                    <toggle
                        v-if="programType === 'affiliate'"
                        size="default"
                        v-model="data.general.show_footer"
                        title="Include footer"
                    >
                        <template #description>
                            The footer is designed with your brand’s signature color and features links for sign-up and log-in.
                        </template>
                    </toggle>

                    <toggle
                        v-if="programType === 'affiliate'"
                        size="default"
                        v-model="data.general.display_commission"
                        @update:modelValue="updateCommissionTextInputDisplay"
                        title="Show commission"
                    >
                        <template #description>
                            Include the program commission structure under the program description.
                        </template>
                    </toggle>

                    <div v-if="programType === 'affiliate'">
                        <div v-if="showCommissionTextInput">
                            <text-field
                                label="Commission structure"
                                v-model="data.general.commission_text"
                                placeholder="E.g. 30% for a lifetime"
                            >
                                <template #note>
                                    Enter your commission structure or leave blank for automatic generation.
                                </template>
                            </text-field>
                        </div>
                    </div>

                </div>

            </form-section>

            <form-section title="Page content" description="Additional details about your program.">

                <div class="space-y-4">

                    <div class="flex justify-between items-center">
                        <div class="text-md font-medium tracking-normal">
                            Page content builder
                        </div>
                        <modal title="Page content builder" size="full" ref="modal">
                            <template #toggle>
                                <btn type="secondary" size="small" icon-name="fullscreen">Full screen</btn>
                            </template>
                            <template #body>
                                <base-resources-builder
                                    name="landingPageResources"
                                    :program-type="props.programType"
                                    :resources="data.resources"
                                    :file-upload-url="props.fileUploadUrl"
                                    :file-delete-url="props.fileDeleteUrl"
                                    zero-fields-title="Start building your page"
                                    zero-fields-message="Start building your custom landing page by adding resources."
                                ></base-resources-builder>
                            </template>
                        </modal>
                    </div>

                    <base-resources-builder
                        name="landingPageResources"
                        :program-type="props.programType"
                        :resources="data.resources"
                        :file-upload-url="props.fileUploadUrl"
                        :file-delete-url="props.fileDeleteUrl"
                        zero-fields-title="Start building your page"
                        zero-fields-message="Start building your custom landing page by adding resources."
                    ></base-resources-builder>

                </div>

            </form-section>

        </div>

        <div class="bg-gray-50 px-10 py-4 text-right rounded-b sticky bottom-0 z-40 col-span-3">
            <btn
                button-type="submit"
                type="primary"
                :disabled="updatingResources"
                @click.prevent="saveResources()"
            >
                <spinner
                    v-if="updatingResources"
                    :is-white="true"
                    class="mr-1.5"
                />
                {{ updatingResources ? 'Updating...' : 'Update' }}
            </btn>
        </div>

        <notification
            :show="notificationData.show"
            :title="notificationData.title"
            :type="notificationData.type"
            class="mb-20"
            @notificationClosed="notificationData.show = false"
        >
            <template #message>
                {{ notificationData.message }}
            </template>
        </notification>
    </div>
</template>

<script setup>
import {ref, defineProps, shallowRef} from 'vue';
import Spinner from "../../../../../elements/Spinner";
import TextEditor from "../../../../../elements/TextEditor";
import TextField from "../../../../../elements/forms/TextField";

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
    programType: null,
    programLeadsEnabled: false,
    updateUrl: {
        type: String,
        required: true,
    },
    fileUploadUrl: {
        type: String,
        required: true,
    },
    fileDeleteUrl: {
        type: String,
        required: true,
    },
});

props.data.general = props.data?.general ?? {
    introduction: '',
    description: '',
    commission_text: '',
    program_branding_type: 'symbol',
    display_signup_and_login_links: false,
    display_submit_lead_link: false,
    display_commission: true,
    show_footer: true,
};

props.data.resources = props.data?.resources ?? [];
const programType = ref(props.programType ?? null);
const leadsEnabled = ref(props.programLeadsEnabled === true || props.programLeadsEnabled === 1 || props.programLeadsEnabled === '1');

const updatingResources = ref(false);
const showCommissionTextInput = ref(props.data.general.display_commission);

const notificationData = shallowRef({
    show: false,
    type: '',
    title: '',
    message: '',
});

const updateCommissionTextInputDisplay = () => {
    showCommissionTextInput.value = props.data.general.display_commission;
}

const saveResources = () => {
    updatingResources.value = true;
    axios.post(props.updateUrl, {
        landingPageResources: JSON.stringify(props.data)
    })
        .then(response => {
            if (response.status === 200) {
                notificationData.value = {
                    show: true,
                    type: 'success',
                    title: 'Done',
                    message: 'Data saved successfully',
                };
            }
        })
        .catch(error => {
            notificationData.value = {
                show: true,
                type: 'warning',
                title: 'Error',
                message: 'Data update error, see console for error details',
            };
        })
        .finally(() => {
            updatingResources.value = false;
        });
}
</script>
