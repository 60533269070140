<template>

    <div class="space-y-6">

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the <badge type="neutral" title="PartneroJS snippet"></badge> on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>

        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Update your Oncehub event</h3>
                <div class="text-sm space-y-2">
                    <p>In this step, you need to <a href="https://help.oncehub.com/help/automatic-redirect" target="_blank" class="underline hover:no-underline">configure the automatic redirect</a> back to your website, to a confirmation page, after a successful booking.</p>
                    <p> Log in to your <b>Oncehub</b> account, and edit the event you want to track.</p>
                    <p> The location of the booking form and redirect section depends on whether or not your booking page has any event types associated with it.</p>
                    <ul class="list-disc ml-4 space-y-1">
                        <li>For booking pages <strong>associated</strong> with event types, go to <strong>booking pages&nbsp;</strong>in the bar on the left →<strong>&nbsp;</strong>select the relevant <strong>event type →<strong>&nbsp;</strong> booking form and redirect&nbsp;</strong>section.</li>
                        <li>For booking pages <strong>not associated</strong> with event types, go to <strong>booking pages&nbsp;</strong>in the bar on the left →<strong>&nbsp;</strong>select the relevant <strong>booking page →<strong>&nbsp;b</strong></strong><strong>ooking form and redirect&nbsp;</strong>section.</li>
                    </ul>
                    <p> Enter URL (confirmation page) where you want to redirect and track Customers once they make a schedule.</p>
                    <p> Select <b>Send booking confirmation data to redirect page</b> checkbox.</p>

                </div>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sign-ups tracking</h3>
                <div class="text-sm space-y-2">
                    <p>Paste the following script on the <b>confirmation</b> page you are using to redirect customers:</p>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-2').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-2" rows="2" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs" v-pre><script>
po('integration', 'oncehub', null);
</script></textarea>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sales tracking</h3>
                <div class="text-sm space-y-2">
                    <p>To complete the integration, you need to share details of successful sales with Partnero. You can do this in a few ways:</p>
                    <ul class="list-disc ml-4 space-y-0.5">
                        <li>Use <b>Stripe</b> or <b>Paddle Billing</b> integrations</li>
                        <li>Implementing tracking through the <a class="underline hover:no-underline" href="https://developers.partnero.com/guide/affiliate.html#sending-sales-data" target="_blank">Parterno API</a></li>
                        <li>Implementing tracking through the <a class="underline hover:no-underline" href="https://developers.partnero.com/guide/javascript-tracking.html#sales-tracking" target="_blank">Partnero JS</a></li>
                        <li>Use <b>Zapier</b> or <b>Make</b> to connect everything.</li>
                    </ul>
                </div>
            </div>
        </div>

    </div>

</template>
