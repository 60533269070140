<template>
    <settings-accordion
        label="Visibility settings"
        :placeholder="settingsSummary"
        :error="error"
        :opened="error ? true : false"
    >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <radio-group-default
                :name="SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE"
                v-model="selectedVisibilityType"
                :options="[
                    { value: SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_ALL_PAGES, label: 'All pages'},
                    { value: SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_SHOW_ON_SPECIFIC_PAGES, label: 'Show on specific pages'},
                    { value: SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_HIDE_ON_SPECIFIC_PAGES, label: 'Hide on specific pages'},
                ]"
            />

            <checkbox-group
                :name="SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS + '[]'"
                v-model="selectedVisibilityDeviceSettings"
                group-class="space-y-4"
                :options="[
                    { value: SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS_HIDE_DESKTOPS, label: 'Hide on desktops'},
                    { value: SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS_HIDE_TABLETS, label: 'Hide on tablets'},
                    { value: SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS_HIDE_MOBILE_DEVICES, label: 'Hide on mobile devices'},
                ]"
            />

            <div 
                class="col-span-2" 
                v-if="[
                    SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_SHOW_ON_SPECIFIC_PAGES, 
                    SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_HIDE_ON_SPECIFIC_PAGES
                ].includes(selectedVisibilityType)"
            >
                <textarea 
                    rows="3"
                    :name="SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_SPECIFIC_PAGES"
                    v-model="selectedVisibilityTypeSpecificPages"
                    placeholder="List the URL of each page on a separate line"
                    class="block w-full rounded border-gray-300 text-sm focus:ring-0 focus:border-gray-400"
                />
            </div>
        </div>
    </settings-accordion>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
    visibilityType: {
        required: true
    },
    visibilityDeviceSettings: {
        type: Array,
        default: () => []
    },
    visibilityTypeSpecificPages: {
        type: String,
        default: ''
    },
    error: {
        default: false,
    }
})

const emit = defineEmits([
    'update:visibilityType',
    'update:visibilityDeviceSettings',
    'update:visibilityTypeSpecificPages'
])

// Using computed with get/set makes it cleaner
const selectedVisibilityType = computed({
    get: () => props.visibilityType,
    set: (value) => emit('update:visibilityType', value)
})

const selectedVisibilityDeviceSettings = computed({
    get: () => props.visibilityDeviceSettings,
    set: (value) => emit('update:visibilityDeviceSettings', value)
})

const selectedVisibilityTypeSpecificPages = computed({
    get: () => props.visibilityTypeSpecificPages,
    set: (value) => emit('update:visibilityTypeSpecificPages', value)
})

// Constants
const SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE = 'portal_signup_popup_visibility_type'
const SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS = 'portal_signup_popup_visibility_device_settings'
const SETTING_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_SPECIFIC_PAGES = 'portal_signup_popup_visibility_type_specific_pages'

const SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_ALL_PAGES = 'all_pages'
const SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_SHOW_ON_SPECIFIC_PAGES = 'show_on_specific_pages'
const SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_HIDE_ON_SPECIFIC_PAGES = 'hide_on_specific_pages'

const SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS_HIDE_DESKTOPS = 'hide_desktops'
const SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS_HIDE_TABLETS = 'hide_tablets'
const SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS_HIDE_MOBILE_DEVICES = 'hide_mobile_devices'

const settingsSummary = computed(() => {
    const summary = []

    // Visibility Type with Pages
    const visibilityTypeBase = {
        [SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_ALL_PAGES]: 'Shows on <strong>all pages</strong>',
        [SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_SHOW_ON_SPECIFIC_PAGES]: 'Shows on <strong>specific pages</strong>',
        [SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_HIDE_ON_SPECIFIC_PAGES]: 'Hidden on <strong>specific pages</strong>'
    }[selectedVisibilityType.value]

    if (visibilityTypeBase) {
        let visibilityText = visibilityTypeBase
        
        // Add pages to visibility type if applicable
        if ([
            SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_SHOW_ON_SPECIFIC_PAGES,
            SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_TYPE_HIDE_ON_SPECIFIC_PAGES
        ].includes(selectedVisibilityType.value) && selectedVisibilityTypeSpecificPages.value) {
            const pages = selectedVisibilityTypeSpecificPages.value.split('\n').filter(line => line.trim())
            if (pages.length > 0) {
                const boldPages = pages.map(page => `<strong>${page}</strong>`)
                visibilityText += `<br>Pages: ${boldPages.join(', ')}`
            }
        }
        
        summary.push(visibilityText)
    }

    // Device Settings
    const deviceRestrictions = []
    if (selectedVisibilityDeviceSettings.value.includes(SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS_HIDE_DESKTOPS)) {
        deviceRestrictions.push('<strong>desktop</strong>')
    }
    if (selectedVisibilityDeviceSettings.value.includes(SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS_HIDE_TABLETS)) {
        deviceRestrictions.push('<strong>tablet</strong>')
    }
    if (selectedVisibilityDeviceSettings.value.includes(SETTING_VALUE_PORTAL_SIGNUP_POPUP_VISIBILITY_DEVICE_SETTINGS_HIDE_MOBILE_DEVICES)) {
        deviceRestrictions.push('<strong>mobile</strong>')
    }

    if (deviceRestrictions.length > 0) {
        summary.push(`Hidden on ${deviceRestrictions.join(', ')}`)
    }

    return summary.length > 0 ? summary.join('<br>') : 'Define where to show a pop-up'
})
</script> 