<template>
    <slot :childScope="childScope" :childMethods="{showEmailForm}"/>
</template>
<script>
export default {
    props: ['min', 'errors'],
    data() {
        return {
            childScope: {
                email: '',
                rules: {
                    emailLength: false,
                    validEmail: false,
                },
                emailFormVisible: false,
            },
            regex: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$'
        }
    },

    created() {
        this.childScope.emailFormVisible = this.errors; // if we have errors, make it visible
    },

    watch: {
        'childScope.email': function (current, old) {
            this.childScope.rules.passwordLength = current.length >= this.min
            this.childScope.rules.validEmail = new RegExp(this.regex).test(current)
        }
    },

    methods: {
        showEmailForm() {
            this.childScope.emailFormVisible = true;
        },
    }
}
</script>
