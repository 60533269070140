<template>
    <a
        class="whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
        :class="{
        'border-teal-500 text-teal-600 ': active,
        'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': !active,
        }"
    >
        <slot/>
    </a>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
