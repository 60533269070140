<script setup>
import {useAnalyticsRealTimeStore} from "../../../../../../stores/app/programs/analytics/AnalyitcsRealTimeStore";
import AnalyticsTablesPagination from "./AnalyticsTablesPagination.vue";
import {formatNumber} from "../../../../../../helpers/common";

const analyticsRealTimeStore = useAnalyticsRealTimeStore();
</script>

<template>
    <div class="grid lg:grid-cols-1 gap-4">
        <div class="bg-white rounded border">
            <table class="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Pages
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Entries
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Visitors
                    </th>
                    <th scope="col"
                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                        Referred visitors
                    </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                <tr v-if="!analyticsRealTimeStore?.getAnalyticsRealTimePagesData?.data?.length">
                    <td colspan="4" class="px-4 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
                        No data for selected time period
                    </td>
                </tr>
                <template v-else>
                    <tr v-for="(pageData, index) in analyticsRealTimeStore.getAnalyticsRealTimePagesData.data"
                        :key="'pages-' + index"
                        class="hover:bg-gray-50 cursor-pointer group"
                    >
                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{ pageData.page.replace(/^https?:\/\//i, '') }}
                        </td>
                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{ formatNumber(pageData.entries) }}
                        </td>
                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{ formatNumber(pageData.visitors) }}
                        </td>
                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{ formatNumber(pageData.referred_visitors) }}
                        </td>
                    </tr>
                    <tr v-if="analyticsRealTimeStore?.getAnalyticsRealTimePagesData?.last_page > 1"
                        class="group">
                        <td colspan="4" class="text-sm text-gray-900 text-center">
                            <AnalyticsTablesPagination
                                :paginationData="analyticsRealTimeStore.getAnalyticsRealTimePagesData"
                                data-to-fetch="pages"
                            />
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>
.custom-scrollbar::-webkit-scrollbar-track {
    margin-top: 2.56rem;
}
</style>
