<template>
    <div>
        <field-label v-if="label" :title="label" />
        <div class="inline-flex z-0 w-full rounded">
            <template v-for="(option, index) in options">
                <label
                    v-if="!option.custom || picked !== option.value"
                    class="flex-1 py-2.5 px-2 -ml-px border border-gray-300 cursor-pointer focus:z-20 focus:border-gray-400"
                    :class="{
                        'bg-teal-50 z-10 text-gray-900 border-gray-300': picked === option.value,
                        'text-gray-600 hover:text-gray-900': picked !== option.value && !option.disabled,
                        'text-gray-400 cursor-not-allowed': option.disabled,
                        'rounded-l': index === 0,
                        'rounded-r': index === options.length-1,
                    }"
                >
                    <input type="radio"
                        :name="name"
                        v-model="picked"
                        :value="option.value"
                        :disabled="option.disabled"
                        class="sr-only" />

                    <div class="flex justify-center items-center text-xs font-medium">
                        {{ option.label }}
                    </div>
                </label>

                <input
                    v-else
                    :type="option.custom.type"
                    :name="option.custom.name"
                    :placeholder="option.custom.placeholder"
                    :value="customValue"
                    :disabled="option.disabled"
                    class="z-10 flex-1 pr-2 -ml-px w-8 border-gray-300 transition-shadow text-xs font-medium focus:ring-0"
                    :class="{
                        'bg-teal-50 z-10 text-gray-900 border-gray-300 focus:border-gray-400': picked === option.value,
                        'text-gray-700': picked !== option.value,
                        'bg-gray-100 text-gray-400 cursor-not-allowed': option.disabled,
                        'rounded-l': index === 0,
                        'rounded-r': index === options.length-1,
                    }"
                />
            </template>
        </div>
        <small v-if="$slots.note" class="text-gray-500 mt-1 block">
            <slot name="note" />
        </small>
    </div>
</template>

<script>
import FieldLabel from "./FieldLabel";
export default {
    emits: ['update:modelValue'],
    components: {FieldLabel},
    props: ['name', 'modelValue', 'options', 'label'],
    data() {
        return {
            customValue: this.modelValue,
            picked: this.modelValue
        }
    },
    mounted() {
        this.$emit('update:modelValue', this.picked);
        const values = this.options.map(option => option.value).filter(value => value !== 'custom');
        if (!values.includes(this.picked)) {
            this.customValue = this.picked;
            this.picked = 'custom';
        }
    },
    watch: {
        picked() {
            this.$emit('update:modelValue', this.picked);
        },
        options: {
            handler(newOptions) {
                // If current picked value is disabled, find first enabled option
                const selectedOption = newOptions.find(option => option.value === this.picked);
                if (selectedOption?.disabled) {
                    const firstEnabledOption = newOptions.find(option => !option.disabled);
                    if (firstEnabledOption) {
                        this.picked = firstEnabledOption.value;
                    }
                }
            },
            deep: true
        }
    }
}
</script>
