import {defineStore} from "pinia";

export let usePayoutBankTransferAutomationStore = defineStore('payoutBankTransferAutomation', {
    state: () => ({
        programId: null,
        frequency: null,
        frequencyValue: null,
        frequencyValueDays: [],
        maximumAmount: null,
        minimumAmount: null,
        partneroTosAccepted: false,
        wiseFundsTosAccepted: false,
        reattemptUnsuccessfulPayouts: false,
        programAutomatedPayoutsEnabled: false,
        savingSettingsStatus: false,
        togglingAutomationStatus: false,
        showValidationMessages: false,
        notification: {
            showNotification: false,
            notificationType: '',
            notificationTitle: '',
            notificationMessage: '',
        }
    }),

    actions: {
        setProgramId(programId) {
            this.programId = programId
        },
        setFrequency(frequency) {
            this.frequency = frequency
        },
        setFrequencyValue(frequencyValue) {
            this.frequencyValue = frequencyValue
        },
        setFrequencyValueDays(frequencyValueDays) {
            this.frequencyValueDays = frequencyValueDays
        },
        setMaximumAmount(maximumAmount) {
            this.maximumAmount = maximumAmount
        },
        setMinimumAmount(minimumAmount) {
            this.minimumAmount = minimumAmount
        },
        setPartneroTosAccepted(partneroTosAccepted) {
            this.partneroTosAccepted = partneroTosAccepted
        },
        setWiseFundsTosAccepted(wiseFundsTosAccepted) {
            this.wiseFundsTosAccepted = wiseFundsTosAccepted
        },
        setReattemptUnsuccessfulPayouts(reattemptUnsuccessfulPayouts) {
            this.reattemptUnsuccessfulPayouts = reattemptUnsuccessfulPayouts
        },
        setProgramAutomatedPayoutsEnabled(programAutomatedPayoutsEnabled) {
            this.programAutomatedPayoutsEnabled = programAutomatedPayoutsEnabled
        },
        setShowingValidationMessages(showValidationMessages) {
            this.showValidationMessages = showValidationMessages
        },
        setSavingSettingsStatus(savingSettingsStatus) {
            this.savingSettingsStatus = savingSettingsStatus
        },
        updateFrequencyDays(day) {
            if (this.frequencyValueDays.includes(day)) {
                this.frequencyValueDays.splice(this.frequencyValueDays.indexOf(day), 1);
            } else {
                this.frequencyValueDays.push(day);
            }
        },
        resetFrequencyValues() {
            this.frequencyValue = null;
            this.frequencyValueDays = [];
        },
        toggleAutomation(){
            this.togglingAutomationStatus = true;
            axios.post(route('app_int.integrations.bank-transfer.mass-payout.toggle_automation', { program: this.programId }),
                { program_automated_payouts_wise_enabled: this.programAutomatedPayoutsEnabled }
            )
                .then((response) => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.updateNotification(
                            true,
                            'success',
                            'Automation status changed',
                            response.data.message
                        );
                    }
                    else {
                        this.updateNotification(
                            true,
                            'warning',
                            'Automation status error',
                            response.data.message
                        );
                    }
                    this.programAutomatedPayoutsEnabled = response.data.automation_status
                })
                .catch((error) => {
                    this.updateNotification(
                        true,
                        'warning',
                        'Action failed',
                        error.response.data.message
                    )
                    this.programAutomatedPayoutsEnabled = !this.programAutomatedPayoutsEnabled
                })
                .finally(() => {
                    this.togglingAutomationStatus = false;
                })
        },
        updateNotification(show, type, title, msg) {
            this.$patch({
                notification: {
                    showNotification: show,
                    notificationType: type,
                    notificationTitle: title,
                    notificationMessage: msg
                }
            })
        },
    },

    getters: {
        getMinimumAmount() {
            return this.minimumAmount
        },
        getMaximumAmount() {
            return this.maximumAmount
        },
        getFrequency() {
            return this.frequency
        },
        getFrequencyValueDays() {
            return this.frequencyValueDays
        },
        getFrequencyValue() {
            return this.frequencyValue
        },
        getPartneroTosAccepted() {
            return this.partneroTosAccepted
        },
        getWiseFundsTosAccepted() {
            return this.wiseFundsTosAccepted
        },
        getProgramAutomatedPayoutsEnabled() {
            return this.programAutomatedPayoutsEnabled
        },
        getReattemptUnsuccessfulPayouts() {
            return this.reattemptUnsuccessfulPayouts
        },
        getNotification() {
            return this.notification
        },
        getSavingSettings() {
            return this.savingSettingsStatus
        },
        getTogglingAutomationStatus() {
            return this.togglingAutomationStatus
        },
        getProgramId() {
            return this.programId
        },
        getFrontendValidationMessages() {
            let validationMessages = [];
            if (this.frequency === null) {
                validationMessages.push('Frequency is required.');
            }
            if (this.frequency !== 'days' && this.frequencyValue === null) {
                validationMessages.push('Frequency value is required.');
            }
            if (this.minimumAmount !== null && this.maximumAmount !== null && parseInt(this.minimumAmount) > parseInt(this.maximumAmount)) {
                validationMessages.push('Minimum amount cannot be greater than maximum amount.');
            }
            if (this.frequency === 'days' && this.frequencyValueDays.length === 0) {
                validationMessages.push('At least one day must be selected.');
            }
            return validationMessages;
        },
        automationToggleDisabled() {
            return this.togglingAutomationStatus
                || this.frequency === null
                || (this.frequencyValue === 'days' && this.frequencyValueDays.length === 0)
                || (this.frequency !== 'days' && this.frequencyValue === null)
        }
    }
})
