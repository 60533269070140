<template>

    <div class="space-y-6">

        <alert type="neutral">
            <p class="mb-1">This is the simplest method to begin tracking purchases made by referred visitors.</p>
            <p><b>Please note</b> that statistics will be displayed in Partnero after the first purchase is completed by the referred customer.</p>
        </alert>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Set up tracking of Stripe Payment Links</h3>
                <div class="text-sm space-y-2">
                    <p>Identify purchases made by referred buyers by accurately identifying and linking them via Stripe Payment Links.</p>
                </div>
            </div>

            <tabs>
                <tab @click="setActiveTab(TAB_1)" :class='{"border-b-4": activeTab === TAB_1}'>
                    Default
                </tab>
                <tab @click="setActiveTab(TAB_2)" :class='{"border-b-4": activeTab === TAB_2}'>
                    Pricing table
                </tab>
                <tab @click="setActiveTab(TAB_3)" :class='{"border-b-4": activeTab === TAB_3}'>
                    Buy button
                </tab>
            </tabs>

            <div class="space-y-4" v-show="activeTab === TAB_1">
                <div class="text-sm space-y-2">
                    <p>Choose this method if you added Stripe Payment Links directly to your website.</p>
                </div>
                <div class="relative">
                    <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-1').value)">
                        <tooltip content="Copied" method="click">
                            <btn type="secondary" size="pill">Copy</btn>
                        </tooltip>
                    </div>
                    <textarea id="snippet-1" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script>
(function() {
const updateLinkHref = link => {
    const cookieValue = (document.cookie.match(/(^| )partnero_partner=([^;]+)/) || [])[2];
    if (cookieValue) {
        try {
            const url = new URL(link.href);
            url.searchParams.set('client_reference_id', decodeURIComponent(cookieValue));
            link.href = url.href;
        } catch (error) {
            console.error('Error:', error);
        }
    }
};

document.querySelectorAll('a[href*="buy.stripe.com"]').forEach(link => {
    updateLinkHref(link);
    link.addEventListener('click', () => updateLinkHref(link));
});
})();
</script></textarea>
                    <small class="text-gray-500 mt-1 block">
                        Include the following script on every page containing payment links. It's important to place
                        this script at the bottom of the page, directly above the closing <span
                        class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">&lt;/body&gt;</span>
                        tag for optimal functionality.            </small>
                </div>
            </div>
            <div class="space-y-4" v-show="activeTab === TAB_2">
                <div class="text-sm space-y-2">
                    <p>Choose this method if you added Stripe Payment Links using <a class="underline hover:no-underline" href="https://docs.stripe.com/payments/checkout/pricing-table" target="_blank">Stripe Pricing table</a> to your website.</p>
                </div>
                <div class="relative">
                    <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-2').value)">
                        <tooltip content="Copied" method="click">
                            <btn type="secondary" size="pill">Copy</btn>
                        </tooltip>
                    </div>
                    <textarea id="snippet-2" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script>
(function() {
    document.addEventListener('DOMContentLoaded', function() {
        const stripePricingTables = document.getElementsByTagName('stripe-pricing-table');
        const cookieValue = getCookieValue('partnero_partner');
        if (cookieValue) {
            for (let i = 0; i &lt; stripePricingTables.length; i++) {
                stripePricingTables[i].setAttribute('client-reference-id', decodeURIComponent(cookieValue));
            }
        }
    });

    function getCookieValue(cookieName) {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i &lt; ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
})();
</script>
                    </textarea>
                    <small class="text-gray-500 mt-1 block">
                        Include the following script on every page containing payment links. It's important to place
                        this script at the bottom of the page, directly above the closing <span
                        class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">&lt;/body&gt;</span>
                        tag for optimal functionality.            </small>
                </div>
            </div>
            <div class="space-y-4" v-show="activeTab === TAB_3">
                <div class="text-sm space-y-2">
                    <p>Choose this method if you added Stripe Payment Links using <a class="underline hover:no-underline" href="https://docs.stripe.com/payment-links/buy-button" target="_blank">Stripe Buy button</a> to your website.</p>
                </div>
                <div class="relative">
                    <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-3').value)">
                        <tooltip content="Copied" method="click">
                            <btn type="secondary" size="pill">Copy</btn>
                        </tooltip>
                    </div>
                    <textarea id="snippet-3" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script>
(function() {
    document.addEventListener('DOMContentLoaded', function() {
        const stripeBuyButtons = document.getElementsByTagName('stripe-buy-button');
        const cookieValue = getCookieValue('partnero_partner');
        if (cookieValue) {
            for (let i = 0; i &lt; stripeBuyButtons.length; i++) {
                stripeBuyButtons[i].setAttribute('client-reference-id', decodeURIComponent(cookieValue));
            }
        }
    });

    function getCookieValue(cookieName) {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i &lt; ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
})();
</script>
                    </textarea>
                    <small class="text-gray-500 mt-1 block">
                        Include the following script on every page containing payment links. It's important to place
                        this script at the bottom of the page, directly above the closing <span
                        class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">&lt;/body&gt;</span>
                        tag for optimal functionality.            </small>
                </div>
            </div>

        </div>

    </div>
</template>
<script setup>

import {ref} from 'vue';

const TAB_1 = 'tab_1';
const TAB_2 = 'tab_2';
const TAB_3 = 'tab_3';

let activeTab = ref(TAB_1);

const setActiveTab = (tab) => {
    activeTab.value = tab;
};

</script>


