<template>
    <div class="rounded bg-white border mb-10">
        <div class="p-6">
            <div class="sm:flex sm:items-center sm:justify-between">
                <div class="sm:flex sm:space-x-5">
                    <div class="flex justify-center">
                        <div v-html="partner.avatar_html"></div>
                    </div>
                    <div class="mt-4 text-center sm:mt-0 sm:pt-3 sm:text-left">

                        <div class="sm:flex sm:space-x-3 sm:mb-0 mb-1 items-center">
                            <h3 class="font-semibold text-2xl">{{ partner.name + ' ' + partner.surname }}</h3>
                            <span v-if="partner.deleted"
                                  class="inline-block flex-shrink-0 rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800 capitalize">Archived</span>
                            <span v-else-if="partner.status !== 'suspended' && partner.status !== 'deleted'"
                                  class="inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium bg-teal-100 text-teal-800 capitalize">{{
                                    statusName
                                }}</span>
                            <span v-if="partner.status === 'deleted'"
                                  class="inline-block flex-shrink-0 rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800 capitalize">{{
                                    statusName
                                }}</span>
                            <tooltip :content="'Reason: ' + suspendReason" class="-mt-0.5 inline-block"
                                     v-if="partner.status === 'suspended'">
                                <span v-if="partner.status === 'suspended'"
                                      class="inline-block flex-shrink-0 rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800 capitalize">{{
                                        statusName
                                    }}</span>
                            </tooltip>
                        </div>
                        <p class="text-gray-900 text-base">{{ partnerEmail }}
                            <tooltip content="Impersonate" class="-mt-0.5 inline-block" v-if="program_is_refer_a_friend && portal_enabled">
                                <a target="_blank" :href="impersonate_url">
                                    <svg class="text-gray-900 w-5 h-5" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z"/>
                                        <path
                                            d="M10 11V8l5 4-5 4v-3H1v-2h9zm-7.542 4h2.124A8.003 8.003 0 0 0 20 12 8 8 0 0 0 4.582 9H2.458C3.732 4.943 7.522 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-4.478 0-8.268-2.943-9.542-7z"/>
                                    </svg>
                                </a>
                            </tooltip>
                        </p>
                    </div>
                </div>
                <div class="mt-5 flex justify-center sm:mt-0">
                    <options>
                        <template #toggle>
                            <btn type="secondary" icon-name="more" size="small" class="h-full"></btn>
                        </template>
                        <template #items>
                            <!-- Edit partner modal -->
                            <modal title="Edit profile" size="medium" name="partner_profile_modal">
                                <template #toggle>
                                    <options-item title="Edit"></options-item>
                                </template>

                                <template #body>

                                    <modal-tabs>
                                        <modal-tab @click="setActiveTab('profile')" :active="activeTab('profile')">
                                            Profile
                                        </modal-tab>
                                        <modal-tab @click="setActiveTab('metadata')" :active="activeTab('metadata')">
                                            Metadata
                                        </modal-tab>
                                    </modal-tabs>

                                    <div v-if="this.editError"
                                         class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm mb-6"
                                         role="alert">
                                        <ul>
                                            <li>{{ this.editError }}</li>
                                        </ul>
                                    </div>

                                    <div v-show="activeTab('profile')"
                                         class="grid gap-y-6 gap-x-8 xl:col-span-2 lg:grid-cols-2">
                                        <div class="flex items-end gap-x-4 col-span-2">
                                            <div class="w-full">
                                                <text-field
                                                    label="Customer ID"
                                                    type="text"
                                                    name="key"
                                                    class="w-full"
                                                    :disabled="disableKeyInput"
                                                    v-model="partner_key"
                                                ></text-field>
                                            </div>
                                            <btn @click="toggleKeyInput" class="w-1/3" type="secondary">
                                                {{ disableKeyInput ? 'Change' : 'Cancel' }}
                                            </btn>
                                        </div>
                                        <text-field
                                            label="Name"
                                            v-model="partner.name"
                                        />
                                        <text-field
                                            label="Last name"
                                            v-model="partner.surname"
                                        />
                                        <text-field
                                            label="Email address"
                                            v-model="partner.email"
                                            class="col-span-2"
                                        />

                                        <div class="flex items-end gap-x-4 col-span-2">
                                            <div class="w-full">
                                                <text-field
                                                    label="Password"
                                                    type="password"
                                                    name="password"
                                                    class="w-full"
                                                    v-model="partner_password"
                                                    :disabled="disablePasswordInput"
                                                    :show_reveal_password_icon=!disablePasswordInput
                                                ></text-field>
                                            </div>
                                            <btn @click="togglePasswordInput" class="w-1/3" type="secondary">
                                                {{ disablePasswordInput ? 'Change' : 'Cancel' }}
                                            </btn>
                                        </div>

                                    </div>

                                    <div v-show="activeTab('metadata')"
                                         class="grid gap-y-6 gap-x-8 lg:grid-cols-2">
                                        <div>
                                            <label class="block text-sm mb-1.5">Date of birth</label>
                                            <vs-datepicker
                                                label=""
                                                id="partner-metadata-date-of-birth"
                                                v-model="partner.metadata.date_of_birth"
                                                placeholder="YYYY-MM-DD"
                                                format="YYYY-MM-DD"
                                                value-type="format"
                                                :clearable="true"
                                                class="!w-full"
                                            ></vs-datepicker>
                                        </div>
                                        <div>
                                            <text-field
                                                label="Joining date"
                                                type="text"
                                                name="metadata.joining_date"
                                                class="w-full"
                                                :model-value="partner.metadata.joining_date"
                                                :disabled="true"
                                            ></text-field>
                                        </div>
                                    </div>
                                </template>

                                <template #footer>
                                    <btn type="primary" @click="editPartner" :loading="loading">Update</btn>
                                </template>
                            </modal>
                            <!-- ./ Edit partner modal -->

                            <options-divider></options-divider>

                            <modal title="Are you sure?" size="small" name="partner_suspend_modal"
                                   v-if="partner.status !== 'suspended'">
                                <template #toggle>
                                    <options-item title="Suspend"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to suspend this customer?
                                        </div>
                                        <div class="grid gap-x-6 gap-y-4">
                                            <div class="col-span-2">
                                                <label class="block text-sm mb-1.5">Suspend reason</label>
                                                <textarea v-model="suspendReason"
                                                          class="transition-shadow block w-full rounded focus:ring-0 py-2 px-3 text-sm border-gray-300 focus:border-gray-400"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="suspendPartner">Yes, suspend</btn>
                                </template>
                            </modal>

                            <modal title="Are you sure?" size="small" name="partner_restore_modal"
                                   v-if="partner.status !== 'active'">
                                <template #toggle>
                                    <options-item title="Restore"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to restore this customer?
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="restorePartner">Yes, restore</btn>
                                </template>
                            </modal>

                            <modal title="Are you sure?" size="small" name="partner_archive_modal"
                                   v-if="!partner.deleted">
                                <template #toggle>
                                    <options-item title="Archive"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to archive this partner?
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="archivePartner">Yes, archive</btn>
                                </template>
                            </modal>

                            <modal title="Are you sure?" size="small" name="partner_restore_archived_modal"
                                   v-if="partner.deleted">
                                <template #toggle>
                                    <options-item title="Restore archived"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to restore archived partner?
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="restoreArchivePartner">Yes, restore
                                        archived
                                    </btn>
                                </template>
                            </modal>

                            <modal title="Are you sure?" size="small" name="partner_delete_modal"
                                   v-if="partner.status !== 'deleted'">
                                <template #toggle>
                                    <options-item title="Delete"></options-item>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to delete this customer?<br/>
                                            This process cannot be undone.
                                        </div>
                                        <div class="grid gap-x-6 gap-y-4">
                                            <div class="col-span-2">
                                                <label class="block text-sm mb-1.5">Please type <span
                                                    class="font-semibold">DELETE</span>
                                                    to confirm.</label>
                                                <text-field v-model="deleteConfirmation"
                                                            :error="deleteConfirmationError"
                                                            v-on:keydown.enter.prevent="deletePartner"/>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" :loading="loading" @click="deletePartner">Yes, delete</btn>
                                </template>
                            </modal>

                        </template>
                    </options>
                </div>
            </div>
        </div>

        <div :class="{'sm:grid-cols-3': referring_partner_id, 'sm:grid-cols-2': !referring_partner_id}"
             class="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 sm:divide-y-0 sm:divide-x">
            <div class="px-6 py-5 grid justify-items-center">
                <div class="flex items-center text-sm text-gray-500">
                    <svg class="mr-1.5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18"
                         height="18">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"
                            fill="rgba(17,24,39,1)"/>
                    </svg>
                    <span>Joined on {{ partner.joined }}</span>
                </div>
            </div>
            <div class="px-6 py-5 grid justify-items-center">
                <div class="flex items-center text-sm text-gray-500">
                    <svg class="mr-1.5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18"
                         height="18">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm2.595 7.812a3.51 3.51 0 0 1 0-1.623l-.992-.573 1-1.732.992.573A3.496 3.496 0 0 1 17 14.645V13.5h2v1.145c.532.158 1.012.44 1.405.812l.992-.573 1 1.732-.992.573a3.51 3.51 0 0 1 0 1.622l.992.573-1 1.732-.992-.573a3.496 3.496 0 0 1-1.405.812V22.5h-2v-1.145a3.496 3.496 0 0 1-1.405-.812l-.992.573-1-1.732.992-.572zM18 19.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
                            fill="rgba(17,24,39,1)"/>
                    </svg>
                    <span>ID: {{ partner_key }} </span>
                </div>
            </div>
            <div class="px-6 py-5 grid justify-items-center" v-if="referring_partner_id">
                <div class="flex items-center text-sm text-gray-500">
                    <svg class="mr-1.5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18"
                         height="18">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M1 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H1zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM21.548.784A13.942 13.942 0 0 1 23 7c0 2.233-.523 4.344-1.452 6.216l-1.645-1.196A11.955 11.955 0 0 0 21 7c0-1.792-.393-3.493-1.097-5.02L21.548.784zm-3.302 2.4A9.97 9.97 0 0 1 19 7a9.97 9.97 0 0 1-.754 3.816l-1.677-1.22A7.99 7.99 0 0 0 17 7a7.99 7.99 0 0 0-.43-2.596l1.676-1.22z"
                            fill="rgba(17,24,39,1)"/>
                    </svg>
                    <span>Referred by: <a class="text-teal-600 font-semibold" :href="referring_partner_url"
                                          target="_blank">{{ referring_partner_name }}</a></span>
                </div>
            </div>
        </div>

        <notification
            :show="showNotification"
            :title="notificationTitle"
            :type="notificationType"
            class="mb-20"
            @notificationClosed="this.showNotification = false"
        >
            <template #message>
                {{ notificationMessage }}
            </template>
        </notification>
    </div>
</template>
<script>
import TextField from "../../../../../elements/forms/TextField";
import ModalTab from "../../../../../elements/tabs/ModalTab.vue";
import ModalTabs from "../../../../../elements/tabs/ModalTabs.vue";
import Tooltip from "../../../../../elements/Tooltip";
import VsDatepicker from '@vuesimple/vs-datepicker';

export default {
    name: 'ReferAFriendCustomerProfile',
    components: {ModalTab, ModalTabs, TextField, Tooltip, VsDatepicker},
    props: [
        'program_id', 'partner_id',
        'referring_partner_name', 'referring_partner_id',
        'suspend_reason', 'suspend_url', 'delete_url', 'restore_url', 'edit_url',
        'disable_2fa_url', 'partner_email', 'partner_name',
        'partner_surname', 'partner_joined', 'partner_avatar_html', 'partner_status',
        'partner_deleted', 'partner_identifier',
        'program_is_refer_a_friend', 'impersonate_url', 'portal_enabled', 'partner_metadata'
    ],
    data() {
        return {
            loading: false,
            showNotification: false,
            notificationType: '',
            notificationTitle: '',
            notificationMessage: '',

            disableKeyInput: true,
            disablePasswordInput: true,

            deleteConfirmation: '',
            disable2FAConfirmation: '',
            deleteConfirmationError: false,
            suspendReason: this.suspend_reason,

            editError: '',
            deleteError: '',
            suspendError: '',
            disable2FAError: '',

            partner: {
                email: this.partner_email ?? null,
                joined: this.partner_joined ?? null,
                status: this.partner_status ?? null,
                deleted: this.partner_deleted ?? false,
                name: this.partner_name ?? null,
                surname: this.partner_surname ?? null,
                avatar_html: this.partner_avatar_html ?? null,
                metadata: {
                    date_of_birth: this.partner_metadata['system.date_of_birth'] ?? null,
                    joining_date: this.partner_metadata['system.joining_date'] ?? null,
                }
            },
            /* Separate email holder to display in view and only update on success update */
            partnerEmail: this.partner_email,
            // Separate key and password as they are optional, for adding them later into the partner object
            partner_key: this.partner_identifier ?? null,
            partner_password: null,

            activeTabName: 'profile',
            referring_partner_url: this.referring_partner_id ? route('programs.customer.show', {
                program: this.program_id,
                customer: this.referring_partner_id
            }) : null
        }
    },
    methods: {
        editPartner() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.editError = false;

            if (!this.disableKeyInput) {
                this.partner.key = this.partner_key
            }
            if (!this.disablePasswordInput) {
                this.partner.password = this.partner_password
            }

            axios
                .post(this.edit_url, this.partner)
                .then(response => {
                    if (response.status === 200) {
                        this.emitter.emit('modal-off', 'partner_profile_modal');
                        this.showNotification = true
                        this.notificationTitle = 'Done'
                        this.notificationType = 'success'
                        this.notificationMessage = 'Customer profile updated successfully'
                        this.partnerEmail = this.partner.email
                    } else {
                        this.showNotification = true
                        this.notificationTitle = 'Error'
                        this.notificationType = 'warning'
                        this.notificationMessage = 'Customer profile update error, see console for error details.'
                        this.editError = response.data.errors[0] ?? 'Failed to update customer';
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;

                    if (error.response.data.message) {
                        this.editError = error.response.data.message;
                        return;
                    }

                    this.editError = Array.isArray(errors) ? errors[0] : errors
                }).finally(() => {
                this.disablePasswordInput = true
                this.disableKeyInput = true
                this.loading = false
            });
        },
        deletePartner() {
            if (this.loading) {
                return;
            }

            if (this.deleteConfirmation.toLowerCase() !== 'delete') {
                this.deleteConfirmationError = true;
                return;
            }

            this.loading = true;
            this.deleteError = false;
            this.deleteConfirmationError = false;

            axios
                .post(this.delete_url)
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {
                        window.location.href = route('programs.customers.index', {program: this.program_id});
                    } else {
                        this.deleteError = response.data.errors[0] ?? 'Failed to delete customer';
                    }

                    this.deleteConfirmation = '';
                    const partnerStatus = response.data.partner_status ?? null;
                    if (partnerStatus !== null) {
                        this.partner.status = partnerStatus;
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.deleteError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);
        },
        suspendPartner() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.suspendError = false;

            axios
                .post(this.suspend_url, {suspend_reason: this.suspendReason})
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {
                        this.partner.status = 'suspended';
                        this.emitter.emit('modal-off', 'partner_suspend_modal');
                    } else {
                        this.suspendError = response.data.errors[0] ?? 'Failed to suspend customer';
                    }

                    const partnerStatus = response.data.partner_status ?? null;
                    if (partnerStatus !== null) {
                        this.partner.status = partnerStatus;
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.suspendError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);

        },
        restorePartner() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.suspendError = false;

            axios
                .post(this.restore_url)
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {

                        this.partner.status = 'active';
                        this.partner.deleted = response.data.deleted_at ?? false;

                        this.emitter.emit('modal-off', 'partner_restore_modal');
                    } else {
                        this.suspendError = response.data.errors[0] ?? 'Failed to suspend customer';
                    }

                    const partnerStatus = response.data.partner_status ?? null;
                    if (partnerStatus !== null) {
                        this.partner.status = partnerStatus;
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.suspendError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);

        },
        archivePartner() {
            if (this.loading || this.partner.deleted) {
                return;
            }

            this.loading = true;
            this.archiveError = false;

            axios
                .post(route('app_int.partner.archive', {program: this.program_id, partner: this.partner_id}), {})
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {
                        window.location.reload();
                    } else {
                        this.archiveError = response.data.errors[0] ?? 'Failed to archive partner';
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.archiveError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);
        },
        restoreArchivePartner() {
            if (this.loading || !this.partner.deleted) {
                return;
            }

            this.loading = true;
            this.archiveError = false;

            axios
                .post(route('app_int.partner.restore_archived', {
                    program: this.program_id,
                    partner: this.partner_id
                }), {})
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);

                    if (status === 1) {
                        window.location.reload();
                    } else {
                        this.archiveError = response.data.errors[0] ?? 'Failed to archive partner';
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors || false;
                    this.archiveError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);
        },
        disablePartner2FA() {
            if (!this.loading) {
                return;
            }

            this.loading = true;
            this.disable2FAError = false;

            axios
                .post(this.disable_2fa_url)
                .then(response => {
                    const status = parseInt(response.data.status ?? 0);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'partner_2fa_disable_modal');
                    } else {
                        this.disable2FAError = response.data.errors[0] ?? 'Failed to disable 2FA';
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors ?? false;
                    this.disable2FAError = Array.isArray(errors) ? errors[0] : errors;
                }).finally(() => this.loading = false);
        },
        setActiveTab(name) {
            this.activeTabName = name
        },
        activeTab(name) {
            return name === this.activeTabName;
        },
        toggleKeyInput() {
            this.disableKeyInput = !this.disableKeyInput;
            if (this.disableKeyInput) {
                delete this.partner.key
            }
        },
        togglePasswordInput() {
            this.disablePasswordInput = !this.disablePasswordInput;
            if (this.disablePasswordInput) {
                delete this.partner.password
            }
        },
    },
    computed: {
        statusName() {
            if (this.partner.status === 'active') {
                return 'Active';
            }

            return this.partner.status;
        }
    }
}
</script>
<style scoped>
</style>
