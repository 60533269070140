<template>
    <div class="grid py-14 xl:grid-cols-3 gap-10">
        <div class="space-y-2">
            <h3 class="text-lg font-medium">
                Social media sharing
            </h3>
            <div class="text-sm text-gray-500">Create and edit sharing content for your partners.</div>
        </div>
        <div class="grid col-span-2 gap-y-8 gap-8">
            <div>
                <div class="space-y-2">
                    <social-share-accordion
                        v-for="(settings, social) in socialShareSettings"
                        :social="social"
                        :settings="settings"
                        @settingsChanged="updateSettings"
                    ></social-share-accordion>
                    <input type="text" name="social_share_settings" hidden v-model="jsonSocialShareSettings">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SocialShareAccordion from './SocialShareAccordion.vue';

export default {
    name: 'SocialShareSection',
    components: {
        SocialShareAccordion,
    },
    props: {
        socialShareResources: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            socialShareSettings: this.socialShareResources
        }
    },
    methods: {
        updateSettings (type, values) {
            this.socialShareSettings[type] = values;
        }
    },
    computed: {
        jsonSocialShareSettings () {
            return JSON.stringify(this.socialShareSettings);
        }
    }
}
</script>
