<template>
    <tr>
        <td :colspan="numberOfTableColumns">
            <table class="min-w-full">
                <tbody class="bg-white divide-y divide-gray-200 divide-opacity-75">
                <tr v-for="row in numberOfSkeletonRows" :key="row">
                    <td class="pl-8 pr-4 py-3">
                        <div class="h-3 bg-gray-200 w-6/12 rounded-lg mb-1.5"></div>
                        <div class="w-10/12 h-3 bg-gray-200 bg-opacity-70 rounded-lg"></div>
                    </td>
                    <td v-for="col in numberOfTableColumns - 1 "
                        :class="col === numberOfTableColumns - 1 ? 'pl-4 pr-8 py-3 text-right' : 'px-4 py-3'"
                    >
                        <div class="w-6/12 h-4 bg-gray-200 rounded-lg"></div>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
</template>

<script>
export default {
    name: "InitialLoadingSkeletonRows",
    props: {
        numberOfTableColumns: {
            type: [Number],
            required: true
        },
        numberOfSkeletonRows: {
            type: [Number],
            default: 4
        }
    }
}
</script>

