import {defineStore} from "pinia";

export let usePayoutAllAutomationStore = defineStore('payoutAllAutomation', {
    state: () => ({
        programId: null,
        scheduledPayoutsHistory: [],
        notification: {
            showNotification: false,
            notificationType: '',
            notificationTitle: '',
            notificationMessage: '',
        }
    }),

    actions: {
        setProgramId(programId) {
            this.programId = programId
        },
        setScheduledPayoutsHistory(scheduledPayoutsHistory) {
            this.scheduledPayoutsHistory = scheduledPayoutsHistory
        },
        reloadScheduledPayoutsHistory() {
            axios.get(route('app_int.payouts.mass-payout.gateway.scheduled_payouts', { program: this.programId}))
                .then((response) => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.setScheduledPayoutsHistory(response.data.scheduled_payout_history_list)
                    }
                })
                .catch((error) => {
                    this.updateNotification(
                        true,
                        'warning',
                        'Refreshing automated payouts list failed',
                        error.response.data.message
                    )
                })
        },
        updateNotification(show, type, title, msg) {
            this.$patch({
                notification: {
                    showNotification: show,
                    notificationType: type,
                    notificationTitle: title,
                    notificationMessage: msg
                }
            })
        },
    },

    getters: {
        getScheduledPayoutsHistory() {
            return this.scheduledPayoutsHistory
        },
        getProgramId() {
            return this.programId
        },
    }
})
