<template>
    <div class="rounded border bg-white">
        <div class="flex justify-between py-4 px-5">
            <div class="flex items-center space-x-4 truncate group">
                <icon :name="social" size="20"
                      class="text-gray-900">
                </icon>
                <span class="text-sm truncate">{{ upperCaseFirst(social) }}</span>
            </div>
            <div class="flex pl-6 space-x-4">
                <div>
                    <toggle size="medium"
                            name="social_facebook"
                            class="-mr-5"
                            :model-value="socialData.active"
                            @update:modelValue="socialData.active = $event"
                    ></toggle>
                </div>
            </div>
        </div>
        <div v-show="socialData.edit" class="py-6 px-6 bg-gray-50 bg-opacity-75 border-t space-y-4">
            <label class="block mb-1.5 text-sm">Default text</label>
            <textarea v-model="socialData.default_text" type="text" class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"></textarea>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SocialShareAccordion',
    props: {
        social: {
            type: String,
            required: true
        },
        settings: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            socialData: {}
        }
    },
    watch: {
        socialData: {
            handler () {
                this.$emit('settingsChanged', this.social, this.socialData);
            }
        }
    },
    created() {
        this.socialData = this.settings;
    },
    methods: {
        upperCaseFirst(string) {
            if(!string) {
                return string;
            }
            return string[0].toUpperCase() + string.slice(1);
        }
    }
}
</script>
