<template>
    <div>

        <div class="p-4 bg-gray-50 bg-opacity-75 border-t space-y-4">
            <div class="grid lg:grid-cols-2 gap-8 gap-y-6">
                <div class="col-span-2">
                    <label class="block mb-1.5 text-sm"> YouTube video URL </label>
                    <input
                        type="text"
                        v-model="data.files.video_url"
                        class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"/>
                </div>
                <div class="col-span-2">
                    <label class="block mb-1.5 text-sm"> Title </label>
                    <input
                        type="text"
                        v-model="data.title"
                        class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"/>
                </div>
                <div class="col-span-2">
                    <label class="block mb-1.5 text-sm"> Description </label>
                    <textarea
                        v-model="data.description"
                        type="text"
                        class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"></textarea>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Icon from "../../../../elements/Icon.vue";

export default {
    props: ['unique_id', 'data', 'options'],
    components: {
        Icon,
    },
    created() {
        this.data.files = this.data?.files ?? {};
        this.data.files.banner_image = this.data.files.banner_image ?? {};
        this.data.files.banner_content = this.data.files.banner_content ?? {};
    }
}
</script>

<style lang="scss"></style>
