<script setup>
import PartneroReferAFriendInvite from "./includes/Invite";
import PartneroReferAFriendRefLinks from "./includes/RefLinks";
import PartneroReferAFriendStats from "./includes/Stats";
import {usePartneroReferAFriendStore} from "../../../stores/app/refer-a-friend/PartneroReferAFriendStore";
import {onMounted} from "vue";

const props = defineProps({
    coupon_codes: {
        type: Array,
        default: []
    },
    used_coupon_codes: {
        type: Array,
        default: []
    },
});

const partneroReferAFriendStore = usePartneroReferAFriendStore()

function init() {
    partneroReferAFriendStore.fetchCustomerStats()
    partneroReferAFriendStore.fetchCustomerBalance()
}

onMounted(() => {
    init();
});
</script>

<template>
    <div class="space-y-4">
        <div class="flex items-center justify-between">
            <h3 class="text-lg font-semibold text-gray-900">Invite your friends</h3>
        </div>
        <div class="p-6 bg-white rounded border space-y-6">
            <PartneroReferAFriendInvite></PartneroReferAFriendInvite>
            <PartneroReferAFriendRefLinks></PartneroReferAFriendRefLinks>
        </div>
    </div>

    <div class="space-y-4" v-if="props.coupon_codes.length > 0 || props.used_coupon_codes.length > 0">
        <div class="flex items-center justify-between">
            <h3 class="text-lg font-semibold text-gray-900">Available rewards</h3>
        </div>
        <div class="p-6 bg-white rounded border space-y-6">
            <div v-if="props.coupon_codes.length > 0">
                <div class="text-sm mb-1.5">There is a reward available for you. You can use the coupon during checkout
                    when upgrading your Partnero plan.
                </div>
                <badge v-for="coupon in props.coupon_codes" type="success" :title="coupon.coupon_code"></badge>
            </div>
            <div :class="{'border-t pt-6': props.coupon_codes.length > 0}" v-if="props.used_coupon_codes.length > 0">
                <div class="text-sm mb-1.5">Redeemed coupons:</div>
                <badge v-for="used_coupon in props.used_coupon_codes" type="neutral"
                       :title="used_coupon.coupon_code"></badge>
            </div>
        </div>
    </div>

    <PartneroReferAFriendStats></PartneroReferAFriendStats>
</template>
