<template>
    <div>
        <div
            v-if="type === 'default'"
            class="sm:flex sm:items-center sm:justify-between md:mt-1"
            :class="[sizing[size].spacing]"
        >
            <div class="flex-1 min-w-0 py-3 sm:py-2">
                <span v-if="subtitle" class="text-sm text-gray-900 text-opacity-50  tracking-wide dark:text-gray-200">{{ subtitle }}</span>
                <h1
                    v-if="size === 'default'"
                    class="text-2xl font-semibold text-gray-900 sm:text-3xl sm:truncate dark:text-gray-200 tracking-tight"
                >
                    {{ title }}
                </h1>

                <h2
                    v-else-if="size === 'small'"
                    class="text-2xl font-semibold text-gray-900 md:text-3xl sm:truncate dark:text-gray-200"
                >
                    {{ title }}
                </h2>

                <h3
                    v-else-if="size === 'xsmall'"
                    class="text-lg font-semibold text-gray-900 md:text-lg sm:truncate dark:text-gray-200"
                >
                    {{ title }}
                </h3>

                <div v-if="description" class="text-sm text-gray-900 text-opacity-50 tracking-wide dark:text-gray-200 mt-2">{{ description }}</div>
                <slot name="under_description" />
            </div>
            <div class="flex-shrink-0 flex md:ml-4 space-x-3">
                <slot name="actions" />
            </div>
        </div>

        <nav v-if="type === 'full'" class="fixed top-0 w-full flex items-center lg:p-8 px-8 py-6 bg-white bg-opacity-80 z-40" style="-webkit-backdrop-filter: blur(20px);backdrop-filter: blur(20px);">
            <div class="w-1/3 flex">
                <slot name="actions" />
            </div>
            <div class="w-1/3 flex justify-center">
                <!--<img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlNpZGViYXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9Illjb2RlIj4KICAgICAgICAgICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMwLjAwMDAwMCwgMzAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTExLjQyNDE1MzMsMCBMMTEuNDI0MTUzMyw1Ljg1ODc3OTUxIEw2LjAyNCw4Ljk3OCBMMTIuNjE1NTczNSwxMi43ODY4MDA4IEwxMC45NTEsMTMuNzQ5IEwyMy4wNDY1NDAxLDYuNzUxMDEzNDkgTDIzLjA0NjU0MDEsMTIuNjE1MjcxNyBMMy4zOTUxNjA5NiwyMy45ODU2NjY2IEwzLjM3MDM3MjYsMjQgTDMuMzQzMTgxMjksMjMuOTgyNzE1NiBMMC45NiwyMi40NzEzMzY1IEwwLjk2LDE2Ljc2MTY1MDggTDMuMzY0MTc1NTEsMTguMTM5MzI0MiBMNy40NzYsMTUuNzYgTDAuOTYsMTEuOTA5MDA5OSBMMC45Niw2LjA1Mzc1NTE2IEwxMS40MjQxNTMzLDAgWiIgaWQ9Inktc3Ryb2tlIiBmaWxsPSIjMDAwMDAwIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=" width="28" class="">-->
            </div>
            <div class="w-1/3">

            </div>
        </nav>

    </div>
</template>

<script>
    export default {
        props: {
            title: '',
            subtitle: '',
            description: '',
            size: {
                default: 'default'
            },
            type: {
                default: 'default',
            },

        },
        data() {
            return {
                sizing: {
                    default: { spacing: 'mb-8' },
                    small: { spacing: 'mb-6' },
                    xsmall: { spacing: 'mb-4' },
                },
            }
        },
    }
</script>

<style scoped>

</style>
