<template>
    <table class="table-auto w-full divide-y divide-gray-200">
        <thead class="text-[13px] text-slate-500/70">
        <tr class="h-1">
            <th :colspan="TABLE_COLUMNS_COUNT">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <table-header-columns-and-sorting
                :tableHeaders="tableHeaders"
                header-text-component="div"
            />
        </tr>
        <tr class="h-1"/>
        </thead>
        <tbody class="text-sm font-medium divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!logs || logs?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && logs?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr v-for="(notification, index) in logs"
                :key="notification.id + '-' + notification.program_id + '-' + index"
            >
                <td class="px-5 py-3 first:pl-3 last:pr-3 last:bg-gradient-to-r last:from-transparent last:to-white last:to-[12px] last:pl-5 last:sticky last:right-0">
                    <div class="text-sm font-semibold">{{ notification.error_source.charAt(0).toUpperCase() + notification.error_source.slice(1) }}</div>
                    <div class="text-xs text-gray-500 uppercase" :title="notification.error_description && notification.error_description.length > 34 ? notification.error_description : ''">
                        {{ getDescription(notification.error_description) }}
                    </div>
                </td>
                <td class="px-5 py-3 first:pl-3 last:pr-3 last:bg-gradient-to-r last:from-transparent last:to-white last:to-[12px] last:pl-5 last:sticky last:right-0">
                    {{ notification.events_count }}
                </td>
                <td class="px-5 py-3 first:pl-3 last:pr-3 last:bg-gradient-to-r last:from-transparent last:to-white last:to-[12px] last:pl-5 last:sticky last:right-0">
                    <div class="text-slate-500">{{ notification.last_detected_at }}</div>
                </td>
                <td class="px-5 py-3 first:pl-3 last:pr-3 last:bg-gradient-to-r last:from-transparent last:to-white last:to-[12px] last:pl-5 last:sticky last:right-0">
                    <div class="text-slate-900"> {{ notification.details }}</div>
                </td>
                <td class="px-5 py-3 first:pl-3 last:pr-3 last:bg-gradient-to-r last:from-transparent last:to-white last:to-[12px] last:pl-5 last:sticky last:right-0 text-center">
                    <btn v-if="!notification.resolved_at" type="secondary" size="pill" @click="resolveInAppNotifications(notification.type)">Resolve</btn>
                    <badge v-else
                           type="success"
                           size="small"
                           title="Resolved"
                    ></badge>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
    <notification
        :show="notificationData.showNotification"
        :title="notificationData.notificationTitle"
        :type="notificationData.notificationType"
        class="mb-20"
        @notificationClosed="notificationData.showNotification = false"
    >
        <template #message>
            {{ notificationData.notificationMessage }}
        </template>
    </notification>
</template>

<script>
import Avatar from "../../../../common/Avatar";
import InitialLoadingSkeletonRows from "../../../../common/InitialLoadingSkeletonRows";
import ProgressBar from "../../../../../elements/ProgressBar";
import NoSearchDataRow from "../../../../common/NoSearchDataRow";
import useRecentActivities from "../../../../../composables/common/filter-and-pagination-data";
import TableHeaderColumnsAndSorting from "../../../filters-and-pagination/TableHeaderColumnsAndSorting.vue";
import useFiltersAndPagination from "../../../../../state/common/filters-and-pagination";
import Notification from "../../../../../elements/Notification.vue";
import {ref} from "vue";

export default {
    name: "LogsDataTable",
    components: {
        Notification,
        TableHeaderColumnsAndSorting,
        InitialLoadingSkeletonRows,
        NoSearchDataRow,
        ProgressBar,
        Avatar
    },
    props: ['logs', 'tableHeaders', 'program'],
    setup(props) {
        const TABLE_COLUMNS_COUNT = 5;
        const {loadingFiltersAndPaginationData} = useRecentActivities();
        const {filters, search, sorting, fetchDataFunction, fetchDataBaseUrl} = useFiltersAndPagination()

        const fetchData = () => {
            fetchDataFunction.value(fetchDataBaseUrl.value, {
                ...filters.value,
                search: search.value,
                sortingColumn: sorting.key,
                sortingDirection: sorting.direction
            })
        }

        const notificationData = ref({
            showNotification: false,
            notificationTitle: 'Error',
            notificationType: 'warning',
            notificationMessage: 'Resolving error, see console for error details.'
        });

        return {loadingFiltersAndPaginationData, TABLE_COLUMNS_COUNT, fetchData, notificationData}
    },
    methods: {
        getDescription(description) {
            if (!description) {
                return '-'
            }
            return description.length > 34 ? description.substring(0, 31) + '...' : description
        },
        resolveInAppNotifications(type) {+
            axios.patch(route('app_int.in-app-notifications.resolve', {program: this.program}), {type: type})
                .then((response) => {
                    const status = parseInt(response.data.status || '-1');
                    if (status === 1) {
                        this.notificationData.showNotification = true;
                        this.notificationData.notificationType = 'success';
                        this.notificationData.notificationTitle = 'Success';
                        this.notificationData.notificationMessage = response.data.message || 'Notification resolved.';
                        this.fetchData();
                    } else if (status === -1) {
                        this.notificationData.showNotification = true
                        this.notificationData.notificationTitle = 'Error'
                        this.notificationData.notificationType = 'warning'
                        this.notificationData.notificationMessage = response.data.message || 'Error occurred while resolving notification.'
                    }
                })
                .catch((error) => {
                    this.notificationData.showNotification = true
                    if (error.response) {
                        this.notificationData.notificationMessage = error.response.data.error || 'Error occurred while resolving notification.'
                    }
                    console.log(error, error.response)
                })
        }
    }
}
</script>
