<template>
    <div class="space-y-12">
        <div class="grid grid-cols-2 gap-8">
            <div class="border p-10 rounded">
                <div class="space-y-12 divide-y divide-gray-200">
                    <div class="space-y-12">
                        <div>
                            <h3 class="text-lg font-medium">Billing details</h3>
                            <p class="text-gray-500 text-sm">Information will be used to form invoices.</p>
                        </div>
                        <div class="grid md:grid-cols-2 col-span-2 gap-8 gap-y-6">
                            <text-field
                                label="Name"
                                field-id="name"
                                name="first_name"
                                type="text"
                                :error="billingInfoErrors.first_name ?? false"
                                v-model="billingInformation.first_name">
                            </text-field>
                            <text-field
                                label="Last name"
                                field-id="last_name"
                                name="last_name"
                                type="text"
                                :error="billingInfoErrors.last_name ?? false"
                                v-model="billingInformation.last_name">
                            </text-field>
                            <text-field
                                label="Company name"
                                field-id="company_name"
                                name="company_name"
                                type="text"
                                class="col-span-2"
                                :error="billingInfoErrors.company_name ?? false"
                                v-model="billingInformation.company_name">
                            </text-field>
                            <text-field
                                label="Street address"
                                field-id="address"
                                name="address"
                                type="text"
                                :error="billingInfoErrors.address ?? false"
                                v-model="billingInformation.address">
                            </text-field>
                            <text-field
                                label="City"
                                field-id="city"
                                name="city"
                                type="text"
                                :error="billingInfoErrors.city ?? false"
                                v-model="billingInformation.city">
                            </text-field>

                            <div>
                                <field-label title="Country"/>
                                <select name="country" v-model="billingInformation.country" @change="billingInfoChanged"
                                        :class="{'border-red-400': (billingInfoErrors.country ?? false), 'border-gray-300': !(billingInfoErrors.country ?? false)}"
                                        class="block w-full rounded sm:text-sm focus:ring-0 focus:border-gray-400">
                                    <option v-for="country in countriesOptions" :value="country.value">
                                        {{ country.name }}
                                    </option>
                                </select>
                            </div>

                            <text-field
                                label="ZIP / Postal"
                                field-id="zip"
                                name="zip"
                                type="text"
                                :error="billingInfoErrors.zip ?? false"
                                v-model="billingInformation.zip">
                            </text-field>
                            <text-field
                                v-if="requireState"
                                label="State / Province"
                                field-id="state"
                                name="state"
                                type="text"
                                :error="billingInfoErrors.state ?? false"
                                v-model="billingInformation.state">
                            </text-field>

                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-gray-50 p-10 rounded">
                <div class="space-y-12">
                    <div>
                        <h3 class="text-lg font-medium">Summary</h3>
                        <p class="text-gray-500 text-sm">Summary of your Partnero plan.</p>
                    </div>
                    <div>
                        <div
                            class="flex justify-between pb-6 border-b border-gray-200 dark:border-gray-600 border-opacity-75">
                            <div class="flex flex-col space-y-2">
                                <span class="font-medium dark:text-white">{{ plan.name }}</span>
                                <span
                                    class="tracking-wide text-black dark:text-white opacity-50 text-xs flex flex-col space-y-1">
                                    <div class="flex flex-row items-center space-x-1">
                                        <span class="font-medium">Plan description</span>
                                    </div>
                                </span>
                            </div>
                            <span class="font-medium dark:text-white">
                                <span>
                                    {{ invoicePreview.subtotal.amount_formatted }} {{
                                        invoicePreview.currency
                                    }}/{{ plan.per_period }}
                                </span>
                            </span>
                        </div>

                        <div v-for="discount in invoicePreview.discounts" class="flex justify-between pt-6">
                            <span class="font-medium dark:text-white">{{ discount.name }}</span>
                            <span class="dark:text-white">
                                {{ discount.amount_formatted_with_currency }}
                            </span>
                        </div>

                        <div class="flex justify-between pt-6">
                            <span class="font-medium dark:text-white">Total</span>
                            <span class="dark:text-white">
                                {{ invoicePreview.total.amount_formatted_with_currency }}
                            </span>
                        </div>

                    </div>

                    <div>
                        <div class="text-gray-500 text-xs">
                            <p class="mb-2">By clicking 'Purchase', I acknowledge that I'm
                                authorizing Partnero to automatically charge my default payment
                                method today and then every 30 days (recurring payment) unless I
                                cancel. I may cancel anytime by going to the Plan and billing
                                page within my Billing settings.</p>
                            <p>I also acknowledge that I read Partnero Terms of Use.</p>
                        </div>
                    </div>

                    <div>
                        <alert type="warning">Please note that your subscription plan will be billed through your Shopify store.                        </alert>
                    </div>

                    <div>
                        <btn
                            :disabled="!allowPurchase"
                            :loading="loading.purchase"
                            @click="purchase"
                            type="primary" size="huge" icon-name="arrow-right" icon-right="true" class="w-full">
                            Purchase with Shopify
                        </btn>
                        <alert type="error" class="mt-6" v-if="purchaseError">{{ purchaseError }}</alert>
                        <alert type="error" class="mt-6" v-if="!user_email_verified">Please verify your email address before purchasing a plan.</alert>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ShopifyCheckout",
    props: {
        plan: {type: Object, required: false},
        plan_key: {type: String, required: true},
        user_email_verified: {type: Boolean, required: true},
        billing_information: {type: Object, required: false},

        countries: {type: Array, required: true,},
        countriesRequiringTaxId: {type: Array, required: true,},
        countriesRequiringRegId: {type: Array, required: true,},
        countriesRequiringState: {type: Array, required: true,},
    },
    data() {
        return {
            purchaseError: null,
            billingInfoErrors: {},
            invoicePreviewLoaded: true,
            billingInformation: {
                country: this.billing_information.country_code ?? null,
                first_name: this.billing_information.first_name ?? null,
                last_name: this.billing_information.last_name ?? null,
                company_name: this.billing_information.company ?? null,
                address: this.billing_information.address ?? null,
                city: this.billing_information.city ?? null,
                zip: this.billing_information.zip_code ?? null,
                state: this.billing_information.state ?? null,
            },
            invoicePreview: this.getIniInvoicePreview(),
            loading: {
                purchase: false
            }
        };
    },
    watch: {
        'billingInformation.first_name': function () {
            this.billingInfoChange()
        },
        'billingInformation.last_name': function () {
            this.billingInfoChange()
        },
        'billingInformation.zip': function () {
            this.billingInfoChange()
        },
        'billingInformation.country': function () {
            this.billingInfoChange()
        },
        'billingInformation.company_name': function () {
            this.billingInfoChange()
        },
        'billingInformation.state': function () {
            this.billingInfoChange()
        },
        'billingInformation.address': function () {
            this.billingInfoChange()
        },
        'billingInformation.city': function () {
            this.billingInfoChange()
        },
    },
    methods: {
        billingInfoChanged() {
            this.billingInfoChange();
        },
        paymentMethodsChangeEvent(paymentMethods) {
            this.paymentMethods = paymentMethods;
        },
        purchase() {
            if (this.loading.purchase === true || !this.allowPurchase) {
                return;
            }

            this.purchaseError = null;
            this.loading.purchase = true;

            axios.post(route('app_int.billing.shopify.subscribe'), {
                'plan_key': this.plan_key,
                'billing_info': this.billingInformation
            })
                .then((response) => {
                    this.billingInfoErrors = {};
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        window.location = response.data.redirect_url;
                    } else if (status === 0 && response.data.error) {
                        this.purchaseError = response.data.error;
                    }
                })
                .catch((error) => {
                    this.setBillingInfoErrors(error);
                })
                .finally(() => this.loading.purchase = false);
        },
        billingInfoChange() {
            if (this.billingInformation.country && this.billingInformation.zip) {
                this.loadInvoicePreview();
            }
        },
        setBillingInfoErrors(error) {
            this.billingInfoErrors = {};
            const errors = error.response.data.errors ?? {};
            for (const name in errors) {
                if (name.startsWith('billing_info.')) {
                    let field = name.replace('billing_info.', '');
                    this.billingInfoErrors[field] = true;
                }
            }
        },
        getIniInvoicePreview() {
            return {
                currency: this.plan.currency,
                total: {
                    amount: '--',
                    amount_formatted: this.plan.price.amount_formatted,
                    amount_formatted_with_currency: this.plan.price.amount_formatted_with_currency,
                },
                subtotal: {
                    amount: '--',
                    amount_formatted: this.plan.price.amount_formatted,
                    amount_formatted_with_currency: this.plan.price.amount_formatted_with_currency,
                },
                discounts: [],
                plan: {
                    name: '',
                    period: 'month',
                    period_interval: 'monthly'
                },
            };
        }
    },
    computed: {
        allowPurchase() {
            return this.invoicePreviewLoaded;
        },
        countryName() {
            const code = this.billingInformation.country;
            const country = this.countries.find((i) => {
                return i.value === code;
            });

            if (country) {
                return country.name;
            }

            return '';
        },
        countriesOptions() {
            return this.countries.map((i) => ({
                name: i.name,
                value: i.value,
            }));
        },
        requireState() {
            return this.countriesRequiringState.includes(this.billingInformation.country)
        },
        requireTaxId() {
            return this.countriesRequiringTaxId.includes(this.billingInformation.country)
        },
        requireRegId() {
            return this.countriesRequiringRegId.includes(this.billingInformation.country)
        }
    }
}
</script>
<style scoped></style>
