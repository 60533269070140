<template>

    <div class="space-y-6">

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>

            <div class="space-y-2">
                <div class="text-sm space-y-2">
                    <p>Follow these steps to insert Partnero Universal into your Squarespace site:</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Navigate to <b>Website</b></li>
                        <li>Proceed to <b>Website Tools</b></li>
                        <li>Choose <b>Code Injection</b></li>
                        <li>Insert the Partnero Universal script into the <b>HEADER</b> section</li>
                    </ol>
                </div>
            </div>

        </div>

        <div class="space-y-4 border-t pt-6">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sales tracking on checkout</h3>
                <div class="text-sm space-y-2">
                    <p>Scroll down until you reach the <b>ORDER STATUS PAGE</b> section.</p>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-1').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-1" rows="4" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script>
po('transactions', 'create', {
    data: {
      key: '{orderId}',
      amount: {orderSubtotal},
      customer: {
        key: '{customerEmailAddress}',
        email: '{customerEmailAddress}'
      }
    },
    options: {
      create_customer: true
    }
  });
</script></textarea>
                <small class="text-gray-500 mt-1 block">Paste this script into the area.</small>
            </div>
        </div>

        <alert type="warning"><b>Please note:</b> When using this integration method, transaction creation via JavaScript (JS) calls must be enabled to ensure successful tracking.</alert>

    </div>

</template>
