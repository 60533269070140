<template>
  <modal :title="isEdit ? 'Update webhook' : 'Create an incoming webhook'" ref="webhook_catcher_modal" size="small"
         @modalClose="modalClose" @modalShow="modalShow">

    <template #toggle v-if="isEdit">
      <btn type="secondary" size="small" class="-mr-px rounded-r-none focus:z-10">
        Manage
      </btn>
    </template>
    <template #toggle v-else>
      <btn type="primary">
        Create an incoming webhook
      </btn>
    </template>

    <template #body>
      <div class="space-y-4">

          <alert type="error" v-if="errors.length > 0">
              <ul>
                  <li v-for="error in errors">{{ error }}</li>
              </ul>
          </alert>

          <div class="space-y-4">

              <div>
                  <div class="text-sm mb-1.5">Test URL</div>
                  <div class="relative">
                      <div class="absolute right-3 top-1.5" onclick="copyToClipboard(document.getElementById('webhook-url-2').value)">
                          <tooltip content="Copied" method="click">
                              <btn type="secondary" size="pill">Copy</btn>
                          </tooltip>
                      </div>
                      <textarea id="webhook-url-2" rows="1" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs">{{ catcher.test_url }}</textarea>
                      <small class="block mt-2 leading-5 text-gray-500">Send a test data payload to the provided URL. Once we receive the payload, you’ll be able to map the data fields to your desired action. No data will be ceated on your your account.</small>
                  </div>
              </div>

              <div>
                  <div class="text-sm mb-1.5">URL</div>
                  <div class="relative">
                      <div class="absolute right-3 top-1.5" onclick="copyToClipboard(document.getElementById('webhook-url-1').value)">
                          <tooltip content="Copied" method="click">
                              <btn type="secondary" size="pill">Copy</btn>
                          </tooltip>
                      </div>
                      <textarea id="webhook-url-1" rows="1" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs">{{ catcher.url }}</textarea>
                      <small class="block mt-2 leading-5 text-gray-500">This is your production URL. Once setup is complete, send real data to this URL. The data will be created in your account once it reaches this endpoint.</small>
                  </div>
              </div>



          </div>

          <div class="space-y-4">

              <select-field
                  label="Action"
                  id="action"
                  name="action"
                  :model-value="catcher.event"
                  @update:model-value="onActionSelected"
              >
                  <option value="">Select action</option>
                  <option value="" disabled>-</option>
                  <option v-for="(eventConfig, event) in events" :value="event"><span
                      v-if="eventConfig.required || false">*</span> {{ eventConfig.title }}
                  </option>
                  <template #note>
                      Specify an action what would you like to do.
                  </template>
              </select-field>


            <div v-for="(fieldConfig, field) in fields" :class="{'text-red-800': fieldErrors[field]}">
              <select-field
                  :label="fieldConfig.title"
                  v-model="fields[field].mappedTo"
                  @update:model-value="onMapChanged(field, $event)"
              >
                <option value=""></option>
                <option v-for="(availableFieldMap) in availableDataPaths" :value="availableFieldMap.path">
                  {{ availableFieldMap.path }}<span
                    v-if="availableFieldMap.value_sample"> ({{ availableFieldMap.value_sample }})</span>
                </option>
              </select-field>
            </div>
          </div>


          <div class="text-sm text-gray-500 flex items-center" v-if="dataPathsLoading">
              <spinner
                  :is-white="false"
                  class="mr-1.5"
              />
              Waiting for sample data...
          </div>

      </div>

    </template>

    <template #footer>
      <btn type="primary" :loading="loading" @click="saveCatcher" :disabled="saveCatcherDisabled">
        <span v-if="isEdit">Update</span>
        <span v-else>Create</span>
      </btn>
    </template>
  </modal>
</template>

<script setup>

import {ref, defineEmits, onMounted, computed} from "vue";
import Modal from "../../../../elements/Modal.vue";
import SelectField from "../../../../elements/forms/SelectField.vue";
import Spinner from "../../../../elements/Spinner.vue";
import Btn from "../../../../elements/Btn.vue";

const catcherDefault = {
  id: null,
  event: null,
  initial: null,
  mapping: {},
  secret: null,
  status: null,
  url: null,
  test_url: null,
};

const props = defineProps({
  events: {
    type: Object,
    default: {}
  },
  catcher: {
    type: Object,
    default: {}
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  program: {
    type: Number,
    required: true
  },
  newCatcherSecretData: {
    type: Object,
    default: {
      secret: null,
      url: null,
      test_url: null,
    }
  }
});

const emit = defineEmits(['submitSuccess']);

const loading = ref(false);
const dataPathsLoading = ref(false);

const fields = ref({});
const errors = ref([]);
const events = ref(props.events);
const webhook_catcher_modal = ref(null);
const newCatcherSecretData = ref(props.newCatcherSecretData);
const catcher = ref({...catcherDefault, ...props.catcher});

const fieldErrors = ref({});

const availableDataPaths = ref(catcher.value.available_data_paths || {});

let dataPathsInterval = null;
const isEdit = ref(catcher.value.id !== null);

const saveCatcherDisabled = computed(() => {
  return (catcher.value.event || null) === null;
})

const saveCatcher = () => {

  if (saveCatcherDisabled.value === true) {
    return;
  }

  fieldErrors.value = {};
  const validation = validate();

  if (validation !== true) {
    errors.value = validation.errors || [];
    return;
  }

  if (!isEdit.value) {
    return createCatcher();
  }
  return updateCatcher();
};

const resetModal = () => {
  fields.value = [];
  fieldErrors.value = {};
  availableDataPaths.value = {};
  catcher.value = catcherDefault;
};

const createCatcher = () => {

  if (loading.value) {
    return;
  }

  errors.value = [];
  loading.value = true;

  axios.post(route('app_int.webhooks_catchers.create', {program: props.program}), {
    catcher_data: catcher.value,
    available_data_paths: availableDataPaths.value
  })
      .then(response => {
        const status = parseInt(response.data.status || -1);

        if (status === 1) {
          webhook_catcher_modal.value.onCancel();
          newCatcherSecretData.value = response.data.new_catcher_secret_data || {};
          emit('submitSuccess', response.data);

          resetModal();
          setSecretsToCatcher();
        }

        if (status === 0) {
          errors.value = response.data.errors || [];
        }
      })
      .finally(() => loading.value = false);
};

const updateCatcher = () => {

  if (loading.value) {
    return;
  }

  errors.value = [];
  loading.value = true;

  axios.post(route('app_int.webhooks_catchers.update', {program: props.program, secret: catcher.value.secret}), {
    catcher_data: catcher.value,
    available_data_paths: availableDataPaths.value
  })
      .then(response => {
        const status = parseInt(response.data.status || -1);

        if (status === 1) {
          webhook_catcher_modal.value.onCancel();
          emit('submitSuccess', response.data);
        }

        if (status === 0) {
          errors.value = response.data.errors || [];
        }
      })
      .finally(() => loading.value = false);
};

const onMapChanged = (field, value) => {

  if (catcher.value.mapping[field] === undefined) {
    catcher.value.mapping[field] = {path: null};
  }

  catcher.value.mapping[field]['path'] = value;
};

const onActionSelected = (action) => {
  catcher.value.event = action;
  changeFields(action);
};

const changeFields = (action) => {
  let tmpFields = {};

  if (action !== null) {
    Object.keys(events.value[action]['fields']).forEach(field => {
      tmpFields[field] = {...{}, ...events.value[action]['fields'][field]};
      tmpFields[field].mappedTo = catcher.value?.mapping?.[field]?.path ?? null;
    });
  }

  fields.value = tmpFields;
};

const clearDataPatsInterval = () => {
  dataPathsLoading.value = false;
  clearInterval(dataPathsInterval);
}

const setSecretsToCatcher = () => {
  catcher.value.secret = newCatcherSecretData.value.secret;
  catcher.value.url = newCatcherSecretData.value.url;
  catcher.value.test_url = newCatcherSecretData.value.test_url;
}

const modalClose = () => {
  clearDataPatsInterval();
}

const modalShow = () => {

  dataPathsLoading.value = true;

  dataPathsInterval = setInterval(() => {
    axios
        .get(route('app_int.webhooks_catchers.get_available_fields_map', {
          program: props.program,
          secret: catcher.value.secret
        }))
        .then(response => {
          const status = parseInt(response.data.status || -1);
          if (status === 1 && (response.data?.data?.data_paths ?? []).length > 0) {
            clearDataPatsInterval();
            availableDataPaths.value = response.data?.data?.data_paths ?? [];
          }
        });
  }, 2000);
}

const validate = () => {

  let hasErrors = false;
  let tmpFieldErrors = {};
  let validationErrors = [];

  const checkFields = events.value[catcher.value.event]?.fields ?? {};

  Object.keys(checkFields).forEach(fieldToCheck => {

    let fieldToCheckConfig = events.value[catcher.value.event]?.fields[fieldToCheck];

    if (fieldToCheckConfig.required === true && (catcher.value?.mapping[fieldToCheck]?.path || null) === null) {
      hasErrors = true;
      validationErrors = ['Please fill all required fields'];
      tmpFieldErrors[fieldToCheck] = {
        error: 'Field is required'
      };
    }
  });

  if (hasErrors === false) {
    return true;
  }

  fieldErrors.value = tmpFieldErrors;

  return {
    errors: validationErrors
  };
}

onMounted(() => {
  onActionSelected(catcher.value.event);
  if (isEdit.value !== true) {
    setSecretsToCatcher();
  }
});

</script>

<style scoped>

</style>
