<template>
    <modal title="Sync Shopify customers" size="small" name="customers_sync_modal">
        <template #toggle>
            <btn type="secondary">Sync Shopify customers</btn>
        </template>
        <template #body>
            <div class="space-y-4 text-sm">
               <span>Would you like to sync customers from "{{ props.storeName }}"?</span>

               <div class="text-gray-500 text-sm space-y-2">
                    <div>Use additional mapping to store and sync customer metadata (e.g. date of birth) on Partnero with Shopify data.</div>
                    <div>Look for customer metadata in your shopify store's <i>Settings > Custom data > Customers</i></div>
                </div>

                <validation-errors :errors="errors" />

                <customer-date-of-birth
                    v-model:enabled="customerMetadataSyncSettings.setting_integration_shopify_sync_customer_date_of_birth_enabled"
                    v-model:mapping="customerMetadataSyncSettings.setting_integration_shopify_customer_date_of_birth_mapping"
                ></customer-date-of-birth>
            </div>
        </template>
        <template #footer>
            <btn :loading="loading" @click="resync" type="primary">Yes, continue</btn>
        </template>
    </modal>
</template>

<script setup>
import {inject, ref} from 'vue';
import ValidationErrors from "../../../../common/ValidationErrors.vue";
import CustomerDateOfBirth from "./metadata-sync/CustomerDateOfBirth.vue";

const props = defineProps({
    program: {
        type: [String, Number],
        required: true
    },
    storeName: {
        type: [String],
        required: false
    },
    customerMetadataSync: {
        type: Object,
        default: () => ({})
    }
});

let loading = ref(false);
const errors = ref(null)
const emitter = inject('emitter');

const customerMetadataSyncSettings = ref({...props.customerMetadataSync} || {})

const resync = () => {

    if (loading.value === true) {
        return;
    }

    loading.value = true;
    errors.value = null

    axios.post(route('app_int.integrations.shopify.resync_customers', {program: props.program}), customerMetadataSyncSettings.value)
        .then((response) => {
            emitter.emit('modal-off', 'customers_sync_modal')
        })
        .catch((error) => {
            errors.value = error.response?.data?.errors;
        })
        .finally(() => loading.value = false);
};

</script>
