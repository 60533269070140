<template>
    <div v-if="connected">
        Connected
    </div>
    <div v-else>
        <modal title="Connect program to Shopify store" size="small" @modalClose="resetMessages">
            <template #toggle>
                <btn type="secondary">Connect</btn>
            </template>
            <template #body>
                <div class="space-y-6">
                    <alert type="error" v-if="errorMessage">{{ errorMessage }}</alert>
                    <alert type="success" v-if="successMessage">{{ successMessage }}</alert>
                    <div class="text-sm text-gray-500">
                        Are you sure you want to link this program to your Shopify store, <span class="font-semibold">{{ shop_name }}</span>?
                    </div>
                </div>
            </template>
            <template #footer>
                <btn type="primary" :loading="loading.connect" @click="connect">Yes, connect</btn>
            </template>
        </modal>
    </div>
</template>
<script>
export default {
    name: 'ShopifyConnectProgram',
    props: ['program', 'shop', 'connected', 'shop_name'],
    data() {
        return {
            loading: {
                connect: false
            },
            errorMessage: null,
            successMessage: null
        }
    },
    methods: {
        resetMessages() {
            this.errorMessage = null;
            this.successMessage = null;
        },
        connect() {
            if (this.loading.connect) {
                return;
            }

            this.resetMessages();
            this.loading.connect = true;

            axios.post(
                route('app_int.integrations.shopify.connect_program', {program: this.program}),
                {shop: this.shop}
            )
                .then((response) => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        this.successMessage = response.data.message || 'Successfully connected to Shopify store!';
                        window.location.replace(route('dashboard'));
                    } else {
                        this.errorMessage = response.data.message || 'Failed to connect program';
                    }
                })
                .finally(() => {
                    this.loading.connect = false;
                });
        }
    }
}
</script>
