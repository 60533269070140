<template>
    <div>
        <label :for="input_names.select" class="block mb-1.5 text-sm">{{ text.name }}</label>
        <div class="grid gap-1" :class="types_value_disabled ? 'grid-cols-1' : 'grid-cols-2'">
            <select :id="input_names.select" :name="input_names.select" v-model="type" @change="typeChange($event)"
                    class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400">
                <option value="immediately" v-if="start_of_trigger">Immediately</option>
                <option value="never" v-if="!start_of_trigger">Never</option>
                <option value="occurrences">After X occurrences</option>
            </select>
            <text-field :model-value="times_value" :name="input_names.input" v-if="!types_value_disabled"
                        :disabled="types_value_disabled" :required="!types_value_disabled"
                        @update:modelValue="valueChange"></text-field>
        </div>
        <small class="text-gray-500 mt-1 block">{{ text.description }}</small>
    </div>
</template>
<script>
export default {
    emits: ['update:type', 'update:value'],
    name: 'CommissionTriggerPeriod',
    props: ['commission_type', 'commission_times_value', 'name', 'description', 'select_name', 'input_name', 'start_of_trigger'],
    data() {
        return {
            text: {
                name: this.name ?? '',
                description: this.description ?? '',
            },
            input_names: {
                select: this.select_name,
                input: this.input_name,
            },
            type: this.commission_type,
            times_value: this.commission_times_value,
        }
    },
    computed: {
        types_value_disabled() {
            return this.type === 'immediately' || this.type === 'never';
        }
    },
    methods: {
        typeChange(event) {
            this.$emit('update:type', event);
        },
        valueChange(value) {
            this.$emit('update:value', value);
        },
    }
}
</script>
<style scoped>
</style>
