<template>
    <nav class="flex flex-wrap rounded-t bg-white relative z-10 -mb-px border-b" aria-label="Tabs">
        <slot />
    </nav>
</template>

<script>
export default {
    props: {
        name: {},
    },
}
</script>
