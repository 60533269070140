<template>
    <modal title="Resubmit" size="small" ref="resubmit_modal">
        <template #toggle>
            <btn type="light" class="ml-3">Request resubmit</btn>
        </template>
        <template #body>
            <div class="space-y-4">
                <div class="text-sm opacity-60 mb-6">
                    Do you really want to resubmit this request?
                </div>
                <div>
                    <label class="block text-sm mb-1.5">Resubmit reason</label>
                    <textarea
                        v-model="resubmissionReason"
                        name="resubmit_reason"
                        :maxlength="maxResubmitReasonsLength"
                        class="transition-shadow block w-full rounded focus:ring-0 py-2 px-3 text-sm border-gray-300 focus:border-gray-400"
                    >
                        {{ defaultResubmitReason }}
                    </textarea>
                </div>
            </div>
        </template>
        <template #footer>
            <btn type="primary"
                 @click.native="resubmitPayoutRequest()"
                 :loading="updatingPayout"
                 :disabled="updatingPayout"
            >
                Request resubmit
            </btn>
        </template>
    </modal>
</template>

<script>
import usePayouts from '../../../../../composables/app/payouts'
import {ref} from "vue";
export default {
    name: "ResubmitRequestModal",
    emits: ['payout-updated'],
    props: {
        maxResubmitReasonsLength: {
            default: 160
        },
        defaultResubmitReason: {
            default: ''
        },
        programId: {
            required: true
        },
        payoutId: {
            required: true
        }
    },
    setup() {
        const resubmissionReason = ref('')
        const { updatePayout, updatingPayout } = usePayouts()

        return {
            resubmissionReason,
            updatePayout,
            updatingPayout
        }
    },
    methods: {
        resubmitPayoutRequest() {
            this.updatePayout(this.programId, { resubmit: 1, payout_request: this.payoutId, resubmit_reason: this.resubmissionReason }).finally(() => {
                this.$emit('payout-updated')
            });
        }
    }
}
</script>

