<template>
    <form method="post" :action="form_url">
        <card>
            <template #body>

                <input type="hidden" name="_token" :value="csrf"/>

                <form-layout>

                    <form-section title="Reward" description="Set up the main details of reward.">

                        <div class="space-y-4">
                            <text-field
                                label="Reward name"
                                field-id="commission_name"
                                name="commission_name"
                                type="text"
                                autofocus="true"
                                v-model="data.name"
                                required="true"
                                placeholder="E.g. Give $20, get $20"

                            >
                            </text-field>

                            <text-field
                                label="Reward description"
                                field-id="commission_description "
                                name="commission_description"
                                type="text"
                                v-model="data.description"
                            >
                                <template #note>
                                    Optional. Provide a description of the reward.
                                </template>
                            </text-field>
                        </div>


                        <radio-group
                            label="Reward trigger"
                            description="Choose an event when reward will start."
                            name='reward_trigger'
                            :model-value="data.reward_trigger"
                            :options="[
                                {
                                    value: 'signup',
                                    label: 'Signup',
                                    description: 'Activates whenever a new customer successfully signs up.'
                                },
                                {
                                    value: 'sale',
                                    label: 'Sale',
                                    description: 'Fires each time a customer completes a purchase.'
                                },
                                {
                                    value: 'date',
                                    label: 'Anniversaries & Dates',
                                    description: 'Triggers on special dates, such as anniversaries or birthdays.'
                                },
                                {
                                    value: 'custom',
                                    label: 'Custom',
                                    description: 'Logic-driven triggers based on specific conditions and advanced criteria.'

                                }
                                ]"
                            layout="vertical"
                            @update:model-value="rewardTriggerChange"
                        >
                            <template #note>
                                Select the action that will trigger a reward.
                            </template>
                        </radio-group>

                        <div v-if="data.reward_trigger === 'date'" class="">
                           <select-field
                                label="Source"
                                name="reward_trigger_date_source"
                                :model-value="data.reward_trigger_date_source"
                            >
                               <option value="" disabled default>Select a source</option>
                               <option value="system.date_of_birth">Date of birth</option>
                               <option value="system.joining_date">Joining date</option>
                               <template #note>
                                   Choose a customer field source to trigger a reward.
                               </template>
                           </select-field>
                        </div>

                        <div v-if="data.reward_trigger === 'custom'" class="grid grid-cols-2 gap-8">

                            <text-field
                                label="Trigger name"
                                field-id="custom_action_name"
                                name="custom_action_name"
                                type="text"
                                v-model="data.custom_action_name"
                            />

                            <text-field
                                label="Identifier"
                                field-id="custom_action_identifier"
                                name="custom_action_identifier"
                                type="text"
                                v-model="data.custom_action_identifier"
                            />

                        </div>

                        <radio-group
                            label="Reward"
                            name='commission_units'
                            :model-value="data.units"
                            @update:model-value="commissionUnitsChange"
                            :options="[
                                {
                                    value: 'custom',
                                    label: 'General',
                                    description: 'Reward customers with incentives like coupons, discounts, or special offers.'
                                  },
                                {
                                    value: 'currency',
                                    label: 'Commission',
                                    description: 'Reward customers with in-app perks like bonus credits or other rewards.'
                                    },
                                // { value: 'units', label: 'Units' },
                                ...(reward_integrations.shopify_store_credit ? [{ value: 'shopify_store_credit', label: 'Store credit', description: 'Reward customers with your Shopify store credits.' }] : [])
                            ]"
                            layout="vertical"
                        >
                            <template #note>
                                Select the type of reward. You can choose between monetary commissions or general
                                rewards like coupon codes or other incentives.
                            </template>
                        </radio-group>

                    </form-section>

                    <form-section title="Reward settings" description="Configure additional settings to customize reward behavior.">
                        <div class="grid grid-cols-2 gap-6">

                            <div v-if="data.units === 'currency' || data.units === 'units' || data.units === 'custom' || (reward_integrations.shopify_store_credit && data.units === 'shopify_store_credit')" class="col-span-2">
                                <div class="grid grid-cols-2 gap-6">
                                    <select-field
                                        v-if="data.units === 'currency' || (reward_integrations.shopify_store_credit && data.units === 'shopify_store_credit')"
                                        label="Currency"
                                        name="commission_units_currency"
                                        :model-value="data.units_currency"
                                    >
                                        <option v-for="currency in commissions_data.currencies" :value="currency.code">
                                            {{ currency.code_upper }}
                                        </option>
                                    </select-field>

                                    <text-field
                                        v-if="data.units === 'units'"
                                        label="Reward units"
                                        field-id="commission_units_name"
                                        name="commission_units_name"
                                        type="text"
                                        placeholder=""
                                        v-model="data.custom_units_name">
                                        <template #note>
                                            E.g. credits
                                        </template>
                                    </text-field>

                                    <CommissionCustomPeriod
                                        v-if="reward_integrations.shopify_store_credit && data.units === 'shopify_store_credit'"
                                        name="Shopify store credit expiry"
                                        description="Choose store credit validity period."
                                        select_name="commission_units_shopify_store_credit_period_type"
                                        input_name="commission_units_shopify_store_credit_period_times_value"
                                        :period_type="data.units_shopify_store_credit_period_type"
                                        :period_times_value="data.units_shopify_store_credit_period_times_value"
                                    />

                                    <text-field
                                        v-if="data.reward_trigger === 'sale'"
                                        label="Minimum transaction value"
                                        field-id="minimum_transaction_value"
                                        name="minimum_transaction_value"
                                        type="text"
                                        placeholder=" E.g. 10"
                                        v-model="data.minimum_transaction_value">
                                        <template #note>
                                            Minimum transaction amount required to qualify for a reward.
                                        </template>
                                    </text-field>

                                </div>
                            </div>

                            <commission-period_setting
                                name="Reward recurrence"
                                description="Choose how many rewards can be triggered from one referral."
                                select_name="commission_period_type"
                                input_name="commission_period_times_value"
                                :commission_type="data.period_type"
                                :commission_times_value="data.period_value"
                            />

                            <commission-review_period_setting
                                name="Rewards review period"
                                description="After this period, the referred purchase will count toward a reward."
                                select_name="commission_review_period_type"
                                input_name="commission_review_period_times_value"
                                :commission_type="data.review_period_type"
                                :commission_times_value="data.review_period_value"
                            />

                            <commission-trigger_period_setting
                                name="Start of the trigger"
                                description="Choose when rewards will be triggered."
                                select_name="commission_start_trigger_type"
                                input_name="commission_start_trigger_times_value"
                                :start_of_trigger="true"
                                :commission_type="data.commission_start_trigger_type"
                                :commission_times_value="data.commission_start_trigger_times_value"
                            />

                            <commission-trigger_period_setting
                                name="End of the trigger"
                                description="Choose when rewards trigger will end."
                                select_name="commission_end_trigger_type"
                                input_name="commission_end_trigger_times_value"
                                :start_of_trigger="false"
                                :commission_type="data.commission_end_trigger_type"
                                :commission_times_value="data.commission_end_trigger_times_value"
                            />

                        </div>
                    </form-section>

                    <form-section
                        :title="data.reward_trigger === 'date' ? 'Customer reward' : 'Referring customer reward'"
                        :description="`Set up the reward a ${data.reward_trigger === 'date' ? 'customer' : 'referring customer'} will receive.`"
                    >
                        <div v-if="data.units === 'currency' || data.units === 'units' || (reward_integrations.shopify_store_credit && data.units === 'shopify_store_credit')" class="space-y-8">
                            <div>
                                <radio-group
                                    label="Commission type"
                                    name="partner_commission_type"
                                    :options="commissionTypeOptions"
                                    :model-value="data.partner.commission.type"
                                    @update:model-value="partnerCommissionTypeChange"
                                />
                            </div>

                            <div v-if="data.partner.commission.type !== 'none'">
                                <text-field
                                    label="Commission amount"
                                    field-id="partner_commission_value"
                                    name="partner_commission_value"
                                    type="text"
                                    :placeholder="data.partner.commission.type === 'percent' ? '10' : '10.00'"
                                    :prepend="data.partner.commission.type === 'percent' ? '%' : null"
                                    v-model="data.partner.commission.value"
                                />
                            </div>
                        </div>

                        <div v-else-if="data.units === 'custom'" class="space-y-8">
                            <radio-group
                                label="Reward"
                                name="partner_commission_type"
                                :model-value="data.partner.commission.type"
                                :options="customCommissionTypeOptions"
                                @update:model-value="partnerCommissionTypeChange"
                            />

                            <div v-if="data.partner.commission.type !== 'none'">
                                <text-field
                                    v-if="data.partner.commission.type === 'generic_coupon'"
                                    name="partner_commission_value"
                                    label="Generic coupon"
                                    placeholder="E.g. DISCOUNT20"
                                    v-model="data.partner.commission.value"
                                >
                                    <template #note>
                                        Enter an existing coupon or promotion code that the referring customer will be
                                        rewarded with.
                                    </template>
                                </text-field>

                                <text-field
                                    v-if="data.partner.commission.type === 'amazon_gift_card'"
                                    name="partner_commission_value"
                                    label="Amazon Gift card amount"
                                    v-model="data.partner.commission.value"
                                ></text-field>

                                <text-field
                                    v-if="data.partner.commission.type === 'shopify_gift_card'"
                                    name="partner_commission_value"
                                    label="Shopify Gift card amount"
                                    v-model="data.partner.commission.value"
                                ></text-field>

                                <div class="space-y-4">
                                    <div v-if="data.partner.commission.type === 'dynamic_coupon'">

                                       <div>
                                           <label class="block text-sm leading-5 mb-1.5">Coupon</label>
                                           <div class="grid grid-cols-3 gap-4 items-center">
                                               <VueMultiselect
                                                   id="partner-coupon-select"
                                                   class="col-span-2"
                                                   name="partner_commission_value"
                                                   placeholder="Select a coupon"
                                                   :options="couponsList"
                                                   track-by="uuid_code"
                                                   label="name"
                                                   v-model="partnerSelectedCoupon"
                                                   :multiple="false"
                                                   :searchable="true"
                                                   :loading="isLoadingCoupons"
                                                   :internal-search="false"
                                                   :clear-on-select="false"
                                                   :close-on-select="true"
                                                   :options-limit="300"
                                                   :limit="1"
                                                   :limit-text="limitText"
                                                   :max-height="600"
                                                   :show-no-results="true"
                                                   @search-change="doSearch($event)"
                                               ></VueMultiselect>
                                               <CouponModal v-if="!partnerSelectedCoupon"
                                                            :program="program"
                                                            :coupon-data="couponData"
                                                            :coupon-sync-providers="coupon_sync_providers"
                                                            coupon-action="create"
                                                            @coupon-created="handlePartnerCouponCreated"
                                                            id="partner-coupon-modal"
                                               >
                                               </CouponModal>
                                           </div>
                                           <small class="text-gray-500 mt-1 block">Select an existing coupon or create a new.</small>
                                       </div>

                                        <input type="text" hidden name="partner_commission_value"
                                               v-model="partnerJsonCouponReward">

                                    </div>

                                    <div class="space-y-8" v-if="partnerSelectedCoupon && partnerSelectedCoupon.sync_provider === 'stripe'">
                                        <toggle title="Apply a coupon to existing customers"
                                            name="stripe_products_for_partner_to_apply_coupon_to_enabled"
                                            v-model="stripeProductsForPartnerToApplyCouponToEnabled"
                                        >
                                            <template #description>
                                                When enabled, you can apply a coupon to an existing customer for a specific product.
                                            </template>
                                        </toggle>
                                    </div>

                                    <div v-if="stripeProductsForPartnerToApplyCouponToEnabled && partnerSelectedCoupon && partnerSelectedCoupon.sync_provider === 'stripe'">
                                        <div>
                                            <label class="block text-sm leading-5 mb-1.5">Product</label>
                                            <VueMultiselect
                                                id="partner-stripe-product-select"
                                                name="stripe_products_for_partner_to_apply_coupon_to"
                                                placeholder="Select a product"
                                                :options="stripeProductsListForPartner"
                                                track-by="id"
                                                label="name"
                                                v-model="selectedStripeProductsToApplyCouponToForPartner"
                                                :multiple="true"
                                                :searchable="true"
                                                :loading="isLoadingStripeProductsForPartner"
                                                :internal-search="false"
                                                :clear-on-select="false"
                                                :close-on-select="false"
                                                :options-limit="300"
                                                :limit="20"
                                                :limit-text="limitText"
                                                :max-height="600"
                                                :show-no-results="true"
                                                @search-change="searchStripeProductsForPartners"
                                            ></VueMultiselect>
                                            <small class="text-gray-500 mt-1 block">Select a product to which a coupon needs to be applied.</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="space-y-4" v-if="data.partner.commission.type === 'custom'">
                                    <text-field
                                        name="partner_commission_value"
                                        label="Reward"
                                        v-model="data.partner.commission.value"
                                    >
                                        <template #note>
                                            Enter a reward for the referring customer. You can enter a URL, description,
                                            or other relevant details.
                                        </template>
                                    </text-field>
                                </div>
                            </div>
                        </div>
                    </form-section>

                    <form-section v-if="data.reward_trigger !== 'date'" title="Referral reward"
                                  description="Set up the reward a referred customer will receive.">
                        <div v-if="data.units === 'currency' || data.units === 'units' || (reward_integrations.shopify_store_credit && data.units === 'shopify_store_credit')" class="space-y-8">
                            <div>
                                <radio-group
                                    label="Commission type"
                                    name="referral_commission_type"
                                    :model-value="data.referral.commission.type"
                                    :options="commissionTypeOptions"
                                    @update:model-value="referralCommissionTypeChange"
                                />
                            </div>

                            <div v-if="data.referral.commission.type !== 'none'">
                                <text-field
                                    label="Commission amount"
                                    field-id="referral_commission_value"
                                    name="referral_commission_value"
                                    type="text"
                                    :placeholder="data.referral.commission.type === 'percent' ? '10' : '10.00'"
                                    :prepend="data.referral.commission.type === 'percent' ? '%' : null"
                                    v-model="data.referral.commission.value"
                                />
                            </div>
                        </div>

                        <div v-else-if="data.units === 'custom'" class="space-y-8">
                            <radio-group
                                label="Reward"
                                name="referral_commission_type"
                                :model-value="data.referral.commission.type"
                                :options="customCommissionTypeOptions"
                                @update:model-value="referralCommissionTypeChange"
                            />

                            <div v-if="data.referral.commission.type !== 'none'">
                                <text-field
                                    v-if="data.referral.commission.type === 'generic_coupon'"
                                    name="referral_commission_value"
                                    label="Generic coupon"
                                    placeholder="E.g. DISCOUNT20"
                                    v-model="data.referral.commission.value"
                                >
                                    <template #note>
                                        Enter an existing coupon or promotion code that the referred customer will be
                                        rewarded with.
                                    </template>
                                </text-field>

                                <text-field
                                    v-if="data.referral.commission.type === 'amazon_gift_card'"
                                    name="referral_commission_value"
                                    label="Amazon Gift card amount"
                                    v-model="data.referral.commission.value"
                                ></text-field>

                                <text-field
                                    v-if="data.referral.commission.type === 'shopify_gift_card'"
                                    name="referral_commission_value"
                                    label="Shopify Gift card amount"
                                    v-model="data.referral.commission.value"
                                ></text-field>

                                <div class="space-y-4">
                                    <div v-if="data.referral.commission.type === 'dynamic_coupon'">
                                        <div>
                                            <label class="block text-sm leading-5 mb-1.5">Coupon</label>
                                            <div class="grid grid-cols-3 gap-4 items-center">
                                        <VueMultiselect
                                            id="referral-coupon-select"
                                            class="col-span-2"
                                            name="referral_commission_value"
                                            placeholder="Select a coupon"
                                            :options="couponsList"
                                            track-by="uuid_code"
                                            label="name"
                                            v-model="referralSelectedCoupon"
                                            :multiple="false"
                                            :searchable="true"
                                            :loading="isLoadingCoupons"
                                            :internal-search="false"
                                            :clear-on-select="false"
                                            :close-on-select="true"
                                            :options-limit="300"
                                            :limit="1"
                                            :limit-text="limitText"
                                            :max-height="600"
                                            :show-no-results="true"
                                            @search-change="doSearch($event)"
                                        ></VueMultiselect>
                                        <CouponModal v-if="!referralSelectedCoupon"
                                                     :program="program"
                                                     :coupon-data="couponData"
                                                     :coupon-sync-providers="coupon_sync_providers"
                                                     coupon-action="create"
                                                     @coupon-created="handleReferralCouponCreated"
                                                     id="referral-coupon-modal"
                                        >
                                        </CouponModal>
                                            </div>
                                            <small class="text-gray-500 mt-1 block">Select an existing coupon or create a new.</small>
                                        </div>

                                        <input type="text" hidden name="referral_commission_value"
                                               v-model="referralJsonCouponReward">
                                    </div>

                                    <div class="space-y-8" v-if="referralSelectedCoupon && referralSelectedCoupon.sync_provider === 'stripe'">
                                        <toggle title="Apply a coupon to existing customers"
                                                name="stripe_products_for_referral_to_apply_coupon_to_enabled"
                                                v-model="stripeProductsForReferralToApplyCouponToEnabled"
                                        >
                                            <template #description>
                                                When enabled, you can apply a coupon to an existing customer for a specific product.
                                            </template>
                                        </toggle>
                                    </div>

                                    <div v-if="stripeProductsForReferralToApplyCouponToEnabled && referralSelectedCoupon && referralSelectedCoupon.sync_provider === 'stripe'">
                                        <div>
                                            <label class="block text-sm leading-5 mb-1.5">Product</label>
                                        <VueMultiselect
                                            id="referral-stripe-product-select"
                                            class="col-span-2"
                                            name="stripe_products_for_referral_to_apply_coupon_to"
                                            placeholder="Select a product"
                                            :options="stripeProductsListForReferral"
                                            track-by="id"
                                            label="name"
                                            v-model="selectedStripeProductsToApplyCouponToForReferral"
                                            :multiple="true"
                                            :searchable="true"
                                            :loading="isLoadingStripeProductsForReferral"
                                            :internal-search="false"
                                            :clear-on-select="false"
                                            :close-on-select="false"
                                            :options-limit="300"
                                            :limit="20"
                                            :limit-text="limitText"
                                            :max-height="600"
                                            :show-no-results="true"
                                            @search-change="searchStripeProductsForReferrals"
                                        ></VueMultiselect>
                                            <small class="text-gray-500 mt-1 block">Select a product to which a coupon needs to be applied.</small>

                                        </div>
                                    </div>
                                </div>

                                <div class="space-y-4" v-if="data.referral.commission.type === 'custom'">
                                    <text-field
                                        name="referral_commission_value"
                                        label="Reward"
                                        v-model="data.referral.commission.value"
                                    >
                                        <template #note>
                                            Enter a reward for the referred customer. You can enter a URL, description,
                                            or other relevant details.
                                        </template>
                                    </text-field>
                                </div>
                            </div>
                        </div>

                        <div class="space-y-8" v-if="data.reward_trigger === 'signup'">
                            <toggle
                                title="Reward customer for their own signup"
                                name="reward_non_referred_partner_signup"
                                :model-value="data.referral.reward_non_referred_signup"
                                @update:modelValue=""
                            >
                                <template #description>
                                    Customers will be rewarded once they join the program without being referred.
                                </template>
                            </toggle>
                        </div>

                    </form-section>
                </form-layout>

            </template>
            <template #footer>
                <btn button-type="submit">{{ submit_name }}</btn>
            </template>
        </card>
    </form>
</template>
<script>
import { onMounted, ref } from "vue";
import VueMultiselect from "vue-multiselect";
import FormSection from "../../../../elements/forms/FormSection.vue";
import TextField from "../../../../elements/forms/TextField";
import Toggle from "../../../../elements/forms/Toggle.vue";
import { useCouponStore } from "../../../../stores/app/programs/common/coupons/CouponStore";
import { isValidJson } from "../../resources-builder/helpers";
import CouponForm from "../coupons/CouponForm.vue";
import CouponModal from "../coupons/CouponModal.vue";
import CommissionCustomPeriod from "../settings/CommissionCustomPeriod.vue";
export default {

    name: 'ReferralProgramCommissionSettings',
    props: {
        commissions_data: Object,
        form_url: String,
        csrf: String,
        submit_button_name: String,
        coupon_sync_providers: {
            type: Array,
            default: []
        },
        coupon_data: {
            type: Object,
            default: {}
        },
        program: {
            type: Object,
            default: {}
        }
    },
    components: {CouponModal, VueMultiselect, FormSection, CouponForm, Toggle, TextField, CommissionCustomPeriod},
    setup(props) {

        const couponData = ref(props.coupon_data)
        const { setShowRewardCouponAsOption } = useCouponStore()

        onMounted(() => {
            couponData.value.is_reward_coupon = true
            if (couponData.value.auto_assign_promotion_code) {
                couponData.value.auto_assign_promotion_code.is_reward_promotion_code = true
            }
            setShowRewardCouponAsOption(false)
        })

        return {
            couponData
        }
    },
    data() {
        return {
            submit_name: this.submit_button_name ?? 'Submit',

            reward_integrations: {
                amazon_gift_card: this.commissions_data?.reward_integrations?.amazon_gift_card ?? true,
                shopify_gift_card: this.commissions_data?.reward_integrations?.shopify_gift_card ?? true,
                coupon: this.commissions_data?.reward_integrations?.coupon ?? true,
                shopify_store_credit: this.commissions_data?.reward_integrations?.shopify_store_credit ?? false
            },

            data: {
                name: this.commissions_data.commission_name ?? '',
                description: this.commissions_data.commission_description ?? '',
                reward_trigger: this.getRewardTriggerValue(this.commissions_data.reward_trigger ?? 'sale'),
                reward_trigger_date_source: this.commissions_data?.reward_trigger_date_source ?? '',

                custom_action_name: this.commissions_data?.custom_action_name ?? '',
                custom_action_identifier: this.commissions_data?.custom_action_identifier ?? '',

                coupon_sync_provider: this.commissions_data?.coupon_sync_provider ?? '',

                units: this.commissions_data?.units ?? 'custom',
                custom_units_name: this.commissions_data?.custom_units_name ?? '',
                units_currency: this.commissions_data?.units_currency ?? 'USD',
                minimum_transaction_value: this.commissions_data?.minimum_transaction_value ?? null,

                units_shopify_store_credit_period_type: this.commissions_data?.units_shopify_store_credit_period_type ?? 'lifetime',
                units_shopify_store_credit_period_times_value: this.commissions_data?.units_shopify_store_credit_period_times_value ?? '1',

                period_type: this.commissions_data?.period_type ?? 'lifetime',
                period_value: this.commissions_data?.period_value ?? '1',
                review_period_type: this.commissions_data?.review_period_type ?? 'off',
                review_period_value: this.commissions_data?.review_period_value ?? '1',

                commission_start_trigger_type: this.commissions_data?.commission_start_trigger_type ?? 'immediately',
                commission_start_trigger_times_value: this.commissions_data?.commission_start_trigger_times_value ?? '1',

                commission_end_trigger_type: this.commissions_data?.commission_end_trigger_type ?? 'never',
                commission_end_trigger_times_value: this.commissions_data?.commission_end_trigger_times_value ?? '1',

                partner: {
                    commission: {
                        type: this.commissions_data?.partner?.commission?.type ?? 'none',
                        value: this.commissions_data?.partner?.commission?.value ?? '',
                    }
                },
                referral: {
                    commission: {
                        type: this.commissions_data?.referral?.commission?.type ?? 'none',
                        value: this.commissions_data?.referral?.commission?.value ?? '',
                    },
                    other_attributes: [],
                    reward_non_referred_signup: this.commissions_data?.referral?.reward_non_referred_partner_signup ?? false
                },
            },
            isLoadingCoupons: false,
            delayTimer: null,
            delayTimerForPartners: null,
            delayTimerForReferrals: null,
            couponsList: [],
            partnerSelectedCoupon: null,
            referralSelectedCoupon: null,
            stripeProductsForPartnerToApplyCouponToEnabled: false,
            stripeProductsForReferralToApplyCouponToEnabled: false,
            stripeProductsListForPartner: [],
            selectedStripeProductsToApplyCouponToForPartner: [],
            isLoadingStripeProductsForPartner: false,
            stripeProductsListForReferral: [],
            selectedStripeProductsToApplyCouponToForReferral: [],
            isLoadingStripeProductsForReferral: false
        }
    },
    created() {
        if (this.data.partner.commission.type === 'dynamic_coupon' && isValidJson(this.data.partner.commission.value)) {
            const parsedPartnerCoupon = JSON.parse(this.data.partner.commission.value);
            this.partnerSelectedCoupon = parsedPartnerCoupon;
            this.couponsList.push(parsedPartnerCoupon);
            if (parsedPartnerCoupon.sync_provider === 'stripe' && parsedPartnerCoupon.stripe_products) {
                this.stripeProductsForPartnerToApplyCouponToEnabled = parsedPartnerCoupon.stripe_products_for_partner_to_apply_coupon_to_enabled ?? false;
                this.selectedStripeProductsToApplyCouponToForPartner = parsedPartnerCoupon.stripe_products;
                this.stripeProductsListForPartner = parsedPartnerCoupon.stripe_products;
            }
            this.doSearch('')
        }
        if (this.data.referral.commission.type === 'dynamic_coupon' && isValidJson(this.data.referral.commission.value)) {
            const parsedReferralCoupon = JSON.parse(this.data.referral.commission.value);
            this.referralSelectedCoupon = parsedReferralCoupon;
            if (this.referralSelectedCoupon.name !== this.partnerSelectedCoupon.name) {
                this.couponsList.push(parsedReferralCoupon);
            }
            if (parsedReferralCoupon.sync_provider === 'stripe' && parsedReferralCoupon.stripe_products) {
                this.stripeProductsForReferralToApplyCouponToEnabled = parsedReferralCoupon.stripe_products_for_referral_to_apply_coupon_to_enabled ?? false;
                this.selectedStripeProductsToApplyCouponToForReferral = parsedReferralCoupon.stripe_products;
                this.stripeProductsListForReferral = parsedReferralCoupon.stripe_products;
            }
            this.doSearch('')
        }
    },
    methods: {
        getRewardTriggerValue(given) {
            if (['sale', 'signup', 'date'].includes(given) === true) {
                return given;
            }

            return 'custom';
        },
        rewardTriggerChange(event) {
            this.data.reward_trigger = event;
        },
        commissionUnitsChange(event) {
            this.data.units = event;
        },
        partnerCommissionTypeChange(event) {
            this.data.partner.commission.type = event;
        },
        referralCommissionTypeChange(event) {
            this.data.referral.commission.type = event;
        },
        limitText(count) {
            return `and ${count} other customers`
        },
        doSearch(query) {
            clearTimeout(this.delayTimer);
            this.delayTimer = setTimeout(() => {
                this.isLoadingCoupons = true;
                axios.get(route('app_int.coupon.search', {program: this.program}) + '?search=' + query, {
                    params: {
                        search_only_reward_coupons: true
                    }
                })
                    .then(response => {
                        this.isLoadingCoupons = false;
                        this.couponsList = response.data;
                        if (this.partnerSelectedCoupon && !this.couponsList.find(coupon => coupon.id === this.partnerSelectedCoupon.id)) {
                            this.couponsList.push(this.partnerSelectedCoupon);
                        }
                        if (this.referralSelectedCoupon && !this.couponsList.find(coupon => coupon.id === this.referralSelectedCoupon.id)) {
                            this.couponsList.push(this.referralSelectedCoupon);
                        }
                    })
                    .catch(error => {
                        this.isLoadingCoupons = false;
                    })
                    .finally(() => {
                        this.isLoadingCoupons = false;
                    });
            }, 700);
        },
        handlePartnerCouponCreated(coupon) {
            this.couponsList.push(coupon);
            this.partnerSelectedCoupon = {
                id: coupon.id,
                name: coupon.name,
                uuid_code: coupon.uuid_code,
                sync_provider: coupon.sync_provider
            };
        },
        handleReferralCouponCreated(coupon) {
            // this is for a better ux only, because we are removing create button and modal from the page
            setTimeout(() => {
                this.couponsList.push(coupon);
                this.referralSelectedCoupon = {
                    id: coupon.id,
                    name: coupon.name,
                    uuid_code: coupon.uuid_code,
                    sync_provider: coupon.sync_provider

                };
            }, 500);
        },
        searchStripeProductsForPartners(query) {
            clearTimeout(this.delayTimerForPartners);
            this.delayTimerForPartners = setTimeout(() => {
                this.isLoadingStripeProductsForPartner = true;
                if (query.length > 0 && query.length < 3) {
                    this.isLoadingStripeProductsForPartner = false;
                    return;
                }
                axios.get(route('app_int.provider.products.search.stripe', {program: this.program}) + '?search=' + query)
                    .then(response => {
                        this.isLoadingStripeProductsForPartner = false;
                        this.stripeProductsListForPartner = response.data?.data || [];
                    })
                    .catch(error => {
                        this.isLoadingStripeProductsForPartner = false;
                    })
                    .finally(() => {
                        this.isLoadingStripeProductsForPartner = false;
                    });
            }, 700);
        },
        searchStripeProductsForReferrals(query) {
            clearTimeout(this.delayTimerForReferrals);
            this.delayTimerForReferrals = setTimeout(() => {
                this.isLoadingStripeProductsForReferral = true;
                if (query.length > 0 && query.length < 3) {
                    this.isLoadingStripeProductsForReferral = false;
                    return;
                }
                axios.get(route('app_int.provider.products.search.stripe', {program: this.program}) + '?search=' + query)
                    .then(response => {
                        this.isLoadingStripeProductsForReferral = false;
                        this.stripeProductsListForReferral = response.data?.data || [];
                    })
                    .catch(error => {
                        this.isLoadingStripeProductsForReferral = false;
                    })
                    .finally(() => {
                        this.isLoadingStripeProductsForReferral = false;
                    });
            }, 700);
        }
    },
    watch: {
        'data.partner.commission.type'(newVal) {
            if (newVal === 'dynamic_coupon') {
                this.doSearch('')
            } else {
                this.partnerSelectedCoupon = null;
                this.stripeProductsForPartnerToApplyCouponToEnabled = false;
            }
        },
        'data.referral.commission.type'(newVal) {
            if (newVal === 'dynamic_coupon') {
                this.doSearch('')
            } else {
                this.referralSelectedCoupon = null;
                this.stripeProductsForReferralToApplyCouponToEnabled = false;
            }
        },
        stripeProductsForPartnerToApplyCouponToEnabled(newVal) {
            if (newVal) {
                this.searchStripeProductsForPartners('')
            }
        },
        stripeProductsForReferralToApplyCouponToEnabled(newVal) {
            if (newVal) {
                this.searchStripeProductsForReferrals('')
            }
        }
    },
    computed: {
        commissionTypeOptions() {

            let options = [
                {
                    value: 'none',
                    label: 'None',
                    description: 'Skip customer reward'
                },
                {
                    value: 'static',
                    label: 'Static',
                    description: 'Reward your customers with a fixed amount of reward units.'
                }
            ];

            if (this.data.reward_trigger === 'sale' || this.data.reward_trigger === 'custom') {
                options = [
                    {
                        value: 'none',
                        label: 'None',
                        description: 'Skip customer reward'
                    },
                    {
                        value: 'percent',
                        label: 'Percentage',
                        description: 'Reward your customers with a percentage amount of reward units.'
                    },
                    {
                        value: 'static',
                        label: 'Static',
                        description: 'Reward your customers with a fixed amount of reward units.'
                    }
                ];
            }

            return options;
        },
        customCommissionTypeOptions() {

            let commissionTypes = [
                {
                    value: 'none',
                    label: 'None',
                    description: 'Skip customer reward'
                },
                {
                    value: 'generic_coupon',
                    label: 'Generic coupon',
                    description: 'Reward a customer with a generic coupon.'
                },
                {
                    value: 'custom',
                    label: 'Custom',
                    description: 'Reward a customer with a custom reward, i.e. PDF book, etc.'
                },
            ];

            if (this.reward_integrations.shopify_gift_card) {
                commissionTypes.splice(2, 0, {
                    value: 'shopify_gift_card',
                    label: 'Shopify Gift Card',
                    description: 'Reward a customer with Shopify Gift Card. Available only for Shopify Plus members.'
                });
            }

            if (this.reward_integrations.amazon_gift_card) {
                commissionTypes.splice(2, 0, {
                    value: 'amazon_gift_card',
                    label: 'Amazon Gift Card',
                    description: 'Reward a customer with Amazon Gift Card.'
                });
            }

            if (this.reward_integrations.coupon) {
                commissionTypes.splice(2, 0, {
                    value: 'dynamic_coupon',
                    label: 'Dynamic coupon',
                    description: 'Reward a customer with a coupon.'
                });
            }

            return commissionTypes;
        },
        partnerJsonCouponReward() {
            if (this.partnerSelectedCoupon == null) {
                return null;
            }

            if (this.partnerSelectedCoupon.sync_provider === 'stripe') {
                return JSON.stringify({
                    ...this.partnerSelectedCoupon,
                    stripe_products_for_partner_to_apply_coupon_to_enabled: this.stripeProductsForPartnerToApplyCouponToEnabled,
                    stripe_products: this.selectedStripeProductsToApplyCouponToForPartner.map(product => {
                        return {
                            id: product.id,
                            name: product.name
                        }
                    })
                });
            }

            return JSON.stringify(this.partnerSelectedCoupon);
        },
        referralJsonCouponReward() {
            if (this.referralSelectedCoupon == null) {
                return null;
            }

            if (this.referralSelectedCoupon.sync_provider === 'stripe') {
                return JSON.stringify({
                    ...this.referralSelectedCoupon,
                    stripe_products_for_referral_to_apply_coupon_to_enabled: this.stripeProductsForReferralToApplyCouponToEnabled,
                    stripe_products: this.selectedStripeProductsToApplyCouponToForReferral.map(product => {
                        return {
                            id: product.id,
                            name: product.name
                        }
                    })
                });
            }

            return JSON.stringify(this.referralSelectedCoupon);
        }
    }
}
</script>
