import { defineStore } from "pinia";

export let useRevolvappEmailsListStore = defineStore( 'revolvappEmailsList', {
    state: () => ({
        templates: {},
        sendTestEmailInProgress: false,
        saveEmailUpdatesInProgress: {},
        publishingEmailDraftInProgress: {},
        sendTestEmailUrl: null,
        updateEmailsUrl: null,
        publishDraftEmailUrl: null,
        testEmailAddress: '',
        notification: {
            showNotification: false,
            notificationType: '',
            notificationTitle: '',
            notificationMessage: '',
        }
    }),

    actions: {
        sendTestEmail(email_template) {
            this.sendTestEmailInProgress = true;
            axios
                .post(this.sendTestEmailUrl, {
                    slug: email_template.slug,
                    email_address: this.testEmailAddress
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {
                        this.notification.showNotification = true
                        this.notification.notificationType = 'success'
                        this.notification.notificationTitle = 'Mail sent'
                        this.notification.notificationMessage = 'Test mail has been sent.'
                    }
                    else if (status === 2) {
                        this.notification.showNotification = true
                        this.notification.notificationType = 'warning'
                        this.notification.notificationTitle = 'Mail has not been sent'
                        this.notification.notificationMessage = 'Test mail has not been sent. Check provider settings.'
                    }
                    else if (status === 0) {
                        this.notification.showNotification = true
                        this.notification.notificationType = 'warning'
                        this.notification.notificationTitle = 'Mail has not been sent'
                        this.notification.notificationMessage = response.data?.error ?? 'Test mail has not been sent. Check provider settings.'
                    }
                })
                .catch(error => {
                    this.notification.showNotification = true
                    this.notification.notificationType = 'warning'
                    this.notification.notificationTitle = 'Sending failed'
                    this.notification.notificationMessage = 'Sending test mail has failed, check console for details.'
                    console.log(error, error.response)
                })
                .finally(() => this.sendTestEmailInProgress = false);
        },
        saveChanges(email_template) {
            this.saveEmailUpdatesInProgress[email_template.slug] = true;
            axios
                .post(this.updateEmailsUrl, {
                    slug: email_template.slug,
                    subject: email_template.mail.subject,
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1){
                        this.notification.showNotification = true
                        this.notification.notificationType = 'success'
                        this.notification.notificationTitle = 'Done'
                        this.notification.notificationMessage = 'Subject successfully updated.'
                    }
                })
                .catch(error => {
                })
                .finally(() => this.saveEmailUpdatesInProgress[email_template.slug] = false);
        },
        publishDraft(templatesGroup, email_template) {
            this.publishingEmailDraftInProgress[email_template.slug] = true;
            axios
                .post(this.publishDraftEmailUrl, {
                    slug: email_template.slug
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1){
                        const updatedItem = response.data.updatedProgramEmail
                        // Update item in templates, so it reflects in frontend without additional call or refresh
                        const oldItemIndex = this.templates[templatesGroup].findIndex((emailTemplate) => emailTemplate.mail.id === updatedItem.id)
                        this.templates[templatesGroup][oldItemIndex].mail.has_draft = false
                        this.templates[templatesGroup][oldItemIndex].mail.revolvapp_template = updatedItem.revolvapp_template
                        this.templates[templatesGroup][oldItemIndex].revolvapp_template = updatedItem.revolvapp_template
                        this.templates[templatesGroup][oldItemIndex].html = updatedItem.html

                        this.notification.showNotification = true
                        this.notification.notificationType = 'success'
                        this.notification.notificationTitle = 'Done'
                        this.notification.notificationMessage = 'Draft successfully published.'
                    }
                })
                .catch(error => {
                })
                .finally(() => this.publishingEmailDraftInProgress[email_template.slug] = false);
        },
        toggleEditMode(email_template) {
            email_template.is_open_for_edit = !email_template.is_open_for_edit;
        },
        openEmailEdit(email_template) {
            window.open(email_template.urls.edit, '_self');
        }
    },

    getters: {
        getTemplates: (state) => state.templates
    }
})
