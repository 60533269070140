<script setup>

import {useAnalyticsOverviewStore} from "../../../../../../../stores/app/programs/analytics/AnalyticsOverview";
import {createRouteForTemplate} from "../../../../../../../helpers/common";

const analyticsOverviewStore = useAnalyticsOverviewStore();

</script>

<template>
    <div class="h-full space-y-4 xl:flex">
        <div class="p-6 bg-white rounded border w-full"
             :class="{grid: analyticsOverviewStore.getAnalyticsOverviewRecentTransactionData.length === 0}"
        >
            <ul v-if="analyticsOverviewStore.getAnalyticsOverviewRecentTransactionData.length > 0"
                role="list"
                class="-mb-8"
            >
                <li v-for="(transaction, index) in analyticsOverviewStore.getAnalyticsOverviewRecentTransactionData">
                    <div class="relative pb-8">
                        <span v-if="index !== analyticsOverviewStore.getAnalyticsOverviewRecentTransactionData.length - 1"
                            class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true">
                        </span>
                        <div class="relative flex space-x-3">
                            <template v-if="transaction['origin'] === 'signup'">
                                <div>
                                    <span
                                        class="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center ring-8 ring-white text-white">
                                        <icon name="user-fill"/>
                                    </span>
                                </div>
                                <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                    <div class="text-sm text-gray-500">
                                        <span>New signup from</span>
                                        <a :href="createRouteForTemplate('programs.customer.show', { program: analyticsOverviewStore.getProgram, 'customer': transaction['partner_customer_id']})"
                                           class="font-medium text-gray-900">
                                            {{ transaction['partner_customer'] }}
                                        </a>
                                    </div>
                                    <div class="whitespace-nowrap text-right text-sm text-gray-500">
                                        <time datetime="2020-09-20">
                                            {{ transaction['created_at'] }}
                                        </time>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="transaction['origin'] === 'sale'">
                                <div>
                                    <span v-if="transaction['first_of_this_origin'] === true"
                                        class="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white text-white">
                                       <icon name="bank-card-fill"/>
                                    </span>
                                    <span v-else
                                        class="h-8 w-8 rounded-full bg-teal-500 flex items-center justify-center ring-8 ring-white text-white">
                                        <icon name="currency"/>
                                    </span>
                                </div>
                                <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                    <div class="text-sm text-gray-500">
                                        <span class="text-sm text-gray-500">
                                            {{ transaction['first_of_this_origin'] === true ? 'First purchase by ' : 'Recurring purchase by ' }}
                                        </span>
                                        <a :href="createRouteForTemplate('programs.customer.show', { program: analyticsOverviewStore.getProgram, customer: transaction['partner_customer_id']})"
                                           class="font-medium text-gray-900">
                                            {{ transaction['partner_customer'] }}
                                        </a>
                                    </div>
                                    <div class="whitespace-nowrap text-right text-sm text-gray-500">
                                        <time datetime="2020-09-20">
                                            {{ transaction['created_at'] }}
                                        </time>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div>
                                    <span
                                        class="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center ring-8 ring-white text-white">
                                        <icon name="user-fill"/>
                                    </span>
                                </div>
                                <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                    <div class="text-sm text-gray-500">
                                        <span class="text-sm text-gray-500">
                                            {{ transaction['origin'] }} by
                                        </span>
                                        <a :href="createRouteForTemplate('programs.customer.show', { program: analyticsOverviewStore.getProgram, customer: transaction['partner_customer_id']})"
                                           class="font-medium text-gray-900">
                                            {{ transaction['partner_customer'] }}
                                        </a>
                                    </div>
                                    <div class="whitespace-nowrap text-right text-sm text-gray-500">
                                        <time datetime="2020-09-20">
                                            {{ transaction['created_at'] }}
                                        </time>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-else class="text-center text-gray-500 grid content-center">
                No recent transactions
            </div>
        </div>
    </div>
</template>
