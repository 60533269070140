<template>
    <div class="border-b -mx-9 mb-8">
        <div class="px-9">
            <nav class="-mb-px flex space-x-6">
                <slot/>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
}
</script>
