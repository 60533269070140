<template>

    <newsletter-setup-guide :programId="programId"/>

    <card inset="none" class="mb-4" #body>
        <div class="grid grid-cols-1 sm:grid-cols-3">
            <div class="col-span-1 p-6 border-b sm:border-b-0">
                <div class="flex items-center gap-4">

                    <program-initials :value="program.initial" type="newsletter"></program-initials>

                    <div class="flex gap-0.5 flex-col">
                        <span class="block font-medium text-xs uppercase">{{ program.type }}</span>
                        <span class="block font-semibold">{{ program.name }}</span>
                    </div>
                </div>
            </div>
            <div class="col-span-2 p-6 flex items-center">
                <ul class="flex-1 grid gap-y-4 gap-x-4 text-sm grid-cols-3">
                    <li class="flex flex-col gap-1.5">
                        <div class="text-sm text-gray-500">ESP</div>
                        <div class="font-semibold text-sm">{{ program.esp_type_name }}</div>
                    </li>
                    <li class="flex flex-col gap-1.5">
                        <div class="text-sm text-gray-500">Rewards</div>
                        <div class="font-semibold text-sm">{{rewards_count}} rewards</div>
                    </li>
                    <li class="flex flex-col gap-1.5">
                        <div class="text-sm text-gray-500">Program created</div>
                        <div class="font-semibold text-sm">{{ program.created_at }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </card>

    <div class="mt-4">
        <div class="grid lg:grid-cols-3 gap-4">
            <white-card title="Subscribers" :value="total_subscribers"></white-card>
            <white-card title="Referrers" :value="total_referring"></white-card>
            <white-card title="Referred subscribers" :value="total_referred"></white-card>
        </div>
    </div>

</template>

<script>
export default {
    name: 'NewsletterProgramStats',
    props: {
        programId: {
            type: Number,
            required: true,
        },
        dataUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            errors: [],
            loading: false,
            timePeriod: 'total',
            program: {
                initial: '',
                type: '',
                name: '',
                esp_type_name: '',
                created_at: '',
                last_edited: '',
            },
            total_subscribers: 0,
            total_referring: 0,
            total_referred: 0,
            rewards_count: 0,
        }
    },
    created() {
        this.refreshData();
    },
    methods: {
        refreshData() {

            this.loading = true;

            axios
                .post(this.dataUrl, {
                    filter: {
                        program: this.programId
                    }
                })
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.program = response.data.data.program;
                        this.total_subscribers = response.data.data.total_subscribers;
                        this.total_referring = response.data.data.total_referring;
                        this.total_referred = response.data.data.total_referred;
                        this.rewards_count = response.data.data.rewards_count;


                    }
                })
                .catch(error => {
                    this.errors = error?.response?.data?.errors || [];
                })
                .finally(() => this.loading = false);
        },
        timePeriodChange() {
            this.refreshData();
        },
        getArrowClass(value) {
            return (value < 0) ? 'bg-red-100 text-red-500' : 'bg-teal-100 text-teal-600';
        },
        getIconName(value) {
            return (value < 0) ? 'arrow-down' : 'arrow-up';
        }
    }
}
</script>
