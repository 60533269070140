<template>
    <div class="rounded p-6 space-y-4"
         :class="[
            [types[type].style],
        ]">

        <div class="text-sm flex items-center">
            <span>{{ title }}</span>

            <select v-if="currency && currenciesCount > 1" @change="currencySelected" v-model="currencyUnit"
                    class="block border-0 focus:ring-0 leading-4 ml-auto rounded text-right text-2xs text-gray-500 bg-gray-100 py-0.5 pl-2 pr-6">
                <option
                    v-for="(amount, unit) in this.value"
                    :value="unit"
                >
                    {{ unit.toUpperCase() }}
                </option>
                <option value="">ALL</option>
            </select>

        </div>

        <div v-if="!showAllValues">
            <h3
                class="text-2xl font-semibold leading-tight flex items-baseline space-x-2"
                :class="{'xl:text-3xl': biggerTextOnLargeScreen}"
            >
                <div>{{ valueToShow }}<span v-if="showPercentage">%</span></div>
                <span v-if="this.growth < 0"
                      class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-red-100 bg-opacity-50 font-normal align-top tracking-tight text-red-500">
                    <svg class="inline w-2 h-2 text-red-600" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12h7l-8 8-8-8h7V4h2z"/></svg>
                    {{ this.growth }}%
                </span>
                <span v-else-if="this.growth >= 0"
                      class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-teal-100 bg-opacity-50 font-normal align-top tracking-tight text-teal-600">
                    <svg class="inline w-2 h-2 text-teal-600" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                        fill="none" d="M0 0h24v24H0z"/><path d="M13 12v8h-2v-8H4l8-8 8 8z"/></svg>
                    {{ this.growth }}%
                </span>
            </h3>
            <div v-if="extraValue"
                 class="text-sm font-normal"
                 :class="[extraTextStyleColor[extraTextColor]]"
            >
                {{ extraValueBasedOnCurrency ? (currenciesCount === 1 ? Object.values(extraValue)[0] : (extraValue[currencyUnit] || '')) : extraValue }} {{ extraTitle }}
            </div>
        </div>


        <div v-if="showAllValues && currency">
            <h3 class="text-2xl font-semibold leading-tight xl:text-3xl flex items-center gap-2">
                <span>
                    <div v-for="element in this.value">
                        {{ element }}
                    </div>
                </span>
            </h3>
            <div v-if="extraValue" class="text-sm font-normal" :class="[extraTextStyleColor[extraTextColor]]">
                {{ extraValueBasedOnCurrency ? Object.values(extraValue).join(', ') : extraValue }} {{ extraTitle }}
            </div>
        </div>

    </div>
</template>
<script>
export default {
    props: {
        title: '',
        value: '',
        extraTitle: '',
        extraValue: '',
        growth: '',
        type: {
            default: 'default',
        },
        currency: {
            default: false,
        },
        showPercentage: {
            default: false
        },
        biggerTextOnLargeScreen: {
            default: true
        },
        extraTextColor: {
            default: 'default'
        },
        extraValueBasedOnCurrency: {
            default: false
        },
    },
    data() {
        return {
            types: {
                default: {style: 'bg-white border text-gray-900'},
                teal: {style: 'bg-teal-500 text-white'},
            },
            extraTextStyleColor: {
                default: 'text-gray-500',
                teal: 'text-teal-500',
                white: 'text-white text-opacity-75',
            },
            currencyUnit: 'usd',
            showAllValues: false,
            valueToShow: this.value,
        }
    },
    methods: {
        currencySelected() {
            if (this.currencyUnit === '') {
                this.showAllValues = true
            } else if (this.currency && typeof this.value === 'object' && this.value !== null) {
                this.showAllValues = false
                this.valueToShow = this.value[this.currencyUnit];
            }
        },
        setValueToShow(newValue) {
            if(typeof newValue === 'undefined'){
                newValue = this.value
            }

            if (this.currenciesCount === 1) {
                this.valueToShow = Object.values(newValue)[0];
            } else if (this.currenciesCount > 1) {
                if (newValue.hasOwnProperty(this.currencyUnit)) {
                    this.currencySelected(this.currencyUnit);
                } else {
                    this.valueToShow = Object.values(newValue)[0];
                }
            } else {
                this.valueToShow = newValue
            }
        }
    },
    computed: {
        currenciesCount() {
            if (this.currency && typeof this.value === 'object' && this.value !== null) {
                return Object.keys(this.value).length;
            }
            return 0;
        },
    },
    watch: {
        value(newValue) {
            this.setValueToShow(newValue)
        },
    },
    created() {
        this.setValueToShow()
    }
}
</script>

<style scoped>

</style>
