<template>

    <h3 class="mb-4 text-lg font-semibold text-gray-900 mt-8">Insights</h3>

    <div class="flex items-center mb-5" v-if="hasData">
        <div class="grid gap-4 md:grid-cols-3 flex-1 xl:grid-cols-12">
            <div class="xl:col-span-6 relative inline-flex relative">
                <date-range-picker
                    class="flex-1"
                    :date_to="filter.date_range.to"
                    :date_from="filter.date_range.from"
                    @update="dateRangeUpdate"
                    :ranges="datepickerRanges"
                ></date-range-picker>
                <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none text-opacity-50">
                    <svg class="w-4 h-4" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M2 11h20v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-9zm15-8h4a1 1 0 0 1 1 1v5H2V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2z"/>
                    </svg>
                </div>
            </div>
            <div class="xl:col-span-6">
                <div class="flex">
                    <btn class="flex-grow rounded-r-none" :type="btnTypes.yearly"
                         @click="setGroupType('yearly')">Yearly
                    </btn>
                    <btn class="flex-grow rounded-r-none rounded-l-none border-l-0 border-r-0" :type="btnTypes.monthly"
                         @click="setGroupType('monthly')">Monthly
                    </btn>
                    <btn class="flex-grow rounded-l-none" :type="btnTypes.daily"
                         @click="setGroupType('daily')">Daily
                    </btn>
                </div>
            </div>
        </div>
    </div>

    <div class="space-y-8">
        <div class="p-8 bg-white border rounded">
            <div class="text-md font-semibold text-gray-900 border-b pb-4 w-1/6">Payouts</div>
            <div>
                <apexchart v-if="payoutsChart" :width="payoutsChart.width" :height="payoutsChart.height"
                           :type="payoutsChart.type" :options="payoutsChart.options"
                           :series="payoutsChart.series"></apexchart>
            </div>
        </div>
    </div>

</template>

<script>
import moment from 'moment-timezone';
import { formatNumber} from "../../../../../helpers/common";

export default {
    name: 'PayoutInsights',
    props: {
        program: {
            type: [String, Number],
            required: true,
        }
    },
    data () {
        return {
            errors: [],
            loading: false,
            hasData: true,
            payoutsChart: false,
            chartStroke: {
                'curve' : 'smooth',
                width: 2
            },
            yAxisLabelFormat: {
                labels: {
                    formatter: (value) => formatNumber(value)
                }
            },
            filter: {
                group_type: 'monthly',
                date_range: {
                    from: moment.utc().startOf('year'),
                    to: moment()
                }
            },
            btnTypes: {
                yearly: 'secondary',
                monthly: 'secondary-green',
                daily: 'secondary'
            },
            datepickerRanges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'Current Year': [moment.utc().startOf('year'), moment()]
            },
            currencyArray: [
                {
                    name: 'USD',
                    symbol: '$'
                },
                {
                    name: 'EUR',
                    symbol: '€'
                }
            ]
        }
    },
    created() {
        if (this.hasData) {
            this.refreshData();
        }
    },
    methods: {
        formatNumber,
        refreshData() {

            let _this = this;
            this.loading = true;

            axios
                .post(route('app_int.views.payouts.payout_insights'), {filter: this.filter, program: this.program})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.payoutsChart = response.data.payoutsChart;

                        if (response.data.currencyList && response.data.currencyList.length > 0) {
                            this.currencyArray = response.data.currencyList;
                        }

                        this.payoutsChart.options.stroke = this.chartStroke;
                        this.payoutsChart.options.yaxis = this.yAxisLabelFormat;
                        this.payoutsChart.options.tooltip = {
                            y : {
                                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                                    return _this.getCurrencyFromSeriesTitle(seriesIndex)
                                        + formatNumber(value);
                                }
                            }
                        };
                    }
                })
                .catch(error => {
                    this.errors = error?.response?.data?.errors || [];
                })
                .finally(() => this.loading = false);
        },
        setGroupType(groupType) {
            this.filter.group_type = groupType;

            this.btnTypes = {
                yearly: 'secondary',
                monthly: 'secondary',
                daily: 'secondary'
            }
            this.btnTypes[groupType] = 'secondary-green';

            this.refreshData();
        },
        dateRangeUpdate(ev, picker) {
            this.filter.date_range.from = picker.startDate.format('YYYY-MM-DD');
            this.filter.date_range.to = picker.endDate.format('YYYY-MM-DD');

            this.refreshData();
        },
        getCurrencyFromSeriesTitle(seriesIndex) {
            let symbol = '';
            this.currencyArray.forEach((currency) => {
                if(this.payoutsChart.series[seriesIndex].name.includes(currency.name)) {
                    symbol = currency.symbol;
                }
            });
            return symbol;
        }
    }
}
</script>
<style type="text/css">
    .daterangepicker {
        color: black;
    }
</style>
