import {defineStore} from "pinia";
import {reactive} from "vue";

export let useCouponStore = defineStore('coupon', {
    state: () => ({
        formData: {
            id: '',
            name: '',
            uuid_code: '',
            coupon_discount_type: 'percent',
            coupon_discount_amount: '',
            coupon_duration_type: 'lifetime',
            coupon_duration_value: '',
            redemption_specific_date_status: 'off',
            redemption_specific_date_value: '',
            redemption_times_status: 'off',
            redemption_times_value: '',
            is_reward_coupon: false,
            metadata: []
        },
        syncProvider: '',
        promotionCodes: [],
        autoAssignPromotionCode: {
            auto_generate_promotion_code: false,
            auto_generate_promotion_code_name_fields: [],
            auto_generate_promotion_code_name_random_characters_count: 2,
            auto_generate_promotion_code_name_custom_prefix: '',
            edit: true,
            active: true,
            first_time_order: false,
            minimum_order_status: '0',
            minimum_order_value: '',
            expiration_date_status: '0',
            expiration_date_value: '',
            redemption_times_status: '0',
            redemption_times_value: '',
            is_reward_promotion_code: false,
            metadata: [],
        },
        // when creating a coupon from rewards, we want
        // only reward coupons to be created, so we have a possibility not to give a choice to the user
        showRewardCouponAsOption: true,
        notification: {
            showNotification: false,
            notificationTitle: 'Error',
            notificationType: 'warning',
            notificationMessage: 'Updating error, see console for error details.'
        },
    }),

    actions: {
        resetForm() {
            this.formData = {
                id: '',
                name: '',
                uuid_code: '',
                coupon_discount_type: 'percent',
                coupon_discount_amount: '',
                coupon_duration_type: 'lifetime',
                coupon_duration_value: '',
                redemption_specific_date_status: 'off',
                redemption_specific_date_value: '',
                redemption_times_status: 'off',
                redemption_times_value: '',
                is_reward_coupon: false,
                metadata: []
            };
            this.promotionCodes = [];
            this.autoAssignPromotionCode = {
                auto_generate_promotion_code: false,
                auto_generate_promotion_code_name_fields: [],
                auto_generate_promotion_code_name_custom_prefix: '',
                edit: true,
                active: true,
                first_time_order: false,
                minimum_order_status: '0',
                minimum_order_value: '',
                expiration_date_status: '0',
                expiration_date_value: '',
                redemption_times_status: '0',
                redemption_times_value: '',
                is_reward_promotion_code: false,
                metadata: [],
            }
        },
        fillFormDataFromCouponData(couponData) {
            this.formData.id = couponData.id ?? '',
            this.formData.name = couponData.name ?? '',
            this.formData.uuid_code = couponData.uuid_code ?? '',
            this.formData.coupon_discount_type = couponData.coupon_discount_type ?? 'percent',
            this.formData.coupon_discount_amount = couponData.coupon_discount_amount ?? '',
            this.formData.coupon_duration_type = couponData.coupon_duration_type ?? 'lifetime',
            this.formData.coupon_duration_value = couponData.coupon_duration_value ?? '',
            this.formData.redemption_specific_date_status = couponData.redemption_specific_date_status ?? 'off',
            this.formData.redemption_specific_date_value = couponData.redemption_specific_date_value ?? '',
            this.formData.redemption_times_status = couponData.redemption_times_status ?? 'off',
            this.formData.redemption_times_value = couponData.redemption_times_value ?? '',
            this.formData.is_reward_coupon = couponData.is_reward_coupon ?? false,
            this.formData.metadata = couponData.metadata ?? []
            this.promotionCodes = couponData.promotion_codes ?? []
            this.autoAssignPromotionCode = couponData.auto_assign_promotion_code ?? {
                auto_generate_promotion_code: false,
                auto_generate_promotion_code_name_fields: [],
                auto_generate_promotion_code_name_custom_prefix: '',
                edit: true,
                active: true,
                first_time_order: false,
                minimum_order_status: '0',
                minimum_order_value: '',
                expiration_date_status: '0',
                expiration_date_value: '',
                redemption_times_status: '0',
                redemption_times_value: '',
                is_reward_promotion_code: false,
                metadata: [],
            }
        },
        setShowRewardCouponAsOption(value) {
            this.showRewardCouponAsOption = value
        },
        showNotification(show, title, type, message) {
            this.notification.showNotification = show;
            this.notification.notificationTitle = title;
            this.notification.notificationType = type;
            this.notification.notificationMessage = message;
        }
    },

    getters: {
        getFormValue: (state) => (key) => state.formData[key],
        getFormMetadata: (state) => (key) => state.formData.metadata[key],
        getFormData: (state) => state.formData,
        getPromotionCodes: (state) => state.promotionCodes,
        getAutoAssignPromotionCode: (state) => state.autoAssignPromotionCode,
        getIsAutogenerateRewardCoupon: (state) => state.formData.is_reward_coupon,
        getShowRewardCouponAsOption: (state) => state.showRewardCouponAsOption
    }
})
