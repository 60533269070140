<template>

    <div class="space-y-6">

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>

            <div class="space-y-2">
                <div class="text-sm space-y-2">
                    <p>Follow these steps to insert Partnero Universal into your site:</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Log in to your account</li>
                        <li>Select your website</li>
                        <li>Go to the <b>Design Settings</b> -> <b>Custom CSS / HEAD</b></li>
                        <li>Paste the script inside the <b>Additional HEAD Code</b> section</li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sign-ups and sales tracking</h3>
                <div class="text-sm space-y-2">
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Go to the <b>Widget Manager</b></li>
                        <li>Find widget <b>Bootstrap Theme - Conversion Tracking Codes</b></li>
                        <li>Click <b>Edit</b></li>
                        <li>In HTML section find a text <i>Enter Conversion Tracking Codes To Track Sign Ups & Upgraded Members Below ******* --></i></li>
                        <li>Paste the following script below:</li>
                    </ol>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-2').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-2" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs" v-pre><script>
    po('transactions', 'create', {
        data: {
            key: 'transaction_&#60;?php echo $conversion_tracking['user_id']; ?&#62;',
            amount: &#60;?php echo $conversion_tracking['amount']; ?&#62;,
            customer: {
                key: '&#60;?php echo $conversion_tracking['email']; ?&#62;',
                name: '&#60;?php echo $conversion_tracking['full_name']; ?&#62;',
                email: '&#60;?php echo $conversion_tracking['email']; ?&#62;'
            }
        },
        options: {
            create_customer: true
        }
    });
</script></textarea>
            </div>

            <div class="text-sm space-y-2">
               <div> In order to track recurring payments Stripe integration must be enabled.</div>
            </div>

        </div>

        <alert type="warning"><b>Please note:</b> When using this integration method, transaction creation via JavaScript (JS) calls must be enabled to ensure successful tracking.</alert>

    </div>

</template>
