<script setup>
import {computed, reactive, ref} from "vue";
import CouponDuration from "./settings/CouponDuration.vue";
import RedemptionSpecificDatePeriod from "./settings/RedemptionSpecificDatePeriod.vue";
import RedemptionTimesPeriod from "./settings/RedemptionTimesPeriod.vue";
import Btn from "../../../../elements/Btn.vue";
import Notification from "../../../../elements/Notification.vue";
import Spinner from "../../../../elements/Spinner.vue";
import Icon from "../../../../elements/Icon.vue";
import TextField from "../../../../elements/forms/TextField.vue";
import FormSection from "../../../../elements/forms/FormSection.vue";

const props = defineProps(
        {
            couponData: [String, Object, null],
            couponAction: [String],
            program: Object,
            syncProvider: [String, null],
            csrf: String,
        }
    )

    const editing = props.couponAction === 'edit';

    const notification = reactive({
        showNotification: false,
        notificationTitle: 'Error',
        notificationType: 'neutral',
        notificationMessage: 'Updating coupon data...'
    });

    const updatingCoupon = ref(false);

    const formData = ref({
        sync_provider: props.syncProvider ?? null,
        id: props.couponData.id ?? '',
        name: props.couponData.name ?? '',
        uuid_code: props.couponData.uuid_code ?? '',
        coupon_discount_type: props.couponData.coupon_discount_type ?? 'percent',
        coupon_discount_amount: props.couponData.coupon_discount_amount ?? '',
        coupon_duration_type: props.couponData.coupon_duration_type ?? 'lifetime',
        coupon_duration_value: props.couponData.coupon_duration_value ?? '',
        redemption_specific_date_status: props.couponData.redemption_specific_date_status ?? 'off',
        redemption_specific_date_value: props.couponData.redemption_specific_date_value ?? '',
        redemption_times_status: props.couponData.redemption_times_status ?? 'off',
        redemption_times_value: props.couponData.redemption_times_value ?? '',
        is_reward_coupon: props.couponData.is_reward_coupon ?? false,
        metadata: props.couponData.metadata ?? []
    })

    const jsonMetadata = computed(() => {
        return JSON.stringify(formData.value.metadata)
    })

    const addMetadata = () => {
        formData.value.metadata.push({ key: '', value: '' });
    }

    const removeMetadata = (metadataIndex) => {
        formData.value.metadata.splice(metadataIndex, 1);
    }

    function showNotification(show, title, type, message) {
        notification.showNotification = show;
        notification.notificationTitle = title;
        notification.notificationType = type;
        notification.notificationMessage = message ?? notification.notificationMessage;
    }

    const updateCouponData = () => {
        updatingCoupon.value = true;
        axios.patch(route('app_int.coupon.update', {program: props.program, coupon: formData.value.id}), {
            id: formData.value.id,
            name: formData.value.name,
            metadata: formData.value.metadata,
        }).then(response => {
            const status = parseInt(response.data.status || '-1');
            if (status === 1) {
                showNotification(true, 'Done', 'success', response.data?.message ?? 'Coupon updated successfully');
            } else {
                showNotification(true, 'Error', 'warning', response.data?.message ?? 'Something went wrong')
            }
        }).catch(error => {
            showNotification(true, 'Error', 'warning', error.response?.data?.message ?? 'Something went wrong');
            console.log(error)
        }).finally(() => {
            updatingCoupon.value = false;
        })
    }

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<template>
    <div>
        <notification
            :show="notification.showNotification"
            :title="notification.notificationTitle"
            :type="notification.notificationType"
            class="mb-20"
            @notificationClosed="notification.showNotification = false"
            whitespace="whitespace-normal"
        >
            <template #message>
                {{ notification.notificationMessage }}
            </template>
        </notification>
        <card :bordered="false">
            <template #body>
                <input type="hidden" name="_token" :value="csrf"/>
                <input type="hidden" name="id" v-model="formData.id"/>
                <input type="hidden" name="sync_provider" v-model="formData.sync_provider"/>
                <form-layout>

                    <form-section title="Coupon" description="Set up the main details of the coupon.">
                        <text-field
                            label="Coupon name"
                            field-id="name"
                            name="name"
                            type="text"
                            autofocus="true"
                            v-model="formData.name"
                            placeholder="E.g. First purchase discount"
                        >
                            <template #note>
                                The name of the coupon can be displayed to your customers through your application.
                            </template>
                        </text-field>
                        <text-field
                            label="Internal name"
                            field-id="uuid_code"
                            name="uuid_code"
                            type="text"
                            v-model="formData.uuid_code"
                            :disabled="editing"
                        >
                            <template #note>
                                This will identify the coupon internally within Partnero or Partnero API. You can leave this blank, and we will generate a UUID for you.
                            </template>
                        </text-field>
                    </form-section>

                    <form-section
                        title="Coupon type"
                        description="Determine whether the coupon provides a fixed amount or a percentage discount."
                    >
                        <div class="grid grid-cols-2 gap-8">
                            <div class="col-span-2">
                                <radio-group
                                    label="Coupon type"
                                    name="coupon_discount_type"
                                    v-model="formData.coupon_discount_type"
                                    :options="[
                                        { value: 'percent', label: 'Percentage', description: 'Add coupon discount with a percentage amount.', disabled: editing},
                                        { value: 'static', label: 'Static', description: 'Add coupon discount with a fixed amount.', disabled: editing},
                                    ]"
                                    layout="horizontal"
                                />
                            </div>
                            <div>
                                <text-field
                                    label="Discount amount"
                                    field-id="coupon_discount_type"
                                    name="coupon_discount_amount"
                                    type="text"
                                    :placeholder="formData.coupon_discount_type === 'percent' ? 'e.g. 10' : 'e.g. 10.00'"
                                    :prepend="formData.coupon_discount_type === 'percent' ? '%' : null"
                                    v-model="formData.coupon_discount_amount"
                                    :disabled="editing"
                                />
                            </div>
                        </div>
                    </form-section>

                    <form-section title="Coupon duration" description="Set up for how long the coupon is valid for.">
                        <div class="grid grid-cols-2 gap-8">
                            <coupon-duration
                                name="Coupon duration"
                                description="For subscriptions and customers, this determines how long this coupon will apply once redeemed."
                                select_name="coupon_duration_type"
                                input_name="coupon_duration_value"
                                :coupon_duration_type="formData.coupon_duration_type"
                                :coupon_duration_value="formData.coupon_duration_value"
                                :disabled="editing"
                            />
                        </div>
                    </form-section>

                    <form-section
                        title="Redemption limits"
                        description="Fine-tune the availability of the coupon."
                    >
                        <div class="grid grid-cols-2 gap-8">
                            <redemption-specific-date-period
                                id="spec-date-period-coupon"
                                name="Specific date"
                                description="Limit the date range when customers can redeem this coupon."
                                select_name="redemption_specific_date_status"
                                input_name="redemption_specific_date_value"
                                v-model:type="formData.redemption_specific_date_status"
                                v-model:redemption_value="formData.redemption_specific_date_value"
                                :disabled="editing"
                            />
                            <redemption-times-period
                                name="Times"
                                description="Limit the total number of times this coupon can be redeemed."
                                select_name="redemption_times_status"
                                input_name="redemption_times_value"
                                v-model:type="formData.redemption_times_status"
                                v-model:redemption_value="formData.redemption_times_value"
                                :disabled="editing"
                            />
                        </div>
                    </form-section>

                    <form-section v-if="program?.program_type?.type === 'refer_a_friend'"
                                  title="Reward coupon"
                                  description="If you would like to use this coupon as a reward coupon, enable this option."
                    >
                        <toggle
                            v-model="formData.is_reward_coupon"
                            name="is_reward_coupon"
                            title="Dynamic coupon"
                            :true-value="true"
                            :disabled="editing"
                        >
                            <template #description>
                                If enabled, customer promotion codes will be generated on the promotion code you provided with the coupon.
                            </template>
                        </toggle>
                    </form-section>

                    <form-section title="Metadata"
                                  description="Organize and store additional information about a coupon through metadata, a set of key-value pairs linked to it."
                    >
                        <div v-for="(metadata, metadataIndex) in formData.metadata"
                             :key="'coupon-metadata-' + metadataIndex"
                             class="grid grid-cols-5 gap-4 mb-2"
                        >
                            <text-field
                                class="md:col-span-2"
                                type="text"
                                autofocus="true"
                                v-model="metadata.key"
                                placeholder="Key"
                            >
                            </text-field>
                            <text-field
                                class="col-span-3"
                                type="text"
                                autofocus="true"
                                v-model="metadata.value"
                                placeholder="Value"
                            >
                                <template #append>
                                    <div class="inline-flex items-center pl-2 text-gray-500 cursor-pointer">
                                        <icon
                                            name="trash"
                                            size="18"
                                            @click="removeMetadata(metadataIndex)"
                                        />
                                    </div>
                                </template>
                            </text-field>
                        </div>
                        <input type="text" hidden name="metadata" v-model="jsonMetadata">
                        <div>
                            <btn type="secondary"
                                 icon-name="add-circle"
                                 @click="addMetadata()"
                                 size="small"
                            >
                                Add metadata
                            </btn>
                        </div>
                    </form-section>

                </form-layout>
            </template>
            <template #footer>
                <btn v-if="couponAction === 'edit'"
                     @click="updateCouponData"
                     :disabled="updatingCoupon"
                >
                    <spinner
                        v-if="updatingCoupon"
                        :is-white="true"
                        class="mr-1.5"
                    />
                    {{ updatingCoupon ? 'Updating...' : 'Update' }}
                </btn>
            </template>
        </card>
    </div>
</template>

