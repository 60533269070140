<template>
    <span
        class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4"
        :class="[
            [types[type].style],
        ]"
    >
        {{ title }}
    </span>
</template>

<script>
    export default {
        props: {
            title: '',
            type: {
                default: 'success',
            },
        },
        data() {
            return {
                types: {
                    success: { style: 'bg-teal-100 text-teal-800'},
                    green: { style: 'bg-green-500 text-white'},
                    warning: { style: 'bg-orange-100 text-orange-800'},
                    neutral: { style: 'bg-gray-100 text-gray-800'},
                    shopifyGreen: { style: 'bg-green-500 text-white'},
                    stripeIndigo: { style: 'bg-indigo-500 text-white'},
                    wooPurple: { style: 'bg-purple-50 text-white'},
                    error: { style: 'bg-red-100 text-red-800'},
                },
            }
        },
    }
</script>

<style scoped>

</style>
