<template>
    <div class="bg-gradient-to-tr from-white via-gray-50 to-teal-50 border-l-4 border-teal-600 rounded">
        <div class="border border-l-0 rounded rounded-l-none px-10 py-12">
            <div class="space-y-6">
                <div class="flex items-center justify-between">
                    <div>
                        <h3 class="tracking-normal font-sans text-xl font-semibold leading-snug text-gray-900 mb-2">{{ title }}</h3>
                        <div class="text-base font-light leading-relaxed text-inherit font-normal text-gray-500">{{ description }}</div>
                    </div>
                    <a href="#" class="rounded hover:bg-gray-100" @click="toggleAccordion=!toggleAccordion">
                        <icon :name="toggleAccordion ? 'arrow-up-s' : 'arrow-down-s'" size="24"></icon>
                    </a>
                </div>
                <slot name="accordionHeader"></slot>
            </div>
            <div class="space-y-12" v-show="toggleAccordion">
                <hr class="mt-12"/>
                <slot name="accordionContent"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'content-accordion',
  props: {
    title: '',
    description: '',
    expanded: false,
  },
  data() {
    return {
      toggleAccordion: this.expanded ?? false
    }
  }
}
</script>
