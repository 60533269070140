<template>
    <modal name="delete_commission" title="Please confirm" size="small">
        <template #toggle>
            <options-item title="Delete"></options-item>
        </template>
        <template #body>
            <div class="space-y-6 text-left">
                <div class="text-sm text-gray-500">Do you really want to delete this reward?</div>
                <toggle v-if="hasDynamicCoupon"
                        title="Delete linked coupons with promotion codes"
                        v-model="deleteCouponAssociatedWithReward"
                />
            </div>
        </template>
        <template #footer>
            <btn type="primary" @click="deleteCommission" :loading="loading.deleting">
                Delete
            </btn>
        </template>
    </modal>
</template>
<script>

export default {
    name: 'DeleteCommission',
    props: ['program', 'commission', 'hasDynamicCoupon'],
    data() {
        return {
            loading: {
                deleting: false
            },
            deleteCouponAssociatedWithReward: false
        }
    },
    methods: {
        deleteCommission() {

            if (this.loading.deleting) {
                return;
            }

            this.loading.deleting = true;

            axios.post(route('app_int.commission.delete', [this.program, this.commission]), {
                deleteCouponAssociatedWithReward: this.deleteCouponAssociatedWithReward
            })
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'delete_commission')
                        window.location.reload();
                    }
                })
                .finally(() => {
                    this.loading.deleting = false;
                });

        }
    }
}
</script>
<style scoped>
</style>
