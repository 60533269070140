<template>
    <img
        v-if="avatarDetails.image_url"
        class="rounded-full"
        :class="avatarDetails.html_class"
        :src="avatarDetails.image_url"
        :alt="avatarDetails.initials"
    >
    <div
        v-else-if="avatarDetails.initials"
        class=" rounded-full flex items-center justify-center font-medium text-gray-500 bg-gray-200"
        :class=" avatarDetails.html_class || 'w-10 h-10 text-sm'"
    >
        {{ avatarDetails.initials }}
    </div>
    <div
        v-else
        class="rounded-full flex items-center justify-center font-medium text-gray-500 bg-gray-200"
        :class=" avatarDetails.html_class || 'w-10 h-10 text-sm'"
    >
        <icon name="user"></icon>
    </div>
</template>

<script>
export default {
    name: "Avatar",
    props: {
        avatarDetails: {
            type: Object,
            required: true
        }
    }
}
</script>
