<script setup>
    import {ref} from "vue";

    const props = defineProps({
        countryOptions: {
            type: Array,
            default: () => []
        },
        value: {
            type: String
        },
    })

    const selectedCountry = ref(props.value)

</script>

<template>
    <select-field
        name="country"
        label="Country"
        v-model="selectedCountry"
    >
        <option v-for="country in countryOptions"
                :key="'acc-prof-country-' + country.value"
                :value="country.value"
        >
            {{ country.name }}
        </option>
    </select-field>
</template>
