<script setup>

    import {ref} from "vue";

    const props = defineProps({
        providerName: {
            type: String,
            default: 'ESP'
        }
    });

    const deleteExistingSubscribersFromPartnero = ref(true);
    const reSync = ref('1');
    const updating = ref(false);

</script>

<template>
    <modal title="Update settings?" size="small" ref="modal">
        <template #toggle>
            <btn>Update</btn>
        </template>
        <template #body>
            <div class="space-y-6 text-left">
                <div class="text-sm text-gray-500">
                    Settings have been changed. Do you want to re-sync data with third-party ESP?
                </div>
                <radio-group-inline
                    name="re-sync"
                    id="re-sync"
                    v-model="reSync"
                    :options="[ { value: '1', label: 'Yes, re-sync', description: ''}, { value: '0', label: 'Ignore', description: ''} ]">
                </radio-group-inline>
                <div v-if="reSync === '1'" class="mt-3">
                    <toggle v-model="deleteExistingSubscribersFromPartnero"
                            class="text-left"
                            name="delete_existing_subscribers_from_partnero"
                            title="Delete subscribers from Partnero"
                    >
                        <template #description>
                            If enabled, all existing subscribers will be deleted from Partnero before re-sync. This action is
                            irreversible from Partnero side but the subscribers will remain in your {{ providerName }} account
                            and you can re-import them at any time.
                        </template>
                    </toggle>
                </div>
            </div>
        </template>
        <template #footer>
            <btn button-type="submit"
                 type="primary"
                 @click="updating = true"
                 :loading="updating"
            >
                Update
            </btn>
        </template>
    </modal>
</template>
