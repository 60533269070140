<template>
    <div class="bg-white rounded border p-4" v-if="rewards.length < 1">
        <div class="flex justify-between items-center">
            <div class="text-sm text-gray-900">
                Create your first reward.
            </div>
            <div>
                <btn type="secondary" size="small" @click="addReward">Create a reward</btn>
            </div>
        </div>
    </div>

    <template v-if="rewards.length > 0">

        <div class="bg-white rounded border" v-for="(reward, index) in rewards">
            <div class="flex justify-between items-center p-4">
                <div class="flex items-center gap-4">
                    <icon name="gift" size="24"></icon>
                    <div class="text-sm text-gray-900">{{ reward.name }}</div>
                </div>
                <div class="flex items-center gap-2">
                    <button type="button"
                            class="rounded border hover:bg-gray-50 hover:bg-opacity-75 p-2 focus:outline-none group-hover:text-white"
                            @click="deleteReward(index)">
                        <icon size="14" name="delete"></icon>
                    </button>
                    <button type="button"
                            class="rounded border hover:bg-gray-50 hover:bg-opacity-75 p-2 focus:outline-none"
                            @click="toggleEdit(index)">
                        <icon size="14" name="settings" v-if="!reward._edit"></icon>
                        <icon size="14" name="check-line" v-if="reward._edit"></icon>
                    </button>
                </div>
            </div>
            <div class="bg-gray-50 bg-opacity-75 border-t p-6" v-if="reward._edit">
                <div class="space-y-4">
                    <text-field
                        label="Reward name"
                        name="reward_name"
                        v-model="reward.name"
                        type="text">
                        <template #note>
                            Create a name for your reward.
                        </template>
                    </text-field>
                    <text-field
                        label="Reward description"
                        name="reward_description"
                        v-model="reward.description"
                        type="text">
                        <template #note>
                            Briefly describe what subscribers can get from this reward.
                        </template>
                    </text-field>
                    <text-field
                        label="How many subscribers would you like to collect?"
                        name="subscribers_number"
                        type="number"
                        v-model="reward.subscribers_count"
                        class="w-1/2">
                        <template #note>
                            Subscribers needed to get this reward.
                        </template>
                    </text-field>

                    <div>
                        <label for="format" class="block text-sm mb-1.5">What would you like to offer?</label>
                        <select name="time_format" class="input" v-model="reward.fulfillment_type" @change="handleRewardSelectChange($event.target.value)">
                            <option value="url_link">Text or Link/PDF download</option>
                            <option value="generic_coupon">Generic coupon</option>
                            <option value="amazon_gift_card" :disabled="!hasAmazonGiftCard">{{ amazonGiftCardText }}
                            </option>
                            <option value="stripe_coupon" :disabled="!stripe_integration">{{ stripeCouponText }}</option>
                        </select>

                    </div>

                    <text-field v-if="reward.fulfillment_type !== 'amazon_gift_card' && reward.fulfillment_type !== 'stripe_coupon'"
                                label="Fulfillment"
                                name="fulfillment"
                                v-model="reward.fulfillment_value"
                                type="text">
                        <template #note>
                            <span v-if="reward.fulfillment_type == 'url_link'">
                                Provide a reward for the referring subscriber. You can enter a URL, description, or other relevant details.
                            </span>
                            <span v-if="reward.fulfillment_type == 'generic_coupon'">
                                Provide a coupon or promotion code that will be sent to the referring subscriber.
                            </span>
                        </template>
                    </text-field>

                    <text-field v-if="reward.fulfillment_type === 'amazon_gift_card'"
                                label="Gift Card Amount"
                                name="fulfillment"
                                v-model="reward.fulfillment_value"
                                type="text">
                    </text-field>

                    <div v-if="reward.fulfillment_type === 'stripe_coupon'"
                         class="grid grid-cols-3 space-x-2 items-center"
                    >
                        <VueMultiselect
                            id="nrp-reward-coupon-select"
                            class="col-span-2"
                            placeholder="Search for a coupon"
                            :options="couponsList"
                            track-by="uuid_code"
                            label="name"
                            v-model="reward.fulfillment_value"
                            :multiple="false"
                            :searchable="true"
                            :loading="isLoadingCoupons"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="1"
                            :limit-text="limitText"
                            :max-height="600"
                            :show-no-results="true"
                            @search-change="doSearch($event)"
                        ></VueMultiselect>
                        <CouponModal v-if="reward.fulfillment_type === 'stripe_coupon' && !reward.fulfillment_value"
                                     :program="program"
                                     :coupon-data="rewardStripeCouponData"
                                     :coupon-sync-providers="['stripe']"
                                     :sync-provider="'stripe'"
                                     coupon-action="create"
                                     disable-in-app-coupon
                                     @coupon-created="handleRewardStripeSelectedCoupon($event, index)"
                                     id="reward-stripe-coupon-modal"
                        >
                        </CouponModal>
                    </div>

                </div>
            </div>
        </div>
    </template>
    <input type="hidden" name="rewards" v-model="rewardsValue"/>
    <btn @click="addReward" v-if="rewards.length > 0" size="small" type="secondary" icon-name="add-circle">Add a
        reward
    </btn>
</template>
<script>
import CouponModal from "../coupons/CouponModal.vue";
import VueMultiselect from "vue-multiselect";
import {onMounted, ref} from "vue";
import {useCouponStore} from "../../../../stores/app/programs/common/coupons/CouponStore";

export default {
    name: 'NewsletterProgramCommissionSettings',
    components: {CouponModal, VueMultiselect},
    props: ['program_rewards', 'has_amazon_gift_card', 'stripe_integration', 'program', 'reward_stripe_coupon_data'],
    data() {
        return {
            rewards: this.program_rewards,
            hasAmazonGiftCard: !!this.has_amazon_gift_card,
            couponsList: [],
            rewardStripeSelectedCoupon: null,
            isLoadingCoupons: false,
            delayTimer: null,
        }
    },
    setup(props) {

        const rewardStripeCouponData = ref(props.reward_stripe_coupon_data)
        const {setShowRewardCouponAsOption} = useCouponStore()

        onMounted(() => {
            rewardStripeCouponData.value.is_reward_coupon = true
            if (rewardStripeCouponData.value.auto_assign_promotion_code) {
                rewardStripeCouponData.value.auto_assign_promotion_code.is_reward_promotion_code = true
            }
            setShowRewardCouponAsOption(false)
        })

        return {
            rewardStripeCouponData
        }
    },
    methods: {
        toggleEdit(index) {
            this.rewards[index]._edit = !(this.rewards[index]._edit);
        },
        deleteReward(index) {
            this.rewards.splice(index, 1);
        },
        addReward() {
            this.rewards.push(
                {
                    id: null,
                    type: 'default',
                    name: '',
                    description: '',
                    subscribers_count: 1,
                    fulfillment_type: 'url_link',
                    fulfillment_value: '',
                    _edit: true
                }
            );
        },
        handleRewardStripeSelectedCoupon(coupon, rewardIndex) {
            this.couponsList.push(coupon);
            this.rewardStripeSelectedCoupon = coupon;
            this.rewards[rewardIndex].fulfillment_value = {
                id: coupon.id,
                name: coupon.name,
                uuid_code: coupon.uuid_code
            }
        },
        handleRewardSelectChange(value) {
            if (value === 'stripe_coupon') {
                this.doSearch('');
            }
        },
        doSearch(query) {
            clearTimeout(this.delayTimer);
            this.delayTimer = setTimeout(() => {
                this.isLoadingCoupons = true;
                axios.get(route('app_int.coupon.search', {program: this.program}) + '?search=' + query, {
                    params: {
                        search_only_reward_coupons: true
                    }
                })
                    .then(response => {
                        this.isLoadingCoupons = false;
                        this.couponsList = response.data;
                    })
                    .catch(error => {
                        this.isLoadingCoupons = false;
                    })
                    .finally(() => {
                        this.isLoadingCoupons = false;
                    });
            }, 700);
        }
    },
    computed: {
        rewardsValue() {
            return JSON.stringify(this.rewards);
        },
        amazonGiftCardText() {
            return this.hasAmazonGiftCard ? 'Amazon Gift Card' : 'Amazon Gift Card (not connected)';
        },
        stripeCouponText() {
            return this.stripe_integration ? 'Stripe Coupon' : 'Stripe Coupon (not connected)';
        }
    }
}
</script>
<style scoped>
</style>
