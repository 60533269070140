import {defineStore} from "pinia";

export let useEspSettingsStore = defineStore('updateNewsletterProgram', {
    state: () => ({
        groups: null,
        group_export: null,
        group_import: null,
        lists: null,
        list_export: null,
        list_import: null,
        tags: null,
        tag_export: null,
        tag_import: null,
    }),

    actions: {
        setGroups(groups) {
            this.groups = groups;
        },
        setGroupExport(group_export) {
            this.group_export = group_export;
        },
        setGroupImport(group_import) {
            this.group_import = group_import;
        },
        setLists(lists) {
            this.lists = lists;
        },
        setListExport(list_export) {
            this.list_export = list_export;
        },
        setListImport(list_import) {
            this.list_import = list_import;
        },
        setTags(tags) {
            this.tags = tags;
        },
        setTagExport(tag_export) {
            this.tag_export = tag_export;
        },
        setTagImport(tag_import) {
            this.tag_import = tag_import;
        },
    },

    getters: {
        getGroups: (state) => state.groups,
        getGroupExport: (state) => state.group_export,
        getGroupImport: (state) => state.group_import,
        getLists: (state) => state.lists,
        getListExport: (state) => state.list_export,
        getListImport: (state) => state.list_import,
        getTags: (state) => state.tags,
        getTagExport: (state) => state.tag_export,
        getTagImport: (state) => state.tag_import,
    },
});
