<template>
    <div class="flex justify-between items-center px-10 py-10 space-x-8">
        <div class="space-y-2">
            <h3 class="text-lg font-medium">Duplicate program</h3>
            <p class="text-sm text-gray-500">Create a new program by duplicating this one. All settings and information
                will be copied to the new program.</p>
        </div>
        <div>
            <modal :title="'Are you sure?'" size="small">
                <template #toggle>
                    <btn type="light">Duplicate</btn>
                </template>
                <template #body>
                    <div class="space-y-6">
                        <div class="text-sm text-gray-500">
                            Do you really want to duplicate this program?<br/>
                            A new program will be created.
                        </div>
                    </div>
                </template>
                <template #footer>
                    <btn @click="duplicateProgram" type="primary">Yes, duplicate</btn>
                </template>
            </modal>
        </div>
    </div>
</template>
<script>
export default {
    name: "ProgramDuplicate",
    props: ['program'],
    methods: {
        duplicateProgram: function () {
            window.location.href = route('programs.duplicate', this.program);
        }
    }
}
</script>

<style scoped>

</style>
