<template>
    <div class="space-y-4">
        <div v-if="goalCommissions.length === 0" class="text-sm text-gray-500 bg-gray-100 rounded py-4 px-4">
            Create a goal or target-based reward for a partner.
        </div>
        <div class="grid grid-cols-6 flex gap-4 border rounded px-4 py-4" v-for="(goalCommission, index) in goalCommissions">

            <div class="flex relative rounded col-span-2">
                <input
                    type="text"
                    v-model="goalCommission.commission_value"
                    @input="commissionUpdated"
                    placeholder="e.g. 100"
                    class="block w-full rounded-l border-gray-300 transition-shadow sm:text-sm focus:ring-0 focus:border-gray-400">
                <span class="inline-flex items-center px-3 text-gray-500 rounded-r border border-l-0 border-gray-300 sm:text-sm"> {{ commissionCurrency }} </span>
                <div class="flex items-center text-sm text-gray-500 ml-4">if</div>
            </div>

            <select-field v-model="goalCommission.condition_element" @input="commissionUpdated">
                <option value="">select</option>
                <option v-for="conditionElement in props.goalCommissionConditionElements" :value="conditionElement.key">
                    {{ conditionElement.label }}
                </option>
            </select-field>

            <select-field v-model="goalCommission.condition_type" @input="commissionUpdated">
                <option value="">select</option>
                <option v-for="conditionType in props.goalCommissionConditionTypes" :value="conditionType.key">
                    {{ conditionType.label }}
                </option>
            </select-field>

            <div class="flex items-center justify-start gap-2 col-span-2">
                <text-field
                    type="text"
                    v-model="goalCommission.condition_value"
                    @input="commissionUpdated"
                    placeholder="e.g. 50"
                >
                </text-field>

                <btn
                    :icon-name="accordions[index] === true ? 'arrow-up-s' : 'arrow-down-s'"
                    @click="() => toggleAccordion(index)"
                    type="secondary"
                >
                </btn>

                <btn
                    icon-name="trash"
                    type="secondary"
                    @click="removeGoalCommission(index)"
                >
                </btn>
            </div>

            <div v-show="accordions[index] === true" class="col-span-6 grid grid-cols-3 gap-4 flex items-center border-t pt-4">
                <div class="flex relative rounded">
                    <div class="flex items-center text-sm text-gray-500 mr-4">Starts</div>
                    <datepicker
                        :datepicker-value="prepDatepickerValue(goalCommission.condition_date_from)"
                        :default-selected-date="null"
                        :min-date="moment().startOf('day').format(DATETIME_DISPLAY_FORMAT)"
                        :format="DATETIME_DISPLAY_FORMAT"
                        :time="true"
                        :time24hr="true"
                        :auto-update-input="false"
                        @update="(value) => fromDateUpdated(index, value)"
                    ></datepicker>
                </div>
                <div class="flex relative rounded">
                    <div class="flex items-center text-sm text-gray-500 mr-4">Ends</div>
                    <datepicker
                        :datepicker-value="prepDatepickerValue(goalCommission.condition_date_to)"
                        :default-selected-date="null"
                        :min-date="moment().startOf('day').format(DATETIME_DISPLAY_FORMAT)"
                        :format="DATETIME_DISPLAY_FORMAT"
                        :time="true"
                        :time24hr="true"
                        :auto-update-input="false"
                        @update="(value) => toDateUpdated(index, value)"
                    ></datepicker>
                </div>
                <toggle
                    :model-value="goalCommission.condition_repeatable"
                    @update:modelValue="(value) => handleConditionRepeatableToggle(index, value)"
                    :true-value="true"
                    title="The goal is recurring"
                ></toggle>
            </div>


        </div>

        <div v-if="goalCommissions.length > 0" class="py-3 self-stretch">
            <hr class="border border-b-0">
            <span
                class="text-xs block bg-white text-black px-2 py-1 -mt-3 float-left ml-3 uppercase">and</span>
        </div>

        <div v-if="canAddGoalCommission">
            <btn type="secondary" size="small" icon-name="add-circle" @click="addGoalCommission">
                Add
            </btn>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment-timezone'
import {computed, ref} from "vue";

const props = defineProps({
    commissionCurrency: {
        type: String,
        default: ''
    },
    goalCommissions: {
        type: Array,
        default(rawProps) {
            return [];
        }
    },
    goalCommissionConditionElements: {
        type: Object,
        default: () => {}
    },
    goalCommissionConditionTypes: {
        type: Object,
        default: () => {}
    },
});
const emit = defineEmits(['update:goal-commissions'])

const goalCommissions = ref(props.goalCommissions);
const canAddGoalCommission = computed(() => goalCommissions.value.length < 5);
const accordions = ref({})

const DATETIME_SAVING_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';
const DATETIME_DISPLAY_FORMAT = 'YYYY-MM-DD HH:mm';

const addGoalCommission = () => {
    if (canAddGoalCommission) {
        goalCommissions.value.push({
            commission_value: '',
            condition_element: '',
            condition_type: '',
            condition_value: '',
            condition_date_from: moment().format(DATETIME_SAVING_FORMAT),
            condition_date_to: '',
            condition_repeatable: false,
            condition_identifier: '' // It will be generated in backend before saving
        });
        commissionUpdated()
    }
};
const removeGoalCommission = (index) => {
    goalCommissions.value.splice(index, 1);
    commissionUpdated()
    delete accordions.value[index];
};

const commissionUpdated = () => {
    emit('update:goal-commissions', goalCommissions.value)
}

//Repeatable conditions
const handleConditionRepeatableToggle = (index, value) => {
    goalCommissions.value[index].condition_repeatable = value
    commissionUpdated()
}

const fromDateUpdated = (index, selectedDate) => {
    goalCommissions.value[index].condition_date_from = selectedDate.format(DATETIME_SAVING_FORMAT)
    commissionUpdated()
}

const toDateUpdated = (index, selectedDate) => {
    goalCommissions.value[index].condition_date_to = selectedDate.format(DATETIME_SAVING_FORMAT)
    commissionUpdated()
}

const toggleAccordion = (index) => {
    accordions.value[index] = !accordions.value[index]
}

const prepDatepickerValue = (value) => {
    return value ? moment(value, DATETIME_SAVING_FORMAT).format() : value
}
</script>
