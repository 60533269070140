<template>
    <modal :title="'Send a test email'" size="small" name="send_test_email">
        <template #toggle>
            <btn type="secondary">{{ title }}</btn>
        </template>
        <template #body>
            <alert v-if="testSendError" type="error">{{ testSendError }}</alert>
            <div class="space-y-6">
                <div class="text-sm opacity-60">{{ description }}</div>
                <div>
                    <text-field label="Email address" type="email" placeholder="example@example.com"
                                @update:modelValue="updateAddress"
                                :modelValue="address"
                                v-on:keyup.enter.prevent="sendTestEmail"/>
                </div>
            </div>
        </template>
        <template #footer>
            <btn type="primary" @click="sendTestEmail" :disabled="sendTestEmailInProgress"
                 :loading="sendTestEmailInProgress">Send
            </btn>
        </template>
    </modal>
</template>
<script>
export default {
    name: "SendTestEmail",
    props: {
        email_address: {type: String},
        title: {type: String},
        description: {type: String},
        send_test_email_url: {type: String},
    },
    data() {
        return {
            testSendError: false,
            address: this.email_address,
            sendTestEmailInProgress: false
        }
    },
    methods: {
        sendTestEmail() {

            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/.test(this.address))) {
                this.testSendError = 'Please enter a valid email address';
                return;
            }

            this.testSendError = false;
            this.sendTestEmailInProgress = true;

            axios
                .post(this.send_test_email_url, {
                    email_address: this.address
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {
                        this.emitter.emit('modal-off', 'send_test_email');
                    } else {
                        this.testSendError = response.data?.error ?? 'Test email failed';
                    }
                })
                .catch(error => {
                    this.testSendError = error.response.data.errors[0] || 'Test email failed';
                })
                .finally(() => this.sendTestEmailInProgress = false);
        },
        updateAddress(value) {
            this.address = value;
        }
    },
    computed: {}
}
</script>
<style scoped></style>
