<template>


    <div class="space-y-4">
        <div class="space-y-2">
            <h3 class="font-semibold text-md">Sign-ups tracking</h3>
            <div class="text-sm space-y-2">
                <p>Notify Partnero about each new user registration to monitor referrals from affiliate partners.</p>
            </div>
        </div>

    </div>

    <tabs>
        <tab @click="setActiveTab(TAB_1)" :class='{"border-b-4": activeTab === TAB_1}'>
            Front-end
        </tab>
        <tab @click="setActiveTab(TAB_2)" :class='{"border-b-4": activeTab === TAB_2}'>
            Back-end
        </tab>
    </tabs>

    <div class="space-y-4" v-show="activeTab === TAB_1">
        <div class="text-sm space-y-2">
            <p>Run this script right after a user signs up to guarantee accurate tracking.</p>
            <p>Please note that you have to replace <b>key</b>, <b>name</b> and <b>email</b> with a real data from your platform.</p>
        </div>

        <div class="relative">
            <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-1').value)">
                <tooltip content="Copied" method="click">
                    <btn type="secondary" size="pill">Copy</btn>
                </tooltip>
            </div>
            <textarea id="snippet-1" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script>
po('customers',
  'signup', {
    data: {
        key: 'customer_123456', // or email address
        name: 'John',
        email: 'john.doe@partnero.com'
    }
  }
);
</script></textarea>
            <small class="text-gray-500 mt-1 block">Customers referred by partners are automatically identified when their data matches information stored in the <b>partnero_partner</b> cookie, which is set by our Partnero Universal script. This process is handled by the Partnero Universal script, ensuring accurate recognition of referred customers.

            </small>
        </div>
    </div>

    <div class="space-y-4" v-show="activeTab === TAB_2">
        <div class="text-sm space-y-2">
            <p>Make a request to Partnero API once a customer signed up to your platform.</p>
        </div>

        <div class="relative">
            <textarea id="snippet-2" rows="1" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs">POST https://api.partnero.com/v1/customers</textarea>
        </div>


        <div class="text-sm font-semibold">cURL request example </div>
        <div class="relative">
            <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-3').value)">
            <tooltip content="Copied" method="click">
                <btn type="secondary" size="pill">Copy</btn>
            </tooltip>
        </div>
            <textarea id="snippet-3" rows="5" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs">curl --location --request POST 'https://api.partnero.com/v1/customers' \
--header 'Accept: application/json' \
--header 'Authorization: Bearer YOUR_API_TOKEN' \
--header 'Content-Type: application/json' \
--data-raw '{
"partner": {
"key": "PARTNER_KEY"
},
"key": "CUSTOMER_KEY",
"email": "customer@partnero.com",
"name": "Partnero",
"surname": "Customer"
}'</textarea>
        </div>

        <div class="text-sm space-y-2">
            <p class="mt-4 mb-4">Please note that while creating a customer you have to replace <b>key</b>, <b>name</b> and <b>email</b> with a real data from your platform.</p>
            <alert type="warning">
                You have to replace <b>partner.key</b> data object with <b>UNIQUE_PARTNER_KEY</b>, which is saved in <b>partnero_partner</b> cookie created by our Partnero Universal script snippet.
            </alert>
        </div>


    </div>


</template>
<script setup>

import {ref} from 'vue';

const TAB_1 = 'tab_1';
const TAB_2 = 'tab_2';

let activeTab = ref(TAB_1);

const setActiveTab = (tab) => {
    activeTab.value = tab;
};

</script>

