<template>
    <div>
        <field-label v-if="label" :title="label" />
        <div
            class=""
            :class="{
                '-space-y-px': layout === 'vertical',
                'grid grid-flow-col auto-cols-fr -space-x-px': layout === 'horizontal',
            }"
        >
            <radio
                v-for="(option, index) in options"
                :name="name"
                v-model="picked"
                :invisible-input="invisibleInput"
                :label="option.label"
                :description="option.description"
                :disabled="option.disabled ?? false"
                :value="option.value"
                :class="{
                    'rounded-t': index === 0 && layout === 'vertical',
                    'rounded-b': index === options.length-1 && layout === 'vertical',
                    'rounded-l': index === 0 && layout === 'horizontal',
                    'rounded-r': index === options.length-1 && layout === 'horizontal',
                }"
            ></radio>
        </div>
        <small v-if="$slots.note" class="text-gray-500 mt-1 block">
            <slot name="note" />
        </small>
    </div>
</template>

<script>
export default {
    emits: ['update:modelValue'],
    props: {
        name: {},
        modelValue: {},
        options: {},
        label: {},
        invisibleInput: {},
        layout: {
            default: 'vertical',
        }
    },
    data() {
        return {
            picked: this.modelValue
        }
    },

    watch: {
        picked() {
            this.$emit('update:modelValue', this.picked)

        }
    },
    mounted() {
        this.$emit('update:modelValue', this.picked)
    }
}
</script>

<style scoped>

</style>
