<template>
    <modal title="Invite a partner" size="small" name="invite_partner" @modalClose="modalClose">
        <template #toggle>
            <btn type="primary">Invite partner</btn>
        </template>
        <template #body>
            <div class="space-y-4 text-left">

                <alert type="error" v-if="errorMessage">{{ errorMessage }}</alert>
                <alert type="suuccess" v-if="successMessage" class="rounded bg-green-50 border border-green-100 text-green-800 text-sm px-3.5 py-2.5">{{ successMessage }}</alert>

                <div class="text-sm text-gray-500">
                    Invite a partner to join your partner program by sending an invitation to the provided email address.
                </div>

                <div>
                    <label for="email" class="block text-sm opacity-80 mb-1.5">
                        Email
                    </label>
                    <text-field fieldId="email" :model-value="email" v-on:keydown.enter="invitePartner"
                                autofocus="true"
                                placeholder="Enter partner's email address" :error="(formErrors.email ?? false)"
                                @update:modelValue="updateEmail"></text-field>
                </div>
                <div>
                    <label for="name" class="block text-sm opacity-80 mb-1.5">
                        Name
                    </label>
                    <text-field fieldId="name" :model-value="name" v-on:keydown.enter="invitePartner"
                                placeholder="Enter partner's name" :error="(formErrors.name ?? false)"
                                @update:modelValue="updateName"></text-field>
                </div>

                <div v-show="showMore">
                    <label for="surname" class="block text-sm opacity-80 mb-1.5">
                        Last name (optional)
                    </label>
                    <text-field fieldId="surname" :model-value="surname" v-on:keydown.enter="invitePartner"
                                placeholder="Enter partner's last name" :error="(formErrors.surname ?? false)"
                                @update:modelValue="updateSurname"></text-field>
                </div>
                <div @click.prevent="showMoreFields()" class="flex justify-end">
                    <a href="" class="flex items-center hover:underline">
                        <span class="text-xs font-medium ml-1">
                            {{showMore ? 'Show less fields' : 'Show more fields'}}
                        </span>
                    </a>
                </div>

            </div>
        </template>
        <template #footer>
            <btn :loading="loading" @click="invitePartner" type="primary">Invite partner</btn>
        </template>
    </modal>
</template>
<script>
export default {
    name: 'invitePartner',
    props: ['invite_url'],
    data() {
        return {
            errorMessage: false,
            successMessage: false,
            loading: false,
            formErrors: {},
            email: '',
            name: '',
            surname: '',
            showMore: false,
        }
    },
    methods: {
        invitePartner() {

            this.errorMessage = false;
            this.successMessage = false;

            if (this.loading || !this.valid()) {
                return;
            }

            this.loading = true;

            axios
                .post(this.invite_url, {
                    email: this.email,
                    name: this.name,
                    surname: this.surname,
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {

                        const self = this;
                        this.successMessage = 'Invite sent';

                        setTimeout(function () {
                            self.emitter.emit('modal-off', 'invite_partner');
                            self.cleanForm();
                        }, 2000);

                    } else {
                        this.error = 'Failed to invite partner.';
                    }
                })
                .catch(error => {
                    let errorMessage = error.response.data.error || false;
                    if (errorMessage) {
                        this.errorMessage = errorMessage;
                    }
                }).finally(() => this.loading = false);
        },
        valid() {

            let valid = true;
            this.formErrors = {};

            if (this.inputIsEmpty(this.email) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.email) !== true) {
                valid = false;
                this.formErrors.email = true;
            }

            if (this.inputIsEmpty(this.name) || this.name.length > 100) {
                valid = false;
                this.formErrors.name = true;
            }

            return valid;
        },
        modalClose(name) {
            this.cleanForm();
        },
        cleanForm() {
            this.email = '';
            this.name = '';
            this.surname = '';
            this.errorMessage = false;
            this.successMessage = false;
            this.formErrors = {};
        },
        inputIsEmpty(value) {
            return (value ?? '').trim().length < 1;
        },
        updateEmail(value) {
            this.email = value;
        },
        updateName(value) {
            this.name = value;
        },
        updateSurname(value) {
            this.surname = value;
        },
        showMoreFields() {
            this.showMore = !this.showMore;
        }
    }
}
</script>
<style scoped>
</style>
