<template>
  <div class="space-y-12">

    <div class="flex flex-col items-center gap-6">

        <icon name="shopping-bag" size="36"></icon>

      <div class="flex flex-col items-center space-y-1">
        <span class="font-semibold text-lg">Which e-commerce plaftorm are your using?</span>
        <span class="text-gray-500 text-md">Choose an e-commerce platform currently in use by your business that you wish to integrate with Partnero.</span>
      </div>

      <div class="grid grid-cols-3 gap-6 mt-6 w-full">

        <a @click="setActiveTab(TAB_SHOPIFY)" class="border rounded flex items-center gap-4 p-4 hover:shadow-md hover:scale-102 transform transition ease-in-out" :class='{"border-b-4": activeTab === TAB_SHOPIFY}'>
          <div class="w-10 h-10 rounded flex flex-col items-center justify-center">
            <img src="/images/integrations/shopify.png">
          </div>
          <div class="flex flex-col justify-start text-sm">
            <span class="font-semibold">Shopify</span>
          </div>
        </a>
        <a @click="setActiveTab(TAB_WOOCOMMERCE)" class="border rounded flex items-center gap-4 p-4 hover:shadow-md hover:scale-102 transform transition ease-in-out" :class='{"border-b-4": activeTab === TAB_WOOCOMMERCE}'>
          <div class="w-10 h-10 rounded flex flex-col items-center justify-center">
            <img src="/images/integrations/woocommerce.png">
          </div>
          <div class="flex flex-col justify-start text-sm">
            <span class="font-semibold">WooCommerce</span>
          </div>
        </a>
        <a @click="setActiveTab(TAB_OTHER)" class="border rounded flex items-center gap-4 p-4 hover:shadow-md hover:scale-102 transform transition ease-in-out" :class='{"border-b-4": activeTab === TAB_OTHER}'>
          <div class="flex-shrink-0 w-10 h-10 rounded bg-tahiti-100 flex flex-col items-center justify-center text-dark-gray">
            <icon size="18" name="apps"></icon>
          </div>
          <div class="flex flex-col justify-start text-sm">
            <span class="font-semibold">Other</span>
          </div>
        </a>
      </div>

      <div class="mt-6" v-show="activeTab === TAB_SHOPIFY">

          <div class="flex items-center gap-4 mb-6">
              <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">1</div>
              <h3 class="text-md font-semibold">Install <a href="https://apps.shopify.com/partnero-affiliate-management" target="_blank" class="underline hover:no-underline">Partnero Affiliate Management</a> app from Shopify App Store</h3>
          </div>

          <div class="flex items-center gap-4 mb-4">
              <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">2</div>
              <h3 class="text-md font-semibold">Connect Partnero account with your Shopify store</h3>
          </div>
          <div class="text-sm mb-6 ml-14 space-y-2">
              <p>After installing the Partnero Affiliate Management app on your Shopify store, return to your Shopify account and select <span class="font-semibold">Apps</span>. Click on <span class="font-semibold">Partnero Affiliate Management</span> to be taken to Partnero. Log in and select the affiliate program you wish to link to your Shopify store.</p>
          </div>
      </div>

      <div class="mt-6" v-show="activeTab === TAB_WOOCOMMERCE">

          <div class="flex items-center gap-4 mb-4">
              <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">1</div>
              <h3 class="text-md font-semibold">Download the <a href="https://wordpress.org/plugins/partnero/" target="_blank" class="underline hover:no-underline">Partnero Affiliate Management</a> plugin</h3>
          </div>
          <div class="text-sm mb-6 ml-14 space-y-2">
              <p>After downloading the plugin, upload the plugin files to the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">/wp-content/plugins/partnero</span> directory on your site.</p>
              <p>Or, to install the plugin directly from your WordPress dashboard, go to <span class="font-semibold">Plugins</span> -> <span class="font-semibold">Add New</span>, search for <span class="font-semibold">Partnero</span>, and click <span class="font-semibold">Install</span>.</p>
          </div>

          <div class="flex items-center gap-4 mb-4">
              <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">2</div>
              <h3 class="text-md font-semibold">Activate <a href="https://wordpress.org/plugins/partnero/" target="_blank" class="underline hover:no-underline">Partnero Affiliate Management</a> plugin</h3>
          </div>
          <div class="text-sm mb-6 ml-14 space-y-2">
              <p>Navigate to <span class="font-semibold">Plugins</span> on your WordPress dashboard, then to <span class="font-semibold">Installed plugins</span> and find <span class="font-semibold">Partnero</span>. Click <span class="font-semibold">Activate</span> to enable the plugin.</p>
          </div>

          <div class="flex items-center gap-4 mb-4">
              <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">3</div>
              <h3 class="text-md font-semibold">Connect Partnero account with your WooCommerce store</h3>
          </div>
          <div class="text-sm mb-6 ml-14 space-y-2">
              <p>Go to <span class="font-semibold">Partnero</span> on your WordPress sidebar and enter the API key for your affiliate program to connect. Aan API key can be created <a href="../integrations" target="_blank" class="underline hover:no-underline">here</a>.</p>
          </div>

      </div>

        <div class="mt-6 w-full" v-show="activeTab === TAB_OTHER">
          <div class="text-sm mb-6 space-y-6">
              <p>Partnero directly integrates with <span class="font-semibold">Shopify</span> and <span class="font-semibold">WooCommerce</span>. For other e-commerce platforms, you can connect using the <a href="https://developers.partnero.com/guide/affiliate.html" target="_blank" class="underline hover:no-underline">Partnero API</a>.</p>

              <div class="flex items-center gap-4 bg-tahiti-100 p-6 rounded border border-gray-900 border-opacity-5">
                 <div class="bg-teal-300 bg-opacity-25 rounded p-4">
                     <icon name="question-line" size="20"></icon>
                 </div>
                  <p class="text-md">If you require assistance with integration, <a href="https://www.partnero.com/service-integration" target="_blank" class="underline hover:no-underline">reach out to the Partnero team</a>, and we'll handle the integration process for your e-commerce store.</p>
              </div>

        </div>

      </div>

    </div>
  </div>
</template>
<script setup>
defineProps({});

import {ref} from 'vue';

const TAB_SHOPIFY = 'shopify';
const TAB_WOOCOMMERCE = 'woocommerce';
const TAB_OTHER = 'other';

let activeTab = ref(TAB_SHOPIFY);

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

</script>
