<template>
    <table class="min-w-full divide-y divide-gray-200">
        <thead>
        <tr class="h-1">
            <th :colspan="TABLE_COLUMNS_COUNT">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <th class="pl-4 w-6">
                <options
                    :isRight="false"
                    name="payout_table_options"
                >
                    <template #toggle>
                        <div class="bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                            <icon name="arrow-down-small"></icon>
                        </div>
                    </template>
                    <template #items>
                        <options-item
                            title="Select all"
                            @click="setExportSelection('selectAll')"
                        />
                        <options-item
                            title="Select visible"
                            @click="setExportSelection('selectVisible')"
                        />
                    </template>
                </options>
            </th>
            <table-header-columns-and-sorting
                :tableHeaders="tableHeaders"
            />
        </tr>
        <tr class="h-1"/>
        </thead>
        <tbody class="divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!payouts || payouts?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && payouts?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr v-for="(payout, index) in payouts"
                :key="payout.payout_id + '-' + payout.program_id + '-' + index"
                class="hover:bg-gray-50 cursor-pointer group"
            >
                <td class="pl-4 w-6">
                    <checkbox
                        v-model="exportOptions.selectedIds"
                        :value="payout.id"
                        @click="setExportSelection('selectCustom')"
                    />
                </td>
                <td
                    class="px-4 py-4 whitespace-nowrap font-medium text-sm text-gray-900 group-hover:text-teal-600"
                    @click="viewPayout(payout.url)">
                    <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                            <avatar :avatar-details="payout.avatar_details"/>
                        </div>
                        <div class="ml-4">
                            {{ payout.partner_full_name }}
                            <div
                                class="text-sm font-normal text-gray-500">{{ payout.partner_email }}
                            </div>
                        </div>
                    </div>
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500"
                    @click="viewPayout(payout.url)"
                >
                    <payout-status :status="payout.status"/>
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500"
                    @click="viewPayout(payout.url)"
                >
                    {{ payout.date }}
                </td>
                <td class="px-4 py-4 whitespace-nowraptext-gray-500 text-sm font-medium text-gray-900 uppercase"
                    @click="viewPayout(payout.url)"
                >
                    {{ payout.amount }}
                </td>
                <td class="px-4 py-4 whitespace-nowraptext-gray-500 text-sm font-medium text-gray-900"
                    @click="viewPayout(payout.url)"
                >
                    {{ payoutMethodName(payout) }}
                </td>
                <td class="pl-4 pr-8 py-4">
                    <btn @click.native="showPayoutDetailsModal(payout)" type="light" size="small" class="whitespace-nowrap">
                        View details
                    </btn>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
    <div v-if="selectedPayoutForDetails">
        <payout-details-modal
            :payout="selectedPayoutForDetails"
            :bank-transfer-fields="prepareBankTransferFields(selectedPayoutForDetails)"
            @modal-closed="resetSelectedPayoutForDetails"
        />
    </div>
</template>

<script>
import Avatar from "../../../../common/Avatar";
import InitialLoadingSkeletonRows from "../../../../common/InitialLoadingSkeletonRows";
import PayoutStatus from "./PayoutStatus";
import PayoutDetailsModal from "./PayoutDetailsModal";
import ProgressBar from "../../../../../elements/ProgressBar";
import NoSearchDataRow from "../../../../common/NoSearchDataRow";
import useRecentActivities from "../../../../../composables/common/filter-and-pagination-data";
import useTableDataExporter from "../../../../../composables/common/select-export-and-delete-data";
import {ref, watch} from "vue";
import TableHeaderColumnsAndSorting from "../../../filters-and-pagination/TableHeaderColumnsAndSorting.vue";

export default {
    name: "PayoutDataTable",
    components: {
        TableHeaderColumnsAndSorting,
        InitialLoadingSkeletonRows,
        NoSearchDataRow,
        PayoutDetailsModal,
        PayoutStatus,
        ProgressBar,
        Avatar
    },
    props: ['payouts', 'bankTransferFields', 'tableHeaders'],
    setup(props) {
        const TABLE_COLUMNS_COUNT = 7;
        const {loadingFiltersAndPaginationData} = useRecentActivities();
        const {exportOptions, exportSetup} = useTableDataExporter();

        const selectedPayoutForDetails = ref(null);

        const resetSelectedPayoutForDetails = () => {
            selectedPayoutForDetails.value = null
        }

        watch(
            () => props.payouts,
            (newPayouts) => {
                if (selectedPayoutForDetails.value) {
                    if (Array.isArray(newPayouts)) {
                        selectedPayoutForDetails.value = newPayouts.find(payout => payout.id === selectedPayoutForDetails.value.id) ?? null
                    } else {
                        resetSelectedPayoutForDetails()
                    }
                }
            },
            { deep: true } // Add deep if you need to watch nested properties
        );

        return {loadingFiltersAndPaginationData, TABLE_COLUMNS_COUNT, exportOptions, exportSetup, selectedPayoutForDetails, resetSelectedPayoutForDetails}
    },
    methods: {
        viewPayout(url) {
            window.location = url
        },
        setExportSelection(type) {

            this.exportOptions.selectType = type
            if (type === 'selectVisible' || type === 'selectAll') {
                this.exportOptions.selectedIds = [];
                this.payouts.forEach(payout => {
                    this.exportOptions.selectedIds.push(payout.id);
                });
            }

            this.emitter.emit('hide-options', 'payout_table_options');

            let self = this;
            // This is ultra stupid, dunno why selectedIds are not filled on event, but timeout helps
            setTimeout(function () {
                self.emitter.emit('export-selection-set', {
                    name: self.exportSetup.name,
                    options: self.exportOptions
                });
            }, 10);
        },
        payoutMethodName(payout) {
            if (payout.payout_gateway === 'paypal') {
                return 'PayPal';
            }

            if (payout.payout_gateway === 'bank_transfer') {
                return 'Wire Transfer';
            }

            if (payout.payout_gateway === 'crypto') {
                return 'Crypto';
            }

            return '---';
        },
        prepareBankTransferFields(payout) {
            if (payout.payout_gateway === 'bank_transfer') {
                return this.bankTransferFields[payout.amount_units][payout.info.bank_transfer_info.targetCurrency?.toLowerCase() || payout.amount_units]
            }
            return null;
        },
        showPayoutDetailsModal(payout) {
            this.selectedPayoutForDetails = payout
            this.$nextTick(() => {
                this.emitter.emit('modal-on', 'payout-details-modal')
            })
        },
    }
}
</script>
