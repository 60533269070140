<template>
    <table class="min-w-full divide-y divide-gray-200">
        <thead>
        <tr class="h-1">
            <th :colspan="TABLE_COLUMNS_COUNT">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <th class="pl-4 w-6">
                <options
                    :isRight="false"
                    name="lead_table_options"
                >
                    <template #toggle>
                        <div class="bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                            <icon name="arrow-down-small"></icon>
                        </div>
                    </template>
                    <template #items>
                        <options-item
                            title="Select all"
                            @click="setExportSelection('selectAll')"
                        />
                        <options-item
                            title="Select visible"
                            @click="setExportSelection('selectVisible')"
                        />
                    </template>
                </options>
            </th>
            <table-header-columns-and-sorting
                :tableHeaders="tableHeaders"
            />
        </tr>
        <tr class="h-1"/>
        </thead>
        <tbody class="divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!leads || leads?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && leads?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr v-for="(lead, index) in leads"
                :key="lead.id"
                class="hover:bg-gray-50 group"
            >
                <td class="pl-4 w-6">
                    <checkbox
                        v-model="exportOptions.selectedIds"
                        :value="lead.id"
                        @click="setExportSelection('selectCustom')"
                    />
                </td>
                <td class="px-4 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                        <div class="whitespace-nowrap font-semibold text-sm text-gray-900">
                            <div>{{ lead.lead_info.name }}</div>
                            <div class="text-sm font-normal text-gray-500">{{ lead.lead_info.email }}</div>
                        </div>
                    </div>
                </td>
                <td class="px-4 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                        <div>
                            <badge type="success" :title="lead.status" v-if="lead.status == 'Converted'"></badge>
                            <badge type="error" :title="lead.status" v-else-if="lead.status == 'Rejected'"></badge>
                            <badge type="neutral" :title="lead.status" v-else></badge>
                        </div>
                    </div>
                </td>
                <td class="px-4 py-4 whitespace-nowrap font-medium text-sm cursor-pointer text-gray-900 group-hover:text-teal-600">
                    <div class="flex items-center">
                        <div v-if="lead.submitter">
                            <a v-if="lead.submitter.url" :href="lead.submitter.url">{{ lead.submitter.name }}</a>
                            <span v-else>{{ lead.submitter.name }}</span>
                        </div>
                        <div v-else>
                            <span>-</span>
                        </div>
                    </div>
                </td>
                <td class="px-4 py-4 whitespace-nowrap font-medium text-sm text-gray-900 group-hover:text-teal-600">
                    <div class="flex items-center">
                        <div v-if="lead.converted_to">
                            <a :href="lead.converted_to.url">{{ lead.converted_to.name }}</a>
                        </div>
                        <div v-else>
                            <span>-</span>
                        </div>
                    </div>
                </td>
                <td class="px-4 py-4">
                    <modal size="medium" title="Lead submission" name="check_lead_submission"
                           :show-cancel-button="false">
                        <template #toggle>
                            <btn type="secondary"
                                 size="small"
                            >
                                Check submission
                            </btn>
                        </template>
                        <template #body>
                            <div class="border rounded">
                                <table class="min-w-full">
                                    <tbody class="divide-y divide-gray-200">
                                    <tr v-for="(submission_form, index) in lead.submission_form">
                                        <td class="text-sm font-semibold p-4">{{ submission_form.question }}</td>
                                        <td class="text-sm text-gray-500 p-4">{{ submission_form.answer }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template #footer>
                            <modal title="Are you sure?" size="small" name="delete_lead_submission">
                                <template #toggle>
                                    <btn type="light">Delete</btn>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to delete this lead?<br/>
                                            This process cannot be undone.
                                        </div>
                                        <alert type="error" class="mt-4" v-if="errors.deleting[lead.id]">
                                            {{ errors.deleting[lead.id] }}
                                        </alert>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" @click="deleteLead(lead, index)" :loading="loading.deleting">
                                        Yes, delete
                                    </btn>
                                </template>
                            </modal>
                            <modal title="Are you sure?" size="small" name="reject_lead_submission"
                                   v-if="lead.can_be_rejected ?? false">
                                <template #toggle>
                                    <btn>Reject</btn>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Do you really want to reject this lead?
                                        </div>
                                        <alert type="error" class="mt-4" v-if="errors.rejecting[lead.id]">
                                            {{ errors.rejecting[lead.id] }}
                                        </alert>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" @click="rejectLead(lead, index)" :loading="loading.rejecting">
                                        Yes, reject
                                    </btn>
                                </template>
                            </modal>
                            <modal title="Convert Lead to Customer" size="small" name="convert_lead_submission"
                                   v-if="lead.can_be_converted ?? false">
                                <template #toggle>
                                    <btn>Convert</btn>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-sm text-gray-500">
                                            Are you sure you want to convert this lead into a customer?
                                        </div>
                                        <div>
                                            <text-field
                                                label="Transaction amount"
                                                v-model="commissionTransactionAmount"
                                                placeholder="E.g. 10000"
                                                type="number"
                                                :error="commissionTransactionAmountError"
                                                :error-message="commissionTransactionAmountError"
                                            >
                                                <template #note>
                                                    Enter the transaction amount used to close this lead (this will be
                                                    used for commission calculations).
                                                </template>
                                            </text-field>
                                        </div>

                                        <alert type="error" class="mt-4" v-if="errors.converting[lead.id]">
                                            {{ errors.converting[lead.id] }}
                                        </alert>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn type="primary" @click="convertLead(lead, index)" :loading="loading.converting">
                                        Yes, convert
                                    </btn>
                                </template>
                            </modal>
                        </template>
                    </modal>
                </td>
                <td class="pl-4 pr-4 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                    <time :datetime="lead.created_at">
                        {{ lead.created_at }}
                    </time>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>
import {toRef} from "vue";
import InitialLoadingSkeletonRows from "../../../common/InitialLoadingSkeletonRows";
import NoSearchDataRow from "../../../common/NoSearchDataRow";
import ProgressBar from "../../../../elements/ProgressBar";
import filtersAndPagination from "../../../../composables/common/filter-and-pagination-data";
import TableHeaderColumnsAndSorting from "../../filters-and-pagination/TableHeaderColumnsAndSorting.vue";
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";
import Btn from "../../../../elements/Btn.vue";
import ValidationErrors from "../../../common/ValidationErrors.vue";
import Modal from "../../../../elements/Modal.vue";
import Alert from "../../../../elements/Alert.vue";

export default {
    name: "LeadsDataTable",
    emits: ['hide-options', 'lead-changed', 'lead-deleted'],
    components: {
        Alert,
        Modal,
        ValidationErrors,
        Btn,
        TableHeaderColumnsAndSorting,
        InitialLoadingSkeletonRows,
        NoSearchDataRow,
        ProgressBar
    },
    props: {
        program: {
            type: [Number, String],
            required: true,
        },
        leads: {
            default: []
        },
        tableHeaders: {
            default: []
        },
        programLeadsCommissionType: {
            type: [String, null],
            default: null
        },
        programLeadsCustomCommissionType: {
            type: [String, null],
            default: null
        },
        programLeadsCommissionAmount: {
            type: [Number, String, null],
            default: null
        },
    },
    setup() {

        const TABLE_COLUMNS_COUNT = 7;
        const {loadingFiltersAndPaginationData} = filtersAndPagination();
        const {exportOptions, exportSetup} = useTableDataExporter();

        const commissionTransactionAmount = toRef(null);
        const commissionTransactionAmountError = toRef(null);

        const loading = toRef({
            converting: false,
            deleting: false,
            rejecting: false,
        });

        const errors = toRef({
            converting: {},
            deleting: {},
            rejecting: {},
        });

        return {
            loadingFiltersAndPaginationData,
            TABLE_COLUMNS_COUNT,
            exportOptions,
            exportSetup,
            loading,
            errors,
            commissionTransactionAmount,
            commissionTransactionAmountError
        }
    },
    methods: {
        convertLead(lead, index) {
            
            if (this.loading.converting) {
                return;
            }

            this.commissionTransactionAmountError = null;
            this.errors.converting[lead.id] = null;
            this.loading.converting = true;

            let commissionTransactionAmount = null;
            commissionTransactionAmount = Number(this.commissionTransactionAmount);

            if (commissionTransactionAmount === null || commissionTransactionAmount <= 0) {
                this.commissionTransactionAmountError = "Please enter transaction amount";
                this.loading.converting = false;
                return;
            }

            axios
                .post(route('app_int.program_leads.convert', {program: this.program}), {
                    lead_id: lead.id,
                    commission_transaction_amount: commissionTransactionAmount
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        this.$emit('lead-changed', lead, index, response.data.lead);
                        this.emitter.emit('modal-off', 'convert_lead_submission');
                        this.emitter.emit('modal-off', 'check_lead_submission');
                    }

                    if (status === 0 && response.data.error) {
                        this.errors.converting[lead.id] = response.data.error;
                    }
                })
                .finally(() => this.loading.converting = false);
        },
        rejectLead(lead, index) {
            if (this.loading.rejecting) {
                return;
            }

            this.errors.rejecting[lead.id] = null;
            this.loading.rejecting = true;

            axios
                .post(route('app_int.program_leads.reject', {program: this.program}), {
                    lead_id: lead.id
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        this.$emit('lead-changed', lead, index, response.data.lead);
                        this.emitter.emit('modal-off', 'reject_lead_submission');
                        this.emitter.emit('modal-off', 'check_lead_submission');
                    }

                    if (status === 0 && response.data.error) {
                        this.errors.rejecting[lead.id] = response.data.error;
                    }
                })
                .finally(() => this.loading.rejecting = false);
        },
        deleteLead(lead, index) {
            if (this.loading.deleting) {
                return;
            }

            this.errors.deleting[lead.id] = null;
            this.loading.deleting = true;

            axios
                .post(route('app_int.program_leads.delete', {program: this.program}), {
                    lead_id: lead.id
                })
                .then(response => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        this.$emit('lead-deleted', lead, index);
                        this.emitter.emit('modal-off', 'delete_lead_submission');
                        this.emitter.emit('modal-off', 'check_lead_submission');
                    }

                    if (status === 0 && response.data.error) {
                        this.errors.deleting[lead.id] = response.data.error;
                    }
                })
                .finally(() => this.loading.deleting = false);
        },
        setExportSelection(type) {
            this.exportOptions.selectType = type;
            if (type === 'selectVisible' || type === 'selectAll') {
                this.exportOptions.selectedIds = [];
                this.leads.forEach(lead => {
                    this.exportOptions.selectedIds.push(lead.id);
                })
            }
            this.emitter.emit('hide-options', 'lead_table_options');
        }
    }
}
</script>
