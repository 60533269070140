<script setup>
import {ref} from "vue";

const inviteEmails = ref('')
const inviteErrors = ref('')

function sendInvite() {
    inviteErrors.value = ''
    axios.post(route('app_int.refer_a_friend.invite'), {
        invite_emails: inviteEmails.value
    })
        .then((response) => {
            inviteEmails.value = ''
        })
        .catch((error) => {
            inviteErrors.value = error.response.data?.message || ''
        })
}
</script>

<template>
    <div class="space-y-1">
        <h4 class="text-md font-semibold">Invite friend by email</h4>
        <div class="text-sm">Invite your friends to join Partnero by sending them invitation email.</div>
    </div>

    <form @submit.prevent="sendInvite">
        <div class="rounded-md bg-gray-50 p-6 flex items-start justify-between gap-4">
            <div class="w-3/4">
                <text-field
                    field-id="invite_emails"
                    name="invite_emails"
                    type="text"
                    placeholder="e.g. friend@email.com"
                    v-model="inviteEmails"
                    :error="!!inviteErrors"
                    :error-message="inviteErrors"
                ></text-field>
                <small class="text-gray-500 mt-1 block">Additional email addresses must be separated by a comma.</small>
            </div>
            <div class="w-1/4">
                <btn class="w-full" button-type="submit">Send invite</btn>
            </div>
        </div>
    </form>

</template>
