import {ref} from "vue";
import useFilters from "../../state/common/filters-and-pagination";

const {filters, search} = useFilters()

// select types: selectAll, selectVisible, selectCustom
const exportOptions = ref({
    selectType: null,
    selectedIds: [],
    selectTypeCustomModels: [],
    filters: filters,
    search: search,
});



const exportSetup = ref({
    name: '',
    exportModelNameSingular: '',
    exportModelNamePlural: '',
    exportUrl: '',
    showExportButton: true,
});

const deleteSetup = ref({
    deleteUrl: '',
    additionalData: {
        // provided from backend to check if there are any related data to delete
        rewardCouponsExistWithinProgram: false,
        thirdPartyCouponsExistWithinProgram: false,
        // computed on frontend when custom select is used
        rewardCouponsExistWithinCustomSelect: false,
        thirdPartyCouponsExistWithinCustomSelect: false,
        deleteRewardAssociatedWithCoupon: false,
        deleteCouponOnSyncProviderSide: false,
    },
});

const approvalSetup = ref({
    approvalUrl: '',
    selectedPartnersIdsToApprove: [],
    unapprovedPartnersExistWithinProgram: false,
})

const exportDataOriginal = ref({});

const exportData = ref({
    status: null,
    lines: 0,
    total: 0,
    file_name: null,
    public_link: null,
});

const fileReadyForDownloadExist = ref(false);

function resetExportData() {
    exportData.value.status = null
    exportData.value.lines = 0
    exportData.value.total = 0
    exportData.value.file_name = null
    exportData.value.public_link = null
    removeExportFilePublicUrlFromLocalStorage()
    fileReadyForDownloadExist.value = false
}

function parseExportData(dataToParse) {
    exportDataOriginal.value = dataToParse

    exportData.value.lines = dataToParse.lines
    exportData.value.total = dataToParse.total
    exportData.value.status = dataToParse.status
    exportData.value.public_url = dataToParse.public_url
    exportData.value.file_name = dataToParse.file_name

    if (dataToParse.status === 'done') {
        setExportFilePublicUrlToLocalStorage(dataToParse.public_url)
        fileReadyForDownloadExist.value = true
        removeExportIdFromLocalStorage()
    }
}

const notification = ref({
    showNotification: false,
    notificationType: '',
    notificationTitle: '',
    notificationMessage: '',
});

function showNotification(show, title, type, message) {
    notification.value.showNotification = show;
    notification.value.notificationTitle = title;
    notification.value.notificationType = type;
    notification.value.notificationMessage = message;
}

function resetNotification() {
    notification.value.showNotification = false
    notification.value.notificationType = ''
    notification.value.notificationTitle = ''
    notification.value.notificationMessage = ''
}

const exportingInProgress = ref(false);

const setExportIdToLocalStorage = (id) => {
    localStorage.setItem(exportSetup.value.exportModelNamePlural + '_export_id', id)
}

const getExportIdFromLocalStorage = () => {
    return localStorage.getItem(exportSetup.value.exportModelNamePlural + '_export_id')
}

const removeExportIdFromLocalStorage = () => {
    localStorage.removeItem(exportSetup.value.exportModelNamePlural + '_export_id')
}

const setExportFilePublicUrlToLocalStorage = (link) => {
    localStorage.setItem(exportSetup.value.exportModelNamePlural + '_public_link', link)
}

const getExportFilePublicUrlFromLocalStorage = () => {
    return localStorage.getItem(exportSetup.value.exportModelNamePlural + '_public_link')
}

const removeExportFilePublicUrlFromLocalStorage = () => {
    localStorage.removeItem(exportSetup.value.exportModelNamePlural + '_public_link')
}

const checkForPreviousExport = () => {
    const exportFileLink = getExportFilePublicUrlFromLocalStorage()
    const exportId = getExportIdFromLocalStorage()

    if (exportFileLink) {
        fileReadyForDownloadExist.value = true
        handleExistingExportFile(exportFileLink)
    } else if (exportId) {
        setupExportStream(exportId, true)
    }
}

const deletingInProgress = ref(false);
const approvingInProgress = ref(false);

function handleExistingExportFile(exportFilePublicLink) {
    if (exportFilePublicLink) {
        exportData.value.public_url = exportFilePublicLink
        exportData.value.status = 'done'
    }
}

function setupExportStream(exportId, exportAlreadyRunning = false) {
    const sseFeed = '/export/' + exportId + '/sse'

    globalThis.es = new EventSource(sseFeed)


    es.addEventListener('message', (event) => {
        if (exportAlreadyRunning) {
            exportingInProgress.value = true
            eId = exportId
        }
        if (event.data === 'END-OF-STREAM') {
            exportingInProgress.value = false
            notification.value.showNotification = true
            notification.value.notificationTitle = 'Done'
            notification.value.notificationType = 'success'
            notification.value.notificationMessage = 'Export prepared successfully'
            es.close()
            removeExportIdFromLocalStorage()
        } else {
            const data = JSON.parse(event.data)
            parseExportData(data)
        }
    }, false)

    es.addEventListener('error', (event) => {
        if (event.readyState === EventSource.CLOSED) {
            notification.value.showNotification = true
            notification.value.notificationTitle = 'Error'
            notification.value.notificationType = 'warning'
            notification.value.notificationMessage = 'Can\'t export CSV'
        }
    }, false)
}

export default function useTableDataExporter() {

    globalThis.eId = 0;

    const exportCSV = async () => {

        exportingInProgress.value = true

        axios.post(exportSetup.value.exportUrl, exportOptions.value)
            .then(response => {
                const {id} = response.data.data
                eId = id;
                setExportIdToLocalStorage(id)
                setupExportStream(id)
            })
            .catch(error => {
                console.log('Exporting error', error)
                exportingInProgress.value = false
            })
            .finally(() => {
            })
    }

    const deleteData = async () => {

        deletingInProgress.value = true

        axios.delete(deleteSetup.value.deleteUrl,
            { data: {
                ...exportOptions.value,
                additional: deleteSetup.value.additionalData,
            } })
            .then(response => {
                const status = parseInt(response.data.status || '-1');
                if (status === 1) {
                    showNotification(true, 'Done', 'success', response.data.message ?? 'Data deleted successfully');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    let errors = response.data.errors || response.data.error || false;
                    let message = 'Something went wrong';
                    if (errors) {
                        if (typeof errors === 'object') {
                            message = Object.values(errors).flat().join(' ');
                        } else {
                            message = errors;
                        }
                    }
                    showNotification(true, 'Error', 'warning', message );
                }
            })
            .catch(error => {
                let errors = error.response?.data?.errors || error.response?.data?.error || error.response?.data?.message || false;
                let message = 'Something went wrong';
                if (errors) {
                    if (typeof errors === 'object') {
                        message = Object.values(errors).flat().join(' ');
                    } else {
                        message = errors;
                    }
                }
                showNotification(true, 'Error', 'warning', message);
                console.log(error)
            })
            .finally(() => {
                deletingInProgress.value = false
            })
    }

    const approve = async () => {
        approvingInProgress.value = true

        axios.post(approvalSetup.value.approvalUrl, {
            selectType: exportOptions.value.selectType,
            selectedIds: approvalSetup.value.selectedPartnersIdsToApprove
        })
            .then(response => {
                const status = parseInt(response.data.status || '-1');
                if (status === 1) {
                    showNotification(true, 'Done', 'success', response.data.message ?? 'Data approved successfully');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    let errors = response.data.errors || response.data.error || false;
                    let message = 'Something went wrong';
                    if (errors) {
                        if (typeof errors === 'object') {
                            message = Object.values(errors).flat().join(' ');
                        } else {
                            message = errors;
                        }
                    }
                    showNotification(true, 'Error', 'warning', message );
                }
            })
            .catch(error => {
                let errors = error.response?.data?.errors || error.response?.data?.error || error.response?.data?.message || false;
                let message = 'Something went wrong';
                if (errors) {
                    if (typeof errors === 'object') {
                        message = Object.values(errors).flat().join(' ');
                    } else {
                        message = errors;
                    }
                }
                showNotification(true, 'Error', 'warning', message);
                console.log(error)
            })
            .finally(() => {
                approvingInProgress.value = false
            })
    }

    const resetExport = async () => {
        resetExportData()
        resetNotification()
        clearSelectedIds()
    }

    const clearSelectedIds = () => {
        exportOptions.value.selectedIds = []
    }

    const cancelExport = () => {
        if (es) {
            es.close()
        }

        eId = eId || getExportIdFromLocalStorage()

        if (eId) {
            axios.post(`/export/${eId}/cancel`)
                .then(response => {
                    eId = 0
                    resetExportData()
                    resetNotification()
                    exportingInProgress.value = false
                    removeExportIdFromLocalStorage()
                })
                .catch(error => {})
                .finally(() => {})
        }
    }

    return {
        exportCSV,
        exportData,
        exportOptions,
        exportSetup,
        deleteSetup,
        approvalSetup,
        exportingInProgress,
        deletingInProgress,
        approvingInProgress,
        notification,
        cancelExport,
        resetExport,
        clearSelectedIds,
        deleteData,
        approve,
        checkForPreviousExport,
        fileReadyForDownloadExist,
    }
}
