<template>
    <div>
        <modal
            :title="'Create a transaction'"
            size="small"
            ref="transactionModal"
            @modalClose="modalClose"
        >
            <template #toggle>
                <btn
                    type="secondary"
                    @click="validationErrors = null"
                >
                    Create transaction
                </btn>
            </template>
            <template #body>
                <validation-errors :errors="validationErrors" />
                <div class="grid md:grid-cols-2 col-span-2 gap-8 gap-y-6">
                    <div class="col-span-2">
                        <text-field
                            v-model="transaction.key"
                            label="Transaction key"
                            field-id="transaction_key"
                            name="key"
                            type="text"
                            autofocus="true"
                            required="true">
                        </text-field>
                        <small class="block mt-2 leading-5 text-gray-500">Enter an unique key associated with your
                            application or payment provider.</small>
                    </div>
                    <div>
                        <text-field
                            v-model.number="transaction.amount"
                            label="Transaction amount"
                            field-id="transaction_amount"
                            name="amount"
                            type="text"
                            placeholder="E.g. 10"
                            required="true">
                        </text-field>
                        <small class="block mt-2 leading-5 text-gray-500">The amount of the purchase.</small>
                    </div>
                    <div>
                        <text-field
                            v-model="transaction.action"
                            label="Action"
                            field-id="transaction_action"
                            name="action"
                            type="text"
                            placeholder="E.g. Sale"
                                required="true">
                        </text-field>
                    </div>
                    <div class="col-span-2" v-show="showMore">
                            <div class="space-y-4">
                            <text-field
                                v-model="transaction.product_id"
                                label="Product ID"
                                field-id="transaction_product_id"
                                name="product_id"
                                type="text"
                                placeholder="E.g. prod_123"
                            >
                            </text-field>
                            <text-field
                                v-model="transaction.product_type"
                                label="Product type"
                                field-id="transaction_product_type"
                                name="product_type"
                                type="text"
                                placeholder="E.g. monthly"
                            >
                            </text-field>
                        </div>
                    </div>

                    <div class="col-span-2">
                        <toggle
                            size="default"
                            v-model="transaction.rewarded"
                            title="Rewarded"
                        >
                            <template #description>
                                Mark transaction's reward as paid.
                            </template>
                        </toggle>
                    </div>
                    <div @click.prevent="showMoreFields()" class="col-span-2 flex justify-end">
                        <a href="" class="flex items-center hover:underline">
                        <span class="text-xs font-medium ml-1">
                            {{ showMore ? 'Show less fields' : 'Show more fields' }}
                        </span>
                        </a>
                    </div>
                </div>
            </template>
            <template #footer>
                <btn
                    button-type="button"
                    @click="createTransaction"
                    :disabled="creatingInProgress"
                >
                    <spinner
                        v-if="creatingInProgress"
                        :is-white="true"
                        class="mr-1.5"
                    />
                    {{ creatingInProgress ? 'Creating...' : 'Create' }}
                </btn>
            </template>
        </modal>
    </div>
</template>

<script>
import useFilters from "../../../../state/common/filters-and-pagination";
import ValidationErrors from "../../../common/ValidationErrors";
import Spinner from "../../../../elements/Spinner";
import {ref} from "vue";

export default {
    name: "CreateTransactionModal",
    components: {
        ValidationErrors,
        Spinner
    },
    props: {
        createTransactionUrl: {
            type: String,
            required: true
        },
        customerKey: {
            type: [String, Number],
            required: true
        }
    },
    setup() {
        const creatingInProgress = ref(false)
        const filters = {}
        const {
            fetchDataBaseUrl,
            fetchDataFunction,
        } = useFilters()

        function fetchData() {
            fetchDataFunction.value(fetchDataBaseUrl.value, {...filters})
        }
        return { fetchData, creatingInProgress, filters }
    },
    data() {
        return {
            transaction: {
                key: '',
                amount: '',
                action: '',
                customer: {
                    key: ''
                },
                rewarded: false,
                product_id: '',
                product_type: ''
            },
            validationErrors: null,
            showMore: false,
        }
    },
    methods: {
        createTransaction() {

            this.transaction.customer.key = this.customerKey
            this.creatingInProgress = true

            axios.post(this.createTransactionUrl, this.transaction)
                .then(response => {
                    if (response.status === 200) {
                        this.$refs.transactionModal.onCancel()
                        this.creatingInProgress = false
                        this.fetchData()
                    }
                })
                .catch(error => {
                    this.validationErrors = error.response?.data?.errors;
                })
                .finally(() => {
                    this.creatingInProgress = false
                });
        },
        resetFields(item) {
            for (let key in item) {
                if (typeof item[key] === 'object' && item[key] !== null) {
                    this.resetFields(item[key])
                } else {
                    item[key] = ''
                }
            }
        },
        modalClose() {
            this.resetFields(this.transaction)
            this.showMore = false
        },
        showMoreFields() {
            this.showMore = !this.showMore;
        }
    }
}
</script>
