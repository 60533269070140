<template>
    <div>
        <div class="px-10 py-14">

        <div>
            <draggable
                class="space-y-2 overflow-auto"
                v-model="resources"
                handle=".handle"
                group="resources"
                @start="drag=true"
                direction="vertical"
                chosenClass="bg-gray-100"
                @end="drag=false"
                item-key="$index"
            >
                <template #item="{element}">
                    <Res :data="element" :options="options" :unique_id="element.unique_id ?? uniqueId()"
                         @remove="removeResource"/>
                </template>
            </draggable>

            <div v-if="!resources.length" class="rounded border">
                <div class="flex justify-center items-center text-center p-20">
                    <div>
                        <div class="flex justify-center items-center p-4">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path d="M21 4H7a2 2 0 1 0 0 4h14v13a1 1 0 0 1-1 1H7a4 4 0 0 1-4-4V6a4 4 0 0 1 4-4h13a1 1 0 0 1 1 1v1zM5 18a2 2 0 0 0 2 2h12V10H7a3.982 3.982 0 0 1-2-.535V18zM20 7H7a1 1 0 1 1 0-2h13v2z"/>
                            </svg>
                        </div>
                        <h2 class="font-semibold text-lg mb-2">{{ this.zeroFieldsTitle }}</h2>
                        <p class="text-gray-500 text-sm">{{ this.zeroFieldsMessage }}</p>
                    </div>
                </div>
            </div>

        </div>
        <div class="w-max mt-2">
            <options :is-top="true" :is-right="false">
                <template #toggle>
                    <btn type="secondary" v-on:click.prevent>Add resource</btn>
                </template>
                <template v-slot:items="{ setShow }">
                    <options-item @click="addResource('assets');setShow(false)" title="Assets"></options-item>
                    <options-item @click="addResource('banners');setShow(false)" title="Banners"></options-item>
                    <options-item @click="addResource('colors');setShow(false)" title="Colors"></options-item>
                    <options-item @click="addResource('accordion');setShow(false)" title="Accordion"></options-item>
                    <options-item @click="addResource('links');setShow(false)" title="Links"></options-item>
                    <options-item @click="addResource('videos');setShow(false)" title="Video"></options-item>
                </template>
            </options>
        </div>
    </div>

        <input type="hidden" :name="name" :value="fieldsString">
        <div class="bg-gray-50 px-10 py-4 text-right rounded-b sticky bottom-0 z-40">
        <btn button-type="submit" type="primary" @click.native="saveResources()">
            <spinner
                v-if="updatingResources"
                :is-white="true"
                class="mr-1.5"
            />
            {{ updatingResources ? 'Updating...' : 'Update' }}
        </btn>
    </div>
        <notification
            :show="showNotification"
            :title="notificationTitle"
            :type="notificationType"
            class="mb-20"
            @notificationClosed="this.showNotification = false"
            whitespace="whitespace-normal"
        >
            <template #message>
                {{ notificationMessage }}
            </template>
        </notification>
    </div>
</template>

<script>
import Res from './Res.vue'
import {v4 as uuid} from "uuid";
import draggable from 'vuedraggable'
import Spinner from "../../../elements/Spinner";

export default {
    props: ['name', 'value', 'zeroFieldsMessage','zeroFieldsTitle', 'update_url', 'file_upload_url', 'file_delete_url'],

    components: {
        Res,
        draggable,
        Spinner
    },

    computed: {
        fieldsString() {
            return JSON.stringify(this.resources);
        }
    },

    data() {
        return {
            resources: [],
            showNotification: false,
            notificationType: '',
            notificationTitle: '',
            notificationMessage: '',
            updatingResources: false,
            options: {
                'update_url': this.update_url,
                'file_delete_url': this.file_delete_url,
                'file_upload_url': this.file_upload_url
            },
            drag: false,
        }
    },
    created() {
        this.resources = this.value || []
    },
    methods: {
        saveResources() {
            this.validationErrors = [];
            this.updatingResources = true
            axios
                .post(this.update_url, {resources: this.fieldsString})
                .then(response => {
                    const status = parseInt(response?.data?.status ?? -1);
                    if (response.status === 200) {
                        this.showNotification = true
                        this.notificationTitle = 'Done'
                        this.notificationType = 'success'
                        this.notificationMessage = 'Resource updated successfully'
                        this.updatingResources = false
                    }
                })
                .catch(error => {
                    this.showNotification = true
                    this.notificationTitle = 'Error'
                    this.notificationType = 'warning'

                    if (error?.response?.status === 422) {
                        this.notificationMessage = error?.response?.data?.message ?? 'Resource update error, see console for error details.'
                    } else {
                        this.notificationMessage = 'Resource update error, see console for error details.'
                    }
                })
                .finally(() => {
                this.updatingResources = false
            });
        },
        addResource(type) {
            this.resources.push({type, edit: true})
        },
        removeResource(resource) {
            this.resources = this.resources.filter((item) => item !== resource)
        },
        uniqueId() {
            return 'a' + uuid().split('-')[0];
        }
    }
}
</script>
