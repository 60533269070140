<script setup>

    import {useAnalyticsRealTimeStore} from "../../../../../../stores/app/programs/analytics/AnalyitcsRealTimeStore";
    import {computed} from "vue";

    const analyticsStore = useAnalyticsRealTimeStore();

    const chart = computed(() => {
        return analyticsStore.getAnalyticsRealTimeStatsChartAndCountries.chart;
    });

    const extendedOptions = computed(() => {
        return {
            ...analyticsStore.getAnalyticsRealTimeStatsChartAndCountries.chart.options,
            stroke: {
                ...analyticsStore.getAnalyticsRealTimeStatsChartAndCountries.chart.options.stroke,
                curve: 'smooth'
            },
        }
    });
</script>

<template>
    <apexchart
        v-if="chart"
        :width="chart.width"
        :height="chart.height"
        :type="chart.type"
        :options="extendedOptions"
        :series="chart.series"
    />
</template>
