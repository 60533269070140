<template>
    <label :for="labelFor" class="block text-sm leading-5 mb-1.5">
        {{ title }}
    </label>
</template>

<script>
    export default {
        props: {
            title: '',
            labelFor: ''
        }
    }
</script>

<style scoped>

</style>
