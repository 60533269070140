<script setup>
 import {usePayoutPaypalAutomationStore} from "../../../../../../stores/app/programs/payouts/automation/paypal/PayoutPaypalAutomationStore";

 const payoutAutomationStore = usePayoutPaypalAutomationStore();
</script>

<template>
  <div class="bg-white border rounded p-6 flex justify-between items-center">
    <toggle v-model="payoutAutomationStore.programAutomatedPayoutsEnabled"
            @click="payoutAutomationStore.toggleAutomation()"
            :disabled="payoutAutomationStore.automationToggleDisabled"
    ></toggle>
    <span class="flex flex-col flex-grow" id="availability-label">
            <span class="text-sm font-medium text-gray-900">
                Automated payouts with PayPal
            </span>
            <span class="text-sm text-gray-500">
                Easily manage your payouts payments with automated PayPal payouts.
            </span>
        </span>
  </div>
</template>
