<script setup>

import {generateRandomShortString} from "../../../helpers/common";

const props = defineProps({
    numberOfRows: {
        type: Number,
        default: 5
    }
});

const randomName = generateRandomShortString();

</script>

<template>
    <div class="w-full p-4 mx-auto border border-gray-200 rounded">
        <div class="flex space-x-4 animate-pulse">
            <div class="flex-1 px-2 py-4 space-y-9">
                <div v-for="num in props.numberOfRows" :key="'table-' + randomName + '-' + num"
                     class="h-4 rounded"
                     :class="num % 2 === 0 ? 'bg-gray-200' : 'bg-gray-300'"
                />
            </div>
        </div>
    </div>
</template>
