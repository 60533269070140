<template>
    <filters-and-pagination
        :pagination-data="filtersAndPaginationData"
        :filterable-fields="filterFields"
        :data-exists="initialDataState"
        :loading-data="loadingFiltersAndPaginationData"
        model-name="subscriber"
    >
        <subscriber-data-table :subscribers="filtersAndPaginationData.data" :table-headers="tableHeaders" />
    </filters-and-pagination>
</template>

<script>
import FiltersAndPagination from "../../../filters-and-pagination/FiltersAndPagination";
import {onMounted, ref, toRef, watch} from "vue";
import useRecentActivities from "../../../../../composables/common/filter-and-pagination-data";
import useFiltersAndPagination from "../../../../../state/common/filters-and-pagination";
import IndexDataTable from "./IndexDataTable.vue";
import useTableDataExporter from "../../../../../composables/common/select-export-and-delete-data";

export default {
    name: "SubscribersIndex",

    components: {
        SubscriberDataTable: IndexDataTable,
        FiltersAndPagination,
    },

    props: {
        subscriberUrl: {
            default: ''
        },
        subscriberExportUrl: {
            default: ''
        },
        subscriberDeleteUrl: {
            default: ''
        },
        filterFields: {
            default: {}
        },
        tableHeaders: {
            default: []
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        }
    },

    setup(props) {
        const subscriberUrl = toRef(props, 'subscriberUrl')
        const subscriberExportUrl = toRef(props, 'subscriberExportUrl')
        const subscriberDeleteUrl = toRef(props, 'subscriberDeleteUrl')
        const filterFields = toRef(props, 'filterFields')
        const { loadingFiltersAndPaginationData, filtersAndPaginationData, getFiltersAndPaginationData } = useRecentActivities()
        const { setDataBaseUrl, setFetchDataFunction } = useFiltersAndPagination()
        const { exportSetup, deleteSetup, checkForPreviousExport } = useTableDataExporter()

        const initialDataState = ref(!!props.dataExists)

        onMounted( () => {
            setDataBaseUrl(subscriberUrl.value)
            setFetchDataFunction(getFiltersAndPaginationData)

            exportSetup.value.exportModelNameSingular = 'newsletter_subscribers'
            exportSetup.value.exportModelNamePlural = 'subscribers'
            exportSetup.value.exportUrl = subscriberExportUrl.value
            deleteSetup.value.deleteUrl = subscriberDeleteUrl.value
            checkForPreviousExport()
        })

        watch(filtersAndPaginationData, (newValue) => {
            // the first time the data is loaded, we want to set the initialDataState to true so we can show the table
            // use filters properly if returned data is empty, etc.
            if (newValue?.data?.length) {
                initialDataState.value = true
            }
        })

        return {
            filtersAndPaginationData,
            loadingFiltersAndPaginationData,
            filterFields,
            initialDataState
        }
    }
}
</script>

