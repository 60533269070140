<template>
    <div>

        <header-bar size="xsmall" title="Webhooks">
            <template #actions>
                <webhook-modal
                    :events="webhookEvents"
                    :is-edit="false"
                    :submit-url="storeUrl"
                    @submitSuccess="getList"
                ></webhook-modal>            </template>
        </header-bar>

        <div v-if="errors.length > 0"
            class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm mb-6"
            role="alert"
        >
            <ul><li v-for="error in errors">{{ error }}</li></ul>
        </div>
        <progress-bar v-if="loading"/>
        <div class="bg-white rounded border" v-if="webhooks.length <= 0">
            <div class="flex justify-center items-center text-center py-20">
                <div>
                    <div class="flex justify-center items-center p-4">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13.06 8.11l1.415 1.415a7 7 0 0 1 0 9.9l-.354.353a7 7 0 0 1-9.9-9.9l1.415 1.415a5 5 0 1 0 7.071 7.071l.354-.354a5 5 0 0 0 0-7.07l-1.415-1.415 1.415-1.414zm6.718 6.011l-1.414-1.414a5 5 0 1 0-7.071-7.071l-.354.354a5 5 0 0 0 0 7.07l1.415 1.415-1.415 1.414-1.414-1.414a7 7 0 0 1 0-9.9l.354-.353a7 7 0 0 1 9.9 9.9z"/></svg>
                    </div>
                    <h2 class="font-semibold text-lg mb-2">You don't have any webhooks</h2>
                    <p class="text-gray-500 text-sm">Use webhooks to trigger actions based on your program events.</p>
                </div>
            </div>
        </div>

        <div v-else class="overflow-hidden bg-white rounded border sm:overflow-visible">
            <div class="overflow-x-auto sm:overflow-x-visible">
                <div class="inline-block min-w-full align-middle">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead>
                        <tr>
                            <th scope="col"
                                class="py-3 pr-4 pl-8 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                URL
                            </th>
                            <th scope="col"
                                class="py-3 px-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                Status
                            </th>
                            <th scope="col"
                                class="pr-8 pl-4 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="webhook in webhooks" :key="webhook.id" class="hover:bg-gray-50">
                            <td class="py-4 pr-4 pl-8 text-sm font-semibold text-gray-900 whitespace-nowrap">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 w-10 h-10">
                                        <div class="flex items-center justify-center font-medium text-sm text-gray-500 bg-gray-200 w-10 h-10 rounded">
                                            {{ webhook.initial }}
                                        </div>
                                    </div>
                                    <div class="ml-5">
                                        <a href="#!" class="hover:text-teal-600">{{ webhook.name }}</a>
                                        <div class="text-sm font-normal text-gray-500">
                                            {{ ellipsisString(webhook.url, 64) }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="py-4 px-4 text-base font-semibold text-gray-900 whitespace-nowrap">
                            <span
                                class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-teal-100 text-teal-800">
                                    {{ getStatusString(webhook.is_active) }}
                                </span>
                            </td>
                            <td valign="middle" class="flex justify-end py-5 pr-8 pl-4">

                                <webhook-modal
                                    :webhook="JSON.stringify(webhook)"
                                    :events="webhookEvents"
                                    :is-edit="true"
                                    :submit-url="`${storeUrl}/${webhook.id}`"
                                    @submitSuccess="getList"
                                ></webhook-modal>

                                <options class="inline-flex">
                                    <template #toggle>
                                        <btn type="secondary" size="small" icon-name="more"
                                             class="rounded-l-none"></btn>
                                    </template>
                                    <template #items>

                                        <options-item
                                            :title="getStatusString(webhook.is_active, true)"
                                            @click="updateStatus(webhook)"
                                        ></options-item>
                                        <options-divider></options-divider>
                                        <options-item title="Delete" @click="deleteWebhook(webhook)"></options-item>

                                    </template>
                                </options>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from "../../../elements/ProgressBar";
import WebhookModal from './WebhookModal.vue';
import { ellipsisString } from '../../../helpers/common.js';

export default {
    name: 'Section',
    props: {
        listUrl: {
            type: String,
            required: true
        },
        storeUrl: {
            type: String,
            required: true
        },
        webhookEvents: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            loading: false,
            errors: [],
            webhooks: [],
            statusArray: [ 'Disable', 'Active' ]
        }
    },
    mounted () {
        this.getList();
    },
    methods: {
        ellipsisString,
        getList () {

            if (this.loading) {
                return;
            }

            this.loading = true;

            axios
                .get(this.listUrl)
                .then(response => {
                    const status = parseInt(response.data.status || -1);

                    if (status === 1) {
                        this.webhooks = response.data.webhooks || [];
                    }
                })
                .catch(error => {
                    this.errors = this.extractErrors(error.response.data);
                })
                .finally(() => this.loading = false);

        },
        deleteWebhook (webhook) {
            axios
                .delete(`${this.storeUrl}/${webhook.id}`)
                .then(response => {
                    const status = parseInt(response.data.status || -1);

                    if (status === 1) {
                        this.getList();
                    }
                })
                .catch(error => {
                    this.errors = this.extractErrors(error.response.data);
                });
        },
        updateStatus (webhook) {
            axios
                .patch(`${this.storeUrl}/${webhook.id}/update_status`, { is_active: !webhook.is_active })
                .then(response => {
                    const status = parseInt(response.data.status || -1);

                    if (status === 1) {
                        this.getList();
                    }
                })
                .catch(error => {
                    this.errors = this.extractErrors(error.response.data);
                });
        },
        getStatusString (statusIndex, isOpposite) {
            statusIndex = isOpposite ? !statusIndex : statusIndex;
            return this.statusArray[Number(statusIndex)];
        },
        extractErrors (responseData) {
            if(responseData.message) {
                return [responseData.message];
            }

            if(responseData.errors && responseData.errors.length) {
                return responseData.errors;
            }

            return [];
        }
    },
    components: {
        WebhookModal,
        ProgressBar
    }
}
</script>
