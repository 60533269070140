<template>
    <div>

        <div class="flex justify-between items-center">
            <toggle name="portal_general_partnero_branding_enabled"
                    v-model="branding"></toggle>
            <div class="flex flex-col flex-grow" id="availability-label">

                <span class="text-sm font-medium text-gray-900">Enable Partnero branding on the portal</span>
                <span class="text-sm text-gray-500">Enable Partnero branding on your portal and join the Partnero Affiliate program.</span>

                <input type="hidden" name="portal_general_associated_branding_email" v-model="email">
                <input type="hidden" name="portal_partnero_associated_branding_partner_id" v-model="partner_id">

                <modal title="Partnero Affiliate program" size="small" name="partnero_affiliate_modal">
                    <template #toggle>
                        <btn type="secondary" size="small" icon-name="user-shared" class="mt-4 rounded-full"  v-if="branding">Sign up for Partnero Affiliate program</btn>
                    </template>
                    <template #body>
                        <div>

                            <div class="text-sm text-gray-500 mb-2">
                                Join the <a class="underline" href="https://www.partnero.com/partnero-affiliate-program" target="_blank">Partnero Affiliate program</a> by adding a referral link to the Partnero in your portal.
                            </div>
                            <div class="text-sm text-gray-500 mb-4">
                                Enter the email address you want to associate with your partner account. If you don't have a partner account, one will be created for you automatically.
                            </div>

                            <text-field
                                v-model="email"
                                label="Email address"
                                type="text"
                                placeholder="your@email.com"
                            >
                            </text-field>
                            <small v-if="linked_partner_email">Associated partner's email is <b>{{ linked_partner_email }}</b>.</small>

                            <text-field
                                :hidden="true"
                                v-model="partner_id"
                                label="Linked Partner ID"
                                type="number"
                            >
                            </text-field>

                        </div>
                    </template>
                    <template #footer>
                        <btn @click="emitter.emit('modal-off', 'partnero_affiliate_modal')">Save</btn>
                    </template>
                </modal>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PartneroBrandingSetting',
    props: ['branding_enabled', 'branding_email', 'linked_partner_id', 'linked_partner_email'],
    data() {
        return {
            branding: this.branding_enabled,
            email: this.branding_email,
            partner_id: this.linked_partner_id,
        }
    },
    created() {
        if(!this.branding) {
            this.branding = false;
        }
    }
}
</script>
<style scoped>
</style>
