<template>
    <modal title="Shopify customer metadata mapping" size="small" name="shopify_customer_metadata_mapping">
        <template #toggle>
            <btn size="small" type="light" class="-mr-px focus:z-10">Metadata sync</btn>
        </template>
        <template #body>
            <div class="space-y-4">
                <div class="text-sm space-y-2">
                    <div>Use additional mapping to store and sync customer metadata (e.g. date of birth) on Partnero with Shopify data.</div>
                    <div>Look for customer metadata in your shopify store's <i>Settings > Custom data > Customers</i></div>
                </div>
                <validation-errors :errors="errors" />
                <customer-date-of-birth
                    v-model:enabled="customerMetadataSyncSettings.setting_integration_shopify_sync_customer_date_of_birth_enabled"
                    v-model:mapping="customerMetadataSyncSettings.setting_integration_shopify_customer_date_of_birth_mapping"
                ></customer-date-of-birth>
            </div>
        </template>
        <template #footer>
            <btn type="primary" @click="updateMappingSettings" :loading="loading" :disabled="loading">
                Save
            </btn>
        </template>
    </modal>

    <notification
        :show="notificationSettings.show"
        :title="notificationSettings.title"
        :type="notificationSettings.type"
        class="mb-20"
        @notificationClosed="handleNotificationClosed"
    >
        <template #message>
            {{ notificationSettings.message }}
        </template>
    </notification>
</template>

<script setup>
import {ref, inject} from "vue";
import CustomerDateOfBirth from "./metadata-sync/CustomerDateOfBirth.vue";
import ValidationErrors from "../../../../common/ValidationErrors.vue";

const props = defineProps({
    programId: {
        type: Number,
        required: true
    },
    customerMetadataSync: {
        type: Object,
        required: true
    },
})

const customerMetadataSyncSettings = ref({...props.customerMetadataSync} || {})

const loading = ref(false)
const errors = ref(null)
const emitter = inject('emitter');

const updateMappingSettings = () => {
    if (loading.value === true) {
        return;
    }

    loading.value = true
    errors.value = null
    axios.post(route('app_int.integrations.settings.update', {program: props.programId}), customerMetadataSyncSettings.value)
        .then((response) => {
            const status = parseInt(response.data.status ?? -1);
            if (status === 1) {
                notificationSettings.value = {
                    ...notificationSettings.value,
                    show: true,
                    title: 'Success',
                    message: 'Metadata mapping settings updated successfully',
                    type: 'success'
                }
                emitter.emit('modal-off', 'shopify_customer_metadata_mapping')
            }
        })
        .catch((error) => {
            errors.value = error.response?.data?.errors;
        })
        .finally(() => {
            loading.value = false
        })
}

const notificationSettings = ref({
    show: false,
    title: '',
    message: '',
    type: ''
})
const handleNotificationClosed = () => {
    notificationSettings.value.message = ''
    notificationSettings.value.title = ''
    notificationSettings.value.type = ''
    notificationSettings.value.show = false
}

</script>