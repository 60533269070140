<template>
    <div class="flex items-start">
        <div class="flex-shrink-0 w-10 h-10">
            <img class="w-10 h-10 rounded" src="/images/integrations/paddle.png"/>
        </div>
        <div class="ml-5 w-full">
            <div class="flex justify-between">
                <div class="flex items-center">
                    <span class="text-sm font-semibold">Paddle</span>
                    <PaddleAfterIntegrationInstructions
                        v-if="connected"
                        class="ml-1.5"
                        :webhook-url="integrationsConfig.url.webhook"
                        :events-list="integrationsConfig.supported_events"
                    />
                </div>
                <badge title="Connected" v-if="connected"></badge>
            </div>
            <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                Connect your Paddle Billing account to Partnero.
            </div>
            <div class="flex gap-2 items-center">
            <modal v-if="!connected" :title="'Paddle integration'" size="small" name="paddle_integration">
                <template #toggle>
                    <btn v-if="!connected" type="secondary" size="small" class="-mr-px focus:z-10">
                        Authorize
                    </btn>
                </template>
                <template #body>
                    <validation-errors :errors="errors.validationErrors" />
                    <alert v-if="errors.responseError" type="error">
                        <span class="break-all">
                            {{ errors.responseError }}
                        </span>
                    </alert>
                    <div class="space-y-4">

                        <text-field
                            label="API key"
                            v-model="apiKey"
                            type="password"
                            :show_reveal_password_icon="true"
                            autofocus="true"
                            required="true"
                            :class="{'border-red-400': errors.apiKey}"
                            placeholder="e.g. ed21b04...15bca"
                        >
                            <template #note>
                                Searching for your API key? Simply sign in to your Paddle dashboard, navigate to <strong>Developer Tools</strong>, and select <strong>Authentication</strong> to locate your key.
                            </template>

                        </text-field>

                        <toggle
                            size="default"
                            v-model="useSandboxAccount"
                            title="Enable test mode"
                        >
                            <template #description>
                                If enabled, you can connect Paddle's sandbox.
                            </template>
                        </toggle>

                    </div>
                </template>
                <template #footer>
                    <div class="flex justify-end">
                        <btn type="primary" :loading="loading.authorize" class="-mr-px focus:z-10" @click="authorize">
                            Authorize
                        </btn>
                    </div>
                </template>
            </modal>
            <modal v-if="connected" title="Paddle integration" size="small" name="paddle_disconnect">
                <template #toggle>
                    <btn v-if="connected" size="small" class="-mr-px focus:z-10">Disconnect</btn>
                </template>
                <template #body>
                    <div class="space-y-4">
                        <div class="text-sm text-gray-500">Are you sure you wish to disconnect the Paddle integration?</div>
                    </div>
                </template>
                <template #footer>
                    <btn :loading="loading.disconnect" @click="disconnect">Disconnect</btn>
                </template>
            </modal>
                <customer-identifier-mapping
                    v-if="connected"
                    :program-id="programId"
                    title="Paddle integration mapping (optional)"
                    provider="paddle"
                    :customer-identifier-holder-in-metadata="integrationsConfig.setting_integration_paddle_customer_identifier_mapping"
                    field-to-look-in="custom data"
                />
            </div>
        </div>
    </div>
</template>
<script>

import ValidationErrors from "../../../common/ValidationErrors.vue";
import Alert from "../../../../elements/Alert.vue";
import PaddleCustomDataInstructions from "./components/PaddleCustomDataInstructions.vue";
import PaddleAfterIntegrationInstructions from "./components/PaddleAfterIntegrationInstructions.vue";
import CustomerIdentifierMapping from "../common/identifier-mapping/CustomerIdentifierMapping.vue";

export default {
    components: {CustomerIdentifierMapping, PaddleAfterIntegrationInstructions, PaddleCustomDataInstructions, Alert, ValidationErrors},
    props: ['integrationsConfig', 'programId'],
    data() {
        return {
            apiKey: null,
            useSandboxAccount: false,
            clientWebhookSecret: null,
            loading: {
                authorize: false,
                disconnect: false,
            },
            errors: {
                apiKey: false,
                responseError: null,
                validationErrors: null,
            },
            connected: this.integrationsConfig.connected ?? false,
        }
    },
    methods: {
        authorize() {

            this.errors.apiKey = false;
            this.errors.responseErrors = null;

            if (this.apiKey === null || this.apiKey.trim() === '') {
                this.errors.apiKey = true;
            }

            if (this.errors.apiKey === false) {
                if (this.loading.authorize) {
                    return;
                }
                this.loading.authorize = true;

                axios.post(route('programs.paddle.quick_connect', {program: this.programId}), {
                    apiKey: this.apiKey,
                    useSandboxAccount: this.useSandboxAccount,
                }).then((response) => {
                    const status = parseInt(response.data.status ?? -1);
                    if (status === 1) {
                        window.location.reload();
                    }
                    else if (status === 0) {
                        this.errors.responseError = response.data?.error;
                    }
                })
                .catch((error) => {
                        this.errors.validationErrors = error.response?.data?.errors;
                    })
                .finally(() => this.loading.authorize = false);
            }
        },
        disconnect() {

            if (this.loading.disconnect) {
                return;
            }
            this.loading.disconnect = true;

            axios.post(route('programs.paddle.disconnect', {program: this.programId})).then((response) => {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {
                    window.location.reload();
                }
            })
                .catch((error) => {
                })
                .finally(() => this.loading.disconnect = false);
        }
    }
}
</script>
<style scoped>
</style>
