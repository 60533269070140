<template>
    <div class="relative">

        <div @click="show = true" class="h-full">
            <slot name="toggle">

            </slot>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
        <div v-show="show"
             class="bg-white border border-gray-200 rounded shadow-lg absolute py-1 whitespace-nowrap z-50 mt-1 max-w-64 dark:bg-gray-900 dark:border-gray-800"
             :class="isRight ? 'right-0' : 'left-0', isTop ? 'bottom-10' : '', width"
        >

            <ul v-if="$slots.items">
                <slot name="items" :setShow="setShow"></slot>
            </ul>
        </div>
        </transition>
        <div v-if="show" @click="show = false" class="fixed left-0 top-0 w-full h-screen z-40"></div>
    </div>
</template>

<script>
    import Btn from "../Btn";

    export default {

      components: { Btn },

      props: {
          isRight: {
              default: true,
          },
          isTop: {
              default: false,
          },
          width: {
              default: false,
          },
          name: null
      },

        data: function () {
           return {
             show: false
           }
        },

        mounted() {
           const self = this
           this.emitter.on('hide-options', function (name) {
               if (self.name !== null && name === self.name) {
                   self.show = false
               }
           });
        },

        methods: {
            setShow(value) {
                this.show = value
            }
        }
    }
</script>

<style>
.dropdown-toggle:after {
    display: inline-block;
    margin-left: .25rem;
    width: 20px;
    height: 20px;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%2364748b' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
}
</style>
