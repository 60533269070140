<template>

        <div class="relative bg-white rounded-md border transition-shadow duration-200 dark:bg-transparent dark:border-gray-800 mb-6">
            <div class="divide-y lg:divide-y-0 lg:divide-x flex flex-col lg:flex-row">
                <div class="flex-1 px-6 py-3 lg:py-0 lg:my-3 flex items-center justify-between">
                    <div>
                        <div class="mt-2 text-sm text-opacity-75 text-gray-900 mb-px">Incoming approvals</div>
                        <select id="incoming_approvals" @change="timePeriodChange($event)" name="time_period"
                                v-model="timePeriod"
                                class="block border-0 focus:ring-0 leading-4 pl-0 pr-7 py-1 rounded text-xs text-gray-500">
                            <option value="total" selected>Total</option>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="this_month">This month</option>
                            <option value="last_month">Last month</option>
                            <option value="last_6_months">Last 6 months</option>
                            <option value="this_year">This year</option>
                            <option value="last_year">Last year</option>
                        </select>
                    </div>
                    <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300 flex items-center space-x-2">
                        <span>{{ this.data.approvalsRequested }}</span>
                        <span v-if="this.data.approvalsRequestedGrowth < 0"
                              class="mt-2 text-2xs font-medium px-1 py-0.5 rounded bg-red-100 bg-opacity-50 font-normal align-top tracking-tight text-red-500"><svg
                            class="inline w-2 h-2 text-red-600" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path
                            d="M13 12h7l-8 8-8-8h7V4h2z"></path></svg> {{ this.data.approvalsRequestedGrowth }}% </span>
                        <span v-else
                              class="text-2xs font-medium px-1 py-0.5 rounded bg-teal-100 bg-opacity-50 font-normal align-top tracking-tight text-teal-600">
                            <svg class="inline w-2 h-2 text-teal-600" fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path
                                fill="none" d="M0 0h24v24H0z"/><path d="M13 12v8h-2v-8H4l8-8 8 8z"/></svg>
                            {{ this.data.approvalsRequestedGrowth }}%
                        </span>
                    </h3>
                </div>
                <div class="flex-1 px-6 py-3 lg:py-0 lg:my-3 flex items-center justify-between">
                    <div>
                        <div class="mt-2 text-sm text-opacity-75 text-gray-900 mb-px">Approved</div>
                        <select id="approved_partners" @change="timePeriodChange($event)" name="time_period"
                                v-model="timePeriod"
                                class="block border-0 focus:ring-0 leading-4 pl-0 pr-7 py-1 rounded text-xs text-gray-500">
                            <option value="total" selected>Total</option>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="this_month">This month</option>
                            <option value="last_month">Last month</option>
                            <option value="last_6_months">Last 6 months</option>
                            <option value="this_year">This year</option>
                            <option value="last_year">Last year</option>
                        </select>
                    </div>
                    <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300 flex items-center space-x-2">
                        <span>{{ this.data.approvedApprovals }}</span>
                        <span
                            class="text-2xs font-medium px-1 py-0.5 rounded bg-teal-100 bg-opacity-50 font-normal align-top tracking-tight text-teal-600">
                            {{ this.data.approvedApprovalsPercentage }}%
                        </span>
                    </h3>
                </div>
            </div>
        </div>

</template>
<script>
export default {
    name: "ViewApprovalsStats",
    props: ['data_url'],
    data() {
        return {
            errors: [],
            loading: false,
            timePeriod: 'today',
            data: {
                approvalsTotal: 0,
                approvalsRequested: 0,
                approvalsRequestedGrowth: 0,
                approvedApprovals: 0,
                approvedApprovalsPercentage: 0,
            }
        }
    },
    created() {
        this.refreshData();
    },
    methods: {
        refreshData() {

            this.loading = true;

            axios
                .post(this.data_url, {filter: {time_period: this.timePeriod}})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        this.data.approvalsTotal = response.data.data.total_approvals ?? 0;
                        this.data.approvalsRequested = response.data.data.approvals_requested ?? 0;
                        this.data.approvalsRequestedGrowth = response.data.data.approvals_requested_growth ?? 0;
                        this.data.approvedApprovals = response.data.data.approvals_approved ?? 0;
                        this.data.approvedApprovalsPercentage = response.data.data.approvals_percentage_from_total ?? 0;
                    }
                })
                .catch(error => {
                    this.errors = error.response.data.errors || [];
                })
                .finally(() => this.loading = false);
        },
        timePeriodChange() {
            this.refreshData();
        }
    }
}
</script>

<style scoped>

</style>
