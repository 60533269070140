<template>
  <div class="px-2 py-0.5">
    <component
        :is="tag"
        :type="tag === 'button' && buttonType"
        :href="href"
        class="block px-3 py-1.5 text-sm tracking-wide leading-5 hover:bg-gray-200 hover:bg-opacity-50 focus:outline-none w-full text-left min-w-48 rounded dark:text-gray-400 dark:hover:bg-gray-800"
        :class="[
          isDisabled ? 'cursor-not-allowed opacity-50 pointer-events-none' : '',
        ]"
        @click.native="handleClick"
    >
        {{ title }}
    </component>
  </div>
</template>

<script>
    export default {
        props: {
            title: '',
            href: {
                type: String,
            },
            buttonType: {
                default: 'button',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            preventDoubleClick: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                preventClick: false
            }
        },
        computed: {
            tag() {
                return this.href ? 'a' : 'button'
            },
            isDisabled() {
                return this.disabled || this.preventClick
            }
        },
        methods: {
            handleClick(event) {
                if (this.preventClick) {
                    event.preventDefault();
                    event.stopPropagation();
                } else if (this.preventDoubleClick > 0) {
                    this.$nextTick(() => {
                        this.preventClick = true;
                        setTimeout(() => this.preventClick = false, this.preventDoubleClick)
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
