<script setup>
import {onMounted, ref} from "vue";
import Btn from "../../../../elements/Btn.vue";
import CouponForm from "./CouponForm.vue";
import {useCouponStore} from "../../../../stores/app/programs/common/coupons/CouponStore";
import ProgramProviderSyncTypeForm from "./ProgramProviderSyncTypeForm.vue";

    const props = defineProps(
        {
            couponData: [String, Object, null],
            couponAction: [String],
            program: Object,
            syncProvider: [String, null],
            syncType: [String, null],
            csrf: String,
        }
    )

    const couponStore = useCouponStore()

    onMounted(() => {
        couponStore.fillFormDataFromCouponData(props.couponData)
    })

    const formUrl = route('programs.coupon.create.store', {program: props.program})
    const couponListRoute = route('programs.coupons.list', {'program': props.program})

    const submitButtonName = props.couponAction === 'create' ? 'Create' : 'Back'

    const formData = ref({
        sync_provider: props.syncProvider ?? null,
        id: props.couponData.id ?? '',
    })

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<template>
    <div>
        <ProgramProviderSyncTypeForm v-if="syncProvider && syncProvider === 'stripe' && !syncType" :syncProvider="syncProvider" :program="program"/>
        <form v-else method="post" :action="formUrl">
        <card>
            <template #body>

                <input type="hidden" name="_token" :value="csrf"/>
                <input type="hidden" name="id" v-model="formData.id"/>
                <input type="hidden" name="sync_provider" v-model="formData.sync_provider"/>
                <form-layout>
                    <CouponForm :couponData="couponData"
                                :couponAction="couponAction"
                                :program="program"
                                :syncProvider="syncProvider"
                    />
                </form-layout>
            </template>
            <template #footer>
                <btn v-if="couponAction === 'edit'" button-type="link" :href="couponListRoute">Back</btn>
                <btn v-else button-type="submit">{{ submitButtonName }}</btn>
            </template>
        </card>
    </form>
    </div>
</template>

