<template>
    <modal title="Approve request" size="small">
        <template #toggle>
            <btn type="primary" class="ml-3">Approve</btn>
        </template>
        <template #body>
            <div class="space-y-6">
                <div class="text-sm text-gray-500">
                    Do you really want to approve this request?
                </div>
            </div>
        </template>
        <template #footer>
            <btn type="primary"
                 @click.native="approvePayoutRequest()"
                 :loading="updatingPayout"
                 :disabled="updatingPayout"
            >
                Yes, approve
            </btn>
        </template>
    </modal>
</template>

<script>
import {ref} from "vue";
import usePayouts from "../../../../../composables/app/payouts";

export default {
    name: "ApproveRequestModal",
    emits: ['payout-updated'],
    props: {
        programId: {
            required: true
        },
        payoutId: {
            required: true
        }
    },
    setup() {
        const resubmissionReason = ref('')
        const { updatePayout, updatingPayout } = usePayouts()

        return {
            resubmissionReason,
            updatePayout,
            updatingPayout
        }
    },
    methods: {
        approvePayoutRequest() {
            this.updatePayout(this.programId, { approve: 1, payout_request: this.payoutId }).finally(() => {
                this.$emit('payout-updated')
            })
        }
    }
}
</script>

<style scoped>

</style>
