import Vuex from 'vuex';
import { folder } from './folder'
import { file } from './file'
import { main } from './main'
import { fileUpload } from './fileUpload'

export default new Vuex.Store({
    modules: {
        folder,
        file,
        main,
        fileUpload
    }
})
