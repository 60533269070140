<template>
    <filters-and-pagination
        :pagination-data="filtersAndPaginationData"
        :filterable-fields="filterFields"
        :data-exists="dataExists"
        :loading-data="loadingFiltersAndPaginationData"
        :showTableFilter="showTableFilter"
        model-name="payouts"
    >
        <payout-data-table :payouts="filtersAndPaginationData.data"
                           :bank-transfer-fields="bankTransferFields"
                           :table-headers="tableHeaders"
        />
    </filters-and-pagination>
</template>

<script>
import FiltersAndPagination from "../../filters-and-pagination/FiltersAndPagination";
import PayoutDataTable from "./components/PayoutDataTable";
import useRecentActivities from "../../../../composables/common/filter-and-pagination-data";
import {onMounted, toRef, computed} from "vue";
import useFiltersAndPagination from "../../../../state/common/filters-and-pagination";
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";

export default {
    name: "PayoutsIndex",
    components: {
        FiltersAndPagination,
        PayoutDataTable
    },
    props: {
        showTableFilter: {
            type: Boolean,
            default: true
        },
        payoutRequestsUrl: {
            default: ''
        },
        payoutExportUrl: {
            default: ''
        },
        filterFields: {
            default: {}
        },
        tableHeaders: {
            default: []
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        },
        metaData: {
            type: Object,
            default: () => {}
        },
        gateway: {
            type: String,
            default: 'all'
        },
        bankTransferFields: {
            type: Object,
            default: () => {}
        },
    },
    setup(props) {
        const payoutRequestsUrl = toRef(props, 'payoutRequestsUrl')
        const payoutExportUrl = toRef(props, 'payoutExportUrl')
        const filterFields = toRef(props, 'filterFields')
        const metaData = toRef(props, 'metaData')
        const gateway = toRef(props, 'gateway')

        const {
            loadingFiltersAndPaginationData,
            filtersAndPaginationData,
            getFiltersAndPaginationData
        } = useRecentActivities()

        const {setDataBaseUrl, setFetchDataFunction, setMetaData} = useFiltersAndPagination()
        const {exportSetup} = useTableDataExporter()

        const exportSetupName = computed(() => {
            return gateway.value + '_mass_payout'
        })

        onMounted(() => {
            setDataBaseUrl(payoutRequestsUrl.value)
            setFetchDataFunction(getFiltersAndPaginationData)
            setMetaData(metaData.value)

            exportSetup.value.name = exportSetupName
            exportSetup.value.exportModelNameSingular = 'payment'
            exportSetup.value.exportModelNamePlural = 'payments'
            exportSetup.value.exportUrl = payoutExportUrl.value
        })

        return {
            filtersAndPaginationData,
            loadingFiltersAndPaginationData,
            filterFields,
        }
    }
}
</script>

