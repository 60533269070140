<template>

    <div class="flex flex-shrink-0 justify-center items-center rounded font-semibold tracking-tight"
         :class="[
            [types[type].style],
            [sizes[size].style],
        ]"
    >
        {{ value }}
    </div>

</template>

<script>
    export default {
        props: {
            value: '',
            type: {
                default: 'affiliate',
            },
            size: {
                default: 'lg',
            },
        },
        data() {
            return {
                types: {
                    affiliate: { style: 'text-teal-600 bg-teal-300 bg-opacity-30'},
                    referral: { style: 'bg-orange-100 text-orange-900'},
                    newsletter: { style: 'bg-pink-100 text-pink-900'},
                },
                sizes: {
                    xl: { style: 'w-20 h-20 text-xl'},
                    lg: { style: 'w-20 h-20 text-xl'},
                    md: { style: 'w-14 h-14 text-md'},
                    sm: { style: 'w-10 h-10 text-sm'},
                },
            }
        },
    }
</script>

<style scoped>

</style>
