<script setup>
   import {useCouponSyncStore} from "../../../../stores/app/programs/common/coupons/CouponSyncStore";
   import InitialLoadingSkeletonRows from "../../../common/InitialLoadingSkeletonRows.vue";
   import ProgressBar from "../../../../elements/ProgressBar.vue";
   import NoSearchDataRow from "../../../common/NoSearchDataRow.vue";
   import {inject, ref, watch} from "vue";
   const emitter = inject('emitter');

   const props = defineProps({
       programId: {
           type: Number,
           required: true
       },
       syncProvider: {
           type: String,
           required: true
       }
   })

   const syncCouponSettingsRef = ref({});

   const couponSyncStore = useCouponSyncStore()

   watch(() => syncCouponSettingsRef.value[props.syncProvider]?.showModal, (newValue) => {
       if (newValue) {
           if (props.syncProvider === 'stripe') {
                couponSyncStore.getProgramProviderCurrentSyncType(props.syncProvider)
           } else {
               couponSyncStore.syncSettings.syncType = 'all'
               couponSyncStore.syncSettings.syncTypeDisabled = true
           }
       } else {
           couponSyncStore.syncSettings.selectedIds = []
           couponSyncStore.syncSettings.syncType = 'all'
           couponSyncStore.syncSettings.syncTypeDisabled = false
           couponSyncStore.searchQueries[props.syncProvider] = ''
           couponSyncStore.syncProviderCoupons = []
       }
   })

   let searchTimeout = null;
   watch(() => couponSyncStore.searchQueries[props.syncProvider], () => {
       clearTimeout(searchTimeout);

       searchTimeout = setTimeout(() => {
           if (couponSyncStore.searchQueries[props.syncProvider]) {
               couponSyncStore.searchSyncProviderCoupon(props.syncProvider)
           } else if (couponSyncStore.searchQueries[props.syncProvider] === '' && syncCouponSettingsRef.value[props.syncProvider]?.showModal){
               couponSyncStore.getSyncProviderCouponList(props.syncProvider)
           }
       }, 500)
   })

   watch(() => couponSyncStore.syncSettings.syncType, (newValue, oldValue) => {
       couponSyncStore.syncSettings.selectedIds = []
       if (newValue === 'custom') {
         couponSyncStore.getSyncProviderCouponList(props.syncProvider)
       }
   })

   const TABLE_COLUMNS_COUNT = 4;

   const setCouponSyncSettings = (type) => {
       if (type === 'visible') {
           if (couponSyncStore.syncSettings.syncType !== 'custom') {
               couponSyncStore.syncSettings.syncType = 'custom'
           }
           couponSyncStore.syncSettings.selectedIds = []
           couponSyncStore.syncProviderCoupons.forEach(coupon => {
               couponSyncStore.syncSettings.selectedIds.push(coupon.id)
           })
         emitter.emit('hide-options', 'coupon_select_options')
       } else if (type === 'all') {
           couponSyncStore.syncSettings.selectedIds = []
           couponSyncStore.syncProviderCoupons = []
       }
   }

   const resetSettingsToDefaults = () => {
       couponSyncStore.syncSettings.syncType = 'all'
       couponSyncStore.syncSettings.limit = 10
       couponSyncStore.syncSettings.selectedIds = []
       couponSyncStore.searchQueries[props.syncProvider] = ''
       couponSyncStore.syncProviderCoupons = []
   }
</script>

<template>
    <modal
            size="large"
            :title="couponSyncStore.capitalizeProviderName(syncProvider) + ' coupons sync'"
            :ref="(element) => syncCouponSettingsRef[syncProvider] = element"
        >
            <template #toggle>
                <options-item :key="'sync-btn-' + syncProvider"
                              :title="'Sync ' + couponSyncStore.capitalizeProviderName(syncProvider) + ' coupons'"
                              :disabled="couponSyncStore.syncInProgress"
                >
                </options-item>
            </template>
            <template #body>

                <div class="space-y-6">
                    <div class="space-y-4">
                        <div class="text-sm text-gray-500">
                            <span v-if="syncProvider === 'stripe'">Coupons sync lets you easily sync coupons from your Stripe account and store them in Partnero. This makes it simple to assign coupons to your partners or customers.</span>
                            <span v-else>Coupons sync lets you easily sync coupons to Partnero. This makes it simple to assign coupons to your partners or customers.</span>
                        </div>
                        <select-field
                            v-model="couponSyncStore.syncSettings.syncType"
                            @click="setCouponSyncSettings($event.target.value)"
                            :disabled="couponSyncStore.syncSettings.syncTypeDisabled"
                            label="Coupons to sync"
                        >
                            <option value="all">All coupons</option>
                            <option value="custom">Custom</option>
                            <template #note v-if="couponSyncStore.syncSettings.syncType === 'all'">
                                Every coupon created will automatically sync with this program.
                            </template>
                        </select-field>
                    </div>

                    <div v-show="syncProvider === 'stripe' && couponSyncStore.syncSettings.syncType !== 'all'" class="bg-white relative w-full">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <icon name="magnifier-glass" size="16"></icon>
                        </div>
                        <input
                            v-model="couponSyncStore.searchQueries[syncProvider]"
                            type="text"
                            :name="'coupon-search-' + syncProvider"
                            :id="'coupon-search-' + syncProvider"
                            class="transition-shadow block w-full pl-9 text-sm border-gray-300 rounded bg-transparent focus:ring-0 focus:border-gray-400 placeholder-gray-400"
                            :placeholder="'Search for a coupon'"
                        >
                        <button
                            class="absolute right-2 bg-gray-500 bg-opacity-20 py-1 pl-1.5 pr-2 rounded flex items-center justify-center top-1.5 text-gray-500 focus:outline-none hover:bg-opacity-30"
                            @click.prevent="resetSettingsToDefaults"
                        >
                            <icon name="close" size="10"></icon>
                            <span class="text-xs font-medium ml-0.5">
                                Reset
                            </span>
                        </button>
                    </div>

                    <div v-if="couponSyncStore.syncSettings.syncType !== 'all'" class="space-y-4">

                        <div class="border rounded">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead>
                                <tr class="h-1">
                                    <th :colspan="TABLE_COLUMNS_COUNT">
                                        <progress-bar v-if="couponSyncStore.loadingProviderCoupons"/>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="pl-4 w-6">
                                      <options
                                          :isRight="false"
                                          name="coupon_select_options"
                                      >
                                        <template #toggle>
                                          <div class="bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                                            <icon name="arrow-down-small"></icon>
                                          </div>
                                        </template>
                                        <template #items>
                                          <options-item
                                              title="Select visible"
                                              @click="setCouponSyncSettings('visible')"
                                          />
                                        </template>
                                      </options>
                                    </th>
                                    <th scope="col"
                                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                        Coupon
                                    </th>
                                    <th scope="col"
                                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                        Terms
                                    </th>
                                    <th scope="col"
                                        class="px-4 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider">
                                        Synced to program
                                    </th>
                                </tr>
                                <tr class="h-1"/>
                                </thead>
                                <tbody class="divide-y divide-gray-200">
                                <initial-loading-skeleton-rows
                                    v-if="couponSyncStore.loadingProviderCoupons && (!couponSyncStore.syncProviderCoupons || couponSyncStore.syncProviderCoupons?.length === 0)"
                                    :number-of-table-columns="TABLE_COLUMNS_COUNT"
                                />
                                <no-search-data-row
                                    v-else-if="!couponSyncStore.loadingProviderCoupons && couponSyncStore.syncProviderCoupons?.length === 0"
                                    :number-of-table-columns="TABLE_COLUMNS_COUNT"
                                />
                                <template v-else>
                                    <tr v-for="(providerCoupon, index) in couponSyncStore.syncProviderCoupons"
                                        :key="providerCoupon.name + index"
                                        class="hover:bg-gray-50 cursor-pointer group"
                                    >
                                        <td class="pl-4 w-6">
                                            <checkbox
                                                v-model="couponSyncStore.syncSettings.selectedIds"
                                                :value="providerCoupon.id"
                                                @click="couponSyncStore.syncSettings.syncType = 'custom'"
                                                :disabled="couponSyncStore.syncSettings.syncTypeDisabled"
                                            />
                                        </td>
                                        <td
                                            class="px-4 py-4 whitespace-nowrap font-semibold text-sm text-gray-900"
                                        >
                                            {{ providerCoupon.name || '-' }}
                                        </td>

                                        <td
                                            class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                        >
                                            {{ providerCoupon.value_type_duration || '-' }}
                                        </td>
                                        <td
                                            class="px-4 py-4 whitespace-nowrap text-gray-900 text-sm"
                                        >
                                            {{ providerCoupon.program_name || 'Not synced' }}
                                        </td>
                                    </tr>
                                    <tr v-if="couponSyncStore.syncProviderCoupons.length === 0 && couponSyncStore.paginationMessage">
                                        <td :colspan="TABLE_COLUMNS_COUNT" class="text-center text-gray-500 py-4">
                                            {{ couponSyncStore.paginationMessage }}
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="flex justify-between items-center">
                            <div class="flex justify-start items-center gap-4">
                                <div class="text-xs text-gray-500">Showing</div>
                                <select
                                    class="block border-0 focus:ring-0 leading-4 pl-0 pr-6 py-0 rounded text-xs text-gray-500"
                                    v-model="couponSyncStore.syncSettings.limit"
                                    @change="couponSyncStore.getSyncProviderCouponList(props.syncProvider)"
                                >
                                    <option :value="10">10</option>
                                    <option :value="20">20</option>
                                    <option :value="50">50</option>
                                    <option :value="100">100</option>
                                </select>
                            </div>
                            <div class="flex justify-end items-center gap-2"
                                 v-if="couponSyncStore.getNextPageParam || couponSyncStore.getPrevPageParam"
                            >

                                <btn
                                    v-if="couponSyncStore.getPrevPageParam"
                                    size="pill" type="secondary" iconName="arrow-left-s" @click="couponSyncStore.getSyncProviderCouponList(props.syncProvider, 'prev')"></btn>
                                <btn
                                    v-if="couponSyncStore.getNextPageParam"
                                      size="pill" type="secondary" iconName="arrow-right-s" @click="couponSyncStore.getSyncProviderCouponList(props.syncProvider, 'next')"></btn>

                            </div>
                        </div>

                    </div>
                </div>

            </template>
            <template #footer>
                <btn
                    @click="couponSyncStore.syncCoupons(props.syncProvider)"
                    :loading="couponSyncStore.syncInProgress"
                    :disabled="couponSyncStore.syncInProgress"
                >
                    Sync
                </btn>
            </template>
</modal>
</template>

