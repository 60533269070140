<template>
    <div>
        <div class="form-group form-icon col-sm-6 col-lg-3 mb-lg-0">
            <input
                class="py-2 pr-4 w-full text-sm text-gray-500 bg-white rounded border border-gray-300 transition-shadow hover:bg-gray-50 focus:outline-none focus:ring-0 focus:border-gray-400"
                type="text"
                :id="uuid"
                :name="datepickerName"
                :value="_selectedDate"
            >
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import { v4 as uuid } from "uuid";

export default {
    components: {},
    props: {
        datepickerValue: {
            type: [String, Number],
            default: ''
        },
        datepickerName: {
            type: String,
            default: ''
        },
        defaultSelectedDate: {
            type: [String, Number, Object],
            default: moment().add(5, 'days')
        },
        autoUpdateInput: {
            type: Boolean,
            default: true
        },
        minDate: {
            type: String,
            default: ''
        },
        time: {
            type: Boolean,
            default: false
        },
        time24hr: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD'
        }
    },
    emits: ['date-changed', 'update'],
    data() {
        const preSelectedDate = this.getSelectedDate();
        return {
            uuid: uuid(),
            _selectedDate: this.formattedDate(preSelectedDate),
        }
    },
    mounted() {
        this.iniDatePicker();
    },
    methods: {
        iniDatePicker() {
            const self = this;
            $('#' + this.uuid).daterangepicker({
                'locale': {
                    'format': this.format,
                    'separator': ' - ',
                    'firstDay': 1,
                },
                timePicker: this.time,
                timePicker24Hour: this.time24hr,
                singleDatePicker: true,
                showDropdowns: true,
                autoUpdateInput: this.autoUpdateInput,
                minDate: this.minDate,
                minYear: parseInt(moment().format('YYYY'),10),
                maxYear: parseInt(moment().add(10, "years").format('YYYY'),10)
            }, (start, end, label) => {
                this._selectedDate = this.formattedDate(start);
                self.$emit('update', start);
            });
        },
        formattedDate(mDate) {
            return mDate?.format(this.format)
        },
        getSelectedDate() {
            const defaultSelectedDate = this.defaultSelectedDate ? moment(this.defaultSelectedDate) : null;
            return this.datepickerValue ? moment(this.datepickerValue) : defaultSelectedDate;
        }
    }
}
</script>
