<template>
    <div>
        <div class="space-y-4">
            <template v-for="dashboardContentBlock in data.dashboardContentBlocks">
                <div class="rounded border bg-white">
                    <div class="flex justify-between p-4 handle">
                        <div class="flex items-center space-x-3 truncate">
                            <button type="button" class="cursor-move focus:outline-none">
                                <svg class="w-5 h-5 text-gray-500 hover:text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path></svg>
                            </button>
                            <span
                                class="text-sm truncate cursor-pointer"
                                @click.native="dashboardContentBlock.edit=!dashboardContentBlock.edit"
                            >
                                Content block
                            </span>
                        </div>
                        <div class="flex pl-6 space-x-4">
                            <button type="button" class="focus:outline-none" @click="removeContentBlock(dashboardContentBlock)">
                                <svg class="w-5 h-5 text-gray-500 hover:text-red-600" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"></path></svg>
                            </button>
                            <button
                                type="button"
                                class="focus:outline-none"
                                @click.native="dashboardContentBlock.edit=!dashboardContentBlock.edit"
                            >
                                <svg class="w-5 h-5 text-gray-500 hover:text-gray-900"
                                     :class="{ 'text-teal-600 hover:text-teal-700': dashboardContentBlock.edit }"
                                     fill="currentColor"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 24 24"
                                     width="24"
                                     height="24"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"></path><path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div v-if="dashboardContentBlock.edit"
                         class="p-4 bg-gray-50 bg-opacity-75 border-t space-y-4"
                    >
                        <div class="grid lg:grid-cols-2 gap-6 gap-y-4">

                            <div class="col-span-2">
                                <label class="flex justify-between items-center mb-1.5 text-sm">
                                    Title
                                </label>
                                <input
                                    type="text"
                                    v-model="dashboardContentBlock.title"
                                    class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"
                                />
                            </div>

                            <div class="col-span-2">
                                <DashboardFileUpload
                                    :data="dashboardContentBlock.files.dashboard_content_image"
                                    :options="options"
                                    :unique_id="unique_id"
                                    accept="image/*"
                                    title="Image"
                                    :name="getImageName(dashboardContentBlock.files.dashboard_content_image.path)"
                                    no_src="/images/upload-image.png"
                                ></DashboardFileUpload>
                            </div>

                            <div class="col-span-2">
                                <text-editor
                                    :text="dashboardContentBlock.content"
                                    @input="(newValue) => { dashboardContentBlock.content = newValue }"
                                />
                            </div>

                            <div>
                                <label
                                    class="flex justify-between items-center mb-1.5 text-sm">
                                    Button
                                </label>

                                <input
                                    type="text"
                                    v-model="dashboardContentBlock.button"
                                    placeholder=""
                                    class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"
                                />
                            </div>
                            <div>
                                <label
                                    class="flex justify-between items-center mb-1.5 text-sm">
                                    URL
                                </label>

                                <input
                                    type="text"
                                    v-model="dashboardContentBlock.url"
                                    placeholder="https://"
                                    class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="flex justify-between items-center">
            <btn type="secondary" @click="addContentBlock" v-bind:class="{ 'mt-4': data.dashboardContentBlocks.length }">Add content block</btn>
            <div class="mt-4">
                <div class="flex justify-between items-center">
                    <toggle
                        name="columns_toggle"
                        size="default"
                        v-model="data.displayBlocksInColumns"
                    />
                    <span class="flex flex-col flex-grow" id="columns_toggle-label">
                        <span class="text-sm text-gray-900">Display blocks in columns</span>
                    </span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {uniqueId} from '../../../../resources-builder/helpers';
import DashboardFileUpload from "./includes/DashboardFileUpload";

export default {
    components: { DashboardFileUpload },
    props: ['unique_id', 'data', 'options'],
    created() {
        this.data.dashboardContentBlocks = this.data?.dashboardContentBlocks ?? [];
        this.data.displayBlocksInColumns = this.data?.displayBlocksInColumns ?? false;
    },
    methods: {
        addContentBlock() {
            this.data.dashboardContentBlocks.push({
                type: 'dashboardContentBlock',
                files: {
                    dashboard_content_image: {}
                },
                edit: true,
                title: '',
                content: '',
                button: '',
                url: '',
            });
        },
        removeContentBlock(dashboardContentBlock) {
            this.data.dashboardContentBlocks = this.data.dashboardContentBlocks.filter((item) => item !== dashboardContentBlock);
        },
        getImageName(path) {
            let name = '';

            if(path) {
                name = path.split('/')?.pop()?.split('.')[0];
            }
            if(!name) {
                name = 'dashboard_content_image_' + uniqueId();
            }
            return name;
        }
    }
}
</script>
