<template>
    <div>
        <label
            v-if="label"
            class="block text-sm mb-1.5"
        >
            {{ label }}
        </label>
        <div class="tiptap-menu" v-if="editor">
            <button type="button" @click="editor.chain().focus().toggleBold().run()"
                    :class="{ 'is-active': editor.isActive('bold') }">
                <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path
                        d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"/>
                </svg>
            </button>
            <button type="button" @click="editor.chain().focus().toggleItalic().run()"
                    :class="{ 'is-active': editor.isActive('italic') }">
                <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z"/>
                </svg>
            </button>
            <button type="button" @click="editor.chain().focus().toggleStrike().run()"
                    :class="{ 'is-active': editor.isActive('strike') }">
                <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M13 9h-2V6H5V4h14v2h-6v3zm0 6v5h-2v-5h2zM3 11h18v2H3v-2z"/>
                </svg>
            </button>
            <button type="button" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                    :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0H24V24H0z"/>
                    <path d="M13 20h-2v-7H4v7H2V4h2v7h7V4h2v16zm8-12v12h-2v-9.796l-2 .536V8.67L19.5 8H21z"/>
                </svg>
            </button>
            <button type="button" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                    :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0H24V24H0z"/>
                    <path
                        d="M4 4v7h7V4h2v16h-2v-7H4v7H2V4h2zm14.5 4c2.071 0 3.75 1.679 3.75 3.75 0 .857-.288 1.648-.772 2.28l-.148.18L18.034 18H22v2h-7v-1.556l4.82-5.546c.268-.307.43-.709.43-1.148 0-.966-.784-1.75-1.75-1.75-.918 0-1.671.707-1.744 1.606l-.006.144h-2C14.75 9.679 16.429 8 18.5 8z"/>
                </svg>
            </button>
            <button type="button" @click="editor.chain().focus().toggleBulletList().run()"
                    :class="{ 'is-active': editor.isActive('bulletList') }">
                <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path
                        d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"/>
                </svg>
            </button>
            <button type="button" @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
                <svg class="h-5 w-5 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M17.6567 14.8284L16.2425 13.4142L17.6567 12C19.2188 10.4379 19.2188 7.90524 17.6567 6.34314C16.0946 4.78105 13.5619 4.78105 11.9998 6.34314L10.5856 7.75736L9.17139 6.34314L10.5856 4.92893C12.9287 2.58578 16.7277 2.58578 19.0709 4.92893C21.414 7.27208 21.414 11.0711 19.0709 13.4142L17.6567 14.8284ZM14.8282 17.6569L13.414 19.0711C11.0709 21.4142 7.27189 21.4142 4.92875 19.0711C2.5856 16.7279 2.5856 12.9289 4.92875 10.5858L6.34296 9.17157L7.75717 10.5858L6.34296 12C4.78086 13.5621 4.78086 16.0948 6.34296 17.6569C7.90506 19.2189 10.4377 19.2189 11.9998 17.6569L13.414 16.2426L14.8282 17.6569ZM14.8282 7.75736L16.2425 9.17157L9.17139 16.2426L7.75717 14.8284L14.8282 7.75736Z" />
                </svg>
            </button>
        </div>
        <div
            class="block w-full bg-white border px-3.5 py-2 rounded rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-indigo-100 focus:ring-2 focus:border-indigo-300">
            <editor-content :editor="editor" class="text-editor-content"/>
        </div>
        <input type="hidden" v-model="value" :name="name"/>
        <modal :title="previousModalUrlValue ? 'Edit link' : 'Add link'" ref="linkModal" size="small" name="linkModal">
            <template #body>
                <div>
                    <field-label label="URL" name="url" />
                    <text-field
                        v-model="modalUrl"
                        label="URL"
                        name="url"
                        :autofocus="true"
                        @keydown.enter.stop="confirmLink"
                    />
                    <!-- This should be a setup for opening in new tab -->
                    <toggle class="mt-8" v-model="openLinkInNewTab" title="Open link in new tab" name="open_in_new_tab" />
                </div>
            </template>
            <template #footer>
                <btn type="primary" @click="confirmLink">{{ previousModalUrlValue ? 'Update' : 'Add' }}</btn>
            </template>
        </modal>
    </div>
</template>

<script>
import {
    Editor, EditorContent,
    BubbleMenu,
    FloatingMenu,
} from '@tiptap/vue-3'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import FieldLabel from "./forms/FieldLabel.vue";

export default {
    components: {
        FieldLabel,
        EditorContent,
        BubbleMenu,
        FloatingMenu,
    },
    emits: ['input'],
    props: {
        name: {
            default: 'editor'
        },
        text: {
            default: ''
        },
        label: {
            default: false
        }
    },
    data() {
        return {
            editor: null,
            value: this.text,
            modalUrl: '',
            previousModalUrlValue: '',
            openLinkInNewTab: true,
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.$emit('input', newValue)
            },
            immediate: true
        }
    },
    methods: {
        setLink() {
            this.modalUrl = this.editor.getAttributes('link').href
            this.previousModalUrlValue = this.modalUrl
            this.$refs.linkModal.onShow()
        },
        confirmLink(event) {
            const linkAttrs = {
                href: this.modalUrl,
                target: this.openLinkInNewTab ? '_blank' : null,
            };

            if (event) {
                event.stopPropagation();
                event.preventDefault();
            }

            if (!this.modalUrl) {
                this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
            } else {
                this.editor.chain().focus().extendMarkRange('link').setLink(linkAttrs).run();
            }

            this.$refs.linkModal.onCancel()
        }
    },
    mounted() {
        this.editor = new Editor({
            content: this.value,
            onUpdate: () => {
                this.value = this.editor.getHTML();
            },
            extensions: [
                StarterKit,
                Link.configure({
                    openOnClick: false,
                    defaultProtocol: 'https',
                }).extend({
                    inclusive: false,
                    exitable: true,
                }),
            ],
        })
    },

    beforeUnmount() {
        this.editor.destroy();
    },
}
</script>

<style lang="scss">
.ProseMirror {
    min-height: 70px;

    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    &:focus, &:active {
        outline: none;
    }

    h1 {
        font-weight: bold;
        font-size: 32px;
        line-height: 1.1;
    }

    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.1;
    }
}

.tiptap-menu {
    display: flex;
    //background-color: #111827;
    //padding: 0.4rem .5rem;
    //border-radius: 0.2rem;

    button {
        border: none;
        background: none;
        color: #FFF;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0 0.4rem;
        opacity: 0.6;

        &:focus, &:active {
            outline: none;
        }

        &:hover,
        &.is-active {
            opacity: 1;
        }
    }
}

.floating-menu {
    display: flex;
    background-color: #111827;
    padding: 0.2rem;
    border-radius: 0.5rem;

    button {
        border: none;
        background: none;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0 0.2rem;
        opacity: 0.6;

        &:hover,
        &.is-active {
            opacity: 1;
        }
    }
}
</style>
