import {v4 as uuid} from "uuid";

export function uniqueId() {
    return 'a' + uuid().split('-')[0];
}

export function resourceOptions(programType) {

    let resourceOptions = [
        {type: 'content_block', name: 'Content block'},
        {type: 'image', name: 'Image'},
        {type: 'text', name: 'Text'},
        {type: 'cta', name: 'CTA'},
        {type: 'faq', name: 'FAQ'},
        {type: 'link', name: 'Links'}
    ];

    if (programType === 'refer_a_friend') {
        resourceOptions.push(
            {
                type: 'available_rewards', name: 'Available rewards'
            }
        );
    }

    return resourceOptions;
}

export function getResourceName(type) {
    let resourceOption = resourceOptions().find(element => element.type === type);
    if (resourceOption) {
        return resourceOption.name;
    }
    return 'Resource';
}

export function isValidJson(jsonString) {
    if (typeof jsonString !== 'string') {
        return false;
    }
    try {
        JSON.parse(jsonString);
    } catch (e) {
        return false;
    }
    return true;
}
