<template>
    <div>
        <modal title="Appearance of Public pages" size="huge" ref="public_pages_appearance">
            <template #toggle>
                <btn type="secondary" icon-name="settings" size="small">Customize</btn>
            </template>
            <template #body>
                <div class="divide-y divide-gray-200">
                    <form-section title="Default"
                                  description="The default styling elements of your public pages on the Partner portal.">
                        <div class="grid lg:grid-cols-2 gap-8">
                            <radio-group-inline-color
                                name="public_page_background_color"
                                label="Background color"
                                @picked="settings.public_page_background_color = $event.finalValue"
                                :model-value="settings.public_page_background_color"
                                :options="[
                                    { value: 'white', label: 'bg-white', label_is_class: true},
                                    { value: 'gray-50', label: 'bg-gray-50', label_is_class: true},
                                    { value: 'gray-100', label: 'bg-gray-100', label_is_class: true},
                                    { value: 'gray-200', label: 'bg-gray-200', label_is_class: true},
                                    { value: 'gray-300', label: 'bg-gray-300', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'public_page_background_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>

                            <radio-group-inline-color
                                name="public_page_background_gradient_color"
                                label="Background gradient color"
                                @picked="settings.public_page_background_gradient_color = $event.finalValue"
                                :model-value="settings.public_page_background_gradient_color"
                                :options="[
                                    { value: 'black', label: 'bg-black', label_is_class: true },
                                    { value: 'gray-300', label: 'bg-gray-300', label_is_class: true },
                                    { value: 'blue-700', label: 'bg-blue-700', label_is_class: true },
                                    { value: 'teal-600', label: 'bg-teal-600', label_is_class: true },
                                    { value: 'none', label: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEwIi8+PGxpbmUgeDE9IjQuOTMiIHkxPSI0LjkzIiB4Mj0iMTkuMDciIHkyPSIxOS4wNyIvPjwvc3ZnPg==', label_is_img: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'public_page_background_gradient_color', placeholder: '', type: 'input' }},
                                ]"
                            ></radio-group-inline-color>

                            <file-upload
                                :src="appearance.get('public_page_background_image')"
                                no_src="/images/upload-image.png"
                                accept="image/*"
                                title="Background"
                                name="public_page_background_image"
                                :upload_url="fileUploadRoute"
                                :delete_url="fileDeleteRoute"
                                show_validation_errors_within_uploader
                            >
                            </file-upload>

                            <radio-group-inline-color
                                label="Text color"
                                name="public_page_text_color"
                                @picked="settings.public_page_text_color = $event.finalValue"
                                :model-value="settings.public_page_text_color"
                                :options="[
                                    { value: 'gray-900', label: 'bg-gray-900', label_is_class: true},
                                    { value: 'gray-800', label: 'bg-gray-800', label_is_class: true},
                                    { value: 'gray-700', label: 'bg-gray-700', label_is_class: true},
                                    { value: 'gray-600', label: 'bg-gray-600', label_is_class: true},
                                    { value: 'gray-500', label: 'bg-gray-500', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'public_page_text_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>

                            <radio-group-inline-color
                                v-if="!appearance.isTrue('portal_layout_brand_primary_color_as_partner_and_pages_primary')"
                                label="Link color"
                                name="public_page_link_color"
                                @picked="settings.public_page_link_color = $event.finalValue"
                                :model-value="settings.public_page_link_color"
                                :options="[
                                    { value: 'indigo-700', label: 'bg-indigo-700', label_is_class: true},
                                    { value: 'blue-700', label: 'bg-blue-700', label_is_class: true},
                                    { value: 'cyan-700', label: 'bg-cyan-700', label_is_class: true},
                                    { value: 'teal-600', label: 'bg-teal-600', label_is_class: true},
                                    { value: 'gray-900', label: 'bg-gray-900', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'public_page_link_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>
                        </div>
                    </form-section>

                    <form-section title="Content style"
                                  description="The styling for default elements of your public pages on the Partner portal.">
                        <div class="grid lg:grid-cols-2 gap-8">
                            <radio-group-inline
                                label="Layout"
                                name="public_page_content_layout"
                                v-model="settings.public_page_content_layout"
                                :options="[
                                    { value: 'one', label: 'Default'},
                                    { value: 'two', label: 'Casual'},
                                ]">
                            </radio-group-inline>

                            <div></div>

                            <radio-group-inline
                                v-if="settings.public_page_content_layout === 'two'"
                                label="Layout style"
                                name="public_page_content_layout_style"
                                v-model="settings.public_page_content_layout_style"
                                :options="[
                                    { value: 'border', label: 'Border'},
                                    { value: 'shadow', label: 'Shadow'},
                                    { value: 'none', label: 'None'}
                                ]">
                            </radio-group-inline>

                            <radio-group-inline-color
                                v-if="settings.public_page_content_layout === 'two'"
                                label="Layout corners"
                                name="public_page_content_layout_corners"
                                @picked="settings.public_page_content_layout_corners = $event.finalValue"
                                :model-value="settings.public_page_content_layout_corners"
                                :options="[
                                    {
                                        value: 'none',
                                        label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8cmVjdCB4PSIyIiB5PSIyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjQiIGZpbGw9Im5vbmUiPjwvcmVjdD48L3N2Zz4=',
                                        label_is_img: true
                                    },
                                    {
                                        value: 'rounded-lg',
                                        label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8cmVjdCB4PSIyIiB5PSIyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHJ4PSI0IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSI0IiBmaWxsPSJub25lIj48L3JlY3Q+PC9zdmc+',
                                        label_is_img: true
                                    },
                                    {
                                        value: 'rounded-xl',
                                        label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8cmVjdCB4PSIyIiB5PSIyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHJ4PSI4IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSI0IiBmaWxsPSJub25lIj48L3JlY3Q+PC9zdmc+',
                                        label_is_img: true
                                    },
                                    {
                                        value: 'rounded-3xl',
                                        label: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy00IGgtNCIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDI4IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiA8cmVjdCB4PSIyIiB5PSIyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHJ4PSIxMCIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iNCIgZmlsbD0ibm9uZSI+PC9yZWN0Pjwvc3ZnPg==',
                                        label_is_img: true
                                    }

                                ]">
                            </radio-group-inline-color>

                            <radio-group-inline-color
                                v-if="settings.public_page_content_layout === 'two'"
                                label="Content background"
                                name="public_page_content_background_color"
                                @picked="settings.public_page_content_background_color = $event.finalValue"
                                :model-value="settings.public_page_content_background_color"
                                :options="[
                                    { value: 'white', label: 'bg-white', label_is_class: true},
                                    { value: 'gray-50', label: 'bg-gray-50', label_is_class: true},
                                    { value: 'gray-100', label: 'bg-gray-100', label_is_class: true},
                                    { value: 'gray-200', label: 'bg-gray-200', label_is_class: true},
                                    { value: 'gray-300', label: 'bg-gray-300', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'public_page_content_background_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>

                            <radio-group-inline-color
                                label="Text color"
                                name="public_page_content_text_color"
                                @picked="settings.public_page_content_text_color = $event.finalValue"
                                :model-value="settings.public_page_content_text_color"
                                :options="[
                                    { value: 'gray-900', label: 'bg-gray-900', label_is_class: true},
                                    { value: 'gray-800', label: 'bg-gray-800', label_is_class: true},
                                    { value: 'gray-700', label: 'bg-gray-700', label_is_class: true},
                                    { value: 'gray-600', label: 'bg-gray-600', label_is_class: true},
                                    { value: 'gray-500', label: 'bg-gray-500', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'public_page_content_text_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>

                            <radio-group-inline-color
                                v-if="!appearance.isTrue('portal_layout_brand_primary_color_as_partner_and_pages_primary')"
                                label="Link color"
                                name="public_page_content_link_color"
                                @picked="settings.public_page_content_link_color = $event.finalValue"
                                :model-value="settings.public_page_content_link_color"
                                :options="[
                                    { value: 'indigo-700', label: 'bg-indigo-700', label_is_class: true},
                                    { value: 'blue-700', label: 'bg-blue-700', label_is_class: true},
                                    { value: 'cyan-700', label: 'bg-cyan-700', label_is_class: true},
                                    { value: 'teal-600', label: 'bg-teal-600', label_is_class: true},
                                    { value: 'gray-900', label: 'bg-gray-900', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'public_page_content_link_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>

                            <radio-group-inline-color
                                v-if="!appearance.isTrue('portal_layout_brand_primary_color_as_partner_and_pages_primary')"
                                label="Button color"
                                name="public_page_content_button_color"
                                @picked="settings.public_page_content_button_color = $event.finalValue"
                                :model-value="settings.public_page_content_button_color"
                                :options="[
                                    { value: 'indigo', label: 'bg-indigo-700', label_is_class: true},
                                    { value: 'blue', label: 'bg-blue-700', label_is_class: true},
                                    { value: 'cyan', label: 'bg-cyan-700', label_is_class: true},
                                    { value: 'teal', label: 'bg-teal-600', label_is_class: true},
                                    { value: 'black', label: 'bg-black', label_is_class: true},
                                    { value: 'custom', label: 'Custom', custom: { name: 'public_page_content_button_color', placeholder: '', type: 'input' }},
                                ]">
                            </radio-group-inline-color>

                            <radio-group-inline
                                label="Input style"
                                name="public_page_content_input_style"
                                v-model="settings.public_page_content_input_style"
                                :options="[
                                    { value: 'white', label: 'White'},
                                    { value: 'transparent', label: 'Transparent'}
                                ]">
                            </radio-group-inline>
                        </div>
                    </form-section>
                </div>
            </template>
            <template #footer>
                <btn @click="updateSettings" :loading="loading.updating">Update</btn>
            </template>
        </modal>
    </div>
</template>

<script setup>

import {ref} from 'vue';
import appearance from "../../../../../../helpers/appearanceConfig";
import Modal from "../../../../../../elements/Modal.vue";
import FormSection from "../../../../../../elements/forms/FormSection.vue";

const props = defineProps({
    program: {
        type: [String, Number],
        required: true,
    }
});

const errors = ref({});
const loading = ref({
    updating: false
});
const public_pages_appearance = ref(null);

const fileUploadRoute = route('app_int.program_settings_file.upload', {program: props.program});
const fileDeleteRoute = route('app_int.program_settings_file.delete', {program: props.program});

const settings = ref({
    public_page_background_color: appearance.get('public_page_background_color', 'gray-50'),
    public_page_background_gradient_color: appearance.get('public_page_background_gradient_color', 'none'),
    public_page_text_color: appearance.get('public_page_text_color', 'gray-800'),
    public_page_link_color: appearance.get('public_page_link_color', 'teal-600'),
    public_page_content_layout: appearance.get('public_page_content_layout', 'two'),
    public_page_content_layout_style: appearance.get('public_page_content_layout_style', 'border'),
    public_page_content_layout_corners: appearance.get('public_page_content_layout_corners', 'rounded-xl'),
    public_page_content_background_color: appearance.get('public_page_content_background_color', 'white'),
    public_page_content_text_color: appearance.get('public_page_content_text_color', 'gray-800'),
    public_page_content_link_color: appearance.get('public_page_content_link_color', 'teal-600'),
    public_page_content_button_color: appearance.get('public_page_content_button_color', 'teal'),
    public_page_content_input_style: appearance.get('public_page_content_input_style', 'white'),
});

const updateSettings = () => {

    if (loading.value.updating) {
        return;
    }
    loading.value.updating = true;

    axios
        .post(route('app_int.program.portal.settings.appearance_of_public_pages.update', {program: props.program}), {
            settings: settings.value,
        })
        .then(response => {
            public_pages_appearance.value.onCancel();
        })
        .catch(error => {
            errors.value = error?.response?.data?.errors || {};
        })
        .finally(() => loading.value.updating = false);
};

</script>

<style scoped>

</style>
