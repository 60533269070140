<script setup>
    import {ref} from "vue";

    const props = defineProps({
        programId: {
            type: Number,
            required: true
        },
        couponNotifications: {
            type: Array,
            required: true
        },
        couponsNotificationsGroupType: {
            type: String,
            required: true
        }
    })

    const notifications = ref(props.couponNotifications)

    const markAsRead = (markAllAsRead = false, notificationId = null) => {
        const data = {
            groupType: props.couponsNotificationsGroupType,
            markAllAsRead: markAllAsRead
        }

        if (notificationId) {
            data.notificationId = notificationId
        }

        axios.post(route('app_int.notification.mark_notification' , {program: props.programId}), data)
            .then(response => {
            notifications.value = response.data
        })
    }

</script>

<template>
    <div v-if="notifications.length">
        <div v-for="notification in notifications" :key="notification.id"
            class="border px-4 py-3 rounded relative text-sm mb-3"
            :class="{
                'bg-teal-100 border-teal-400 text-teal-700': notification.status === 'success',
                'bg-red-100 border-red-400 text-red-700': notification.status === 'error'
        }"
        >
            {{ notification.created_at }} {{ notification.message }}
            <p class="float-right mark-as-read mb-0 cursor-pointer text-sm"
               :class="{'text-blue-700': notification.status === 'success', 'text-red-700': notification.status === 'error'}"
               @click="markAsRead(false, notification.id)"
            >
                    Mark as read
            </p>
        </div>
        <div v-if="notifications.length > 1"
             class="flex justify-end pr-3 mb-4"
        >
            <p @click="markAsRead(true)" class="cursor-pointer text-sm text-blue-700">
                Mark all as read
            </p>
        </div>
    </div>
</template>

