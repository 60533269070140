<template>
    <div class="h-1 relative rounded-full overflow-hidden">
        <div class="w-full h-full bg-gray-200 absolute"></div>
        <div ref="bar" class="h-full bg-teal-600 relative style='width:10%'"></div>
    </div>
</template>

<script>
import {onMounted, onUnmounted, ref} from "vue";

export default {
    name: "ProgressBar",
    setup() {
        const bar = ref(null);
        let progress = 0;
        const intervalSpeed = 15;
        const incrementSpeed = 1;
        let runIndicator;

        const startIndicator = () => {
            runIndicator = setInterval(() => {
                progress += incrementSpeed;
                bar.value.style.width = progress + "%";
                if (progress >= 100) {
                    progress = 0;
                }
            }, intervalSpeed);
        };

        onMounted(() => {
            if (bar.value !== null) {
                startIndicator();
            }
        });

        onUnmounted(() => {
            clearInterval(runIndicator);
        });

        return {
            bar
        };
    },
};
</script>

