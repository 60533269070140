<template>
    <div>
        <div class="form-group form-icon col-sm-6 col-lg-3 mb-lg-0">
            <button type="button" :id="uuid" name="dates"
                    class="inline-flex items-center py-2 pl-10 pr-4 w-full text-sm text-gray-500 bg-white rounded border border-gray-300 transition-shadow hover:bg-gray-50 focus:outline-none focus:ring-0 focus:border-gray-400">
                Date from: <span class="ml-2 text-gray-700">{{ _fromDate }}</span> <span
                class="px-2">-</span> <span class="text-gray-700">{{ _toDate }}</span>
            </button>
            <input type="hidden" name="date_to" :value="_toDate">
            <input type="hidden" name="date_from" :value="_fromDate">
        </div>
    </div>
</template>

<script>
import daterangepickerCss from 'daterangepicker/daterangepicker.css'
import daterangepicker from 'daterangepicker'
import moment from 'moment-timezone'
import {v4 as uuid} from "uuid";

export const DEFAULT_RANGES = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
}

export default {
    components: {},
    props: ['date_from', 'date_to', 'ranges'],
    data() {
        const startEndDates = this.getStartEndDates();
        return {
            uuid: uuid(),
            toDate: startEndDates.to,
            fromDate: startEndDates.from,
            _toDate: this.formattedDate(startEndDates.to),
            _fromDate: this.formattedDate(startEndDates.from)
        }
    },
    watch: {
        _toDate(){
            this.emitDatepickerUpdate()
        },
        _fromDate(){
            this.emitDatepickerUpdate()
        }
    },
    mounted() {
        this.iniDatePicker();
    },
    methods: {
        iniDatePicker() {
            const self = this;
            $('#' + this.uuid).daterangepicker({
                'startDate': this.fromDate,
                'endDate': this.toDate,
                'locale': {
                    'format': 'YYYY-MM-DD',
                    'separator': ' - ',
                    'firstDay': 1,
                },
                ranges: this.ranges ? this.ranges : DEFAULT_RANGES,
                alwaysShowCalendars: true,
                autoApply: true
            }, (start, end, label) => {
                this._toDate = this.formattedDate(end);
                this._fromDate = this.formattedDate(start);
            }).on('apply.daterangepicker', function (ev, picker) {
                self.$emit('update', ev, picker);
            });
        },
        formattedDate(mDate) {
            return mDate.format('YYYY-MM-DD')
        },
        getStartEndDates() {
            const defaultToDate = moment().add(1, 'days').startOf('day');
            const defaultFromDate = moment().subtract(7, 'days').startOf('day');

            const _tDate = this.date_to ? moment(this.date_to, moment.ISO_8601, true) : defaultToDate;
            const _fDate = this.date_from ? moment(this.date_from, moment.ISO_8601, true) : defaultFromDate;

            return {
                'to': _tDate.isValid() ? _tDate : defaultToDate,
                'from': _fDate.isValid() ? _fDate : defaultFromDate
            };
        },
        emitDatepickerUpdate() {
            this.$emit('date-changed', {dateFrom: this._fromDate, dateTo: this._toDate, })
        }
    }
}
</script>
