<script setup>
import CouponForm from "./CouponForm.vue";
import {ref} from "vue";
import {useCouponStore} from "../../../../stores/app/programs/common/coupons/CouponStore";
import Btn from "../../../../elements/Btn.vue";

const props = defineProps(
    {
        couponData: [Object, null],
        couponAction: [String],
        program: Object,
        couponSyncProviders: {
            type: Array,
            default: []
        },
        syncProvider: {
            type: String,
            default: '',
        },
        disableInAppCoupon: {
            type: Boolean,
            default: false,
        },
        csrf: String,
        disabled: {
            type: Boolean,
            default: false,
        },
    }
)

const couponStore = useCouponStore()

const emits = defineEmits(['coupon-created', 'coupon-updated'])

const capitalize = (string) => {
    if (string === 'woocommerce') {
        return 'WooCommerce'
    }
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const getModalTitle = () => {
    if (props.couponAction === 'create') {
        return 'Create a new coupon'
    }
    return 'Edit coupon'
}

const couponModal = ref(null)

const creatingCouponThroughApi = ref(false);


const createCoupon = () => {
    creatingCouponThroughApi.value = true;

    axios.post(route('app_int.coupon.create', {program: props.program}), {
        sync_provider: couponStore.syncProvider,
        name: couponStore.formData.name,
        uuid_code: couponStore.formData.uuid_code,
        coupon_discount_type: couponStore.formData.coupon_discount_type,
        coupon_discount_amount: couponStore.formData.coupon_discount_amount,
        coupon_duration_type: couponStore.formData.coupon_duration_type,
        coupon_duration_value: couponStore.formData.coupon_duration_value,
        redemption_specific_date_status: couponStore.formData.redemption_specific_date_status,
        redemption_specific_date_value: couponStore.formData.redemption_specific_date_value,
        redemption_times_status: couponStore.formData.redemption_times_status,
        redemption_times_value: couponStore.formData.redemption_times_value,
        metadata: couponStore.formData.metadata,
        is_reward_coupon: couponStore.formData.is_reward_coupon,
        promotion_codes: couponStore.getPromotionCodes,
        auto_assign_promotion_code: couponStore.getAutoAssignPromotionCode
    })
        .then(response => {
            const status = parseInt(response.data.status || '-1');
            if (status === 1) {
                couponStore.showNotification(true, 'Done', 'success', response.data.message);
                setTimeout(() => {
                    couponModal.value.onCancel();
                    emits('coupon-created', response.data.coupon);
                }, 2000)
            } else {
                couponStore.showNotification(true, 'Error', 'warning', response.data?.message ?? 'Something went wrong');
            }
        })
        .catch(error => {
            couponStore.showNotification(true, 'Error', 'warning', error.response?.data?.message ?? 'Something went wrong');
            console.log(error)
        })
        .finally(() => {
            creatingCouponThroughApi.value = false;
        })
}

</script>

<template>
    <modal :title="getModalTitle()" size="large" ref="couponModal" name="couponModal" :disabled="disabled">
        <template #toggle>
            <btn type="secondary" class="w-full" :disabled="disabled">{{props.couponAction === 'create' ? "Create new" : "Edit"}}</btn>
        </template>
        <template #body class="divide-y">
            <coupon-form
                :program='program'
                :coupon-action="couponAction"
                :sync-provider="syncProvider"
                :coupon-data='couponData'
                create-coupon-through-api
            >
                <template #prepend-form-section>
                    <select-field
                        label="Coupon provider"
                        name="coupon_sync_provider"
                        description=""
                        v-model="couponStore.syncProvider"
                    >
                        <option v-if="!disableInAppCoupon" value="" :key="'new-coupon-btn-in-app'">
                            In-app coupon
                        </option>
                        <option v-for="provider in couponSyncProviders" :value="provider" :key="'new-coupon-btn-' + provider">
                            {{ capitalize(provider) }}
                        </option>
                        <template #note>
                            Choose one of the available providers for which you want to create a coupon.
                        </template>
                    </select-field>
                </template>
            </coupon-form>
        </template>
        <template #footer>
            <btn type="primary"
                 @click="createCoupon"
                 :loading="creatingCouponThroughApi"
            >
                {{ creatingCouponThroughApi ? 'Creating...' : 'Create coupon' }}
            </btn>
        </template>
    </modal>
</template>

