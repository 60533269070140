<script setup>
import TableSkeleton from '../../../common/skeletons/TableSkeleton'
import { computed } from 'vue';

const props = defineProps({
    title: {
        type: String,
        default: 'Referral overview'
    },
    dataLoading: {
        type: Boolean,
        default: false
    },
    programs: {
        type: Array,
        required: true,
        default: () => []
    },
});

const programsCount = computed(() => {
    return props.programs.length;
});

const singleProgram = computed(() => {
    if (programsCount.value === 1) {
        return props.programs[0];
    }
    return null;
});
</script>

<template>
    <div class="space-y-4">

        <header-bar :title="title" size="xsmall">
            <template v-if="singleProgram" #actions>
                <btn :href="singleProgram.link" type="secondary" size="small" icon-name="arrow-right-s" icon-right="true">
                    Full overview
                </btn>
            </template>
        </header-bar>

        <table-skeleton v-if="dataLoading" :number-of-rows="1"></table-skeleton>
        <div v-else-if="programs.length > 0" class="bg-white border rounded" :class="{'divide-y': !singleProgram}">
            <div v-for="program in programs" :key="program.id" class="grid grid-cols-1 lg:grid-cols-3">
                <div class="col-span-1 p-6 border-b lg:border-b-0" :class="{'flex items-center': !singleProgram}">
                    <div class="flex items-center gap-4">
                        <div
                            class="flex flex-shrink-0 justify-center items-center font-semibold tracking-tight rounded-md bg-orange-100 text-orange-900"
                            :class="{'w-20 h-20 text-xl': singleProgram, 'w-10 h-10 text-sm': !singleProgram}"
                        >
                            {{ program.initial }}
                        </div>
                        <div class="flex gap-0.5 flex-col">
                            <template v-if="singleProgram">
                                <div class="text-xs font-medium uppercase">Referral</div>
                                <div class="text-md font-semibold">
                                    <a :href="program.link" class="hover:underline">
                                        {{ program.name }}
                                    </a>
                                </div>
                            </template>
                            <div v-else class="text-md font-semibold">
                                <a :href="program.link" class="hover:underline">
                                    {{ program.name }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 flex items-center p-6">
                    <ul
                        :class="[
                            'flex-1 grid gap-y-4 gap-x-4 text-sm',
                            singleProgram ? 'grid-cols-3' : 'grid-cols-4'
                        ]"
                    >
                        <li class="flex flex-col gap-1.5">
                            <div class="text-sm text-gray-500">Active rewards</div>
                            <div :class="['font-semibold', singleProgram ? 'text-lg' : 'text-sm']">{{ program.active_rewards }}</div>
                        </li>
                        <li class="flex flex-col gap-1.5">
                            <div class="text-sm text-gray-500">Customers</div>
                            <div :class="['font-semibold', singleProgram ? 'text-lg' : 'text-sm']">{{ program.total_customers }}</div>
                        </li>
                        <li class="flex flex-col gap-1.5">
                            <div class="text-sm text-gray-500">Referred customers</div>
                            <div :class="['font-semibold', singleProgram ? 'text-lg' : 'text-sm']">{{ program.total_referrals }}</div>
                        </li>
                        <li v-if="!singleProgram" class="flex flex-col items-end justify-center gap-1.5">
                            <a :href="program.link">
                                <icon size="18" name="arrow-right-s"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <card v-else class="overflow-hidden" #body>
            <div class="text-sm text-center">
                There are no Referral Programs.
            </div>
        </card>
    </div>
</template>
