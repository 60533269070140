<template>
    <modal :title="'Complete your registration'" :show="true" size="default" name="post_social_register">
        <template #body>


            <form id="postSocialRegisterForm" method="post" :action="submitUrl">
                <input type="hidden" name="_token" id="csrf-token" :value="csrfToken()" />
                <div class="grid gap-y-8">

                    <div class="text-sm text-gray-900 text-opacity-50 tracking-wide">
                        Thank you for signing up! Before you start exploring, please complete your registration by providing the following information.
                    </div>

                    <text-field
                        label="Choose a name for your account"
                        field-id="account_name"
                        name="account_name"
                        type="text"
                        placeholder="Company or organization name"
                        autofocus="true"
                        required="true"
                        field-class="rounded z-10 focus:z-20 relative -mt-px"
                        v-model="accountNameInput"
                    ></text-field>

                    <div v-for="(interest, interestKey) in this.interestOptions">
                        <h4 class="mb-4 text-sm">{{ interest.title }}</h4>
                        <radio-group
                            class="rounded-l"
                            :name="interestKey"
                            :options="formatToRadioOptions(interest.options)"
                        ></radio-group>
                    </div>

                    <label for="newsletter" class="flex relative cursor-pointer">
                        <input type="checkbox"
                               name="newsletter"
                               id="newsletter"
                               class="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-100">
                        <div class="ml-3 text-sm font-normal text-gray-900">
                            I wish to subscribe to the Partnero newsletter. I know I can unsubscribe at anytime.
                        </div>
                    </label>
                </div>
            </form>
        </template>
        <template #footer>
            <btn @click="submit" :disabled="cannotSubmit">Submit</btn>
        </template>
    </modal>
</template>

<script>
export default {
    name: 'PostRegisterModal',
    props: {
        accountName: {
            type: String,
            required: false
        },
        submitUrl: {
            type: String,
            required: false
        },
        interestOptions: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            cannotSubmit: false,
            accountNameInput: this.accountName,
        }
    },
    methods: {
        submit () {
            document.getElementById('postSocialRegisterForm').submit();
        },
        formatToRadioOptions ( options ) {
            let tempOptions = [];

            for(const key in options) {
                tempOptions.push({ value: key, label: options[key] });
            }

            return tempOptions;
        },
        csrfToken () {
            return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        }
    }
}
</script>
