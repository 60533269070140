<template>
    <component
    :is="tag"
    class="inline-flex justify-center items-center font-medium text-center border transition transition-shadow duration-100 ease-in-out outline-none select-none focus:ring-0 focus:outline-none space-x-1"
    :href="href"
    :value="value"
    :name="name"
    :type="tag === 'button' && buttonType"
    :target="tag === 'a' ? linkTarget : null"
    :class="[
      [types[type] ? types[type].style : ''],
      [sizing[size].size],
      type === 'link' ? '' : hasSlotData ? [sizing[size].slot] : [sizing[size].noSlot],
      buttonType === 'button' ? 'inline-flex' : '',
      isDisabled ? 'cursor-not-allowed opacity-50' : '',
      roundedFull ? 'rounded-full' : 'rounded'
    ]"
    @click.native="handleClick"
    >
        <svg
            v-if="loading"
            class="animate-spin -ml-1 w-4 h-4 mr-2"
            :class="type === 'secondary-green' ? '' : 'text-white'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <icon
            v-if="iconName"
            :class="[
            hasSlotData && !iconRight ? [sizing[size].iconSpaceLeft] : '',
            hasSlotData && iconRight ? [sizing[size].iconSpaceRight] : '',
            iconRight ? 'order-last' : ''
            ]"
            :name="iconName"
            color="currentColor"
            :size="[[sizing[size].iconSize]]"
        />
        <slot></slot>
    </component>
</template>

<script>
export default {
    props: {
        href: {
            type: String,
        },
        disabled: {
            default: false,
        },
        buttonType: {
            default: 'button',
        },
        value: {},
        name: {},
        type: {
            default: 'primary',
        },
        size: {
            default: 'default',
        },
        customSize: {
            default: { size: '', iconSize: '', iconSpaceLeft: '', iconSpaceRight: '', noSlot: '', slot: '' }
        },
        iconName: '',
        roundedFull: {
            default: false,
        },
        iconRight: {
            default: false,
        },
        loading: {
            default: false,
        },
        preventDoubleClick: {
            type: Number,
            default: 0
        },
        linkTarget: {
            type: String,
            default: '_self'
        }
    },
    data() {
        return {
            sizing: {
                huge: { size: 'text-sm', iconSize: '14', iconSpaceLeft: 'mr-2', iconSpaceRight: 'ml-2', noSlot: 'p-6', slot: 'px-6 py-4' },
                large: { size: 'text-sm', iconSize: '20', iconSpaceLeft: 'mr-2', iconSpaceRight: 'ml-2', noSlot: 'p-6', slot: 'px-6 py-3' },
                medium: { size: 'text-sm', iconSize: '20', iconSpaceLeft: 'mr-2', iconSpaceRight: 'ml-2', noSlot: 'p-2.5', slot: 'px-5 py-2.5' },
                default: { size: 'text-sm',  iconSize: '16', iconSpaceLeft: 'mr-2', iconSpaceRight: 'ml-2', noSlot: 'p-2', slot: 'px-4 py-2' },
                small: { size: 'text-xs', iconSize: '16', iconSpaceLeft: 'mr-1', iconSpaceRight: 'ml-1', noSlot: 'p-1.5', slot: 'px-3 py-1.5' },
                pill: { size: 'text-2xs', iconSize: '12', iconSpaceLeft: 'mr-0.5', iconSpaceRight: 'ml-0.5', noSlot: 'p-1.5', slot: 'px-1 py-0.5' },
                custom: {
                    size: this.customSize.size,
                    iconSize: this.customSize.iconSize,
                    iconSpaceLeft: this.customSize.iconSpaceLeft,
                    iconSpaceRight: this.customSize.iconSpaceRight,
                    noSlot: this.customSize.noSlot,
                    slot: this.customSize.slot
                },
            },
            types: {
                primary: { style: 'bg-dark-gray hover:bg-opacity-90 border-bg-dark-gray text-white focus:ring-gray-200'},
                secondary: { style: 'bg-white hover:bg-gray-50 border-gray-300 focus:bg-gray-100' },
                ghost: { style: 'lg:bg-opacity-0 bg-opacity-10 hover:bg-gray-100 text-gray-700 shadow-none'},
                teal: { style: 'bg-teal-600 border-teal-600 text-white hover:bg-teal-500 focus:ring-gray-50' },
                white: { style: 'bg-white hover:bg-opacity-90 text-black' },
                gray: { style: 'bg-gray-600 hover:bg-gray-700 text-white focus:ring-gray-50' },
                light: { style: 'bg-gray-200 hover:bg-opacity-80 text-black text-opacity-75 shadow-none focus:ring-gray-200' },
                danger: { style: 'bg-red-400 hover:bg-opacity-90 text-white' },
                green: { style: 'bg-teal-500 hover:bg-teal-600 text-white' },
                warning: { style: 'bg-yellow-200 hover:bg-yellow-300  text-yellow-800 border-yellow-400' },
                'secondary-green': { style: 'bg-teal-50 border-gray-300 focus:bg-teal-50 dark:border-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:bg-gray-800' },
                'secondary-inverse': { style: 'bg-black bg-opacity-50 hover:bg-opacity-75 text-white' },
                'dark-green': { style: 'bg-green-600 hover:bg-opacity-90 text-white' },
                // used in both sides
                link: { style: 'border-transparent text-black hover:text-opacity-80' },


                // partner portal colors
                pink: { style: 'bg-pink-600 border-pink-600 text-white hover:bg-pink-500 focus:ring-gray-50' },
                indigo: { style: 'bg-indigo-700 border-indigo-700 text-white hover:bg-indigo-600 focus:ring-gray-50' },
                'indigo-700': { style: 'bg-indigo-700 border-indigo-700 text-white hover:bg-indigo-600 focus:ring-gray-50' },
                blue: { style: 'bg-blue-700 border-blue-700 text-white hover:bg-blue-600 focus:ring-gray-50' },
                'blue-700': { style: 'bg-blue-700 border-blue-700 text-white hover:bg-blue-600 focus:ring-gray-50' },
                cyan: { style: 'bg-cyan-700 border-cyan-700 text-white hover:bg-cyan-600 focus:ring-gray-50' },
                'cyan-700': { style: 'bg-cyan-700 border-cyan-700 text-white hover:bg-cyan-600 focus:ring-gray-50' },
                'teal-600': { style: 'bg-teal-600 border-teal-600 text-white hover:bg-teal-500 focus:ring-gray-50' },
                black: { style: 'bg-black hover:bg-opacity-90 text-white focus:ring-black-50' },
                custom: { style: 'bg-custom-btn border-opacity-100 hover:bg-opacity-90 focus:ring-gray-50' },
                'custom-content': { style: 'bg-custom-content-btn border-opacity-100 hover:bg-opacity-90 focus:ring-gray-50' },

            },
            preventClick: false
        }
    },
    computed: {
        hasSlotData() {
            return this.$slots.default
        },
        tag() {
            return this.href ? 'a' : 'button'
        },
        isDisabled() {
            return this.disabled || this.preventClick
        }
    },

    methods: {
        handleClick(event) {
            if (this.preventClick) {
                event.preventDefault();
                event.stopPropagation();
            } else if (this.preventDoubleClick > 0) {
                this.$nextTick(() => {
                    this.preventClick = true;
                    setTimeout(() => this.preventClick = false, this.preventDoubleClick)
                });
            }
        }
    }
}
</script>
