<template>
    <div>
        <label :for="select_name" class="block mb-1.5 text-sm">{{text.name}}</label>
        <div class="grid gap-1" :class="types_value_disabled ? 'grid-cols-1' : 'grid-cols-2'">
            <select
                :id="select_name"
                :name="select_name"
                @input="$emit('update:type', $event.target.value)"
                :value="type"
                class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                :disabled="disabled"
            >
                <option value="0">OFF</option>
                <option value="1">ON</option>
            </select>
            <text-field
                v-if="!types_value_disabled"
                :name="input_name"
                @input="$emit('update:redemption_value', $event.target.value)"
                :model-value="redemption_value"
                :autofocus="true"
                :disabled="types_value_disabled || disabled"
                :required="!types_value_disabled"
                >
            </text-field>
        </div>
        <small class="text-gray-500 mt-1 block">{{text.description}}</small>
    </div>
</template>
<script>

export default {
    name: 'RedemptionPeriod',
    props: {
        type: {
            type: String,
            default: '0'
        },
        redemption_value: {
            type: [String, Number],
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        select_name: {
            type: String,
            default: ''
        },
        input_name: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            text:{
                name: this.name || 'Coupon redemption period',
                description: this.description || 'Allows you to limit when a customer can redeem the coupon and the number of times a coupon can be redeemed.',
            }
        }
    },
    computed: {
        types_value_disabled() {
            return this.type === '0';
        }
    },
    methods: {}
}
</script>
<style scoped>
</style>
