<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {useAnalyticsPartnersStore} from "../../../../../../../stores/app/programs/analytics/AnalyticsPartners";
import HeaderBar from "../../../../../../../elements/HeaderBar";
import ChartSkeleton from "../../../../../../common/skeletons/ChartSkeleton";
import {formatNumber} from "../../../../../../../helpers/common";

const {proxy} = getCurrentInstance()
const analyticsPartnersStore = useAnalyticsPartnersStore();
const chartStroke = {
    'curve' : 'smooth',
    width: 2
};
const yAxisLabelFormat = {
    labels: {
        formatter: (value) => formatNumber(value)
    }
};

const groupType = ref('monthly');

const acquisitionChart = computed(() => {
    let acquisitionChart = analyticsPartnersStore.getAnalyticsPartnersAcquisitionInsightsData.acquisitionChart
    acquisitionChart.options.stroke = chartStroke
    acquisitionChart.options.yaxis = yAxisLabelFormat

    return acquisitionChart
});

const handleGroupTypeUpdate = () => {
    analyticsPartnersStore.setGroupType(groupType.value)

    analyticsPartnersStore.fetchAnalyticsPartnersAcquisitionInsightsData()
}
</script>

<template>
    <div class="space-y-4">
        <header-bar title="Partner acquisition" size="xsmall"></header-bar>

        <div class="p-6 bg-white border rounded">
            <div class="flex items-end mb-5">
                <select
                    id="chart_group_type"
                    name="chart_group_type"
                    class="block border-0 focus:ring-0 leading-4 ml-auto px-8 py-1.5 rounded text-right text-xs text-gray-500"
                    v-model="groupType"
                    @change="handleGroupTypeUpdate"
                >
                    <option value="yearly">Yearly</option>
                    <option value="monthly">Monthly</option>
                    <option value="daily">Daily</option>
                </select>
            </div>

            <apexchart
                v-if="analyticsPartnersStore.getAnalyticsPartnersAcquisitionInsightsData && acquisitionChart"
                :width="acquisitionChart.width"
                :height="acquisitionChart.height"
                :type="acquisitionChart.type"
                :options="acquisitionChart.options"
                :series="acquisitionChart.series">
            </apexchart>

            <chart-skeleton v-else></chart-skeleton>
        </div>
    </div>
</template>
