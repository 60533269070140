<template>
    <modal size="small" :title="'API key'" class="h-6" show="true">
        <template #toggle>
        </template>
        <template #body>
            <div class="space-y-6 font-normal">
                <div class="text-sm text-gray-500">
                    Please copy or download this key and save it somewhere safe. For security reasons, we won’t be able
                    to show it to you again.
                </div>

                <div>
                    <label class="block flex justify-between mb-1.5 text-sm">
                        API key
                        <tooltip content="Copied" method="click">
                            <a href="!#" v-on:click.prevent="copyKey" class="text-teal-600 hover:underline">Copy</a>
                        </tooltip>
                    </label>
                    <div class="overflow-scroll py-3 px-4 h-40 break-words bg-gray-100">
                        <span>
                            {{ value }}
                        </span>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <btn v-on:click.prevent="downloadKey">Download</btn>
        </template>
    </modal>
</template>
<script>
import { copyToClipboard } from '../../helpers/common.js';

export default {
    name: "ApiKeyModal",
    props: ['name', 'value'],
    data() {
        return {}
    },
    methods: {
        copyToClipboard,
        copyKey: function () {
            copyToClipboard(this.value);
        },
        downloadKey: function () {
            const url = window.URL.createObjectURL(new Blob([this.value]))
            const el = document.createElement('a')
            el.href = url
            el.setAttribute('download', `${this.name}.txt`)
            document.body.appendChild(el)
            el.click()
            document.body.removeChild(el);
        }
    }
}
</script>

<style scoped>

</style>
