<template>
  <div class="space-y-12">

    <div class="space-y-6">

        <div class="space-y-2">
            <h3 class="font-semibold text-lg">Start tracking website visitors</h3>
            <div class="text-sm space-y-2">
                <p>Visitor tracking enables the identification of referral visits to your website.</p>
                <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
            </div>
        </div>

      <slot name="universal_script"></slot>

    </div>

    <hr/>

    <div class="space-y-12">

        <div class="space-y-2">
            <h3 class="font-semibold text-lg">Start tracking sign-ups and sales</h3>
            <div class="text-sm space-y-2">
                <p>Successful affiliate programs rely on precise tracking of sign-ups and sales, ensuring they are credited to the right affiliate partners.</p>
                <p>Partnero makes this easier with a range of integration options. Use our <span class="font-semibold">universal API</span>, or choose direct integration with payment systems like <span class="font-semibold">Stripe</span> or <span class="font-semibold">Paddle</span> for seamless experience.</p>
            </div>
        </div>


        <div class="space-y-6">

        <div class="grid grid-cols-1 md:grid-cols-4 gap-6">

        <a @click="setActiveTab(TAB_PARTNERO_API)" class="border rounded flex items-center gap-4 p-4 hover:shadow-md hover:scale-102 transform transition ease-in-out" :class='{"border-b-4": activeTab === TAB_PARTNERO_API}'>
          <div class="w-12 h-12 rounded bg-dark-gray flex flex-col items-center justify-center">
            <img class="w-5 h-5" src="/images/logo/partnero-symbol-white.svg">
          </div>
          <div class="flex flex-col justify-start text-sm">
            <span class="font-semibold">Partnero API</span>
          </div>
        </a>

            <a @click="setActiveTab(TAB_PARTNERO_JS)" class="border rounded flex items-center gap-4 p-4 hover:shadow-md hover:scale-102 transform transition ease-in-out" :class='{"border-b-4": activeTab === TAB_PARTNERO_JS}'>
                <div class="w-12 h-12 rounded bg-yellow-300 flex flex-col items-center justify-center text-dark">
                    <icon name="javascript" size="24"></icon>
                </div>
                <div class="flex flex-col justify-start text-sm">
                    <span class="font-semibold">Partnero JS</span>
                </div>
            </a>

        <a @click="setActiveTab(TAB_STRIPE)" class="border rounded flex items-center gap-4 p-4 hover:shadow-md hover:scale-102 transform transition ease-in-out" :class='{"border-b-4": activeTab === TAB_STRIPE}'>
          <div class="flex-shrink-0 w-12 h-12">
            <img class="w-12 h-12 rounded" src="/images/integrations/stripe.png"/>
          </div>
          <div class="flex flex-col justify-start text-sm">
            <span class="font-semibold">Stripe</span>
          </div>
        </a>

        <a @click="setActiveTab(TAB_PADDLE)" class="relative border rounded flex items-center gap-4 p-4 hover:shadow-md hover:scale-102 transform transition ease-in-out" :class='{"border-b-4": activeTab === TAB_PADDLE}'>
          <div class="flex-shrink-0 w-10 h-10">
            <img class="w-10 h-10 rounded" src="/images/integrations/paddle.png"/>
          </div>
          <div class="flex flex-col justify-start text-sm">
            <span class="font-semibold">Paddle</span>
          </div>
        </a>

        </div>

        <div v-show="activeTab === TAB_PARTNERO_API">

            <div class="text-sm space-y-1 bg-gray-100 text-gray-800 p-6 rounded">
                <p>The Partnero API offers a straightforward back-end based method for integrating Partnero with any application through a simple REST API.</p>
                <p>Below is a basic example of how to integrate using the REST API. For more detailed examples, <a href="https://developers.partnero.com/guide/affiliate.html" target="_blank" class="underline hover:no-underline">refer to this guide</a>. Full <a href="https://developers.partnero.com/" target="_blank" class="underline hover:no-underline">developer documentation</a> is also available for in-depth information.</p>
            </div>

            <div class="space-y-6 mt-6">

                <div class="flex items-center gap-4">
                    <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">1</div>
                    <h3 class="font-semibold text-md">Sign-ups tracking</h3>
                </div>
                <div class="text-sm space-y-2">
                    <p>Notify Partnero about new user sign-ups to keep track of referrals from affiliate partners.</p>
                    <p>After each sign-up, make sure to execute the API call. This step is crucial for accurately capturing data about users who joined via affiliate links.</p>
                </div>

                <div>
                    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
POST https://api.partnero.com/v1/customers</code>
                    </pre>
                    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
{
    "partner": {
        "key": "UNIQUE_PARTNER_KEY"
    },
    "key": "customer_123456", // or email address
    "name": "John",
    "email": "john.doe@partnero.com"
}</code>
                    </pre>
                </div>

                <div class="text-sm space-y-2">
                    <p>The API call's payload should contain information about the customer and the affiliate partner who referred them. The <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">partner</span> object is critical. Fill in the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">partner.key</span> with the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">UNIQUE_PARTNER_KEY</span>, found in the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">partnero_partner</span> cookie set by our Partnero Universal script.</p>
                </div>

            </div>

            <div class="space-y-6 mt-6">

                <div class="flex items-center gap-4">
                    <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">2</div>
                    <h3 class="font-semibold text-md">Sales tracking</h3>
                </div>
                <div class="text-sm space-y-2">
                    <p>The following step of the integration is sending data about sales. When any customer makes a purchase, it is recommended to call <a href="https://developers.partnero.com/reference/transactions.html#create-a-transaction" target="_blank" class="underline hover:no-underline">Transaction create</a>.</p>
                    <p>When making this call, we recommend including the most important data about the purchase (see <a href="https://developers.partnero.com/guide/affiliate.html#request-parameters-1" target="_blank" class="underline hover:no-underline">Request parameters</a>). Also, feel free to send data about all purchases; Partnero will ignore requests if there is no existing customer.key.</p>
                </div>

            <div>
                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
POST https://api.partnero.com/v1/transactions</code>
                </pre>
                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-php">
{
    "customer": {
        "key": "customer_123456"
    },
    "key": "transaction_123",
    "amount": 99.99,
    "action": "sale"
}</code>
                </pre>
            </div>

            </div>

            <div class="space-y-6 mt-6">

                <div class="flex items-center gap-4">
                    <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">3</div>
                    <h3 class="font-semibold text-md">Recommendations</h3>
                </div>
                <div class="text-sm space-y-2">
                    <p>We also recommend implementing <a href="https://developers.partnero.com/guide/affiliate.html#recommendations" target="_blank" class="underline hover:no-underline">transaction and customer delete</a> actions. These are useful for instances when a refund is processed, or when a customer is deleted or chooses to close their account.</p>
                </div>

            </div>

        </div>

        <div v-show="activeTab === TAB_PARTNERO_JS">

            <div class="text-sm space-y-1 bg-gray-100 text-gray-800 p-6 rounded">
                <p>The Partnero JS is a straightforward front-end based method for integrating Partnero with any application making simple JavaScript calls.</p>
            </div>

            <div class="space-y-6 mt-6">

                <div class="flex items-center gap-4">
                    <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">1</div>
                    <h3 class="font-semibold text-md">Sign-ups tracking</h3>
                </div>

                <div class="text-sm space-y-2">
                    <p>Notify Partnero about new user sign-ups to keep track of referrals from affiliate partners.</p>
                    <p>Immediately after a user successfully signs up, make sure to run this script to ensure proper tracking.</p>
                </div>

                <div>
                    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-js">
&#60;script&#62;
    po('customers',
      'signup', {
        data: {
            key: 'customer_123456', // or email address
            name: 'John',
            email: 'john.doe@partnero.com'
        }
      }
    );
&#60;/script&#62;</code>
                    </pre>
                </div>

                <div class="text-sm space-y-2">
                    <p>
                        Customers referred by partners are automatically identified when their data matches information stored in the <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">partnero_partner</span> cookie, which is set by our Partnero Universal script. This process is handled by the Partnero Universal script, ensuring accurate recognition of referred customers.
                    </p>


                </div>

            </div>

            <div class="space-y-6 mt-6">

                <div class="flex items-center gap-4">
                    <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">2</div>
                    <h3 class="font-semibold text-md">Sales tracking</h3>
                </div>
                <div class="text-sm space-y-2">
                    <p>The following step of the integration is sending data about sales. When any user makes a purchase, it is recommended to inform Partnero about it.</p>
                    <p>Please ensure to execute this script immediately following a successful sale.</p>
                </div>

                <div>
                    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-js">
&#60;script&#62;
    po('transactions', 'create', {
        data: {
            key: 'transaction_123',
            amount: 99.99,
            amount_units: 'usd',
            customer: {
                key: 'customer_123456'
            }
        }
    });
&#60;/script&#62;</code>
                </pre>
                </div>

            </div>

        </div>

        <div v-show="activeTab === TAB_STRIPE">

            <div class="text-sm space-y-1 bg-gray-100 text-gray-800 p-6 rounded">
                <p>Integrating Stripe with Partnero enables you to easily monitor and record customer purchases and link them to your affiliate partners.</p>
            </div>

            <div class="space-y-6 mt-6">

                <div class="flex items-center gap-4">
                    <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">1</div>
                    <h3 class="font-semibold text-md">Connect your Stripe account</h3>
                </div>

                <div v-if="config.stripe">
                  <integration-stripe-simple
                      :integrations-config="config.stripe"
                      :program-id="parseInt(program)"
                  ></integration-stripe-simple>
                </div>

                <div class="flex items-center gap-4">
                    <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">2</div>
                    <h3 class="font-semibold text-md">Sign-ups and sales tracking</h3>
                </div>

                <tabs>
                    <tab @click="setActiveStripe(STRIPE_1)" :class='{"border-b-4": activeStripe === STRIPE_1}'>
                        Tracking via Payment Links
                    </tab>
                    <tab @click="setActiveStripe(STRIPE_2)" :class='{"border-b-4": activeStripe === STRIPE_2}'>
                        Tracking via user sign-up
                    </tab>
                </tabs>

                <div class="space-y-6" v-show="activeStripe === STRIPE_2">

                    <div class="text-sm space-y-2">
                        <p>Run this script right after a user signs up to guarantee accurate tracking.</p>
                        <p>Please note that you have to replace <b>key</b>, <b>name</b> and <b>email</b> with a real data from your platform.</p>
                    </div>

                    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-js">
&#60;script&#62;
po('customers',
    'signup', {
        data: {
            key: 'customer_123456', // or email address
            name: 'John',
            email: 'john.doe@partnero.com'
        }
    }
);
&#60;/script&#62;</code>
                    </pre>

                </div>

                <div class="space-y-6" v-show="activeStripe === STRIPE_1">

                    <div class="text-sm space-y-2">
                        <p>
                            Include the following script on every page containing payment links. It's important to place
                            this script at the bottom of the page, directly above the closing <span
                            class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800">&lt;/body&gt;</span>
                            tag for optimal functionality.
                        </p>
                    </div>
                    <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-js">
&#60;script&#62;
(function() {
    const updateLinkHref = link => {
        const cookieValue = (document.cookie.match(/(^| )partnero_partner=([^;]+)/) || [])[2];
        if (cookieValue) {
            try {
                const url = new URL(link.href);
                url.searchParams.set('client_reference_id', decodeURIComponent(cookieValue));
                link.href = url.href;
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    document.querySelectorAll('a[href*="buy.stripe.com"]').forEach(link => {
        updateLinkHref(link);
        link.addEventListener('click', () => updateLinkHref(link));
    });
})();
&#60;/script&#62;</code>
                    </pre>




                </div>

            </div>

        </div>

        <div v-show="activeTab === TAB_PADDLE">

            <div class="text-sm space-y-1 bg-gray-100 text-gray-800 p-6 rounded">
                <p>Integrating Paddle with Partnero enables you to easily monitor and record customer purchases and link them to your affiliate partners.</p>
            </div>

        <div class="space-y-6 mt-6">

            <div class="flex items-center gap-4">
                <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">1</div>
                <h3 class="font-semibold text-md">Connect your Paddle account</h3>
            </div>

            <div v-if="config.paddle">
                <integration-paddle-simple
                    :integrations-config="config.paddle"
                    :program-id="parseInt(program)"
                ></integration-paddle-simple>
            </div>

            <div class="flex items-center gap-4">
                <div class="flex justify-center items-center w-10 h-10 bg-gray-50 rounded-xl font-semibold text-sm border">2</div>
                <h3 class="font-semibold text-md">Sign-ups and sales tracking</h3>
            </div>


            <tabs>
                <tab @click="setActivePaddle(PADDLE_JS)" :class='{"border-b-4": activePaddle === PADDLE_JS}'>
                    Tracking via user sign-up
                </tab>
            </tabs>


            <div v-show="activePaddle === PADDLE_JS">
                <div class="text-gray-500 text-sm">
                    <p>Run this script right after a user signs up to guarantee accurate tracking.</p>
                    <p>Please note that you have to replace <b>key</b>, <b>name</b> and <b>email</b> with a real data from your platform.</p>
                </div>

                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="language-js">
&#60;script&#62;
po('customers',
  'signup', {
    data: {
        key: 'customer_123456', // or email address
        name: 'John',
        email: 'john.doe@partnero.com'
    }
  }
&#60;/script&#62;</code>
                </pre>

            </div>

            <div v-show="activePaddle === PADDLE_BACKEND">

                <h4 class="text-sm font-medium">Creating a customer</h4>

                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
POST https://api.paddle.com/customers
</code>
</pre>
                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
{
    "name": "John",
    "email": "john.doe@partnero.com"
    "custom_data": {
        "customer_key": "CUSTOMER_KEY_VALUE_HERE"
    }
}</code>
    </pre>

                <h4 class="text-sm font-medium mt-6">Creating a transaction</h4>
                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
POST https://api.paddle.com/transactions</code>
                </pre>

                <pre class="text-xs bg-gray-100 rounded p-2 my-2">
<code class="curl">
{
  "items": [
    {
      "quantity": 20,
      "price_id": "pri_01gsz91wy9k1yn7kx82aafwvea"
    },
    {
      "quantity": 1,
      "price_id": "pri_01gsz96z29d88jrmsf2ztbfgjg"
    }
  ],
  "customer_id": "ctm_01h8441jn5pcwrfhwh78jqt8hk",
  "address_id": "add_01h848pep46enq8y372x7maj0p",
  "currency_code": "USD",
  "collection_mode": "manual",
  "billing_details": {
    "enable_checkout": false,
    "purchase_order_number": "PO-123",
    "payment_terms": {
      "interval": "day",
      "frequency": 14
    }
  },
  "billing_period": {
    "starts_at": "2023-08-01T00:00:00Z",
    "ends_at": "2024-07-31T23:59:00Z"
  },
  "custom_data": {
       "customer_key": "CUSTOMER_KEY_VALUE_HERE"
  }
}</code>
    </pre>

                <div class="text-gray-500 text-sm">
                    For more information on how to add custom data to Paddle, please refer to the<a href="https://developer.paddle.com/build/transactions/custom-data" target="_blank" class="underline hover:no-underline">Paddle custom data documentation</a>.
                </div>
            </div>

        </div>

        </div>
        </div>

        <div class="flex items-center gap-4 bg-tahiti-100 p-6 rounded border border-gray-900 border-opacity-5 mt-6">
            <div class="bg-teal-300 bg-opacity-25 rounded p-4">
                <icon name="question-line" size="20"></icon>
            </div>
            <p class="text-sm">If you require assistance with integration, <a href="https://www.partnero.com/service-integration" target="_blank" class="underline hover:no-underline">reach out to the Partnero team</a>, and we'll handle the integration process for you.</p>
        </div>

    </div>
  </div>
</template>
<script setup>

import {ref} from 'vue';
import IntegrationPaddleSimple from './../../../integrations/paddle/IntegrationPaddleSimple'

const props = defineProps({
  program: {
    type: [String, Number]
  },
  integrations_config: {
    type: Object
  },
});

const TAB_PADDLE = 'paddle';
const TAB_STRIPE = 'stripe';
const TAB_PARTNERO_API = 'partnero_api';
const TAB_PARTNERO_JS = 'partnero_js';

const PADDLE_JS = 'paddle_js';
const PADDLE_BACKEND = 'paddle_backend';

const STRIPE_1 = 'stripe_1';
const STRIPE_2 = 'stripe_2';

let activeTab = ref(TAB_PARTNERO_API);
let activePaddle = ref(PADDLE_JS);
let activeStripe = ref(STRIPE_1);

const program = props.program;
const config = props.integrations_config;

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

const setActivePaddle = (tab) => {
    activePaddle.value = tab;
};

const setActiveStripe = (tab) => {
    activeStripe.value = tab;
};

</script>
