<template>
    <div class="col-span-2 space-y-6">
        <div class="space-y-4">
            <div>
                <div class="flex justify-between items-center">
                    <toggle
                        :name="props.toggleName"
                        size="default"
                        v-model="enabled"
                    ></toggle>
                    <span class="flex flex-col flex-grow">
                        <span class="text-sm font-medium text-gray-900">{{ props.toggleTitle }}</span>
                        <span class="text-sm text-gray-500">{{ props.toggleDescription }}</span>
                    </span>
                </div>
            </div>

            <div v-if="enabled">
                <text-field
                    :label="props.emailInputTitle"
                    :name="props.emailInputName"
                    type="text"
                    :model-value="props.emailValue">
                    <template #note>
                        {{ props.emailInputDescription }}
                    </template>
                </text-field>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";

const props = defineProps({
    toggleEnabled: String,
    toggleName: String,
    toggleTitle: String,
    toggleDescription: String,
    emailInputName: String,
    emailInputTitle: String,
    emailInputDescription: String,
    emailValue: String,
})

const enabled = ref(!!props.toggleEnabled)
</script>
