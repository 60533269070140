<script setup>
    import {useAnalyticsPartnersStore} from "../../../../../../../stores/app/programs/analytics/AnalyticsPartners";

    const analyticsPartnersStore = useAnalyticsPartnersStore();

</script>

<template>
    <div class="grid lg:grid-cols-3 gap-4">
        <white-card title="Active partners" :value="analyticsPartnersStore.getAnalyticsPartnersStatsData['active_partners']" :growth="analyticsPartnersStore.getAnalyticsPartnersStatsData['active_partners_growth']"></white-card>
        <white-card title="Partners with signups" :value="analyticsPartnersStore.getAnalyticsPartnersStatsData['partners_with_signups']" :growth="analyticsPartnersStore.getAnalyticsPartnersStatsData['partners_with_signups_growth']"></white-card>
        <white-card title="Partners with paid customers" :value="analyticsPartnersStore.getAnalyticsPartnersStatsData['partners_with_paid_customers']" :growth="analyticsPartnersStore.getAnalyticsPartnersStatsData['partners_with_paid_customers_growth']"></white-card>
    </div>
</template>
