<template>
    <div class="flex items-start">
        <div class="flex-shrink-0 w-10 h-10">
            <img class="w-10 h-10 rounded" src="/images/integrations/stripe.png"/>
        </div>
        <div class="ml-5 w-full">
            <div class="flex justify-between">
                <div class="flex items-center">
                    <span class="text-sm font-semibold">Stripe</span>
                </div>
                <badge title="Connected" v-if="connected"></badge>
            </div>
            <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                Connect your Stripe account to Partnero.
            </div>
            <div class="flex gap-2 items-center">
                <modal v-if="!connected" :title="'Stripe integration'" size="default" name="stripe_integration">
                    <template #toggle>
                        <btn v-if="!connected" type="secondary" size="small" class="-mr-px focus:z-10" :disbled="checkingIfConnected" :loading="checkingIfConnected">
                            {{ checkingIfConnected ? 'Checking connection' : 'Authorize' }}
                        </btn>
                    </template>
                    <template #body>
                        <validation-errors :errors="errors.validationErrors"/>
                        <alert v-if="errors.responseError" type="error">
                        <span class="break-all">
                            {{ errors.responseError }}
                        </span>
                        </alert>
                        <div class="space-y-4 text-sm">

                            <div class="text-gray-500 space-y-2">
                                <p>With Stripe integration, you can send transaction data to Partnero, manage coupons,
                                    and select products for product-based commission structure.</p>
                                <p>Choose an authorization method below.</p>
                            </div>

                            <alert type="neutral">
                                <span class="font-semibold">Please note:</span> <span class="font-normal">The Stripe integration is not intended for full affiliate or
                                referral tracking. Please use available integration methods such as <a
                                    href="https://developers.partnero.com/guide/affiliate.html" target="_blank"
                                    class="underline hover:no-underline">API</a>, <a
                                    href="https://developers.partnero.com/guide/javascript-tracking.html"
                                    class="underline hover:no-underline">JavaScript</a>, or other <a
                                    href="./integrations/guides" class="underline hover:no-underline">integration guides</a>.
                                </span>
                            </alert>

                            <tabs>
                                <tab @click="setActiveConnectionTab('oAuth')" :active="activeConnectionTab === 'oAuth'">
                                    OAuth
                                </tab>
                                <tab @click="setActiveConnectionTab('apiConnect')"
                                     :active="activeConnectionTab === 'apiConnect'">
                                    API key
                                </tab>
                            </tabs>

                            <div v-if="activeConnectionTab === 'oAuth'" class="space-y-4">
                                <div class="text-gray-500 text-sm">
                                    <p>By clicking the <b>Authorize</b> button, you’ll be redirected to the Stripe
                                        website to grant Partnero access to your Stripe account.</p>
                                </div>
                            </div>

                            <text-field
                                v-if="activeConnectionTab === 'apiConnect'"
                                label="API key"
                                v-model="apiKey"
                                type="password"
                                :show_reveal_password_icon="true"
                                autofocus="true"
                                required="true"
                                :class="{'border-red-400': errors.apiKey}"
                                placeholder="e.g. sk_live_...7quX"
                            >
                                <template #note>
                                    Searching for your API key? Simply sign in to your Stripe dashboard, navigate to
                                    <strong>Developers</strong> > <strong>API keys</strong>. Copy <strong>Secret
                                    key</strong> into the field above for verification.
                                </template>
                            </text-field>

                            <toggle
                                v-if="syncCustomersAvailable"
                                title="Sync customers"
                                :model-value="syncCustomers"
                                @update:modelValue="setSyncCustomersValue"
                            >
                                <template #description>
                                    Import customers from Stripe directly into the program.
                                </template>
                            </toggle>

                            <toggle
                                v-if="syncCouponsAvailable"
                                title="Sync coupons"
                                :model-value="syncCoupons"
                                @update:modelValue="setSyncCouponsValue"
                            >
                                <template #description>
                                    Synchronize coupons from Stripe into the program.
                                </template>
                            </toggle>

                        </div>
                    </template>
                    <template #footer>
                        <div class="flex justify-end">
                            <btn type="primary" :loading="loading.authorize" class="-mr-px focus:z-10"
                                 @click="authorize">
                                Authorize
                            </btn>
                        </div>
                    </template>
                </modal>
                <modal v-if="connected" title="Stripe integration" size="small" name="stripe_disconnect">
                    <template #toggle>
                        <btn v-if="connected" size="small" class="-mr-px focus:z-10">Disconnect</btn>
                    </template>
                    <template #body>
                        <div class="space-y-4">
                            <div class="text-sm text-gray-500">Are you sure you wish to disconnect the Stripe
                                integration?
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <btn :loading="loading.disconnect" @click="disconnect">Disconnect</btn>
                    </template>
                </modal>
                <customer-identifier-mapping
                    v-if="connected"
                    :program-id="programId"
                    title="Stripe integration mapping (optional)"
                    provider="stripe"
                    :customer-identifier-holder-in-metadata="integrationsConfig.setting_integration_stripe_customer_identifier_mapping"
                />
            </div>
        </div>
    </div>
</template>
<script>

import StripeAfterIntegrationInstructions from "./components/StripeAfterIntegrationInstructions.vue";
import StripeMetadataInstructions from "./components/StripeMetadataInstructions.vue";
import ValidationErrors from "../../../common/ValidationErrors.vue";
import Alert from "../../../../elements/Alert.vue";
import TextField from "../../../../elements/forms/TextField";
import Text from "../../resources-builder/resources/Text";
import CustomerIdentifierMapping from "../common/identifier-mapping/CustomerIdentifierMapping.vue";

export default {
    components: {
        CustomerIdentifierMapping,
        Text,
        TextField, Alert, ValidationErrors, StripeAfterIntegrationInstructions, StripeMetadataInstructions
    },
    emits: ['stripe-connected'],
    props: {
        integrationsConfig: {
            type: Object,
            required: true
        },
        programId: {
            type: Number,
            required: true
        },
        syncCustomersAvailable: {
            type: Boolean,
            default: false
        },
        oAuthSessionData: {
            type: [Object, null],
            default: null
        },
        syncCouponsAvailable: {
            type: Boolean,
            default: true
        },
        checkingConnection: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            apiKey: null,
            syncCustomers: !!this.syncCustomersAvailable,
            syncCoupons: false,
            loading: {
                authorize: false,
                disconnect: false,
            },
            errors: {
                apiKey: false,
                responseError: null,
                validationErrors: null,
            },
            activeConnectionTab: 'oAuth',
        }
    },
    computed: {
        connected() {
            return this.integrationsConfig?.connected ?? false;
        },
        checkingIfConnected() {
            return this.checkingConnection;
        }
    },
    methods: {
        setSyncCustomersValue: function (value) {
            this.syncCustomers = value;
        },
        setSyncCouponsValue: function (value) {
            this.syncCoupons = value;
        },
        setActiveConnectionTab(tab) {
            this.activeConnectionTab = tab;
        },
        authorize() {

            if (this.activeConnectionTab === 'oAuth') {
                this.errors.responseErrors = null;
                if (this.loading.authorize) {
                    return;
                }
                this.loading.authorize = true;

                // When using doNotReloadPageAfterConnect, it means client is connecting stripe on program create step 2
                window.location.href = route('programs.stripe.connect-o-auth', {
                    program: this.programId,
                    sync_customers: this.syncCustomers,
                    sync_coupons: this.syncCoupons,
                    after_connect_return_to_url: this.integrationsConfig.afterConnectReturnToUrl || null,
                    o_auth_session_data: this.oAuthSessionData ? JSON.stringify(this.oAuthSessionData) : null
                });
            } else if (this.activeConnectionTab === 'apiConnect') {
                this.errors.apiKey = false;
                this.errors.responseErrors = null;

                if (this.apiKey === null || this.apiKey.trim() === '') {
                    this.errors.apiKey = true;
                }

                if (this.errors.apiKey === false) {
                    if (this.loading.authorize) {
                        return;
                    }
                    this.loading.authorize = true;

                    axios.post(route('programs.stripe.quick_connect', {program: this.programId}), {
                        apiKey: this.apiKey,
                        sync_customers: this.syncCustomers,
                        sync_coupons: this.syncCoupons,
                    }).then((response) => {
                        const status = parseInt(response.data.status ?? -1);
                        if (status === 1) {
                            if (this.integrationsConfig?.doNotReloadPageAfterConnect) {
                                this.$emit('stripe-connected', true);
                            } else {
                                window.location.reload();
                            }
                        } else if (status === 0) {
                            this.errors.responseError = response.data?.error;
                        }
                    }).catch((error) => {
                        this.errors.validationErrors = error.response?.data?.errors;
                    }).finally(() => this.loading.authorize = false);
                }
            }
        },
        disconnect() {

            if (this.loading.disconnect) {
                return;
            }
            this.loading.disconnect = true;

            axios.post(route('programs.stripe.disconnect', {program: this.programId})).then((response) => {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {
                    if (this.integrationsConfig?.doNotReloadPageAfterConnect) {
                        this.$emit('stripe-connected', false);
                    } else {
                        window.location.reload();
                    }
                }
            }).finally(() => this.loading.disconnect = false);
        }
    }
}
</script>
