<script setup>

import Btn from "../../../../elements/Btn.vue";

const props = defineProps({
        programId: {
            type: Number,
            required: true
        },
        syncProviders: {
            type: Array,
            required: true
        }
    })

    const capitalizeProviderName = (provider) => {
        if (provider === 'woocommerce') {
            return 'WooCommerce'
        }
        return provider.charAt(0).toUpperCase() + provider.slice(1)
    }

    const createCouponRoute = (provider = null) => {
        if (!provider) {
            return route('programs.coupon.create.show' , {program: props.programId});
        }
        return route('programs.coupon.create.show' , {program: props.programId, sync_provider: provider});
    }
</script>

<template>
    <btn v-if="syncProviders.length === 0"
         :href="createCouponRoute()"
         type="primary"
         size="large"
    >
        New coupon
    </btn>
    <options v-else>
        <template #toggle>
            <btn type="primary" icon-right="true" icon-name="more" size="large">
                New coupon
            </btn>
        </template>
        <template  #items>
            <options-item
                :href="createCouponRoute()" size="large"
                title="New in-app coupon"
            >
            </options-item>
            <options-item v-for="provider in syncProviders"
                          :key="'new-coupon-btn-' + provider"
                          :title="'New ' + capitalizeProviderName(provider) + ' coupon'"
                          :href="createCouponRoute(provider)"
            >
            </options-item>
        </template>
    </options>
</template>

