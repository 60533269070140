<template>
    <div>
        <modal title="Choose action" size="small" ref="transactionActionsModal">
            <template #toggle>
                <btn size="small" type="secondary" :disabled="deletingInProgress">
                    Actions
                </btn>
            </template>
            <template #body>

                <div class="space-y-4">
                <div class="text-sm text-left text-gray-500 space-y-1.5">
                    <p>If you delete this transaction, it will be permanently removed and cannot be retrieved.</p>
                    <p>{{
                            transaction.deleted_at ? 'Restore the transaction or delete it permanently.' : 'Consider archiving the transaction as a way to softly delete it.'
                        }}</p>
                </div>

                <div class="flex items-center justify-between gap-4 p-4 border rounded bg-yellow-50 border-yellow-400 text-yellow-800" v-if="allowDeclineReviewPeriod">
                    <div class="text-sm">The reward is in review.</div>
                    <div class="flex gap-2">
                        <btn size="small" v-if="allowApproveReviewPeriod" type="warning" @click="approveReviewPeriod"
                             :disabled="approvingReviewPeriodInProgress">
                            <spinner
                                v-if="approvingReviewPeriodInProgress"
                                class="mr-1.5"
                            />
                            {{ approvingReviewPeriodInProgress ? 'Approving...' : 'Approve' }}
                        </btn>
                        <btn size="small" v-if="allowDeclineReviewPeriod" type="warning" @click="declineReviewPeriod"
                             :disabled="decliningReviewPeriodInProgress">
                            <spinner
                                v-if="decliningReviewPeriodInProgress"
                                class="mr-1.5"
                            />
                            {{ decliningReviewPeriodInProgress ? 'Declining...' : 'Decline' }}
                        </btn>
                   </div>
                </div>
                </div>
            </template>
            <template #footer>
                <btn v-if="transaction.deleted_at" type="light" @click="unArchiveTransaction"
                     :disabled="revokingSoftDeleteInProgress">
                    <spinner
                        v-if="revokingSoftDeleteInProgress"
                        class="mr-1.5"
                    />
                    {{ revokingSoftDeleteInProgress ? 'Restoring...' : 'Restore' }}
                </btn>
                <btn v-else type="light" @click="archiveTransaction" :disabled="softDeletingInProgress">
                    <spinner
                        v-if="softDeletingInProgress"
                        class="mr-1.5"
                    />
                    {{ softDeletingInProgress ? 'Archiving...' : 'Archive' }}
                </btn>
                <modal title="Choose action" size="small" ref="transactionActionsModalConfirm">
                    <template #toggle>
                        <btn type="primary" :disabled="deletingInProgress">
                            <spinner
                                v-if="deletingInProgress"
                                :is-white="true"
                                class="mr-1.5"
                            />
                            {{ deletingInProgress ? 'Deleting...' : 'Delete' }}
                        </btn>
                    </template>
                    <template #body>
                        <div class="text-sm text-left text-gray-500">
                            Are you sure you want to delete this transaction?<br/>This cannot be undone!
                        </div>
                    </template>
                    <template #footer>
                        <btn type="primary" @click="deleteTransaction" :disabled="deletingInProgress">
                            <spinner v-if="deletingInProgress" :is-white="true" class="mr-1.5"/>
                            {{ deletingInProgress ? 'Deleting...' : 'Delete' }}
                        </btn>
                    </template>
                </modal>
            </template>
        </modal>
    </div>
</template>

<script>
import {ref} from "vue";
import useFilters from "../../../../../state/common/filters-and-pagination";
import Spinner from "../../../../../elements/Spinner";

export default {
    name: "TransactionActionsModal",
    components: {Spinner},
    props: {
        transaction: {
            type: Object,
            required: true
        }
    },
    setup() {
        const deletingInProgress = ref(false);
        const softDeletingInProgress = ref(false);
        const revokingSoftDeleteInProgress = ref(false);
        const approvingReviewPeriodInProgress = ref(false);
        const decliningReviewPeriodInProgress = ref(false);

        const filters = {}
        const {
            fetchDataBaseUrl,
            fetchDataFunction
        } = useFilters()

        function fetchData() {
            fetchDataFunction.value(fetchDataBaseUrl.value, {...filters})
        }

        return {
            fetchData,
            deletingInProgress,
            filters,
            softDeletingInProgress,
            revokingSoftDeleteInProgress,
            approvingReviewPeriodInProgress,
            decliningReviewPeriodInProgress
        }
    },
    computed: {
        allowApproveReviewPeriod() {
            return this.transaction.status === 'review_period' && !this.transaction.deleted_at;
        },
        allowDeclineReviewPeriod() {
            return this.transaction.status === 'review_period' && !this.transaction.deleted_at;
        }
    },
    methods: {
        archiveTransaction() {
            this.softDeletingInProgress = true
            axios.delete(route('app_int.customer.transaction.soft_delete_by_key', {program: this.transaction.program_id}),
                {data: {id: this.transaction.id, key: this.transaction.identifier}})
                .then(response => {
                    if (response.status === 200) {
                        this.$refs.transactionActionsModal.onCancel()
                        this.softDeletingInProgress = false
                        this.fetchData()
                    }
                })
                .catch(error => {
                })
                .finally(() => {
                    this.softDeletingInProgress = false
                });
        },
        unArchiveTransaction() {
            this.revokingSoftDeleteInProgress = true
            axios.patch(route('app_int.customer.transaction.revoke_soft_delete_by_key', {program: this.transaction.program_id}),
                {
                    id: this.transaction.id,
                    key: this.transaction.identifier
                })
                .then(response => {
                    if (response.status === 200) {
                        this.$refs.transactionActionsModal.onCancel()
                        this.revokingSoftDeleteInProgress = false
                        this.fetchData()
                    }
                })
                .catch(error => {
                })
                .finally(() => {
                    this.revokingSoftDeleteInProgress = false
                });
        },
        approveReviewPeriod() {
            this.approvingReviewPeriodInProgress = true;
            axios.post(route('app_int.customer.transaction.approve_pending_review_reward', {program: this.transaction.program_id}),
                {
                    id: this.transaction.id,
                    key: this.transaction.identifier
                })
                .then(response => {
                    if (response.status === 200) {
                        this.$refs.transactionActionsModal.onCancel();
                        this.fetchData();
                    }
                })
                .catch(error => {
                })
                .finally(() => {
                    this.approvingReviewPeriodInProgress = false;
                });
        },
        declineReviewPeriod() {
            this.decliningReviewPeriodInProgress = true
            axios.post(route('app_int.customer.transaction.decline_pending_review_reward', {program: this.transaction.program_id}),
                {
                    id: this.transaction.id,
                    key: this.transaction.identifier
                })
                .then(response => {
                    if (response.status === 200) {
                        this.$refs.transactionActionsModal.onCancel();
                        this.fetchData();
                    }
                })
                .catch(error => {
                })
                .finally(() => {
                    this.decliningReviewPeriodInProgress = false;
                });
        },
        deleteTransaction() {
            this.deletingInProgress = true
            axios.delete(route('app_int.customer.transaction.delete', {program: this.transaction.program_id}),
                {data: {id: this.transaction.id}})
                .then(response => {
                    if (response.status === 200) {
                        this.$refs.transactionActionsModalConfirm.onCancel()
                        this.$refs.transactionActionsModal.onCancel()
                        this.deletingInProgress = false
                        this.fetchData()
                    }
                })
                .catch(error => {
                })
                .finally(() => {
                    this.deletingInProgress = false
                });
        }
    }
}
</script>
