<template>
    <nav class="bg-gray-100 rounded py-3 px-4 mx-4 mt-4 space-x-3 flex overflow-auto" aria-label="Tabs">
        <slot />
    </nav>
</template>

<script>
export default {
    props: {
        name: {},
    },
}
</script>
