<template>

    <table class="min-w-full mt-1 divide-y divide-gray-200">
        <thead>
        <tr class="h-1">
            <th :colspan="TABLE_COLUMNS_COUNT">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <th class="pl-4 w-6">
                <options
                    :isRight="false"
                    name="referral_activity_table_options"
                >
                    <template #toggle>
                        <div class="bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                            <icon name="arrow-down-small"></icon>
                        </div>
                    </template>
                    <template #items>
                        <options-item
                            title="Select all"
                            @click="setExportSelection('selectAll')"
                        />
                        <options-item
                            title="Select visible"
                            @click="setExportSelection('selectVisible')"
                        />
                    </template>
                </options>
            </th>
            <table-header-columns-and-sorting :table-headers="tableHeaders" last-column-alignment="justify-end"/>
        </tr>
        <tr class="h-1"/>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!recentActivities || recentActivities?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && recentActivities?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr v-for="(recentActivity, index) in recentActivities"
                :key="recentActivity.referral_activity_id + '-' + recentActivity.customer_id + '-' + index"
                class="hover:bg-gray-50 cursor-pointer group"
            >
                <td class="pl-4 w-6">
                    <checkbox
                        v-model="exportOptions.selectedIds"
                        :value="recentActivity.id"
                        @click="setExportSelection('selectCustom')"
                    />
                </td>
                <td
                    class="pl-4 pr-4 py-4 whitespace-nowrap font-semibold text-sm text-gray-900"
                    @click="viewCustomerProfile(recentActivity.referral_url)"
                >
                    <template v-if="recentActivity.email || recentActivity.customer_full_name">
                        <div v-if="recentActivity.customer_full_name">
                            {{ recentActivity.customer_full_name }}
                        </div>
                        <div class="text-sm font-normal text-gray-500" v-if="recentActivity.email">
                            {{ recentActivity.email }}
                        </div>
                    </template>
                    <template v-else>
                        -
                    </template>
                </td>
                <td
                    v-if="isReferAFriendProgram"
                    class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    @click="viewCustomerProfile(recentActivity.referral_url)"
                >
                    <span
                        class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 capitalize">
                        {{ recentActivity.resource_type_label || 'Unknown' }}
                    </span>
                </td>
                <td
                    class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    @click="viewCustomerProfile(recentActivity.referral_url)"
                >
                    <span
                        class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 capitalize">
                        {{ recentActivity.action }}
                    </span>
                </td>
                <td
                    class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    @click="viewCustomerProfile(recentActivity.referral_url)"
                >
                    <tooltip v-if="recentActivity.reward_commission_description" :content="recentActivity.reward_commission_description">
                        {{ recentActivity.resource_type === 'customer' ? '--' : (recentActivity.reward_value ?? recentActivity.currency_amount) }}
                    </tooltip>
                    <div v-else>
                        <!-- Make helper for currency calc on js side or calculate it on backend -->
                        {{ recentActivity.resource_type === 'customer' ? '--' : (recentActivity.reward_value ?? recentActivity.currency_amount) }}
                    </div>
                </td>
                <td
                    class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    @click="viewCustomerProfile(recentActivity.referral_url)"
                >
                    <referral-status
                        :status="(recentActivity.action === 'signup' && recentActivity.deleted_at) ? 'deleted' : recentActivity.status"
                        :activity="recentActivity"
                    />
                </td>
                <td
                    class="pl-2 pr-4 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                    @click="viewCustomerProfile(recentActivity.referral_url)"
                >
                    <time datetime="2021-04-12 07:08:55">
                        {{ recentActivity.parsed_date }}
                    </time>
                </td>
            </tr>
        </template>
        </tbody>
    </table>

</template>

<script>
import InitialLoadingSkeletonRows from "../../../../common/InitialLoadingSkeletonRows";
import ProgressBar from "../../../../../elements/ProgressBar";
import ReferralStatus from "../../../../common/ReferralStatus";
import NoSearchDataRow from "../../../../common/NoSearchDataRow";
import filtersAndPagination from "../../../../../composables/common/filter-and-pagination-data";
import useTableDataExporter from "../../../../../composables/common/select-export-and-delete-data";
import TableHeaderColumnsAndSorting from "../../../filters-and-pagination/TableHeaderColumnsAndSorting.vue";

export default {
    name: "ReferralActivitiesDataTable",
    emits: ['hide-options'],
    components: {
        TableHeaderColumnsAndSorting,
        InitialLoadingSkeletonRows,
        NoSearchDataRow,
        ProgressBar,
        ReferralStatus
    },
    props: {
        recentActivities: {
            default: []
        },
        isReferAFriendProgram: {
            type: Boolean,
            default: false
        },
        tableHeaders: {
            default: []
        }
    },
    setup(props) {
        const TABLE_COLUMNS_COUNT = 7
        const {loadingFiltersAndPaginationData} = filtersAndPagination()

        const {exportOptions} = useTableDataExporter()

        return {loadingFiltersAndPaginationData, TABLE_COLUMNS_COUNT, exportOptions}
    },
    methods: {
        viewCustomerProfile(url) {
            if (url) {
                window.location = url
            }
        },
        setExportSelection(type) {
            this.exportOptions.selectType = type
            if (type === 'selectVisible' || type === 'selectAll') {
                this.exportOptions.selectedIds = []
                this.recentActivities.forEach(referralActivity => {
                    this.exportOptions.selectedIds.push(referralActivity.id)
                })
            }
            this.emitter.emit('hide-options', 'referral_activity_table_options');
        }
    },
    watch: {
        'exportOptions.selectedIds': {
            deep: true,
            handler(newValue) {
                if (this.exportOptions.selectType !== 'selectAll') {
                    this.exportOptions.selectTypeCustomModels = this.recentActivities
                        .filter(activity => newValue.includes(activity.id))
                        .map(activity => ({
                                id: activity.id,
                                identifier: activity.identifier,
                                action: activity.action
                            })
                        );
                }
            }
        }
    }
}
</script>

