<template>
    <div class="grid py-14 xl:grid-cols-3 gap-12">
        <div class="space-y-2">
            <h3 class="text-lg font-medium">
                Default style
            </h3>
            <p class="text-gray-500 text-sm">Choose the styling for default elements of your emails.</p>
        </div>
        <div class="grid col-span-2 gap-y-4 gap-8">

            <div class="grid lg:grid-cols-2 gap-12">
                <div>
                    <label class="block mb-1.5 text-sm">
                        Background color
                    </label>
                    <radio-group-inline-color name="email_background_color"
                                              :model-value="emailSettingsStore.getSettings.email_background_color || DEFAULT_EMAIL_BACKGROUND_COLOR"
                                              @picked="updateStoreValue($event, 'email_background_color')"
                                              :options="getCommonColorPalette('email_background_color')">
                    </radio-group-inline-color>
                </div>


                <div>
                    <label class="block mb-1.5 text-sm">
                        Brand color
                    </label>
                    <radio-group-inline-color name="email_brand_color"
                                              :model-value="emailSettingsStore.getSettings.email_brand_color || DEFAULT_MAIL_COLOR"
                                              @picked="updateStoreValue($event, 'email_brand_color')"
                                              :options="getBrandColorPalette('email_brand_color')">
                    </radio-group-inline-color>
                </div>


                <div>
                    <label class="block mb-1.5 text-sm">
                        Text and Heading color
                    </label>
                    <radio-group-inline-color name="email_text_color"
                                              :model-value="emailSettingsStore.getSettings.email_text_color || DEFAULT_TEXT_HEADING_AND_LINK_COLOR"
                                              @picked="updateStoreValue($event, 'email_text_color')"
                                              :options="getTextColorPalette('email_text_color')">
                    </radio-group-inline-color>
                </div>
                <div>
                    <label class="block mb-1.5 text-sm">
                        Link color
                    </label>
                    <radio-group-inline-color name="email_link_color"
                                              :model-value="emailSettingsStore.getSettings.email_link_color || DEFAULT_TEXT_HEADING_AND_LINK_COLOR"
                                              @picked="updateStoreValue($event, 'email_link_color')"
                                              :options="getTextColorPalette('email_link_color')"
                    >
                    </radio-group-inline-color>
                </div>
                <div>
                    <label class="block mb-1.5 text-sm">
                        Footer text color
                    </label>
                    <radio-group-inline-color name="email_footer_text_color"
                                              :model-value="emailSettingsStore.getSettings.email_footer_text_color || DEFAULT_FOOTER_TEXT_COLOR"
                                              @picked="updateStoreValue($event, 'email_footer_text_color')"
                                              :options="getCommonColorPalette('email_footer_text_color')">
                    </radio-group-inline-color>
                </div>
                <div>
                    <label class="block mb-1.5 text-sm">
                        Divider color
                    </label>
                    <radio-group-inline-color name="email_divider_color"
                                              :model-value="emailSettingsStore.getSettings.email_divider_color || DEFAULT_DIVIDER_COLOR"
                                              @picked="updateStoreValue($event, 'email_divider_color')"
                                              :options="getCommonColorPalette('email_divider_color')">
                    </radio-group-inline-color>
                </div>
                <!-- Needed to instantiate revolvapp and update templates -->
                <div id="revolvApp" class="hidden"/>
            </div>
        </div>
    </div>
</template>

<script setup>

    import {useEmailSettingsStore} from "../../../../../stores/app/emails/settings/EmailSettingsStore";

    import {
        DEFAULT_MAIL_COLOR,
        DEFAULT_TEXT_HEADING_AND_LINK_COLOR,
        DEFAULT_FOOTER_TEXT_COLOR,
        DEFAULT_DIVIDER_COLOR,
        DEFAULT_EMAIL_BACKGROUND_COLOR
    } from "../../../../../helpers/revolvapp-helper";

    let emailSettingsStore = useEmailSettingsStore()

    const getCommonColorPalette = (fieldName) => {
        return [
            { value: '#ffffff', label: 'bg-white', label_is_class: true},
            { value: '#fefefe', label: 'bg-gray-50', label_is_class: true}, // HEX is not reflecting real Tailwind color but it's better
            { value: '#f3f4f6', label: 'bg-gray-100', label_is_class: true},
            { value: '#e5e7eb', label: 'bg-gray-200', label_is_class: true},
            { value: '#d1d5db', label: 'bg-gray-300', label_is_class: true},
            { value: 'custom', label: 'Custom', custom: { name: fieldName, placeholder: '', type: 'input' }},
        ]
    }

    const getTextColorPalette = (fieldName) => {
        return [
            { value: '#111827', label: 'bg-gray-900', label_is_class: true},
            { value: '#1f2937', label: 'bg-gray-800', label_is_class: true},
            { value: '#374151', label: 'bg-gray-700', label_is_class: true},
            { value: '#4b5563', label: 'bg-gray-600', label_is_class: true},
            { value: '#6b7280', label: 'bg-gray-500', label_is_class: true},
            { value: 'custom', label: 'Custom', custom: { name: fieldName, placeholder: '', type: 'input' }},
        ]
    }

    const getBrandColorPalette = (fieldName) => {
        return [
            { value: '#4338ca', label: 'bg-indigo-700', label_is_class: true},
            { value: '#1d4ed8', label: 'bg-blue-700', label_is_class: true},
            { value: '#0e7490', label: 'bg-cyan-700', label_is_class: true},
            { value: '#0d9488', label: 'bg-teal-600', label_is_class: true},
            { value: '#111827', label: 'bg-gray-900', label_is_class: true},
            { value: 'custom', label: 'Custom', custom: { name: fieldName, placeholder: '', type: 'input' }},
        ]
    }

    const updateStoreValue = (data, settingsKey) => {
        data?.pickedValue === 'custom'
            ? emailSettingsStore.settings[settingsKey] = data.customValue
            : emailSettingsStore.settings[settingsKey] = data.pickedValue
    }
</script>
