<template>

    <div class="space-y-6">

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>

            <div class="space-y-2">
                <div class="text-sm space-y-2">
                    <p>Follow these steps to insert Partnero Universal into your Wix site:</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Navigate to <b>Website</b></li>
                        <li>Go to <b>Settings</b> -> <b>Custom code</b></li>
                        <li>Insert the Partnero Universal script into the <b>Head</b> section</li>
                    </ol>
                    <p>Make sure that the script is being loaded on all pages.</p>
                </div>
            </div>

        </div>

        <div class="space-y-4 border-t pt-6">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sign-ups tracking</h3>
                <div class="text-sm space-y-2">
                    <p>On the same page, insert the following script into <b>Body - End</b> section.</p>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-1').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-1" rows="3" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs"><script>
po('integration', 'wix', null);
</script></textarea>
            </div>
        </div>

        <div class="space-y-4 border-t pt-6">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sales tracking on checkout</h3>
                <div class="text-sm space-y-2">
                    <p>To enable sales tracking for your Wix store, please follow these steps:</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Navigate to the <b>Wix Website Editor</b> and enable the <b>Velo Developer Mode</b></li>
                        <li>Once <b>Velo Developer Mode</b> is enabled, please go to the <b>Public & Backend</b> section</li>
                        <li>Then, click on the <b>+</b> sign next to the <b>Backend</b> area and choose <b>New .js File</b></li>
                        <li>Name the new file <b>events.js</b></li>
                        <li>Then, add the snippet below to the <b>events.js</b></li>
                    </ol>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-2').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-2" rows="3" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs">import {fetch} from 'wix-fetch';

export async function wixStores_onOrderPaid(event) {
    await fetch("https://api.partnero.com/v1/transactions", {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer [PARTNERO_API_KEY]'
        },
        body: JSON.stringify({
            action: "sale",
            customer: {
                key: event.billingInfo.email
            },
            key: event._id,
            amount: event.totals.total,
            amount_units: event.currency
        })
    });
}</textarea>
            </div>

            <div class="text-sm space-y-2">
                <p><b>Important!</b> Replace <b>[PARTNERO_API_KEY]</b> with real API key that can be created <a class="underline hover:no-underline" href="../integrations">here</a>.</p>
            </div>

        </div>

    </div>

</template>
