<template>
    <modal title="Create a customer" size="small" name="partner_customer_create" @modalClose="modalClose">
        <template #toggle>
            <btn type="primary">Create customer</btn>
        </template>
        <template #body>
            <div class="space-y-4 text-left">

                <alert type="error" v-if="errorMessage">{{ errorMessage }}</alert>
                <alert type="success" v-if="successMessage">{{ successMessage }}</alert>

                <div class="text-sm text-gray-500" v-if="!refer_a_friend_program">
                    Create and assign a new customer to your partner.
                </div>
                <div class="text-sm text-gray-500" v-if="refer_a_friend_program">
                    Create a new customer who will be participating in your refer-a-friend program.
                </div>

                <div>
                    <label for="email" class="block text-sm opacity-80 mb-1.5">
                        Email address
                    </label>
                    <text-field fieldId="email" @keydown.enter="createCustomer" autofucus="true"
                                placeholder="Enter customer's email address" :error="(formErrors.email ?? false)"
                                v-model="email"></text-field>
                </div>
                <div>
                    <label for="name" class="block text-sm opacity-80 mb-1.5">
                        Name
                    </label>
                    <text-field fieldId="name" @keydown.enter="createCustomer"
                                placeholder="Enter customer's name" :error="(formErrors.name ?? false)"
                                v-model="name"></text-field>
                </div>
                <div>
                    <label for="identifier" class="block text-sm opacity-80 mb-1.5" v-if="!refer_a_friend_program">
                        Customer's identifier
                    </label>
                    <label for="identifier" class="block text-sm opacity-80 mb-1.5" v-if="refer_a_friend_program">
                        Customer's ID
                    </label>
                    <text-field fieldId="identifier" @keydown.enter="createCustomer"
                                :placeholder="identifier_placeholder" :error="(formErrors.identifier ?? false)"
                                v-model="identifier"></text-field>
                </div>
                <div v-show="showMore">
                    <label for="surname" class="block text-sm opacity-80 mb-1.5">
                        Last name (optional)
                    </label>
                    <text-field fieldId="surname" @keydown.enter="createCustomer"
                                placeholder="Enter a last name or leave it empty" :error="(formErrors.surname ?? false)"
                                v-model="surname"></text-field>
                </div>
                <div v-show="showMore && refer_a_friend_program">
                    <label for="key" class="block text-sm opacity-80 mb-1.5">
                        Customer key / token (optional)
                    </label>
                    <text-field fieldId="key" @keydown.enter="createCustomer"
                                placeholder="Enter a personalized token or leave it empty"
                                :error="(formErrors.key ?? false)"
                                v-model="key">
                        <template #note>
                            Key will be used to generate an unique referral link. If not provided, key is generated
                            automatically.
                        </template>
                    </text-field>
                </div>
                <div v-show="showMore && refer_a_friend_program">
                    <label for="date_of_birth" class="block text-sm opacity-80 mb-1.5">
                        Date of birth (optional)
                    </label>
                    <vs-datepicker
                        label=""
                        id="customer-metadata-date-of-birth"
                        v-model="metadata.date_of_birth"
                        placeholder="YYYY-MM-DD"
                        format="YYYY-MM-DD"
                        value-type="format"
                        :clearable="true"
                        class="!w-full"
                        :class="{'border-red-500': (formErrors.date_of_birth ?? false)}"
                    ></vs-datepicker>
                </div>
                <div @click.prevent="showMoreFields()" class="flex justify-end">
                    <a href="" class="flex items-center hover:underline">
                        <span class="text-xs font-medium ml-1">
                            {{ showMore ? 'Show less fields' : 'Show more fields' }}
                        </span>
                    </a>
                </div>
            </div>
        </template>
        <template #footer>
            <btn :loading="loading" @click="createCustomer" type="primary" :disabled="loading">{{ loading ? 'Creating customer' : 'Create customer' }}</btn>
        </template>
    </modal>
</template>
<script>
import Icon from "../../../../elements/Icon";
import useFilters from "../../../../state/common/filters-and-pagination";
import VsDatepicker from '@vuesimple/vs-datepicker';

export default {
    name: 'partnerCustomerCreate',
    components: {Icon, VsDatepicker},
    props: {
        program: {
            type: [String, Number],
            required: true
        },
        partner: {
            type: [String, Number],
            required: true
        },
        allow_empty_key: {
            type: Boolean,
            required: false,
            default: false
        },
        refer_a_friend_program: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            errorMessage: false,
            successMessage: false,
            loading: false,
            formErrors: {},
            email: '',
            name: '',
            surname: '',
            identifier: '',
            key: '',
            metadata: {
                date_of_birth: null
            },
            showMore: false,
            identifier_placeholder: this.refer_a_friend_program ? 'Enter an ID or leave it empty' : 'Enter an identifier'
        }
    },
    methods: {
        createCustomer() {

            this.errorMessage = false;
            this.successMessage = false;

            if (this.loading || !this.valid()) {
                return;
            }


            this.loading = true;

            axios
                .post(route('app_int.views.program.partner.customer.create', [this.program, this.partner]), {
                    email: this.email,
                    name: this.name,
                    surname: this.surname,
                    identifier: this.identifier,
                    key: this.key,
                    metadata: this.metadata
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {

                        const self = this;
                        this.successMessage = 'Customer created successfully.';

                        setTimeout(function () {
                            self.emitter.emit('modal-off', 'partner_customer_create');
                            self.cleanForm();
                        }, 2000);

                        self.fetchCustomersData()
                    } else if (status === 0) {
                        this.errorMessage = response.data?.error || 'Failed to create customer';
                    }
                })
                .catch(error => {
                    let generalErrorMessage = error.response.data.error || '';
                    let emailError = error.response.data.errors?.email || '';
                    let name = error.response.data.errors?.name || '';
                    let identifier = error.response.data.errors?.identifier || '';
                    let key = error.response.data.errors?.key || '';

                    const errorMessage = `${generalErrorMessage} ${emailError} ${name} ${identifier} ${key}`.trim();

                    if (errorMessage) {
                        this.errorMessage = errorMessage;
                    }
                }).finally(() => this.loading = false);
        },
        valid() {

            let valid = true;
            this.formErrors = {};

            if (!this.refer_a_friend_program) {
                if (this.inputIsEmpty(this.email) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.email) !== true) {
                    valid = false;
                    this.formErrors.email = true;
                }
            }

            if (this.inputIsEmpty(this.name) || this.name.length > 100) {
                valid = false;
                this.formErrors.name = true;
            }

            if (!this.allow_empty_key) {
                if (this.inputIsEmpty(this.identifier) || this.identifier.length > 100) {
                    valid = false;
                    this.formErrors.identifier = true;
                }
            }

            return valid;
        },
        modalClose(name) {
            this.cleanForm();
        },
        cleanForm() {
            this.email = '';
            this.name = '';
            this.surname = '';
            this.key = '';
            this.identifier = '';
            this.errorMessage = false;
            this.successMessage = false;
            this.formErrors = {};
            this.showMore = false;
        },
        inputIsEmpty(value) {
            return (value ?? '').trim().length < 1;
        },
        fetchCustomersData() {
            const {
                fetchDataBaseUrl,
                fetchDataFunction,
            } = useFilters()
            fetchDataFunction.value(fetchDataBaseUrl.value)
        },
        showMoreFields() {
            this.showMore = !this.showMore;
        }
    }
}
</script>
<style scoped>
</style>
