<template>
    <div>

        <label class="block mb-1 text-sm" v-if="domains.length < 1">
            Connect custom domain
        </label>
        <small class="text-gray-500 mt-1 mb-3 block" v-if="domains.length < 1">
            Connect your own domain in order to have custom referral links.
        </small>

        <div class="space-y-2">
            <template v-for="(oneDomain, index) in allDomains" :id="index">
                <div class="rounded border bg-white">
                    <div class="flex justify-between py-4 px-5">
                        <a :href="'https://' + oneDomain.domain" target="_blank"
                           class="flex items-center space-x-4 truncate cursor-pointer group">
                            <svg class="w-5 h-5 text-gray-900 group-hover:text-teal-700"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                                 stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"></path>
                                <line x1="10" y1="14" x2="20" y2="4"></line>
                                <polyline points="15 4 20 4 20 9"></polyline>
                            </svg>
                            <span class="text-sm truncate group-hover:text-teal-700 group-hover:underline">
                                {{ oneDomain.domain }}
                            </span>
                            <badge title="Default" v-if="oneDomain.id === default_id"></badge>
                        </a>
                        <div class="flex pl-6 space-x-4">
                            <modal title="Delete domain" size="small" class="h-6">
                                <template #toggle>
                                    <button type="button" class="focus:outline-none mt-0.5">
                                        <svg class="w-5 h-5 text-gray-500 hover:text-red-600" fill="currentColor"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                             height="24">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path
                                                d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"/>
                                        </svg>
                                    </button>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div class="text-gray-700">
                                            Do you really want to delete domain "{{ oneDomain.domain }}"?
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn :loading="deleting_loading" v-on:click="deleteDomain(oneDomain.id)"
                                         type="primary">Delete
                                    </btn>
                                </template>
                            </modal>

                            <modal title="Custom link settings" class="h-6" name="utm_tags_modal">
                                <template #toggle>
                                    <button type="button" class="focus:outline-none mt-0.5">
                                        <svg class="w-5 h-5 text-gray-500 hover:text-gray-900" fill="currentColor"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                             height="24">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path
                                                d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                        </svg>
                                    </button>
                                </template>
                                <template #body>
                                    <div class="space-y-6">
                                        <div
                                            v-if="!oneDomain.verified"
                                            class="flex items-center bg-red-50 px-4 py-3 border border-red-100 rounded text-red-500 text-sm">
                                            <div
                                                class="flex justify-center items-center w-4 h-4 bg-red-400 rounded-full mr-2">
                                                <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor"
                                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                </svg>
                                            </div>
                                            <span>DNS records for <b>{{ oneDomain.domain }}</b> cannot be found.</span>
                                        </div>

                                        <div class="text-gray-700 text-sm" v-if="!oneDomain.verified">
                                            Head over to your DNS provider and add provided DNS records to verify your
                                            domain.
                                        </div>

                                        <div v-if="!oneDomain.verified">
                                            <div class="divide-y">
                                                <div
                                                    class="grid grid-cols-11 gap-8 pb-2 text-xs uppercase text-gray-500 ">
                                                    <div class="col-span-1">Status</div>
                                                    <div class="col-span-2">Type</div>
                                                    <div class="col-span-4">Name</div>
                                                    <div class="col-span-4">Content</div>
                                                </div>
                                                <div class="grid grid-cols-11 gap-8 py-2">
                                                    <div class="col-span-1">
                                                        <div v-if="oneDomain.verified"
                                                             class="flex justify-center items-center w-5 h-5 bg-teal-400 rounded-full">
                                                            <svg class="w-4 h-4 text-white" fill="none"
                                                                 stroke="currentColor"
                                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2" d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div v-if="!oneDomain.verified"
                                                             class="flex justify-center items-center w-5 h-5 bg-gray-400 rounded-full">
                                                            <svg class="w-4 h-4 text-white" fill="none"
                                                                 stroke="currentColor"
                                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div v-if="oneDomain.is_top_domain"
                                                         class="bg-gray-200 col-span-2 flex h-5 items-center justify-center rounded text-xs w-min px-1.5 font-medium">
                                                        A
                                                    </div>
                                                    <div v-else
                                                         class="bg-gray-200 col-span-2 flex h-5 items-center justify-center rounded text-xs w-min px-1.5 font-medium">
                                                        CNAME
                                                    </div>
                                                    <div
                                                        class="col-span-4 text-sm py-0.5 break-all whitespace-normal font-medium">
                                                        {{ oneDomain.domain }}
                                                    </div>
                                                    <div v-if="oneDomain.is_top_domain"
                                                         class="col-span-4 text-sm py-0.5 break-all whitespace-normal font-medium">
                                                        {{ a_record_target }}
                                                    </div>
                                                    <div v-else
                                                         class="col-span-4 text-sm py-0.5 break-all whitespace-normal font-medium">
                                                        {{ cname_target }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <alert type="success" class="border border-green-100" v-if="oneDomain.verified">
                                            Great! Domain <b>{{ oneDomain.domain }}</b> is successfully linked to
                                            Partnero. Partners can create links using this domain.
                                        </alert>

                                        <div v-if="oneDomain.verified">
                                            <div class="flex justify-between items-center mt-3">
                                                <toggle name="default"
                                                        @update:modelValue="oneDomain.default = $event"
                                                        :model-value="oneDomain?.default ?? false"
                                                ></toggle>
                                                <span class="flex flex-col flex-grow" id="default">
                                                    <span class="text-sm font-medium text-gray-900">
                                                        Set as default
                                                    </span>
                                                    <span class="text-sm text-gray-500">Set this link as default tracking link.</span>
                                                </span>
                                            </div>
                                            <div class="flex justify-between items-center mt-3">
                                                <toggle name="utm_tags"
                                                        @update:modelValue="oneDomain.utm_tags.enabled = $event"
                                                        :model-value="oneDomain?.utm_tags?.enabled ?? false"></toggle>
                                                <span class="flex flex-col flex-grow" id="utm_tags">
                                                        <span
                                                            class="text-sm font-medium text-gray-900">Enable UTM tags</span>
                                                        <span class="text-sm text-gray-500">Add parameters to your URLs to track custom links traffic on web analytics platforms.</span>
                                                    </span>
                                            </div>
                                            <div class="border rounded mt-6 px-6 py-6"
                                                 v-if="oneDomain?.utm_tags?.enabled ?? false">
                                                <div class="grid md:grid-cols-2 col-span-2 gap-6 gap-y-4">
                                                    <div>
                                                        <text-field
                                                            label="Campaign source"
                                                            field-id="campaign_source"
                                                            name="campaign_source"
                                                            v-on:keydown.enter="saveUtmTags(oneDomain)"
                                                            :model-value="oneDomain.utm_tags.tags.campaign_source ?? ''"
                                                            @update:modelValue="oneDomain.utm_tags.tags.campaign_source = $event"
                                                            type="text">
                                                        </text-field>
                                                        <small class="text-gray-500 mt-1 block">Added to the links as
                                                            <i>utm_source</i>.</small>
                                                    </div>
                                                    <div>
                                                        <text-field
                                                            label="Campaign medium"
                                                            field-id="campaign_medium"
                                                            name="campaign_medium"
                                                            v-on:keydown.enter="saveUtmTags(oneDomain)"
                                                            :model-value="oneDomain?.utm_tags.tags.campaign_medium ?? ''"
                                                            @update:modelValue="oneDomain.utm_tags.tags.campaign_medium = $event"
                                                            type="text">
                                                        </text-field>
                                                        <small class="text-gray-500 mt-1 block">Added to the links as
                                                            <i>utm_medium</i>.</small>
                                                    </div>
                                                    <div>
                                                        <text-field
                                                            label="Campaign name"
                                                            field-id="campaign_name"
                                                            name="campaign_name"
                                                            v-on:keydown.enter="saveUtmTags(oneDomain)"
                                                            :model-value="oneDomain?.utm_tags.tags.campaign_name ?? ''"
                                                            @update:modelValue="oneDomain.utm_tags.tags.campaign_name = $event"
                                                            type="text">
                                                        </text-field>
                                                        <small class="text-gray-500 mt-1 block">Added to the links as
                                                            <i>utm_campaign</i>.</small>
                                                    </div>
                                                    <div>
                                                        <text-field
                                                            label="Campaign term"
                                                            field-id="campaign_term"
                                                            name="campaign_term"
                                                            v-on:keydown.enter="saveUtmTags(oneDomain)"
                                                            :model-value="oneDomain?.utm_tags.tags.campaign_term ?? ''"
                                                            @update:modelValue="oneDomain.utm_tags.tags.campaign_term = $event"
                                                            type="text">
                                                        </text-field>
                                                        <small class="text-gray-500 mt-1 block">Added to the links as
                                                            <i>utm_term</i>.</small>
                                                    </div>
                                                    <div>
                                                        <text-field
                                                            label="Campaign content"
                                                            field-id="campaign_content"
                                                            name="campaign_content"
                                                            v-on:keydown.enter="saveUtmTags(oneDomain)"
                                                            :model-value="oneDomain?.utm_tags.tags.campaign_content ?? ''"
                                                            @update:modelValue="oneDomain.utm_tags.tags.campaign_content = $event"
                                                            type="text">
                                                        </text-field>
                                                        <small class="text-gray-500 mt-1 block">Added to the links as
                                                            <i>utm_content</i>.</small>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <btn
                                        v-on:click="saveUtmTags(oneDomain)"
                                        :loading="saving_utm_loading"
                                        type="primary"
                                        v-if="oneDomain.verified">
                                        Save
                                    </btn>
                                    <btn v-if="!oneDomain.verified" v-on:click="reVerifyCustomDomain(oneDomain)"
                                         :loading="verification_loading"
                                         type="primary">
                                        Check DNS records
                                    </btn>
                                </template>
                            </modal>
                        </div>
                    </div>
                </div>
            </template>

            <modal size="small" :title="'Connect your domain'">
                <template #toggle>
                    <btn class="mt-2">Connect domain</btn>
                </template>
                <template #body>
                    <div class="space-y-6">
                        <div class="text-gray-700 text-sm">
                            Enter the URL of the domain you’d like to connect.
                        </div>
                        <div>
                            <label
                                class="block mb-1 text-sm font-medium text-gray-700 hidden">
                                Domain
                            </label>
                            <div class="flex rounded">
                                <div
                                    class="flex flex-grow items-stretch w-full focus-within:z-10">
                                    <input
                                        type="text"
                                        name="newDomain"
                                        id="newDomain"
                                        v-model.trim=newDomain
                                        v-on:input="customDomainChange"
                                        v-on:keyup.enter="saveCustomDomain"
                                        placeholder="custom.domain.com"
                                        class="block -ml-px w-full border-gray-300 transition-shadow rounded-r sm:text-sm focus:ring-indigo-100 focus:ring-0 focus:border-gray-400"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #footer>
                    <modal title="Domain verification" @modalClose="verificationModalClose">
                        <template #toggle>
                            <btn>Next</btn>
                        </template>
                        <template #body>
                            <div class="space-y-6">
                                <template v-for="(error, index) in save_errors" :id="index">
                                    <div
                                        class="flex items-center bg-red-50 px-4 py-3 border border-red-100 rounded text-red-500 text-sm">
                                        <div
                                            class="flex justify-center items-center w-4 h-4 bg-red-400 rounded-full mr-2">
                                            <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor"
                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                      d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        </div>
                                        <span>{{ error }}</span>
                                    </div>
                                </template>
                                <template v-for="(error, index) in verification_errors" :id="index">
                                    <div
                                        class="flex items-center bg-red-50 px-4 py-3 border border-red-100 rounded text-red-500 text-sm">
                                        <div
                                            class="flex justify-center items-center w-4 h-4 bg-red-400 rounded-full mr-2">
                                            <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor"
                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                      d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        </div>
                                        <span>{{ error }}</span>
                                    </div>
                                </template>

                                <div class="text-gray-700 text-sm">
                                    Go to your DNS provider and create the following two records to verify your domain:
                                </div>
                                <div v-if="newDomainType !== newDomainTypes.SUBDOMAIN">
                                    <div>
                                        <div class="divide-y">
                                            <div class="grid grid-cols-11 gap-8 pb-2 text-xs uppercase text-gray-500">
                                                <div class="col-span-1">Status</div>
                                                <div class="col-span-2">Type</div>
                                                <div class="col-span-4">Name</div>
                                                <div class="col-span-4">Content</div>
                                            </div>

                                            <div v-if="newDomainType === newDomainTypes.TOP_DOMAIN">
                                                <div class="grid grid-cols-11 gap-8 py-2">
                                                    <div class="col-span-1">
                                                        <div v-if="isValidDomain(newDomain)"
                                                             class="flex justify-center items-center w-5 h-5 bg-teal-400 rounded-full">
                                                            <svg class="w-4 h-4 text-white" fill="none"
                                                                 stroke="currentColor"
                                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2" d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div v-if="!isValidDomain(newDomain)"
                                                             class="flex justify-center items-center w-5 h-5 bg-gray-400 rounded-full">
                                                            <svg class="w-4 h-4 text-white" fill="none"
                                                                 stroke="currentColor"
                                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="bg-gray-200 col-span-2 flex h-5 items-center justify-center rounded text-xs w-min px-1.5 font-medium">
                                                        A
                                                    </div>
                                                    <div
                                                        class="col-span-4 text-sm py-0.5 break-all whitespace-normal font-medium">
                                                        {{ newDomain }}
                                                    </div>
                                                    <div
                                                        class="col-span-4 text-sm py-0.5 break-all whitespace-normal font-medium">
                                                        {{ a_record_target }}
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-11 gap-8 py-2" v-if="topDomainSecondOption">
                                                    <div class="col-span-1">
                                                        <div v-if="isValidDomain('www.' + newDomain)"
                                                             class="flex justify-center items-center w-5 h-5 bg-teal-400 rounded-full">
                                                            <svg class="w-4 h-4 text-white" fill="none"
                                                                 stroke="currentColor"
                                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2" d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div v-if="!isValidDomain('www.' + newDomain)"
                                                             class="flex justify-center items-center w-5 h-5 bg-gray-400 rounded-full">
                                                            <svg class="w-4 h-4 text-white" fill="none"
                                                                 stroke="currentColor"
                                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="bg-gray-200 col-span-2 flex h-5 items-center justify-center rounded text-xs w-min px-1.5 font-medium">
                                                        CNAME
                                                    </div>
                                                    <div
                                                        class="col-span-4 text-sm break-all whitespace-normal font-medium">
                                                        {{ 'www.' + newDomain }}
                                                    </div>
                                                    <div
                                                        class="col-span-4 text-sm break-all whitespace-normal font-medium">
                                                        {{ cname_target }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="newDomainType === newDomainTypes.TOP_DOMAIN_WWW">
                                                <div class="grid grid-cols-11 gap-8 py-2">
                                                    <div class="col-span-1">
                                                        <div v-if="isValidDomain(newDomain)"
                                                             class="flex justify-center items-center w-5 h-5 bg-teal-400 rounded-full">
                                                            <svg class="w-4 h-4 text-white" fill="none"
                                                                 stroke="currentColor"
                                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2" d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div v-if="!isValidDomain(newDomain)"
                                                             class="flex justify-center items-center w-5 h-5 bg-gray-400 rounded-full">
                                                            <svg class="w-4 h-4 text-white" fill="none"
                                                                 stroke="currentColor"
                                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="bg-gray-200 col-span-2 flex h-5 items-center justify-center rounded text-xs w-min px-1.5 font-medium">
                                                        CNAME
                                                    </div>
                                                    <div
                                                        class="col-span-4 text-sm py-0.5 break-all whitespace-normal font-medium">
                                                        {{ newDomain }}
                                                    </div>
                                                    <div
                                                        class="col-span-4 text-sm py-0.5 break-all whitespace-normal font-medium">
                                                        {{ cname_target }}
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-11 gap-8 py-2" v-if="topDomainSecondOption">
                                                    <div class="col-span-1">
                                                        <div v-if="isValidDomain(newDomainWithoutWWW)"
                                                             class="flex justify-center items-center w-5 h-5 bg-teal-400 rounded-full">
                                                            <svg class="w-4 h-4 text-white" fill="none"
                                                                 stroke="currentColor"
                                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2" d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div v-if="!isValidDomain(newDomainWithoutWWW)"
                                                             class="flex justify-center items-center w-5 h-5 bg-gray-400 rounded-full">
                                                            <svg class="w-4 h-4 text-white" fill="none"
                                                                 stroke="currentColor"
                                                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="bg-gray-200 col-span-2 flex h-5 w-min px-1.5 items-center justify-center rounded text-xs font-medium">
                                                        A
                                                    </div>
                                                    <div
                                                        class="col-span-4 text-sm break-all whitespace-normal font-medium">
                                                        {{ newDomainWithoutWWW }}
                                                    </div>
                                                    <div
                                                        class="col-span-4 text-sm break-all whitespace-normal font-medium">
                                                        {{ a_record_target }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="newDomainType !== newDomainTypes.SUBDOMAIN" class="space-x-2 flex text-sm">
                                    <input type="checkbox"
                                           name="www"
                                           id="www"
                                           v-model=topDomainSecondOption
                                           v-on:change="topDomainSecondOptionChange"
                                           class="h-5 w-5 rounded cursor-pointer border-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 text-teal-500">
                                    <label for="www">
                                        {{ topDomainMessage }}
                                    </label>
                                </div>

                                <div v-if="newDomainType === newDomainTypes.SUBDOMAIN">
                                    <div class="divide-y">
                                        <div
                                            class="grid grid-cols-11 gap-8 pb-2 text-xs uppercase text-gray-500">
                                            <div class="col-span-1">Status</div>
                                            <div class="col-span-2">Type</div>
                                            <div class="col-span-4">Name</div>
                                            <div class="col-span-4">Content</div>
                                        </div>
                                        <div class="grid grid-cols-11 gap-8 py-2">
                                            <div class="col-span-1">
                                                <div v-if="isValidDomain(newDomain)"
                                                     class="flex justify-center items-center w-5 h-5 bg-teal-400 rounded-full">
                                                    <svg class="w-4 h-4 text-white" fill="none"
                                                         stroke="currentColor"
                                                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              stroke-width="2" d="M5 13l4 4L19 7"></path>
                                                    </svg>
                                                </div>
                                                <div v-if="!isValidDomain(newDomain)"
                                                     class="flex justify-center items-center w-5 h-5 bg-gray-400 rounded-full">
                                                    <svg class="w-4 h-4 text-white" fill="none"
                                                         stroke="currentColor"
                                                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                class="bg-gray-200 col-span-2 flex h-5 items-center justify-center rounded text-xs w-min px-1.5 font-medium">
                                                CNAME
                                            </div>
                                            <div class="col-span-4 text-sm break-all whitespace-normal font-medium">
                                                {{ newDomain }}
                                            </div>
                                            <div class="col-span-4 text-sm break-all whitespace-normal font-medium">
                                                {{ cname_target }}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="flex justify-between items-center">
                                    <toggle name="addedDns" :model-value="allow_verify_domain"
                                            @update:modelValue="addedDnsCheck"></toggle>
                                    <span class="flex flex-col flex-grow" id="addedDns">
                                        <span
                                            class="text-sm font-medium text-gray-900">I have added the DNS records</span>
                                    </span>
                                </div>
                            </div>
                        </template>
                        <template #footer>
                            <btn v-on:click="verifyCustomDomain" :loading="verification_loading" type="primary"
                                 v-if="!allow_save_domain"
                                 :disabled="!allow_verify_domain">
                                Check DNS records
                            </btn>
                            <btn v-on:click="saveCustomDomain" :loading="adding_new_loading" type="primary"
                                 v-if="allow_save_domain"
                                 :disabled="!allow_save_domain">
                                Save
                            </btn>
                        </template>
                    </modal>
                </template>
            </modal>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgramCustomDomains",
    props: ['domains', 'create_url', 'save_utm_url', 'remove_url', 'update_url', 'verify_url', 'cname_targets', 'a_record_targets'],
    data() {
        return {
            newDomain: '',
            allDomains: this.domains,
            newDomainWithoutWWW: '',
            newDomainTypes: {
                SUBDOMAIN: 1,
                TOP_DOMAIN: 2,
                TOP_DOMAIN_WWW: 3,
            },
            topDomainMessage: '',
            topDomainMessages: {
                WWW: "Would you like to redirect your base domain to the domain you're connecting?",
                NON_WWW: "I would like to also redirect the “www” traffic to the connected domain"
            },
            save_errors: [],
            delete_errors: [],
            update_errors: [],
            verification_errors: [],
            verifications: [],
            newDomainType: false,
            allow_save_domain: false,
            allow_verify_domain: false,
            show_utm_tags: false,
            topDomainSecondOption: true,
            cname_target: this.cname_targets[0],
            a_record_target: this.a_record_targets[0],
            deleting_loading: false,
            adding_new_loading: false,
            verification_loading: false,
            saving_utm_loading: false,

            default_id: this.domains.find(domain => domain.default)?.id
        }
    },
    methods: {
        saveUtmTags: function (domain) {

            if (this.saving_utm_loading) {
                return;
            }

            this.saving_utm_loading = true;

            this.update(domain);

            axios
                .post(this.save_utm_url, {domain_id: domain.id, utm_tags: domain.utm_tags})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        //maybe better show success message instead of closing?
                        this.emitter.emit('modal-off', 'utm_tags_modal')
                    }
                })
                .catch(error => {
                })
                .finally(() => this.saving_utm_loading = false);
        },
        deleteDomain: function (domainId) {

            if (this.deleting_loading) {
                return;
            }

            this.delete_errors = [];
            this.deleting_loading = true;

            axios
                .post(this.remove_url, {domain_id: domainId})
                .then(response => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        window.location.reload();
                    }
                })
                .catch(error => {
                    this.delete_errors = error.response.data.errors.domain || [];
                })
                .finally(() => this.deleting_loading = false);
        },
        update: function (domain) {
            axios
                .post(this.update_url, { domain: domain })
                .then(response => {
                    const status = parseInt(response.data.status || 0);

                    if (status === 1) {
                        if (domain.default) {
                            this.default_id = domain.id
                        } else if(this.default_id === domain.id) {
                            this.default_id = null
                        }

                        this.allDomains = this.allDomains.map((domain) => {
                            if (domain.id !== this.default_id) {
                                domain.default = false
                            }
                            return domain
                        })
                    }
                })
                .catch(error => {
                    this.update_errors = error.response.data.errors || [];
                })
                .finally(() => {});

        },
        addedDnsCheck: function (value) {
            this.allow_verify_domain = value;
        },
        reVerifyCustomDomain: function (oneDomain) {

            if (this.verification_loading) {
                return;
            }

            this.verification_loading = true;

            axios
                .post(this.verify_url, {re_verify: oneDomain.id})
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {
                        oneDomain.verified = true;
                    }

                    if (status === 2) {
                        oneDomain.verified = false;
                    }

                    if (status === 0) {
                        // general errors
                    }
                })
                .catch(error => {
                    // general errors
                })
                .finally(() => this.verification_loading = false);
        },
        verifyCustomDomain: function () {

            if (this.verification_loading) {
                return;
            }

            this.verifications = [];
            this.verification_errors = [];
            this.verification_loading = true;

            axios
                .post(this.verify_url, {
                    domain: this.newDomain,
                    www_option: this.topDomainSecondOption,
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);
                    if (status === 1) {
                        this.verifications = response.data.verifications || [];
                        if (this.checkVerifications()) {
                            this.allow_save_domain = true;
                        } else {

                            /**
                             * New business logic: allow to save custom link even if verification failed
                             */
                            this.allow_save_domain = true;

                            for (let domain in this.verifications) {
                                if (this.verifications.hasOwnProperty(domain) && !this.verifications[domain]?.verified) {
                                    this.verification_errors.push(domain + ': ' + this.verifications[domain]?.reason);
                                }
                            }
                        }
                    }

                    if (status === 0) {
                        this.verification_errors = response.data.errors.domain || [];
                    }
                })
                .catch(error => {
                    this.verification_errors = error.response.data.errors.domain || [];
                })
                .finally(() => this.verification_loading = false);
        },
        topDomainSecondOptionChange: function () {
            this.allow_save_domain = false;
        },
        isValidDomain: function (domain) {
            return this.verifications[domain]?.verified || false;
        },
        saveCustomDomain: function () {

            if (this.adding_new_loading || !this.allow_verify_domain) {
                return;
            }

            this.save_errors = [];
            this.adding_new_loading = true;

            axios
                .post(this.create_url, {
                    domain: this.newDomain,
                    domainType: this.domainType,
                    www_option: this.topDomainSecondOption,
                })
                .then(response => {
                    const status = parseInt(response.data.status || 0);

                    if (status === 1) {
                        window.location.reload();
                    }

                    if (status === 0) {
                        this.save_errors = response.data.errors.domain || [];
                    }
                })
                .catch(error => {
                    this.save_errors = error.response.data.errors.domain || [];
                })
                .finally(() => this.adding_new_loading = false);
        },
        customDomainChange: function () {

            this.newDomainType = this.newDomainTypes.SUBDOMAIN;

            const newDomain = (this.newDomain || '').trim();
            const domainParts = newDomain.split('.').filter((domainPart) => domainPart.trim() !== '');

            if (domainParts.length === 2 && domainParts[0].trim().toLowerCase() !== 'www') {
                this.newDomainType = this.newDomainTypes.TOP_DOMAIN;
                this.topDomainMessage = this.topDomainMessages.NON_WWW;
            } else if (domainParts.length === 3 && domainParts[0].trim().toLowerCase() === 'www') {
                this.newDomainType = this.newDomainTypes.TOP_DOMAIN_WWW;
                this.topDomainMessage = this.topDomainMessages.WWW;
                this.newDomainWithoutWWW = domainParts.splice(1).join('.');
            } else if (domainParts.length > 2) {
                this.newDomainType = this.newDomainTypes.SUBDOMAIN;
            }
        },
        checkVerifications: function () {
            for (let domain in this.verifications) {
                if (this.verifications.hasOwnProperty(domain)) {
                    if (!this.verifications[domain]?.verified) {
                        return false;
                    }
                }
            }

            return true;
        },
        verificationModalClose: function () {
            this.verifications = [];
            this.allow_save_domain = false;
            this.allow_verify_domain = false;
        }
    }
}
</script>

<style scoped>

</style>
