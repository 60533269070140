<template>

    <div class="space-y-6">

        <alert type="neutral">
            This guide explains how to create a referred customer on Partnero once they sign up on your platform. Once the customer is created, all purchases done via Paddle will be associated to the created customer.
        </alert>

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>
        </div>

        <guide-signup-tracking></guide-signup-tracking>

    </div>

</template>

