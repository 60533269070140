<template>
    <div class="rounded border bg-white">
        <div class="flex justify-between p-4 handle">
            <div class="flex items-center space-x-3 truncate">
                <button type="button" class="cursor-move focus:outline-none">
                    <svg class="w-5 h-5 text-gray-500 hover:text-gray-900" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                    </svg>
                </button>

                <svg v-if="data.type === 'text'" class="w-5 h-5 text-gray-900" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M13 6v15h-2V6H5V4h14v2z"/>
                </svg>
                <svg v-else-if="['input', 'portal_signup_last_name', 'portal_signup_link_key', 'np_subscription_name'].includes(data.type)" class="w-5 h-5 text-gray-900" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path
                        d="M5 5v14h14V5H5zM4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5.869 12l-.82 2H6.833L11 7h2l4.167 10H14.95l-.82-2H9.87zm.82-2h2.622L12 9.8 10.689 13z"/>
                </svg>
                <svg v-else-if="data.type === 'email'" class="w-5 h-5 text-gray-900" width="24px" height="24px"
                     viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="mail-line">
                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                            <path
                                d="M5,5 L5,19 L19,19 L19,5 L5,5 Z M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M18.8501039,4.29289322 L20.2643175,5.70710678 L11.7790361,14.1923882 L11.7781746,14.1923882 L3.29289322,5.70710678 L4.70710678,4.29289322 L11.7778932,11.3638932 L18.8501039,4.29289322 Z"
                                id="Shape" fill="currentColor" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </svg>
                <svg v-else-if="data.type === 'password'" class="w-5 h-5 text-gray-900" width="24px" height="24px"
                     viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="pass">
                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                            <path
                                d="M5,5 L5,19 L19,19 L19,5 L5,5 Z M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M11,11 L13,11 L13,13 L11,13 L11,11 Z M15,11 L17,11 L17,13 L15,13 L15,11 Z M7,11 L9,11 L9,13 L7,13 L7,11 Z"
                                id="Shape" fill="currentColor" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </svg>
                <svg v-else-if="data.type === 'textarea'" class="w-5 h-5 text-gray-900" fill="currentColor" width="24px"
                     height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="textarea">
                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                            <path
                                d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M5,5 L5,19 L19,19 L19,5 L5,5 Z M8,7 L11,7 L11,9 L8,9 L8,7 Z M8,11 L16,11 L16,13 L8,13 L8,11 Z M8,15 L16,15 L16,17 L8,17 L8,15 Z"
                                id="Shape" fill="#000000" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </svg>
                <svg v-else-if="data.type === 'select'" class="w-5 h-5 text-gray-900" width="24px" height="24px"
                     viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="select">
                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                            <path
                                d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M5,5 L5,19 L19,19 L19,5 L5,5 Z M9.174,9.343 L7.76,10.7575 L12.003,15 L16.2455,10.7575 L14.831,9.343 L12.003,12.171 L9.174,9.343 Z"
                                id="Shape" fill="currentColor" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </svg>
                <svg v-else-if="data.type === 'checkbox'" class="w-5 h-5 text-gray-900" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path
                        d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h14V5H5zm6.003 11L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"/>
                </svg>
                <svg v-else-if="data.type === 'radio'" class="w-5 h-5 text-gray-900" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"/>
                </svg>
                <svg v-else-if="data.type === 'countries'" class="w-5 h-5 text-gray-900" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"/>
                </svg>

                <span class="text-sm text-gray-900 truncate max-w-96 cursor-pointer" @click.native="data.edit=!data.edit">
                    {{ data.title }}
                    <span v-if="!data.title" class="text-gray-500">
                        <template v-if="data.type === 'text'">Text</template>
                        <template v-else-if="data.type === 'input'">Input</template>
                        <template v-else-if="data.type === 'email'">Email</template>
                        <template v-else-if="data.type === 'password'">Password</template>
                        <template v-else-if="data.type === 'textarea'">Textarea</template>
                        <template v-else-if="data.type === 'select'">Select</template>
                        <template v-else-if="data.type === 'countries'">World countries</template>
                        <template v-else-if="data.type === 'checkbox'">Checkbox</template>
                        <template v-else-if="data.type === 'radio'">Radio</template>
                        <template v-else-if="data.type === 'portal_signup_last_name'">Last name</template>
                        <template v-else-if="data.type === 'portal_signup_link_key'">Personalized token</template>
                        <template v-else-if="data.type === 'np_subscription_name'">Name</template>
                    </span>
                </span>
            </div>
            <div class="flex pl-6 space-x-4">
                <button type="button" class="focus:outline-none" @click.native="$emit('remove', data)"
                        v-if="!data.protected">
                    <svg class="w-5 h-5 text-gray-500 hover:text-red-600" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"/>
                    </svg>
                </button>
                <button type="button" class="focus:outline-none" @click.native="data.edit=!data.edit">
                    <svg class="w-5 h-5 text-gray-500 hover:text-gray-900"
                         v-bind:class="{ 'text-teal-600 hover:text-teal-700': data.edit }" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                    </svg>
                </button>
            </div>
        </div>

        <div class="py-6 px-6 bg-gray-50 bg-opacity-75 border-t space-y-4" v-if="data.edit">

            <div v-if="data.type !== 'text'">
                <label
                    class="flex justify-between items-center mb-1.5 text-sm">
                    Label
                    <span class="text-xs text-gray-500" v-if="data.type !== 'text'">Required?</span>
                </label>
                <div class="flex relative rounded">
                    <input
                        type="text"
                        name="option1"
                        v-model="data.title"
                        v-bind:class="{ 'pr-10': data.type !== 'text' }"
                        class="block w-full rounded border-gray-300 transition-shadow sm:text-sm focus:border-gray-400 focus:ring-0"/>

                    <button type="button"
                            class="-mt-px absolute right-2 top-3 inline-flex flex-shrink-0 w-7 h-4 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
                            role="switch"
                            aria-checked="false"
                            @click="data.required=!data.required"
                            :class="{ 'bg-teal-600': data.required, 'bg-gray-200': !data.required,}"
                            v-if="data.type !== 'text'">
                        <span class="sr-only">Use setting</span>
                        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                        <span aria-hidden="true"
                              class="inline-block w-3 h-3 bg-white rounded-full ring-0 shadow transition duration-200 ease-in-out transform pointer-events-none"
                              :class="{ 'translate-x-3': data.required, 'translate-x-0': !data.required, }"></span>
                    </button>
                </div>
            </div>

            <div class="grid gap-y-2" v-if="data.type === 'text'">
                <div
                    class="tiptap-menu"
                    v-if="editor"
                >
                    <button type="button" @click="editor.chain().focus().toggleBold().run()"
                            :class="{ 'is-active': editor.isActive('bold') }">
                        <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path
                                d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"/>
                        </svg>
                    </button>
                    <button type="button" @click="editor.chain().focus().toggleItalic().run()"
                            :class="{ 'is-active': editor.isActive('italic') }">
                        <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z"/>
                        </svg>
                    </button>
                    <button type="button" @click="editor.chain().focus().toggleStrike().run()"
                            :class="{ 'is-active': editor.isActive('strike') }">
                        <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M13 9h-2V6H5V4h14v2h-6v3zm0 6v5h-2v-5h2zM3 11h18v2H3v-2z"/>
                        </svg>
                    </button>
                    <button type="button" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                        <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0H24V24H0z"/>
                            <path d="M13 20h-2v-7H4v7H2V4h2v7h7V4h2v16zm8-12v12h-2v-9.796l-2 .536V8.67L19.5 8H21z"/>
                        </svg>
                    </button>
                    <button type="button" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                        <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0H24V24H0z"/>
                            <path
                                d="M4 4v7h7V4h2v16h-2v-7H4v7H2V4h2zm14.5 4c2.071 0 3.75 1.679 3.75 3.75 0 .857-.288 1.648-.772 2.28l-.148.18L18.034 18H22v2h-7v-1.556l4.82-5.546c.268-.307.43-.709.43-1.148 0-.966-.784-1.75-1.75-1.75-.918 0-1.671.707-1.744 1.606l-.006.144h-2C14.75 9.679 16.429 8 18.5 8z"/>
                        </svg>
                    </button>
                    <button type="button" @click="editor.chain().focus().toggleBulletList().run()"
                            :class="{ 'is-active': editor.isActive('bulletList') }">
                        <svg class="w-5 h-5 text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path
                                d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"/>
                        </svg>
                    </button>
                </div>

                <div
                    class="block w-full bg-white border px-3.5 py-2 rounded rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-indigo-100 focus:ring-2 focus:border-indigo-300">
                    <editor-content :editor="editor"/>
                </div>
            </div>

            <div class="grid gap-y-1.5" v-if="typesWithOptions.includes(data.type)">
                <label class="block text-sm">
                    Options
                </label>
                <div v-for="(option, index) in data.options">
                    <div class="flex relative rounded">
                        <span
                            class="inline-flex items-center px-4 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">
                            {{ index + 1 }}
                        </span>
                        <input
                            type="text"
                            name="option1"
                            @blur="checkOptions()"
                            @input="checkOptions(option)"
                            v-model="data.options[index]"
                            class="block w-full rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-0 focus:border-gray-400"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    Editor, EditorContent,
    BubbleMenu,
    FloatingMenu,
} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

export default {
    props: ['data'],

    components: {
        EditorContent,
        BubbleMenu,
        FloatingMenu,
    },

    data() {
        return {
            editor: null,
            typesWithOptions: ['select', 'checkbox', 'radio']
        }
    },

    methods: {
        checkOptions(optionFocused) {
            if (!this.typesWithOptions.includes(this.data.type)) {
                return;
            }

            this.data.options ??= [];
            this.data.options = this.data.options.filter((element, index, options) => {
                if (element === optionFocused) {
                    return true;
                }

                return !!options[index];
            })

            if (!this.data.options.length || this.data.options[this.data.options.length - 1]) {
                this.data.options.push('');
            }
        }
    },

    mounted() {
        this.checkOptions();
        this.editor = new Editor({
            content: this.data.text,
            onUpdate: () => {
                this.data.text = this.editor.getHTML()
            },
            extensions: [
                StarterKit,
            ],
        })
    },

    beforeUnmount() {
        this.editor.destroy();
    },
}
</script>

<style lang="scss">
/* Basic editor styles */

.ProseMirror {
    min-height: 70px;

    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    &:focus, &:active {
        outline: none;
    }

    h1 {
        font-weight: bold;
        font-size: 32px;
        line-height: 1.1;
    }

    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.1;
    }
}

.tiptap-menu {
    display: flex;
    //background-color: #111827;
    //padding: 0.4rem .5rem;
    //border-radius: 0.2rem;

    button {
        border: none;
        background: none;
        color: #FFF;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0 0.4rem;
        opacity: 0.6;

        &:focus, &:active {
            outline: none;
        }

        &:hover,
        &.is-active {
            opacity: 1;
        }
    }
}

.floating-menu {
    display: flex;
    background-color: #111827;
    padding: 0.2rem;
    border-radius: 0.5rem;

    button {
        border: none;
        background: none;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0 0.2rem;
        opacity: 0.6;

        &:hover,
        &.is-active {
            opacity: 1;
        }
    }
}
</style>
