<template>
    <div class="bg-white rounded border">
        <div class="flex justify-between items-center p-6">

            <div class="flex items-center gap-4">
                <div
                    class="h-20 w-20 text-xl rounded-full flex items-center justify-center font-medium text-gray-500 bg-gray-200">
                    {{ subscriberInitials }}
                </div>
                <div>
                    <h3 class="font-semibold text-2xl">{{ subscriberFullName }}</h3>
                    <div class="text-gray-900 text-base">{{ subscriberEmail }}</div>

                    <div class="mt-1 text-sm text-gray-500 flex gap-1">
                        <span v-if="subscriberTos">Terms of Use accepted on <span class="font-semibold">{{ subscriberTosDate }}</span></span>
                        <span v-if="subscriberTos && subscriberMarketingConsent">&bull;</span>
                        <span v-if="subscriberMarketingConsent">Marketing consent accepted on <span class="font-semibold"> {{ subscriberMarketingConsentDate}}</span></span>
                    </div>

                    <div class="text-gray-900 text-base mt-1.5">
                        <a :href="subscriberPortalUrl" target="_blank" class="flex gap-0.5 items-center font-medium text-sm text-teal-600 hover:underline">
                            <span>
                                <icon name="link" size="12"/>
                            </span>
                            <span>
                                {{ subscriberPortalUrl }}
                            </span>
                        </a>
                    </div>

                    <newsletter-approve-subscriber
                        v-if="!subscriberApproved"
                        :program-id="programId"
                        :subscriber-id="subscriberId"
                        :subscriber-approved="subscriberApproved"
                    ></newsletter-approve-subscriber>

                </div>
            </div>
            <div>
                <options>
                    <template #toggle>
                        <btn type="secondary" icon-name="more" size="small" class="h-full"></btn>
                    </template>
                    <template #items>
                        <modal title="Subscriber profile" size="medium" name="subscriber_profile_modal">
                            <template #toggle>
                                <options-item title="Details"></options-item>
                            </template>

                            <template #body>

                                <modal-tabs>
                                    <modal-tab @click="setActiveTab('profile')" :active="activeTab('profile')">
                                        Profile
                                    </modal-tab>
                                    <modal-tab v-if="subscriber_cfields.length"
                                               @click="setActiveTab('fields')"
                                               :active="activeTab('fields')"
                                    >
                                        Fields
                                    </modal-tab>
                                </modal-tabs>

                                <div v-show="activeTab('profile')"
                                     class="grid gap-y-6 gap-x-8 xl:col-span-2 lg:grid-cols-2">
                                    <text-field
                                        label="Name"
                                        :disabled="true"
                                        :model-value="subscriberFullName"
                                    />
                                    <text-field
                                        label="Email"
                                        :disabled="true"
                                        :model-value="subscriberEmail"
                                    />
                                    <!-- ./ Other settings -->
                                </div>

                                <div v-show="activeTab('fields')">
                                    <!-- Custom Singup Fields -->

                                    <div class="flex items-center justify-between mb-4">
                                        <div class="text-sm font-semibold">Additional subscriber fields</div>
                                    </div>

                                    <div class="grid gap-y-6 gap-x-8 xl:col-span-2 lg:grid-cols-2 border rounded p-4">
                                        <template v-for="cfield in subscriber_cfields">
                                            <div v-if="cfield.type !== 'text'">
                                                <label class="block text-sm mb-1.5">{{cfield.title}}</label>
                                                <div class="text-sm font-semibold">{{cfield.display_value || '-'}}</div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </modal>
                        <options-divider></options-divider>
                        <modal title="Are you sure?" size="small" name="subscriber_delete_modal">
                            <template #toggle>
                                <options-item title="Delete"></options-item>
                            </template>
                            <template #body>
                                <div class="space-y-6">
                                    <div class="text-sm text-gray-500">
                                        Do you really want to delete this subscriber?<br/>
                                        This process cannot be undone.
                                    </div>
                                    <div class="grid gap-x-6 gap-y-4">
                                        <div class="col-span-2">
                                            <label class="block text-sm mb-1.5">Please type <span
                                                class="font-semibold">DELETE</span>
                                                to confirm.</label>
                                            <text-field v-model="deleteConfirmation"
                                                        :error="deleteConfirmationError"
                                                        v-on:keydown.enter.prevent="deleteSubscriber"/>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #footer>
                                <btn type="primary" :loading="loading" @click="deleteSubscriber">Yes, delete</btn>
                            </template>
                        </modal>

                    </template>
                </options>
            </div>
        </div>

        <div
            class="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 sm:divide-y-0 sm:divide-x"
            :class="{'sm:grid-cols-3': subscriberIsReferred, 'sm:grid-cols-2': !subscriberIsReferred}"
        >
            <div class="px-6 py-5 grid justify-items-center">
                <div class="flex items-center text-sm">
                    <icon class="mr-1.5" name="calendar"></icon>
                    <span class=" text-gray-500">Joined on {{ subscriberCreatedAt }}</span>
                </div>
            </div>
            <div class="px-6 py-5 grid justify-items-center">
                <div class="flex items-center text-sm">
                    <icon class="mr-1.5" name="user-heart"></icon>
                    <span class=" text-gray-500">Referral count: <span
                        class="font-semibold">{{ subscriberReferralCount }}</span></span>
                </div>
            </div>

            <div v-if="subscriberIsReferred" class="px-6 py-5 grid justify-items-center">
                <div class="flex items-center text-sm">
                    <icon class="mr-1.5" name="user-star"></icon>
                    <span class=" text-gray-500">Referred by <a
                        :href="referringSubscriberProfileUrl"
                        class="underline hover:no-underline">{{ referringSubscriberFullName }}</a></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NewsletterSubscriberProfile',
    props: [
        'programId', 'subscriberId',
        'subscriberInitials', 'subscriberFullName', 'subscriberEmail',
        'subscriberTos', 'subscriberTosDate', 'subscriberMarketingConsent',
        'subscriberMarketingConsentDate', 'subscriberPortalUrl', 'subscriberApproved',
        'subscriberIsReferred', 'subscriberCreatedAt', 'subscriberReferralCount',
        'referringSubscriberProfileUrl', 'referringSubscriberFullName', 'custom_fields'
    ],
    data() {
        return {
            loading: false,
            showNotification: false,

            subscriber_cfields: this.custom_fields ?? [],

            deleteConfirmation: '',
            deleteConfirmationError: false,
            deleteError: '',
            activeTabName: 'profile',
        }
    },
    methods: {
        deleteSubscriber() {
            if (this.loading) {
                return;
            }

            if (this.deleteConfirmation.toLowerCase() !== 'delete') {
                this.deleteConfirmationError = true;
                return;
            }

            this.loading = true;
            this.deleteError = false;
            this.deleteConfirmationError = false;

            axios.delete(route('programs.subscribers.subscriber.destroy', {
                program: this.programId,
                subscriber: this.subscriberId
            }), {
                data: {
                    confirmation_string: this.deleteConfirmation
                }

            })
                .then((response) => {
                    if (response.status === 200) {
                        window.location.href = '/programs/' + this.programId + '/subscribers';
                    }
                })
                .catch(() => {
                    this.deleteError = 'An error occurred while deleting the subscriber.';
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setActiveTab(name) {
            this.activeTabName = name
        },
        activeTab(name) {
            return name === this.activeTabName;
        },
    },
}
</script>
