<template>
    <table class="min-w-full divide-y divide-gray-200">
        <thead>
        <tr class="h-1">
            <th :colspan="programType !== 'referral' ? TABLE_COLUMNS_COUNT : 4">
                <progress-bar v-if="loadingFiltersAndPaginationData"/>
            </th>
        </tr>
        <tr>
            <th class="pl-4 w-6">
                <options
                    :isRight="false"
                    name="customer_activity_table_options"
                >
                    <template #toggle>
                        <div class="bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                            <icon name="arrow-down-small"></icon>
                        </div>
                    </template>
                    <template #items>
                        <options-item
                            title="Select all"
                            @click="setExportSelection('selectAll')"
                        />
                        <options-item
                            title="Select visible"
                            @click="setExportSelection('selectVisible')"
                        />
                    </template>
                </options>
            </th>
            <table-header-columns-and-sorting
                :tableHeaders="tableHeaders"
            />
            <th v-if="programType !== 'referral'"></th>
        </tr>
        <tr class="h-1"/>
        </thead>
        <tbody class="divide-y divide-gray-200">
        <initial-loading-skeleton-rows
            v-if="loadingFiltersAndPaginationData && (!customerActivities || customerActivities?.length === 0)"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <no-search-data-row
            v-else-if="!loadingFiltersAndPaginationData && customerActivities?.length === 0"
            :number-of-table-columns="TABLE_COLUMNS_COUNT"
        />
        <template v-else>
            <tr
                v-for="(activity, index) in customerActivities"
                :key="'customer-activity-' + activity.partner_id + '-' + activity.customer_id + '-' + index"
                class="hover:bg-gray-50 cursor-pointer group"
            >
                <td class="pl-4 w-6">
                    <checkbox
                        v-model="exportOptions.selectedIds"
                        :value="activity.id"
                        @click="setExportSelection('selectCustom')"
                    />
                </td>
                <td class="pl-8 pr-4 py-4 whitespace-nowrap font-normal text-sm text-gray-500">
                    {{ activity.identifier }}
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {{ activity.resource_type === 'customer' ? '--' : activity.amount }}
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <tooltip v-if="activity.reward_commission_description" :content="activity.reward_commission_description">
                        {{ activity.resource_type === 'customer' ? '--' : activity.reward }}
                    </tooltip>
                    <div v-else>
                        {{ activity.resource_type === 'customer' ? '--' : activity.reward }}
                    </div>
                </td>
                <td class="px-4 py-4 whitespace-nowraptext-gray-500 text-sm font-medium text-gray-900">
                    <referral-status
                        :activity="activity"
                        :status="getStatusLabel(activity)"
                    />
                </td>
                <td class="pl-4 pr-8 py-4 whitespace-nowraptext-gray-500 text-right text-sm text-gray-500">
                    <time datetime="{{ activity.parsed_date_time }}">
                        {{ activity.parsed_date_time }}
                    </time>
                </td>
                <td class="pr-4 pl-4 text-right" v-if="programType !== 'referral'">
                    <transaction-actions-modal
                        :transaction="activity"
                    />
                </td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>
import InitialLoadingSkeletonRows from "../../../../common/InitialLoadingSkeletonRows";
import NoSearchDataRow from "../../../../common/NoSearchDataRow";
import ReferralStatus from "../../../../common/ReferralStatus";
import TransactionActionsModal from "./TransactionActionsModal.vue";
import ProgressBar from "../../../../../elements/ProgressBar";
import filtersAndPagination from "../../../../../composables/common/filter-and-pagination-data";
import useTableDataExporter from "../../../../../composables/common/select-export-and-delete-data";
import TableHeaderColumnsAndSorting from "../../../filters-and-pagination/TableHeaderColumnsAndSorting.vue";
import Icon from "../../../../../elements/Icon.vue";
import Tooltip from "../../../../../elements/Tooltip.vue";

export default {
    name: "CustomerActivityTable",
    emits: ['hide-options'],
    components: {
        TableHeaderColumnsAndSorting,
        Tooltip, Icon,
        InitialLoadingSkeletonRows,
        ProgressBar,
        TransactionActionsModal,
        NoSearchDataRow,
        ReferralStatus
    },
    props: {
        customerActivities: {
            type: Array,
            default: []
        },
        programType: {
            required: true
        },
        tableHeaders: {
            default: []
        }
    },
    setup() {
        const TABLE_COLUMNS_COUNT = 6
        const {loadingFiltersAndPaginationData} = filtersAndPagination()

        const {exportOptions} = useTableDataExporter()

        return {loadingFiltersAndPaginationData, TABLE_COLUMNS_COUNT, exportOptions}
    },
    methods: {
        setExportSelection(type) {
            this.exportOptions.selectType = type
            if (type === 'selectVisible' || type === 'selectAll') {
                this.exportOptions.selectedIds = []
                this.customerActivities.forEach(customerActivity => {
                    this.exportOptions.selectedIds.push(customerActivity.id)
                })
            }
            this.emitter.emit('hide-options', 'customer_activity_table_options');
        },
        getStatusLabel(activity) {
            if (activity.action === 'signup' && activity.deleted_at) {
                return 'deleted'
            }
            else if (activity.deleted_at) {
                return 'archived'
            }
            else {
                return activity.status
            }

        }
    },
    watch: {
        'exportOptions.selectedIds': {
            deep: true,
            handler(newValue) {
                if (this.exportOptions.selectType !== 'selectAll') {
                    this.exportOptions.selectTypeCustomModels = this.customerActivities
                        .filter(activity => newValue.includes(activity.id))
                        .map(activity => ({
                                id: activity.id,
                                identifier: activity.identifier,
                                action: activity.action
                            })
                        );
                }
            }
        }
    }
}
</script>
