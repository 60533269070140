import axios from 'axios'

export const folder = {
    namespaced: true,
    state: () => ({
        loading: false,
        folders: [],
        selected: null,
        editing: {},
        deleting: {},
    }),
    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },
        setFolders(state, folders) {
            state.folders = folders
        },
        setSelected(state, folderId) {
            state.selected = folderId
        },
        addFolder(state, folder) {
            if (typeof folder.id !== 'undefined' && folder.id !== '') {
                state.folders.push(folder)
            }
        },
        updateFolder(state, folder) {
            state.folders = state.folders.map((f) => (f.id === folder.id ? folder : f))
        },
        removeFolder(state, folder) {
            state.folders = state.folders.filter((f) => f.id !== folder.id)
        },
        setEditing(state, folder) {
            state.editing = folder
        },
        setDeleting(state, folder) {
            state.deleting = folder
        },
    },
    actions: {
        getFolders({ commit }) {
            commit('setLoading', true)
            axios.get('/filemanager/file_folders')
                .then((response) => {
                    commit('setFolders', response.data.data)
                    commit('setLoading', false)
                })
        },
        addFolder({ commit }, folder) {
            commit('addFolder', folder)
        },
        updateFolder({ commit }, folder) {
            commit('updateFolder', folder)
        },
        removeFolder({ commit, state, dispatch }, folder) {
            if (state.selected === folder.id) {
                commit('setSelected', null)
                dispatch('file/getFiles', {}, { root: true })
            }
            commit('removeFolder', folder)
        },
        setSelected({ commit, dispatch }, folderId) {
            commit('setSelected', folderId)
            dispatch('file/getFiles', {}, { root: true })
        },
    },
    getters: {

    },
}
