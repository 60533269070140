<script setup>
import {inject, onMounted, ref} from "vue";
import {usePayoutPaypalAutomationStore} from "../../../../../../stores/app/programs/payouts/automation/paypal/PayoutPaypalAutomationStore";

const props = defineProps({
    programId: {
      type: Number,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  });

  const payoutAutomationStore = usePayoutPaypalAutomationStore();
  const emitter = inject('emitter');

  const init = () => {
    payoutAutomationStore.setProgramId(props.programId);
    payoutAutomationStore.setFrequency(props.settings.frequency);
    payoutAutomationStore.setFrequencyValue(props.settings.frequency !== 'days' ? props.settings.frequency_value ?? null : null);
    payoutAutomationStore.setFrequencyValueDays(props.settings.frequency === 'days' ? props.settings.frequency_value ?? [] : []);
    payoutAutomationStore.setMaximumAmount(props.settings.maximum_amount);
    payoutAutomationStore.setMinimumAmount(props.settings.minimum_amount);
    payoutAutomationStore.setPartneroTosAccepted(props.settings.partnero_tos_accepted ?? false);
    payoutAutomationStore.setPayPalFundsTosAccepted(props.settings.paypal_funds_tos_accepted ?? false);
    payoutAutomationStore.setReattemptUnsuccessfulPayouts(props.settings.reattempt_unsuccessful_payouts ?? false);
    payoutAutomationStore.setProgramAutomatedPayoutsEnabled(props.settings.program_automated_payouts_enabled ?? false);
  }

  onMounted(() => {
      init()
  });

  const dayList = [
    {value: 'sunday', label: 'Sunday'},
    {value: 'monday', label: 'Monday'},
    {value: 'tuesday', label: 'Tuesday'},
    {value: 'wednesday', label: 'Wednesday'},
    {value: 'thursday', label: 'Thursday'},
    {value: 'friday', label: 'Friday'},
    {value: 'saturday', label: 'Saturday'},
  ];

    const saveSettings = () => {
            if (payoutAutomationStore.getFrontendValidationMessages.length > 0) {
                payoutAutomationStore.setShowingValidationMessages(true);
                return;
            }
            payoutAutomationStore.setSavingSettingsStatus(true);
            const data = {
                program_automated_payouts_frequency: payoutAutomationStore.getFrequency,
                program_automated_payouts_frequency_value: payoutAutomationStore.getFrequencyValue,
                program_automated_payouts_maximum_amount: payoutAutomationStore.getMaximumAmount,
                program_automated_payouts_minimum_amount: payoutAutomationStore.getMinimumAmount,
                program_automated_payouts_partnero_tos_accepted: payoutAutomationStore.getPartneroTosAccepted,
                program_automated_payouts_paypal_funds_tos_accepted: payoutAutomationStore.getPayPalFundsTosAccepted,
                program_automated_payouts_reattempt_unsuccessful_payouts: payoutAutomationStore.getReattemptUnsuccessfulPayouts,
                program_automated_payouts_enabled: payoutAutomationStore.getProgramAutomatedPayoutsEnabled,
            }
            if (payoutAutomationStore.getFrequency === 'days') {
                data.program_automated_payouts_frequency_value = payoutAutomationStore.getFrequencyValueDays.join(',');
            } else {
                data.program_automated_payouts_frequency_value = payoutAutomationStore.getFrequencyValue
            }

            axios.post(route('app_int.integrations.paypal.mass-payout.automation_settings', { program: payoutAutomationStore.getProgramId }), data)
                .then((response) => {
                    const status = parseInt(response.data.status || -1);
                    if (status === 1) {
                        payoutAutomationStore.updateNotification(
                            true,
                            'success',
                            'Settings saved',
                            'Automation settings saved successfully.'
                        );
                        emitter.emit('modal-off', 'paypal_automation_settings_modal')
                    }
                })
                .catch((error) => {
                    payoutAutomationStore.updateNotification(
                        true,
                        'warning',
                        'Action failed',
                        error.response.data.message
                    )
                })
                .finally(() => {
                    payoutAutomationStore.setSavingSettingsStatus(false);
                    payoutAutomationStore.setShowingValidationMessages(false);
                })
    }
</script>

<template>
  <div class="bg-white border rounded p-6 flex justify-between items-center gap-4">
    <div class="text-sm space-y-2">
      <div>
        <span class="font-semibold">{{ 'Status: ' }}</span>
        <badge :title="payoutAutomationStore.getProgramAutomatedPayoutsEnabled ? 'Live' : 'Inactive'"
               :type="payoutAutomationStore.getProgramAutomatedPayoutsEnabled ? 'success' : 'neutral'"
        ></badge>
      </div>
      <div>
        <span class="font-semibold">{{ 'Schedule: ' }}</span>
          <span v-if="payoutAutomationStore.getFrequency === 'days' && payoutAutomationStore.getFrequencyValueDays?.length > 0" class="text-gray-500">
              {{ 'Every ' + payoutAutomationStore.getFrequencyValueDays.map(day => day.charAt(0).toUpperCase() + day.slice(1)).join(', ') }}
          </span>
          <span v-else-if="payoutAutomationStore.getFrequency === 'every_week' && payoutAutomationStore.getFrequencyValue" class="text-gray-500">
              {{ 'Every week on ' + payoutAutomationStore.getFrequencyValue.charAt(0).toUpperCase() + payoutAutomationStore.getFrequencyValue.slice(1) }}
          </span>
          <span v-else-if="payoutAutomationStore.getFrequency === 'every_month' && payoutAutomationStore.getFrequencyValue" class="text-gray-500">
            {{ 'Every month on ' + payoutAutomationStore.getFrequencyValue.charAt(0).toUpperCase() + payoutAutomationStore.getFrequencyValue.slice(1) }}
          </span>
          <span v-else class="text-gray-500">--</span>
      </div>
      <div>
        <span class="font-semibold">Min amount:</span> <span class="text-gray-500">{{ payoutAutomationStore.getMinimumAmount ?? 'n/a' }}</span>
        <span>∙ <span class="font-semibold">Max amount:</span> <span class="text-gray-500">{{ payoutAutomationStore.getMaximumAmount ?? 'n/a' }}</span></span>
      </div>
    </div>

    <modal title="Payout Automation" size="large" name="paypal_automation_settings_modal">
      <template #toggle>
        <btn>Manage</btn>
      </template>
      <template #body>

        <div class="space-y-8">
          <div class="space-y-4">

              <alert type="error" v-if="payoutAutomationStore.showValidationMessages && payoutAutomationStore.getFrontendValidationMessages.length > 0">
                  <ul>
                      <li
                          v-for="(error) in payoutAutomationStore.getFrontendValidationMessages"
                      >
                          {{ error }}
                      </li>
                  </ul>
              </alert>

            <div class="grid md:grid-cols-2 col-span-2"
                 :class="{'gap-8 gap-y-6': payoutAutomationStore.getFrequency !== 'days'}"
            >
              <div>
                <field-label title="Payout time"></field-label>
                <select v-model="payoutAutomationStore.frequency"
                        class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                        @change="payoutAutomationStore.resetFrequencyValues()"
                >
                  <option :value="null">Select frequency</option>
                  <option value="days">Every day</option>
                  <option value="every_week">Every week</option>
                  <option value="every_month">Every month</option>
                </select>
              </div>
              <div v-if="payoutAutomationStore.getFrequency === 'every_week' || payoutAutomationStore.getFrequency === 'every_month'">
                <div v-if="payoutAutomationStore.getFrequency === 'every_week'">
                  <field-label title="Day of the week"></field-label>
                  <select class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                          v-model="payoutAutomationStore.frequencyValue"
                  >
                    <option :value="null">Select a day</option>
                    <option v-for="day in dayList" :key="day.value" :value="day.value">{{ day.label }}</option>
                  </select>
                </div>
                <div v-else>
                  <field-label title="Day of the month"></field-label>
                  <select class="block w-full rounded border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                          v-model="payoutAutomationStore.frequencyValue"
                  >
                    <option :value="null">Select a day</option>
                    <option value="1">1st</option>
                    <option value="2">2nd</option>
                    <option value="3">3rd</option>
                    <option value="4">4th</option>
                    <option value="5">5th</option>
                    <option value="6">6th</option>
                    <option value="7">7th</option>
                    <option value="8">8th</option>
                    <option value="9">9th</option>
                    <option value="10">10th</option>
                    <option value="11">11th</option>
                    <option value="12">12th</option>
                    <option value="13">13th</option>
                    <option value="14">14th</option>
                    <option value="15">15th</option>
                    <option value="16">16th</option>
                    <option value="17">17th</option>
                    <option value="18">18th</option>
                    <option value="19">19th</option>
                    <option value="20">20th</option>
                    <option value="21">21st</option>
                    <option value="22">22nd</option>
                    <option value="23">23rd</option>
                    <option value="24">24th</option>
                    <option value="25">25th</option>
                    <option value="26">26th</option>
                    <option value="27">27th</option>
                    <option value="28">28th</option>
                    <option value="last_day">last day of the month</option>
                    <option value="29">29th (not available in all months)</option>
                    <option value="30">30th (not available in all months)</option>
                    <option value="31">31st (not available in all months)</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="payoutAutomationStore.getFrequency === 'days'" class="grid md:grid-cols-5 col-span-2 gap-2">
              <div v-for="day in dayList" :key="day.value"
                   class="flex justify-start items-center"
              >
                <toggle
                    :model-value="payoutAutomationStore.getFrequencyValueDays.includes(day.value)"
                    @update:modelValue="payoutAutomationStore.updateFrequencyDays(day.value)"
                ></toggle>
                  <div class="text-sm font-medium text-gray-900">
                    {{ day.label }}
                  </div>
                </div>
            </div>
          </div>

          <div class="grid md:grid-cols-2 col-span-2 gap-8 gap-y-6">
            <text-field v-model="payoutAutomationStore.minimumAmount" label="Minimum amount (optional)" type="number">
              <template #note>
                The scheduled payout will not initiate if the minimum total commission amount is not reached.
              </template>
            </text-field>
            <text-field v-model="payoutAutomationStore.maximumAmount" label="Maximum amount (optional)" type="number">
              <template #note>
                Scheduled payouts will be stopped if the total commission amount from all affiliates surpasses the maximum limit.
              </template>
            </text-field>
          </div>

          <div class="flex justify-between items-center">
            <toggle v-model="payoutAutomationStore.reattemptUnsuccessfulPayouts"></toggle>
            <span class="flex flex-col flex-grow" id="availability-label">
                                <span class="text-sm font-medium text-gray-900">
                                    Reattempt unsuccessful payouts in the upcoming cycle
                                </span>
                                <span class="text-sm text-gray-500">
                                   If enabled, any failed payouts will automatically be retried in the next payout cycle.
                                </span>
                            </span>
          </div>

          <div class="space-y-2">
            <div class="flex justify-start items-center space-x-2">
              <input type="checkbox"
                     class="h-4 w-4 cursor-pointer text-teal-600 border-gray-300 rounded-sm focus:ring-teal-100"
                     id="terms-auto-payout"
                     v-model="payoutAutomationStore.partneroTosAccepted"
                     :disabled="payoutAutomationStore.getProgramAutomatedPayoutsEnabled"
              >
              <label class="text-sm text-gray-500 cursor-pointer" for="terms-auto-payout">
                I understand that Partnero is not responsible for unverified payouts approved and paid automatically.
              </label>
            </div>
            <div class="flex justify-start items-center space-x-2">
              <input type="checkbox"
                     class="h-4 w-4 cursor-pointer text-teal-600 border-gray-300 rounded-sm focus:ring-teal-100"
                     id="terms-paypal-funds"
                     v-model="payoutAutomationStore.payPalFundsTosAccepted"
                     :disabled="payoutAutomationStore.getProgramAutomatedPayoutsEnabled"
              >
              <label class="text-sm text-gray-500 cursor-pointer" for="terms-paypal-funds">
                I am aware that it is essential to ensure my PayPal account has sufficient funds before the scheduled date.
              </label>
            </div>
          </div>
        </div>


      </template>
      <template #footer>
        <btn type="primary"
             :loading="payoutAutomationStore.getSavingSettings"
             :disabled="payoutAutomationStore.getSavingSettings"
             @click="saveSettings"
        >
          Save
        </btn>
      </template>
    </modal>
      <notification
              :show="payoutAutomationStore.getNotification.showNotification"
              :title="payoutAutomationStore.getNotification.notificationTitle"
              :type="payoutAutomationStore.getNotification.notificationType"
              whitespace="whitespace-normal"
              class="mb-20"
              @notificationClosed="payoutAutomationStore.getNotification.showNotification = false"
      >
          <template #message>
              {{ payoutAutomationStore.getNotification.notificationMessage }}
          </template>
      </notification>
  </div>
</template>
