<template>
    <div class="">
        <div class="flex justify-between items-center gap-4">
            <div class="text-sm">
                <span v-if="exportData.status === 'starting'">
                    File export is about to start.
                </span>
                <span class="italic" v-if="exportData.status === 'exporting' || exportData.status === 'started'">
                    Exporting {{ formatNumber(exportData.lines) }} / {{ formatNumber(exportData.total) }}
                </span>
                <span class="font-semibold" v-if="exportData.status === 'done'">
                    The file is ready to download.
                </span>
            </div>
            <div class="flex gap-3">
                <div
                    v-if="exportingInProgress"
                    class="flex gap-3"
                >
                    <btn
                        size="pill"
                        icon-name="spinner"
                        :disabled="true"
                        type="secondary"
                    >
                        Download
                    </btn>
                    <btn
                        type="light"
                        iconName="close"
                        size="pill"
                        @click="cancelExport"
                    >
                        Cancel
                    </btn>
                </div>
                <btn
                    v-else-if="!exportingInProgress && exportData.status !== 'done'"
                    type="light"
                    iconName="download"
                    size="pill"
                    @click="exportCSV"
                >
                    Export
                </btn>
                <div
                    v-else-if="!exportingInProgress && exportData.status === 'done'"
                    class="flex gap-3"
                >
                    <btn
                        type="dark-green"
                        icon-name="download"
                        size="pill"
                        :href="exportData.public_url"
                    >
                        Download
                    </btn>
                    <btn
                        type="light"
                        iconName="close"
                        size="pill"
                        @click="resetExport"
                    >
                        Clear export
                    </btn>
                </div>
            </div>
        </div>
        <notification
            :show="notificationData.showNotification"
            :title="notificationData.notificationTitle"
            :type="notificationData.notificationType"
            class="mb-20"
            @notificationClosed="notificationData.showNotification = false"
        >
            <template #message>
                {{ notificationData.notificationMessage }}
            </template>
        </notification>
    </div>
</template>

<script setup>
import Notification from "../../../elements/Notification";
import {formatNumber} from "../../../helpers/common";
import {onMounted, ref} from "vue";

const props = defineProps({
    uniqueKey: {
        type: [Number, String],
        required: true,
    },
    exportUrl: {
        type: String,
        required: true,
    },
});

const exportOptions = ref({
    selectType: 'selectAll',
    selectedIds: [],
    selectTypeCustomModels: [],
    filters: [],
    search: '',
});
const exportSetup = ref({
    name: '',
    exportModelNameSingular: '',
    exportModelNamePlural: '',
    exportUrl: ''
});
const exportDataOriginal = ref({});
const exportData = ref({
    status: null,
    lines: 0,
    total: 0,
    file_name: null,
    public_link: null,
});
const fileReadyForDownloadExist = ref(false);
const exportingInProgress = ref(false);
const exportContext = ref({
    eId: 0,
    es: null
});

const resetExportData = () => {
    exportData.value.status = null;
    exportData.value.lines = 0;
    exportData.value.total = 0;
    exportData.value.file_name = null;
    exportData.value.public_link = null;
    removeExportFilePublicUrlFromLocalStorage();
    fileReadyForDownloadExist.value = false;
}

const parseExportData = (dataToParse) => {
    exportDataOriginal.value = dataToParse;

    exportData.value.lines = dataToParse.lines;
    exportData.value.total = dataToParse.total;
    exportData.value.status = dataToParse.status;
    exportData.value.public_url = dataToParse.public_url;
    exportData.value.file_name = dataToParse.file_name;

    if (dataToParse.status === 'done') {
        setExportFilePublicUrlToLocalStorage(dataToParse.public_url);
        fileReadyForDownloadExist.value = true;
        removeExportIdFromLocalStorage();
    }
}

const checkForPreviousExport = () => {
    const exportFileLink = getExportFilePublicUrlFromLocalStorage();
    const exportId = getExportIdFromLocalStorage();

    if (exportFileLink) {
        fileReadyForDownloadExist.value = true;
        handleExistingExportFile(exportFileLink);
    } else if (exportId) {
        setupExportStream(exportId, true);
    }
}

const handleExistingExportFile = (exportFilePublicLink) => {
    if (exportFilePublicLink) {
        exportData.value.public_url = exportFilePublicLink;
        exportData.value.status = 'done';
    }
}

const setupExportStream = (exportId, exportAlreadyRunning = false) => {
    const sseFeed = '/export/' + exportId + '/sse';

    exportContext.value.es = new EventSource(sseFeed);

    exportContext.value.es.addEventListener('message', (event) => {
        if (exportAlreadyRunning) {
            exportingInProgress.value = true;
            exportContext.value.eId = exportId;
        }
        if (event.data === 'END-OF-STREAM') {
            exportingInProgress.value = false;
            notificationData.value.showNotification = true;
            notificationData.value.notificationTitle = 'Done';
            notificationData.value.notificationType = 'success';
            notificationData.value.notificationMessage = 'Export prepared successfully';
            exportContext.value.es.close();
            removeExportIdFromLocalStorage();
        } else {
            const data = JSON.parse(event.data);
            parseExportData(data);
        }
    }, false)

    exportContext.value.es.addEventListener('error', (event) => {
        if (event.readyState === EventSource.CLOSED) {
            notificationData.value.showNotification = true;
            notificationData.value.notificationTitle = 'Error';
            notificationData.value.notificationType = 'warning';
            notificationData.value.notificationMessage = 'Can\'t export CSV';
        }
    }, false)
}

const exportCSV = async () => {

    exportingInProgress.value = true;

    axios.post(exportSetup.value.exportUrl, exportOptions.value)
        .then(response => {
            const {id} = response.data.data
            exportContext.value.eId = id;
            setExportIdToLocalStorage(id);
            setupExportStream(id);
        })
        .catch(error => {
            console.log('Exporting error', error);
            exportingInProgress.value = false;
        })
        .finally(() => {
        })
}

const resetExport = async () => {
    resetExportData();
    resetNotification();
}

const cancelExport = () => {
    if (exportContext.value.es) {
        exportContext.value.es.close();
    }

    exportContext.value.eId = exportContext.value.eId || getExportIdFromLocalStorage();

    if (exportContext.value.eId) {
        axios.post(`/export/${exportContext.value.eId}/cancel`)
            .then(response => {
                exportContext.value.eId = 0;
                resetExportData();
                resetNotification();
                exportingInProgress.value = false;
                removeExportIdFromLocalStorage();
            })
            .catch(error => {
            })
            .finally(() => {
            })
    }
}

onMounted(() => {
    exportSetup.value.name = 'payout-details-' + props.uniqueKey;
    exportSetup.value.exportModelNameSingular = 'payout-details-' + props.uniqueKey;
    exportSetup.value.exportModelNamePlural = 'payout-details-' + props.uniqueKey;
    exportSetup.value.exportUrl = props.exportUrl;
    checkForPreviousExport();
})

// Localstorage start
const setExportIdToLocalStorage = (id) => {
    localStorage.setItem(exportSetup.value.exportModelNamePlural + '_export_id', id);
}

const getExportIdFromLocalStorage = () => {
    return localStorage.getItem(exportSetup.value.exportModelNamePlural + '_export_id');
}

const removeExportIdFromLocalStorage = () => {
    localStorage.removeItem(exportSetup.value.exportModelNamePlural + '_export_id');
}

const setExportFilePublicUrlToLocalStorage = (link) => {
    localStorage.setItem(exportSetup.value.exportModelNamePlural + '_public_link', link);
}

const getExportFilePublicUrlFromLocalStorage = () => {
    return localStorage.getItem(exportSetup.value.exportModelNamePlural + '_public_link');
}

const removeExportFilePublicUrlFromLocalStorage = () => {
    localStorage.removeItem(exportSetup.value.exportModelNamePlural + '_public_link');
}
// Localstorage ends

// Notification start
const notificationData = ref({
    showNotification: false,
    notificationType: '',
    notificationTitle: '',
    notificationMessage: '',
});

const showNotification = (show, title, type, message) => {
    notificationData.value.showNotification = show;
    notificationData.value.notificationTitle = title;
    notificationData.value.notificationType = type;
    notificationData.value.notificationMessage = message;
}

const resetNotification = () => {
    notificationData.value.showNotification = false;
    notificationData.value.notificationType = '';
    notificationData.value.notificationTitle = '';
    notificationData.value.notificationMessage = '';
}
// Notification ends
</script>

