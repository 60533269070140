<template>
    <div>
        <filters-and-pagination
            :pagination-data="filtersAndPaginationData"
            :filterable-fields="filterFields"
            :data-exists="initialDataState"
            :loading-data="loadingFiltersAndPaginationData"
            model-name="referral"
        >
            <customer-data-table v-if="dataType === 'referrals'"
                                 :table-headers="tableHeaders"
                                 :recent-activities="filtersAndPaginationData.data"
            />
            <referral-data-table v-if="dataType === 'referral-activities'"
                                 :recent-activities="filtersAndPaginationData.data"
                                 :table-headers="tableHeaders"
            />
        </filters-and-pagination>
    </div>
</template>

<script>
import FiltersAndPagination from "../../filters-and-pagination/FiltersAndPagination";
import ReferralDataTable from "./components/ReferralDataTable";
import useRecentActivities from "../../../../composables/common/filter-and-pagination-data";
import {onMounted, ref, toRef, watch} from "vue";
import useFiltersAndPagination from "../../../../state/common/filters-and-pagination";
import useTableDataExporter from "../../../../composables/common/select-export-and-delete-data";
import CustomerDataTable from "./components/CustomerDataTable.vue";

export default {
    name: "ReferralIndex",

    components: {
        FiltersAndPagination,
        CustomerDataTable,
        ReferralDataTable,
    },

    props: {
        programActivitiesUrl: {
            default: ''
        },
        programActivitiesExportUrl: {
            default: ''
        },
        filterFields: {
            default: {}
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        },
        dataType: {
            type: String,
            default: 'referral-activities'
        },
        tableHeaders: {
            default: []
        }
    },

    setup(props) {
        const dataType = toRef(props, 'dataType')
        const programActivitiesUrl = toRef(props, 'programActivitiesUrl')
        const activitiesExportUrl = toRef(props, 'programActivitiesExportUrl')
        const filterFields = toRef(props, 'filterFields')
        const {
            loadingFiltersAndPaginationData,
            filtersAndPaginationData,
            getFiltersAndPaginationData
        } = useRecentActivities()
        const {setDataBaseUrl, setFetchDataFunction} = useFiltersAndPagination()
        const {exportSetup, checkForPreviousExport} = useTableDataExporter()

        const initialDataState = ref(!!props.dataExists)

        onMounted(() => {
            setDataBaseUrl(programActivitiesUrl.value);
            setFetchDataFunction(getFiltersAndPaginationData);

            exportSetup.value.exportModelNameSingular = 'recent activity';
            exportSetup.value.exportModelNamePlural = 'recent activities';
            exportSetup.value.exportUrl = activitiesExportUrl.value;
            checkForPreviousExport();
        })

        watch(filtersAndPaginationData, (newValue) => {
            // the first time the data is loaded, we want to set the initialDataState to true so we can show the table
            // use filters properly if returned data is empty, etc.
            if (newValue?.data?.length) {
                initialDataState.value = true
            }
        })

        return {
            filtersAndPaginationData,
            loadingFiltersAndPaginationData,
            filterFields,
            initialDataState
        }
    }
}
</script>
