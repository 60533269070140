<script setup>
import Icon from "../../../../../../elements/Icon.vue";
import {formatNumber} from "../../../../../../helpers/common";
import {useAnalyticsRealTimeStore} from "../../../../../../stores/app/programs/analytics/AnalyitcsRealTimeStore";

const analyticsRealTimeStore = useAnalyticsRealTimeStore();

const props = defineProps({
    paginationData: {
        type: Object,
        required: true
    },
    dataToFetch: {
        type: String,
        required: true
    }
});

const fetchData = (url) => {
    switch (props.dataToFetch) {
        case 'pages':
            analyticsRealTimeStore.fetchRealTimePagesData(url);
            break;
        case 'visitorsBySource':
            analyticsRealTimeStore.fetchRealTimeVisitorsBySourceData(url);
            break;
        case 'visitorsByReferral':
            analyticsRealTimeStore.fetchRealTimeVisitorsByReferralData(url);
            break;
    }
}
</script>

<template>
    <nav class="px-4 py-3 flex items-center justify-between sm:px-6" aria-label="Pagination">
        <div class="hidden sm:block">
            <p class="text-sm text-gray-400">
                Showing
                {{ ' ' }}
                <span class="font-medium">{{ formatNumber(paginationData.from) }}</span>
                {{ ' ' }}
                to
                {{ ' ' }}
                <span class="font-medium">{{ formatNumber(paginationData.to) }}</span>
                {{ ' ' }}
                of
                {{ ' ' }}
                <span class="font-medium">{{ formatNumber(paginationData.total) }}</span>
                {{ ' ' }}
                results
            </p>
        </div>
        <div class="flex-1 flex justify-between sm:justify-end">
            <div class="rounded mr-1 border border-gray-200 p-2"
                 :class="{ 'cursor-pointer': paginationData.prev_page_url }"
                @click="fetchData(paginationData.prev_page_url)"
            >
                <icon name="arrow-left-s"
                      :class="{ 'text-gray-300': paginationData.prev_page_url === null }"
                />
            </div>
            <div class="rounded ml-1 border border-gray-200 p-2"
                 :class="{ 'cursor-pointer': paginationData.next_page_url }"
                @click="fetchData(paginationData.next_page_url)"
            >
                <icon name="arrow-right-s"
                        :class="{ 'text-gray-300': paginationData.next_page_url === null }"
                />
            </div>
        </div>
    </nav>
</template>
