<template>
    <div>
        <label for="commission_type" class="block mb-1.5 text-sm">Commission type</label>
        <fieldset>
            <div class="-space-y-px bg-white rounded-md">
                <radio-group
                    name="commission_type"
                    id="commission_type"
                    :model-value="settings.commission_type"
                    :options="[
                            { value: 'percent', label: 'Percentage', description: 'Reward your partners with a percentage of the sale amount.'},
                            { value: 'static', label: 'Fixed amount', description: 'Reward your partners with a fixed commission.'},
                        ]"
                    @update:model-value="settings.commission_type=$event"
                ></radio-group>
            </div>
        </fieldset>
    </div>
    <div>
        <div class="grid gap-y-6 gap-8 md:grid-cols-2">
            <div v-if="!compactMode">
                <label for="commission" class="block mb-1.5 text-sm">Commission and currency</label>
                <div class="flex relative rounded-md">
                    <span
                        v-if="typeIsPercent"
                        class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">
                            %
                    </span>
                    <input
                        type="text"
                        placeholder="e.g. 10"
                        v-model="settings.commission"
                        class="block w-full rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-0 focus:border-gray-400"
                        :class="{'rounded-l': !typeIsPercent}"
                    />
                    <div class="flex absolute inset-y-0 right-0 items-center">
                        <label for="currency" class="sr-only">Currency</label>
                        <select
                            v-model="settings.currency"
                            class="py-0 pr-7 pl-2 h-full text-gray-500 bg-transparent rounded border-transparent focus:ring-0 focus:border-gray-400 sm:text-sm">
                            <option v-for="currency in props.currencies" :value="currency.code_upper">
                                {{ currency.code_upper }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="flex items-end gap-4">
                <btn class="w-full md:w-auto" v-if="!compactMode" @click="showDynamicRewardModal">Advanced commission</btn>
                <modal title="Advanced commission" size="large" :show="false" ref="dynamic_reward_modal" cancel_btn_title="Save">
                    <template #body>

                        <p class="text-sm text-gray-500 mb-3">
                            Create an advanced commission model that provides a range of rewarding incentives for your
                            affiliates.
                        </p>

                        <div class="grid grid-cols-4 gap-4 mb-8 mt-8">
                            <div>
                                <div class="flex relative rounded-md mb-1">
                                    <span
                                        v-if="typeIsPercent"
                                        class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">
                                        %
                                    </span>
                                    <input
                                        type="text"
                                        placeholder="e.g. 10"
                                        v-model="settings.commission"
                                        class="block w-full rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-0 focus:border-gray-400"
                                        :class="{'rounded-l': !typeIsPercent}"
                                    />
                                    <div class="flex absolute inset-y-0 right-0 items-center">
                                        <label for="currency" class="sr-only">Currency</label>
                                        <select
                                            v-model="settings.currency"
                                            class="py-0 pr-7 pl-2 h-full text-gray-500 bg-transparent rounded border-transparent focus:ring-0 focus:border-gray-400 sm:text-sm"
                                        >
                                            <option v-for="currency in props.currencies" :value="currency.code_upper">
                                                {{ currency.code_upper }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="text-sm text-gray-500 flex items-center">
                                is for everyone.
                            </div>
                        </div>

                        <tabs>
                            <tab @click="setActiveCommissionTab(DYNAMIC)" :active="activeCommissionTab === DYNAMIC">
                                Dynamic commissions
                            </tab>
                            <tab @click="setActiveCommissionTab(PRODUCT)" :active="activeCommissionTab === PRODUCT">
                                Product commissions
                            </tab>
                            <tab @click="setActiveCommissionTab(GOAL)" :active="activeCommissionTab === GOAL">
                                Goals
                            </tab>
                        </tabs>

                        <div class="border border-t-0 rounded-b p-6">

                            <program-commissions-settings-affiliate-dynamic
                                v-show="activeCommissionTab === DYNAMIC"
                                v-model:dynamic-commissions="dynamicCommissions"
                                v-model:dynamic-period-commissions="dynamicPeriodCommissions"
                                :type-is-percent="typeIsPercent"
                                :default-commission="settings.commission"
                                :dynamic-commission-condition-elements="props.dynamicCommissionConditionElements"
                                :dynamic-commission-condition-types="props.dynamicCommissionConditionTypes"
                                :dynamic-period-commission-condition-types="props.dynamicPeriodCommissionConditionTypes"
                            ></program-commissions-settings-affiliate-dynamic>

                            <program-commissions-settings-affiliate-product
                                v-show="activeCommissionTab === PRODUCT"
                                v-model:product-commissions="productCommissions"
                                :type-is-percent="typeIsPercent"
                                :product-commission-condition-elements="props.productCommissionConditionElements"
                                :show-product-import="props.showProductImport"
                                :loading="loading.products"
                                :stripe-products="stripeProducts"
                                :shopify-products="shopifyProducts"
                            >
                            </program-commissions-settings-affiliate-product>

                            <program-commissions-settings-affiliate-goal
                                v-show="activeCommissionTab === GOAL"
                                v-model:goal-commissions="goalCommissions"
                                :commission-currency="settings.currency"
                                :goal-commission-condition-elements="goalCommissionConditionElements"
                                :goal-commission-condition-types="goalCommissionConditionTypes"
                            ></program-commissions-settings-affiliate-goal>
                        </div>

                        <div class="space-y-4 mt-5" v-show="activeCommissionTab === PRODUCT">
                            <div class="flex justify-between items-center">
                                <toggle
                                    :model-value="commissionProductPriorityOverDynamic"
                                    @update:modelValue="handleProductPriorityToggle"
                                ></toggle>
                                <span class="flex flex-col flex-grow" id="priority-label">
                                    <span class="text-sm font-medium text-gray-900">Product priority over the dynamic commission</span>
                                    <span class="text-sm text-gray-500">Rule set for product commissions will have higher priority compared to dynamic rules</span>
                                </span>
                            </div>
                        </div>

                    </template>
                </modal>
            </div>
        </div>
        <small class="text-gray-500 mt-1 block" v-if="!compactMode">Set the commission amount and the currency.</small>

        <div v-if="compactMode">
            <div class="flex justify-between items-end">
            <label for="commission" class="block mb-1.5 text-sm">Commission and currency</label>
            <btn size="small" type="secondary" class="mb-1.5" @click="showDynamicRewardModal">Edit</btn>
            </div>

            <div class="border rounded p-4 space-y-4">

                <div class="grid md:grid-cols-2 gap-4">
                    <div class="rounded bg-gray-50 p-4">
                        <div class="text-sm font-semibold">Default commission</div>
                        <div class="text-sm text-gray-500">
                            {{ settings.commission }}<span v-if="typeIsPercent">%</span>
                        </div>
                    </div>
                    <div class="rounded bg-gray-50 p-4">
                        <div class="text-sm font-semibold">Currency</div>
                        <div class="text-sm text-gray-500">{{ settings.currency.toUpperCase() }}</div>
                    </div>
                </div>


                <div v-if="dynamicPeriodCommissions.length > 0">
                    <div class="bg-gray-50 rounded p-4 text-sm font-semibold">Dynamic commissions</div>
                    <ul class="text-sm space-y-2 pl-4 pt-4">
                        <li v-for="(periodCommission, index) in validDynamicPeriodCommissions">
                                        <span class="font-semibold">{{ periodCommission.commission_value }}
                                            <span v-if="typeIsPercent">%</span>
                                            <span v-else>&nbsp;{{ settings.currency.toUpperCase() }}</span>
                                        </span>
                            if <span class="font-semibold">period</span>
                            is {{ getConditionTypeLabel(periodCommission.condition_type) }} <span class="font-semibold">{{ periodCommission.period_times }}</span> {{ periodCommission.period_type }}
                            <ul v-if="periodCommission.dynamic_commissions?.length > 0" class="text-sm space-y-2 ml-4 mt-2">
                                <li v-for="(dynamicCommission, itemIndex) in validDynamicPeriodDynamicCommissions(index)">
                                    {{itemIndex+1}}.
                                    <span class="font-semibold">
                                                    {{ dynamicCommission.commission_value }}
                                                    <span v-if="typeIsPercent">%</span>
                                                    <span v-else>&nbsp;{{ settings.currency.toUpperCase() }}</span>
                                                </span>
                                    if <span class="font-semibold">{{ getConditionElementLabel(dynamicCommission.condition_element) }}</span>
                                    is {{ getConditionTypeLabel(dynamicCommission.condition_type) }} <span class="font-semibold">{{ dynamicCommission.condition_value }}</span>
                                    <span v-if="dynamicCommission.condition_element === 'first_sale'">coming from the customer</span>
                                    <template v-if="!['single_sale', 'first_sale'].includes(dynamicCommission.condition_element)">
                                        during <span v-if="dynamicCommission.condition_value_period === 'previous'" class="font-semibold">previous {{ dynamicCommission.condition_value_period_times }} {{ dynamicCommission.condition_value_period_type }}</span>
                                        <span v-else class="font-semibold">all time</span>
                                    </template>
                                </li>
                                <badge
                                    v-if="periodCommission.dynamic_commissions.length !== validDynamicPeriodDynamicCommissions(index).length"
                                    type="error"
                                    :title="`You have ` + (periodCommission.dynamic_commissions.length - validDynamicPeriodDynamicCommissions(index).length) + ` incomplete dynamic period dynamic commissions.`"
                                />
                            </ul>
                        </li>
                        <badge
                            v-if="dynamicPeriodCommissions.length !== validDynamicPeriodCommissions.length"
                            type="error"
                            :title="`You have ` + (dynamicPeriodCommissions.length - validDynamicPeriodCommissions.length) + ` incomplete dynamic period commission.`"
                        />
                    </ul>
                </div>

                <div v-if="dynamicCommissions.length > 0">
                    <div class="bg-gray-50 rounded p-4 text-sm font-semibold">Dynamic commissions</div>
                    <ul class="text-sm space-y-2 pl-4 pt-4">
                        <li v-for="(dynamicCommission, parentIndex) in validDynamicCommissions">
                        <span class="font-semibold">
                            {{ dynamicCommission.commission_value }}
                            <span v-if="typeIsPercent">%</span>
                            <span v-else>&nbsp;{{ settings.currency.toUpperCase() }}</span>
                        </span>
                            if <span class="font-semibold">{{ getConditionElementLabel(dynamicCommission.condition_element) }}</span>
                            is {{ getConditionTypeLabel(dynamicCommission.condition_type) }} <span class="font-semibold">{{ dynamicCommission.condition_value }}</span>
                            <span v-if="dynamicCommission.condition_element === 'first_sale'">coming from the customer</span>
                            <template v-if="!['single_sale', 'first_sale'].includes(dynamicCommission.condition_element)">
                                during <span v-if="dynamicCommission.condition_value_period === 'previous'" class="font-semibold">previous {{ dynamicCommission.condition_value_period_times }} {{ dynamicCommission.condition_value_period_type }}</span>
                                <span v-else class="font-semibold">all time</span>
                            </template>
                        </li>
                        <badge
                            v-if="dynamicCommissions.length !== validDynamicCommissions.length"
                            type="error"
                            :title="`You have ` + (dynamicCommissions.length - validDynamicCommissions.length) + ` incomplete dynamic commission.`"
                        />
                    </ul>
                </div>

                <div v-if="productCommissions.length > 0">
                <div class="bg-gray-50 rounded p-4 text-sm font-semibold">Product-based</div>
                    <ul class="text-sm space-y-2 pl-4 pt-4">
                        <li v-for="productCommission in validProductCommissions">
                        <span class="font-semibold">{{ productCommission.commission_value }}<span
                            v-if="typeIsPercent">%</span><span v-else>&nbsp;{{ settings.currency.toUpperCase() }}</span></span>
                            if <span
                            class="font-semibold">{{
                                getProductConditionElementLabel(productCommission.condition_element)
                            }}</span>
                            is {{ productCommission.condition_value.length > 1 ? 'one of ' : '' }}<span
                            class="font-semibold">{{ productCommission.condition_value.join(', ') }}</span>
                        </li>
                        <badge v-if="productCommissions.length !== validProductCommissions.length" type="error"
                               :title="`You have ` + (productCommissions.length - validProductCommissions.length) + ` incomplete product commission.`"/>
                        <li v-if="commissionProductPriorityOverDynamic === 'on'"
                            class="bg-green-50 text-green-600 p-2 my-5 rounded">Product has priority over dynamic
                            commissions
                        </li>
                    </ul>
                </div>
                <div v-if="goalCommissions.length > 0">
                    <div class="bg-gray-50 rounded p-4 text-sm font-semibold">Goals</div>
                    <ul class="text-sm space-y-2 pl-4 pt-4">
                        <li v-for="goalCommission in validGoalCommissions">
                        <span class="font-semibold">{{
                                goalCommission.commission_value
                            }} {{ settings.currency.toUpperCase() }}</span> if <span
                            class="font-semibold">{{
                                getGoalConditionElementLabel(goalCommission.condition_element)
                            }}</span>
                            is {{ getGoalConditionTypeLabel(goalCommission.condition_type) }} <span
                            class="font-semibold">{{ goalCommission.condition_value }}</span>,
                            applicable from <span
                            class="font-semibold">{{ formatDateTimeToDisplay(goalCommission.condition_date_from) }}</span>
                            <template v-if="goalCommission.condition_date_to"> until <span
                                class="font-semibold">{{ formatDateTimeToDisplay(goalCommission.condition_date_to) }}</span>
                            </template>
                            <badge v-if="goalCommission.condition_repeatable" type="warning" title="Repeated" class="mx-2"/>
                            <badge v-else type="neutral" title="Once" class="mx-2"/>
                        </li>
                        <badge v-if="goalCommissions.length !== validGoalCommissions.length" type="error"
                               :title="`You have ` + (goalCommissions.length - validGoalCommissions.length) + ` incomplete goal commission.`"/>
                    </ul>
                </div>
            </div>
        </div>
        <input type="hidden" name="commission" v-model="settings.commission"/>
        <input type="hidden" name="currency" v-model="settings.currency"/>
        <input type="hidden" name="dynamic_commissions" v-model="dynamicConditionsJson"/>
        <input type="hidden" name="product_commissions" v-model="productConditionsJson"/>
        <input type="hidden" name="goal_commissions" v-model="goalConditionsJson"/>
        <input type="hidden" name="dynamic_period_commissions" v-model="dynamicPeriodConditionsJson"/>
        <input type="hidden" :name="props.productPriorityToggleName" v-model="commissionProductPriorityOverDynamic"/>
    </div>
</template>
<script setup>
import moment from 'moment-timezone';
import {ref, computed, inject, onMounted} from "vue";
import Badge from "../../../../elements/Badge";

const props = defineProps({
    currencies: {
        type: Object,
    },
    dynamicCommissions: {
        type: Array,
        default(rawProps) {
            return [];
        }
    },
    productCommissions: {
        type: Array,
        default(rawProps) {
            return [];
        }
    },
    goalCommissions: {
        type: Array,
        default(rawProps) {
            return [];
        }
    },
    dynamicPeriodCommissions: {
        type: Array,
        default(rawProps) {
            return [];
        }
    },
    commissionSettings: {
        type: Object,
        default(rawProps) {
            return {
                commission_type: 'static',
                commission: 0,
                currency: 'USD'
            };
        }
    },
    dynamicCommissionConditionElements: {
        type: Object,
        default: () => {
        }
    },
    dynamicCommissionConditionTypes: {
        type: Object,
        default: () => {
        }
    },
    dynamicPeriodCommissionConditionTypes: {
        type: Object,
        default: () => {
        }
    },
    productCommissionConditionElements: {
        type: Object,
        default: () => {
        }
    },
    productPriorityToggleName: {
        type: String,
        default: 'commission_product_priority_over_dynamic'
    },
    productPriorityToggleEnabled: {
        type: Boolean,
        default: false
    },
    productImportUrl: {
        type: String,
        required: true
    },
    showProductImport: {
        type: Boolean,
        default: false
    },
    goalCommissionConditionElements: {
        type: Object,
        default: () => {
        }
    },
    goalCommissionConditionTypes: {
        type: Object,
        default: () => {
        }
    }
});

const emitter = inject('emitter');

const settings = ref(props.commissionSettings);
const dynamicCommissions = ref(props.dynamicCommissions);
const productCommissions = ref(props.productCommissions);
const goalCommissions = ref(props.goalCommissions);
const dynamicPeriodCommissions = ref(props.dynamicPeriodCommissions);
const dynamic_reward_modal = ref(null);

const validDynamicCommissions = computed(() => {
    return dynamicCommissions.value.filter((commission) => {
        return (commission.commission_value && commission.condition_element === 'first_sale')
            || (commission.commission_value && commission.condition_element && commission.condition_type && commission.condition_value)
    })
});

const validDynamicPeriodCommissions = computed(() => {
    return dynamicPeriodCommissions.value.filter((commission) => {
        return commission.commission_value && commission.condition_type && commission.period_times && commission.period_type;
    })
});

const validDynamicPeriodDynamicCommissions = (index) => {
    return dynamicPeriodCommissions.value[index]?.dynamic_commissions?.filter((commission) => {
        return (commission.commission_value && commission.condition_element === 'first_sale')
            || (commission.commission_value && commission.condition_element && commission.condition_type && commission.condition_value)
    })
};

const validProductCommissions = computed(() => {
    return productCommissions.value.filter((commission) => {
        return commission.commission_value && commission.condition_element && Array.isArray(commission.condition_value) && commission.condition_value.length > 0
    })
});

const validGoalCommissions = computed(() => {
    return goalCommissions.value.filter((commission) => {
        return commission.commission_value && commission.condition_element && commission.condition_type && commission.condition_value && commission.condition_date_from
    })
});

const compactMode = computed(() => dynamicCommissions.value.length > 0 || productCommissions.value.length > 0 || goalCommissions.value.length > 0 || dynamicPeriodCommissions.value.length > 0);

const typeIsPercent = computed(() => settings.value.commission_type === 'percent');

const dynamicConditionsJson = computed(() => JSON.stringify(dynamicCommissions.value));
const productConditionsJson = computed(() => JSON.stringify(productCommissions.value));
const goalConditionsJson = computed(() => JSON.stringify(goalCommissions.value));
const dynamicPeriodConditionsJson = computed(() => JSON.stringify(dynamicPeriodCommissions.value));

const commissionProductPriorityOverDynamic = ref(props.productPriorityToggleEnabled ? 'on' : null);
const handleProductPriorityToggle = (value) => {
    commissionProductPriorityOverDynamic.value = value ? 'on' : null
}

const getConditionElementLabel = (key) => {
    return props.dynamicCommissionConditionElements[key]?.label
}

const getConditionTypeLabel = (key) => {
    return props.dynamicCommissionConditionTypes[key]?.label
}

const getProductConditionElementLabel = (key) => {
    return props.productCommissionConditionElements[key]?.label
}

const getGoalConditionElementLabel = (key) => {
    return props.goalCommissionConditionElements[key]?.label
}

const getGoalConditionTypeLabel = (key) => {
    return props.goalCommissionConditionTypes[key]?.label
}

// Tabs
const DYNAMIC = 'dynamic';
const PRODUCT = 'product';
const GOAL = 'goal';

let activeCommissionTab = ref(DYNAMIC);

const setActiveCommissionTab = (tab) => {
    activeCommissionTab.value = tab;
};

//Products from stripe and shopify
const stripeProducts = ref([]);
const shopifyProducts = ref([]);

const loading = ref({
    products: false
});

const loadProducts = () => {
    if (!props.showProductImport || loading.value.products) {
        return;
    }

    loading.value.products = true;

    axios.get(props.productImportUrl)
        .then(response => {
            const status = parseInt(response.data.status || -1);
            if (status === 1) {
                stripeProducts.value = response.data.stripe_products
                shopifyProducts.value = response.data.shopify_products
            }
        })
        .finally(() => {
            loading.value.products = false;
        });
}

const formatDateTimeToDisplay = (value) => {
    return moment(value, 'YYYY-MM-DD HH:mm:ss Z').format('Do MMM, YYYY');
}

const showDynamicRewardModal = () => {
    dynamic_reward_modal.value.onShow();
}

onMounted(() => {
    loadProducts()
})
</script>

