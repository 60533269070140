<template>
    <!-- dataExists comes from the blade view and it's initial value. If partner is created or imported, that is done via
       ajax call and blade variable is not updated. That's why we are checking data length.
        We have to put filter up here to set the filter fields, because if data does not exist the filtered data query won't be triggered on initial load
        and after the first record creation two queries will be triggered, one without and one with filters for the profile/customers/transactions page.
        -->
    <table-filter
        v-show="showTableFilter && (dataExists || paginationData?.data?.length > 0)"
        :filter-list="filterableFields"
    />
    <div v-if="dataExists || paginationData?.data?.length > 0">

        <export-delete-and-approve-details v-if="exportOptions.selectedIds.length > 0 || exportingInProgress || fileReadyForDownloadExist"/>

        <div class="bg-white rounded border overflow-x-auto mt-6">
            <slot/>
            <pagination
                v-if="showPagination && paginationData"
                :pagination="paginationData"
            />
        </div>
    </div>
    <no-data-table v-else-if="!loadingData && !dataExists" :model-name="modelName"/>
</template>

<script>
import TableFilter from "./filters/TableFilter";
import Pagination from "./pagination/Pagination";
import NoDataTable from "./NoDataTable";
import ExportDeleteAndApproveDetails from "./exports/ExportDeleteAndApproveDetails.vue";
import useFilters from "../../../state/common/filters-and-pagination";
import {watch} from "vue";
import useTableDataExporter from "../../../composables/common/select-export-and-delete-data";

export default {
    name: "FiltersAndPagination",
    components: {TableFilter, Pagination, NoDataTable, ExportDeleteAndApproveDetails},
    props: {
        showTableFilter: {
            type: Boolean,
            default: true
        },
        showPagination: {
            type: Boolean,
            default: true
        },
        dataExists: {
            type: [Boolean, String, Number],
            default: false
        },
        paginationData: {
            type: Object
        },
        filterableFields: {
            type: Object
        },
        loadingData: {
            type: Boolean,
            default: false
        },
        modelName: {
            type: String,
            required: true
        },
        fetchDataWithoutFilters: {
            type: Function
        }
    },
    setup(props) {
        const showFilters = props.showTableFilter

        const {
            filters,
            fetchDataBaseUrl,
            fetchDataFunction,
        } = useFilters()

        function fetchData() {
            if (props.showTableFilter) {
                fetchDataFunction.value(fetchDataBaseUrl.value, {...filters})
            } else {
                fetchDataFunction.value(fetchDataBaseUrl.value)
            }
        }

        const { exportOptions, exportingInProgress, fileReadyForDownloadExist } = useTableDataExporter()

        watch(fetchDataFunction, () => {
            if (!showFilters) {
                fetchData();
            }
        })

        return { exportOptions, exportingInProgress, fileReadyForDownloadExist }
    }
}
</script>
