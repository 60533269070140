<template>

    <div class="flex items-center justify-between gap-4">

        <div class="flex items-center justify-start gap-4">
            <div class="flex-shrink-0 w-10 h-10">
                <img class="w-10 h-10 rounded" src="/images/integrations/paypal.png">
            </div>

            <div v-if="has_integration">
                <div class="text-sm font-semibold">PayPal Mass Pay</div>
                <div v-if="status_message" class="text-sm font-semibold text-gray-500 mt-0.5">{{ status_message }}</div>
                <div v-else-if="error_message" class="ml-5">{{ error_message }}</div>
                <div v-else class="text-sm font-normal text-gray-500 mt-0.5">
                    Pay rewards automatically via PayPal Mass Pay integration.
                </div>
            </div>

            <div v-if="!has_integration">
                <div class="text-sm font-semibold">PayPal Mass Pay</div>
                <div class="text-sm font-normal text-gray-500 mt-0.5">
                    To begin issuing automatic payouts through PayPal Mass Pay, <a class="underline" :href="integrationSetupUrl">please link your PayPal account</a>.
                </div>
            </div>

        </div>

        <div>
            <btn v-if="has_integration" type="secondary" :loading="loading.setup_process" :disabled="!allowIntegration" @click="setupProcess">
                Proceed
            </btn>
            <modal name="payout_through_api_process_modal"
                   title="Payment Execution"
                   size="small"
                   :show="false"
                   :show-top-right-close-button="false"
                   :close-modal-on-outside-click="false"
            >
                <template #close-button>
                    <btn @click="cancelProcessAndCloseModal" icon-name="close" type="light" size="small"></btn>
                </template>
                <template #body>
                    <div class="space-y-6">
                        <span v-if="message" class="text-sm">{{ message }}</span>
                    </div>
                </template>
                <template #footer-cancel-button>
                    <btn @click.native="cancelProcessAndCloseModal"
                         type="light"
                         :disabled="!allowProcess || loading.processing || loading.cancelling_process"
                    >
                        Cancel process
                    </btn>
                </template>
                <template #footer>
                    <btn v-on:click="process" :loading="loading.processing" type="primary"
                         :disabled="!allowProcess || loading.processing || loading.cancelling_process"
                    >
                        Process
                    </btn>
                </template>
            </modal>
        </div>
    </div>
</template>
<script>
import {isProxy, toRaw} from 'vue';
import useFilters from "../../../../../state/common/filters-and-pagination";

export default {
    name: "PayoutThroughApi",
    props: ['program', 'has_integration'],
    data() {

        return {
            loading: {
                processing: false,
                setup_process: false,
                cancelling_process: false,
            },
            uuid: null,
            selectedIds: [],
            selectedType: null,
            can_process: false,
            message: null,
            is_done: false,
            error_message: null,
            status_message: null,
            allowIntegration: false,
            allowProcess: false,
            integrationSetupUrl: route('programs.integrations', this.program),
            gateway: 'paypal'
        }
    },
    mounted() {
        let self = this;
        this.emitter.on('export-selection-set', function (exportData) {
            if (exportData.name === 'paypal_mass_payout') {
                let selectedIds = exportData.options.selectedIds;
                if (isProxy(exportData.options.selectedIds)) {
                    selectedIds = toRaw(exportData.options.selectedIds)
                }
                self.payoutsChanges(exportData.options.selectType, selectedIds);
            }
        });

    },
    methods: {
        setupProcess() {

            if (this.loading.setup_process || !this.allowIntegration) {
                return;
            }

            const self = this;
            this.allowProcess = false;
            this.loading.setup_process = true;
            this.status_message = null;

            axios.post(route('app_int.payouts.mass-payout.gateway.api_setup_process', {program: this.program, gateway: this.gateway}), {
                type: this.selectedType,
                ids: this.selectedIds
            }).then(function (response) {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {

                    self.uuid = response.data.result.uuid;

                    const count = response.data.result.total_payouts;

                    if (count > 0) {
                        self.allowProcess = true;
                        const payoutTxt = count === 1 ? 'payout' : 'payouts';
                        self.message = count + ' ' + payoutTxt + ' will be processed. The total amount is ' + response.data.result.total_payouts_amount;
                    } else {
                        self.allowProcess = false;
                        self.message = 'No payouts found.';
                    }
                    self.emitter.emit('modal-on', 'payout_through_api_process_modal');
                }
            }).finally(() => this.loading.setup_process = false);
        },
        process() {
            if (this.loading.processing) {
                return;
            }

            let self = this;
            this.loading.processing = true;

            axios.post(route('app_int.payouts.mass-payout.gateway.api_process', {program: this.program, gateway: this.gateway}), {
                payout_through_mass_pay: true,
                uuid: this.uuid
            }).then(function (response) {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {
                    if (response.data.result.status_text ?? false) {
                        self.status_message = 'Status: ' + response.data.result.status_text;
                    }
                    self.emitter.emit('modal-off', 'payout_through_api_process_modal');
                    self.processStatus();
                }
            }).finally();
        },
        processStatus() {
            let self = this;
            axios.post(route('app_int.payouts.mass-payout.gateway.api_process_status', {program: this.program, gateway: this.gateway}), {
                uuid: this.uuid
            }).then(function (response) {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {
                    if (response.data.result.status_text ?? false) {
                        self.status_message = 'Status: ' + response.data.result.status_text;
                        if (response.data.result.paid_amount ?? false) {
                            self.status_message += '. Total paid: ' + response.data.result.paid_amount;
                        }
                    }
                    if (response.data.result.error ?? false) {
                        self.is_done = true;
                        self.status_message = null;
                        self.error_message = response.data.result.error;
                    }

                    if (response.data.result.is_done ?? false) {
                        self.uuid = false;
                        self.is_done = true;
                        self.loading.processing = false;
                        self.loading.setup_process = false;

                        self.payoutsChanges(null, []);
                        self.reloadPayouts();
                    } else {
                        self.processStatus();
                    }
                }
            });
        },
        payoutsChanges(selectType, selectedIds) {
            this.selectedType = selectType;
            this.selectedIds = selectedIds;
            this.allowIntegration = (this.selectedType === 'selectAll' || this.selectedIds.length > 0) && this.has_integration;
        },
        cancelProcessAndCloseModal() {
            if (this.loading.cancelling_process) {
                return;
            }

            this.loading.cancelling_process = true;

            const self = this;

            axios.delete(route('app_int.payouts.mass-payout.gateway.delete_canceled_api_setup_process_data', {program: this.program, gateway: this.gateway}), {
                data: {
                    uuid: self.uuid
                }
            }).then(function (response) {
                const status = parseInt(response.data.status ?? -1);
                if (status === 1) {
                    if (response.data.result.status_text ?? false) {
                        self.status_message = 'Status: ' + response.data.result.status_text;
                    }
                    self.uuid = null;
                    self.message = null;
                    self.is_done = false;
                    self.error_message = null;
                    self.allowProcess = false;
                    self.emitter.emit('modal-off', 'payout_through_api_process_modal');
                } else if (status === 0) {
                    this.status_message = 'Error cancelling process.';
                    this.error_message = response.data.errors?.[0] ?? 'Error';
                }
            }).finally(
                () => {
                    this.loading.cancelling_process = false
                    self.emitter.emit('modal-off', 'payout_through_api_process_modal');
                }
            );
        },
        reloadPayouts() {
            const {
                fetchDataBaseUrl,
                fetchDataFunction,
            } = useFilters()
            fetchDataFunction.value(fetchDataBaseUrl.value);
        }
    }
}
</script>

<style scoped>

</style>
