<script setup>
import { ref, computed } from 'vue';
import axios from 'axios';
import {createRouteForTemplate} from "../../../../../helpers/common";
import DisconnectNrpButton from "./components/DisconnectNrpButton.vue";
import {useEspSettingsStore} from "../../../../../stores/app/programs/newsletter/EspSettingsStore";

const {
    program,
    esp_config,
    setting_group_import,
    setting_group_export,
    setting_subscribers_sync
} = defineProps(['program', 'esp_config', 'setting_group_import', 'setting_group_export', 'setting_subscribers_sync'])

const espConfig = esp_config ?? {};

const espSettingsStore = useEspSettingsStore();
espSettingsStore.setGroups(esp_config?.groups ?? []);
espSettingsStore.setGroupImport(esp_config?.group_import ?? 0);
espSettingsStore.setGroupExport(esp_config?.group_export ?? 0);


const subscribers_sync = ref(espConfig.subscribers_sync ?? true);
const isConnected = ref(espConfig.connected ?? false);
const isSetupCompleted = ref(espConfig.setupCompleted ?? false);
const deleteExistingSubscribersFromPartnero = ref(false);

const loading = ref({
    connecting: false,
    disconnecting: false,
});

const enabled_settings = ref({
    setting_group_import: setting_group_import ?? false,
    setting_group_export: setting_group_export ?? false,
    setting_subscribers_sync: setting_subscribers_sync ?? false,
});

const enabled_settings_json = computed(() => JSON.stringify(enabled_settings.value));

function disconnect() {
    if (loading.disconnecting) {
        return;
    }
    loading.disconnecting = true;

    axios
        .post(route('app_int.newsletter.esp.disconnect', { program }), {
            type: 'mailchimp',
            deleteExistingSubscribersFromPartnero: deleteExistingSubscribersFromPartnero.value
        })
        .then((response) => {
            const status = parseInt(response.data.status || 0);

            if (status === 1) {
                espSettingsStore.setGroupImport('');
                espSettingsStore.setGroupExport('');
                subscribers_sync.value = '';
                isConnected.value = false;
            }
        })
        .catch((error) => {})
        .finally(() => (loading.disconnecting = false));
}
</script>


<template>
    <div class="bg-white rounded border p-4">
        <!-- Integration not connected -->
        <div class="flex justify-between items-center" v-if="!isConnected">
            <div class="text-sm text-gray-900">
                Mailchimp is not connected.
            </div>
            <btn :href="createRouteForTemplate('programs.mailchimp.authorize', {program: program})" type="secondary" size="small">Authorize</btn>
        </div>
        <!-- Integration is connected -->
        <div class="flex justify-between items-center gap-4" v-if="isConnected">
            <div class="flex items-center">
                <div class="flex-shrink-0 w-10 h-10">
                    <img class="w-10 h-10 rounded" src="/images/integrations/mailchimp-256x256.png">
                </div>
                <div class="text-sm text-gray-500 ml-5">
                    Successfully connected.
                </div>
            </div>
            <div>
                <DisconnectNrpButton title="Mailchimp disconnect"
                                     :loading="loading.disconnecting"
                                     @disconnectEsp="disconnect"
                                     v-model:delete-existing-subscribers="deleteExistingSubscribersFromPartnero"
                                     provider="Mailchimp"
                ></DisconnectNrpButton>
            </div>
        </div>
    </div>

    <div v-if="isConnected && enabled_settings.setting_group_import">
        <label for="format" class="block text-sm mb-1.5">Subscriber list</label>
        <select name="esp_mailchimp_group_import" class="input" v-model="espSettingsStore.group_import">
            <option value="0">Select a group</option>
            <option v-for="group in espSettingsStore.groups" :value="group.id">{{ group.name }}</option>
        </select>
        <small class="text-gray-500 mt-1 block">Please select the subscribers who will participate in your newsletter
            referral program.</small>
    </div>

    <div v-if="isConnected && enabled_settings.setting_group_export">
        <label for="format" class="block text-sm mb-1.5">Destination list</label>
        <select name="esp_mailchimp_group_export" class="input" v-model="espSettingsStore.group_export">
            <option value="0">Select a group</option>
            <option v-for="group in espSettingsStore.groups" :value="group.id">{{ group.name }}</option>
        </select>
        <small class="text-gray-500 mt-1 block">Please choose the destination where referred subscribers should be saved
            upon subscribing.</small>
    </div>

    <div class="flex justify-between items-center" v-if="isConnected && enabled_settings.setting_subscribers_sync">
        <toggle name="esp_mailchimp_subscribers_sync" v-model="subscribers_sync"></toggle>
        <span class="flex flex-col flex-grow">
                            <span class="text-sm font-medium text-gray-900">Enable subscribers synchronization</span>
                            <span class="text-sm text-gray-500">When enabled every new subscriber that appears in the group will be synced with your newsletter referral program. </span>
                        </span>
    </div>
    <input type="hidden" name="esp_mailchimp_enabled_settings" v-model="enabled_settings_json">
</template>

