<template>
    <button class="focus:outline-none focus:shadow-none">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: "OptionsToggle"
    }
</script>

<style scoped>

</style>
