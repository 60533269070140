<script setup>
    import {computed, onMounted, ref} from "vue";
    import ListSkeleton from "../../../../common/skeletons/ListSkeleton.vue";
    import SimplePagination from "../SimplePagination.vue";
    import ProgressBar from "../../../../../elements/ProgressBar.vue";

    const props = defineProps({
        program: {
            type: Object,
            required: true
        },
        coupon: {
            type: Object,
            required: true
        }
    });

    const classicPromotionCodes = ref({
        data: []
    });

    const baseUrl = route('app_int.program.coupon.promotion-code.classic-list', { program: props.program, coupon: props.coupon });

    const loadingClassicPromotionCodes = ref(false);

    const canArchive = computed(() => props.coupon.sync_provider !== 'woocommerce');

    const getSyncProviderStatus = (syncProviderId) => {
        if (syncProviderId) {
            return 'Yes';
        } else {
            return '<span class="text-red-600">No</span>';
        }
    }

    const getClassicPromotionCodes = (url) => {
        loadingClassicPromotionCodes.value = true;
        axios.get(url)
            .then(response => {
                classicPromotionCodes.value = response.data;
            })
            .finally(() => {
                loadingClassicPromotionCodes.value = false;
            });
    };

    const getPartnerLink = (partnerId) => {
        if (!partnerId) {
            return '#';
        }
        return route('programs.partner.show', { program: props.program, partner: partnerId });
    };

    const getReferralLink = (customerId) => {
        if (!customerId) {
            return '#';
        }
        return route('programs.customer.show', { program: props.program, customer: customerId });
    };

    onMounted(() => {
        getClassicPromotionCodes(baseUrl);
    });

</script>

<template>
    <div v-if="loadingClassicPromotionCodes && classicPromotionCodes.data.length === 0">
        <ListSkeleton :number-of-items="2"></ListSkeleton>
    </div>
    <div v-else-if="classicPromotionCodes.data.length > 0" class="space-y-4">
        <header-bar title="Promotion codes" size="xsmall"></header-bar>
        <div class="border rounded divide-y">
            <table class="min-w-full">
                <thead class="border-b">
                <tr class="h-1">
                    <th :colspan="coupon.sync_provider ? 7 : 6">
                        <progress-bar v-if="loadingClassicPromotionCodes"/>
                    </th>
                </tr>
                <tr>
                    <th class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Promotion code</th>
                    <th class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Redemptions</th>
                    <th class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Expires</th>
                    <th class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Assigned</th>
                    <th class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Status</th>
                    <th v-if="coupon.sync_provider" class="py-4 px-6 text-sm text-left text-gray-500 font-normal">Synced</th>
                    <th></th>
                </tr>
                </thead>
                <tbody class="divide-y">
                    <tr v-for="promotionCode in classicPromotionCodes.data" :key="'classic-promo-' + promotionCode['code'] ?? ''">
                        <td class="p-6 text-sm">
                            <div class="flex gap-4 items-center">
                                <icon name="coupon" size="24"></icon>
                                <div>
                                    <div class="font-semibold" :title="promotionCode['code'] ?? ''">
                                        {{ promotionCode['code']
                                        ? (promotionCode['code'].length > 20 ? promotionCode['code'].substring(0, 18) + '...' : promotionCode['code'])
                                        : ''
                                        }}
                                    </div>
                                    <div class="text-gray-500">
                                        {{ promotionCode['details'] ?? '' }}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="p-6 text-sm">{{ promotionCode['times_redeemed']  }} / {{ promotionCode['redemption_times_status'] === '0' ? '&#8734' : promotionCode['redemption_times_value'] }}</td>
                        <td class="p-6 text-sm">{{ promotionCode['expiration_date_status'] ? promotionCode['expiration_date_value'] : '—' }}</td>
                        <td class="p-6 text-sm space-x-2">
                            <a v-if="promotionCode['limit_to_specific_partner'] && promotionCode['coupon_specific_partners']?.['id']"
                               :href="getPartnerLink(promotionCode['coupon_specific_partners']?.['id'])"
                               class="font-medium text-sm text-gray-900 hover:text-teal-600">
                                {{ promotionCode['coupon_specific_partners']?.['full_name'] }}
                            </a>
                            <a v-if="promotionCode['coupon_specific_customers']"
                               :href="getReferralLink(promotionCode['coupon_specific_customers']?.['id'])"
                               class="font-medium text-sm text-gray-900 hover:text-teal-600">
                                {{ promotionCode['coupon_specific_customers']?.['customer_full_name'] }}
                            </a>
                            <template v-else>
                            —
                            </template>
                        </td>
                        <td class="p-6 text-sm whitespace-nowrap">
                            <badge v-if="promotionCode['active']"
                                type="success"
                                size="small"
                                title="Active"
                            ></badge>
                            <badge v-else
                                type="warning"
                                size="small"
                                title="Not active"
                            ></badge>
                        </td>
                        <td v-if="coupon.sync_provider" class="p-6 text-sm">
                            <div v-html="getSyncProviderStatus(promotionCode['sync_provider_id'])"></div>
                        </td>
                        <td class="p-6">
                            <div class=" flex items-center justify-end">
                                <promotion-code-modal
                                    :can-archive="canArchive"
                                    :promotion-code="promotionCode"
                                    :program="program"
                                    :coupon-id="coupon['id']"
                                    promotion-code-action="edit"
                                ></promotion-code-modal>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <SimplePagination :pagination="classicPromotionCodes" @fetchData="getClassicPromotionCodes"></SimplePagination>
        </div>
    </div>
</template>

