<template>
    <div
        v-if="errors"
        class="flex items-center bg-red-50 px-4 py-3 rounded text-red-500 text-sm gap-3 relative" role="alert"
    >
        <ul>
            <li
                v-for="(error) in errors"
            >
                {{ error[0] || error }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ValidationErrors',
    props: {
        errors: {
            type: [Object, Array],
            default: null
        }
    }
}
</script>
