<template>
    <Filemanager />
</template>

<script>
import Filemanager from './../../../../../packages/filemanager/resources/js/Filemanager'

export default {
    name: 'FilemanagerWrapper',
    components: {
        Filemanager,
    },
}
</script>

<style scoped>

</style>
