<template>

    <div>
        <field-label v-if="label" :title="label"/>
        <div class="inline-flex z-0 w-full rounded">
            <template v-for="(option, index) in options">
                <label
                    v-if="!option.custom || picked !== option.value"
                    class="flex flex-1 justify-center items-center px-2 -ml-px border border-gray-200 cursor-pointer group focus:z-20 focus:border-gray-300"
                    :class="{
                        'bg-teal-50 z-10 text-gray-900 border-gray-300': picked === option.value,
                        'text-gray-600 hover:text-gray-900': picked !== option.value,
                        'rounded-l': index === 0,
                        'rounded-r': index === options.length-1,
                        '': !option.custom,
                        }"
                >
                    <input type="radio"
                           :name="name"
                           v-model="picked"
                           :value="option.value"
                           class="sr-only"/>

                    <div class="flex justify-center items-center h-9 text-xs font-medium group">
                        <div class="w-3.5 h-3.5 border rounded-full" v-if="option.label_is_class ?? false"
                             :class="option.label"></div>
                        <div
                            class="w-3.5 h-3.5"
                            v-else-if="option.label_is_img ?? false"
                            :class="{
                                'group-hover:opacity-75 opacity-50': picked !== option.value,
                            }"
                        >
                            <img :src="option.label" alt="option.value"/>
                        </div>
                        <div v-else-if="option.label_is_text">
                            {{ option.label }}
                        </div>
                        <div class="h-4" v-else>
                            <svg class="w-4 h-4 text-gray-500 group-hover:text-gray-900" fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path
                                    d="M7.784 14l.42-4H4V8h4.415l.525-5h2.011l-.525 5h3.989l.525-5h2.011l-.525 5H20v2h-3.784l-.42 4H20v2h-4.415l-.525 5h-2.011l.525-5H9.585l-.525 5H7.049l.525-5H4v-2h3.784zm2.011 0h3.99l.42-4h-3.99l-.42 4z"/>
                            </svg>
                        </div>
                    </div>
                </label>

                <input
                    v-else
                    :type="option.custom.type"
                    :name="option.custom.name"
                    :placeholder="option.custom.placeholder"
                    v-model="customValue"

                    class="z-10 flex-1 px-1 -ml-px w-16 text-xs text-center border border-gray-300 transition-shadow outline-none"
                    :class="{
                        'bg-teal-50 z-10 text-gray-900 border-gray-300 focus:border-gray-400': picked === option.value,
                        'text-gray-700': picked !== option.value,
                        'rounded-l': index === 0,
                        'rounded-r': index === options.length-1,
                        }"
                />
            </template>
        </div>
    </div>
</template>

<script>
import {ref, watch} from "vue";

export default {
    props: ['name', 'label', 'modelValue', 'options'],
    emits: ['picked'],
    setup(props, {emit}) {

        const customValue = ref(props.modelValue);
        const picked = ref(props.modelValue);

        watch([picked, customValue], ([newPicked, newCustom, finalValue]) => {
            finalValue = newPicked;
            if (newPicked === 'custom') {
                finalValue = newCustom;
            }

            emit('picked', {pickedValue: newPicked, customValue: newCustom, finalValue: finalValue});
        });

        return {customValue, picked}
    },
    mounted() {
        const values = this.options.map(option => option.value).filter(value => value !== 'custom')
        if (!values.includes(this.picked)) {
            this.customValue = this.picked;
            this.picked = 'custom';
        }
    }
}
</script>
