<script setup>

    import {useAnalyticsOverviewStore} from "../../../../../../../stores/app/programs/analytics/AnalyticsOverview";
    import {createRouteForTemplate} from "../../../../../../../helpers/common";

    const analyticsOverviewStore = useAnalyticsOverviewStore();

</script>

<template>
    <div class="grid lg:grid-cols-2 xl:col-span-2 gap-4">
        <div class="p-6 bg-white rounded border">
            <div class="mb-4 text-sm text-gray-900">Partners</div>
            <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                <a :href="createRouteForTemplate('programs.partners.index', { program: analyticsOverviewStore.getProgram, status: 'approved'})" class="hover:underline">{{analyticsOverviewStore.getAnalyticsOverviewStatsData['total_partners']}}</a>
            </h3>
        </div>
        <div class="p-6 bg-white rounded border">
            <div class="mb-4 text-sm text-gray-900">Signups</div>
            <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                <a :href="createRouteForTemplate('programs.referrals', { program: analyticsOverviewStore.getProgram, action: 'signup'})" class="hover:underline">{{analyticsOverviewStore.getAnalyticsOverviewStatsData['total_signups']}}</a>
            </h3>
        </div>
        <div class="p-6 bg-white rounded border">
            <div class="mb-4 text-sm text-gray-900">Paid customers</div>
            <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                {{analyticsOverviewStore.getAnalyticsOverviewStatsData['total_paid_accounts']}}
            </h3>
        </div>
        <div class="p-6 bg-white rounded border">
            <div class="mb-4 text-sm text-gray-900">Purchases</div>
            <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                {{analyticsOverviewStore.getAnalyticsOverviewStatsData['total_purchases']}}
            </h3>
        </div>

    </div>

    <div class="p-6 h-full bg-teal-500 rounded-md transition-shadow duration-200 dark:bg-teal-600">
        <div class="mb-4 text-sm text-white">Program revenue</div>
        <h3 class="text-2xl xl:text-3xl font-semibold leading-tight flex items-center gap-2">
            <span>
                <div v-for="$revenue in analyticsOverviewStore.getAnalyticsOverviewStatsData['total_revenue']"
                    class="text-white">
                    {{ $revenue }}
                </div>
            </span>
        </h3>
        <div class="border-b border-white border-opacity-10 my-7"></div>
        <div class="mb-3 text-sm text-white">Rewards</div>
        <h3 class="text-2xl  font-semibold leading-tight text-white mb-1 flex gap-2 items-center">
            <span>
                <div v-for="$reward in analyticsOverviewStore.getAnalyticsOverviewStatsData['total_reward']"
                    class="text-white">
                    {{ $reward }}
                </div>
            </span>
        </h3>
        <div class="text-white text-opacity-75 text-sm">
            <div class="text-white">
                <span v-for="(reward, index) in analyticsOverviewStore.getAnalyticsOverviewStatsData['total_pending']" >
                {{ reward }} {{ index < analyticsOverviewStore.getAnalyticsOverviewStatsData['total_pending'].length - 1 ? '/': '' }}
                </span>
                pending
            </div>
        </div>
    </div>
</template>
