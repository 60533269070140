<script setup>

import {useAnalyticsOverviewStore} from "../../../../../../stores/app/programs/analytics/AnalyticsOverview";
import {onMounted} from "vue";
import Stats from "./components/Stats.vue";
import StatsSkeleton from "../../../../../common/skeletons/StatsSkeleton.vue";
import ChartSkeleton from "../../../../../common/skeletons/ChartSkeleton.vue";
import InsightsChart from "./components/InsightsChart.vue";
import InsightsRecentTransactions from "./components/InsightsRecentTransactions.vue";
import ListSkeleton from "../../../../../common/skeletons/ListSkeleton.vue";
import Payouts from "./components/Payouts.vue";
import StatsWithSumSkeleton from "../../../../../common/skeletons/StatsWithSumSkeleton.vue";
import {createRouteForTemplate} from "../../../../../../helpers/common";
import TopPartners from "./components/TopPartners.vue";
import AccountOrProgramInsights from "../../../../views/AccountOrProgramInsights.vue";
import TableSkeleton from "../../../../../common/skeletons/TableSkeleton.vue";

const props = defineProps({
    program: Object
});

const analyticsOverviewStore = useAnalyticsOverviewStore();

const init = () => {
    analyticsOverviewStore.setAnalyticsOverviewProgram(props.program);
    analyticsOverviewStore.fetchAnalyticsOverviewStatsData();
    analyticsOverviewStore.fetchAnalyticsOverviewRevenueInsightsData();
    analyticsOverviewStore.fetchAnalyticsOverviewRecentTransactionData();
    analyticsOverviewStore.fetchAnalyticsOverviewPayoutsData();
    analyticsOverviewStore.fetchAnalyticsOverviewTopPartnersData();
};

onMounted(() => {
    init();
});

</script>

<template>
    <!-- Stats -->
    <div class="grid xl:grid-cols-3 gap-4">
        <Stats v-if="analyticsOverviewStore.getAnalyticsOverviewStatsData"/>
        <StatsWithSumSkeleton v-else/>
    </div>
    <!-- ./Stats -->

    <!-- Insights -->
    <div class="space-y-4">
        <div class="grid xl:grid-cols-3 gap-4">
            <div class="xl:col-span-2">
                <h3 class="text-lg font-semibold text-gray-900">Insights</h3>
            </div>
            <div class="hidden xl:block">
                <h3 class="text-lg font-semibold text-gray-900">Recent transactions</h3>
            </div>
        </div>

        <div class="grid xl:grid-cols-3 gap-4">
            <AccountOrProgramInsights class="col-span-2" :program="program.id" />
<!--        <InsightsChart v-if="analyticsOverviewStore.getAnalyticsOverviewRevenueInsightsData"/>-->
<!--            <div v-else class="col-span-2">-->
<!--                <ChartSkeleton />-->
<!--            </div>-->
            <InsightsRecentTransactions v-if="analyticsOverviewStore.getAnalyticsOverviewRecentTransactionData"/>
            <ListSkeleton v-else />
        </div>
    </div>
    <!-- ./Insights -->

    <!-- Payouts -->
    <div class="space-y-4">
        <div class="flex items-center justify-between">
            <h3 class="text-lg font-semibold text-gray-900">Payouts</h3>
            <btn v-if="analyticsOverviewStore.getProgram"
                :href="createRouteForTemplate('programs.payouts.list', { program: analyticsOverviewStore.getProgram })"
                 type="secondary" size="small" icon-name="arrow-right-s" icon-right="true"
            >
                All payouts
            </btn>
        </div>

        <Payouts v-if="analyticsOverviewStore.getAnalyticsOverviewPayoutsData"/>

        <StatsSkeleton v-else />
    </div>
    <!-- ./Payouts -->

    <!-- Top partners -->
    <div class="space-y-4">
        <div class="flex items-center justify-between">
            <h3 class="text-lg font-semibold text-gray-900">Top partners</h3>
            <btn v-if="analyticsOverviewStore.getProgram"
                 :href="createRouteForTemplate('programs.partners.index', { program: analyticsOverviewStore.getProgram })"
                 type="secondary" size="small" icon-name="arrow-right-s" icon-right="true"
            >
                All partners
            </btn>
        </div>

        <TopPartners v-if="analyticsOverviewStore.getAnalyticsOverviewTopPartnersData"/>

        <TableSkeleton v-else />
    </div>
    <!-- ./Top partners -->
</template>
