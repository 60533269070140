<template>

    <div class="space-y-4">
        <div class="bg-white rounded border">

            <div class="px-10 divide-y divide-gray-200">

                <form-section title="Trusted domains"
                              description="Enhance your integration's security by whitelisting trusted domains or IP addresses. This ensures that only requests from these sources are accepted."
                >

                    <div>
                        <textarea v-model="domains" id="trusted-domains" rows="5"
                                  class="block w-full text-sm rounded border-gray-300 focus:ring-0 focus:border-gray-400 mb-1 scroll-h"></textarea>
                        <small class="text-gray-500 mt-1 block">
                            List trusted domains and IP addresses, each on a separate line.
                        </small>
                        <small class="text-red-500 mt-1 block" v-if="updateError">
                            {{ updateError }}
                        </small>
                    </div>

                </form-section>

                <form-section title="Secure JS calls"
                              description="Restricted usage of JavaScript integrations."
                >
                    <toggle
                        title="Enable customer creation via JS calls"
                        :model-value="js_call_create_customers"
                        @update:modelValue="toggleCustomersCalls"
                    >
                        <template #description>
                            When enabled, simplified JavaScript integrations like Partnero Universal can be used
                            to create
                            customers. This is not needed if API integration is used instead.
                        </template>
                    </toggle>

                    <toggle
                        title="Enable transaction creation via JS calls"
                        :model-value="js_call_create_transactions"
                        @update:modelValue="toggleTransactionCalls"
                    >
                        <template #description>
                            When enabled, transactions can be created using JavaScript calls. This
                            method is recommended only
                            if alternatives like Stripe or Paddle for transaction tracking are
                            unavailable.
                        </template>
                    </toggle>

                    <modal title="Please confirm" size="small" name="enable_js_transactions_modal"
                           @modalClose="onCancelTransactionCalls">
                        <template #body>
                            <div class="space-y-6">
                                <div class="text-sm text-gray-500">
                                    Are you sure you want to enable transaction creation via JavaScript calls?
                                    This could potentially
                                    lead to misuse or abuse.
                                </div>
                            </div>
                        </template>
                        <template #footer>
                            <btn type="primary" @click="enableTransactionCalls">Yes, enable</btn>
                        </template>
                    </modal>

                </form-section>

            </div>
            <div class="bg-gray-50 px-10 py-4 text-right rounded-b sticky bottom-0 z-40">
                <btn @click="updateSettings" :loading="loading">
                    Update
                </btn>
            </div>

        </div>
    </div>

</template>
<script setup>
import {ref, onMounted, inject} from "vue";
import Toggle from "../../elements/forms/Toggle";

const props = defineProps({
    program: {
        type: [Number]
    },
    trusted_domains: {
        type: Array
    },
    js_call_create_customers: {
        type: Boolean
    },
    js_call_create_transactions: {
        type: Boolean
    },
});

let domains = ref([]);
let loading = ref(false);
let updateError = ref(false);
const programId = props.program;

domains.value = props.trusted_domains.join("\n");
const js_call_create_customers = ref(!!props.js_call_create_customers);
const js_call_create_transactions = ref(!!props.js_call_create_transactions);
const emitter = inject('emitter');

const onCancelTransactionCalls = () => {
    js_call_create_transactions.value = false;
}

const toggleTransactionCalls = (value) => {
    if (value) {
        emitter.emit('modal-on', 'enable_js_transactions_modal')
    }
    js_call_create_transactions.value = value;
}

const toggleCustomersCalls = (value) => {
    js_call_create_customers.value = value;
}

const enableTransactionCalls = () => {
    emitter.emit('modal-off', 'enable_js_transactions_modal')
    js_call_create_transactions.value = true;
}

const updateSettings = () => {

    if (loading.value) {
        return;
    }

    loading.value = true;
    updateError.value = false;

    axios
        .post(route('app_int.js_integration.update_settings', {program: programId}), {
            domains: domains.value,
            allow_js_calls_create_customers: js_call_create_customers.value,
            allow_js_calls_create_transactions: js_call_create_transactions.value,
        })
        .then(response => {
            const status = parseInt(response.data.status || -1);
            const returnDomains = response.data.domains || [];

            if (status === 1 && returnDomains) {
                domains.value = returnDomains.join("\n");
            }
        })
        .catch(error => {
            updateError.value = error.response.data.errors.domains || false;
        })
        .finally(() => {
            loading.value = false;
        });

};

onMounted(() => {
});
</script>
