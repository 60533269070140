<template>
    <div class=" bg-tahiti-100 border border-gray-900 border-opacity-5 rounded mb-10 relative p-10" v-if="show">

        <div class="mb-10">
            <h3 class="text-lg font-semibold mb-2">Great news!</h3>
            <p class="text-md">You've managed to set up an affiliate program for your Shopify store. Before proceeding
                further, let's double-check that you've completed all the necessary integration steps.</p>
        </div>

        <div class="flex items-center gap-4 mb-4">
            <div
                class="flex justify-center items-center w-10 h-10 bg-teal-300 rounded-full font-semibold text-sm border border-teal-400">
                1
            </div>
            <div>
                <p class="text-md font-semibold mb-0.5">Enable Partnero Integration</p>
                <p class="text-sm">Integration allows you to fully experience all the benefits of Partnero.</p>
            </div>
        </div>

        <div class="mb-4 ml-14 space-y-1">
            <p>To fully experience all the benefits of Partnero, it may be necessary to manually enable the Partnero app
                on some Shopify themes.</p>
            <p>Go to <span class="font-semibold">Online Store -> Themes</span>. Click on <span class="font-semibold">Customize</span>
                next to your Current theme. Click on <span class="font-semibold">App embeds</span> and enable <span
                    class="font-semibold">Partnero Integration</span>. <a href="https://help.partnero.com/article/55-integrate-shopify" class="underline hover:no-underline">Click
                    here</a> to read more.</p>
        </div>

        <div>
            <btn size="large" icon-name="chevron-right" icon-right="true" @click="hideNotification">I've completed all
                steps
            </btn>
        </div>

    </div>
</template>
<script>
export default {
    name: 'AfterInstallCheckList',
    props: ['program', 'has_shopify_store', 'hide'],
    data() {
        return {
            show: this.has_shopify_store && !this.hide
        }
    },
    methods: {
        hideNotification() {
            axios.post(route('app_int.integrations.shopify.hide_post_install_check_list', {program: this.program}));
            this.show = false;

        }
    }
}
</script>
<style scoped>
</style>
