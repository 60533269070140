<template>
    <div>
        <!-- Filter button control and search -->
        <div class="flex lg:flex-row flex-col flex-col-reverse gap-3">
            <btn
                @click="toggleFilters"
                type="secondary"
                icon-name="filter"
            >
                <span>Filters</span>
                <span
                    class="bg-gray-200 rounded-full w-4 h-4 text-sm font-semibold leading-none inline-flex items-center justify-center"
                >
                    <span class="text-xs">
                        {{ activeFiltersCount }}
                    </span>
                </span>
            </btn>
            <div class="bg-white relative w-full">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <icon name="magnifier-glass" size="16"></icon>
                </div>
                <div>
                    <input
                        v-model="searchQuery"
                        type="text"
                        name="search"
                        id="search"
                        class="transition-shadow block w-full pl-9 text-sm border-gray-300 rounded bg-transparent focus:ring-0 focus:border-gray-400 placeholder-gray-400"
                        :placeholder="searchFieldPlaceholder">
                </div>
                <button
                    class="absolute right-2 bg-gray-500 bg-opacity-20 py-1 pl-1.5 pr-2 rounded flex items-center justify-center top-1.5 text-gray-500 focus:outline-none hover:bg-opacity-30"
                    @click.prevent="clearFilters"
                >
                    <icon name="close" size="10"></icon>
                    <span class="text-xs font-medium ml-0.5">Clear all</span>
                </button>
            </div>
        </div>
        <validation-errors
            :errors="validationErrors"
            class="mt-2.5"
        />
        <!-- ./Filter button control and search -->
        <!-- Filters -->
        <div
            v-if="filtersAreVisible"
            class="bg-gray-100 grid gap-3 p-4 rounded mt-3"
            :class="'xl:grid-cols-' + Math.min(Object.keys(filterList).length, 4)"
        >
            <template v-for="(filter, fieldName) in filterList">
                <!-- select -->
                <div
                    v-if="filter.type === 'select'"
                    :key="filter.type + '-' + filter.label"
                    class="flex-1"
                >
                    <span class="text-sm text-gray-900 pointer-events-none mb-1.5">{{ filter.label }}</span>
                    <select
                        v-model="filters[fieldName]"
                        :id="fieldName"
                        :name="fieldName"
                        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:ring-0 focus:border-gray-400 sm:text-sm rounded-md"
                    >
                        <option v-for="(option) in filter.select_options"
                                :key="'option-' + filter.label + '-' + option.label"
                                :selected="option.value === filter.default_value"
                                :value="option.value"
                        >
                            {{ option.label }}
                        </option>
                    </select>
                </div>
                <!-- ./select -->
                <!-- text with condition select -->
                <div
                    v-if="filter.type === 'text_with_condition_select'"
                    :key="filter.type + '-' + filter.label"
                    class="flex-1"
                >
                    <div class="flex flex-col">
                        <div class="flex items-center mb-1.5">
                            <span class="text-sm text-gray-900 pointer-events-none">{{ filter.label }}</span>
                            <select
                                v-model="filters[fieldName + 'Condition']"
                                class="block border-0 focus:ring-0 leading-4 ml-auto text-xs text-gray-500 text-right p-0 pr-7 -mr-2 bg-transparent"
                            >
                                <option
                                    v-for="(option) in filter.condition_select"
                                    :selected="option.value === filter.default_select_value"
                                    :value="option.value"
                                >
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                        <div class="relative">
                            <text-field
                                v-model="filters[fieldName]"
                                type="text"
                                :placeholder="filter.placeholder"
                            />
                            <button
                                class="absolute right-2.5 bg-gray-500 bg-opacity-20 rounded flex items-center justify-center top-2.5 text-gray-500 p-1 focus:outline-none hover:bg-opacity-30"
                                @click.prevent="filters[fieldName] = ''"
                            >
                                <icon name="close" size="10"></icon>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ./text with condition select -->
            </template>
        </div>
        <!-- ./Filters -->
    </div>
</template>

<script>
import useFilters from "../../../../state/common/filters-and-pagination";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {setFilterFields} from "../../../../helpers/common";
import ValidationErrors from "../../../common/ValidationErrors.vue";

export default {
    name: "TableFilter",
    components: {ValidationErrors},
    props: {
        filterList: {
            type: Object,
            default: {}
        }
    },
    setup(props) {
        let searchQuery = ref('')

        let filters = reactive({})
        let filtersAreVisible = ref(false)
        const filterList = props.filterList

        function toggleFilters() {
            filtersAreVisible.value = !filtersAreVisible.value
        }

        function clearFilters() {
            searchQuery.value = ''
            setFilterFields(filters, filterList, true)
        }

        // this is needed when referring from analytics page
        function setFilterIfExistInRoute() {
            const routeParams = new URLSearchParams(window.location.search);
            if (routeParams) {
                routeParams.forEach((value, key) => {
                    if (filters.hasOwnProperty(key)) {
                        filters[key] = value
                    }
                })
            }
            if (activeFiltersCount.value > 0) {
                filtersAreVisible.value = true
            }
        }

        onMounted(() => {
            setFilterFields(filters, filterList)
            setFilterIfExistInRoute()
        })

        const {
            fetchDataBaseUrl,
            fetchDataFunction,
            setFilters: setFiltersToStore,
            setSearchParam: setSearchParamToStore,
            sorting,
            validationErrors,
            searchFieldPlaceholder
        } = useFilters()

        function fetchData() {
            fetchDataFunction.value(fetchDataBaseUrl.value, {
                ...filters,
                search: searchQuery.value,
                sortingColumn: sorting.value.key,
                sortingDirection: sorting.value.direction
            })
        }

        watch(filters, (updatedFilters) => {
            setFiltersToStore(updatedFilters)
            fetchData();
        })

        watch(searchQuery, () => {
            doSearch()
        })

        let delayTimer = null;

        function doSearch() {
            clearTimeout(delayTimer);
            delayTimer = setTimeout(function () {
                setSearchParamToStore(searchQuery.value)
                fetchData();
            }, 500);
        }

        const activeFiltersCount = computed(function () {
            let countActive = 0
            Object.keys(filterList).forEach(filterName => {
                if (filterList[filterName].type === 'select'
                    && filterList[filterName].default_value !== filters[filterName]) {
                    countActive++
                }
                if (filterList[filterName].type === 'text_with_condition_select'
                    && (filterList[filterName].default_text !== filters[filterName]
                        || filterList[filterName].default_select_value !== filters[filterName + 'Condition'])) {
                    countActive++
                }
            })
            return countActive
        })

        return {
            filters,
            toggleFilters,
            filtersAreVisible,
            searchQuery,
            activeFiltersCount,
            validationErrors,
            clearFilters,
            searchFieldPlaceholder
        }
    }
}
</script>
