import axios from 'axios'

export const file = {
    namespaced: true,
    state: () => ({
        loading: false,
        files: [],
        meta: [],
        page: 1,
        limit: 25,
        sortField: 'created_at',
        sortOrder: 'desc',
        searchQuery: '',
        type: null,
        selected: [],
        selectedAll: false,
        view: 'grid',
        previewFile: {},
    }),
    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },
        setFiles(state, files) {
            state.files = files
        },
        addFile(state, file) {
            state.files.unshift(file)
        },
        updateFile(state, file) {
            state.files = state.files.map((item) => ((item.id === file.id) ? file : item))
        },
        setSelected(state, selected) {
            state.selected = selected
        },
        setMeta(state, meta) {
            state.meta = meta
        },
        setSortField(state, sortField) {
            localStorage.setItem('filemanager:file:sortField', sortField)
            state.sortField = sortField
        },
        setSortOrder(state, sortOrder) {
            localStorage.setItem('filemanager:file:sortOrder', sortOrder)
            state.sortOrder = sortOrder
        },
        setSearchQuery(state, searchQuery) {
            state.searchQuery = searchQuery
        },
        setType(state, type) {
            state.type = type
        },
        setLimit(state, limit) {
            state.limit = limit
        },
        setPage(state, page) {
            state.page = page
        },
        setView(state, view) {
            localStorage.setItem('filemanager:file:view', view)
            state.view = view
        },
        deselectFile(state, fileId) {
            state.selected = state.selected.filter((s) => s !== fileId)
        },
        selectFile(state, fileId) {
            state.selected.push(fileId)
        },
        setSelectedAll(state, selectedAll) {
            state.selectedAll = selectedAll
        },
        setPreviewFile(state, file) {
            state.previewFile = file
        },
        initialiseStore(state) {
            if (localStorage.getItem('filemanager:file:view')) {
                state.view = localStorage.getItem('filemanager:file:view')
            }
            if (localStorage.getItem('filemanager:file:sortField')) {
                state.sortField = localStorage.getItem('filemanager:file:sortField')
            }
            if (localStorage.getItem('filemanager:file:sortOrder')) {
                state.sortOrder = localStorage.getItem('filemanager:file:sortOrder')
            }
        },
    },
    actions: {
        getFiles({ commit, state, rootState }) {
            commit('setLoading', true)
            commit('setSelected', [])
            commit('setSelectedAll', false)
            const params = {
                page: state.page,
                limit: state.limit,
            }
            if (state.sortField) {
                params.sort_field = state.sortField
            }
            if (state.sortOrder) {
                params.sort_order = state.sortOrder
            }
            if (state.type) {
                params.type = state.type
            }
            if (rootState.folder.selected) {
                params.folder = rootState.folder.selected
            }
            if (state.searchQuery) {
                params.search = state.searchQuery
            }
            axios.post('/filemanager/files', params)
                .then((response) => {
                    commit('setFiles', response.data.data)
                    commit('setMeta', response.data.meta)
                    commit('setLoading', false)
                })
                .catch((error) => {
                    console.log(error, error.response)
                    commit('setLoading', false)
                })
        },
        setSortField({ commit, dispatch }, sortField) {
            commit('setSortField', sortField)
            dispatch('getFiles')
        },
        setSortOrder({ commit, dispatch }, sortOrder) {
            commit('setSortOrder', sortOrder)
            dispatch('getFiles')
        },
        setSearchQuery({ commit, dispatch }, searchQuery) {
            commit('setSearchQuery', searchQuery)
            dispatch('getFiles')
        },
        setFileType({ commit, dispatch }, type) {
            commit('setType', type)
            dispatch('getFiles')
        },
        setLimit({ commit, dispatch }, limit) {
            dispatch('setPage', {
                page: 1,
                fetch: false,
            })
            commit('setLimit', limit)
            dispatch('getFiles')
        },
        setPage({ commit, dispatch }, { page, fetch = true }) {
            commit('setPage', page)
            if (fetch) {
                dispatch('getFiles')
            }
        },
        toggleSelected({ commit, state }, fileId) {
            if (state.selected.includes(fileId)) {
                commit('deselectFile', fileId)
            } else {
                commit('selectFile', fileId)
            }
        },
        toggleSelectAll({ commit, state }) {
            if (state.selectedAll) {
                commit('setSelectedAll', false)
                commit('setSelected', [])
            } else {
                commit('setSelectedAll', true)
                const selected = []
                state.files.forEach((file) => {
                    selected.push(file.id)
                })
                commit('setSelected', selected)
            }
        },
    },
    getters: {

    },
}
