<template>

    <div class="space-y-6">

        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Start tracking website visitors</h3>
                <div class="text-sm space-y-2">
                    <p>For accurate tracking, you need to install the PartneroJS snippet on your website as custom code.</p>
                </div>
            </div>

            <slot name="universal_script"></slot>

            <div class="space-y-2">
                <div class="text-sm space-y-2">
                    <p>Follow these steps to insert Partnero Universal into your Thinkific site:</p>
                    <ol class="list-decimal ml-4 space-y-1">
                        <li>Log in to your <b>Thinkific</b> account</li>
                        <li>Proceed to <b>Settings</b> and choose <b>Code &amp; analytics</b></li>
                        <li>Insert the Partnero Universal Script into the <b>Site footer code</b> section</li>
                    </ol>
                </div>
            </div>

        </div>

        <div class="space-y-4 border-t pt-6">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Sign-ups tracking</h3>
                <div class="text-sm space-y-2">
                    <p>On the same page, find a section named <b>Signup tracking code</b>.</p>
                    <p>Paste the following script into the area:</p>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-1').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-1" rows="4" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs" v-pre><script>
  window.onload = function() {
    po('customers', 'signup', {
      data: {
        key: '{{email}}',
        name: '{{first_name}}',
        email: '{{email}}'
      }
    });
  };
</script></textarea>
            </div>
        </div>

        <alert type="warning">If you choose to use <b>Stripe</b> integration, you can skip the following steps.</alert>


        <div class="space-y-4">
            <div class="space-y-2">
                <h3 class="font-semibold text-md">Orders tracking </h3>
                <div class="text-sm space-y-2">
                    <p>On the same page, find a section named <b>Order tracking code</b>.</p>
                    <p>Paste the following script into the area:</p>
                </div>
            </div>

            <div class="relative">
                <div class="absolute right-5 top-3" onclick="copyToClipboard(document.getElementById('snippet-2').value)">
                    <tooltip content="Copied" method="click">
                        <btn type="secondary" size="pill">Copy</btn>
                    </tooltip>
                </div>
                <textarea id="snippet-2" rows="4" class="bg-gray-50 block w-full text-sm rounded border-gray-200 focus:ring-0 focus:border-gray-400 mb-1 scroll-h font-monospace text-xs" v-pre><script>
  window.onload = function() {
    po('transactions', 'create', {
        data: {
            key: '{{product_id}}',
            amount: {{total_price}},
            customer: {
                key: '{{billing_email}}'
            }
        }
    });
  };
</script></textarea>
            </div>
        </div>

        <alert type="warning"><b>Please note:</b> When using this integration method, transaction creation via JavaScript (JS) calls must be enabled to ensure successful tracking.</alert>

    </div>

</template>
