<template>
    <email-edit-header />
    <!-- element -->
    <div id="editor" />
    <notification
        :show="emailStore.get_notification.showNotification"
        :title="emailStore.get_notification.notificationTitle"
        :type="emailStore.get_notification.notificationType"
        class="mb-20"
        @notificationClosed="emailStore.get_notification.showNotification = false"
    >
        <template #message>
            {{ emailStore.get_notification.notificationMessage }}
        </template>
    </notification>
</template>

<script setup>
import Revolvapp from "../../../../revolvapp/revolvapp.usm.min";
import "../../../../revolvapp/reorder.js";
import {useRevolvappEmailStore} from "../../../../stores/app/emails/revolvapp/RevolvAppEmailStore";
import {onMounted} from "vue";
import EmailEditHeader from "./components/EmailEditHeader";

    const props = defineProps({
        autosave_url: {
            type: String
        },
        autosave_status_update_url: {
            type: String
        },
        draft_save_url: {
            type: String
        },
        setup_url: {
            type: String
        },
        update_email_subject_url: {
            type: String
        },
        slug: {
            type: String
        },
        template: {
            type: String
        },
        draft_template: {
            type: [String, null]
        },
        default_template: {
            type: [String, null]
        },
        template_autosave: {
            type: [Boolean, Number],
            default: false
        },
        db_email_subject: {
            type: String,
            default: ''
        },
        default_revolvapp_style_settings: {
            required: true
        }
    })

    let emailStore = useRevolvappEmailStore()

    const mountEditor = () => {
        const revolvapp = Revolvapp('#editor', {
            image: {
                url: true, // enable images by url
                upload: false, // disable image upload
            },
            plugins: ['reorder'], // reorder plugin
            styles: { // default styles
                text: {
                    'font-size': '16px',
                    'color': props.default_revolvapp_style_settings.email_text_color || '#000'
                },
                heading: {
                    'color': props.default_revolvapp_style_settings.email_text_color || '#000'
                },
                link: {
                    'color': props.default_revolvapp_style_settings.email_link_color || '#000'
                },
                button: {
                    'font-size': '16px',
                    'font-weight': 'bold',
                    'color': '#fff',
                    'background-color': props.default_revolvapp_style_settings.email_brand_color || '#14b8a6',
                    'padding': '20px 40px',
                    'border-radius': '4px'
                },
                divider: {
                    'height': '2px',
                    'background-color': props.default_revolvapp_style_settings.email_divider_color || '#111118'
                },
            },
            blocks: {
                hidden: ['three-text', 'three-images', 'three-headings-text', 'three-images-text', 'three-images-headings-text'] // hidden blocks
            },
            subscribe: {
                'editor.change': function () {
                    templateChangeEvent(revolvapp);
                },
            }
        });
        revolvapp.app.editor.setTemplate( props.draft_template || props.template);
    }

    let delayTimer = null;

    const templateChangeEvent = (revolvapp) => {
        if (emailStore.get_autosave) {
            clearTimeout(delayTimer);
            delayTimer = setTimeout(function () {
                emailStore.saveTemplate(revolvapp);
            }, 500);
        }
    }

    onMounted(() => {

        emailStore.$patch({
            autosave_url: props.autosave_url,
            draft_save_url: props.draft_save_url,
            autosave_status_update_url: props.autosave_status_update_url,
            update_email_subject_url:props.update_email_subject_url,
            autosave: props.template_autosave,
            email_subject: props.db_email_subject,
            revolvapp_default_template: props.default_template,
            default_revolvapp_style_settings: props.default_revolvapp_style_settings,
            slug: props.slug
        })

        mountEditor()
    })
</script>

<style scoped>
@import "../../../../revolvapp/css/revolvapp.css";
</style>
