<script setup>

import {useEspSettingsStore} from "../../../../../../stores/app/programs/newsletter/EspSettingsStore";
import {ref, computed} from "vue";

    const props = defineProps({
        provider: {
            type: String,
            default: 'ESP'
        }
    });

    const espSettingsStore = useEspSettingsStore();

    const deleteExistingSubscribersFromPartnero = ref(false);

    const importGroupInitialState = espSettingsStore.getGroupImport;
    const importListInitialState = espSettingsStore.getListImport;
    const importTagInitialState = espSettingsStore.getTagImport;

    const importGroupChanged = computed(() => {
        return importGroupInitialState !== espSettingsStore.getGroupImport;
    });

    const importListChanged = computed(() => {
        return importListInitialState !== espSettingsStore.getListImport;
    });

    const importTagChanged = computed(() => {
        return importTagInitialState !== espSettingsStore.getTagImport;
    });

    const updating = ref(false);

    const getProviderName = () => {
        switch (props.provider) {
            case 'activecampaign':
                return 'ActiveCampaign';
            case 'convertkit':
                return 'ConvertKit';
            case 'klaviyo':
                return 'Klaviyo';
            case 'mailchimp':
                return 'Mailchimp';
            case 'mailerlite':
                return 'MailerLite';
            case 'mailerlite_classic':
                return 'MailerLite Classic';
            default:
                return 'ESP';
        }
    }

</script>

<template>
    <modal v-if="importGroupChanged || importListChanged || importTagChanged" size="small" title="Update program" name="esp_update">
        <template #toggle>
            <btn>Update</btn>
        </template>
        <template #body>
            <div>
                <toggle v-model="deleteExistingSubscribersFromPartnero"
                        class="text-left"
                        name="delete_existing_subscribers_from_partnero"
                        title="Delete subscribers from Partnero"
                >
                    <template #description>
                        If enabled, all existing subscribers will be deleted from Partnero. This action is
                        irreversible from Partnero side but the subscribers will remain in your {{ getProviderName() }}
                        and you can re-import them at any time.
                    </template>
                </toggle>
            </div>
        </template>
        <template #footer>
            <btn button-type="submit"
                 type="primary"
                 @click="updating = true"
                 :loading="updating"
            >
                Update
            </btn>
        </template>
    </modal>
    <div v-else>
        <input type="checkbox" class="invisible" name="delete_existing_subscribers_from_partnero" value="off">
        <btn button-type="submit"
             type="primary"
             @click="updating = true"
             :loading="updating"
        >
            Update
        </btn>
    </div>
</template>

