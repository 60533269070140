<script setup>
import {usePartneroReferAFriendStore} from "../../../../stores/app/refer-a-friend/PartneroReferAFriendStore";
import StatsSkeletonItem from "../../../common/skeletons/StatsSkeletonItem";
import {formatNumber} from "../../../../helpers/common";

const partneroReferAFriendStore = usePartneroReferAFriendStore()
</script>
<template>
    <div class="space-y-4">
        <div class="flex items-center justify-between">
            <h3 class="text-lg font-semibold text-gray-900">Your stats</h3>
        </div>
        <div class="grid xl:grid-cols-4 gap-4">
            <template v-if="partneroReferAFriendStore.getReferrals">
                <div class="p-6 bg-white rounded border">
                    <div class="mb-4 text-sm text-gray-900">Friends signed up</div>
                    <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                        {{ formatNumber(partneroReferAFriendStore.getReferrals.signups ) }}
                    </h3>
                </div>
                <div class="p-6 bg-white rounded border">
                    <div class="mb-4 text-sm text-gray-900">Friends upgraded</div>
                    <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                        {{ formatNumber(partneroReferAFriendStore.getReferrals.sales_count ) }}
                    </h3>
                </div>
                <div class="p-6 bg-white rounded border">
                    <div class="mb-4 text-sm text-gray-900">Credits earned</div>
                    <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                        <template v-if="partneroReferAFriendStore.getReferrals?.revenue?.length">
                            <div v-for="(amount, currency) in partneroReferAFriendStore.getReferrals.revenue">{{ amount }}</div>
                        </template>
                        <div v-else>0</div>
                    </h3>
                </div>
            </template>
            <template v-else>
                <StatsSkeletonItem v-for="index in 3" :key="'stat-' + index"></StatsSkeletonItem>
            </template>
            <div v-if="partneroReferAFriendStore.getBalance" class="p-6 bg-white rounded border">
                <div class="mb-4 text-sm text-gray-900">Current balance</div>
                <h3 class="text-2xl font-semibold leading-tight xl:text-3xl dark:text-gray-300">
                    <template v-if="partneroReferAFriendStore.getBalance?.length">
                        <template v-if="partneroReferAFriendStore.getBalance.currency">
                            <div v-for="balance in partneroReferAFriendStore.getBalance">{{ balance.amount + ' ' + balance.amount_units }}</div>
                        </template>
                        <template v-if="partneroReferAFriendStore.getBalance.non_currency">
                            <div v-for="balance in partneroReferAFriendStore.getBalance">{{ balance.amount }}</div>
                        </template>
                    </template>
                    <div v-else>0</div>
                </h3>
            </div>
            <StatsSkeletonItem v-else></StatsSkeletonItem>
        </div>
    </div>
</template>
