import {defineStore} from "pinia";

export let useAnalyticsOverviewStore = defineStore('analyticsOverview', {
    state: () => ({
        analyticsOverviewStatsData: null,
        analyticsOverviewRevenueInsightsData: null,
        analyticsOverviewRecentTransactionData: null,
        analyticsOverviewPayoutsData: null,
        analyticsOverviewTopPartnersData: null,
        program: null,
    }),

    actions: {
        setAnalyticsOverviewProgram(program) {
            this.program = program
        },
        setAnalyticsOverviewStatsData(data) {
            this.analyticsOverviewStatsData = data
        },
        fetchAnalyticsOverviewStatsData() {
            axios.get(route('app_int.analytics.overview.stats', { program: this.getProgram.id }))
                .then((response) => {
                    this.setAnalyticsOverviewStatsData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        setAnalyticsOverviewRevenueInsightsData(data) {
            this.analyticsOverviewRevenueInsightsData = data
        },
        fetchAnalyticsOverviewRevenueInsightsData() {
            axios.get(route('app_int.analytics.overview.revenue-insights', { program: this.getProgram.id }))
                .then((response) => {
                    this.setAnalyticsOverviewRevenueInsightsData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        setAnalyticsOverviewRecentTransactionData(data) {
            this.analyticsOverviewRecentTransactionData = data
        },
        fetchAnalyticsOverviewRecentTransactionData() {
            axios.get(route('app_int.analytics.overview.recent-transactions', { program: this.getProgram.id }))
                .then((response) => {
                    this.setAnalyticsOverviewRecentTransactionData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        setAnalyticsOverviewPayoutsData(data) {
            this.analyticsOverviewPayoutsData = data
        },
        fetchAnalyticsOverviewPayoutsData() {
            axios.get(route('app_int.analytics.overview.payouts', { program: this.getProgram.id }))
                .then((response) => {
                    this.setAnalyticsOverviewPayoutsData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        setAnalyticsOverviewTopPartnersData(data) {
            this.analyticsOverviewTopPartnersData = data
        },
        fetchAnalyticsOverviewTopPartnersData() {
            axios.get(route('app_int.analytics.overview.top-partners', { program: this.getProgram.id }))
                .then((response) => {
                    this.setAnalyticsOverviewTopPartnersData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    },

    getters: {
        getAnalyticsOverviewStatsData: (state) => { return state.analyticsOverviewStatsData },
        getAnalyticsOverviewRevenueInsightsData: (state) => { return state.analyticsOverviewRevenueInsightsData },
        getAnalyticsOverviewRecentTransactionData: (state) => { return state.analyticsOverviewRecentTransactionData },
        getAnalyticsOverviewPayoutsData: (state) => { return state.analyticsOverviewPayoutsData },
        getAnalyticsOverviewTopPartnersData: (state) => { return state.analyticsOverviewTopPartnersData },
        getProgram: (state) => { return state.program },
    }


})
